import React from "react";
import {
    Container, Row
} from "reactstrap";
import TopConsumers from "./consumer";
import UsageOverTime from "./historical";

//i18n
const BoardContent = ({ board, isLoading }) => {
    return (
        <React.Fragment>
            <TopConsumers consumers={board.consumers || {}} isLoading={isLoading} />
            <UsageOverTime board={board} isLoading={isLoading} />
        </React.Fragment>
    );
};


export default BoardContent;