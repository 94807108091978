import React, { useEffect, useState } from "react"
import { isEmpty } from "lodash";
import ReactApexChart from "react-apexcharts";
import { Card, CardBody, CardTitle, Col, Row } from "reactstrap";
import { truncateFromMiddle } from "utils";
import SimpleBar from "simplebar-react";

const PieChart = ({ data, title }) => {

    const values = (data || []).slice(0, 10).map(i => i.totalRequests);
    const labels = (data || []).slice(0, 10).map(i => truncateFromMiddle(i.principalName, 10));

    const options = {
        labels: labels,
        legend: { show: 1 },
        plotOptions: {
            pie: {
                donut: {
                    size: "60%",
                },
            },
        },
    };
    return <Card className="border">
        <CardTitle className="pt-3 ps-4 pb-2 border-bottom">
            <h5 className="text-muted">{title}</h5>
        </CardTitle>
        <CardBody>

            <div id="donut-chart">
                <ReactApexChart
                    options={options}
                    series={values}
                    type="donut"
                    height={350}
                    className="apex-charts"
                />
            </div>
        </CardBody>
    </Card>
}

const UsageOverTime = ({ board }) => {
    const [series, setSeries] = useState([]);
    const [labels, setLabels] = useState([]);

    const options = {
        chart: {
            height: 380,
            type: 'bar',
            zoom: {
                enabled: false
            },
            toolbar: {
                show: false
            }
        },
        // colors: apaexlineChartColors,
        dataLabels: {
            enabled: false,
        },
        stroke: {
            width: [3, 3],
            curve: 'straight'
        },
        title: {
            text: 'Traffic 7 derniers jours ',
            align: 'left',
            style: {
                fontWeight: '50',
            },
        },
        grid: {
            row: {
                colors: ['transparent', 'transparent'], // takes an array which will be repeated on columns
                opacity: 1
            },
            borderColor: '#f1f1f1'
        },
        markers: {
            style: 'inverted',
            size: 6
        },
        xaxis: {
            categories: labels,
            title: {
                text: 'Date',
                offsetY: 100,
            }
        },
        yaxis: {
            title: {
                text: 'Nombre de requêtes'
            },
            min: 0,
            max: 100
        },
        legend: {
            position: 'top',
            horizontalAlign: 'right',
            floating: true,
            offsetY: -35,
            offsetX: -5
        },
        responsive: [{
            breakpoint: 600,
            options: {
                chart: {
                    toolbar: {
                        show: false
                    }
                },
                legend: {
                    show: false
                },
            }
        }]
    }

    useEffect(() => {

        if (isEmpty(board))
            return;


        setSeries([{
            name: "Total",
            data: board.distribution.map(i => i.totalRequests)
        },
        {
            name: "Succès",
            data: board.distribution.map(i => i.totalSuccessRequests)
        },
        {
            name: "Echecs",
            data: board.distribution.map(i => i.totalFailedRequests)
        }
        ]);
        setLabels(board.distribution.map(i => new Date(i.id).toLocaleDateString("fr-FR")))



    }, [board])

    return <Col md={6}>
        <div className="card-item">
            <SimpleBar className="scroller-graph">
                <Row>
                    <Col md={12}>
                        <Card>
                            <CardBody>
                                <ReactApexChart
                                    options={options}
                                    series={series}
                                    type="bar"
                                    height="350"
                                    className="apex-charts"
                                />
                            </CardBody>
                        </Card>

                    </Col>
                </Row>
                <Row className="">
                    <Col xl={12} xxl={6}>

                        {board?.consumers?.assistants && <PieChart data={board?.consumers?.assistants} title="Top 10 assistants" />}

                    </Col>

                    <Col xl={12} xxl={6}>
                        {board?.consumers?.applications && < PieChart data={board?.consumers?.applications} title="Top 10 applications" />}
                    </Col>
                </Row>

            </SimpleBar>
        </div>

    </Col>
}

export default UsageOverTime;