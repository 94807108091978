import { ResourceTypes } from "constants/general";
import { capitalize, isEmpty } from "lodash";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { addAssistantFeedback } from "service/assistant";
import { operationCompleted } from "utils/notifications";
import { withTranslation } from "react-i18next";

const UserFeedBack = ({ message, assistant, t }) => {

    const [feedback, setFeedback] = useState(null);
    const [vote, setVote] = useState(null);
    const [canVote, setCanVote] = useState(true);
    const [showFeedBack, setShowFeedback] = useState(false);

    const onVote = (state) => {
        setVote(state);
        setCanVote(false);
        setTimeout(() => {
            setCanVote(true);
        }, 3000);

        setShowFeedback(true);
    }


    const onSendFeedback = () => {
        addAssistantFeedback(message.conversationId, {
            messageId: message.id,
            resourceId: assistant?.id,
            resourceOwner: assistant?.principalId,
            conversationId: message.conversationId,
            isNegative: vote === false,
            feedback: feedback,
            resourceType: assistant ? ResourceTypes.ASSISTANTS : ResourceTypes.DATASOURCES
        }).then(resp => {
            operationCompleted(t("Merci de votre feedback. Un agent s'en occupe pour integrer vos remarques rapidement."))
            setShowFeedback(false);

        });
    }

    return <div className="float-end">

        <Modal
            isOpen={showFeedBack}
            size={"lg"}
            fade={false}
            toggle={() => setShowFeedback(!showFeedBack)}
            centered={true}
            contentClassName="bg-info bg-opacity-50"
        >
            <div className="d-flex justify-content-between p-2">
                <h5 className="text-white"><i className="mdi mdi-information text-info" /> {t("Rajouter un Feedback à")} <b>{message?.agentName ? message.agentName : "cet assistant"}</b></h5>
                <div className=""><button onClick={() => setShowFeedback(!showFeedBack)} className="btn-close " /></div>
            </div>

            <ModalBody>
                <p className="text-white">{vote ? t("Qu'avez-vous apprecié?") : t("Que s'est-il passé?")}</p>
                <textarea
                    rows={10}
                    className="form-control"
                    value={feedback}
                    onChange={e => setFeedback(e.target.value)}
                    placeholder={t("Faites part de vos constats avec autant de détails que possible...")}
                >

                </textarea>
            </ModalBody>

            <ModalFooter>

                <button disabled={isEmpty(feedback)} onClick={onSendFeedback} className="btn btn-success">{t("Envoyer")}</button>
                <button onClick={() => setShowFeedback(false)} className="btn btn-danger">{t("Annuler")}</button>

            </ModalFooter>
        </Modal>
        {!canVote && <div><i className="mdi mdi-check" /> {t("Merci")}</div>
        }
        {canVote && <div className="hstack gap-2 ">
            <Link onClick={() => onVote(true)}><i className="mdi mdi-thumb-up text-success" /></Link>
            <Link onClick={() => onVote(false)}><i className="mdi mdi-thumb-down text-danger" /></Link>
        </div>}
    </div>
}

export default withTranslation()(UserFeedBack);
