import React, { Fragment, useState } from "react";
import {
    Col,
    Card,
    Container, Row
} from "reactstrap";

import { withTranslation } from "react-i18next";
import FileSystem from "./../documents/browser/list"
import Applications from "pages/Studio/applications/list";
import { ResourceTypes } from "constants/general";
import TemplateList from "pages/Automation/templates/list";
import Assistant from "../assistant";

const getResourceViewer = (info, openAssistant) => {

    switch (info?.type) {

        case ResourceTypes.FILESYSTEM:
            return <FileSystem {...info} />

        case ResourceTypes.APPLICATIONS:
            return <Applications {...info} />

        case ResourceTypes.TEMPLATES:
            return <TemplateList {...info} />

        case ResourceTypes.ASSISTANTS:
            return <Assistant {...info} openAssistant={openAssistant} />

        case ResourceTypes.DATASOURCES:
            return <Assistant {...info} openAssistant={openAssistant} />

        default:
            return <Fragment></Fragment>
    }
}


const ResourcesViewer = ({ resource, openAssistant }) => {

    return (
        <div className="workspace-resources-rightbar ">
            {getResourceViewer(resource, openAssistant)}
        </div >
    );
};

export default withTranslation()(ResourcesViewer);