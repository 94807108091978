import { call, put, takeEvery } from "redux-saga/effects"
import { CREATE_OMNICHAT_CONVERSATION, GET_OMNICHAT_CONVERSATION_HISTORY } from "./actionTypes"
import { getOmniChatConversationHistorySuccess, createOmniChatConversationSuccess, createOmniChatConversationFailed, getOmniChatConversationHistoryFailed, } from "./actions"
import { createOmniChatConversation, getConversationHistory } from "helpers/backend_helper"

function* createConversation(action) {
  try {
    const response = yield call(createOmniChatConversation, action.payload)
    yield put(createOmniChatConversationSuccess(response))
  } catch (error) {
    yield put(createOmniChatConversationFailed(error))
  }
}

function* getOmniChatConversationHistory(action) {
  try {
    const response = yield call(getConversationHistory, action.payload)
    yield put(getOmniChatConversationHistorySuccess(response))
  } catch (error) {
    yield put(getOmniChatConversationHistoryFailed(error))
  }
}

function* omnichatSaga() {
  yield takeEvery(CREATE_OMNICHAT_CONVERSATION, createConversation)
  yield takeEvery(GET_OMNICHAT_CONVERSATION_HISTORY, getOmniChatConversationHistory)
}

export default omnichatSaga
