
//CONVERSATIONS
export const GET_CONVERSATIONS = "/assistant/conversations";
export const GET_ASSISTANTS = "/assistant";
export const GET_OMNICHAT_CONVERSATIONS = "/assistant/omnichat/conversations";


//AI 
export const GET_AI_MODELS = "/assistant/models";



//DOCUMENTS
export const UPLOAD_FILES = "/documents/upload";
export const DOWNLOAD_FILE = "/documents/download";
export const UPLOAD_TASK_FILES = "/documents/task/upload";
export const GET_RECENT_FILES = "/workspace/documents/recents";
export const BROWSE_DIR = "/workspace/documents/browse";
export const GET_DIRECTORY = "/workspace/documents";
export const CREATE_FILES = "/documents/files";
export const BULK_DELETE = "/documents/bulk/delete";
export const INDEX_WORKSPACE_CONTENT = "/workspace/documents/index";
export const READ_TABULAR = "/documents/content/tabular/read";
export const SAVE_TABULAR = "/documents/content/tabular/save";

export const RUN_TASK_OVER_TABLE = "/documents/task/tabular";
export const ESTIMATE_INDEX_WORKSPACE_CONTENT = "/workspace/documents/index/estimate";


// TODO
export const GET_TODOS = "/workspace/tasks";
export const ADD_USER_TODO_BINDERS = "/workspace/tasks/folders";
export const GET_WORKSPACE_SETTINGS = "/workspace/settings";

//SEARCH
export const GET_DATA_SERVICES = "/search/providers";
export const SEARCH_INFO = "/search";


//TEMPLATES
export const PROCESS_TEMPLATE = "/workspace/templates/process";
export const GET_TEMPLATE = "/workspace/templates";
export const GENERATE_FROM_TEMPLATE = "/workspace/templates/generate/document";
export const DOWNLOAD_GENERATE_FROM_TEMPLATE = "/workspace/templates/generate/document/download";

//CRM 
export const GET_CRM_OPPTY = "/crm/opportunities";
export const GET_CRM_OPPTY_TREND = "/crm/opportunities/trend";
export const GET_CRM_OPPTY_BOARD = "/crm/opportunities/board";
export const GET_CRM_CONTACTS = "/crm/contacts";
export const GET_CRM_USERS = "/crm/users";
export const GET_CRM_ACCOUNT = "/crm/opportunities/accounts";
export const GET_CRM_OPPTY_STAGES = "/crm/opportunities/stages";


// APP STUDIO
export const GET_BOARD_EXEC_PLAN = "/studio/board/plan";
export const RUN_BOARD = "/studio/board/run";
export const RUN_APP = "/studio/apps/run";
export const CREATE_BOARD_APP = "/studio/apps";
export const GET_BOARD_APPS = "/studio/apps";


//DATASOURCES

export const GET_DATASOURCE_LIST = "/admin/datasources";
export const GET_DATASOURCE_DOMAINS_LIST = "/admin/datasources/domains/all";
export const ADD_NEW_DATASOURCE_LIST = "/admin/datasources";
export const UPDATE_DATASOURCE_LIST = "/admin/datasources";
export const DELETE_DATASOURCE_LIST = "/admin/datasources";
export const GET_APPLY_Datasource = "/admin/datasource";
export const DELETE_APPLY_Datasource = "/admin/datasources";
export const DATASOURCES_PROVIDER = "/admin/datasources/providers";
export const DATASOURCES_BROWSE = "/admin/datasources/browse";

// Users
export const GET_USERS = "/admin/users";
export const GET_USER_PROFILE = "/admin/users";
export const ADD_NEW_USER = "/admin/users";
export const UPDATE_USER = "/admin/users";
export const DELETE_USER = "/admin/users";
export const GET_USER_ACCOUNT_SETTINGS = "/admin/users/account";


//GROUPS
export const GET_GROUPS = "/admin/groups";

//ROLES
export const GET_ROLES = "/admin/roles";

//Usages
export const GET_USAGES_BOARD = "/admin/monitoring/board";
export const GET_FEEDBACKS_BOARD = "/admin/monitoring/feedbacks/board";
export const GET_FEEDBACKS = "/admin/monitoring/feedbacks";

// SOCIAL LOGIN
export const SOCIAL_LOGIN = "/social-login";

// DEPLOYMENTS
export const GET_DEPLOYMENTS = "/admin/deployments";

// ADD ONS
export const GET_SUPPORT_REQUESTS = "/addons/support/requests";
export const GET_SUPPORT_PLATFORM_BOARD = "/addons/support";
export const GET_SUPPORT_PLATFORMS = "/addons/support/platforms";
export const RESOLVE_SUPPORT_REQUESTS = `${GET_SUPPORT_REQUESTS}/autoresolve`


// PROMPT STORE
export const GET_PROMPT_STORE = "/prompts/store";