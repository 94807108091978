import React, { useState } from "react"
import { Link } from "react-router-dom"

import {
    Row, Col, Label, Input, Card, CardBody
} from "reactstrap"
import Azure from "assets/images/providers/azure.svg"
import { isEmpty } from "lodash";


function ConnectionComponent({ type, state, onConnectionUpdate }) {

    if (!type)
        return;

    state = { ...state, type: type };

    switch (type) {

        case "storageaccount":
        case "datalake":
        case "sqldatabase":
            return <Row>
                <div className="mb-0">
                    <Label for="basicpill-connstring-input1">
                        Connection String
                    </Label>
                    <Input
                        type="password"
                        className="form-control"
                        id="basicpill-connstring-input1"
                        placeholder="Enter the connection string"
                        onChange={e => onConnectionUpdate({
                            ...state,
                            connectionString: e.target.value,
                        })}
                    />
                </div>
            </Row>
        case "cosmosdb":
            return <Row><div className="vstack gap-3">
                <div className="mb-3">
                    <Label for="basicpill-endpoint-input1">
                        Endpoint URI
                    </Label>
                    <Input
                        type="text"
                        className="form-control"
                        id="basicpill-endpoint-input1"
                        placeholder="ie:https://..."
                        onChange={e => onConnectionUpdate({
                            ...state,
                            endpoint: e.target.value,
                        })}
                    />
                </div>

                <div className="mb-3">
                    <Label for="basicpill-endpoint-input1">
                        Application Key
                    </Label>
                    <Input
                        type="password"
                        className="form-control"
                        id="basicpill-endpoint-input1"
                        placeholder="Endpoint URI"
                        onChange={e => onConnectionUpdate({
                            ...state,
                            key: e.target.value,
                            type: type
                        })}
                    />
                </div>

                <div className="mb-3">
                    <Label for="basicpill-database-input1">
                        Database
                    </Label>
                    <Input
                        type="text"
                        className="form-control"
                        id="basicpill-database-input1"
                        placeholder="ie: products"
                        onChange={e => onConnectionUpdate({
                            ...state,
                            database: e.target.value
                        })}
                    />
                </div>
                <div className="mb-3">
                    <Label for="basicpill-container-input1">
                        Containers
                    </Label>
                    <Input
                        type="text"
                        className="form-control"
                        id="basicpill-container-input1"
                        placeholder="ie: products, customers, ..."
                        onChange={e => onConnectionUpdate({
                            ...state,
                            collections: e.target.value
                        })}
                    />
                </div>
            </div>
            </Row>
    }
}


const AzureStorageConnectivity = ({ onSetConnection }) => {

    const [storageType, setStorageType] = useState("sto");
    const [connection, setConnection] = useState({});
    const check = (state) => {

        switch (state.type) {

            case "cosmosdb":
                return !isEmpty(state.endpoint) && !isEmpty(state.key) && !isEmpty(state.type) && !isEmpty(state.database) && !isEmpty(state.collections)
            default:
                return !isEmpty(state.type) && !isEmpty(state.connectionString);
        }
    }

    const handleConnectionUPdate = (state) => {
        setConnection(state);
        onSetConnection({
            azure: state,
            isValid: check(state)
        });
    }

    return (
        <React.Fragment >

            <div>
                <h5>Connect to Azure Storage</h5>
            </div>
            <Row className="mt-3" >
                <Col md={8}>
                    <Row >
                        <Col md={6}>
                            <div className="mb-3">
                                <Label>Data Provider</Label>
                                <select className="form-select"
                                    value={storageType}
                                    onChange={e => setStorageType(e.target.value)}
                                >
                                    <option defaultValue>
                                        Select storage type
                                    </option>
                                    <option value="storageaccount">
                                        Storage Account
                                    </option>
                                    <option value="datalake">Datalake</option>
                                    <option value="sqldatabase">SQL Database</option>
                                    <option value="cosmosdb">CosmosDB</option>
                                </select>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            {<ConnectionComponent type={storageType} state={connection} onConnectionUpdate={handleConnectionUPdate} />}
                        </Col>
                    </Row>
                </Col>
                <Col md={4}>

                    <Card>
                        <CardBody>
                            <div className="favorite-icon">
                                <Link to="#"><i className="uil uil-heart-alt fs-18"></i></Link>
                            </div>
                            <img src={Azure} alt="" height="100" className="mb-3" />
                            <h5 className="fs-17 mb-2">
                                <Link to="/job-details" className="text-dark">Description</Link></h5>
                            <p className="text-muted fs-14 mb-1"> A comprehensive cloud computing platform and infrastructure service offered by Microsoft. It provides a wide range of cloud-based services and resources, including computing power, storage, databases, networking, analytics, artificial intelligence (AI), and more, enabling organizations to build, deploy, and manage applications and services in the cloud.</p>

                            <div className="mt-3 hstack gap-2">
                                <span className="badge rounded-1 badge-soft-info">Datalake</span>
                                <span className="badge rounded-1 badge-soft-info">SQL Database</span>
                                <span className="badge rounded-1 badge-soft-info">NoSQL</span>
                                <span className="badge rounded-1 badge-soft-info">...</span>
                            </div>

                        </CardBody>
                    </Card>
                </Col>
            </Row>

        </React.Fragment>
    )
}

export default AzureStorageConnectivity;
