import React, { createRef, useEffect, useState } from "react";
import { TaskStatus } from "constants/tasks";
import { Badge, Card, CardBody, CardTitle } from "reactstrap";
import SimpleBar from "simplebar-react";
import { map } from "lodash";
import { getFileIcon, truncateFromMiddle } from "utils";
import GenerationTextArea from "components/Assistant/Generation";

const PromptStreamView = ({ streams, file, taskName }) => {
    return <GenerationTextArea
        title={taskName || file?.name}
        text={streams?.text}
        isGenerating={streams?.status === TaskStatus.STARTED}
    />
}

export default PromptStreamView;