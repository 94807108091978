
const ROLE_ALREADY_EXIST = "RoleAlreadyExist";

export const parseError = (error) => {
    const type = error?.response?.data?.type;

    if (type) {

        switch (type) {

            case ROLE_ALREADY_EXIST:
                return "Le rôle sous le meme nom existe déja";

            default:
                return type;
        }
    }

    return error;
}