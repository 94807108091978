import React, { useEffect, useRef, useState } from "react"
import { Card, CardBody, Col, Row } from "reactstrap"
import SimpleBar from "simplebar-react"
import { ask } from 'service/assistant';
import { useSelector, useDispatch } from "react-redux";
import {
    getOmniChatConversationHistory,
    addOmniChatMessageHistory
} from "store/actions";
import { isEmpty, map } from 'lodash';
import Answer from '../chat/message/answer';
import { uuidv4 } from 'utils';
import Question from '../chat/message/question';
import LandingChat from './landing';
import AssistantInputChat from 'components/Assistant/InputChat';
import { truncateFromMiddle } from "utils";
import { Link } from "react-router-dom";
import { EventTypes } from "constants/events";
import { TaskStatus } from "constants/tasks";

const getAssistantCard = (conversation) => {
    return <div className="d-flex flex-row">
        <img className=" p-1 bg- bg-danger rounded-circle avatar-circle"
            src={conversation.agentLogo} width={40} height={50} />

        <div className="vstack ms-2 pt-1">
            <span className="fw-bold font-size-14 text-white ">{conversation.agentName}</span>
            <p className="text-white "> {conversation.description}</p>
        </div>
    </div>
}

const getConversationIcon = (conversation) => {

    switch (conversation?.type) {
        case "document":
            return <i className={"mdi mdi-file text-info"} />

        case "task":
            return <i className={"bx bx-task font-size-14 text-info"} />
        default:
            return <i className={"mdi mdi-chat"} />

    }
}

const OmniChat = props => {

    const displayMessageContainerRef = useRef();
    const [currentMessage, setCurrentMessage] = useState("");
    const [reply, setReply] = useState({ text: "" });
    const dispatch = useDispatch();

    const { conversation, history, isLoading, datasources, accessToken } = useSelector(state => ({
        conversation: state.Omnichat.conversation,
        history: state.Omnichat.history,
        isLoading: state.Omnichat.isLoading,
        datasources: state.Omnichat.datasources,
        accessToken: state.Login.accessToken,
    }));

    const onConnect = () => {

    }

    const onclose = () => {

    }

    const onError = () => {

    }
    const onMessage = (e) => {
        try {

            const parsedEvent = JSON.parse(e.data);
            switch (parsedEvent.type) {

                case EventTypes.MESSAGE_RECEIVED:
                    setReply(prev => ({
                        ...prev,
                        status: TaskStatus.THINKING
                    }));
                    break;

                case EventTypes.START:
                    setReply(prev => ({
                        ...prev,
                        agentName: parsedEvent.agentName,
                        agentLogo: parsedEvent.agentLogo,
                        text: '',
                        context: [],
                        status: TaskStatus.STARTED,
                        recommendations: []
                    }));

                    break;

                case EventTypes.STREAMING:
                    setReply(prev => ({
                        ...prev,
                        text: prev?.text + parsedEvent.value
                    }));
                    break;

                case EventTypes.CONTEXT:
                    setReply(prev => ({
                        ...prev,
                        context: parsedEvent.value
                    }));
                    break;

                case EventTypes.CONTENT_RECOMMANDATIONS:
                    setReply(prev => ({
                        ...prev,
                        recommendations: parsedEvent.value
                    }));
                    break;

                case EventTypes.END:
                    setReply(prev => ({
                        ...prev,
                        status: TaskStatus.COMPLETED
                    }));
                    break;
            }
        }
        catch (error) {
            console.log(error)
        }
    }

    const sendMessage = (prompt, sources, apps) => {

        const payload = {
            text: prompt,
            conversationId: conversation.id,
            sentByUser: true,
            role: 'user',
            datasources: datasources,
            datasources: (sources || []).map(source => ({ providerId: source.id })),
            applications: (apps || []).map(app => app.id),
        }

        dispatch(addOmniChatMessageHistory(payload))
        setCurrentMessage("");
        ask(payload, onConnect, onMessage, onError, onclose, accessToken)
    }


    const onSubmit = (request, sources, apps) => {

        if (isEmpty(request))
            return;

        sendMessage(request, sources, apps);

    }

    const scrollToBottom = (smooth) => {
        if (displayMessageContainerRef?.current) {
            const scrollEl = displayMessageContainerRef.current;
            scrollEl?.scroll({
                top: scrollEl?.scrollHeight + 1000,
                behavior: smooth ? 'smooth' : 'auto',
            });
        }
    }

    const onMessageEnd = (response) => {
        dispatch(addOmniChatMessageHistory({
            id: uuidv4(),
            text: response.text,
            role: "assistant",
            sentByUser: false,
            references: response.context,
            recommendations: response.recommendations,
            agentName: response.agentName || conversation?.agentName,
            agentLogo: response.agentLogo
        }));
    }


    const handleInputChange = ({ value, mentions }) => {
        setCurrentMessage(value);
    }

    // useEffect(() => {
    //     scrollToBottom(false);
    // }, [history])

    useEffect(() => {
        if (conversation?.id)
            dispatch(getOmniChatConversationHistory(conversation?.id))
    }, [conversation])

    useEffect(() => {

        if (reply.status === TaskStatus.COMPLETED) {
            onMessageEnd(reply);
        }

        scrollToBottom(false);

    }, [reply]);

    return <div className=''>
        <div className="d-flex justify-content-between omnichat-banner  p-1 ">
            {conversation?.agentLogo ? getAssistantCard(conversation) : <div>
                <h6 className="font-size-14 text-white">
                    <div className="hstack gap-1 ">
                        {getConversationIcon(conversation)}
                        {truncateFromMiddle(conversation?.title || "Mon assistant", 50)}
                    </div>
                </h6>
            </div>}

            <div >
                <Link
                    to="#"
                    onClick={e => {
                        e.preventDefault()
                        props.showRightSidebarAction(false)
                    }}
                    className="right-bar-toggle float-end"
                >
                    <i className="mdi mdi-close noti-icon" />
                </Link>
                {/* <h5 className="m-0">Jask</h5> */}
            </div>
        </div>

        {!isLoading && <div className="omnichat">
            <SimpleBar className='scroller ' scrollableNodeProps={{ ref: displayMessageContainerRef }} >
                <div className=" chat-history p-2 " ref={displayMessageContainerRef}>
                    <Card>
                        <CardBody>

                            <ul className="list-unstyled">
                                <li>
                                    <div className="chat-day-title">
                                        <span className="title">Just Ask</span>
                                    </div>

                                </li>

                                {history && map(history, message => {
                                    return <li className={message.role === "user" ? "right" : ""} >
                                        <div className="conversation-list ">
                                            {message.role === "user" && <Question message={message} />}
                                            {message.role === "assistant" && <Answer
                                                message={message}
                                                agentName={conversation?.agentName}
                                                agentLogo={conversation?.agentLogo}
                                                size={"sm"}
                                            />}
                                        </div>
                                    </li>
                                })}

                                {(reply?.status === TaskStatus.STARTED || reply?.status === TaskStatus.THINKING) && < li >
                                    <div className="conversation-list ">
                                        <Answer message={reply} />
                                    </div>

                                </li>}
                            </ul>
                        </CardBody>
                    </Card>
                </div>
            </SimpleBar>

            <div className="assistant-input p-2 ps-4 pe-4">
                <div className="assistant-input-section ps-2 pe-2 mb-1 " >
                    <Row>
                        <Col>
                            <div className="position-relative p-2">
                                <AssistantInputChat
                                    onValueChange={handleInputChange}
                                    onSubmit={onSubmit}
                                    readOnly={reply?.status === TaskStatus.STARTED}
                                />
                            </div>
                        </Col>
                    </Row>
                    {/* <Row>
                        <Col>
                            <div className="position-relative p-2">
                                <input
                                    type="text"
                                    // rows={1}
                                    value={currentMessage}
                                    // readOnly={isReceiving || isEmpty(currentConversation?.id)}
                                    onKeyPress={onSubmit}
                                    onChange={e => setCurrentMessage(e.target.value)}
                                    className="form-control"
                                    placeholder="Just ask ..."
                                />
                            </div>

                        </Col>
                    </Row> */}
                </div>
            </div>
            <div className="d-flex justify-content-between">
                <div className="hstack gap-3 ps-4 pb-2">
                </div>
            </div>
        </div>}

        {isLoading === true && <LandingChat />}
    </div>
}



export default OmniChat