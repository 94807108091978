import { GET_CONVERSATIONS, GET_CONVERSATIONS_SUCCESS, CREATE_CONVERSATION, CREATE_CONVERSATION_FAILED, CREATE_CONVERSATION_SUCCESS, GET_CONVERSATIONS_FAILED, GET_CONVERSATION_HISTORY, GET_CONVERSATION_HISTORY_SUCCESS, GET_CONVERSATION_HISTORY_FAILED, DELETE_CONVERSATION, DELETE_CONVERSATION_SUCCESS, DELETE_CONVERSATION_FAILED, UPDATE_CONVERSATION, UPDATE_CONVERSATION_TITLE, ADD_MESSAGE_HISTORY, GET_DATA_SERVICES, GET_DATA_SERVICES_SUCCESS, SEARCH_RESOURCES_SUCCESS, SEARCH_RESOURCES, GET_DATA_SERVICES_FAILED, SEARCH_RESOURCES_FAILED, GET_AI_MODELS, GET_AI_MODELS_SUCCESS, GET_AI_MODELS_FAILED } from "./actionTypes"

export const createConversation = payload => ({
  type: CREATE_CONVERSATION,
  payload: payload,
})

export const createConversationSuccess = payload => ({
  type: CREATE_CONVERSATION_SUCCESS,
  payload: payload,
})

export const createConversationFailed = error => ({
  type: CREATE_CONVERSATION_FAILED,
  payload: error,
})

export const getConversations = () => ({
  type: GET_CONVERSATIONS,
})

export const getConversationSuccess = conversations => ({
  type: GET_CONVERSATIONS_SUCCESS,
  payload: conversations,
})

export const getConversationsFailed = error => ({
  type: GET_CONVERSATIONS_FAILED,
  payload: error,
})


export const getConversationHistory = (payload) => ({
  type: GET_CONVERSATION_HISTORY,
  payload: payload,
})

export const getConversationHistorySuccess = history => ({
  type: GET_CONVERSATION_HISTORY_SUCCESS,
  payload: history,
})

export const getConversationhistoryFailed = error => ({
  type: GET_CONVERSATION_HISTORY_FAILED,
  payload: error,
})

export const delConversation = payload => ({
  type: DELETE_CONVERSATION,
  payload: payload,
})

export const delConversationSuccess = (id) => ({
  type: DELETE_CONVERSATION_SUCCESS,
  payload: id
})

export const delConversationFailed = error => ({
  type: DELETE_CONVERSATION_FAILED,
  payload: error,
})

export const updateConversationTitle = (payload) => ({
  type: UPDATE_CONVERSATION_TITLE,
  payload: payload,
})

export const addMessageHistory = (payload) => ({
  type: ADD_MESSAGE_HISTORY,
  payload: payload,
})

export const getDataServices = () => ({
  type: GET_DATA_SERVICES
})

export const getDataServicesSuccess = (payload) => ({
  type: GET_DATA_SERVICES_SUCCESS,
  payload: payload
})

export const getDataServicesFailed = (payload) => ({
  type: GET_DATA_SERVICES_FAILED,
  payload: payload
})

export const searchResources = (payload) => ({
  type: SEARCH_RESOURCES,
  payload: payload

})

export const searchResourcesSuccess = (payload) => ({
  type: SEARCH_RESOURCES_SUCCESS,
  payload: payload

})

export const searchResourcesFailed = (payload) => ({
  type: SEARCH_RESOURCES_FAILED,
  payload: payload
})

export const getModels = () => ({
  type: GET_AI_MODELS,
})

export const getModelsSuccess = (payload) => ({
  type: GET_AI_MODELS_SUCCESS,
  payload: payload
})

export const getModelsFailed = (error) => ({
  type: GET_AI_MODELS_FAILED,
  payload: error
})