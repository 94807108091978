import { TaskStatus } from "constants/tasks";
import React, { createRef, useEffect, useState } from "react";
import { ScenarioType } from "../constants";
import SingleFileInstruction from "./simple";
import MultipleFileInstruction from "./Grouped";
import CompareFilseInstruction from "./comparaison";
import { useSelector, useDispatch } from "react-redux";
import { getModels } from "store/actions";
import { isEmpty } from "lodash";


const TaskInstruction = ({ scenario, onFinish }) => {

    const [availableModels, setAvailableModels] = useState([]);
    const dispatch = useDispatch();

    const { models, apps } = useSelector(state => ({
        models: state.Assistant.models,
        apps: state.Studio.apps
    }));



    const GetView = (id, apps, models, onFinish) => {

        switch (id) {

            case ScenarioType.FILE_BY_FILE:
                return <SingleFileInstruction models={models} apps={apps} onFinish={onFinish} />

            case ScenarioType.MULTIPLE_FILE:
                return <SingleFileInstruction models={models} apps={apps} onFinish={onFinish} />

            case ScenarioType.COMPARAISON:
                return <CompareFilseInstruction />
        }

    }

    useEffect(() => {

        if (isEmpty(models)) {
            dispatch(getModels());
        }

    }, [dispatch]);

    useEffect(() => {

        setAvailableModels(models?.map(m => ({
            value: m.id,
            label: m.name,
            description: m.description
        })));

    }, [models])


    return <div>
        {GetView(scenario?.id, apps, availableModels, onFinish)}
    </div>
}

export default TaskInstruction;