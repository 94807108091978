import { GET_TODO_WORK_SUCCESS, GET_TODO_WORK_FAIL, GET_TODO_WORK, ADD_TODO_WORK_SUCCESS, DEL_TODO_WORK_SUCCESS, DEL_TODO_WORK_FAIL, ADD_TODO_WORK_FAIL, ADD_TODO_WORK_START, SUBMIT_TODO_WORK_SUCCESS, DELETE_TODO_WORK_JOB, SUBMIT_TODO_WORK_FAIL, ON_TODO_WORK_UPDATED, ADD_TODO_DRAFT, GET_WORKSPACE_PREFERENCES_SUCCESS, GET_WORKSPACE_PREFERENCES_FAILED, ADD_TODO_CATEGORY_SUCCESS, ADD_TODO_CATEGORY_FAILED, ON_TODO_WORK_UPDATED_SUCCESS, DEL_TODO_CATEGORY_SUCCESS } from "./actionTypes"

const INIT_STATE = {
  todos: [],
  error: {},
  runningTasks: [],
  isAdding: false,
  isLoading: false,
  settings: {
    binders: []
  }
}

const todos = (state = INIT_STATE, action) => {
  switch (action.type) {

    case ADD_TODO_DRAFT:
      return {
        ...state,
        todos: [action.payload, ...state.todos],
      }

    case GET_TODO_WORK:
      return {
        ...state,
        isLoading: true
      }

    case GET_TODO_WORK_SUCCESS:
      return {
        ...state,
        isLoading: false,
        todos: action.payload,
      }

    case GET_TODO_WORK_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      }

    case ADD_TODO_WORK_START:
      return {
        ...state,
        isAdding: true
      };

    case ADD_TODO_WORK_SUCCESS:
      return {
        ...state,
        isAdding: false,
        todos: [action.payload, ...state.todos],
      };

    case ON_TODO_WORK_UPDATED_SUCCESS:
      return {
        ...state,
        isAdding: false,
        todos: [action.payload, ...state.todos.filter(todo => todo.id !== action.payload.id)],
      };

    case SUBMIT_TODO_WORK_SUCCESS:
      return {
        ...state,
        runningTasks: [...state.runningTasks, action.payload],
      };

    case SUBMIT_TODO_WORK_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case DELETE_TODO_WORK_JOB:
      return {
        ...state,
        runningTasks: state.runningTasks.filter(
          task => task.id.toString() !== action.payload.toString()
        )
      }

    case ADD_TODO_WORK_FAIL:
      return {
        ...state,
        isAdding: false,
        error: action.payload,
      };

    case ON_TODO_WORK_UPDATED:
      const newTodos = [];
      const newTodo = action.payload;
      state.todos.forEach(todo => {

        if (todo.id === newTodo.id) {
          newTodos.push(newTodo);
        } else {
          newTodos.push(todo);
        }
      })

      return {
        ...state,
        isAdding: false,
        error: action.payload,
        todos: [...newTodos],
      };

    case DEL_TODO_WORK_SUCCESS:
      return {
        ...state,
        todos: state.todos.filter(
          todo => todo.id.toString() !== action.payload.toString()
        ),
      };

    case DEL_TODO_WORK_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case ADD_TODO_CATEGORY_SUCCESS:
      return {
        ...state,
        settings: {
          ...state.settings,
          binders: [...(state.settings.binders || []), action.payload]
        }
      }

    case DEL_TODO_CATEGORY_SUCCESS:
      return {
        ...state,
        settings: {
          ...state.settings,
          binders: state.settings.binders.filter(i => i.id !== action.payload)
        }
      }


    case GET_WORKSPACE_PREFERENCES_SUCCESS:
      return {
        ...state,
        settings: action.payload
      }

    case ADD_TODO_CATEGORY_FAILED:
    case GET_WORKSPACE_PREFERENCES_FAILED:
      return {
        ...state,
        error: action.payload,
      }

    default:
      return state

  }
}

export default todos;