import PropTypes from 'prop-types';
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Routes, Route } from "react-router-dom";
import { layoutTypes } from "./constants/layout";
import { ToastContainer } from 'react-toastify';
import { axiosApi } from 'helpers/api_helper';

// Import Routes all
import { authProtectedRoutes, publicRoutes } from "./routes";

// Import all middleware
import Authmiddleware from "./routes/route";

//Azure Middleware
import { EventType } from '@azure/msal-browser';
import { InteractionType } from '@azure/msal-browser';
import { MsalProvider, useMsal } from '@azure/msal-react';
import AzureAuthmiddleware from 'routes/authProviders/azure';

// layouts Format
import VerticalLayout from "./components/VerticalLayout/";
import HorizontalLayout from "./components/HorizontalLayout/";
import NonAuthLayout from "./components/NonAuthLayout";

// Import scss
import "./assets/scss/theme.scss";

// Import Firebase Configuration file
import { initFirebaseBackend } from "./helpers/firebase_helper";
import { loginSuccess, profileSuccess } from 'store/actions';

const getLayout = (layoutType) => {
  let Layout = VerticalLayout;
  switch (layoutType) {
    case layoutTypes.VERTICAL:
      Layout = VerticalLayout;
      break;
    case layoutTypes.HORIZONTAL:
      Layout = HorizontalLayout;
      break;
    default:
      break;
  }
  return Layout;
};

const Pages = ({ apiClient }) => {

  const dispatch = useDispatch();
  const { instance } = useMsal();
  const { layoutType } = useSelector((state) => ({
    layoutType: state.Layout.layoutType,
  }));



  const Layout = getLayout(layoutType);

  useEffect(() => {

    const callbackId = instance.addEventCallback((event) => {
      if (
        (event.eventType === EventType.LOGIN_SUCCESS || event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) &&
        event.payload.account
      ) {


        /**
         * For the purpose of setting an active account for UI update, we want to consider only the auth
         * response resulting from SUSI flow. "tfp" claim in the id token tells us the policy (NOTE: legacy
         * policies may use "acr" instead of "tfp"). To learn more about B2C tokens, visit:
         * https://docs.microsoft.com/en-us/azure/active-directory-b2c/tokens-overview
         */
        // if (compareIssuingPolicy(event.payload.idTokenClaims, b2cPolicies.names.editProfile)) {
        //   // retrieve the account from initial sing-in to the app
        //   const originalSignInAccount = instance
        //     .getAllAccounts()
        //     .find(
        //       (account) =>
        //         account.idTokenClaims.oid === event.payload.idTokenClaims.oid &&
        //         account.idTokenClaims.sub === event.payload.idTokenClaims.sub &&
        //         compareIssuingPolicy(account.idTokenClaims, b2cPolicies.names.signUpSignIn)
        //     );

        //   let signUpSignInFlowRequest = {
        //     authority: b2cPolicies.authorities.signUpSignIn.authority,
        //     account: originalSignInAccount,
        //   };

        //   // silently login again with the signUpSignIn policy
        //   instance.ssoSilent(signUpSignInFlowRequest);
        // }

        /**
         * Below we are checking if the user is returning from the reset password flow.
         * If so, we will ask the user to reauthenticate with their new password.
         * If you do not want this behavior and prefer your users to stay signed in instead,
         * you can replace the code below with the same pattern used for handling the return from
         * profile edit flow
         */
        // if (compareIssuingPolicy(event.payload.idTokenClaims, b2cPolicies.names.forgotPassword)) {
        //   let signUpSignInFlowRequest = {
        //     authority: b2cPolicies.authorities.signUpSignIn.authority,
        //     scopes: [
        //       ...protectedResources.apiTodoList.scopes.read,
        //       ...protectedResources.apiTodoList.scopes.write,
        //     ],
        //   };
        //   // instance.loginRedirect(signUpSignInFlowRequest);
        // }
      }

      if (event.eventType === EventType.LOGIN_FAILURE) {

        console.log("logging failure")
        // Check for forgot password error
        // Learn more about AAD error codes at https://docs.microsoft.com/en-us/azure/active-directory/develop/reference-aadsts-error-codes
        if (event.error && event.error.errorMessage.includes('AADB2C90118')) {
          const resetPasswordRequest = {
            authority: b2cPolicies.authorities.forgotPassword.authority,
            scopes: [],
          };
          // instance.loginRedirect(resetPasswordRequest);
        }
      }
    });

    return () => {
      if (callbackId) {
        instance.removeEventCallback(callbackId);
      }
    };
    // eslint-disable-next-line
  }, [instance]);


  useEffect(() => {
    const account = instance.getActiveAccount();
    if (!account)
      return;

    const token = account.idToken;

    dispatch(loginSuccess(account.idToken));


    const requestInterceptor = apiClient.interceptors.request.use(
      (req) => {
        req.headers.Authorization = `Bearer ${token}`;
        return req;
      },
      (err) => {
        return Promise.reject(err);
      });

    return () => {
      apiClient.interceptors.request.eject(requestInterceptor);
    };

  }, [apiClient]);


  return <Routes>
    {publicRoutes.map((route, idx) => (
      <Route
        path={route.path}
        element={
          <NonAuthLayout>
            {route.component}
          </NonAuthLayout>
        }
        key={idx}
        exact={true}
      />
    ))}

    {authProtectedRoutes.map((route, idx) => (
      <Route
        path={route.path}
        element={
          // <Authmiddleware>
          <AzureAuthmiddleware role={route.role}>
            <Layout>{route.component}</Layout>
          </AzureAuthmiddleware>
          // </Authmiddleware>
        }
        key={idx}
        exact={true}
      />
    ))}
  </Routes>
};

const App = ({ instance }) => {

  return (
    <MsalProvider instance={instance}>
      <React.Fragment>
        <ToastContainer limit={5} style={{ zIndex: 10000 }} />
        <Pages apiClient={axiosApi} />
      </React.Fragment>
    </MsalProvider>
  );
};

App.propTypes = {
  layout: PropTypes.any
};

export default App;