import React, { useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import TableContainer from 'components/Common/TableContainer';

//import components
import DeleteModal from 'components/Common/DeleteModal';

import {
    getUsers, deleteUser as onDeleteUser
} from "store/actions";

import {
    Name, Role, SelectUser, FullName
} from "./columns";

//redux
import { useSelector, useDispatch } from "react-redux";

import {
    Card,
    CardBody
} from "reactstrap";
import AddUser from "../add";

function UserListView() {

    const [modal, setModal] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [deleteModal, setDeleteModal] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { users, isLoading } = useSelector(state => ({
        users: state.Users.users,
        isLoading: state.Users.isLoading,

    }));

    const toggle = () => {
        if (modal) {
            setModal(false);
        } else {
            setModal(true);
        }
    };

    const deleteUser = () => {
        dispatch(onDeleteUser(selectedUser.id));
    }

    const handleDeleteUser = (user) => {
        setSelectedUser(user);
        setDeleteModal(true);
    };

    const handleAdduser = () => {
        setIsEdit(false);
        setSelectedUser(null);
        toggle();
    };

    const handleEditUser = (user) => {
        setIsEdit(true);
        setSelectedUser(user);
        toggle();
    }

    const columns = useMemo(
        () => [
            {
                Header: '',
                accessor: 'id',
                disableFilters: true,
                Cell: (cellProps) => {
                    return <SelectUser {...cellProps} />;
                }
            },
            {
                Header: 'Noms complet',
                disableFilters: true,
                Cell: (cellProps) => {
                    return <FullName {...cellProps} />;
                }
            },
            {
                Header: 'Nom',
                accessor: 'name',
                disableFilters: true,
                Cell: (cellProps) => {
                    return <Name {...cellProps} />;
                }
            },
            {
                Header: 'Prénoms',
                accessor: 'firstName',
                disableFilters: true,
                Cell: (cellProps) => {
                    return <Name {...cellProps} />;
                }
            },
            {
                Header: 'Email',
                accessor: 'email',
                disableFilters: true,
                Cell: (cellProps) => {
                    return <Name {...cellProps} />;
                }
            },
            {
                Header: 'Fonction',
                accessor: 'jobTitle',
                disableFilters: true,
                Cell: (cellProps) => {
                    return <Name {...cellProps} />;
                }
            },
            {
                Header: 'Dernière connexion',
                accessor: 'lastLogin',
                disableFilters: true,
                Cell: (cellProps) => {
                    return <Name {...cellProps} />;
                }
            },
            {
                Header: 'Role',
                accessor: 'roles',
                disableFilters: true,
                Cell: (cellProps) => {
                    return <Role {...cellProps} />;
                }
            },
            {
                Header: '',
                accessor: 'action',
                disableFilters: true,
                Cell: (cellProps) => {
                    return <div>
                        <div className="hstack gap-1">
                            <Link onClick={() => handleDeleteUser(cellProps.row.original)}><i className="mdi mdi-close-circle text-danger font-size-20" /></Link>
                            <Link onClick={() => handleEditUser(cellProps.row.original)}><i className="mdi mdi-pencil-circle text-info font-size-20" /></Link>
                        </div>
                    </div>;
                }
            }
        ],
        []
    );

    const onRefresh = () => {
        dispatch(getUsers())
    }

    useEffect(() => {
        onRefresh();
    }, [dispatch]);

    return (
        <React.Fragment>
            <DeleteModal
                show={deleteModal}
                onDeleteClick={deleteUser}
                onCloseClick={() => setDeleteModal(false)}
                text={"Voulez-vraiment supprimer cet utilisateur ?"}
            />
            <AddUser isOpen={modal} onCloseClick={toggle} isEdit={isEdit} user={selectedUser} />
            <Card className="datasources-panel">
                <CardBody>
                    <TableContainer
                        columns={columns}
                        data={users}
                        isGlobalFilter={true}
                        isAddOptions={false}
                        handleAddClicks={handleAdduser}
                        addButtonText="Nouveau"
                        isDatasourceListGlobalFilter={false}
                        isGenericAdd={true}
                        customPageSize={10}
                        onRefresh={onRefresh}
                    />
                </CardBody>
            </Card>
        </React.Fragment>
    );
}


export default UserListView;