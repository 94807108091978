import { ResourceTypes } from "constants/general";
import React, { } from "react";
import { withTranslation } from "react-i18next";
import { Badge } from "reactstrap";

const ResourceCard = ({ name, description, logo, icon, modelName, type, t }) => {

    return logo ? (<div className="d-flex flex-row">
        <img className=" p-1 bg- bg-danger rounded-circle avatar-circle"
            src={logo} width={40} height={40} />
        <div className="vstack ms-2 ">
            <div className="hstack gap-1">
                <span className="fw- font-size-14 text-white  mt-1">{name}    </span>
                {modelName && <span className="font-size-10 text-muted">
                    {/* <Badge color="success" className="font-size-10 me-1">{type === ResourceTypes.AGENT_ASSISTANT ? t("Analyse avancée") : t("Recherche d'informations")}</Badge> */}
                    <Badge color="danger" className="ms-1 font-size-10 mt-1 me-1">Powered by  {modelName}</Badge>
                </span>}
            </div>
            <span className="text-white text- font-size-10"> {description}</span>
        </div>
    </div>) : (<div className="hstack gap-2 p-1">
        <div className="mb-3">{icon}</div>
        <div className="vstack ">
            <span className="fw-bold font-size-14 text-white ">{name}</span>
            <p className="text-white text-muted"> {description}</p>
        </div>
    </div>)
}


export default withTranslation()(ResourceCard)