import React from 'react';
import { Badge } from 'reactstrap';
import { Link } from 'react-router-dom';
import { getInitiales, getItemType, getProviderLogo } from 'utils';
import { RotatingLines } from 'react-loader-spinner';
import { map } from 'lodash';
import { toDate } from 'helpers/to-local-date';


const SelectUser = (cell) => {
    return <div className="form-check font-size-16">
        <input className="form-check-input" type="checkbox" id={"datasource-" + cell.value} />
        <label className="form-check-label" htmlFor="upcomingtaskCheck01"></label>
    </div>
}

const Name = (cell) => {
    return <Link className='font-size-14 text-muted'>
        {cell.value ? cell.value : ""}
    </Link>
};

const Date = (cell) => {
    return <Link className='font-size-14 text-muted'>
        {cell.value ? toDate(cell.value) : ""}
    </Link>
};


const Role = (cell) => {
    const roles = cell.row.original.roles;
    return <div className='hstack gap-1'>
        {map(roles, role => {
            return <Badge className="badge-soft-info font-size-12">{role?.name}</Badge>
        })}
    </div>
};

const Members = (cell) => {
    const members = cell.row.original.members;
    return <Link className='font-size-14 '>
        {members.length > 0 ? members.length + " utilisateurs" : "Aucun"}
    </Link>
};

const GroupName = (cell) => {

    return <div className='hstack'>
        <div className="avatar-xs me-3">
            <span className="avatar-title rounded-circle bg-info bg-soft text-info font-size-18">
                {getInitiales(cell.value || "")}
            </span>
        </div>
        <span>{cell.value}</span>

    </div>
}


const Scope = (cell) => {


    switch (cell.value) {

        case "private":
            return <Badge className="badge-soft-info">Personal</Badge>
        default:
            return <Badge className="badge-soft-info">Organization</Badge>
    }
};




export { Name, Role, Scope, SelectUser, Date, Members, GroupName };