import React, { memo, useCallback, useEffect, useState } from 'react';
import Application from './application';
import Search from './search';
import CustomApp from './custom';
import Knowlege from './knowledge';
import Assistant from './assistant';
import Prompt from './prompt';
import Context from './context';
import Loop from './foreach';
import Split from './split';

import { useSelector } from "react-redux";

const DisplayNode = ({ llms, data }) => {

    const onChange = useCallback((item) => {
        data.onConfigure(item);
    }, []);

    switch (data.type) {

        case 'App':
            return <Application llms={llms} data={data} onChange={onChange} />

        case 'Search':
            return <Search llms={llms} data={data} onChange={onChange} />

        case 'Custom':
            return <CustomApp llms={llms} data={data} onChange={onChange} />

        case 'Assistant':
            return <Assistant llms={llms} data={data} onChange={onChange} />

        case 'knowledge':
            return <Knowlege llms={llms} data={data} onChange={onChange} />

        case 'promptStore':
            return <Prompt llms={llms} data={data} onChange={onChange} />

        case 'context':
            return <Context llms={llms} data={data} onChange={onChange} />

        case 'loop':
            return <Loop llms={llms} data={data} onChange={onChange} />

        case 'split':
            return <Split llms={llms} data={data} onChange={onChange} />
    }
}

const BoardNode = (props) => {

    const [llms, setLLMs] = useState([]);

    const { models } = useSelector(state => ({
        models: state.Assistant.models
    }));

    useEffect(() => {
        setLLMs((models || []).map(model => ({
            ...model,
            id: model.id,
            title: model.name,
            description: model.description,
            provider: model.provider
        })));

    }, [models])

    return <DisplayNode llms={llms} {...props} />
}

export default BoardNode;