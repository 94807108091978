import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
    Table,
    DropdownMenu,
    DropdownToggle,
    UncontrolledDropdown,
    DropdownItem,
    Card,
    CardBody,
    Button,
} from "reactstrap"

const FileListPlaceHolders = ({ ancestors }) => {


    return (

        <div className="recentFiles">
            <div className="d-flex flex-wrap">
                {ancestors.map(item => {
                    return <div key={item.location} className="hstack me-1 ">
                        {item.level > 0 && <span>/</span>}
                        <Link className="ms-1">{item.name || "Mes Documents"}</Link>
                    </div>
                })}

                <div className="ms-auto">
                    <Link className=" "><i className=" font-size-20 mdi mdi-folder-plus text-warning me-1" /> </Link>
                    <Link className=" "><i className=" font-size-20 mdi mdi-comment-search me-1" />   </Link>
                    <Link className=" "><i className=" font-size-20 mdi mdi-apps me-1" />   </Link>
                    <Link className=" "><i className=" font-size-20 mdi mdi-cog-sync me-1" />   </Link>
                    <Link className=" "><i className=" font-size-20 mdi mdi-dots-horizontal me-1" />   </Link>

                </div>
            </div>
            <hr className="mt-2" />

            <div className="table-responsive">
                <Table className="table align-middle table-nowrap table-hover mb-0">
                    <thead>
                        <tr>
                            <th scope="col">Nom</th>
                            <th scope="col" >Date de création</th>
                            <th scope="col">Dernière modification</th>
                            <th scope="col" colSpan="2">
                                Taille
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr className="placeholder-glow">
                            <td><span className="placeholder col-12"></span></td>
                            <td><span className="placeholder col-6"></span></td>
                            <td><span className="placeholder col-6"></span></td>
                            <td><span className="placeholder col-4"></span></td>
                            <td><span className="placeholder col-4"></span></td>

                        </tr>

                        <tr className="placeholder-glow">
                            <td><span className="placeholder col-12"></span></td>
                            <td><span className="placeholder col-6"></span></td>
                            <td><span className="placeholder col-6"></span></td>
                            <td><span className="placeholder col-4"></span></td>
                            <td><span className="placeholder col-4"></span></td>

                        </tr>

                        <tr className="placeholder-glow">
                            <td><span className="placeholder col-12"></span></td>
                            <td><span className="placeholder col-6"></span></td>
                            <td><span className="placeholder col-6"></span></td>
                            <td><span className="placeholder col-4"></span></td>
                            <td><span className="placeholder col-4"></span></td>

                        </tr>
                        <tr className="placeholder-glow">
                            <td><span className="placeholder col-12"></span></td>
                            <td><span className="placeholder col-6"></span></td>
                            <td><span className="placeholder col-6"></span></td>
                            <td><span className="placeholder col-4"></span></td>
                            <td><span className="placeholder col-4"></span></td>

                        </tr>

                        <tr className="placeholder-glow">
                            <td><span className="placeholder col-12"></span></td>
                            <td><span className="placeholder col-6"></span></td>
                            <td><span className="placeholder col-6"></span></td>
                            <td><span className="placeholder col-4"></span></td>
                            <td><span className="placeholder col-4"></span></td>

                        </tr>

                    </tbody>
                </Table>
            </div>
        </div>

    )
}

export default FileListPlaceHolders;
