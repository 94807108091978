
//Azure Backend
import { PublicClientApplication, EventType } from "@azure/msal-browser";
import { msalConfigB2C, msalConfig } from "helpers/auth/azureadb2c"

// const msalInstance = new PublicClientApplication(msalConfig);
const msalInstance = new PublicClientApplication(msalConfigB2C);



// Default to using the first account if no account is active on page load
if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
    // Account selection logic is app dependent. Adjust as needed for different use cases.
    msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
}

msalInstance.addEventCallback((event) => {
    if (
        (event.eventType === EventType.LOGIN_SUCCESS ||
            event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
            event.eventType === EventType.SSO_SILENT_SUCCESS) &&
        event.payload.account
    ) {

        console.log("LOGIN SUCCESS ", event.payload.account);
        msalInstance.setActiveAccount(event.payload.account);
    }
});


await msalInstance.initialize()

export {
    msalInstance
}