import React, { useEffect, useState, useRef, useMemo } from "react";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import Select from "react-select"
import {
    Card,
    CardBody,
    Col,
    Container,
    Row,
    Modal,
    ModalHeader,
    ModalBody,
    Label,
    FormFeedback,
    UncontrolledTooltip,
    Input,
    Form,
} from "reactstrap";

import {
    getUsers as onGetUsers,
    addNewUser as onAddNewUser,
    updateUser as onUpdateUser,
    deleteUser as onDeleteUser,
    getRoles,
} from "store/actions";
import { useSelector, useDispatch } from "react-redux";
import { isEmpty, map } from "lodash";


const AddUser = ({ isOpen, onCloseClick, isEdit, user }) => {

    const [roleNames, setRolesNames] = useState([]);
    const [selectRoles, setSelectedRoles] = useState([]);
    const dispatch = useDispatch();

    const { roles, isLoading } = useSelector(state => ({
        roles: state.Users.roles,
        isLoading: state.Users.isLoading,

    }));

    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            name: (user && user.name) || "",
            firstName: (user && user.firstName) || "",
            roles: (user && user.roles) || "",
            email: (user && user.email) || "",
            jobTitle: (user && user.jobTitle) || "",
            // password: (user && user.password) || "",

        },
        validationSchema: Yup.object({
            name: Yup.string().required("Champs obligatoire"),
            firstName: Yup.string().required("Champs obligatoire"),
            email: Yup.string().matches(
                /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                "Champs obligatoire"
            ).required("Champs obligatoire"),
            jobTitle: Yup.string().required("Champs obligatoire"),
            // password: Yup.string().required("Champs obligatoire"),

        }),
        onSubmit: values => {
            if (isEdit) {
                const updateUser = {
                    id: user.id,
                    name: values.name,
                    firstName: values.firstName,
                    roles: values.roles,
                    email: values.email,
                    jobTitle: values.jobTitle,
                    password: values.password,
                    roles: selectRoles.map(s => ({ id: s.value, name: s.label })),
                };
                // update user
                dispatch(onUpdateUser(updateUser));
                validation.resetForm();

            } else {
                const newUser = {
                    name: values["name"],
                    firstName: values["firstName"],
                    email: values["email"],
                    roles: selectRoles.map(s => ({ id: s.value, name: s.label })),
                    jobTitle: values["jobTitle"],
                    password: values["password"]

                };
                // save new user
                dispatch(onAddNewUser(newUser));
                // validation.resetForm();
            }
            // onCloseClick();
        },
    });

    useEffect(() => {
        if (isEmpty(roles))
            dispatch(getRoles())
    }, [dispatch])

    useEffect(() => {
        setRolesNames((roles || []).map(role => ({
            value: role.id,
            label: role.name
        })));

    }, [roles]);


    useEffect(() => {
        if (user) {
            setSelectedRoles(user.roles.map(role => (
                {
                    value: role.id,
                    label: role.name
                })
            ));
        } else {

            setSelectedRoles([]);
        }
    }, [user])

    return <Modal isOpen={isOpen} toggle={onCloseClick} centered={true} size="lg"
        unmountOnClose={true} >
        <ModalHeader toggle={onCloseClick} tag="h4">
            {!!isEdit ? "Editer " : "Ajouter un utilisateur"}
        </ModalHeader>
        <ModalBody>
            <Card>
                <CardBody>

                    <Form
                        onSubmit={e => {
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                        }}
                    >
                        <Row>
                            <Col xs={6}>
                                <div className="mb-3">
                                    <Label className="form-label">Nom</Label>
                                    <Input
                                        name="name"
                                        type="text"
                                        placeholder="Inserer le nom"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.name || ""}
                                        invalid={
                                            validation.touched.name &&
                                                validation.errors.name
                                                ? true
                                                : false
                                        }
                                    />
                                    {validation.touched.name &&
                                        validation.errors.name ? (
                                        <FormFeedback type="invalid">
                                            {validation.errors.name}
                                        </FormFeedback>
                                    ) : null}
                                </div>
                            </Col>
                            <Col xs={6}>
                                <div className="mb-3">
                                    <Label className="form-label">Prénoms</Label>
                                    <Input
                                        name="firstName"
                                        label="firstName"
                                        placeholder="insérer le ou les prénom(s)"
                                        type="text"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.firstName || ""}
                                        invalid={
                                            validation.touched.firstName &&
                                                validation.errors.firstName
                                                ? true
                                                : false
                                        }
                                    />
                                    {validation.touched.firstName &&
                                        validation.errors.firstName ? (
                                        <FormFeedback type="invalid">
                                            {validation.errors.firstName}
                                        </FormFeedback>
                                    ) : null}
                                </div>

                            </Col>
                        </Row>

                        <Row>
                            <Col xs={6}>
                                <div className="mb-3">
                                    <Label className="form-label">Poste</Label>
                                    <Input
                                        name="jobTitle"
                                        label="jobTitle"
                                        type="text"
                                        placeholder="inserer le poste"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.jobTitle || ""}
                                        invalid={
                                            validation.touched.jobTitle &&
                                                validation.errors.jobTitle
                                                ? true
                                                : false
                                        }
                                    />
                                    {validation.touched.jobTitle &&
                                        validation.errors.jobTitle ? (
                                        <FormFeedback type="invalid">
                                            {validation.errors.jobTitle}
                                        </FormFeedback>
                                    ) : null}
                                </div>



                            </Col>
                            <Col xs={6}>


                                <div className="mb-3">
                                    <Label className="form-label">Email</Label>
                                    <Input
                                        name="email"
                                        label="Email"
                                        type="email"
                                        placeholder="Inserer l'email"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.email || ""}
                                        invalid={
                                            validation.touched.email &&
                                                validation.errors.email
                                                ? true
                                                : false
                                        }
                                    />
                                    {validation.touched.email &&
                                        validation.errors.email ? (
                                        <FormFeedback type="invalid">
                                            {validation.errors.email}
                                        </FormFeedback>
                                    ) : null}
                                </div>


                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <div className="mb-3">
                                    <Label className="form-label">Roles</Label>
                                    <div>
                                        <Select
                                            options={roleNames}
                                            className="select2-selection"
                                            value={selectRoles}
                                            onChange={setSelectedRoles}
                                            isMulti
                                        />
                                    </div>
                                    {/* <Input
                                type="select"
                                name="roles"
                                className="form-select"
                                multiple={true}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.roles || []}
                                invalid={
                                    validation.touched.roles &&
                                        validation.errors.roles
                                        ? true
                                        : false
                                }
                            >

                                {map(roles, role => {
                                    return <option value={role.id}>{role.name}</option>
                                })}

                            </Input>
                            {validation.touched.roles &&
                                validation.errors.roles ? (
                                <FormFeedback type="invalid">
                                    {validation.errors.roles}
                                </FormFeedback>
                            ) : null} */}
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div className="text-end">
                                    <button
                                        type="submit"
                                        className="btn btn-success save-user"
                                    >
                                        Save
                                    </button>
                                </div>
                            </Col>
                        </Row>
                    </Form>
                </CardBody>

            </Card>
        </ModalBody>
    </Modal>
}

export default AddUser;