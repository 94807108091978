export const EventTypes = {
    START: "start",
    STREAMING: "streaming",
    CONTEXT: "context",
    CONVERSATION_RENAMED: "conversationTitleRenamed",
    PROGRESS: "progress",
    END: "end",
    ERROR: "error",
    STEP_START: "stepStart",
    STEP_END: "stepEnd",
    FILE_START: "fileStart",
    FILE_END: "fileEnd",
    LOADING_FILE: "loadingFIle",
    TASK_START: "taskStart",
    TASK_END: "taskEnd",
    APPLICATION_LINEAGE: "workflowLineage",
    CONTENT_RECOMMANDATIONS: "recommendations",
    MESSAGE_RECEIVED: "messageReceived",
    ASSESSMENT: "assessment",
    SUGGESTIONS: "suggestions",
    SEARCH_RESULT: "search",
    SEARCH_FILTERS: "search_filters",
    SEARCH_CONTEXT: "search_context",


}