import React, { memo, ReactNode, useCallback, useEffect, useState } from 'react';
import { Handle, Position } from '@xyflow/react';
import { Link } from "react-router-dom";
import { Badge, Card, CardBody, CardFooter, CardTitle, Collapse, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import classnames from "classnames";
import { isEmpty, map, truncate } from 'lodash';
import SingleHandle from '../../handle/SingleHandle';

const inputTypes = [
    {
        id: 'all',
        title: 'Texte et fichiers ',
        description: 'Concatenation du texte et du contenu des fichiers'
    },
    {
        id: 'textOnly',
        title: 'Texte',
        description: 'Appliquer les instructions le texte fourni'
    },
    {
        id: 'fileOnly',
        title: 'Fichiers',
        description: 'Appliquer les instructions sur chaque fichier uniquement'
    },
    {
        id: 'rowOnly',
        title: 'Ligne par ligne',
        description: 'Appliquer les instructions sur chaque ligne'
    },
]


const getStatusClassName = (status) => {

    if (!status)
        return "text-danger";
    else {
        return "text-success";
    }
}

const CustomAppNode = ({ data, onChange, type }) => {
    const [collapsed, setCollapsed] = useState(false);
    const [nodeName, setNodeName] = useState(data.name);

    return <Card>
        <div className="accordion accordion-flush" id="accordion">
            <div className="accordion-item">
                <div className="accordion-header" id="headingOne">
                    <button
                        className={classnames(
                            "accordion-button",
                            "fw-medium",
                            { collapsed: !collapsed }
                        )}
                        type="button"
                        onClick={() => setCollapsed(!collapsed)}
                        style={{ cursor: "pointer" }}
                    >
                        <div className='vstack'>
                            <div className="title hstack gap-0 ">
                                {data?.logo ? <img className='rounded-circle avatar-circle' width={40} height={50} src={data?.logo} /> : <i className={"fa fa-solid fa-code font-size-16"} />}
                                <input className="border-0 form-control" readOnly style={{ background: 'transparent' }} type='text' value={nodeName} />
                            </div>
                            <div className="subline ms-3">
                                <div className="text-success ms-4"><small > Valide </small><i className='mdi mdi-check-circle' /></div>
                            </div>
                        </div>


                    </button>
                </div>

                <Collapse isOpen={collapsed}>

                    <div className="accordion-body">
                        <div className='mb-2'>
                            {/* <p>
                                {data.description}
                            </p> */}
                            <p className='text-wrap' >{truncate(data.description, { length: 300 })}</p>

                        </div>

                    </div>
                </Collapse>
            </div>
            <SingleHandle nodeId={data?.id} type="target" position={Position.Left} />
            <SingleHandle nodeId={data?.id} type="source" position={Position.Right} />
        </div>
        <CardFooter className='border-top'>
            <div className='d-flex justify-content-between'>
                <div>
                    <Badge className='badge badge-soft-info'>Crée par  {data.owner}</Badge>
                </div>
            </div>
        </CardFooter>
    </Card>
}

export default memo(CustomAppNode);