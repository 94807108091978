import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { CREATE_STUDIO_APP, DEL_STUDIO_APP, GET_STUDIO_APP, getS } from "./actionTypes"
import { addStudioAppFailed, addStudioAppSuccess, delStudioAppFailed, delStudioAppSuccess, getStudioAppsFailed, getStudioAppsSuccess } from "./actions"

//Include Both Helper File with needed methods
import { getStudioApps as onGetStudioApps, createStudioApp as onCreateStudio, delStudioApp as onDelStudioApp } from "helpers/backend_helper"

function* loadStudioApps(action) {
  try {
    const response = yield call(onGetStudioApps, action.payload)
    yield put(getStudioAppsSuccess(response))
  } catch (error) {
    yield put(getStudioAppsFailed(error))
  }
}

function* addStudioApp(action) {
  try {
    console.log(action)
    const response = yield call(onCreateStudio, action.payload)
    yield put(addStudioAppSuccess(response))
  } catch (error) {
    yield put(addStudioAppFailed(error))
  }
}

function* delStudioApp(action) {
  try {
    const response = yield call(onDelStudioApp, action.payload)
    yield put(delStudioAppSuccess(response))
  } catch (error) {
    yield put(delStudioAppFailed(error))
  }
}


function* studioSaga() {

  yield takeEvery(GET_STUDIO_APP, loadStudioApps)
  yield takeEvery(CREATE_STUDIO_APP, addStudioApp)
  yield takeEvery(DEL_STUDIO_APP, delStudioApp)
}

export default studioSaga;