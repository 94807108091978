import React from "react"
import { Link } from "react-router-dom"
import {
  Table,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  DropdownItem,
  UncontrolledTooltip,
  Spinner
} from "reactstrap"
import SimpleBar from "simplebar-react";
import { getFileIcon } from "utils";


const WebSelection = ({ resources, onSelected, isLoading }) => {

  return (
    <React.Fragment>
      <div className="border p-4 ">
        <div className="d-flex flex-wrap justify-content-between">
          <h5 className="font-size-16 me-3"> <i className="mdi mdi-link font-size-20 me-2" />Ressources trouvées ({resources.length})</h5>
          {isLoading && <Spinner />}
        </div>

        <div className="ms-4">
          <small className="text-muted">{resources.filter(i => i.selected).length} selectionnées</small>
        </div>
        <hr className="mt-2" />

        <div className="table-responsive">
          <SimpleBar className="scroller">
            <Table className="table align-middle table-nowrap table-hover mb-0">
              <tbody>
                {resources.map((item, key) => (
                  <tr key={key}>
                    <td>
                      <div className="hstack gap-1">
                        <input type="checkbox" checked={item.selected} className="font-size-24" onClick={e => onSelected(item.link)} />
                        {getFileIcon(item.link, 20, < i className="mdi mdi-web font-size-20" />)}
                        <a href={item.link} target="_blank" rel="noopener noreferrer" className="text-muted fw-medium">
                          {item.link}</a>
                      </div>
                    </td>

                  </tr>
                ))}
              </tbody>
            </Table>
          </SimpleBar>
        </div>

      </div>
    </React.Fragment >
  )
}

export default WebSelection;
