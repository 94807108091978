import React, { useEffect, useState } from "react"
import { isEmpty, map } from "lodash";
import ReactApexChart from "react-apexcharts";
import { Card, CardBody, CardTitle, Col, Row } from "reactstrap";
import { truncateFromMiddle } from "utils";
import SimpleBar from "simplebar-react";
import getChartColorsArray from "components/Common/ChartsDynamicColor";
import FeedbackProfile from "../profile/feedback"
import { withTranslation } from "react-i18next";

const PieChart = ({ data, title }) => {

    const values = (data || []).slice(0, 10).map(i => i.totalRequests);
    const labels = (data || []).slice(0, 10).map(i => truncateFromMiddle(i.principalName, 10));

    const options = {
        labels: labels,
        legend: { show: 1 },
        plotOptions: {
            pie: {
                donut: {
                    size: "60%",
                },
            },
        },
    };
    return <Card className="border">
        <CardTitle className="pt-3 ps-4 pb-2 border-bottom">
            <h5 className="text-muted">{title}</h5>
        </CardTitle>
        <CardBody>

            <div id="donut-chart">
                <ReactApexChart
                    options={options}
                    series={values}
                    type="donut"
                    height={350}
                    className="apex-charts"
                />
            </div>
        </CardBody>
    </Card>
}

const UsageOverTime = ({ board, t }) => {
    const [series, setSeries] = useState([]);
    const [labels, setLabels] = useState([]);

    const colors = getChartColorsArray('[ "--bs-success", "--bs-danger"]')
    const options = {
        chart: {
            height: 380,
            type: 'bar',
            zoom: {
                enabled: false
            },
            toolbar: {
                show: false
            }
        },
        // colors: apaexlineChartColors,
        dataLabels: {
            enabled: false,
        },
        stroke: {
            width: [3, 3],
            curve: 'straight'
        },
        title: {
            text: 'Distribution des feedbacks',
            align: 'left',
            style: {
                fontWeight: '50',
            },
        },
        grid: {
            row: {
                colors: ['transparent', 'transparent'], // takes an array which will be repeated on columns
                opacity: 1
            },
            borderColor: '#f1f1f1'
        },
        markers: {
            style: 'inverted',
            size: 6
        },
        xaxis: {
            categories: labels,
            title: {
                text: 'Date',
                offsetY: 100,
            }
        },
        yaxis: {
            title: {
                text: 'Nombre de requêtes'
            },
            min: 0,
            max: 100
        },
        colors: colors,

        legend: {
            position: 'top',
            horizontalAlign: 'right',
            floating: true,
            offsetY: -35,
            offsetX: -5
        },
        responsive: [{
            breakpoint: 600,
            options: {
                chart: {
                    toolbar: {
                        show: false
                    }
                },
                legend: {
                    show: false
                },
            }
        }]
    }

    useEffect(() => {
        if (isEmpty(board?.distribution))
            return;


        setSeries([
            {
                name: "Postive (Imp + Exp)",
                data: board.distribution.map(i => i.totalRequests)
            },
            {
                name: "Negative",
                data: board.distribution.map(i => i.totalNegative)
            }
        ]);
        setLabels(board.distribution.map(i => new Date(i.id).toLocaleDateString("fr-FR")))



    }, [board])

    return <Col md={6}>
        <div className="card-item">
            <SimpleBar className="scroller-graph">
                <Row>
                    <Col md={12}>
                        <Card>
                            <CardBody>
                                <ReactApexChart
                                    options={options}
                                    series={series}
                                    type="bar"
                                    height="350"
                                    className="apex-charts"
                                />
                            </CardBody>
                        </Card>

                    </Col>
                </Row>
                <div className="p-3">
                    <Card>

                        <h5>
                            <i className="bx bxs-right-arrow-circle font-size-16 bx-fade-right me-2 text-primary" />
                            {t("Feedbacks les plus récents")}</h5>
                        {map(board.latestFeedbacks, item => {

                            return <FeedbackProfile item={item} />
                        })}
                    </Card>

                </div>

            </SimpleBar>
        </div>

    </Col>
}

export default withTranslation()(UsageOverTime);