import React, { Fragment, useEffect, useState } from "react"
import classNames from "classnames";
import SimpleBar from "simplebar-react";
import { GET_BOARD_EXEC_PLAN } from "helpers/url_helper";
import { post } from "helpers/api_helper";
import { isEmpty, map } from "lodash";
import { Link } from "react-router-dom";
import { Badge, Collapse, Progress, Spinner } from "reactstrap";
import { getFileIcon, truncateFromMiddle } from "utils";

const AppLineage = ({ app, selectedFile, onFileSelected, isRunning, directory, files, progress }) => {

    useEffect(() => {
        if (isRunning)
            onFileSelected(files[0]);
    }, [isRunning]);




    return <div className="board-execution-plan ">
        <div className="mb-2 p-3  bg-primary rounded ">
            <div className="hstack gap-1">
                <h6 className="mt-1 text-white font-size-14 fw-bold">{files?.length} fichiers  à traiter </h6>
                <small>({progress}/{files?.length} traités) </small>
            </div>
        </div>

        <ul className="list-unstyled  ">

            <SimpleBar className="scroller">

                {map(files || [], (item, index) => {

                    const current = selectedFile?.location === item.location;

                    return <li key={item.location} className={current ? "active" : ""} >
                        <Link onClick={() => onFileSelected(item)}>
                            <div className="d-flex justify-content-between">
                                <div className="d-flex flex-column">
                                    <div className="hstack gap-1">
                                        {getFileIcon(item.name, 18)}
                                        <small className="font-size-10">{truncateFromMiddle(item.name, 30)}</small>
                                    </div>
                                </div>
                                {item.isRunning === true && <Spinner className="ms-2" color="primary" size="sm" />
                                }
                                {!item.isRunning && item.status === 'success' && <div>
                                    {/* <Badge color="success">Succès</Badge> */}
                                    <i className="mdi mdi-check-circle text-success font-size-16" />
                                </div>}
                            </div>

                        </Link>

                    </li>

                })}


            </SimpleBar>
        </ul>

    </div>

}

export default AppLineage;