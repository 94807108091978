import React from "react";
import {
    Container
} from "reactstrap";
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb2";
import EmptyBox from "assets/images/emptyBox.png"
import { withTranslation } from "react-i18next";

const EmptyTodoList = ({ onCreate }) => {

    //meta title
    document.title = "Mes taches | Jask";

    return (
        <div className="emptytasks">


            <div className="d-flex flex-column h-100   align-items-center justify-content-center ">
                <img src={EmptyBox} width={200} height={200} />
                <h5>Vous n'avez aucune tache </h5>
                <button onClick={onCreate} className="btn btn-info mt-2">Créer votre premier tache</button>
            </div>
        </div>
    );
};

export default withTranslation()(EmptyTodoList);