export const GET_DATASOURCE_DOMAINS_LIST = "GET_DATASOURCE_DOMAINS_LIST"
export const GET_DATASOURCE_DOMAINS_LIST_SUCCESS = "GET_DATASOURCE_DOMAINS_LIST_SUCCESS"
export const GET_DATASOURCE_DOMAINS_LIST_ERROR = "GET_DATASOURCE_DOMAINS_LIST_ERROR"

export const GET_DATASOURCE_LIST = "GET_DATASOURCELIST"
export const GET_DATASOURCE_LIST_FAIL = "GET_DATASOURCE_LIST_FAIL"
export const GET_DATASOURCE_LIST_SUCCESS = "GET_DATASOURCE_LIST_SUCCESS"
export const ADD_NEW_DATASOURCE_LIST = "ADD_NEW_DATASOURCE_LIST"
export const ADD_DATASOURCE_LIST_SUCCESS = "ADD_DATASOURCE_LIST_SUCCESS"
export const ADD_DATASOURCE_LIST_FAIL = "ADD_DATASOURCE_LIST_FAIL"
export const UPDATE_DATASOURCE_LIST = "UPDATE_DATASOURCE_LIST"
export const UPDATE_DATASOURCE_LIST_SUCCESS = "UPDATE_DATASOURCE_LIST_SUCCESS"
export const UPDATE_DATASOURCE_LIST_FAIL = "UPDATE_DATASOURCE_LIST_FAIL"
export const DELETE_DATASOURCE_LIST = "DELETE_DATASOURCE_LIST"
export const DELETE_DATASOURCE_LIST_SUCCESS = "DELETE_DATASOURCE_LIST_SUCCESS"
export const DELETE_DATASOURCE_LIST_FAIL = "DELETE_DATASOURCE_LIST_FAIL"
export const GET_APPLY_DATASOURCE = "GET_APPLY_DATASOURCE"
export const GET_APPLY_DATASOURCE_SUCCESS = "GET_APPLY_DATASOURCE_SUCCESS"
export const GET_APPLY_DATASOURCE_FAIL = "GET_APPLY_DATASOURCE_FAIL"
export const DELETE_APPLY_DATASOURCE = "DELETE_APPLY_DATASOURCE"
export const DELETE_APPLY_DATASOURCE_SUCCESS = "DELETE_APPLY_DATASOURCE_SUCCESS"
export const DELETE_APPLY_DATASOURCE_FAIL = "DELETE_APPLY_DATASOURCE_FAIL"
export const STOP_CHECKING_INDEX = "STOP_CHECKING_INDEX"
