import React from "react";
import {
    Container
} from "reactstrap";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb2";
import MonitoringBoard from "./board"
//i18n
import { withTranslation } from "react-i18next";
const LegalBoard = props => {

    //meta title
    document.title = "Suivi des usages | Jask";

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {/* <Breadcrumbs
                        title={props.t("Tableau de bord")}
                        breadcrumbItems={[
                            {
                                title: "Jask"
                            },
                            {
                                title: "Usages"
                            },
                            {
                                title: "Tableau de bord"
                            }
                        ]}
                    /> */}

                    <MonitoringBoard />
                </Container>
            </div>
        </React.Fragment>
    );
};


export default withTranslation()(LegalBoard);
