import React, { useEffect, useState } from "react"
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import reHype from 'remark-rehype'

import DocumentGeneratorPlacerHolders from "./placeholders"
import DocumentStructure from "./components/plan"
import DocumentContent from "./components/mainContent"

import {
    Card,
    CardBody,
} from "reactstrap"
import { generateContentStructure } from "service/documents"


const RichTextEditor = ({ task }) => {

    const [isLoading, setIsLoading] = useState(false);
    const [summary, setSummary] = useState(false);
    const [structure, setStruture] = useState(null);

    const onStart = () => {
        setSummary("");
        setIsLoading(false);
    }

    const onEnd = (final) => {
    }

    const onReceive = token => {
        setSummary(prev => prev + token);
    }

    const onStructureReceived = structure => {
        setStruture(structure);
    }

    useEffect(() => {

        if (!task?.id || summary) {
            return;
        }

        setIsLoading(true);
        generateContentStructure(task, onReceive, onStructureReceived, onStart, onEnd);

    }, [task])



    return <Card>
        <CardBody>
            <div className="me-2">

                <div className="">
                    {isLoading && <DocumentGeneratorPlacerHolders />}
                    {!isLoading && summary && !structure &&
                        <ReactMarkdown className="markdown" remarkPlugins={[remarkGfm, reHype]}>{summary}</ReactMarkdown>
                    }
                    {!isLoading && structure &&
                        <div className="d-flex">
                            <DocumentStructure structure={structure} />
                            <DocumentContent task={task} structure={structure} />
                        </div>
                    }
                </div>

            </div>
        </CardBody>

    </Card>
}

export default RichTextEditor;
