import { isEmpty, map } from "lodash";
import React, { Fragment, memo, useEffect, useState } from "react"
import { Link } from "react-router-dom";
import { Card, Col, Input, Row } from "reactstrap";
import SimpleBar from "simplebar-react";
import Select from "react-select"
import { uuidv4 } from "utils";
import { createFilesFromText } from "service/documents";
import { operationSucceed } from "utils/notifications";
import { OperationStatus } from "constants/tasks";
import DocumentBrowser from "components/Common/DocumentBrowser";

const fileTypes = [{
    label: "Texte",
    value: "text",
    extension: "txt"
},
{
    label: "Fichier Word",
    value: "word",
    extension: "docx"
}
]


const SaveDocumentView = ({ data, directory, onConfigChange }) => {

    const [folderLocation, setFolderLocation] = useState(null);
    const [items, setItems] = useState([]);
    const [outputFormat, setOutputFormat] = useState(fileTypes[0]);
    const [showBrowser, setShowBrowser] = useState(false);

    const toggle = () => {
        setShowBrowser(!showBrowser)
    }
    const UpdateFileName = (file, value) => {
        const files = [...items];
        files.forEach(f => {
            if (f.location === file.location) {
                file.name = value;
            }
        });

        setItems(files);
    }

    const onSelectedPath = (path) => {
        setFolderLocation({
            name: path.name,
            location: path.location
        });
        toggle();
    }

    const onSubmit = (config) => {
        const files = config.files.map(f => ({
            ...f, format: config.output
        }));

        onConfigChange({ ...config, isLoading: true });

        createFilesFromText(config.directory, files).then(resp => {
            onConfigChange({
                ...config, isLoading: false,
                operationStatus: OperationStatus.SUCCESS,
                message: `${resp.length} fichier(s) crées avec succès `
            })
        }).catch(error => {
            onConfigChange({
                ...config, isLoading: false,
                operationStatus: OperationStatus.ERROR,
                error: error
            });
        });
    }

    useEffect(() => {

        const invalidNames = items.filter(f => isEmpty(f.name));
        const payload = {
            directory: folderLocation?.location,
            files: items,
            output: outputFormat.value,
            isValid: invalidNames.length == 0 && !isEmpty(folderLocation?.location) && !isEmpty(outputFormat?.value),
            onSubmit: onSubmit
        }

        onConfigChange(payload);
    }, [items, outputFormat, folderLocation])


    useEffect(() => {

        if (directory) {
            setFolderLocation({
                name: directory.name,
                location: directory.location
            });
        }

    }, [directory]);

    useEffect(() => {

        if (data instanceof Array) {
            setItems(data.map(i => {
                return {
                    ...i,
                    name: `${i.name.split(".")[0]}_${new Date().getTime()}.${fileTypes[0].extension}`
                }
            }));
        } else {
            setItems([{
                ...data,
                name: `${data.name.split(".")[0]}_${new Date().getTime()}.${fileTypes[0].extension}`
            }])
        }

    }, [data])


    return <Card className="documents">
        <DocumentBrowser show={showBrowser} onCloseClick={toggle} onConfirm={onSelectedPath} />

        <div className="mb-3 border p-4">
            <h6>Dossier cible</h6>
            <div className="hr border mb-2" />

            <div className='vstack gap-1'>
                <Link onClick={toggle} to={"#!"}><i className="mdi mdi-folder text-warning" /> {folderLocation?.location || "Selectionner un dossier"}</Link>
            </div>

        </div>

        <div className="mb-3 border p-4">
            <h6>Format de sortie:</h6>
            <div className="hr border mb-2" />

            <Select className="select2-selection"
                options={fileTypes}
                value={outputFormat}
                onChange={setOutputFormat}
            >
            </Select>

        </div>


        <div className="mb-0 border p-4">
            <h6>Noms des fichiers qui seront crées:</h6>
            <div className="hr border mb-2" />
            <ul className="list-unstyled">
                <SimpleBar className="scroller">
                    {map(items, item => {
                        return <li key={item.id}>
                            <div className="d-flex flex-column">

                                <div>
                                    <input
                                        value={item.name}
                                        className="form-control "
                                        onChange={e => UpdateFileName(item, e.target.value)}
                                    />
                                </div>

                            </div>
                        </li>
                    })}
                </SimpleBar>
            </ul>
        </div>

    </Card>
};

export default SaveDocumentView;