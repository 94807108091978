import React, { createRef, useEffect, useState } from "react";
import { TaskStatus } from "constants/tasks";
import { Badge, Card, CardBody, CardTitle } from "reactstrap";
import SimpleBar from "simplebar-react";
import { map } from "lodash";
import { getFileIcon, truncateFromMiddle } from "utils";
import { InstructionType } from "components/FileTask/constants";
import PromptStreamView from "./custom";
import AppStreamView from "./app";

const getStreamView = (type, streams, file, taskId, taskName) => {

    switch (type) {

        case InstructionType.CUSTOM:
            return <PromptStreamView streams={streams} file={file} taskId={taskId} taskName={taskName} />

        case InstructionType.APP:
            return <AppStreamView streams={streams} file={file} taskId={taskId} taskName={taskName} />
    }
}

const StreamView = ({ file, streams, type, taskId, taskName }) => {
    return <React.Fragment>
        {getStreamView(type, streams, file, taskId, taskName)}
    </React.Fragment>
}

export default StreamView;