import React, { useEffect, useState } from "react";
import {
    Alert,
    Col,
    Container,
    Label,
    Modal,
    ModalBody,
    Row,
    Spinner
} from "reactstrap";
import { useSelector, useDispatch } from "react-redux";

import PromptList from "../../pages/Workspace/promptStore/store"
//i18n
import { withTranslation } from "react-i18next";
import { createPrompt } from "store/actions";
import { Link } from "react-router-dom";
import { businessDomains, ResourcesScopes, uuidv4 } from "utils";
import { isEmpty, map } from "lodash";
import Select from "react-select"

//meta title
document.title = "Prompt store | Jask";


const paramRegex = /\[(.*?)\]/g



const AddPrompt = ({ t, show, onClose, prompt }) => {

    const [name, setName] = useState(null);
    const [description, setDescription] = useState(null);
    const [content, setContent] = useState(null);
    const [isCreating, setIsCreating] = useState(false);
    const [params, setParams] = useState([]);
    const [domains, setDomains] = useState([]);
    const [selectedDomains, setSelectedDomains] = useState([]);
    const [scope, setScope] = useState(null);
    const [scopes, setScopes] = useState(null);

    const dispatch = useDispatch();

    const { isAdding } = useSelector(state => ({
        isAdding: state.PromptStore.isAdding,
    }));


    const onSave = () => {
        setIsCreating(true);
        dispatch(createPrompt({
            id: prompt?.id,
            name: name,
            content: content,
            description: description,
            category: selectedDomains?.map(d => d.value).join(";"),
            parameters: params.filter(p => !isEmpty(p.name)),
            scope: scope.value
        }));
    }

    const addParams = () => {
        setParams([...params, {
            id: uuidv4(),
        }])
    }

    const removeParams = (id) => {
        setParams(params.filter(p => p.id !== id));
    }

    const updateParamsName = (id, value) => {

        const items = [...params];
        params.forEach(p => {
            if (p.id === id) {
                p.name = value;
            }
        })

        setParams(items);
    }

    const updateParamsDescription = (id, value) => {

        const items = [...params];
        params.forEach(p => {
            if (p.id === id) {
                p.description = value;
            }
        });
        setParams(items);
    }



    useEffect(() => {
        if (!isAdding && isCreating) {
            setIsCreating(false);
            onClose();
        }
    }, [isAdding]);

    useEffect(() => {
        setDomains(businessDomains.map(d => ({
            label: t(d.name),
            value: d.name,
            description: d.description,
        })));

        setScopes(ResourcesScopes.map(scope => ({ ...scope, label: t(scope.label) })))
    }, []);

    useEffect(() => {
        if (scopes && isEmpty(scope)) {
            setScope(scopes[1]);
        }
    }, [scopes])


    useEffect(() => {
        if (!isEmpty(prompt?.id)) {

            setName(prompt?.name);
            setDescription(prompt?.description);
            setContent(prompt?.content);
            setParams(prompt.parameters);
            setScope(scopes.find(sc => sc.value === prompt.scope));

            const tags = prompt.category.split(";");
            const promptDomains = domains?.filter(d => tags.includes(d.value));
            setSelectedDomains(promptDomains);

        } else {
            setName("");
            setDescription("");
            setContent("");
            setParams([]);
            setSelectedDomains([]);
        }
    }, [prompt, domains, scopes]);


    useEffect(() => {
        const matches = content?.match(paramRegex)?.map(m => m.replace("[", "").replace("]", "").trim()) || [];
        const existing = params.map(p => p.name.trim());
        console.log(matches);

        const candidates = matches.map(m => ({
            id: uuidv4(),
            name: m,
            description: ""
        }));
        setParams(candidates);

    }, [content])
    return <Modal size="lg" isOpen={show} toggle={onClose} centered={true} fade={false} >
        <div className="modal-content">
            <ModalBody>
                <div className="d-flex justify-content-between mb-4 border-bottom border-secondary border-opacity-25 pb-2">
                    <div>
                        <h5><i className="mdi mdi-message-bulleted me-1" />{t("Enregistrer un prompt")}</h5>
                    </div>
                    <div>
                        <button className="btn-close" onClick={onClose} />
                    </div>
                </div>
                <div className="mb-2">
                    <Label className="fw-bold">{t("Nom du prompt")}:</Label>
                    <input
                        className="form-control"
                        value={name}
                        onChange={e => setName(e.target.value)}
                    />
                </div>

                <div className="mb-3">
                    <Label className="fw-bold">{t("Description")}:</Label>
                    <input
                        className="form-control"
                        value={description}
                        onChange={e => setDescription(e.target.value)}
                    />
                </div>
                <div className="mb-3 card">

                    <Row>
                        <Col md={6}>
                            <Label className="fw-bold">{t("Visibilité")}:</Label>
                            <Select
                                options={scopes}
                                value={scope}
                                isMulti={false}
                                className="select2-selection"
                                onChange={setScope}

                            />
                        </Col>
                        <Col md={6}>
                            <Label className="fw-bold">{t("Tags")}:</Label>
                            <Select
                                options={domains}
                                value={selectedDomains}
                                isMulti={true}
                                className="select2-selection"
                                onChange={setSelectedDomains}

                            />
                        </Col>
                    </Row>

                </div>

                <div className="mb-3">
                    <Label className="fw-bold">{t("Contenu du Prompt")}:</Label>
                    <textarea
                        rows={7}
                        className="form-control"
                        value={content}
                        onChange={e => setContent(e.target.value)}
                        placeholder={t("Exemple: Traduis le contenu suivant en [language]")}
                    />
                </div>
                <div className="mb-3">

                    {params?.length > 0 && <div>
                        <Label>{t("Liste des paramètres")}({params?.length})</Label>
                        {map(params, (item, index) => {

                            return <Row key={index} className="mb-1">
                                <Col md={4}>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder={t("Nom du paramètre")}
                                        value={item.name}
                                        onChange={e => updateParamsName(item.id, e.target.value)}
                                    />
                                </Col>
                                <Col md={4}>
                                    <div className="hstack gap-1">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder={t("Description")}
                                            value={item.description}
                                            onChange={e => updateParamsDescription(item.id, e.target.value)}
                                        />

                                        <Link onClick={() => removeParams(item.id)}><i className="mdi mdi-trash-can font-size-14 text-danger" /></Link>
                                    </div>

                                </Col>

                            </Row>
                        })}
                    </div>}


                    {params?.length === 0 && <div>
                        <Alert color="info">
                            <i className="mdi mdi-information  font-size-18 me" /> <b>{t("Bon à savoir")}: </b> {t("Pour rajouter un paramètre à votre prompt, utiliser la syntaxe [NomDuParametre]")}
                        </Alert>
                    </div>}

                    {/* <div className="mt-4">
                        <Link onClick={addParams} >+ {t("Nouveau paramètre")}</Link>
                    </div> */}
                </div>
                <div className="hstack gap-1 float-end">
                    <button disabled={isAdding} onClick={onSave} className="btn btn-success">{t("Valider")}</button>
                    <button className="btn btn-danger">{t("Annuler")}</button>
                    {isAdding && <div className="vr" />}
                    {isAdding && <Spinner />}
                </div>


            </ModalBody>
        </div>
    </Modal>
};


export default withTranslation()(AddPrompt);
