import React, { useEffect, useState, useRef, useMemo } from "react";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import Select from "react-select"
import {
    Card,
    CardBody,
    Col,
    Container,
    Row,
    Modal,
    ModalHeader,
    ModalBody,
    Label,
    FormFeedback,
    UncontrolledTooltip,
    Input,
    Form,
    Table,
    Button,
    Spinner,
} from "reactstrap";

import {
    addDeployment,
    getStudioApps,
} from "store/actions";
import { useSelector, useDispatch } from "react-redux";
import { isEmpty, map } from "lodash";
import CustomSelect, { AppIdentityCard } from "components/Common/CustomSelect";
import { ResourceTypes } from "constants/general";
import { deleteDeploymentKey, generateDeploymentKey, getDeploymentKeys } from "service/admin";
import { copyToClipboard, truncateFromMiddle } from "utils";
import DeleteModal from "components/Common/DeleteModal";
import TeamsIcon from "assets/images/providers/microsoft-teams-icon.png"
import WebIcon from "assets/images/providers/webIcon.png"

const channels = [{
    label: "Web",
    value: "web",
    description: "Best suit for API or Iframe integration ",
    logo: WebIcon
},
{
    label: "Microsoft Teams",
    value: "msteams",
    description: "Expose as MS Teams Bot",
    logo: TeamsIcon
}]

const AddDeployment = ({ isOpen, onCloseClick, isEdit, initialDeployment }) => {

    const [deployment, setDeployment] = useState(null);
    const [selectedApp, setSelectedApp] = useState(null);
    const [selectedKey, setSelectedKey] = useState(null);
    const [showDeleteKey, setShowDeleteKey] = useState(false);
    const [applications, setApplications] = useState(null);
    const [keys, setKeys] = useState(null);
    const [isWorking, setIsWorking] = useState(false);
    const [canEdit, setCanEdit] = useState(false);
    const [isLoadingKeys, setIsLoadingKeys] = useState(false);
    const [channel, setChannel] = useState(channels[0]);

    const dispatch = useDispatch();

    const { isAdding, isLoadingApps, assistants, apps, newDeployment } = useSelector(state => ({
        apps: state.Studio.apps,
        assistants: state.Studio.assistants,
        isAdding: state.Deployments.isAdding,
        isLoadingApps: state.Studio.isLoading,
        newDeployment: state.Deployments.newDeployment,
    }));

    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            name: (deployment && deployment.name) || "",
        },
        validationSchema: Yup.object({
            name: Yup.string().required("Champs obligatoire"),
        }),
        onSubmit: values => {
            if (canEdit) {
                const deploymentToUpdate = {
                    id: deployment.id,
                    name: values.name,
                    description: values.description,
                    applicationId: selectedApp?.id,
                    applicationName: selectedApp?.name,
                    applicationType: selectedApp?.type,
                };
                setIsWorking(true);
                dispatch(addDeployment(deploymentToUpdate));
                validation.resetForm();

            } else {
                const newDeployment = {
                    name: values["name"],
                    description: values["description"],
                    applicationId: selectedApp?.value,
                    applicationName: selectedApp?.label,
                    applicationType: selectedApp?.type,
                    channelId: channel.value

                };

                setIsWorking(true);
                dispatch(addDeployment(newDeployment));
                validation.resetForm();
            }
            // onCloseClick();
        },
    });

    const handleDeleteKey = (key) => {
        setSelectedKey(key);
        setShowDeleteKey(!showDeleteKey);
    }

    const deleteKey = () => {
        setIsWorking(true);
        setShowDeleteKey(!showDeleteKey)
        deleteDeploymentKey(deployment?.id, selectedKey?.id).then(deletedKey => {
            setKeys(keys?.filter(k => k.id !== deletedKey))
        }).finally(() => {
            setIsWorking(false);
        })
    }

    const handleAddKey = (e) => {
        setIsWorking(true);
        e.preventDefault();

        generateDeploymentKey(deployment?.id, {
            deploymentId: deployment?.id,
        }).then(resp => {
            const existingKeys = keys || [];
            setKeys([...existingKeys, resp]);
        }).finally(() => {
            setIsWorking(false);
        });
    }

    useEffect(() => {
        setDeployment(initialDeployment);
    }, [initialDeployment]);


    useEffect(() => {
        if (isEmpty(applications) || isEmpty(assistants)) {
            dispatch(getStudioApps())
        }
    }, [dispatch]);

    useEffect(() => {

        const appItems = (apps || []).map(app => ({ value: app.id, label: app.name, type: ResourceTypes.APPLICATIONS, description: app.description }));
        const assistantItems = (assistants || []).map(app => ({ value: app.id, label: app.name, type: ResourceTypes.APPLICATIONS, logo: app.logoUrl, description: app.description }));
        setApplications([...appItems, ...assistantItems]);

    }, [apps, assistants]);

    useEffect(() => {

        if (deployment && canEdit) {
            const item = (applications || []).filter(app => app.value === deployment.applicationId);

            if (item.length == 1) {
                setSelectedApp(item[0]);
            }

        } else {
            setSelectedApp(null);
            setKeys(null);
            setDeployment(null);
            setCanEdit(false);
        }

        if (keys === null && deployment?.id) {
            getDeploymentKeys(deployment?.id).then(resp => {
                setKeys(resp);
            })
        }

    }, [deployment, applications]);

    useEffect(() => {
        if (!isAdding && isWorking && !isEmpty(newDeployment)) {
            setIsWorking(false);
            setDeployment(newDeployment);
            setCanEdit(true);

        }
    }, [newDeployment, isAdding])

    useEffect(() => {
        setCanEdit(isEdit);
    }, [isEdit])

    return <Modal isOpen={isOpen} toggle={onCloseClick} centered={true} size="lg"
        unmountOnClose={true}
    >
        <ModalHeader toggle={onCloseClick} tag="h4">
            {!!canEdit ? "Editer" : "Nouveau déploiement"}
        </ModalHeader>
        <ModalBody>
            <DeleteModal show={showDeleteKey}
                onCloseClick={() => setShowDeleteKey(false)}
                onDeleteClick={deleteKey}
            />
            <Card>
                <CardBody className="p-1">

                    <Form
                        onSubmit={e => {
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                        }}
                    >
                        <Row>

                            <Col xs={12}>
                                <div className="mb-4">
                                    <h6 className="form-label fw-bold">Nom du déploiement</h6>
                                    <Input
                                        name="name"
                                        type="text"
                                        placeholder="Inserer un nom"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.name || ""}
                                        disabled={canEdit}
                                        invalid={
                                            validation.touched.name &&
                                                validation.errors.name
                                                ? true
                                                : false
                                        }
                                    />
                                    {validation.touched.name &&
                                        validation.errors.name ? (
                                        <FormFeedback type="invalid">
                                            {validation.errors.name}
                                        </FormFeedback>
                                    ) : null}
                                </div>


                                <div className="mb-4">
                                    {<h6 className="form-label fw-bold">{canEdit ? " Application configurée: " : "Application à déployer:"}</h6>}
                                    <div>
                                        {!canEdit && <CustomSelect
                                            options={applications}
                                            className="select2-selection text-muted"
                                            value={selectedApp}
                                            onChange={setSelectedApp}
                                            isMulti={false}
                                            isClearable={true}
                                            isDisabled={canEdit}
                                        />}

                                        {canEdit && <AppIdentityCard
                                            name={selectedApp?.label}
                                            description={selectedApp?.description}
                                            logo={selectedApp?.logo}
                                        />}
                                    </div>
                                </div>

                                <div className="mb-4">
                                    <h6 className="form-label fw-bold">Canal de déploiement</h6>
                                    <CustomSelect
                                        options={channels}
                                        className="select2-selection text-muted"
                                        value={channel}
                                        onChange={setChannel}
                                        isMulti={false}
                                        isClearable={false}
                                    />
                                </div>

                            </Col>
                        </Row>





                        {!canEdit && <Row>
                            <Col>
                                <div className="mb-4">
                                    <button
                                        type="submit"
                                        className="btn btn-success save-user"
                                    >
                                        Créer
                                    </button>
                                </div>
                            </Col>
                        </Row>}
                    </Form>

                    {deployment?.id && <Row>
                        <Col xs={12}>

                            <div className="mb-3">
                                <h6 className="fw-bold">Clés d'accès:</h6>
                                <p><i className="mdi mdi-information" /> Uné clé d'accès vous permet de protéger l'accès à ce déploiment et donc aux ressources associées. </p>
                                <div>
                                    <div className="table-responsive mb-4">
                                        <Table className="table align-middle table-nowrap table-hover mb-0">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Id</th>
                                                    <th scope="col">Secret</th>
                                                    <th scope="col"></th>
                                                </tr>
                                            </thead>

                                            <tbody>
                                                {map(keys || [], item => {
                                                    return <tr id={item.id}>
                                                        <td className="text-wrap text-break">{item.id}  <Link onClick={() => copyToClipboard(item.id)}><i className="mdi mdi-content-copy" /></Link></td>
                                                        <td className="text-wrap">{item.hiddenValue} <Link onClick={() => copyToClipboard(item.value)}><i className="mdi mdi-content-copy" /></Link></td>
                                                        <td className="text-wrap text-break"> <Link disabled={isWorking} onClick={e => handleDeleteKey(item)}><i className="mdi  mdi-trash-can-outline text-danger" /></Link></td>
                                                    </tr>
                                                })}
                                            </tbody>
                                        </Table>
                                    </div>
                                    {isEmpty(keys) && <p>Aucune clé n'est disponible pour ce déploiement.</p>}

                                    <div className="hstack gap-1 mt-4">
                                        <Button className="btn btn-info" disabled={isWorking} onClick={handleAddKey}
                                        >Ajouter</Button>
                                        {isWorking && <div className="vr" />
                                        }
                                        {isWorking && <Spinner size="md" />}
                                    </div>

                                </div>
                            </div>
                        </Col>
                    </Row>}



                </CardBody>

            </Card>
        </ModalBody>
    </Modal>
}

export default AddDeployment;