export const PlatformRoles = {


    GLOBAL_ADMIN: "admin",
    WORKSPACE: "workspace",
    ADDS_ON_SUPPORT: "support",
    TEMPLATES: "templates",
    APPLICATIONS: "application",
    ASSISTANTS: "assistant",
    USER_ADMIN: "users",
    DATASOURCE: "datasources",
    AI_ADMIN: "aiAdmin",
    DEPLOYMENT: "deployment",
    USAGE: "usage",
    SCENARIO: "scenario",
}