import React, { memo, ReactNode, useCallback, useEffect, useState } from 'react';
import { Handle, Position } from '@xyflow/react';
import { Link } from "react-router-dom";
import { Badge, Card, CardBody, CardFooter, CardTitle, Collapse, DropdownItem, DropdownMenu, DropdownToggle, Spinner, UncontrolledDropdown } from 'reactstrap';
import classnames from "classnames";
import { isEmpty, map } from 'lodash';
import { runPromptOptimization } from 'service/tasks';
import { useSelector } from "react-redux";
import SingleHandle from '../../handle/SingleHandle';

const inputTypes = [
    {
        id: 'all',
        title: 'Texte et fichiers ',
        description: 'Concatenation du texte et du contenu des fichiers'
    },
    {
        id: 'textOnly',
        title: 'Texte',
        description: 'Appliquer les instructions le texte fourni'
    },
    {
        id: 'fileOnly',
        title: 'Fichiers',
        description: 'Appliquer les instructions sur chaque fichier uniquement'
    },
    {
        id: 'rowOnly',
        title: 'Ligne par ligne',
        description: 'Appliquer les instructions sur chaque ligne'
    },
]

const getStatusClassName = (status) => {

    if (!status)
        return "text-danger";
    else {
        return "text-success";
    }
}

const ApplicationNode = ({ llms, data, onChange, type }) => {
    const [collapsed, setCollapsed] = useState(false);
    const [nodeName, setNodeName] = useState(data.name);
    const [selectedModel, setSelectedModel] = useState({
        id: data?.modelId,
        title: data?.modelName
    });
    const [instructions, setInstructions] = useState(data?.instructions);
    const [nodeStatus, setNodeStatus] = useState(false);
    const [isLoading, setIsLoading] = useState(data?.isLoading);
    const { accessToken } = useSelector(state => ({
        accessToken: state.Login.accessToken,
    }));

    const onModelChange = (model) => {
        setSelectedModel(model);
    }

    const optimizePrompt = () => {
        setCollapsed(true);
        setIsLoading(true);
        runPromptOptimization(instructions, onPromptOptimizationStart, onPromptToken, onPromptOptimizationStop, accessToken);
    }

    const onPromptOptimizationStart = () => {
        setInstructions(prev => "");
    }

    const onPromptToken = (token) => {
        setInstructions(prev => `${prev}${token}`);
    }

    const onPromptOptimizationStop = (status) => {
        setIsLoading(false);
    }

    useEffect(() => {
        if (isEmpty(selectedModel?.id)) {
            setSelectedModel(llms[0]);
        }
    }, [llms, selectedModel]);


    useEffect(() => {
        onChange({
            ...data,
            name: nodeName,
            modelId: selectedModel?.id,
            modelName: selectedModel?.title,
            instructions: instructions,
            isLoading: isLoading,
            isValid: !isEmpty(nodeName) && !isEmpty(instructions) && instructions.length > 3 && !isEmpty(selectedModel?.id)
        })
    }, [nodeName, selectedModel, instructions, isLoading])

    useEffect(() => {
        setNodeStatus(data.isValid);
    }, [data])

    return <Card>
        <div className="accordion accordion-flush" id="accordion">
            <div className="accordion-item">
                <div className="accordion-header" id="headingOne">
                    <button
                        className={classnames(
                            "accordion-button",
                            "fw-medium",
                            { collapsed: !collapsed }
                        )}
                        type="button"
                        onClick={() => setCollapsed(!collapsed)}
                        style={{ cursor: "pointer" }}
                    >
                        <div className='vstack'>
                            <div className="title hstack gap-0">
                                {data.icon && <i className={data.icon + " font-size-20"} />}
                                <input className="border-0 form-control" style={{ background: 'transparent' }} type='text' value={nodeName} onChange={e => setNodeName(e.target.value)} />

                            </div>
                            <div className="subline ms-3">
                                {nodeStatus && <div className="text-success ms-4"><small > Valide </small><i className='mdi mdi-check-circle' /></div>}
                                {!nodeStatus && <div className="text-warning ms-4"><small > Invalide </small><i className='mdi mdi-alert' /></div>}                            </div>
                        </div>


                    </button>
                </div>

                <Collapse isOpen={collapsed}>

                    <div className="accordion-body">
                        <div className='mb-2 nodrag'>
                            <textarea
                                placeholder='Vos instructions...'
                                className='form-control font-size-10'
                                rows={5}
                                value={instructions}
                                onChange={e => setInstructions(e.target.value)}
                                disabled={isLoading}
                            />
                        </div>

                    </div>
                </Collapse>
            </div>
            <SingleHandle nodeId={data?.id} type="target" position={Position.Left} />
            <SingleHandle nodeId={data?.id} type="source" position={Position.Right} />

        </div>
        <CardFooter className='border-top'>
            <div className='d-flex justify-content-between'>
                <div>

                    <UncontrolledDropdown className="dropup ms-1" disabled={history.length == 0}>
                        <DropdownToggle
                            href="#"
                            tag="a" className="dropdown-toggle ">
                            <Badge className='badge badge-soft-info' > {selectedModel?.title}</Badge>
                        </DropdownToggle>
                        <DropdownMenu className="dropdown-menu-start dropup">
                            <div className="dropdown-header noti-title border-bottom">
                                <h5 className="font-size-12 text-muted text-truncate mn-0">
                                    <div className="hstack gap-1">
                                        <div className="vstack gap-1 mt-1">
                                            <span className=''>  Modèles d'IA à utiliser </span>
                                        </div>
                                    </div>
                                </h5>
                            </div>

                            {map(llms, (item, index) => {
                                return <DropdownItem className={index < inputTypes.length - 1 ? "border-bottom p-2 ps-4 " : "p-2 ps-4"}
                                    onClick={() => onModelChange(item)}
                                >
                                    <div className='vstack gap-1'>

                                        <span className="text-muted font-size-12">
                                            {item.title}
                                        </span>
                                        <small className='font-size-10'>{item.description}</small>
                                    </div>

                                </DropdownItem>
                            })}

                        </DropdownMenu>
                    </UncontrolledDropdown>
                </div>
                <div className='d-flex flex-row'>
                    <UncontrolledDropdown className="dropup ms-1" disabled={history.length == 0}>
                        <DropdownToggle
                            href="#"
                            tag="a" className="dropdown-toggle " disabled={isEmpty(instructions)}>
                            <i className=" mdi mdi-lightbulb-on-outline text-warning" />
                        </DropdownToggle>
                        <DropdownMenu className="dropdown-menu-start dropup">
                            <DropdownItem
                                onClick={optimizePrompt}
                            >
                                <div className='vstack gap-1'>

                                    <span className="text-muted font-size-12">
                                        Ameliorer mon prompt
                                    </span>
                                    <small className='font-size-10'>Génère et enrichit votre prompt</small>
                                </div>

                            </DropdownItem>


                        </DropdownMenu>
                    </UncontrolledDropdown>

                    <UncontrolledDropdown className="dropup ms-1" disabled={history.length == 0}>
                        <DropdownToggle
                            href="#"
                            tag="a" className="dropdown-toggle ">
                            <i className=" mdi mdi-database " />
                        </DropdownToggle>
                        <DropdownMenu className="dropdown-menu-start dropup">
                            <div className="dropdown-header noti-title border-bottom">
                                <h5 className="font-size-12 text-muted text-truncate mn-0">
                                    <div className="hstack gap-1">
                                        <div className="vstack gap-1 mt-1">
                                            <span>  Contexte d'execution </span>
                                            <small>  (Les données à traiter par cette tache)</small>
                                        </div>
                                    </div>
                                </h5>
                            </div>

                            {map(inputTypes, (item, index) => {
                                return <DropdownItem className={index < inputTypes.length - 1 ? "border-bottom p-2 ps-4 " : "p-2 ps-4"}
                                // onClick={() => resendPrompt(item.text)}
                                >
                                    <div className='vstack gap-1'>

                                        <span className="text-muted font-size-12">
                                            {item.title}
                                        </span>
                                        <small className='font-size-10'>{item.description}</small>
                                    </div>

                                </DropdownItem>
                            })}

                        </DropdownMenu>
                    </UncontrolledDropdown>
                    {isLoading && <div className='ms-2'><Spinner size={"sm"} /></div>}
                </div>
            </div>
        </CardFooter>
    </Card >
}

export default memo(ApplicationNode);