import React from "react";
import {
    Container
} from "reactstrap";
//Import Breadcrumb
import Breadcrumbs from "../../../../components/Common/Breadcrumb2";

//i18n
import { withTranslation } from "react-i18next";
import SupportTeamList from "./teams"
const SupportPlatforms = props => {

    //meta title
    document.title = "Jask for Support";

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs
                        title={props.t("Equipes Support")}
                        breadcrumbItems={[
                            {
                                title: "Jask"
                            },
                            {
                                title: "Support"
                            },
                            {
                                title: props.t("Equipes")
                            }
                        ]}
                    />

                    <SupportTeamList />

                </Container>
            </div>
        </React.Fragment>
    );
};


export default withTranslation()(SupportPlatforms);
