import SimpleTableContainer from "components/Common/SimpleTableContainer";
import TableContainer from "components/Common/TableContainer";
import { isEmpty, map } from "lodash";
import React, { useEffect, useMemo, useState } from "react"
import { Card, CardBody, Label, Modal, ModalBody, ModalFooter, ModalHeader, Spinner } from "reactstrap"
import { loadTabularFile } from "service/documents";
import SimpleBar from "simplebar-react"
import Select from 'react-select'
import { withTranslation } from "react-i18next";
import { ChartTypes, ResourceTypes } from "constants/general";
const aggregateTypes = [
    {
        label: "Nombre d'éléments",
        value: "count"
    }
]

const AddChart = ({ show, onClose, onConfirm, chartType, table, dynamicColumns, t }) => {

    const [chartName, setChartName] = useState(null);
    const [chartIcon, setChartIcon] = useState(null);
    const [dimensions, setDimensions] = useState([]);
    const [values, setValues] = useState([]);
    const [selectedDimensions, setSelectedDimensions] = useState([]);
    const [aggregateType, setAggregateType] = useState(aggregateTypes[0]);

    const onChartConfigured = () => {

        onConfirm({
            dimensions: selectedDimensions.map(d => d.value),
            type: chartType
        });
    }

    useEffect(() => {
        if (isEmpty(chartType))
            return;

        switch (chartType) {
            case ChartTypes.PIE:
                setChartName(t("Pie Chart"));
                setChartIcon("bx bx-pie-chart")
                break;
            case ChartTypes.BAR:
                setChartName(t("Bar Chart"));
                setChartIcon("bx bx-bar-chart-square ");
                break;
            case ChartTypes.LINE:
                setChartName(t("Line Chart"));
                setChartIcon("bx bx-line-chart");
                break;
        }
    }, [chartType]);

    useEffect(() => {
        const dim = (table?.dimensions || []).map(d => ({
            label: d,
            value: d
        }));

        setDimensions([...dim, ...dynamicColumns]);
    }, [table, dynamicColumns])

    return <Modal
        isOpen={show}
        toggle={onClose}
        contentClassName="tabular-task-launcher"
        size="xs"
        fade={false}
        centered={true}

    >
        <div className="hstack p-2 bg-info bg-opacity-50">
            <h5> <i className={`${chartIcon} font-size-18`} /> {chartName}</h5>
        </div>

        <ModalBody className="ps-4">
            <Card>
                <div className="p-2">
                    <Label>Dimension:</Label>
                    <Select
                        options={dimensions}
                        value={selectedDimensions}
                        className="select2-selection"
                        onChange={e => setSelectedDimensions([e])}
                    />
                </div>

                <div className="p-2">
                    <Label>Valeurs:</Label>
                    <Select
                        options={aggregateTypes}
                        value={aggregateType}
                        className="select2-selection"
                        onChange={setAggregateType}
                    />
                </div>
            </Card>
        </ModalBody>
        <ModalFooter className="bg-info bg-opacity-25">
            <button className="btn btn-info" onClick={onChartConfigured}>Terminer</button>
        </ModalFooter>

    </Modal>
}

export default withTranslation()(AddChart);