import axios from "axios";


//apply base url for axios
export const API_URL = process.env.REACT_APP_BACKEND_URL;
// export const API_URL = "https://oda-api-dev-fr.azurewebsites.net";
// export const API_URL = "https://kasj-api-dev-fr.azurewebsites.net";

export const axiosApi = axios.create({
  baseURL: API_URL,
});

// axiosApi.defaults.headers.common["Authorization"] = token;

axiosApi.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject(error)
);

export async function get(url, config = {}) {
  return await axiosApi
    .get(url, { ...config })
    .then((response) => response.data);
}

export async function getBlob(url, config = {}) {
  return await axiosApi
    .get(url, { ...config, responseType: 'blob' })
    .then((response) => response);
}

export async function post(url, data, config = {}) {
  return axiosApi
    .post(url, { ...data }, { ...config })
    .then((response) => response.data);
}
export async function post_form(url, data, config = {}) {
  return axiosApi
    .post(url, data, { ...config })
    .then((response) => response.data);
}

export async function put(url, data, config = {}) {
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then((response) => response.data);
}

export async function del(url, config = {}) {
  return await axiosApi
    .delete(url, { ...config })
    .then((response) => response.data);
}
