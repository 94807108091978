import {
    GET_DATASOURCE_LIST_FAIL,
    GET_DATASOURCE_LIST_SUCCESS,
    ADD_DATASOURCE_LIST_SUCCESS,
    ADD_DATASOURCE_LIST_FAIL,
    UPDATE_DATASOURCE_LIST_SUCCESS,
    UPDATE_DATASOURCE_LIST_FAIL,
    DELETE_DATASOURCE_LIST_SUCCESS,
    DELETE_DATASOURCE_LIST_FAIL,
    GET_APPLY_DATASOURCE_SUCCESS,
    GET_APPLY_DATASOURCE_FAIL,
    DELETE_APPLY_DATASOURCE_SUCCESS,
    DELETE_APPLY_DATASOURCE_FAIL,
    GET_DATASOURCE_DOMAINS_LIST,
    GET_DATASOURCE_DOMAINS_LIST_SUCCESS,
    GET_DATASOURCE_DOMAINS_LIST_ERROR,
    STOP_CHECKING_INDEX
} from "./actionTypes";

const INIT_STATE = {
    datasources: [],
    checkIndexing: false,
    error: {},
    jobApply: [],
    domains: []
}

const DatasourceReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_DATASOURCE_LIST_SUCCESS:
            return {
                ...state,
                datasources: action.payload,
            };

        case GET_DATASOURCE_LIST_FAIL:
            return {
                ...state,
                error: action.payload,
            };

        case GET_DATASOURCE_DOMAINS_LIST_SUCCESS:


            return {
                ...state,
                domains: action.payload,
            };

        case GET_DATASOURCE_DOMAINS_LIST_ERROR:
            return {
                ...state,
                error: action.payload,
            };

        case ADD_DATASOURCE_LIST_SUCCESS:
            return {
                ...state,
                checkIndexing: true,
                datasources: [...state.datasources, action.payload],
            };

        case STOP_CHECKING_INDEX:
            return {
                ...state,
                checkIndexing: false,
            };

        case ADD_DATASOURCE_LIST_FAIL:
            return {
                ...state,
                checkIndexing: false,
                error: action.payload,
            };

        case UPDATE_DATASOURCE_LIST_SUCCESS:
            return {
                ...state,
                datasources: state.datasources.map(job =>
                    job.id.toString() === action.payload.id.toString()
                        ? { job, ...action.payload }
                        : job
                ),
            };

        case UPDATE_DATASOURCE_LIST_FAIL:
            return {
                ...state,
                error: action.payload,
            };

        case DELETE_DATASOURCE_LIST_SUCCESS:
            return {
                ...state,
                datasources: state.datasources.filter(
                    datasource => datasource.id.toString() !== action.payload.toString()
                ),
            };

        case DELETE_DATASOURCE_LIST_FAIL:
            return {
                ...state,
                error: action.payload,
            };
        case GET_APPLY_DATASOURCE_SUCCESS:
            return {
                ...state,
                jobApply: action.payload,
            };
        case GET_APPLY_DATASOURCE_FAIL:
            return {
                ...state,
                error: action.payload,
            };
        case DELETE_APPLY_DATASOURCE_SUCCESS:
            return {
                ...state,
                jobApply: state.jobApply.filter(data => data.id.toString() !== action.payload.toString())
            };
        case DELETE_APPLY_DATASOURCE_FAIL:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state
    }
}

export default DatasourceReducer;