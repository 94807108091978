

import React, { useCallback, useEffect, useRef, useState } from "react";
import { withTranslation } from "react-i18next";
import { Card, CardBody } from "reactstrap";
import { isEmpty, map } from "lodash";
import { getFileIcon, uuidv4 } from "utils";
import { Link } from "react-router-dom";
import { downloadFile } from "service/documents";

import { pdfjs, Document, Page } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import SimpleBar from "simplebar-react"
import LoadingPlaceholder from "./loader";


function highlightPattern(text, pattern) {
    const splits = pattern.replace("\"", "").replace("-", ' ').split(' ')
    if (splits.includes(text)) {
        return `<mark>${text}</mark>`
    }
    return text;
}

const PagePreview = ({ isSelected, page, searchText, children }) => {

    const viewRef = useRef(null);

    useEffect(() => {
        if (isSelected) {
            viewRef.current.scrollIntoView({ behavior: 'auto' });
        }
    }, [isSelected]);


    return <span ref={viewRef}>{children}</span>
}

const DocumentPreview = ({ resource, t, onClose }) => {

    const [isRunning, setIsRunning] = useState(false);
    const [data, setData] = useState(null);
    const [numPages, setNumPages] = useState(null);
    const [searchText, setSearchText] = useState('');
    const [defaultPage, setDefaultPage] = useState(null);
    const [loaded, setLoaded] = useState(false);

    const onDocumentLoad = ({ numPages }) => {
        setNumPages(numPages);
    }
    const textRenderer = useCallback(
        (textItem) => {
            return highlightPattern(textItem.str, searchText)
        },
        [searchText]
    );

    useEffect(() => {
        if (loaded)
            setTimeout(() => setDefaultPage(parseInt(resource?.atPage)), 100)
    }, [loaded])

    useEffect(() => {

        if (isEmpty(resource.location) || loaded)
            return;
        downloadFile(resource.location, resource.owner, resource.providerId, resource.providerType).then(resp => {
            setData({
                url: resp
            });
            setLoaded(true);
        })
        console.log("Light ", resource.highlight)

        setSearchText(resource.highlight);
    }, [resource]);



    return <div className="omni-app  pb-4" >
        <div className="d-flex justify-content-between bg-danger bg-opacity- text-white">
            <div className="p-2">
                <div className="hstack gap-2">
                    {resource.image && <img src={resource.image} width={60} height={60} />}
                    <div className={!resource.image ? "ms-2" : ""}>
                        <span className="font-size-16 fw-bold">{resource.name}</span>
                        <p className="m-0 ms-2">{t("Cliquez")} <Link className="fw-bold" to="#" onClick={() => window.open(resource?.webUrl, "_blank")} target="_blank">{t("ici")}</Link> {t("pour ouvrir la source")}</p>
                    </div>

                </div>

            </div>



            <div className="p-2">
                <button className="btn btn-close text-white" onClick={onClose} />
            </div>
        </div>
        <div className="d-flex flex-column ">

            <Document
                file={data}
                onLoadSuccess={onDocumentLoad}
                noData={<LoadingPlaceholder />}
                loading={<LoadingPlaceholder />}

            >
                <SimpleBar style={{ height: '100vh' }} >

                    {Array.apply(null, Array(numPages))
                        .map((x, i) => i + 1)
                        .map(page => <PagePreview key={page} page={page} isSelected={page === defaultPage} >
                            <Card className="w-100 border border-secondary border-opacity-50 m-2 rounded-2" ><Page
                                pageNumber={page}
                                width={700}
                                customTextRenderer={textRenderer}
                                size='A4'
                            /></Card>
                        </PagePreview>)}

                </SimpleBar>
            </Document>

        </div>

    </div>
}

export default withTranslation()(DocumentPreview);
