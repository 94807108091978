import { call, put, takeEvery } from "redux-saga/effects"

//  Redux States
import { PROCESS_DOC_TEMPLATE, GET_RECENT_FILES, GET_TEMPLATE, ADD_TEMPLATE, SUBMIT_GENERATION_TASK, DOWNLOAD_GENERATED_TEMPLATE, DEL_TEMPLATE } from "./actionTypes"
import { processTemplateSuccess, getRecentFilesSuccess, getRecentFilesFailed, downloadTemplateGeneratedDocumentSuccess, downloadTemplateGeneratedDocumentFailed, processTemplateFailed, generateDocumentSuccess, generateDocumentFailed, addTemplateFailed, addTemplateSuccess, getTemplatesSuccess, getTemplatesFailed, delTemplatesSuccess, delTemplatesFailed } from "./actions"

//Include Both Helper File with needed methods
import { processTemplate as onProcessTemplate, delTemplate as onDeleteTemplate, generateDocumentFromTemplate, createTemplate, getTemplates as onGetTemplates, downloadGeneratedDocumentFromTemplate, getRecentFiles } from "helpers/backend_helper"

function* processTemplates(action) {
    try {
        const response = yield call(onProcessTemplate, action.payload)
        yield put(processTemplateSuccess(response))
    } catch (error) {
        yield put(processTemplateFailed(error))
    }
}

function* startGenerateDocumentFromTemplate(action) {
    try {
        const response = yield call(generateDocumentFromTemplate, action.payload)
        yield put(generateDocumentSuccess(response))
    } catch (error) {
        yield put(generateDocumentFailed(error))
    }
}


function* getAllTemplates(action) {
    try {
        const response = yield call(onGetTemplates, action.payload)
        yield put(getTemplatesSuccess(response))
    } catch (error) {
        yield put(getTemplatesFailed(error))
    }
}

function* AddNewTemplate(action) {
    try {
        const response = yield call(createTemplate, action.payload)
        yield put(addTemplateSuccess(response))
    } catch (error) {
        yield put(addTemplateFailed(error))
    }
}

function* deleteTemplate(action) {
    try {
        const response = yield call(onDeleteTemplate, action.payload)
        yield put(delTemplatesSuccess(response))
    } catch (error) {
        yield put(delTemplatesFailed(error))
    }
}


function* listRecentFiles(action) {
    try {
        const response = yield call(getRecentFiles, action.payload)
        yield put(getRecentFilesSuccess(response))
    } catch (error) {
        yield put(getRecentFilesFailed(error))
    }
}

function* downloadGeneratedTemplateDocument(action) {
    try {
        const response = yield call(downloadGeneratedDocumentFromTemplate, action.payload)
        yield put(downloadTemplateGeneratedDocumentSuccess(response))
    } catch (error) {
        yield put(downloadTemplateGeneratedDocumentFailed(error))
    }
}

function* documentsSaga() {
    yield takeEvery(SUBMIT_GENERATION_TASK, startGenerateDocumentFromTemplate)
    yield takeEvery(PROCESS_DOC_TEMPLATE, processTemplates)
    yield takeEvery(GET_TEMPLATE, getAllTemplates)
    yield takeEvery(ADD_TEMPLATE, AddNewTemplate)
    yield takeEvery(DOWNLOAD_GENERATED_TEMPLATE, downloadGeneratedTemplateDocument)
    yield takeEvery(DEL_TEMPLATE, deleteTemplate)
    yield takeEvery(GET_RECENT_FILES, listRecentFiles)


}

export default documentsSaga;