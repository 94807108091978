

import React, { useEffect, useState } from "react";
import {
    Card,
    CardBody,
    CardTitle,
    Col,
    Container, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Row
} from "reactstrap";
import SimpleBar from "simplebar-react"

//i18n
import { withTranslation } from "react-i18next";
import { isEmpty, map } from "lodash";
import { Link } from "react-router-dom";

const ItemVIew = ({ item, itemSelected, onSelectionChange, selectAll }) => {
    const [selected, setSelected] = useState(itemSelected);
    const [instructions, setInstructions] = useState(null);
    const [showInstructions, setShowInstructions] = useState(false);

    const onNewInstruction = (e) => {
        const { key, value } = e;

        if ((!isEmpty(key) && key !== "Enter") || isEmpty(instructions))
            return;
        setShowInstructions(!showInstructions);
    }

    useEffect(() => {
        onSelectionChange({
            id: item.id,
            selected: selected,
            instructions: instructions,
            item: item
        });
    }, [selected, instructions])

    useEffect(() => {
        setSelected(selectAll);
    }, [selectAll])

    useEffect(() => {
        setSelected(itemSelected);
    }, [itemSelected])

    switch (item.type) {
        case "Header":
            return <div className="hstack gap-2 mb-2 mt-2">
                <input id={"checkbox_" + item.id} type="checkbox" checked={selected} onClick={e => setSelected(!selected)} className="mb-2" />
                <h6>{item.text.replace("\n", " ")}</h6>
            </div>
        case "Title":
            return <div className="hstack gap-2 mb-2 mt-2">
                <input id={"checkbox_" + item.id} type="checkbox" checked={selected} onClick={e => setSelected(!selected)} className="mb-2 form-check-input" />
                <h6 className="">{item.text}</h6>
            </div>

        case "ListItem":
        case "NarrativeText":
            return <div className="ms-4 hstack gap-2">
                <div>
                    <input type="checkbox" className="form-check-input" checked={selected} onClick={e => setSelected(!selected)} />
                </div>
                <p className="mt-2">{item.text}</p>
            </div>
    }
}

const GenerationStructure = ({ location, sourceName, structure, onItemChange }) => {

    const [selectAll, setSelectAll] = useState(false);

    useEffect(() => {
        structure?.forEach(item => {
            onItemChange(location, { ...item, selected: selectAll })
        })
    }, [selectAll])



    return (
        <div className="p-2 document-generation-template">
            <Card>
                <CardBody>
                    <div className=" ps-20 pt-2 ">
                        <h5>Structure du document </h5>
                        <div className="vstack gap-2">
                            <small className="text-muted font-size-12 ">Rappel des éléments qui seront contextualisés lors de la génération.</small>
                            <div className="hstack border-top pt-2">
                                <span className="text-muted fw-bold me-2 bold">Source:</span>
                                <span className="badge bg-info mt-0">{sourceName} </span>
                            </div>
                        </div>
                        <hr />
                    </div>
                    <SimpleBar className="structure">
                        <div className="me-2">
                            {map(structure || [], (template) => {
                                return <div key={template.id} className="hstack gap-1">
                                    <ItemVIew item={template.item} itemSelected={template.selected} onSelectionChange={i => onItemChange(location, i)} selectAll={selectAll} />
                                </div>
                            })}
                        </div>
                    </SimpleBar>
                </CardBody>
            </Card>

        </div>
    );
};


export default withTranslation()(GenerationStructure);

