import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
    Card,
    CardBody,
    Col,
    Row,
    Label,
    Input,
    Nav,
    NavItem,
    TabContent,
    TabPane,
    NavLink,
    Spinner
} from "reactstrap";

import {
    getUsers,
    getGroups,
    addRole
} from "store/actions";
import { useSelector, useDispatch } from "react-redux";
import { isEmpty } from "lodash";
import classnames from "classnames"
import Select from "react-select";
import ConsumptionIcon from "assets/images/consumption.png"
import { operationFailed } from "utils/notifications";
import { parseError } from "utils/error";

const permissionsModels = [
    {
        label: "Tous les droits",
        value: "*"
    },
    {
        label: "Consulter",
        value: "read"
    },
    {
        label: "Créer",
        value: "create"
    },
    {
        label: "Executer",
        value: "run"
    },
    {
        label: "Supprimer",
        value: "delete"
    },
    {
        label: "Partager",
        value: "share"
    }
]

const models = [
    {
        label: "Tous les modèles",
        value: "*"
    },
    {
        value: 'gpt35',
        label: 'GPT 3.5',
        description: 'Limité à environ 64K charactères'
    },
    {
        value: 'gpt4',
        label: 'GPT 4',
        description: 'Limité à environ 320K charactères'
    },
    {
        value: 'mistral',
        label: 'Mistral',
        description: 'Limité à environ 100K charactères'
    }
]


const AddRoleProfile = ({ principal, onClose }) => {

    const [profile, setProfile] = useState({});
    const [name, setName] = useState();
    const [description, setDescription] = useState();
    const [activeTab, setactiveTab] = useState('profile');
    const [members, setMembers] = useState([]);
    const [selectedMembers, setSelectedMembers] = useState([]);


    const [documentPermissions, setDocumentPermissions] = useState([permissionsModels[0]]);
    const [applicationPermissions, setApplicationPermissions] = useState([permissionsModels[0]]);
    const [assistantPermissions, setAssistantPermissions] = useState([permissionsModels[0]]);
    const [templatesPermissions, setTemplatesPermissions] = useState([permissionsModels[0]]);
    const [deploymentPermissions, setDeploymentPermissions] = useState([permissionsModels[0]]);
    const [datasourcesPermissions, setDatasourcesPermissions] = useState([permissionsModels[0]]);
    const [monitoringPermissions, setMonitoringPermissions] = useState([permissionsModels[0]]);

    const [documentCount, setDocumentCount] = useState(100);
    const [documentVolume, setDocumentVolume] = useState(100);

    const [applicationCount, SetApplicationCount] = useState(100);
    const [applicationVolume, SetApplicationVolume] = useState(100);

    const [assistantCount, SetAssistantCount] = useState(100);
    const [assistantVolume, SetAssistantVolume] = useState(100);

    const [templatesCount, SetTemplatesCount] = useState(100);
    const [templatesVolume, SetTemplatesVolume] = useState(100);

    const [datasourcesCount, SetDatasourcesCount] = useState(100);
    const [datasourcesVolume, SetDatasourcesVolume] = useState(100);
    const [validConsumption, setValidConsumption] = useState(false);
    const [modelsPermissions, setModelPermissions] = useState([models[0]]);
    const [isCreating, setIsCreating] = useState(false);
    const [canSubmit, setCanSubmit] = useState(false);
    const dispatch = useDispatch();

    const { groups, users, isAdding, error } = useSelector(state => ({
        groups: state.Users.groups,
        users: state.Users.users,
        isAdding: state.Users.isAdding,
        error: state.Users.error,

    }));

    const onSubmit = () => {
        setIsCreating(true);
        dispatch(addRole(profile));
    }

    useEffect(() => {

        if (isEmpty(users))
            dispatch(getUsers());

        if (isEmpty(groups))
            dispatch(getGroups())

    }, [dispatch])

    useEffect(() => {

        const userPrincipals = (users || []).map(u => ({
            label: u.firstName + " " + u.name,
            value: u.id
        }))

        const groupPrincipals = (groups || []).map(u => ({
            label: u.name,
            value: u.id
        }))

        setMembers([...userPrincipals, ...groupPrincipals]);

    }, [users, groups]);

    useEffect(() => {

        const payload = {

            documents: {
                permissions: documentPermissions.map(o => o.value),
                totalRequest: documentCount,
                totalVolume: documentVolume,
                isValidPermissions: documentPermissions.length > 0,
                isValidConsumption: documentCount > 0 && documentVolume > 0
            },

            applications: {
                permissions: applicationPermissions.map(o => o.value),
                totalRequest: applicationCount,
                totalVolume: applicationVolume,
                isValidPermissions: applicationPermissions.length > 0,
                isValidConsumption: applicationCount > 0 && applicationVolume > 0
            },

            templates: {
                permissions: templatesPermissions.map(o => o.value),
                totalRequest: templatesCount,
                totalVolume: templatesVolume,
                isValidPermissions: templatesPermissions.length > 0,
                isValidConsumption: templatesCount > 0 && templatesVolume > 0
            },

            assistants: {
                permissions: assistantPermissions.map(o => o.value),
                totalRequest: assistantCount,
                totalVolume: assistantVolume,
                isValidPermissions: assistantPermissions.length > 0,
                isValidConsumption: assistantVolume > 0 && assistantCount > 0
            },

            datasources: {
                permissions: datasourcesPermissions.map(o => o.value),
                totalRequest: datasourcesCount,
                totalVolume: datasourcesVolume,
                isValidPermissions: datasourcesPermissions.length > 0,
                isValidConsumption: datasourcesCount > 0 && datasourcesVolume > 0
            },

            monitoring: {
                permissions: monitoringPermissions.map(o => o.value),
                isValidPermissions: monitoringPermissions.length > 0
            },

            deployments: {
                permissions: deploymentPermissions.map(o => o.value),
                isValidPermissions: deploymentPermissions.length > 0
            },

            models: {
                permissions: modelsPermissions.map(o => o.value),
                isValidPermissions: modelsPermissions.length > 0
            },
            owners: selectedMembers.map(s => s.id),
            name: name,

        }
        setProfile(payload);
        setValidConsumption(
            payload.applications?.isValidConsumption
            && payload.assistants?.isValidConsumption
            && payload.templates?.isValidConsumption
            && payload.documents?.isValidConsumption
            && payload.datasources?.isValidConsumption
        )

    }, [
        documentPermissions,
        applicationPermissions,
        assistantPermissions,
        templatesPermissions,
        deploymentPermissions,
        datasourcesPermissions,
        monitoringPermissions,
        documentCount,
        documentVolume,
        applicationCount,
        applicationVolume,
        assistantCount,
        assistantVolume,
        templatesCount,
        templatesVolume,
        datasourcesCount,
        datasourcesVolume,
        name
    ])

    useEffect(() => {

        if (isCreating && error) {
            const errMsg = parseError(error);
            operationFailed(errMsg);
        }

        if (!isAdding && isCreating && isEmpty(error)) {
            setIsCreating(false);
            onClose();
        }



    }, [isAdding])

    useEffect(() => {
        setCanSubmit(!isEmpty(name) && validConsumption)
    }, [name, validConsumption]);

    return <div>
        <div className="">
            <button className="btn-close float-end" onClick={onClose} />
        </div>

        <Nav tabs>
            <NavItem>
                <NavLink onClick={() => setactiveTab('profile')}
                    className={classnames({ active: activeTab === 'profile' })} >
                    <div className="hstack gap-1">
                        <i className=" font-size-16 bx bxs-contact" />
                        Nouveau Profile {(!isEmpty(name) ? <i className="mdi mdi-check-circle text-success font-size-16" /> : <i className="mdi mdi-alert text-danger font-size-16" />)}
                    </div>
                </NavLink>
            </NavItem>
            <NavItem>

                <NavLink onClick={() => setactiveTab('consumption')}
                    className={classnames({ active: activeTab === 'consumption' })}>
                    <div className="hstack gap-1">
                        <i className=" font-size-16 bx bxs-receipt" />
                        Plan de consommation {(validConsumption ? <i className="mdi mdi-check-circle text-success font-size-16" /> : <i className="mdi mdi-alert text-danger font-size-16" />)}
                    </div>
                </NavLink>

            </NavItem>
        </Nav>
        <TabContent activeTab={activeTab}>
            <TabPane tabId="profile">
                <div className="p-4">
                    <Row>
                        <Col md={6}>
                            <div className="border p-4">

                                <div className="mb-3">
                                    <Label className="form-label">Nom du profile</Label>
                                    <Input
                                        name="name"
                                        type="text"
                                        placeholder="Saisir le nom"
                                        onChange={e => setName(e.target.value)}
                                        value={name}
                                    />
                                </div>

                                <div className="mb-3">
                                    <Label className="form-label">Description </Label>
                                    <textarea
                                        className="form-control"
                                        rows={5}
                                        value={description}
                                        onChange={e => setDescription(e.target.value)}
                                    />
                                </div>
                            </div>

                            <div className="mb-3 mt-4 border p-4">
                                <h5 className="text-muted font-size-14">Qui peux administrer ce profil?</h5>

                                <Select
                                    options={members}
                                    onChange={setSelectedMembers}
                                    isMulti
                                    isClearable={true}
                                    value={selectedMembers}
                                    className="select2-selection mt-4"
                                />
                            </div>

                        </Col>

                        <Col md={6}>
                            <div className="border p-4">
                                <div className="border-bottom">
                                    <h5> Privillèges</h5>
                                </div>
                                <div className="p-4 ">

                                    <div className="mb-3">
                                        <Row>
                                            <Col md={3} className="mt-2">
                                                <span className="fw-bold">Documents:</span>
                                            </Col>
                                            <Col md={9}>
                                                <Select
                                                    options={permissionsModels}
                                                    value={documentPermissions}
                                                    onChange={setDocumentPermissions}
                                                    isMulti
                                                    isClearable
                                                    className="select2-selection"
                                                />
                                            </Col>
                                        </Row>
                                    </div>
                                    <div className="mb-3">
                                        <Row>
                                            <Col md={3} className="mt-2">
                                                <span className="fw-bold">Applications:</span>
                                            </Col>
                                            <Col md={9}>
                                                <Select
                                                    options={permissionsModels}
                                                    value={applicationPermissions}
                                                    onChange={setApplicationPermissions}
                                                    isMulti
                                                    isClearable
                                                    className="select2-selection"
                                                />
                                            </Col>
                                        </Row>
                                    </div>
                                    <div className="mb-3">
                                        <Row>
                                            <Col md={3} className="mt-2">
                                                <span className="fw-bold">Assistants:</span>
                                            </Col>
                                            <Col md={9}>
                                                <Select
                                                    options={permissionsModels}
                                                    value={assistantPermissions}
                                                    onChange={setAssistantPermissions}
                                                    isMulti
                                                    isClearable
                                                    className="select2-selection"
                                                />
                                            </Col>
                                        </Row>
                                    </div>

                                    <div className="mb-3">
                                        <Row>
                                            <Col md={3} className="mt-2">
                                                <span className="fw-bold">Templates:</span>
                                            </Col>
                                            <Col md={9}>
                                                <Select
                                                    options={permissionsModels}
                                                    value={templatesPermissions}
                                                    onChange={setTemplatesPermissions}

                                                    isMulti
                                                    isClearable
                                                    className="select2-selection"
                                                />
                                            </Col>
                                        </Row>
                                    </div>


                                    <div className="mb-3">
                                        <Row>
                                            <Col md={3} className="mt-2">
                                                <span className="fw-bold">Déploiements:</span>
                                            </Col>
                                            <Col md={9}>
                                                <Select
                                                    options={permissionsModels}
                                                    value={deploymentPermissions}
                                                    onChange={setDeploymentPermissions}
                                                    isMulti
                                                    isClearable
                                                    className="select2-selection"
                                                />
                                            </Col>
                                        </Row>
                                    </div>
                                    <div className="mb-3">
                                        <Row>
                                            <Col md={3} className="mt-2">
                                                <span className="fw-bold">Sources de données:</span>
                                            </Col>
                                            <Col md={9}>
                                                <Select
                                                    options={permissionsModels}
                                                    value={datasourcesPermissions}
                                                    onChange={setDatasourcesPermissions}
                                                    isMulti
                                                    isClearable
                                                    className="select2-selection"
                                                />
                                            </Col>
                                        </Row>
                                    </div>

                                    <div className="mb-3">
                                        <Row>
                                            <Col md={3} className="mt-2">
                                                <span className="fw-bold">Suivi des usages:</span>
                                            </Col>
                                            <Col md={9}>
                                                <Select
                                                    options={permissionsModels}
                                                    value={monitoringPermissions}
                                                    onChange={setMonitoringPermissions}
                                                    isMulti
                                                    isClearable
                                                    className="select2-selection"
                                                />
                                            </Col>
                                        </Row>
                                    </div>

                                    <div className="mb-3">
                                        <Row>
                                            <Col md={3} className="mt-2">
                                                <span className="fw-bold">Choix des modèles:</span>
                                            </Col>
                                            <Col md={9}>
                                                <Select
                                                    options={models}
                                                    value={modelsPermissions}
                                                    onChange={setModelPermissions}
                                                    isMulti
                                                    isClearable
                                                    className="select2-selection"
                                                />
                                            </Col>
                                        </Row>
                                    </div>
                                </div>

                            </div>
                        </Col>
                    </Row>
                    <div className="hstack gap-2 float-end mt-4">
                        <button className="btn btn-success" disabled={!canSubmit || isAdding} onClick={onSubmit}> Sauvegarder</button>
                        <button className="btn btn-danger " onClick={onClose}> Annuler</button>
                        {isAdding && <div className="vr" />}
                        {isAdding && <Spinner />}

                    </div>
                </div>
            </TabPane>
            <TabPane tabId="consumption">
                <div className="p-4">
                    <Row>
                        <Col md={7}>
                            <div className="">

                                <div className=" ">
                                    <div className="mb-3 border p-4">

                                        <div className="border-bottom mb-3">
                                            <h5><i className="mdi mdi-cog" /> Configuration</h5>
                                        </div>

                                        <div className="hstack gap-2 p-2 mb-4">
                                            <span className="p-2 rounded mt-4 fw-bold bg-primary text-white text-center me-2" style={{ width: "120px" }}>Documents:</span>
                                            <div>
                                                <Label>Nombre de création: </Label>
                                                <Input
                                                    min={0}

                                                    type="number"
                                                    className="form-control"
                                                    value={documentCount}
                                                    onChange={e => setDocumentCount(e.target.value)}
                                                />
                                            </div>

                                            <div>
                                                <Label>Volume de données (Mo): </Label>
                                                <Input
                                                    min={0}

                                                    type="number"
                                                    className="form-control"
                                                    value={documentVolume}
                                                    onChange={e => setDocumentVolume(e.target.value)}
                                                />
                                            </div>
                                            {!profile.documents?.isValidConsumption && <i className="mdi mdi-alert text-warning font-size-20 mt-4 " />}
                                            {profile.documents?.isValidConsumption && <i className="mdi mdi-check-circle text-success font-size-20 mt-4 " />}


                                        </div>

                                        <div className="hstack gap-2 p-2 mb-4">
                                            <span className="p-2 rounded mt-4 fw-bold bg-primary text-white text-center me-2" style={{ width: "120px" }}> Applications:</span>
                                            <div>
                                                <Label>Nombre de création: </Label>
                                                <Input
                                                    min={0}

                                                    type="number"
                                                    value={applicationCount}
                                                    onChange={e => SetApplicationCount(e.target.value)}
                                                />
                                            </div>

                                            <div>
                                                <Label>Volume de données (Mo): </Label>
                                                <Input
                                                    min={0}

                                                    type="number"
                                                    value={applicationVolume}
                                                    onChange={e => SetApplicationVolume(e.target.value)}
                                                />
                                            </div>
                                            {!profile.applications?.isValidConsumption && <i className="mdi mdi-alert text-warning font-size-20 mt-4 " />}
                                            {profile.applications?.isValidConsumption && <i className="mdi mdi-check-circle text-success font-size-20 mt-4 " />}

                                        </div>


                                        <div className="hstack gap-2 p-2 mb-4">
                                            <span className="p-2 rounded mt-4 fw-bold bg-primary text-white text-center me-2" style={{ width: "120px" }}> Assistants:</span>
                                            <div>
                                                <Label>Nombre de messages: </Label>
                                                <Input
                                                    min={0}
                                                    type="number"
                                                    value={assistantCount}
                                                    onChange={e => SetAssistantCount(e.target.value)}
                                                />
                                            </div>

                                            <div>
                                                <Label>Volume de données (Mo): </Label>
                                                <Input
                                                    min={0}
                                                    type="number"
                                                    value={assistantVolume}
                                                    onChange={e => SetAssistantVolume(e.target.value)}
                                                />
                                            </div>
                                            {!profile.assistants?.isValidConsumption && <i className="mdi mdi-alert text-warning font-size-20 mt-4 " />}
                                            {profile.assistants?.isValidConsumption && <i className="mdi mdi-check-circle text-success font-size-20 mt-4 " />}

                                        </div>

                                        <div className="hstack gap-2 p-2 mb-4">
                                            <span className="p-2 rounded mt-4 fw-bold bg-primary text-white text-center me-2" style={{ width: "120px" }}> Templates:</span>
                                            <div>
                                                <Label>Nombre de création: </Label>
                                                <Input
                                                    min={0}
                                                    type="number"
                                                    value={templatesCount}
                                                    onChange={e => SetTemplatesCount(e.target.value)}
                                                />
                                            </div>

                                            <div>
                                                <Label>Volume de données (Mo): </Label>
                                                <Input
                                                    min={0}
                                                    type="number"
                                                    value={templatesVolume}
                                                    onChange={e => SetTemplatesVolume(e.target.value)}
                                                />
                                            </div>
                                            {!profile.templates?.isValidConsumption && <i className="mdi mdi-alert text-warning font-size-20 mt-4 " />}
                                            {profile.templates?.isValidConsumption && <i className="mdi mdi-check-circle text-success font-size-20 mt-4 " />}

                                        </div>

                                        <div className="hstack gap-2 p-2 mb-4">
                                            <span className="p-2 rounded mt-4 fw-bold bg-primary text-white text-center me-2 font-size-12" style={{ width: "120px" }}>Sources de données:</span>
                                            <div>
                                                <Label>Nombre de créations: </Label>
                                                <Input
                                                    type="number"
                                                    value={datasourcesCount}
                                                    onChange={e => SetDatasourcesCount(e.target.value)}
                                                    min={0}
                                                />
                                            </div>

                                            <div>
                                                <Label>Volume de données (Mo): </Label>
                                                <Input
                                                    min={0}
                                                    type="number"
                                                    value={datasourcesVolume}
                                                    onChange={e => SetDatasourcesVolume(e.target.value)}
                                                />
                                            </div>
                                            {!profile.datasources?.isValidConsumption && <i className="mdi mdi-alert text-warning font-size-20 mt-4 " />}
                                            {profile.datasources?.isValidConsumption && <i className="mdi mdi-check-circle text-success font-size-20 mt-4 " />}

                                        </div>


                                    </div>
                                </div>
                            </div>
                        </Col>

                        <Col lg={5}>
                            <Card className="">
                                <CardBody>
                                    <div className="favorite-icon">
                                        <Link to="#"><i className="uil uil-heart-alt fs-18"></i></Link>
                                    </div>
                                    <img src={ConsumptionIcon} alt="" height="120" className="mb-3" />
                                    <h5 className="fs-17 mb-2">
                                        <Link to="#" className="text-dark fw-bold">Qu'est ce qu'un plan de consommation  ?</Link></h5>
                                    <p className=""> Un plan de consommation vous permet de parametrer les <b>usages</b> dans Jask en limitant le nombre de requêtes ou le volume de données manipulées par vos utilisateurs.
                                        Les quotas seront toujours vérifiés en premier indépendamment des limitations individuelles de chaque ressource ( application, assistant, tâches, ...)
                                    </p>

                                    <h5 className="fs-17 mb-2 mt-4">
                                        <Link to="#" className="text-dark fw-bold">Comment l'utiliser  ?</Link></h5>

                                    <p>Lorsque vous définissez un plan de consommation, vous pouvez l'affecter à un utilisateur ou à l'ensemble d'un groupe d'utilisateurs. Dans le cadre d'un groupe, les quotas seront partagés au sein du groupe.</p>

                                    <div className="mt-3 hstack gap-2">
                                        <span className="badge rounded-1 badge-soft-info">Quota</span>
                                        <span className="badge rounded-1 badge-soft-info">Requêtes</span>
                                        <span className="badge rounded-1 badge-soft-info">Volumes</span>
                                        <span className="badge rounded-1 badge-soft-info">...</span>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </TabPane>
        </TabContent>


    </div >
}

export default AddRoleProfile;