import PropTypes from 'prop-types'
import React, { useEffect, useState } from "react"
import { Modal, ModalBody, ModalFooter, ModalHeader, Table } from "reactstrap"
import SimpleBar from "simplebar-react";
import { isEmpty } from 'lodash';
import { Link } from 'react-router-dom';
import { getFileIcon, getProviderLogo, truncateFromMiddle } from 'utils';
import { browseDatasource } from 'service/datasources';
import { toLocalDate } from 'helpers/to-local-date';
import { ResourceTypes } from 'constants/general';


const LoadingPlaceholder = () => {


    return (
        <tbody>
            <tr className="placeholder-glow">
                <td><span className="placeholder col-12"></span></td>
                <td><span className="placeholder col-12"></span></td>
                <td><span className="placeholder col-12"></span></td>
                {/* <td><span className="placeholder col-4"></span></td>
                            <td><span className="placeholder col-4"></span></td> */}

            </tr>

            <tr className="placeholder-glow">
                <td><span className="placeholder col-12"></span></td>
                <td><span className="placeholder col-12"></span></td>
                <td><span className="placeholder col-12"></span></td>
                {/* <td><span className="placeholder col-4"></span></td>
                            <td><span className="placeholder col-4"></span></td> */}

            </tr>

            <tr className="placeholder-glow">
                <td><span className="placeholder col-12"></span></td>
                <td><span className="placeholder col-12"></span></td>
                <td><span className="placeholder col-12"></span></td>
                {/* <td><span className="placeholder col-4"></span></td>
                            <td><span className="placeholder col-4"></span></td> */}

            </tr>
            <tr className="placeholder-glow">
                <td><span className="placeholder col-12"></span></td>
                <td><span className="placeholder col-12"></span></td>
                <td><span className="placeholder col-12"></span></td>
                {/* <td><span className="placeholder col-4"></span></td>
                            <td><span className="placeholder col-4"></span></td> */}

            </tr>

            <tr className="placeholder-glow">
                <td><span className="placeholder col-12"></span></td>
                <td><span className="placeholder col-12"></span></td>
                <td><span className="placeholder col-12"></span></td>
                {/* <td><span className="placeholder col-4"></span></td>
                            <td><span className="placeholder col-4"></span></td> */}

            </tr>

        </tbody>

    )
}

const DatasourceBrowser = ({ show, onCloseClick, onConfirm, datasource }, props) => {
    const [currentDir, setCurrentDir] = useState({ location: "/", isDir: true })
    const [directoryContent, setDirectoryContent] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [previousDir, setPreviousDir] = useState([]);
    const [selectedFiles, setSelectedFiles] = useState([]);

    const selectAll = (evt) => {
        const files = [...directoryContent];

        files.forEach(f => {
            f.isSelected = evt.target.checked;
        });

        setDirectoryContent(files)
    }

    const onFileSelected = (file) => {
        const files = [...directoryContent];
        files.forEach(f => {
            if (file.location === f.location) {
                f.isSelected = f.isSelected ? false : true;
            }
        });
        setDirectoryContent(files)

    }

    const changeDir = (e, item) => {
        if (e.detail !== 2)
            return;

        if (!item.isDir) {
            window.open(item.webUrl, "_blank");
            return;
        }

        setPreviousDir([...previousDir, { ...currentDir }]);
        setCurrentDir(item);

        // if (item.location === currentDir?.location)
        //     handleFileClick();
    }

    const browseCurrentDir = () => {

        if (!currentDir?.isDir)
            return;

        const provider = {
            type: datasource?.provider?.type || datasource.type,
            connection: {}
        }

        setIsLoading(true);
        browseDatasource({
            datasourceId: datasource.id,
            info: provider,
            location: currentDir?.location
        }).then(data => {
            setDirectoryContent(data);
        }).finally(() => {
            setIsLoading(false);
        });
    }

    const onParentClick = () => {
        setCurrentDir(previousDir.pop())
    }

    useEffect(() => {

        if (show === false || isEmpty(datasource))
            return;

        browseCurrentDir();

    }, [show, datasource]);

    useEffect(() => {
        if (isEmpty(datasource))
            return;
        browseCurrentDir();
    }, [currentDir])


    useEffect(() => {
        setSelectedFiles(directoryContent.filter(f => f.isSelected).map(file => ({
            id: file.id,
            name: file.name,
            location: file.location,
            isDir: file.isDir,
            type: file.isDir ? ResourceTypes.DIRECTORY : ResourceTypes.FILE
        })));
    }, [directoryContent]);
    return (
        <Modal size="xl" isOpen={show} toggle={onCloseClick} contentClassName="browseFile" centered={true} fade={false} {...props}>
            <ModalHeader>
                <h5>{getProviderLogo(datasource?.provider?.type || datasource?.type, 24)} {datasource?.name}</h5>
                <button type="button" onClick={onCloseClick} className="btn-close position-absolute end-0 top-0 m-3"></button>
            </ModalHeader>
            <div className="modal-content">
                <ModalBody className="">
                    <div className="table-responsive w-100 h-100">
                        <SimpleBar className='window-scroll'>
                            <Table className="table align-middle table-nowrap table-hover mb-0">
                                <thead className='bg-info bg-opacity-50 rounded'>
                                    <tr>
                                        <th scope="col">
                                            <div className="form-check   ms-1">
                                                <input className="form-check-input  font-size-16" type="checkbox" id="selectAll" onChange={selectAll} />
                                                <span className="ms-2">Nom</span>
                                                <label className="form-check-label" htmlFor="selectAll"></label>
                                            </div>

                                        </th>
                                        <th scope="col">Modifié par </th>
                                        <th scope="col">Date de création</th>

                                    </tr>
                                </thead>
                                {!isLoading && <tbody>
                                    {currentDir?.location !== "/" && <tr>
                                        <td><Link onClick={onParentClick}><div className='hstack gap-1'><i className='mdi mdi-folder text-warning font-size-20 ms-4' /> <h4>..</h4></div></Link></td>
                                        <td></td>
                                        <td></td>
                                    </tr>}
                                    {directoryContent.map((item, key) => {

                                        return <tr key={key} onClick={(e) => changeDir(e, item)}>

                                            <td>
                                                <div className="hstack gap-1">
                                                    <div className="form-check font-size-16">
                                                        <input className="form-check-input" type="checkbox" id={item.location} checked={item.isSelected} onClick={e => onFileSelected(item)} />
                                                        <label className="form-check-label" htmlFor="upcomingtaskCheck01"></label>
                                                    </div>

                                                    <Link to="#" className="text-dark fw-medium">
                                                        <div className="vstack">
                                                            <div className="hstack gap-1">
                                                                {item.isDir ? <i className="mdi mdi-folder me-1 font-size-20 text-warning" /> : getFileIcon(item.name, 20)}
                                                                <span> {truncateFromMiddle(item.name, 100)}</span>
                                                                {item.metadata && item.metadata.indexingStatus === 'success' && <i className="mdi mdi-cloud-check text-success ms-2" />}
                                                            </div>
                                                            {!item.isDir && <small className="ms-4 text-muted">Taille: {(item.size / 1000).toFixed(2)} KB | Modifié le {toLocalDate(item.lastModified)}</small>}
                                                        </div>

                                                    </Link>
                                                </div>
                                            </td>
                                            <td>
                                                <div className='vstack'>
                                                    <span>{item.createdBy}</span>
                                                    <span className='text-muted font-size-10'>{item.createdByEmail}</span>
                                                </div>

                                            </td>
                                            <td>{toLocalDate(item.createdAt)}</td>
                                            {/* <td></td>
                                            <td>

                                            </td> */}
                                        </tr>
                                    })}
                                </tbody>}

                                {isLoading && <LoadingPlaceholder />}
                            </Table>
                        </SimpleBar>
                    </div>


                </ModalBody>
                <ModalFooter>
                    <div className="hstack gap-2 ">
                        <button type="button" className="btn btn-success" onClick={() => onConfirm(selectedFiles)}>Valider</button>
                        <button type="button" className="btn btn-secondary" onClick={onCloseClick}>Annuler</button>
                    </div>
                </ModalFooter>
            </div>
        </Modal>
    )
}

DatasourceBrowser.propTypes = {
    onCloseClick: PropTypes.func,
    onDeleteClick: PropTypes.func,
    show: PropTypes.any
}

export default DatasourceBrowser
