import { ResourceTypes } from "constants/general";
import { isEmpty, map } from "lodash";
import React, { Fragment, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Col, Row } from "reactstrap";
import {
    getStudioApps, createOmniChatConversation,
    showRightSidebarAction
} from "store/actions";


const AssistantsCardList = ({ onClickApp }) => {

    const dispatch = useDispatch();
    const { assistants } = useSelector(state => ({
        assistants: state.Studio.assistants
    }));

    const startAssistant = (app) => {
        const conversationInfo = {
            ...app,
            id: app.id,
            name: app.name,
            type: app.type,
            appId: app.id,
            appOwner: app.principalId,
            description: app.description,
            agentName: app.name,
            agentLogo: app.logoUrl
        }

        dispatch(createOmniChatConversation(conversationInfo));
        dispatch(showRightSidebarAction({
            type: ResourceTypes.ASSISTANTS,
            conversation: conversationInfo,
        }));

        if (onClickApp)
            onClickApp(app);
    }

    useEffect(() => {
        if (isEmpty(assistants))
            dispatch(getStudioApps())
    }, [dispatch])


    return <Fragment>
        {!isEmpty(assistants) && <div className="d-flex flex-row flex-wrap assistant-avatars m-4">
            {map(assistants, item => {
                return <div key={item.id} className="d-flex justify-content-center align-items-center " >
                    <div className="ps-4 pe-4 avatar " onClick={() => startAssistant(item)}>
                        {item.logoUrl ? <img src={item.logoUrl} width={60} height={80} /> : <img src={BotIcon} width={60} />}
                        <div className=" mt-2 text-center text-muted" >
                            <p className="">{item.name}</p>
                        </div>
                    </div>
                </div>

            })}
        </div>}
        {isEmpty(assistants) && <div className="hstack gap-2 text-dark p-3">
            <i className="mdi mdi-information text-info font-size-20 mb-3" />
            <p> Vous n'avez aucun assistant pour l'instant.<br />
            </p>
        </div>}
    </Fragment>
}

export default AssistantsCardList;