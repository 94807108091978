import { API_URL, post } from "helpers/api_helper";
import { GET_SUPPORT_REQUESTS, RESOLVE_SUPPORT_REQUESTS } from "helpers/url_helper";
import { fetchEventSource } from "@microsoft/fetch-event-source";

export const resolveSupportRequest = async (payload, onStream, onStart, onComplete, onError, accessToken, cancellation) => {

    try {
        await fetchEventSource(`${API_URL}${RESOLVE_SUPPORT_REQUESTS}`,
            {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${accessToken}`,
                },
                body: JSON.stringify(payload),
                signal: cancellation.signal,
                openWhenHidden: true,
                onopen(res) {
                    if (res.ok && res.status === 200) {
                        console.log("Connection made ", res);
                        onStart();
                    } else if (
                        res.status >= 400 &&
                        res.status < 500 &&
                        res.status !== 429
                    ) {
                        console.log("Client side error ", res);
                        onError(res);
                    }
                },
                onmessage(event) {
                    try {
                        const parsedEvent = JSON.parse(event.data);
                        onStream(parsedEvent);
                    } catch (err) {
                        console.log("Parsing error ", err)
                    }
                },
                onclose() {
                    console.log("Connection closed by the server");
                    onComplete();
                },
                onerror(err) {
                    console.log("There was an error from server:", err);
                    onError(err);
                    throw err
                },
            });
    }

    catch (err) {
        onError(err);
    }

}

export const postSupportRequestReply = (payload) => {
    return post(`${GET_SUPPORT_REQUESTS}/reply`, payload);
}