import React from "react";
import {
    Container
} from "reactstrap";
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb2";

//i18n
import { withTranslation } from "react-i18next";
const CrmContacts = props => {

    //meta title
    document.title = "Scenarios | Jask";

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs
                        title={props.t("Scénario")}
                        breadcrumbItems={[
                            {
                                title: "Jask"
                            },
                            {
                                title: "Automatisation"
                            },
                            {
                                title: "Scénario"
                            }
                        ]}
                    />
                </Container>
            </div>
        </React.Fragment>
    );
};


export default withTranslation()(CrmContacts);
