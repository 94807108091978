import { map } from "lodash";
import React, { useState, useEffect } from "react";
import classnames from "classnames";
import { Card, CardBody, CardTitle, DropdownItem, DropdownMenu, DropdownToggle, Label, Modal, Nav, NavItem, NavLink, TabContent, TabPane, UncontrolledDropdown } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import { withTranslation } from "react-i18next";
import PromptSore from "./store"

const PromptStoreBrowser = ({ show, onPromptSelected, onCloseClick, t }) => {

    return <Modal isOpen={show} toggle={onCloseClick}
        centered={true}
        fade={false}
        size="xl"
        unmountOnClose={true}
    >
        <div className="modal-content prompt-store-browser p-4">
            <PromptSore onPromptSelected={onPromptSelected} />
        </div>
    </Modal>
}

export default withTranslation()(PromptStoreBrowser);