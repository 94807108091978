import { isEmpty } from 'lodash';
import React, { Fragment, memo, ReactNode, useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Select from "react-select";

const tables = [
    {
        label: "Bing Search",
        value: "task"
    }
]


const InternetSearchNode = ({ data, onChange, onValidityChanged }) => {

    const [urls, setUrls] = useState([]);

    useEffect(() => {
        onChange({
            ...data,
            providerConfig: {
                urls: urls,
            },
            viewState: true
        });
    }, [urls])


    useEffect(() => {
        // if (data?.providerConfig?.engine) {
        //     const option = tables.filter(t => t.value === data.providerConfig.engine)[0];
        //     setSelectedTable(option);
        // }
    }, [data])

    return <div className='nodrag' >

        <div className='mb-2 ' >
            <Link><i className='mdi mdi-plus' /> filtre Url </Link>
        </div>

    </div>
}

export default InternetSearchNode;