import React, { useEffect, useState } from "react";
import {
    Card, Nav, NavItem, NavLink,
    UncontrolledDropdown,
    DropdownMenu,
    DropdownToggle,
    DropdownItem
} from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import { withTranslation } from "react-i18next";
import classnames from "classnames";
import { useSelector, useDispatch } from "react-redux";
import SimpleBar from "simplebar-react";
import { map, truncate } from "lodash";
import { ResourceTypes } from "constants/general";
import { getProviderLogo } from "utils";
import DeployAssistant from "../assistant/deploy";
import FileTaskLauncher from "components/FileTask";

//meta title
document.title = "Mes ressources | Jask";

const AssistantResource = ({ item, currentItemId }) => {

    const [showNewMessage, setShowNewMessage] = useState(false);
    const [isTyping, setIsTyping] = useState(false);
    const { channels } = useSelector(state => ({
        channels: state.Streams.channels,
    }));

    useEffect(() => {
        const typing = channels[item?.id]?.isTyping;
        setIsTyping(typing);

        if (typing && item.id !== currentItemId) {
            setShowNewMessage(true);
        }
    }, [channels]);


    useEffect(() => {
        if (currentItemId === item.id) {
            setShowNewMessage(false);
        }
    }, [currentItemId])

    return <div className="hstack">

        <img
            className=" me-1 rounded-circle"
            src={item.logoUrl}
            alt={item.name}
            height="40"
        />
        <div className="vstack">
            <span className="user-title m-0 fw-bold">{item.name}</span>
            <span className="text-muted font-size-10">{truncate(item.description)}</span>
            {isTyping && <span className="text-muted fw-bold font-size-10">écrit...</span>}
        </div>
        {showNewMessage && <span className="badge rounded-pill bg-danger float-end">
            1
        </span>}
    </div>
}

const ResourcesLeftBar = ({ assistants, datasources, onResourceSelected, selectedResource, t }, props) => {
    const [activeTab, setactiveTab] = useState(0);
    const dispatch = useDispatch();
    const [showCreateAssistant, setShowCreateAssistant] = useState(false);
    const [showCustomFileTask, setShowCustomFileTask] = useState(false);
    const [showDatasources, setShowDatasources] = useState(false);
    const [showAssistants, setShowAssistants] = useState(true);
    const navigate = useNavigate();

    const invertView = () => {
        setShowAssistants(!showAssistants);
        setShowDatasources(!showDatasources);
    }

    const onCreateApp = () => {
        navigate("/studio/designer", {
            state: {
                new: true
            }
        })
    }

    const onCreateTemplate = () => {
        onResourceSelected({
            type: ResourceTypes.TEMPLATES,
            create: true
        });
    }

    const onProcessFile = () => {
        setShowCustomFileTask(!showCustomFileTask);
    }

    useEffect(() => {
        onResourceSelected({
            type: ResourceTypes.FILESYSTEM,

        });
    }, []);

    useEffect(() => {
        if (selectedResource?.type === ResourceTypes.ASSISTANTS && selectedResource?.id !== activeTab) {
            setactiveTab(selectedResource?.assistant?.id);
            console.log("Changing tab ", selectedResource)
        }
    }, [selectedResource])

    return (
        <React.Fragment>
            <DeployAssistant
                show={showCreateAssistant}
                onCloseClick={() => setShowCreateAssistant(false)}
            />
            <FileTaskLauncher
                show={showCustomFileTask}
                onCloseClick={() => setShowCustomFileTask(false)}
            />
            <Card className="workspace-resources-leftbar">
                <UncontrolledDropdown>
                    <DropdownToggle clas tag={"btn"} className="w-100 btn btn-danger">
                        {t("Nouveau")}
                    </DropdownToggle>
                    <DropdownMenu className="w-100">
                        <DropdownItem onClick={() => setShowCreateAssistant(!showCreateAssistant)}>

                            <div className="vstack">
                                <div className="hstack gap-1">
                                    <i className="bx bxs-bot font-size-20 text-info" />
                                    <span className="font-size-14 fw-bold ">{t("Assistant Virtuel")}</span>
                                </div>

                                <p className="text-wrap font-size-10 ms-4">{t("Un chatbot personnalisable qui peut etre connecté à vos données.")}</p>
                            </div>

                        </DropdownItem>
                        <DropdownItem divider />
                        <DropdownItem onClick={onCreateApp}>
                            <div className="vstack">
                                <div className="hstack gap-1">
                                    <i className="mdi mdi-apps font-size-20 text-info" />
                                    <span className="font-size-14 fw-bold ">Applications</span>
                                </div>

                                <p className="text-wrap font-size-10 ms-4">{t("Créer un workflow ou processus metiers avec plusieurs étapes de prompts.")}</p>
                            </div>
                        </DropdownItem>
                        <DropdownItem divider />

                        <DropdownItem onClick={onProcessFile}>
                            <div className="vstack">
                                <div className="hstack gap-1">
                                    <i className="bx bxs-file  font-size-20 text-info" />
                                    <span className="font-size-14 fw-bold ">{t("Traitement de fichiers")}</span>
                                </div>

                                <p className="text-wrap font-size-10 ms-4">{t("Analyser un ou plusieurs fichiers en appliquant vos instructions, vos applications et bien plus...")}</p>
                            </div>
                        </DropdownItem>


                        <DropdownItem onClick={onCreateTemplate}>
                            <div className="vstack">
                                <div className="hstack gap-1">
                                    <i className="bx bxs-detail  font-size-20 text-info" />
                                    <span className="font-size-14 fw-bold ">Templates</span>
                                </div>

                                <p className="text-wrap font-size-10 ms-4">{t("Configurer vos modèles de documents et automatiser la génération (contrats, ...).")}</p>
                            </div>
                        </DropdownItem>
                    </DropdownMenu>
                </UncontrolledDropdown>
                <div className="mail-list mt-4">

                    <Nav tabs className="nav-tabs-custom" vertical role="tablist">
                        <NavItem>
                            <NavLink
                                className={classnames({
                                    active: activeTab === 0,
                                })}
                                onClick={() => {
                                    setactiveTab(0);
                                    onResourceSelected({
                                        type: ResourceTypes.FILESYSTEM
                                    });
                                }}
                            >
                                <div className="hstack gap-2">
                                    <i className="mdi mdi-folder font-size-20 text-warning "></i> {t("Mes documents")}{" "}
                                </div>                            </NavLink>
                        </NavItem>

                        <NavItem>
                            <NavLink
                                className={classnames({
                                    active: activeTab === 1,
                                })}
                                onClick={() => {
                                    setactiveTab(1);
                                    onResourceSelected({
                                        type: ResourceTypes.TEMPLATES
                                    });
                                }}
                            >
                                <div className="hstack gap-2">
                                    <i className="bx bxs-detail font-size-20 "></i>{t("Mes Templates")}
                                </div>
                            </NavLink>
                        </NavItem>

                        <NavItem>
                            <NavLink
                                className={classnames({
                                    active: activeTab === 2,
                                })}
                                onClick={() => {
                                    setactiveTab(2);
                                    onResourceSelected({
                                        type: ResourceTypes.APPLICATIONS
                                    });
                                }}
                            ><div className="hstack gap-2">
                                    <i className="bx bx-code  font-size-20"></i>{t("Mes applications")}
                                </div>
                            </NavLink>
                        </NavItem>
                    </Nav>
                </div>

                {/* <Link onClick={invertView}>
                    <h6 className="mt-4"><i className="mdi mdi-database" /> {t("Sources de données")} ({datasources.length})
                        <Link onClick={invertView}>
                            {!showDatasources && <span className="mdi mdi-arrow-right-drop-circle text-info float-end"></span>}
                            {showDatasources && <span className="mdi mdi-arrow-down-drop-circle text-info float-end"></span>}
                        </Link>
                    </h6></Link> */}

                {/* <div className="datasources-list mt-1">
                    {showDatasources && <SimpleBar className=" datasources-scroller">
                        <Nav tabs className="nav-tabs-custom" vertical role="tablist">

                            {
                                map(datasources, item => {
                                    return <NavItem key={item.id}>
                                        <NavLink onClick={() => {
                                            setactiveTab(item.id);
                                            onResourceSelected({
                                                type: ResourceTypes.DATASOURCES,
                                                datasource: item,
                                                name: item.name,
                                                description: item.description,
                                                icon: getProviderLogo(item.provider?.type, 14)
                                            });
                                        }}

                                            className={classnames({
                                                active: activeTab === item.id,
                                            })}
                                        >
                                            <div className="hstack gap-2 text-muted">
                                                {getProviderLogo(item.provider?.type, 14)}
                                                {item.name}
                                            </div>
                                        </NavLink>
                                    </NavItem>
                                })
                            }
                        </Nav>
                    </SimpleBar>}
                </div> */}
                <Link onClick={invertView}>

                    <h6 className="mt-4"><i className="mdi mdi-chat" /> {t("Mes assistants")} ({assistants.length})
                        <Link onClick={invertView}>
                            {!showAssistants && <span className="mdi mdi-arrow-right-drop-circle text-info float-end"></span>}
                            {showAssistants && <span className="mdi mdi-arrow-down-drop-circle text-info float-end"></span>}
                        </Link></h6>
                </Link>
                <div className="mt-2 assistants-list">
                    {showAssistants && <SimpleBar className="assistants-scroller">
                        <Nav tabs className="nav-tabs-custom" vertical role="tablist">
                            {
                                map(assistants, item => {
                                    return <NavItem key={item.id}>
                                        <NavLink onClick={() => {
                                            setactiveTab(item.id);
                                            onResourceSelected({
                                                id: item.id,
                                                type: ResourceTypes.ASSISTANTS,
                                                assistant: item,
                                                name: item.name,
                                                description: item.description,
                                                logoUrl: item.logoUrl
                                            });
                                        }}

                                            className={classnames({
                                                active: activeTab === item.id,
                                            }, "border-bottom")}
                                        >
                                            <AssistantResource item={item} currentItemId={activeTab} />

                                        </NavLink>
                                    </NavItem>
                                })
                            }
                        </Nav>
                    </SimpleBar>}
                </div>
            </Card>
        </React.Fragment >
    );
};

export default withTranslation()(ResourcesLeftBar);
