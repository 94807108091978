import { isEmpty } from "lodash";
import React from "react"
import Select, { components, ControlProps } from 'react-select';
import { Card } from "reactstrap";
import { getInitiales } from "utils";

export const AppIdentityCard = ({ name, logo, description }) => {

    return <div className="hstack gap-1 border-bottom ">
        {logo && <div>
            <img className=" rounded-circle avatar-xs "
                src={logo} />
        </div>}

        {!logo && <div className="avatar-xs me-1">
            <span className="avatar-title rounded-circle" style={{ width: "30px" }}>
                {getInitiales(name || "")}
            </span>
        </div>}

        <div className="vstack gap-1 text-dark">
            <span>{name}</span>
            <small className="">{description}</small>
        </div>
    </div>
}

const CustomMenuControl = props => {
    const value = props?.getValue();
    return <components.SingleValue {...props}  >
        {!isEmpty(value) ? (<AppIdentityCard
            name={value[0].label}
            description={value[0].description}
            logo={value[0].logo}
        />) : props.children}
    </components.SingleValue>
}
const Option = (props) => {
    return (
        <components.Option {...props}>
            <AppIdentityCard
                name={props.data.label}
                description={props.data.description}
                logo={props.data.logo}
            />
        </components.Option>
    );
};


const CustomMenu = ({ children, ...props }) => {
    return <components.MenuList {...props}>
        {children}
    </components.MenuList>
};

const CustomSelect = props => {

    return <Select {...props}
        className="select2-selection "
        components={{
            MenuList: CustomMenu,
            SingleValue: CustomMenuControl,
            Option: Option
        }}

    />
}

export default CustomSelect;