import SimpleTableContainer from "components/Common/SimpleTableContainer";
import TableContainer from "components/Common/TableContainer";
import { isEmpty, map } from "lodash";
import React, { useEffect, useMemo, useState } from "react"
import { Card, CardBody, Label, Modal, ModalBody, ModalFooter, ModalHeader, Spinner } from "reactstrap"
import { loadTabularFile } from "service/documents";
import SimpleBar from "simplebar-react"
import Select from 'react-select'
import { withTranslation } from "react-i18next";




const TabularTaskLauncher = ({ show, onClose, onConfigChange, onStart, columns, models }) => {

    const [loading, setLoading] = useState(false);
    const [prompt, setPrompt] = useState(null);
    const [newColumnName, setNewColumnName] = useState(null);
    const [inputColumnName, setInputColumnName] = useState(null);
    const [isValid, setIsValid] = useState(false);
    const [selectedModel, setSelectedModel] = useState(null);

    useEffect(() => {
        const state = !isEmpty(prompt) && !isEmpty(newColumnName) && !isEmpty(inputColumnName);
        onConfigChange({
            instructions: prompt,
            outputCol: newColumnName,
            inputCol: inputColumnName?.value,
            model: selectedModel?.value,
            isValid: state

        });

        setIsValid(state)
    }, [prompt, newColumnName, inputColumnName, selectedModel]);


    useEffect(() => {
        if (!isEmpty(models))
            setSelectedModel(models[0])
    }, [models]);

    return <Modal
        isOpen={show}
        toggle={onClose}
        contentClassName="tabular-task-launcher"
        size="xs"
        fade={false}
        centered={true}

    >
        <div className="p-2 bg-info bg-opacity-50">
            <h5> <i className="bx bx-task" /> Nouvelle Tache</h5>
        </div>

        <ModalBody className="ps-4">
            <Card>
                <div className="mb-3">
                    <Label><i className="mdi mdi-chat text-info" /> Instructions : </Label>
                    <textarea
                        placeholder="Vos instructions..."
                        value={prompt}
                        onChange={e => setPrompt(e.target.value)}
                        className="form-control"
                        rows={3}
                    />
                </div>
                <div className="mb-4">
                    <Label> <i className="mdi mdi-table text-danger" /> Colonne à analyser : </Label>
                    <Select
                        className="select2-selection"
                        options={columns}
                        value={inputColumnName}
                        onChange={setInputColumnName}
                        isMulti={false}
                    >
                    </Select>
                </div>
                <div>
                    <Label>
                        <i className="mdi mdi-table text-success" /> Nouvelle colonne de résultats
                    </Label>

                    <input
                        type="text"
                        className="form-control"
                        value={newColumnName}
                        onChange={e => setNewColumnName(e.target.value)}
                    />
                </div>
                <div className="mt-2">

                    <Label> Modèles d'IA à utiliser: </Label>
                    <Select
                        className="select2-selection"
                        options={models}
                        value={selectedModel}
                        onChange={setSelectedModel}
                    >
                    </Select>
                </div>
            </Card>
        </ModalBody>
        <ModalFooter className="bg-info bg-opacity-25">
            <button className="btn btn-success" disabled={!isValid} onClick={onStart}>Analyser</button>
            <button className="btn btn-danger" onClick={onClose}>Annuler</button>

        </ModalFooter>

    </Modal>
}

export default TabularTaskLauncher;