

import React, { useEffect, useState } from "react";
import {
    Button,
    Card,
    CardBody,
    Col,
    Container, Form, Input, Label, Modal, NavItem, Row, TabContent, TabPane, Table
} from "reactstrap";

//i18n
import { withTranslation } from "react-i18next";
import Dropzone from "react-dropzone";
import classnames from "classnames"
import { Link, NavLink } from "react-router-dom";
import { getFileIcon, uuidv4 } from "utils";
import { deleteUploadedFile, uploadFile } from "service/documents";
import { isEmpty, map } from "lodash";
import ConfigureTemplate from "./configure"
import {
    addTemplate,
} from "store/actions";
import { useSelector, useDispatch } from "react-redux";
import TaskLoader from "components/Common/Loader";

const AddTemplate = ({ isOpen, onClose }) => {
    const [templateName, setTemplateName] = useState("")
    const [category, setCategory] = useState("")
    const [description, setDescription] = useState("")
    const [selectedFiles, setselectedFiles] = useState([]);
    const [activeTab, setactiveTab] = useState(1)
    const [passedSteps, setPassedSteps] = useState([1])
    const [canConfigure, setCanConfigure] = useState(false);
    const [canSubmit, setCanSubmit] = useState(false);
    const [configuration, setConfiguration] = useState(null);
    const [configurationState, setConfigurationState] = useState(null);
    const dispatch = useDispatch();
    const { isLoading } = useSelector(state => ({
        isLoading: state.Documents.isLoading,

    }));

    function handleAcceptedFiles(files) {
        setselectedFiles([...selectedFiles, ...files]);
    }

    function toggleTab(tab) {

        if (activeTab !== tab) {
            var modifiedSteps = [...passedSteps, tab]


            if (tab >= 1 && tab <= 3) {
                setactiveTab(tab)
                setPassedSteps(modifiedSteps)
            }
        }
    }

    const removeAttachment = file => {
        setselectedFiles([...selectedFiles.filter(a => a.name !== file.name)]);
    }

    const updateTemplateConfiguration = newConfif => {
        const filesState = {};
        const validSelectionPerFile = Object.keys(newConfif).map(location => {
            const selection = Object.values(newConfif[location]).map(i => i.selected ? 1 : 0);
            filesState[location] = selection.reduce((a, b) => a + b, 0) > 0;
            return filesState[location]
        });

        const allFilesAreValid = validSelectionPerFile.map(i => i === true ? 1 : 0).reduce((a, b) => a + b, 0) == selectedFiles.length
        setConfiguration(newConfif);
        setConfigurationState({
            isValid: allFilesAreValid,
            files: { ...filesState }
        })
    }

    const createTemplate = () => {
        const files = Object.keys(configuration).map(location => {
            const items = configuration[location];
            const fileConfig = {
                location: location,
                items: Object.values(items)
            }

            return fileConfig;
        });

        const payload = {
            name: templateName,
            description: description,
            files: files
        }

        dispatch(addTemplate(payload))
    }

    useEffect(() => {
        const canConfigure = selectedFiles.length > 0 && !isEmpty(templateName);
        setCanConfigure(canConfigure);
        setCanSubmit(activeTab === 3 && canConfigure && selectedFiles?.length > 0 && configurationState.isValid)
    }, [selectedFiles, templateName, configuration, activeTab])

    return <Modal isOpen={isOpen} toggle={onClose} size="xl"
        className='modal-dialog-centered '
        contentClassName='template-modal'
    >

        <div className="modal-header">
            <h5 className="modal-title" id="modalLabel">
                Nouveau modèle
            </h5>
            <button type="button"
                onClick={onClose} className="btn-close"></button>
        </div>
        <div className="modal-body">
            <div className="wizard clearfix">
                <div className="steps clearfix">
                    <ul>
                        <NavItem
                            className={classnames({ current: activeTab === 1 })}
                        >
                            <NavLink
                                className={classnames({ current: activeTab === 1 })}
                                onClick={() => {
                                    setactiveTab(1)
                                }}
                                disabled={!(passedSteps || []).includes(1)}
                            >
                                <span className="number">1.</span> Importez vos Fichiers
                            </NavLink>
                        </NavItem>

                        <NavItem
                            className={classnames({ current: activeTab === 2 })}
                        >
                            <NavLink
                                className={classnames({ current: activeTab === 2 })}
                                onClick={() => {
                                    setactiveTab(2)
                                }}
                                disabled={!(passedSteps || []).includes(2)}
                            >
                                <span className="number">2.</span> Configurer vos modèles
                            </NavLink>
                        </NavItem>

                        <NavItem
                            className={classnames({ current: activeTab === 3 })}
                        >
                            <NavLink
                                className={classnames({ current: activeTab === 3 })}
                                onClick={() => {
                                    setactiveTab(3)
                                }}
                                disabled={!(passedSteps || []).includes(3)}
                            >
                                <span className="number">3.</span> Confirmation
                            </NavLink>
                        </NavItem>
                    </ul>
                </div>
                <div className="content clearfix">
                    <TabContent activeTab={activeTab} className="body">
                        <TabPane tabId={1}>

                            <Row>
                                <Col md={7}>
                                    <Row>
                                        <Col md={8}>
                                            <div className="mb-3">
                                                <Label for="template">
                                                    Nom:
                                                </Label>
                                                <Input
                                                    type="text"
                                                    className="form-control"
                                                    id="template"
                                                    placeholder=""
                                                    value={templateName}
                                                    onChange={e => setTemplateName(e.target.value)}
                                                />
                                            </div>
                                        </Col>
                                        <Col md={4}>

                                            <div className="mb-3">
                                                <Label for="template">
                                                    Categorie:
                                                </Label>
                                                <Input
                                                    type="text"
                                                    className="form-control"
                                                    id="template"
                                                    placeholder=""
                                                    value={category}
                                                    onChange={e => setCategory(e.target.value)}
                                                />
                                            </div>

                                        </Col>
                                    </Row>


                                    <Row>
                                        <Col md={12}>
                                            <Label for="description">
                                                Description:
                                            </Label>
                                            <div className="mb-3">
                                                <textarea rows={3}
                                                    id="description"
                                                    className="form-control"
                                                    placeholder="..."
                                                    value={description}
                                                    onChange={e => setDescription(e.target.value)}
                                                />
                                            </div>
                                        </Col>
                                    </Row>


                                    <Form>
                                        <Dropzone
                                            onDrop={acceptedFiles => {
                                                handleAcceptedFiles(acceptedFiles)
                                            }}
                                        >
                                            {({ getRootProps, getInputProps }) => (
                                                <div className="dropzone">
                                                    <div
                                                        className="dz-message needsclick mt-2"
                                                        {...getRootProps()}
                                                    >
                                                        <input {...getInputProps()} />
                                                        <div className="mb-3">
                                                            <i className="display-4 text-muted bx bxs-cloud-upload" />
                                                        </div>
                                                        <h5>Déposez des exemples de fichiers ou cliquez ici </h5>
                                                    </div>
                                                </div>
                                            )}
                                        </Dropzone>

                                    </Form>

                                    {
                                        selectedFiles && <div className="d-flex flex-row flex-wrap">
                                            {map(selectedFiles, file => {
                                                return <div className="p-2 ">
                                                    <div to="#" className="rounded bg-light bg-opacity-50">
                                                        <div className="hstack gap-1 card-body">
                                                            {getFileIcon(file.name)}
                                                            <span className="font-size-10">{file.name}</span>
                                                            <Link onClick={() => removeAttachment(file)} ><i className="mdi mdi-close text-danger font-size-14" /></Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            })}

                                        </div>
                                    }
                                </Col>

                            </Row>
                        </TabPane>

                        <TabPane tabId={2}>
                            <ConfigureTemplate name={templateName} files={selectedFiles} onConfigurationChanged={updateTemplateConfiguration} selectionState={configurationState} />
                        </TabPane>
                        <TabPane tabId={3}>
                            <Row>
                                <Col md={6}>
                                    <div className="p-4">
                                        <div className="">
                                            <h4>Confirmez la configuration</h4>
                                        </div>
                                        <hr />
                                        <div className="table-responsive">
                                            <Table className="table align-middle table-nowrap table-hover mb-0">
                                                <tbody>
                                                    <tr >
                                                        <td>
                                                            <div className='hstack gap-2'> Nom du template:</div></td>
                                                        <td>
                                                            <div className='hstack gap-2'>
                                                                {templateName}
                                                            </div>
                                                        </td>
                                                    </tr>

                                                    <tr >
                                                        <td>
                                                            <div className='hstack gap-2'>Catégorie:</div></td>
                                                        <td>
                                                            <div className='hstack gap-2'>
                                                                {category || "Non spécifié"}
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr >
                                                        <td>
                                                            <div className='hstack gap-2'>Description:</div></td>
                                                        <td>
                                                            <div className='hstack gap-2'>
                                                                {description?.slice(0, 100) || "Non spécifié"}
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr >
                                                        <td>
                                                            <div className='hstack gap-2'>Nombre de fichiers:</div></td>
                                                        <td>
                                                            <div className='hstack gap-2'>
                                                                {selectedFiles.length}
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        </div>
                                    </div>
                                </Col>
                                <Col md={6}>
                                    {isLoading &&
                                        <TaskLoader height={"33vh"} width={100} />
                                    }
                                </Col>
                            </Row>
                        </TabPane>


                    </TabContent>

                </div>

            </div>


        </div>
        <div className="modal-footer">
            <div className="wizard clearfix">
                <div className="actions clearfix mt-4">
                    <ul>
                        <li
                            className={
                                activeTab === 1 ? "previous disabled" : "previous"
                            }
                        >
                            <Link
                                to="#!"
                                onClick={() => {
                                    toggleTab(activeTab - 1)
                                }}
                            >
                                Préc.
                            </Link>
                        </li>
                        {activeTab !== 3 && <li
                            className={(!canConfigure) ? "next disabled" : "next"}
                        >
                            <Link
                                to="#!"
                                onClick={() => {
                                    if (canConfigure)
                                        toggleTab(activeTab + 1)
                                }}
                            >
                                Suiv.
                            </Link>
                        </li>}

                        {canSubmit && <li
                        >
                            <Button className="btn btn-success" disabled={isLoading} onClick={createTemplate}>Créer</Button>
                        </li>}

                    </ul>

                </div>
            </div>
        </div>
    </Modal >
};


export default withTranslation()(AddTemplate);

