/* TASKS */
export const CREATE_CONVERSATION = "CREATE_CONVERSATION"
export const GET_CONVERSATIONS = "GET_CONVERSATIONS"
export const GET_CONVERSATION_HISTORY = "GET_CONVERSATION_HISTORY"

export const CREATE_CONVERSATION_SUCCESS = "CREATE_CONVERSATION_SUCCESS"
export const GET_CONVERSATIONS_SUCCESS = "GET_CONVERSATIONS_SUCCESS"
export const GET_CONVERSATION_HISTORY_SUCCESS = "GET_CONVERSATION_HISTORY_SUCCESS"

export const CREATE_CONVERSATION_FAILED = "CREATE_CONVERSATION_FAILED"
export const GET_CONVERSATIONS_FAILED = "GET_CONVERSATIONS_FAILED"
export const GET_CONVERSATION_HISTORY_FAILED = "GET_CONVERSATION_HISTORY_SUCCESS_FAILED"

export const DELETE_CONVERSATION = "DELETE_CONVERSATION"
export const DELETE_CONVERSATION_FAILED = "DELETE_CONVERSATION_FAILED"
export const DELETE_CONVERSATION_SUCCESS = "DELETE_CONVERSATION_SUCCESS"

export const DELETE_CONVERSATION_HISTORY = "DELETE_CONVERSATION_HISTORY"
export const DELETE_CONVERSATION_HISTORY_FAILED = "DELETE_CONVERSATION_HISTORY_FAILED"
export const DELETE_CONVERSATION_HISTORY_SUCCESS = "DELETE_CONVERSATION_HISTORY_SUCCESS"


export const UPDATE_CONVERSATION_TITLE = "UPDATE_CONVERSATION_TITLE"


export const ADD_MESSAGE_HISTORY = "ADD_MESSAGE_HISTORY"


export const GET_DATA_SERVICES = "GET_DATA_SERVICES"
export const GET_DATA_SERVICES_SUCCESS = "GET_DATA_SERVICES_SUCCESS"
export const GET_DATA_SERVICES_FAILED = "GET_DATA_SERVICES_FAILED"

export const SEARCH_RESOURCES = "SEARCH_RESOURCES"
export const SEARCH_RESOURCES_SUCCESS = "SEARCH_RESOURCES_SUCCESS"
export const SEARCH_RESOURCES_FAILED = "SEARCH_RESOURCES_FAILED"


export const GET_AI_MODELS = "GET_AI_MODELS"
export const GET_AI_MODELS_SUCCESS = "GET_AI_MODELS_SUCCESS"
export const GET_AI_MODELS_FAILED = "GET_AI_MODELS_FAILED"