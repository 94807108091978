import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import {
    Badge,
    Card,
    CardBody,
    CardFooter,
    CardTitle,
    Container
} from "reactstrap";

import { Link } from "react-router-dom";
import { getInitiales } from "utils";
import { toLocalDate } from "helpers/to-local-date";

import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import reHype from 'remark-rehype'

const FeedbackProfile = ({ item, t }) => {
    console.log(item)
    const isPositive = item.isNegative === false;
    const color = isPositive ? "bg-success bg-opacity-10" : "bg-danger bg-opacity-10"

    return <Card className={`border border-info border-opacity-25 ${color}`}>
        <CardBody>
            <CardTitle className=" ">
                <div className="d-flex justify-content-between mb-4 border-bottom border-secondary border-opacity-25 pb-2">
                    <div>
                        <div className="hstack">
                            <div className="avatar-xs me-2">
                                <span className="avatar-title rounded-circle bg-info bg-soft text-info font-size-18">
                                    {getInitiales(item.principalName)}
                                </span>
                            </div>
                            <div className="vstack">
                                <span className="font-size-14 ">{item.principalName} {item.status == "open" && <Badge color="danger" className="ms-2">{t("A résoudre")}</Badge>}</span>
                                <small className="font-size-10">{toLocalDate(item.createdAt)}</small>
                            </div>
                        </div>
                    </div>

                    <div className="float-end"> {isPositive ? <i className="mdi mdi-thumb-up font-size-18 text-success" /> : <i className="mdi mdi-thumb-down font-size-18 text-danger" />}</div>

                </div>

            </CardTitle>
            <div>
                {item.content}
            </div>


            {/* <div className="p-2 border border-secondary mt-3 border-opacity-25">
                <div className="border-bottom border-secondary border-secondary border-opacity-25 pb-1 mb-2">
                    <img src={item.assistantReply.logoUrl} />{item.principalName}
                </div>
                <ReactMarkdown className="markdown" remarkPlugins={[remarkGfm, reHype]}>{item.userQuery.text}</ReactMarkdown>

                <div className="p-2 border border-secondary mt-3 border-opacity-25">
                    <div className="border-bottom border-secondary border-secondary border-opacity-25 pb-1 mb-2">
                        <img src={item.assistantReply.logoUrl} />{item.assistantReply.agentName}
                    </div>
                    <ReactMarkdown className="markdown" remarkPlugins={[remarkGfm, reHype]}>{item.assistantReply.text}</ReactMarkdown>
                </div>
            </div> */}



            <div className="d-flex justify-content-between mt-4">
                <div className=" ">
                    <div className="hstack gap-1">
                        <Link className="font-size-10 ">{t("Voir le détail")}</Link>
                        {/* <div className="vr" />
                        <Link className="font-size-10">{t("Créer un ticket")}</Link> */}

                    </div>

                </div>
                <div className=" ">
                </div>
            </div>

        </CardBody>


    </Card>
}

export default withTranslation()(FeedbackProfile);