import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import SimpleBar from "simplebar-react"
import {
    Table,
    DropdownMenu,
    DropdownToggle,
    UncontrolledDropdown,
    DropdownItem,
    Card,
    CardBody,
} from "reactstrap"

const DocumentPlanPlaceHolders = () => {


    return (
        <React.Fragment>
            <div className="workspace-documents-editor ">
                <Card>
                    <CardBody>
                        <div className="d-flex">

                            <div className="p-2 border workspace-document-structure">
                                <SimpleBar className="rich-text-editor placeholder-glow">
                                    <div className=" ps-2 pt-2 ">
                                        <h5> Chargement du plan ... </h5>
                                        <hr />
                                    </div>
                                    <div className="mb-3">

                                        <div className="hstack gap-2 mb-2 mt-2">
                                            <span className="placeholder placeholder-lg col-6"></span>
                                        </div>

                                        <div className="hstack gap-2 mb-2 mt-2">
                                            <span className="placeholder placeholder-xs col-11"></span>
                                        </div>

                                        <div className="hstack gap-2 mb-2 mt-2">
                                            <span className="placeholder placeholder-xs col-11"></span>
                                        </div>
                                        <div className="hstack gap-2 mb-2 mt-2">
                                            <span className="placeholder placeholder-xs col-11"></span>
                                        </div>
                                        <div className="hstack gap-2 mb-2 mt-2">
                                            <span className="placeholder placeholder-xs col-11"></span>
                                        </div>
                                        <div className="hstack gap-2 mb-2 mt-2">
                                            <span className="placeholder placeholder-xs col-11"></span>
                                        </div>

                                    </div>

                                    <div className="mb-3">

                                        <div className="hstack gap-2 mb-2 mt-2">
                                            <span className="placeholder placeholder-lg col-6"></span>
                                        </div>

                                        <div className="hstack gap-2 mb-2 mt-2">
                                            <span className="placeholder placeholder-xs col-11"></span>
                                        </div>

                                        <div className="hstack gap-2 mb-2 mt-2">
                                            <span className="placeholder placeholder-xs col-11"></span>
                                        </div>
                                        <div className="hstack gap-2 mb-2 mt-2">
                                            <span className="placeholder placeholder-xs col-11"></span>
                                        </div>
                                        <div className="hstack gap-2 mb-2 mt-2">
                                            <span className="placeholder placeholder-xs col-11"></span>
                                        </div>
                                        <div className="hstack gap-2 mb-2 mt-2">
                                            <span className="placeholder placeholder-xs col-11"></span>
                                        </div>
                                    </div>

                                    <div className="mb-3">

                                        <div className="hstack gap-2 mb-2 mt-2">
                                            <span className="placeholder placeholder-lg col-6"></span>
                                        </div>

                                        <div className="hstack gap-2 mb-2 mt-2">
                                            <span className="placeholder placeholder-xs col-11"></span>
                                        </div>

                                        <div className="hstack gap-2 mb-2 mt-2">
                                            <span className="placeholder placeholder-xs col-11"></span>
                                        </div>
                                        <div className="hstack gap-2 mb-2 mt-2">
                                            <span className="placeholder placeholder-xs col-11"></span>
                                        </div>
                                    </div>

                                    <div className="mb-3">

                                        <div className="hstack gap-2 mb-2 mt-2">
                                            <span className="placeholder placeholder-lg col-6"></span>
                                        </div>

                                        <div className="hstack gap-2 mb-2 mt-2">
                                            <span className="placeholder placeholder-xs col-11"></span>
                                        </div>

                                        <div className="hstack gap-2 mb-2 mt-2">
                                            <span className="placeholder placeholder-xs col-11"></span>
                                        </div>
                                        <div className="hstack gap-2 mb-2 mt-2">
                                            <span className="placeholder placeholder-xs col-11"></span>
                                        </div>
                                        <div className="hstack gap-2 mb-2 mt-2">
                                            <span className="placeholder placeholder-xs col-11"></span>
                                        </div>
                                        <div className="hstack gap-2 mb-2 mt-2">
                                            <span className="placeholder placeholder-xs col-11"></span>
                                        </div>
                                    </div>

                                    <div className="mb-3">

                                        <div className="hstack gap-2 mb-2 mt-2">
                                            <span className="placeholder placeholder-lg col-6"></span>
                                        </div>

                                        <div className="hstack gap-2 mb-2 mt-2">
                                            <span className="placeholder placeholder-xs col-11"></span>
                                        </div>

                                        <div className="hstack gap-2 mb-2 mt-2">
                                            <span className="placeholder placeholder-xs col-11"></span>
                                        </div>
                                        <div className="hstack gap-2 mb-2 mt-2">
                                            <span className="placeholder placeholder-xs col-11"></span>
                                        </div>
                                    </div>

                                    <div className="mb-3">

                                        <div className="hstack gap-2 mb-2 mt-2">
                                            <span className="placeholder placeholder-lg col-6"></span>
                                        </div>

                                        <div className="hstack gap-2 mb-2 mt-2">
                                            <span className="placeholder placeholder-xs col-11"></span>
                                        </div>

                                        <div className="hstack gap-2 mb-2 mt-2">
                                            <span className="placeholder placeholder-xs col-11"></span>
                                        </div>
                                        <div className="hstack gap-2 mb-2 mt-2">
                                            <span className="placeholder placeholder-xs col-11"></span>
                                        </div>
                                    </div>

                                </SimpleBar>
                            </div>

                            <div>

                            </div>
                        </div>
                    </CardBody>
                </Card>
            </div>
        </React.Fragment>
    )
}

export default DocumentPlanPlaceHolders;
