import React from "react";
import {
    Container
} from "reactstrap";
//Import Breadcrumb
// import Breadcrumbs from "../../components/Common/Breadcrumb2";
import Feedbacks from "./board"
//i18n
import { withTranslation } from "react-i18next";
const FeedbackBoard = props => {

    //meta title
    document.title = "Suivi des feedbacks | Jask";

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Feedbacks />
                </Container>
            </div>
        </React.Fragment>
    );
};


export default withTranslation()(FeedbackBoard);
