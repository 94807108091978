import React, { useEffect, useState } from "react";
import {
    Button,
    Card,
    CardBody,
    Col,
    Container, Form, Input, Label, Modal, NavItem, Row, TabContent, TabPane, Table
} from "reactstrap";

import SimpleBar from "simplebar-react"
import DocumentPlanPlaceHolders from "../plan/placeholders";
import GenerateDocumentContent from "./content"

import {
    generateDocument,
    processTemplate,
} from "store/actions";
import { useSelector, useDispatch } from "react-redux";
import { isEmpty } from "lodash";
import { uuidv4 } from "utils";
import GenerationStructure from "../plan/content"



const DocumentGeneration = ({ context }) => {
    const [taskId, setTaskId] = useState(null);
    const [taskResult, setTaskResult] = useState(null);
    const [stage, setStage] = useState('plan');
    const dispatch = useDispatch();
    const { isWorking, tasks } = useSelector(state => ({
        isWorking: state.Documents.isGenerating,
        tasks: state.Documents.tasks,
    }));

    useEffect(() => {

        if (!isEmpty(context)) {
            const id = uuidv4();
            setTaskId(id);

            dispatch(generateDocument({
                id: id,
                templateId: context.template.id,
                context: context.context,
                stage: stage
            }))

        }


    }, [context]);

    useEffect(() => {
        if (tasks[taskId]) {
            setTaskResult(tasks[taskId]);
        }
    }, [tasks])

    return <React.Fragment>
        <div className="workspace-documents-editor ">

            {isWorking && <DocumentPlanPlaceHolders />}
            {!isWorking && taskResult &&
                <div className="d-flex ">
                    <GenerationStructure location={taskResult.sourceLocation} sourceName={taskResult.sourceName} structure={taskResult.items} onItemChange={(i) => console.log(i)} />
                    <GenerateDocumentContent task={taskResult} context={context} />
                </div>}
        </div>

    </React.Fragment >
}

export default DocumentGeneration;