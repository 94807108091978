import React, { memo, ReactNode, useCallback, useEffect, useState } from 'react';
import { Handle, Position } from '@xyflow/react';
import { Link } from "react-router-dom";
import { Badge, Card, CardBody, CardFooter, CardTitle, Collapse, DropdownItem, DropdownMenu, DropdownToggle, Label, Tooltip, UncontrolledDropdown, UncontrolledTooltip } from 'reactstrap';
import classnames from "classnames";
import { isEmpty, map, truncate } from 'lodash';
import SingleHandle from '../../handle/SingleHandle';


const AssistantNode = ({ data, onChange, type }) => {
    const [collapsed, setCollapsed] = useState(false);
    const [nodeName, setNodeName] = useState(data.name);
    const [instructions, setInstructions] = useState(data?.instructions);
    const [nodeStatus, setNodeStatus] = useState(false);
    const [includeContext, setIncludeContext] = useState(data?.includeContext);


    useEffect(() => {

        onChange({
            ...data,
            name: nodeName,
            instructions: instructions,
            isValid: true,
            includeContext: includeContext
        })
    }, [instructions, includeContext])




    return <Card style={{ maxWidth: '320px' }}>
        <div className="accordion accordion-flush" id="accordion">
            <div className="accordion-item">
                <div className="accordion-header w-100" id="headingOne">
                    <button
                        className={classnames(
                            "accordion-button",
                            "fw-medium",
                            { collapsed: !collapsed }
                        )}
                        type="button"
                        onClick={() => setCollapsed(!collapsed)}
                        style={{ cursor: "pointer" }}
                    >
                        <div className='vstack'>
                            <div className="title hstack gap-0 ">
                                <img className='rounded-circle avatar-circle' width={40} height={50} src={data?.logo} />
                                <div className='vstack w-100 ms-2 me-4'>
                                    <span className='text-dark'>{data.name}</span>
                                    <small className='text-wrap' >{truncate(data.description, { length: 200 })}</small>
                                </div>
                            </div>
                            <div className="subline ms-4">
                                <div className="text-success ms-4"><small > Valide </small><i className='mdi mdi-check-circle' /></div>
                            </div>
                        </div>


                    </button>
                </div>

                <Collapse isOpen={collapsed}>

                    <div className="accordion-body">
                        <div className='mb-2'>
                            <textarea type='text'
                                className='form-control'
                                placeholder={'Poser une question à ' + data.name + ' ...'}
                                rows={5}
                                value={instructions}
                                onChange={e => setInstructions(e.target.value)}
                            />
                        </div>
                        <div>
                            <input type="checkbox" className="form-check-input" checked={includeContext} onClick={e => setIncludeContext(!includeContext)} />
                            <small className='ms-2'>Inclure le contexte d'execution <i id='context' className='mdi mdi-information text-info' /></small>
                            <UncontrolledTooltip target='context' placement='right'>
                                Les données initiales lors de l'execution de ce workflow  seront également envoyées à  {data?.name || " cette assistant"}.
                            </UncontrolledTooltip>
                        </div>
                    </div>
                </Collapse>
            </div>
            <SingleHandle nodeId={data?.id} type="target" position={Position.Left} />
            <SingleHandle nodeId={data?.id} type="source" position={Position.Right} />
        </div>
        <CardFooter className='border-top'>
            <div className='d-flex justify-content-between'>
                <div>
                    <Badge className='badge badge-soft-info'>Crée par  {data.owner}</Badge>
                </div>
            </div>
        </CardFooter>
    </Card>
}

export default memo(AssistantNode);