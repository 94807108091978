import React, { Fragment, memo, ReactNode, useCallback, useEffect, useState } from 'react';
import { Handle, Position } from '@xyflow/react';
import { Link } from "react-router-dom";
import { Card, CardBody, CardFooter, CardTitle, Collapse, DropdownItem, DropdownMenu, DropdownToggle, Input, UncontrolledDropdown } from 'reactstrap';
import classnames from "classnames";
import { isEmpty, map } from 'lodash';
import DocumentBrowser from 'components/Common/DocumentBrowser';
import { getFileIcon } from 'utils';
import { DatasourceProviders, ResourceTypes } from 'constants/general';
import DatasourceBrowser from 'components/Common/DatasourceBrowser';

const SharepointSearchNode = ({ data, onChange }) => {

    const [locations, setLocations] = useState([]);
    const [showBrowser, setShowBrowser] = useState(false);
    const [provider, setProvider] = useState(null);

    const toggle = () => {
        setShowBrowser(!showBrowser)
    }

    const onSelectedPath = (paths) => {
        const newPaths = [...locations, ...paths]
        console.log(newPaths)
        onChange({
            ...data,
            providerConfig: {
                locations: newPaths,
            },
            viewState: newPaths.length > 0
        })
        setLocations(newPaths)
        toggle();
    }


    const removeLocation = (path) => {
        const newPaths = locations.filter(loc => loc.location !== path.location);
        setLocations(newPaths);
        onChange({
            ...data,
            providerConfig: {
                locations: newPaths,
            },
            viewState: newPaths.length > 0

        });
    }

    useEffect(() => {
        if (data?.providerConfig?.locations) {
            setLocations(data?.providerConfig?.locations)
        }

        setProvider({
            id: data?.providerId,
            type: data?.providerType || DatasourceProviders.SHAREPOINT
        });

    }, [data])

    return <div className="mb-0" key={data.id}>
        <DatasourceBrowser
            show={showBrowser}
            onCloseClick={toggle}
            onConfirm={onSelectedPath}
            datasource={provider}
        />
        <div className='vstack'>
            {map(locations, item => {

                return <div className=' d-flex justify-content-between gap-1 mb-2 border-bottom pb-1'>
                    <div>
                        {item.isDir ? <i className="mdi mdi-folder me-1 text-warning" /> : getFileIcon(item.name, 14)}
                        <span className='truncate'>{item.name}</span>
                    </div>
                    <Link onClick={() => removeLocation(item)}><i className='mdi mdi-delete text-danger ' /></Link>

                </div>
            })}
        </div>
        <div className='d-flex justify-content-center'>
            <Link className='font-size-10' onClick={toggle} >
                <div className='hstack gap-1'>
                    <i className='mdi mdi-plus text- font-size-12' />
                    <span>Selectionner un dossier </span>
                </div>
            </Link>
        </div>


    </div>
}

export default SharepointSearchNode;