import { isEmpty } from "lodash"
import { CREATE_STUDIO_APP, CREATE_STUDIO_APP_SUCCESS, CREATE_STUDIO_APP_FAILED, GET_STUDIO_APP, GET_STUDIO_APP_SUCCESS, GET_STUDIO_APP_FAILED, DEL_STUDIO_APP_SUCCESS, ASSISTANT_STATE_CHANGED } from "./actionTypes"
import { StringToDate, toDate, toLocalDate } from "helpers/to-local-date"

const INIT_STATE = {
  apps: [],
  assistants: [],
  error: null,
  isAdding: false,
  isLoading: false,

}

const studio = (state = INIT_STATE, action) => {
  switch (action.type) {

    case CREATE_STUDIO_APP:
      return {
        ...state,
        isAdding: true
      }

    case CREATE_STUDIO_APP_SUCCESS:
      let runnable = [...state.apps, ...state.assistants].filter(app => app.id !== action.payload.id);
      runnable = [action.payload, ...runnable]
      return {
        ...state,
        isAdding: false,
        apps: runnable.filter(a => isEmpty(a.app)),
        assistants: runnable.filter(a => !isEmpty(a.app)),
      }

    case CREATE_STUDIO_APP_FAILED:
      return {
        ...state,
        isAdding: false,
        error: action.payload,
      }

    case GET_STUDIO_APP:
      return {
        ...state,
        isLoading: false,
      }

    case GET_STUDIO_APP_SUCCESS:

      return {
        ...state,
        isLoading: false,
        apps: action.payload.filter(a => isEmpty(a.app)),
        assistants: action.payload.filter(a => !isEmpty(a.app)),
      };

    case GET_STUDIO_APP_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };

    case DEL_STUDIO_APP_SUCCESS:

      const remainingApps = state.apps.filter(app => app.id !== action.payload)
      const remainingAssistants = state.assistants.filter(app => app.id !== action.payload)

      return {
        ...state,
        apps: remainingApps,
        assistants: remainingAssistants,
      };

    case ASSISTANT_STATE_CHANGED:

      const assistants = [...state.assistants];

      assistants.forEach(a => {
        if (a.id === action.payload.id) {
          a.lastUpdate = new Date().toISOString();
        }
      });

      assistants.sort((a, b) => StringToDate(b.lastUpdate) - StringToDate(a.lastUpdate))

      return {
        ...state,
        apps: state.apps,
        assistants: assistants,
      }

    default:
      return state
  }
}

export default studio;