import { isEmpty } from 'lodash';
import PropTypes from 'prop-types'
import React, { useEffect, useState } from "react"
import { Link } from 'react-router-dom';
import { Label, Modal, ModalBody, Card, CardBody, Input, NavItem, NavLink, TabContent, TabPane, Spinner, Button } from "reactstrap"
import classnames from "classnames"
import BoardExecution from './test';
import DeployBoard from './deploy';
import { useSelector, useDispatch } from "react-redux";
import { withTranslation } from "react-i18next";

import {
    addPlatformApp
} from "store/actions";

const BoardRunner = ({ show, onConfirmlick, onCloseClick, board, onScreenshot, app, t }) => {

    const [isCreating, setIsCreating] = useState(false)
    const [activeTab, setactiveTab] = useState(3)
    const [passedSteps, setPassedSteps] = useState([1]);
    const [context, setContext] = useState(null);
    const [canGoNext, setCanGoNext] = useState(false);
    const [deployment, setDeployment] = useState(null);
    const [canDeploy, setCanDeploy] = useState(false);
    const dispatch = useDispatch();

    const { isWaiting } = useSelector(state => ({
        isWaiting: state.Studio.isAdding,
    }));


    function toggleTab(tab) {
        if (activeTab !== tab) {
            var modifiedSteps = [...passedSteps, tab]
            if (tab >= 1 && tab <= 3) {
                setactiveTab(tab)
                setPassedSteps(modifiedSteps)
            }
        }
    }

    const deployApp = () => {
        setIsCreating(true);

        onScreenshot().then(data => {
            const payload = {
                ...deployment,
                image: data,
                id: app?.id
            }
            dispatch(addPlatformApp(payload));
        })
    }

    const onDeploymentInfoChanged = (info) => {

        setDeployment({
            ...info,
            board: board
        });
    }

    useEffect(() => {
        setCanDeploy(!isEmpty(deployment?.name) && !isEmpty(deployment?.description) && board?.isValid);
    }, [deployment]);

    useEffect(() => {

        switch (activeTab) {

            case 1:
                setCanGoNext(!isEmpty(context) && context.length > 10);
                break;
        }

    }, [activeTab, context]);

    useEffect(() => {
        if (isCreating && !isWaiting) {
            setIsCreating(false);
            onCloseClick();
        }
    }, [isWaiting])


    return <Modal
        size="lg" isOpen={show}
        toggle={onCloseClick} centered={true}
        contentClassName=''

    >

        <div >
            <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel"><div className='hstack gap-1'><i className='bx bx-bot text-success bold font-size-24 me-2' />Jask Studio : {t("Déployer votre process de traitement")}  </div></h5>
                <button type="button"
                    onClick={onCloseClick} className="btn-close"></button>
            </div>

            <div className="modal-body" >

                <DeployBoard board={board} onChange={onDeploymentInfoChanged} app={app} />

                <div className='d-flex flex-row justify-content-end'>

                    <div className="hstack gap-1">

                        <Button
                            className='btn btn-info  '
                            onClick={deployApp}
                            disabled={!canDeploy}
                        >
                            Déployer
                        </Button>
                        {isCreating && <div className='vr' />}
                        {isCreating && <Spinner size="md" className='' />}

                    </div>
                </div>
            </div>
        </div>

    </Modal>
}

export default withTranslation()(BoardRunner);
