export const TaskStatus = {
    STARTED: "Started",
    NOT_STARTED: "NotStarted",
    COMPLETED: "Done",
    CANCELLED: "cancelled",
    THINKING: "Thinking",
    ERROR: "Error",
    WAITING: "waiting",

}


export const OperationStatus = {
    SUCCESS: "success",
    ERROR: "error"
}