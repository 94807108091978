import React, { Fragment, useEffect, useState } from "react"
import { Card, CardBody, Label } from "reactstrap";
import Select from "react-select";
import { OperationStatus, TaskStatus } from "constants/tasks";
import { isEmpty } from "lodash";
import { useDispatch, useSelector } from "react-redux";

import {
    addTodoWorks as onCreateTodo
} from "store/actions";
import { error } from "toastr";

const taskStatus = [{
    label: "A traiter",
    value: TaskStatus.NOT_STARTED,
}, {
    label: "En cours",
    value: TaskStatus.STARTED,
},
{
    label: "Terminé",
    value: TaskStatus.COMPLETED,
}
]


const SaveTodoView = ({ taskName, provider, data, onConfigChange }) => {
    const [todoName, setTodoName] = useState(taskName);
    const [status, setStatus] = useState(taskStatus[2]);
    const [content, setContent] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const { isAdding, error } = useSelector(state => ({
        isAdding: state.Todos.isAdding,
        error: state.Todos.error
    }));

    const dispatch = useDispatch();

    const onSubmit = (config) => {
        setIsLoading(true);
        dispatch(onCreateTodo({
            ...config,
            provider: provider,
            operationStatus: OperationStatus.SUCCESS,
        }));
    }

    const getConfig = () => {

        const itemCreated = isLoading && !isAdding && isEmpty(error);

        return {
            name: todoName,
            content: content,
            isValid: !isEmpty(todoName) && !isEmpty(content),
            onSubmit: onSubmit,
            isLoading: isAdding,
            message: itemCreated ? 'Tache créee avec succès' : undefined,
            operationStatus: itemCreated ? OperationStatus.SUCCESS : (!isEmpty(error) ? OperationStatus.ERROR : undefined)
        }
    }

    useEffect(() => {

        let fullContent = "";

        if (data instanceof Array) {
            data.forEach(item => {
                fullContent = `${fullContent}\n\n${item.content}`
            });
        } else {
            fullContent = data.content;
        }


        setContent(fullContent.trim());

    }, [data])

    useEffect(() => {
        onConfigChange(getConfig())
    }, [todoName, taskName, status, content, isAdding, error])

    return <Card className="todos">
        <div className="mb-4">
            <Label>Tache à créer:</Label>
            <input
                value={todoName}
                type="text"
                className="form-control"
                placeholder="Nom de la tache"
                onChange={e => setTodoName(e.target.value)}

            />
        </div>

        <div className="mb-3">
            <Label>Status:</Label>
            <Select
                options={taskStatus}
                value={status}
                className="select2-selection"
                onChange={e => setStatus(e)}
            />
        </div>
    </Card>
}

export default SaveTodoView;