import React, { memo, useEffect, useRef, useState } from "react";
import { ResourceTypes } from "constants/general";
import { map } from "lodash";
import FileReferences from "./filesystem";
import CrmReferences from "./crm";
import { Link } from "react-router-dom";
import InternetReferences from "./internet";
import { getProviderLogo, uniqueFilter } from "utils";
import { withTranslation } from "react-i18next";


const ResourceReferences = ({ content, references, size, onSuggestionClicked, t }) => {

    const [resources, setResources] = useState([]);
    const [selectedResource, setSelectedResource] = useState(null);
    const [providers, setProviders] = useState([]);
    const [mentionedSources, setMentionedSources] = useState([]);

    const toggleResource = (rsc) => {
        if (rsc.label === selectedResource?.label)
            setSelectedResource(null);
        else
            setSelectedResource(rsc);
    }

    useEffect(() => {
        const providers = references.map(ref => ref.providerType).filter(uniqueFilter);
        const data = references.map((ref, index) => ({ ...ref, order: index }))
        const files = data.filter(ref => ref.type === ResourceTypes.FILE);
        const crmObjects = data.filter(ref => ref.type === ResourceTypes.CRM_OBJECT);
        const internet = data.filter(ref => ref.type === ResourceTypes.INTERNET);

        const providersData = [
            {

                label: "Fichiers",
                data: files,
                count: files.length > 0,
                component: <FileReferences references={files} size={size} onSuggestionClicked={onSuggestionClicked} />,
                order: files.length > 0 ? files[0].order : 1000
            }, {

                label: crmObjects.length > 0 ? crmObjects[0].providerName : t("Opportunités"),
                data: crmObjects,
                count: crmObjects.length > 0,
                component: <CrmReferences references={crmObjects} size={size} onSuggestionClicked={onSuggestionClicked} />,
                icon: crmObjects.length > 0 ? getProviderLogo(crmObjects[0].providerType) : <i className='mdi mdi-table' />,
                order: crmObjects.length > 0 ? crmObjects[0].order : 1000
            },
            {

                label: internet.length > 0 ? internet[0].providerName : t("Liens utiles"),
                data: internet,
                count: internet.length > 0,
                component: <InternetReferences references={internet} size={size} onSuggestionClicked={onSuggestionClicked} />,
                icon: internet.length > 0 ? getProviderLogo(internet[0].providerType) : <i className='mdi mdi-web' />,
                order: internet.length > 0 ? internet[0].order : 1000

            }].sort((a, b) => a.order - b.order)

        setSelectedResource(providersData[0])

        setProviders(providers);
        setResources(providersData);


    }, [references])

    return <div className="chat-references">

        {providers?.length > 1 && <h6 className="fw-bold"><i className="mdi mdi-database me-1" />{t("Sources consultées")}: </h6>}
        {providers?.length > 1 && <div className="hstack gap-1">
            {resources.filter(rsc => rsc.count > 0).map(rsc => {
                return <div key={rsc.label}>
                    <Link onClick={() => toggleResource(rsc)}>
                        <div className={rsc?.label === selectedResource?.label ? "hstack gap-1 bg-info p-1 rounded bg-opacity-25" : "hstack gap-1"}>
                            {rsc.icon}
                            <span> {rsc.label}({rsc?.data?.length})</span>
                        </div>
                    </Link>
                </div>
            })}
        </div>
        }

        <div className="mt-2">
            {selectedResource?.component}

        </div>
    </div>

}

export default withTranslation()(ResourceReferences);
