import React, { memo, useEffect, useState, useCallback } from 'react';
import { Handle, Position, useUpdateNodeInternals, useStore, useHandleConnections, useReactFlow } from '@xyflow/react';
import { Badge, Card, CardFooter, Collapse, DropdownItem, DropdownMenu, DropdownToggle, Label, UncontrolledDropdown } from 'reactstrap';
import classnames from "classnames";
import { isEmpty, map } from 'lodash';
import { runPromptOptimization } from 'service/tasks';
import { useSelector } from "react-redux";

import rehypeRaw from 'rehype-raw'
import remarkGfm from "remark-gfm";
import ReactMarkdown from 'react-markdown'
import MultiHandle from '../../handle/MultiHandle';
import SingleHandle from '../../handle/SingleHandle';

const inputTypes = [
    {
        id: 'all',
        title: 'Texte et fichiers ',
        description: 'Concatenation du texte et du contenu des fichiers'
    },
    {
        id: 'textOnly',
        title: 'Texte',
        description: 'Appliquer les instructions le texte fourni'
    },
    {
        id: 'fileOnly',
        title: 'Fichiers',
        description: 'Appliquer les instructions sur chaque fichier uniquement'
    },
    {
        id: 'rowOnly',
        title: 'Ligne par ligne',
        description: 'Appliquer les instructions sur chaque ligne'
    },
]

const handleStyle = { left: -5, top: 10 };


const getStatusClassName = (status) => {

    if (!status)
        return "text-danger";
    else {
        return "text-success";
    }
}

const PromptStoreNode = ({ llms, data, onChange, type }) => {
    const flow = useReactFlow();
    const [collapsed, setCollapsed] = useState(false);
    const [nodeName, setNodeName] = useState(data.name);
    const [promptId, setPromptId] = useState(data.promptId);
    const [promptOwner, setPromptOwner] = useState(data.promptOwner);
    const [dynamicInputs, setDynamicInputs] = useState(data.inputs);

    const [selectedModel, setSelectedModel] = useState({
        id: data?.modelId,
        title: data?.modelName
    });
    const [instructions, setInstructions] = useState(data?.content);
    const [parameters, setParameters] = useState(data?.parameters);
    const [nodeStatus, setNodeStatus] = useState(false);
    const [isLoading, setIsLoading] = useState(data?.isLoading);
    const { accessToken } = useSelector(state => ({
        accessToken: state.Login.accessToken,
    }));

    const onModelChange = (model) => {
        setSelectedModel(model);
    }

    const optimizePrompt = () => {
        setCollapsed(true);
        setIsLoading(true);
        runPromptOptimization(instructions, onPromptOptimizationStart, onPromptToken, onPromptOptimizationStop, accessToken);
    }

    const onPromptOptimizationStart = () => {
        setInstructions(prev => "");
    }

    const onPromptToken = (token) => {
        setInstructions(prev => `${prev}${token}`);
    }

    const onPromptOptimizationStop = (status) => {
        setIsLoading(false);
    }

    const onHandleValueChanged = (param, value) => {
        const items = [...parameters];
        console.log(param)
        items.forEach(p => {
            if (p.id === param.id) {
                p.defaultValue = value;
                p.isDynamic = param?.isDynamic || p.isDynamic;
                p.source = param?.source || p.source
            }
        });

        setParameters(items);
    }

    const onParamConnected = (paramId, paramHandleId, sourceId) => {
        const items = [...parameters];
        items.forEach(p => {
            if (p.id === paramId) {
                p.source = sourceId;
            }
        });

        setParameters(items);
    }

    const onSourceConnected = (connection) => {

    }

    useEffect(() => {
        if (isEmpty(selectedModel?.id)) {
            setSelectedModel(llms[0]);
        }
    }, [llms, selectedModel]);


    useEffect(() => {

        const hasValidParams = parameters?.length == 0 ? true : parameters.filter(p => isEmpty(p.source) && isEmpty(p.defaultValue)).length == 0;

        onChange({
            ...data,
            name: nodeName,
            modelId: selectedModel?.id,
            modelName: selectedModel?.title,
            parameters: parameters,
            promptId: promptId,
            promptOwner: promptOwner,
            instructions: instructions,
            isLoading: isLoading,
            isValid: !isEmpty(nodeName) && !isEmpty(instructions) && instructions.length > 3 && !isEmpty(selectedModel?.id) && hasValidParams
        })
    }, [nodeName, selectedModel, instructions, isLoading, parameters])

    // console.log("Updating Node...", data)

    useEffect(() => {

        setNodeStatus(data.isValid);

    }, [data]);


    return <Card style={{ minWidth: '300px', maxWidth: '400px' }}>
        <div className="accordion accordion-flush" id="accordion">
            <div className="accordion-item">
                <div className="accordion-header" id="headingOne">
                    <button
                        className={classnames(
                            "accordion-button",
                            "fw-medium",
                            { collapsed: !collapsed }
                        )}
                        type="button"
                        onClick={() => setCollapsed(!collapsed)}
                        style={{ cursor: "pointer" }}
                    >
                        <div className='vstack'>
                            <div className="title hstack gap-0">
                                {data.icon && <i className={data.icon + " font-size-20"} />}
                                <input className="border-0 form-control" style={{ background: 'transparent' }} type='text' value={nodeName} onChange={e => setNodeName(e.target.value)} />

                            </div>
                            <small className='ps-4'>{data.description}</small>
                            <div className="subline ">
                                {nodeStatus && <div className="text-success ms-4"><small > Valide </small><i className='mdi mdi-check-circle' /></div>}
                                {!nodeStatus && <div className="text-warning ms-4"><small > Invalide </small><i className='mdi mdi-alert' /></div>}
                            </div>
                        </div>


                    </button>
                </div>

                <Collapse isOpen={collapsed}>
                    <div className="accordion-body">
                        <div className='mb-2 nodrag border p-2'>
                            <ReactMarkdown
                                remarkPlugins={[remarkGfm]}
                                rehypePlugins={[rehypeRaw]}
                                className={"markdown text-wrap"} >
                                {instructions}
                            </ReactMarkdown>
                        </div>


                    </div>

                </Collapse>
                <div className='pb-2'>
                    {/* <h6 className='ps-1 pb-1 font-size-10 border-bottom border-top pt-1 text-muted'><i className='mdi mdi-information me-1' />Liste des paramètres:</h6> */}
                    {map(data?.parameters || [], (param, index) => {
                        return <MultiHandle
                            key={index}
                            id={param.id}
                            nodeId={data?.id}
                            type="target"
                            position={Position.Left}
                            label={param.name}
                            index={index}
                            onTargetConnected={onParamConnected}
                            onStaticValueChange={(id, value) => onHandleValueChanged(param, value)}
                            handleValue={param.defaultValue}
                        />
                    })}

                </div>

                <SingleHandle nodeId={data?.id} type="source" position={Position.Right} onSourceConnected={onSourceConnected} />
            </div>




            {/* <Handle type="target" position={Position.Left} id='1' key={"1"} />
            <Handle type="target" position={Position.Left} id='2' key={"2"} />
            <Handle type="source" position={Position.Right} /> */}

        </div>
        <CardFooter className='border-top'>
            <div className='d-flex justify-content-between'>
                <div>

                    <UncontrolledDropdown className="dropup ms-1" disabled={history.length == 0}>
                        <DropdownToggle
                            href="#"
                            tag="a" className="dropdown-toggle ">
                            <Badge className='badge badge-soft-info' > {selectedModel?.title}</Badge>
                        </DropdownToggle>
                        <DropdownMenu className="dropdown-menu-start dropup">
                            <div className="dropdown-header noti-title border-bottom">
                                <h5 className="font-size-12 text-muted text-truncate mn-0">
                                    <div className="hstack gap-1">
                                        <div className="vstack gap-1 mt-1">
                                            <span className=''>  Modèles d'IA à utiliser </span>
                                        </div>
                                    </div>
                                </h5>
                            </div>

                            {map(llms, (item, index) => {
                                return <DropdownItem className={index < inputTypes.length - 1 ? "border-bottom p-2 ps-4 " : "p-2 ps-4"}
                                    onClick={() => onModelChange(item)}
                                >
                                    <div className='vstack gap-1'>

                                        <span className="text-muted font-size-12">
                                            {item.title}
                                        </span>
                                        <small className='font-size-10'>{item.description}</small>
                                    </div>

                                </DropdownItem>
                            })}

                        </DropdownMenu>
                    </UncontrolledDropdown>
                </div>
                <div className=''>
                    <div className='hstack gap-1 mt-1'>
                        <Badge className='badge badge-soft-info'>{data.owner}</Badge>
                        {data.tags && map(data.tags.slice(0, 2), tag => {
                            return <Badge className='badge badge-soft-info'>{tag}</Badge>
                        })}
                    </div>

                </div>
            </div>
        </CardFooter>
    </Card >
}

export default PromptStoreNode;