
import React, { useEffect, useState } from "react"
import classnames from "classnames"
import { Modal, ModalBody, ModalHeader, Nav, NavLink, NavItem, TabContent, TabPane, Row, Col, Label, Input, Card, CardBody, Spinner, Badge, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, UncontrolledTooltip, CardTitle, ModalFooter } from "reactstrap"
import { Link } from "react-router-dom"
import SimpleBar from "simplebar-react"
import { withTranslation } from "react-i18next";
import Select from 'react-select';
import { useSelector, useDispatch } from "react-redux";
import { isEmpty, map } from "lodash"
import SupportIcon from "assets/images/technical-support.png"

import {
    addSupportPlatform,
} from "store/actions";


const AddSupportTeam = ({ show, onCloseClick, team, t }) => {

    const ticketsAssigment = [
        { value: 'dynamic', label: t('Assigner dynamiquement') },
        { value: 'priority', label: t('Assigner par priorité') },
    ]

    const ticketsEvaluation = [
        { value: false, label: t('Ne pas évaluer les réponses') },
        { value: true, label: t('Evaluer la pertinence des réponses') },
    ]

    const [activeTab, setactiveTab] = useState('general');
    const [name, setName] = useState();
    const [description, setDescription] = useState();
    const [requestSources, setRequestSources] = useState([]);
    const [members, setMembers] = useState([]);
    const [totalSelectedMembers, setTotalSelectedMembers] = useState(0);
    const [assigmentType, setAssignmentType] = useState(ticketsAssigment[0]);
    const [assessmentType, setAssessmentType] = useState(ticketsEvaluation[0]);
    const [assessmentInstructions, setAssessmentInstructions] = useState(null);
    const [selectedRequestSources, setSelectedRequestSources] = useState([]);
    const [canCreate, setCanCreate] = useState(false);
    const dispatch = useDispatch();

    const { datasources, assistants } = useSelector(state => ({
        datasources: state.Datasources.datasources,
        assistants: state.Studio.assistants,
    }));


    const onSelectedApp = (app) => {
        const items = [...members];

        items.forEach(i => {
            if (i.id === app.id)
                i.isSelected = !i.isSelected;
        });

        setMembers(items);
    }

    const createTeam = () => {

        const payload = {
            id: team?.id,
            name: name,
            description: description,
            members: members.filter(m => m.isSelected).map(m => m.id),
            sources: selectedRequestSources.map(s => s.value.id),
            assignmentType: assigmentType?.value,
            assessResult: assessmentType?.value,
            assessmentRules: assessmentInstructions,
        }

        dispatch(addSupportPlatform(payload));

        onCloseClick();
    }

    useEffect(() => {
        setRequestSources(datasources.map(ds => ({
            label: ds.name,
            value: ds
        })));
    }, [datasources]);

    useEffect(() => {
        setMembers(assistants.map(i => ({ ...i, isSelected: false })));
    }, [assistants]);

    useEffect(() => {
        setTotalSelectedMembers(members.filter(m => m.isSelected).length);
    }, [members])

    useEffect(() => {
        const state = !isEmpty(name) && !isEmpty(description) && totalSelectedMembers > 0 && selectedRequestSources?.length > 0;
        setCanCreate(state);
    }, [assigmentType, assessmentType, selectedRequestSources, totalSelectedMembers, description, name])


    useEffect(() => {
        if (isEmpty(team))
            return

        setName(team.name);
        setDescription(team.description);
        setMembers(assistants.map(a => ({ ...a, isSelected: team.members.includes(a.id) })))
        setSelectedRequestSources(datasources.filter(ds => team.sources.includes(ds.id)).map(ds => ({
            label: ds.name,
            value: ds
        })))

    }, [team])


    return <Modal size="xl"
        isOpen={show}
        toggle={onCloseClick}
        centered={true}
        contentClassName='window-medium'
    >

        <ModalHeader>
            <h5><i className="bx bx-support me-1" />{t("Nouvelle équipe support")}</h5>
            <button type="button" onClick={onCloseClick} className="btn-close position-absolute end-0 top-0 m-3"></button>
        </ModalHeader>

        <div className="modal-content support-teams">
            <div className="ps-2">
                <Nav tabs>
                    <NavItem>
                        <NavLink onClick={() => setactiveTab('general')}
                            className={classnames({ active: activeTab === 'general' })}>

                            <div className="hstack gap-1">
                                <i className=" font-size-20 mdi mdi-tooltip" />
                                {t("Informations Générales")}

                            </div>
                        </NavLink>
                    </NavItem>

                    <NavItem>
                        <NavLink onClick={() => setactiveTab('members')}
                            className={classnames({ active: activeTab === 'members' })}>

                            <div className="hstack gap-1">
                                <i className="bx bxs-group font-size-20 " />
                                {t("Membres de l'équipe")} {totalSelectedMembers > 0 ? "(" + (totalSelectedMembers) + ")" : <i className="mdi mdi-alert text-danger" />}

                            </div>
                        </NavLink>
                    </NavItem>

                    <NavItem>
                        <NavLink onClick={() => setactiveTab('organization')}
                            className={classnames({ active: activeTab === 'organization' })}>

                            <div className="hstack gap-1">
                                <i className="bx bxs-user font-size-20 " />
                                {t("Fonctionnement de l'équipe")}

                            </div>
                        </NavLink>
                    </NavItem>
                </Nav>

                <TabContent activeTab={activeTab}>
                    <TabPane tabId="general">
                        <Card>
                            <CardBody>
                                <Row>
                                    <Col md={5}>
                                        <div className="mb-3">
                                            <Label for="basicpill-name-input1">
                                                {t("Nom de votre équipe")}:
                                            </Label>
                                            <Input
                                                type="text"
                                                className="form-control"
                                                id="teamName"
                                                placeholder=""
                                                value={name}
                                                onChange={e => setName(e.target.value)}
                                            />
                                        </div>
                                        <div className="mb-3">
                                            <Label for="basicpill-name-input1">
                                                {t("Description")}:
                                            </Label>
                                            <textarea
                                                type="text"
                                                className="form-control"
                                                id="description"
                                                placeholder=""
                                                rows={5}
                                                value={description}
                                                onChange={e => setDescription(e.target.value)}
                                            />
                                        </div>


                                        <div className="mb-3">
                                            <Label for="basicpill-name-input1">
                                                {t("Sources des demandes")}:
                                            </Label>
                                            <Select
                                                options={requestSources}
                                                isMulti={true}
                                                value={selectedRequestSources}
                                                onChange={setSelectedRequestSources}
                                            />
                                        </div>
                                    </Col>
                                    <Col lg={1}>

                                    </Col>
                                    <Col lg={5}>
                                        <Card>
                                            <CardBody>
                                                <div className="favorite-icon">
                                                    <Link to="#"><i className="uil uil-heart-alt fs-18"></i></Link>
                                                </div>

                                                <img src={SupportIcon} alt="" height="120" className="mb-3" />

                                                <h5 className="fs-17 mb-2">
                                                    <Link to="#" className="text-dark">{t("Support Virtuel")} 24/7</Link></h5>
                                                <p className=""> Uné équipe support composée d'assistants spécialisés, connectés à vos données et aux demandes supports  pour augmenter votre réactivité .</p>

                                                <p>Une fois votre équipe créee et configurée, vos assistants prennent le relai et peuvent répondre automatiquement à des demandes clients, internes ou autres.</p>


                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </TabPane>
                    <TabPane tabId="members">
                        <Card className="">
                            <SimpleBar className="scroller">
                                <div className="d-flex flex-wrap assistant-list">

                                    {
                                        map(members, app => {
                                            return <div className="border border-secondary border-opacity-25 me-1 mt-2">
                                                <div className="float-end ">
                                                    {app.isSelected ? <i className="mdi mdi-check-circle text-success font-size-16 p2-1 pe-1" /> : <i className=" bx bx-radio-circle text-info font-size-20  pe-1" />}
                                                </div>

                                                <div className="hstack app " onClick={() => onSelectedApp(app)}>
                                                    <img src={app.logoUrl} width={60} height={80} />

                                                    <CardBody className="app-content">
                                                        <CardTitle>{app.name}
                                                        </CardTitle>
                                                        {app.description}
                                                    </CardBody>

                                                </div>
                                                <div>
                                                    <div className="float-start ms-2 text-muted font-size-12 mb-1">
                                                        <Badge color="secondary" className="me-1"><i className="bx bx-user " />{app.principalName}</Badge>
                                                        <Badge color="info">{app.app.modelName}</Badge>
                                                    </div>
                                                </div>
                                            </div>
                                        })
                                    }
                                </div>
                            </SimpleBar>
                        </Card>
                    </TabPane>

                    <TabPane tabId="organization">
                        <Card>
                            <CardBody>
                                <Row>
                                    <Col md={6}>
                                        <div className="mb-4">
                                            <Label for="basicpill-name-input1">
                                                {t("Assignation des tickets ou demandes")}:
                                            </Label>
                                            <Select
                                                options={ticketsAssigment}
                                                isMulti={false}
                                                value={assigmentType}
                                                onChange={setAssignmentType}
                                            />
                                        </div>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col md={6}>
                                        <div className="mb-4">
                                            <Label for="basicpill-name-input1">
                                                {t("Evaluation des réponses")}:
                                            </Label>
                                            <Select
                                                options={ticketsEvaluation}
                                                isMulti={false}
                                                value={assessmentType}
                                                onChange={setAssessmentType}
                                            />
                                        </div>
                                    </Col>
                                </Row>

                                {assessmentType?.value === true && <Row>
                                    <Col md={6}>
                                        <div className="mb-4">
                                            <Label for="basicpill-name-input1">
                                                {t("Instructions spécifiques d'évaluation")} (option):
                                            </Label>
                                            <textarea
                                                type="text"
                                                className="form-control"
                                                id="description"
                                                placeholder=""
                                                rows={5}
                                                value={assessmentInstructions}
                                                onChange={e => setAssessmentInstructions(e.target.value)}
                                            />
                                        </div>
                                    </Col>
                                </Row>}

                            </CardBody>

                        </Card>
                    </TabPane>
                </TabContent>
            </div>

        </div>
        <ModalFooter>
            <button disabled={!canCreate} onClick={createTeam} className="btn btn-success">{t("Créer")}</button>
            <button onClick={onCloseClick} className="btn btn-danger">{t("Annuler")}</button>
        </ModalFooter>
    </Modal>
}

export default withTranslation()(AddSupportTeam);