

import React, { useEffect, useRef, useState } from "react";
import { withTranslation } from "react-i18next";
import { Badge, Card, CardBody } from "reactstrap";
import Dropzone from "react-dropzone";
import { isEmpty, map } from "lodash";
import { getFileIcon, uuidv4 } from "utils";
import { Link } from "react-router-dom";
import { deleteUploadedFile, uploadTaskFiles } from "service/documents";
import { operationCompleted, operationFailed } from "utils/notifications";
import AssistantInputChat from "components/Assistant/InputChat";
import { useSelector, useDispatch } from "react-redux";
import { onAskAssistant } from "store/actions";
import { ResourceTypes } from "constants/general";
import SimpleBar from "simplebar-react"
import Question from "../chat/message/question";
import Answer from "../chat/message/answer";

const OmniSearch = ({ assistant, scope, filters, t, currentConversation, onClose }) => {

    const [conversationReferences, setConversationReferences] = useState([]);
    const [isTyping, setIsTyping] = useState(false);
    const [externalValue, setExternValue] = useState(null);
    const [isExtendedInput, setIsExtendedInput] = useState(false);
    const [streamData, setStreamData] = useState({ text: "" });
    const [search, setSearch] = useState();
    const [channelId, setChannelId] = useState(null);
    const displayMessageContainerRef = useRef();
    console.log(scope)
    const dispatch = useDispatch();
    const { accessToken, channels } = useSelector(state => ({
        accessToken: state.Login.accessToken,
        channels: state.Streams.channels
    }));

    const handleInputChange = ({ value, mentions }) => {
    }

    const onCancelRequest = () => {
    }

    const onSubmit = (e) => {

        const { key } = e;
        if (key === "Enter" && !e.shiftKey && !isEmpty(search)) {
            e.preventDefault();
            askFollowUp();
        }
    }

    const askFollowUp = () => {

        const message = {
            id: uuidv4(),
            sentByUser: true,
            role: "user",
            mode: "chat",
            text: search,
            conversationId: currentConversation.id,
            searchScopeFilters: scope.map(sc => ({
                id: sc.id,
                providerId: sc.providerId,
                type: sc.resourceType,
                name: sc.name,
                location: sc.location,
            }))
        }

        dispatch(onAskAssistant({
            channelId: assistant?.id,
            conversationId: currentConversation?.id,
            message: message,
            token: accessToken,
            agentName: assistant?.agentName,
            agentLogo: assistant?.agentLogo,
            createdAt: new Date(),
            cancellationToken: new AbortController(),
        }))
    }

    const scrollToBottom = (smooth) => {
        if (displayMessageContainerRef?.current) {
            const scrollEl = displayMessageContainerRef.current;
            scrollEl?.scroll({
                top: scrollEl?.scrollHeight + 1000,
                behavior: smooth ? 'smooth' : 'auto',
            });
        }
    }

    useEffect(() => {
        setChannelId(assistant?.id);
    }, [assistant])

    useEffect(() => {

        if (isEmpty(channelId))
            return;

        const currentConversationId = currentConversation?.id;
        const channelData = channels[channelId];
        const typing = channelData?.isTyping;
        setIsTyping(typing || false);

        if (!isEmpty(channelData) && currentConversationId in channelData) {
            setStreamData(prev => channelData[currentConversationId]);
            scrollToBottom(true);
        }

    }, [channels, channelId, currentConversation]);


    return <div className="search-omni-chat  pb-4" >
        <div className="d-flex justify-content-between bg-danger bg-opacity- text-white">
            <div className="p-2">
                <div className="hstack gap-2">
                    {assistant?.image && <img src={assistant.image} width={60} height={60} />}
                    <div className={!assistant?.image ? "ms-2 vstack" : "vstack"}>
                        <span className="font-size-16 fw-bold">{t("Nouvelle Conversation")}</span>
                        <div>
                            <Badge className="p-1">{scope?.length} {t("documents")}</Badge>
                        </div>
                    </div>

                </div>
            </div>
            <div className="p-2">
                <button className="btn btn-close text-white" onClick={onClose} />
            </div>
        </div>
        <div className="search-chat-history h-100">
            <SimpleBar className="h-100" scrollableNodeProps={{ ref: displayMessageContainerRef }}>
                {streamData?.messages?.length > 0 && <div className=" p-3"  >

                    <ul className="list-unstyled">
                        <li>
                            <div className="chat-day-title">
                                <span className="title">Just ask</span>
                            </div>

                        </li>
                        {streamData?.messages && map(streamData.messages, message => {
                            return <li className={message.role === "user" ? "right" : ""} >
                                <div className="conversation-list ">
                                    {message.role === "user" && <Question message={message} />}
                                    {message.role === "assistant" &&
                                        <Answer message={message}
                                            agentName={currentConversation?.agentName}
                                            agentLogo={currentConversation?.agentLogo}
                                            onOpenAssistant={console.log}
                                            onSuggestionClicked={console.log}
                                            assistant={assistant}
                                            onNewSuggestion={console.log}

                                        />}
                                </div>
                            </li>
                        })}
                    </ul>
                </div>}
            </SimpleBar>
        </div>
        <div className=" search-chat-input ms-2 me-2 ">
            <textarea
                className="form-control border border-info border-opacity-50"
                placeholder="Just ask"
                onKeyDown={onSubmit}
                value={search}
                onChange={e => setSearch(e.target.value)}
            />

        </div>
    </div>
}

export default withTranslation()(OmniSearch);