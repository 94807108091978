import React, { useEffect, useState } from "react";
import {
    Card
} from "reactstrap";
//i18n
import { withTranslation } from "react-i18next";
import BaseChat from "./chat";
import ResourceRightBar from "../resources/rightbar";

import { useSelector, useDispatch } from "react-redux";
import {
    updateConversationTitle as onUpdateConversationTitle,
    delConversation,
    onChannelHistory,
    showRightSidebarAction,
} from "store/actions";
import { getOrCreateConversation, getAssistantConversations, getConversationMessages, getDatasourceConversations, createConversationWithApp, createConversationWithDatasource } from "service/assistant";
import { isEmpty } from "lodash";
import { uuidv4 } from "utils";
import SearchGPT from "./chat/searchGPT";
import { AssistantTypes, ResourceTypes } from "constants/general";

const WorkspaceAssistant = ({ name, description, logoUrl, icon, assistant, datasource, openAssistant }) => {

    //meta title
    document.title = `Assistants | ${assistant?.name || datasource?.Name}`;

    const [currentConversation, setCurrentConversation] = useState(null);
    const [showConversations, setShowConversations] = useState(false);
    const [conversationsList, setConversationsList] = useState(null);
    const [conversationsHistory, setConversationsHistory] = useState({});
    const [channelId, setChannelId] = useState();
    const [newExperience, setNewExperience] = useState(true);

    const { isLoading, channels } = useSelector(state => ({
        isLoading: state.Omnichat.isLoading,
        channels: state.Streams.channels

    }));

    const showConversationsList = () => {
        dispatch(showRightSidebarAction({
            type: ResourceTypes.CONVERSATIONS,
            assistant: assistant,
            currentConversation: currentConversation,
            conversations: conversationsList,
            onConversationSelected: setCurrentConversation,
            onRename: conversationRenamed,
            onDelete: onDeleteConversation,

        }))
    }

    const dispatch = useDispatch();

    const conversationRenamed = (conversationId, newValue) => {

        dispatch(onUpdateConversationTitle({
            id: conversationId, title: newValue
        }))

        const items = [...conversationsList];
        items.forEach(i => {
            if (i === conversationId) {
                i.title = newValue;
                return;
            }
        });

        setConversationsList(items);
    }

    const onDeleteConversation = (conversationId) => {
        dispatch(delConversation(conversationId))
        setConversationsList(conversationsList.filter(c => c.id !== conversationId));

    }
    const onConversationDelete = (id) => {

        onDelete(id);
        setItems(items.filter(i => i.id !== id));
    }


    const createNewConversation = () => {

        if (assistant?.id) {
            createConversationWithApp(uuidv4(), assistant.id, assistant.principalId).then(conversation => {
                setConversationsList([conversation, ...conversationsList]);
            })
        } else {
            createConversationWithDatasource(uuidv4(), datasource.id).then(conversation => {
                setConversationsList([conversation, ...conversationsList]);
            })
        }

    }

    useEffect(() => {

        if (!isEmpty(currentConversation?.id) && !isEmpty(channelId)) {
            const channelData = channels[channelId] || {};
            const conversationsData = channelData[currentConversation.id] || {};
            const messages = conversationsData.messages || [];

            if (isEmpty(messages)) {
                getConversationMessages(currentConversation.id).then(messages => {
                    dispatch(onChannelHistory({
                        channelId: channelId,
                        conversationId: currentConversation?.id,
                        messages: messages
                    }));
                });
            }

        } else {
        }

    }, [channelId, currentConversation]);


    useEffect(() => {
        if (assistant) {

            setNewExperience(assistant?.app.type === AssistantTypes.SEARCH);

            setChannelId(assistant.id);

            getAssistantConversations(assistant.id).then(conversations => {

                if (conversations?.length == 0) {

                    const conversationInfo = {
                        id: assistant?.id,
                        name: assistant.name,
                        appId: assistant.id,
                        appOwner: assistant?.principalId,
                    }

                    getOrCreateConversation(conversationInfo).then(newConversation => {
                        setConversationsList(prev => [newConversation]);

                    });

                } else {
                    setConversationsList(prev => conversations);
                }
            })
        }
    }, [assistant]);

    useEffect(() => {

        if (conversationsList === null) {
            return;
        }

        if (conversationsList?.length > 0) {
            setCurrentConversation(conversationsList[0])
        } else {
            createNewConversation();
        }
    }, [conversationsList])

    useEffect(() => {

        if (datasource) {
            setChannelId(datasource.id);

            getDatasourceConversations(datasource?.id).then(conversations => {

                if (conversations?.length == 0) {
                    const conversationInfo = {
                        id: datasource.id,
                        name: datasource.name,
                        datasourceId: datasource.id,
                    }

                    getOrCreateConversation(conversationInfo).then(newConversation => {
                        setConversationsList(prev => [newConversation]);
                        setCurrentConversation(prev => newConversation);

                    });
                } else {
                    setConversationsList(prev => conversations);
                    setCurrentConversation(prev => conversations[0]);
                }
            })


        }
    }, [datasource]);


    useEffect(() => {
        if (showConversations) {

        }
    }, [showConversations])

    return <React.Fragment>

        {
            !newExperience ? <Card>
                <div className="d-flex w-100 main-chat">
                    <div className="chatzone w-100" >
                        <BaseChat
                            currentConversation={currentConversation}
                            title={name}
                            description={description}
                            logoUrl={logoUrl}
                            icon={icon}
                            isLoadingHistory={isLoading}
                            onConversationTitleChanged={conversationRenamed}
                            onShowConversationList={showConversationsList}
                            onNewConversation={createNewConversation}
                            assistant={assistant}
                            datasource={datasource}
                            openAssistant={openAssistant}
                            onDeleteConversation={onDeleteConversation}
                            conversations={conversationsList}
                        />
                    </div>
                </div>
            </Card> :
                <div className="w-100 main-chat">
                    <SearchGPT
                        currentConversation={currentConversation}
                        title={name}
                        description={description}
                        logoUrl={logoUrl}
                        icon={icon}
                        isLoadingHistory={isLoading}
                        onConversationTitleChanged={conversationRenamed}
                        onShowConversationList={showConversationsList}
                        onNewConversation={createNewConversation}
                        assistant={assistant}
                        datasource={datasource}
                        openAssistant={openAssistant}
                        onDeleteConversation={onDeleteConversation}
                        conversations={conversationsList}
                    />
                </div>
        }
    </React.Fragment>

}


export default withTranslation()(WorkspaceAssistant);
