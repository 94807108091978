

import React from "react";
import {
    Card,
    CardBody,
    Col,
    Container, Row
} from "reactstrap";

//i18n
import { withTranslation } from "react-i18next";

const DocumentGeneratorPlaceHolder = props => {

    return (
        <div>
            <div className="vstack gap-3 ms-4 me-4 mt-4">

                {/* <div className="mb-2">
                    <h4 className="card-text placeholder-glow ">
                        <span className="placeholder placeholder-lg col-12 mb-2">

                        </span>
                    </h4>
                </div> */}

                <div>
                    <p className="card-text placeholder-glow ">
                        <span className="placeholder placeholder-lg col-6 mb-2"></span>
                        <span className="placeholder placeholder-xs col-12"></span>
                        <span className="placeholder placeholder-xs col-12"></span>
                        <span className="placeholder placeholder-xs col-12"></span>
                        <span className="placeholder placeholder-xs col-12"></span>
                        <span className="placeholder placeholder-xs col-12"></span>
                        <span className="placeholder placeholder-xs col-12"></span>
                    </p>
                </div>

                <div className="mt-4">
                    <p className="card-text placeholder-glow ">
                        <span className="placeholder placeholder-lg col-6 mb-2"></span>
                        <span className="placeholder placeholder-xs col-12"></span>
                        <span className="placeholder placeholder-xs col-12"></span>
                        <span className="placeholder placeholder-xs col-12"></span>
                        <span className="placeholder placeholder-xs col-12"></span>
                        <span className="placeholder placeholder-xs col-12"></span>
                        <span className="placeholder placeholder-xs col-12"></span>
                    </p>
                </div>

                <div className="mt-4">
                    <p className="card-text placeholder-glow ">
                        <span className="placeholder placeholder-lg col-6 mb-2"></span>
                        <span className="placeholder placeholder-xs col-12"></span>
                        <span className="placeholder placeholder-xs col-12"></span>
                        <span className="placeholder placeholder-xs col-12"></span>
                        <span className="placeholder placeholder-xs col-12"></span>
                        <span className="placeholder placeholder-xs col-12"></span>
                        <span className="placeholder placeholder-xs col-12"></span>
                    </p>
                </div>

                <div className="mt-4">
                    <p className="card-text placeholder-glow ">
                        <span className="placeholder placeholder-lg col-6 mb-2"></span>
                        <span className="placeholder placeholder-xs col-12"></span>
                        <span className="placeholder placeholder-xs col-12"></span>
                        <span className="placeholder placeholder-xs col-12"></span>
                        <span className="placeholder placeholder-xs col-12"></span>
                        <span className="placeholder placeholder-xs col-12"></span>
                        <span className="placeholder placeholder-xs col-12"></span>
                    </p>
                </div>


            </div>
        </div >
    );
};


export default withTranslation()(DocumentGeneratorPlaceHolder);

