import { GET_USAGES, GET_USAGES_SUCCESS, GET_USAGES_FAILED, GET_FEEDBACKS_BOARD_FAILED, GET_FEEDBACKS_FAILED, GET_FEEDBACKS_BOARD_SUCCESS, GET_FEEDBACKS_SUCCESS } from "./actionTypes"

const INIT_STATE = {
  board: [],
  feedbacks: [],
  feedbacksBoard: {},
  isLoading: false,
  error: {},
}

const usages = (state = INIT_STATE, action) => {
  switch (action.type) {


    case GET_USAGES:
      return {
        ...state,
        isLoading: true
      }

    case GET_USAGES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        board: action.payload,
      }

    case GET_FEEDBACKS_BOARD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        feedbacksBoard: action.payload
      }

    case GET_FEEDBACKS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        feedbacks: action.payload
      }

    case GET_FEEDBACKS_BOARD_FAILED:
    case GET_FEEDBACKS_FAILED:
    case GET_USAGES_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      }

    default:
      return state
  }
}

export default usages;