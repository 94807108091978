import React, { useState, memo, useEffect } from "react"
import SimpleBar from "simplebar-react"
import { Link } from "react-router-dom";
import { getFileIcon, getInitiales, uuidv4 } from "utils";
import { isEmpty, map } from "lodash";
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import reHype from 'remark-rehype'
import rehypeRaw from 'rehype-raw'

import {
    Card,
    CardBody,
    CardHeader,
    CardFooter,
    DropdownMenu,
    DropdownItem,
    DropdownToggle,
    UncontrolledDropdown,
    UncontrolledTooltip,
    Row,
    Col,
    Badge,
    Progress,
    CardTitle,
    CardSubtitle,

} from "reactstrap"; import { getTime, toLocalDate } from "helpers/to-local-date";
import TodoChatHistory from "../../actions/chatHistory";

import {
    createOmniChatConversation,
    showRightSidebarAction, getStudioApps,
    deleteTodoWorks,
    addTodoWorks,
    updateTodoWorks
} from "store/actions";
import { useSelector, useDispatch } from "react-redux";
import { ResourceTypes } from "constants/general";
import CustomAppRunner from "../../actions/customApp/runner";
import { withTranslation } from "react-i18next";


const TodoItemView = memo(function TodoItemView({ todo, onShowResourceClick, onRunCustomApp, onDelete, t }) {

    const [item, setItem] = useState(false);
    const [appHandler, setAppHandler] = useState(null);
    const [onChatStream, setOnChatStream] = useState("");
    const dispatch = useDispatch();
    const { apps, binders } = useSelector(state => ({
        apps: state.Studio.apps,
        binders: state.Todos.settings.binders,

    }));

    const startOmnichat = () => {

        const payload = {
            id: item.id,
            name: item.name,
            type: ResourceTypes.TASK,
            content: item.content
        }

        dispatch(createOmniChatConversation(payload));
        dispatch(showRightSidebarAction({
            type: ResourceTypes.ASSISTANTS,
            conversation: payload,
        }));
    }

    const onMoveToBinder = (item) => {

        const binders = (todo.binders || []);

        if (binders.includes(item.id))
            return;

        dispatch(updateTodoWorks({
            ...todo,
            binders: [...binders, item.id]
        }))
    }

    useEffect(() => {
        if (!isEmpty(todo)) {
            setItem({ ...todo });

            if (todo.handler) {
                const selectedApp = apps.find(app => app.id === todo.handler.appId);
                setAppHandler(selectedApp);
            } else {
                setAppHandler(null)
            }
        }
    }, [todo])

    return <div className="w-100 ms-2" >
        <Card className="border todoitem " >
            <CardHeader className="border-bottom bg-info bg-opacity-50 rounded-1">
                <div className="modal-header">
                    <div className="hstack gap-1">
                        <div className="flex-shrink-0 me-2">
                            {item.sourceIcon ? <div className="d-flex flex-column  p-1 justify-content-center align-items-center">
                                <img className=" bg-info bg-opacity-50 rounded-circle " width={40} height={40} src={item.sourceIcon} />
                                <Link className="text-dark font-size-10">  {item.sourceName}</Link>
                            </div>

                                : <div className="bg-info bg-opacity-50  rounded-circle p-2">
                                    <h3 className="">{getInitiales(item.sourceName || "")}</h3>
                                </div>
                            }
                        </div>
                        <div className="vstack gap-0">
                            <h5 className="font-size-16 fw-bold ">{item.name}</h5>
                            <div className="hstack gap-2">
                                <UncontrolledDropdown>
                                    <DropdownToggle tag={"a"}>
                                        <Link className="text-dark"><i className="mdi mdi-folder me-1 text-warning" />{t("Labeliser")}  <i className="mdi mdi-chevron-right" /></Link>
                                    </DropdownToggle>
                                    <DropdownMenu >
                                        <DropdownItem>
                                            {t("Nouvelle Categorie")}
                                        </DropdownItem>
                                        {map(binders || [], item => {
                                            return <DropdownItem onClick={() => onMoveToBinder(item)} key={item.id}>
                                                <i className="mdi mdi-folder me-2 text-warning" /> {item.name}
                                            </DropdownItem>
                                        })}
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                                <div className="vr"></div>
                                <UncontrolledDropdown>

                                    <DropdownToggle
                                        href="#"
                                        tag="a" className="dropdown-toggle"
                                    >
                                        <div className="d-flex flex-row justify-content-center align-items-center mt-0">
                                            <i className="mdi mdi-apps text-dark font-size-16 align-middle " />
                                            <span className="font-size-12 text-dark"> {t("Applications")}</span>
                                        </div>
                                    </DropdownToggle>
                                    <DropdownMenu
                                    >

                                        {(apps || []).map(action => {

                                            return <DropdownItem key={action.id}
                                                onClick={() => onRunCustomApp(action)}
                                            >
                                                <i className="mdi mdi-file-tree font-size-16 text-dark me-1" id="processTip"></i>
                                                {action.name}
                                                <UncontrolledTooltip placement="top" target="processTip">
                                                    {action.name}
                                                </UncontrolledTooltip>
                                            </DropdownItem>
                                        })}

                                    </DropdownMenu>
                                </UncontrolledDropdown>
                                <div className="vr"></div>
                                <Link to="#!" onClick={startOmnichat}>
                                    <div className="hstack gap-0 mb">
                                        <i className="mdi mdi-chat text-dark font-size-16" />
                                        <span className="font-size-12 text-dark">{("Assistant")}</span>
                                    </div>
                                </Link>
                                <div className="vr"></div>
                                <UncontrolledDropdown>

                                    <DropdownToggle
                                        href="#"
                                        tag="a" className="dropdown-toggle"
                                        onClick={onShowResourceClick}
                                    >
                                        <div className="hstack">
                                            <i className="mdi mdi-database text-dark font-size-16 " >
                                            </i>
                                            <span className="font-size-12 text-dark">{t("Ressources utiles")} </span>
                                        </div>
                                    </DropdownToggle>

                                </UncontrolledDropdown>

                                <div className="vr"></div>
                                <Link onClick={onDelete} className="text-dark"> <i className="mdi mdi-trash-can text-danger" />{t("Supprimer")}</Link>

                            </div>

                        </div>
                    </div>
                    <div>
                        <p className="text-dark mb-0">{getTime(item.createdAt)}</p>
                    </div>
                </div>

            </CardHeader>

            <CardBody>
                <SimpleBar className="todoItemContent">
                    {!isEmpty(appHandler) && <div>
                        <CustomAppRunner
                            app={appHandler}
                            todo={item} />
                        <div className="hr border border-secondary border-opacity-50 m-4" />

                    </div>}
                    <ReactMarkdown
                        className="markdown"
                        remarkPlugins={[remarkGfm,]} rehypePlugins={[reHype, rehypeRaw]}>
                        {`${item.content}`}
                    </ReactMarkdown>
                </SimpleBar>
                <TodoChatHistory key={item.id} onChatReply={onChatStream} todo={item} />
            </CardBody>
        </Card>
    </div>

});

export default withTranslation()(TodoItemView);
