import React from "react";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function JaskMessage({ message }) {

    return <div className="vstack">
        <div className="hstack gap-1">
            <i className="bx bx-bot font-size-20" />
            <strong className="mt-1 font-size-14">Jask</strong>
        </div>
        <small className="font-size-12">
            {message}
        </small>
    </div>
}

export const operationFailed = (content) => {
    toast.error(<JaskMessage message={content} />);
}

export const operationInfo = (content) => {
    toast.info(<JaskMessage message={content} />);
}

export const operationSucceed = (content) => {
    toast.success(<JaskMessage message={content} />);
}

export const operationCompleted = (content) => {
    toast.info(<JaskMessage message={content} />);
}