import PropTypes from 'prop-types'
import React, { useState } from "react"
import { Col, Modal, ModalBody, ModalFooter, Row } from "reactstrap"
import ResourcesLeftBar from './leftbar'
import ResourcesList from './list'

const UsefulResources = ({ show, onCloseClick, todo }) => {
    const [selectedSource, setSelectedSource] = useState(null);

    const onSelectionChange = (newSelection) => {
        setSelectedSource(newSelection);
    }

    return (
        <Modal size="xl" isOpen={show} toggle={onCloseClick} centered={true} contentClassName='todo-resources-list'>
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id="modalLabel">
                        <i className='fa fa-database text-info me-1' /> Ressources utiles {todo?.name && ": " + todo.name}
                    </h5>
                    <button type="button"
                        onClick={onCloseClick} className="btn-close"></button>
                </div>
                <ModalBody className="px-4 py-5 ">

                    <Row>
                        <Col md={3}>
                            <ResourcesLeftBar selectedSource={selectedSource} onSelectionChange={onSelectionChange} />
                        </Col>
                        <Col md={9}>
                            <ResourcesList todo={todo} selectedSource={selectedSource} />
                        </Col>
                    </Row>
                </ModalBody>


            </div>
            <ModalFooter>

            </ModalFooter>
        </Modal>
    )
}

UsefulResources.propTypes = {
    onCloseClick: PropTypes.func,
    show: PropTypes.any
}

export default UsefulResources;
