import { GET_PROMPTS, GET_PROMPTS_SUCCESS, CREATE_PROMPT, CREATE_PROMPT_FAILED, GET_PROMPTS_FAILED, DELETE_PROMPT_FAILED, CREATE_PROMPT_SUCCESS, DELETE_PROMPT_SUCCESS } from "./actionTypes"

const INIT_STATE = {
  prompts: [],
  isLoading: false,
  isAdding: false,
  error: {},
}

const PromptStore = (state = INIT_STATE, action) => {
  switch (action.type) {

    case GET_PROMPTS:
      return {
        ...state,
        isLoading: true
      }

    case GET_PROMPTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        prompts: action.payload,
      }

    case CREATE_PROMPT_SUCCESS:

      const others = state.prompts.filter(p => p.id !== action.payload.id);
      return {
        ...state,
        isAdding: false,
        prompts: [action.payload, ...others]

      }

    case CREATE_PROMPT:
      return {
        ...state,
        isAdding: true,
      }

    case DELETE_PROMPT_SUCCESS:
      return {
        ...state,
        prompts: state.prompts.filter(p => p.id !== action.payload)
      }

    case CREATE_PROMPT_FAILED:
    case GET_PROMPTS_FAILED:
    case DELETE_PROMPT_FAILED:
      return {
        ...state,
        isLoading: false,
        isAdding: false,
        error: action.payload
      }

    default:
      return state
  }
}

export default PromptStore;