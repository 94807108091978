import React, { useState } from "react";
import {
    Col,
    Container, Row
} from "reactstrap";

import { withTranslation } from "react-i18next";
import ResourcesLeftBar from "./leftbar";
import ResourceViewer from "./content";
import { useSelector, useDispatch } from "react-redux";
import { ResourceTypes } from "constants/general";
import { operationFailed } from "utils/notifications";

//meta title
document.title = "Mes ressources | Jask";

const MyResources = props => {

    const [selectedResource, setSelectedResource] = useState(null);
    const { assistants, datasources } = useSelector(state => ({
        assistants: state.Studio.assistants,
        datasources: state.Datasources.datasources,

    }));

    const openAssistant = (assistant) => {
        const targetedAssistant = assistants.find(a => a.id === assistant.id);

        if (targetedAssistant?.id) {
            setSelectedResource({
                type: ResourceTypes.ASSISTANTS,
                assistant: targetedAssistant,
                name: targetedAssistant.name,
                description: targetedAssistant.description,
                logoUrl: targetedAssistant.logoUrl
            })
        } else {
            operationFailed("Vous n'avez pas encore à cette application. Veuillez demander l'accès à son proprietaire.")
        }
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {/* <Row>
                        <Col xs={12}> */}

                    <ResourcesLeftBar onResourceSelected={setSelectedResource} assistants={assistants} datasources={datasources} selectedResource={selectedResource} />
                    <ResourceViewer resource={selectedResource} openAssistant={openAssistant} />

                    {/* </Col>
                    </Row> */}

                </Container>
            </div>
        </React.Fragment >
    );
};

export default withTranslation()(MyResources);
