import React, { useEffect, useState } from "react";
import {
    Card,
    CardBody,
    Col,
    Container,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
    Table
} from "reactstrap";
import classnames from "classnames"
import SimpleBar from "simplebar-react"
import { isEmpty, map } from "lodash";
import { getInitiales } from "utils";
import { withTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { showRightSidebarAction } from "store/actions";
import { ResourceTypes } from "constants/general";

//i18n
const TopConsumers = ({ consumers, isLoading, t }) => {
    const [activeTab, setactiveTab] = useState("assistants")
    const [tabs, setTabs] = useState([]);
    const [dataView, setDataView] = useState([]);
    const [principalNameFilter, setPrincipalNameFilter] = useState(null);
    const dispatch = useDispatch();

    const { assistants } = useSelector(state => ({
        assistants: state.Studio.assistants,
    }));


    const openResourceProfile = (resource) => {
        dispatch(showRightSidebarAction({
            type: ResourceTypes.RESOURCE_PROFILE,
            resource: resource
        }))
    }
    const viewFilter = (item, filter) => {
        if (isEmpty(filter))
            return item;

        return {
            ...item,
            values: item.values.filter(i => i.name.toLowerCase().startsWith(filter.toLowerCase()) || i.name.toLowerCase().includes(filter.toLowerCase()))
        }
    }

    const findAssistants = (resource) => {

        const assistant = assistants.find(app => app.id == resource.id);

        if (assistant) {

            return {
                ...resource,
                ...assistant
            }
        }

        return resource
    }

    useEffect(() => {

        if (isEmpty(consumers) || !isEmpty(principalNameFilter))
            return;


        const content = [

            {
                id: "assistants",
                name: "Assistants",
                values: consumers.assistants.map(findAssistants),
                icon: "bx bx-bot",
            },
            {
                id: "users",
                name: "Utilisateurs",
                values: consumers.users,
                icon: "bx bx-user",
            },
        ]

        setTabs(content);

    }, [consumers]);


    return (
        <Col md={6}>
            <Card className="card-item">
                <CardBody>
                    <div className="d-flex justify-content-between">
                        <div>
                            <h4 className="card-title mb-4">Statistiques détaillées</h4>
                        </div>
                        <div className="mb-3">
                            <div className="hstack gap-1">
                                <i className="bx bx-search font-size-18" />
                                <input type="text"
                                    className="form-control border rounded-2"
                                    placeholder="recherche"
                                    style={{ width: 200 }}
                                    onChange={e => setPrincipalNameFilter(e.target.value)}
                                />
                            </div>

                        </div>
                    </div>
                    <Nav pills className="bg-light rounded" role="tablist">
                        {
                            map(tabs, item => {
                                return <NavItem key={item.id}>
                                    <NavLink
                                        className={classnames({ active: activeTab === item.id })}
                                        onClick={() => setactiveTab(item.id)}
                                    >
                                        <i className={item.icon + " me-1"} />
                                        {item.name}
                                    </NavLink>
                                </NavItem>
                            })
                        }

                    </Nav>

                    <TabContent activeTab={activeTab} className>
                        {
                            map(tabs.map(profile => viewFilter(profile, principalNameFilter)), item => {
                                return <TabPane key={item.id} tabId={item.id}>
                                    <SimpleBar className="scroller">
                                        <div className="table-responsive p-2 pt-4">
                                            <Table className="table align-middle table-nowrap">
                                                <thead className="">
                                                    <tr>
                                                        <td className="th"></td>
                                                        <td className="th text-end fw-bold">Nb. requêtes</td>
                                                        <td className="th text-end fw-bold"> Feedback positifs</td>
                                                        <td className="th text-end fw-bold"> Feedback négatifs</td>
                                                        <td className="th text-end fw-bold"> Negatif / Total</td>

                                                    </tr>
                                                </thead>
                                                <tbody>

                                                    {
                                                        map(item.values || [], value => {
                                                            return <tr key={value.id} style={{ cursor: "pointer" }} onClick={() => openResourceProfile(value)}>
                                                                <td>
                                                                    <div className='hstack'>

                                                                        {
                                                                            value.logoUrl ?
                                                                                <div className="avatar-xs me-2">
                                                                                    <span className="rounded-circle ">
                                                                                        <img src={value.logoUrl} width={40} height={40} className="rounded-circle avatar-xs" />
                                                                                    </span>
                                                                                </div> : <div className="avatar-xs me-2">
                                                                                    <span className="avatar-title rounded-circle bg-info bg-soft text-info font-size-18">
                                                                                        {getInitiales(value.name)}
                                                                                    </span>
                                                                                </div>
                                                                        }

                                                                        <h5 className="font-size-14 mb-1">{value.name}</h5>

                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div className="text-end ">
                                                                        <h5 className="font-size-14 mb-0">{new Intl.NumberFormat('fr-FR').format(value.totalRequests)}</h5>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div className="text-end">
                                                                        <h5 className="font-size-14 mb-0">{new Intl.NumberFormat('en-US').format(value.totalPositive)}</h5>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div className="text-end">
                                                                        <h5 className="font-size-14 mb-0">{new Intl.NumberFormat('fr-FR').format(value.totalNegative)} </h5>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div className="text-end">
                                                                        <h5 className="font-size-14 mb-0">{new Intl.NumberFormat('fr-FR').format(value.ratio * 100)} %</h5>
                                                                    </div>
                                                                </td>


                                                            </tr>
                                                        })
                                                    }
                                                </tbody>

                                            </Table>
                                        </div>

                                    </SimpleBar>
                                </TabPane>
                            })
                        }

                    </TabContent>

                </CardBody>
            </Card>

        </Col>
    );
};


export default withTranslation()(TopConsumers);