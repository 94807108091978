import { map } from "lodash";
import React from "react";
import { Badge, Card, CardBody, CardTitle } from "reactstrap";


const scenarios = [{
    id: 'simple',
    title: 'Analyse simple',
    icon: <i className="mdi mdi-file me-1" />,
    description: 'Traiter chacun des fichiers indépendament en appliquant vos instructions ou applications. Ce scénario produit autant de résultats qu\'il y a de fichiers à traiter. ',
    enabled: true,
},
{
    id: 'multiple',
    title: 'Analyse groupée',
    icon: <i className="mdi mdi-file-document-multiple me-1" />,
    description: 'Traiter tous les fichiers en même temps en appliquant vos instructions ou applications. Ce scénario produit un seul résultat pour l\'ensemble des fichiers. ',
    enabled: true,

},
{
    id: 'compare',
    title: 'Comparaison',
    icon: <i className="mdi mdi-file-compare me-1" />,
    description: 'Applique vos instructions de prétraitements sur chacun des fichiers et les compare  selon vos axes d\'analyses. Ce scénario produit un seul résultat final pour l\'ensemble des fichiers.',
    enabled: false,
}

]

const ScenarioPicker = ({ onScenarionSelected, scenario }) => {

    return <div className="scenario-picker d-flex flex-column align-items-center justify-content-center">
        <div className="d-flex mb-2 ">
            <h5>Chosissez votre scénario de traitements:</h5>
        </div>
        <div className="  d-flex align-items-center justify-content-center">
            {map(scenarios, item => {
                return <Card
                    onClick={() => {
                        if (item.enabled)
                            onScenarionSelected(item)
                    }}
                    className="border border-danger border-opacity-25 m-2 p-2 scenario"
                >
                    <CardBody>
                        <CardTitle>{item.icon} {item.title}
                            {scenario?.id === item.id && <div className="float-end">
                                <i className="mdi mdi-check-circle text-success" />
                            </div>
                            }

                            {!item.enabled && <div className="float-end">
                                <Badge>Vérrouillé</Badge>
                            </div>
                            }
                        </CardTitle>
                        <p>{item.description}</p>
                    </CardBody>
                </Card>
            })}
        </div>
    </div>
}

export default ScenarioPicker;