

import React, { useEffect, useState } from "react";
import {
    Card,
    CardBody,
    CardTitle,
    Col,
    Container, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Row
} from "reactstrap";
import SimpleBar from "simplebar-react"

//i18n
import { withTranslation } from "react-i18next";
import { isEmpty, map } from "lodash";
import { Link } from "react-router-dom";

const ItemVIew = ({ item, itemSelected, onSelectionChange, selectAll }) => {
    const [selected, setSelected] = useState(itemSelected);
    const [instructions, setInstructions] = useState(null);
    const [showInstructions, setShowInstructions] = useState(false);

    const onNewInstruction = (e) => {
        const { key, value } = e;

        if ((!isEmpty(key) && key !== "Enter") || isEmpty(instructions))
            return;
        setShowInstructions(!showInstructions);
    }

    useEffect(() => {
        onSelectionChange({
            id: item.id,
            selected: selected,
            instructions: instructions,
            item: item
        });
    }, [selected, instructions])

    useEffect(() => {
        setSelected(selectAll);
    }, [selectAll])

    useEffect(() => {
        setSelected(itemSelected);
    }, [itemSelected])

    switch (item.type) {
        case "Header":
            return <div className="hstack gap-2 mb-2 mt-2">
                <input id={"checkbox_" + item.id} type="checkbox" checked={selected} onClick={e => setSelected(!selected)} className="mb-2" />
                <h6>{item.text.replace("\n", " ")}</h6>
            </div>
        case "Title":
            return <div className="hstack gap-2 mb-2 mt-2">
                <input id={"checkbox_" + item.id} type="checkbox" checked={selected} onClick={e => setSelected(!selected)} className="mb-2 form-check-input" />
                <h6 className="">{item.text}</h6>
                {selected && <div className="mb-2">
                    {/* <Link className=""><i className="mdi mdi-pencil text-warning " /></Link> */}
                    <Dropdown
                        isOpen={showInstructions}
                        toggle={() => setShowInstructions(!showInstructions)}
                    >
                        <DropdownToggle tag={"a"}>
                            <Link className=""><i className="mdi mdi-chat font-size-20 " onClick={() => setShowInstructions(!showInstructions)} />
                            </Link>
                            {!isEmpty(instructions) && <span className=" position-absolute ms-1 top-0 start-50 translate-start badge rounded-pill bg-danger">1 <span className="visually-hidden">unread messages</span></span>
                            }                        </DropdownToggle>

                        <DropdownMenu data-popper-placement="left-start">

                            <div className="p-2" style={{ width: 300, overflow: "visible" }}>
                                <div className="d-flex justify-content-between border-bottom mb-2 p-1">
                                    <h6 className="font-size-12 text-muted">
                                        <i className="mdi mdi-chat me-1" />
                                        Instructions spécifiques</h6>
                                    <button className="btn-close" onClick={() => setShowInstructions(!showInstructions)}></button>
                                </div>
                                <input type="text"
                                    className="form-control"
                                    onKeyPress={onNewInstruction}
                                    value={instructions}
                                    onChange={e => setInstructions(e.target.value)}
                                />
                            </div>
                        </DropdownMenu>
                    </Dropdown>

                </div>
                }
            </div>

        case "ListItem":
        case "NarrativeText":
            return <div className="ms-4 hstack gap-2">
                <div>
                    <input type="checkbox" className="form-check-input" checked={selected} onClick={e => setSelected(!selected)} />
                </div>
                <p className="mt-2">{item.text}</p>
                {selected && <div className="mb-2">
                    {/* <Link className=""><i className="mdi mdi-pencil text-warning " /></Link> */}
                    <Dropdown
                        isOpen={showInstructions}
                        toggle={() => setShowInstructions(!showInstructions)}
                    >
                        <DropdownToggle tag={"a"}>
                            <Link className=""><i className="mdi mdi-chat font-size-20 " onClick={() => setShowInstructions(!showInstructions)} />
                            </Link>
                            {!isEmpty(instructions) && <span className=" position-absolute ms-1 top-0 start-50 translate-start badge rounded-pill bg-danger">1 <span className="visually-hidden">unread messages</span></span>
                            }                        </DropdownToggle>

                        <DropdownMenu data-popper-placement="left-start">

                            <div className="p-2" style={{ width: 300, overflow: "visible" }}>
                                <div className="d-flex justify-content-between border-bottom mb-2 p-1">
                                    <h6 className="font-size-12 text-muted">
                                        <i className="mdi mdi-chat me-1" />
                                        Instructions spécifiques</h6>
                                    <button className="btn-close" onClick={() => setShowInstructions(!showInstructions)}></button>
                                </div>
                                <input type="text"
                                    className="form-control"
                                    onKeyPress={onNewInstruction}
                                    value={instructions}
                                    onChange={e => setInstructions(e.target.value)}
                                />
                            </div>
                        </DropdownMenu>
                    </Dropdown>

                </div>
                }
            </div>
    }
}

const TemplateStructure = ({ location, structure, onItemChange }) => {

    const [selectAll, setSelectAll] = useState(false);

    useEffect(() => {
        structure?.forEach(item => {
            onItemChange(location, { ...item, selected: selectAll })
        })
    }, [selectAll])



    return (
        <div className="p-2  ">
            <div className=" ps-20 pt-2 ">
                <div className="hstack gap-2 ">
                    <input
                        type="checkbox"
                        className="form-check-input"
                        id="customCheckcolor1"
                        checked={selectAll}
                        onClick={() => {
                            setSelectAll(!selectAll)
                        }}
                    />
                    <h5>Structure du document </h5>
                </div>
                <small className="text-muted font-size-12 ms-3">Veuillez selectionner les éléments qui devront être contextualisés</small>
                <hr />
            </div>
            <SimpleBar className="template-structure-content">
                <div className="me-2">
                    {map(structure || [], (template) => {
                        return <div key={template.id} className="hstack gap-1">
                            <ItemVIew item={template.item} itemSelected={template.selected} onSelectionChange={i => onItemChange(location, i)} selectAll={selectAll} />
                        </div>
                    })}
                </div>
            </SimpleBar>
        </div>
    );
};


export default withTranslation()(TemplateStructure);

