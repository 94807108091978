import React, { useState, useRef, useEffect } from "react"
import PropTypes from 'prop-types'

import { Modal, ModalBody, Card, CardBody, Row, Col, Label, Input, Spinner, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap"
import "react-perfect-scrollbar/dist/css/styles.css";
import { Link } from "react-router-dom";
import { getFileIcon, uuidv4 } from "utils";
import { deleteUploadedFile, uploadFile } from "service/documents";
import { isEmpty, map } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select"
import {
    addTodoWorks as onCreateTodo
} from "store/actions";
import ApplicationListSelect from "components/ApplicationSelection";
import ImageLightBox from "components/Images/ImageLighBox";
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import reHype from 'remark-rehype'
import rehypeRaw from 'rehype-raw'
import SimpleBar from "simplebar-react"
import { withTranslation } from "react-i18next";

const CreateTodoItem = ({ todoItem, show, onConfirmlick, onCloseClick, isCreating, content, sourceName, sourceType, sourceId, sourceIcon, t }) => {

    const [attachments, setAttachments] = useState([]);
    const [name, setName] = useState("");
    const [description, setDescription] = useState(content || "");
    const [canCreate, setCanCreate] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [username, setusername] = useState("");
    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCatory] = useState([]);
    const [selectedHandler, setSelectedHandler] = useState(null);
    const [previewApp, setPreviewApp] = useState(false);
    const [isEdit, setIsEdit] = useState(true);

    const dispatch = useDispatch();
    const fileRef = useRef(null);

    const { isAdding, account, binders } = useSelector(state => ({
        isAdding: state.Todos.isAdding,
        account: state.Profile.account,
        binders: state.Todos.settings.binders,
    }));

    const onAppSelected = (app) => {
        console.log(app)
        if (!isEmpty(app)) {
            setSelectedHandler({
                appId: app.id,
                appOwner: app.principalId,
                appOwnerName: app.principalName,
                image: app.image
            });
        } else {
            setSelectedHandler(null);
        }

    }


    useEffect(() => {
        setusername(`${account?.firstName} ${account?.name}`);
    }, [account])

    useEffect(() => {
        if (!isEmpty(content)) {
            setDescription(content);
            setIsEdit(false);

        }
    }, [content])

    useEffect(() => {
        setCategories(binders?.map(b => ({
            label: b.name,
            value: b
        })));
    }, [binders])

    useEffect(() => {
        console.log("handker", selectedHandler)
    }, [selectedHandler])


    const onNewAttachment = (e) => {
        const fileObj = e.target.files && e.target.files[0];
        if (!fileObj) {
            return;
        }

        const target_dir = "workspace/tasks";
        const newFile = {
            id: uuidv4(),
            name: fileObj.name,
            content: fileObj
        }

        setAttachments([...attachments, newFile]);
        uploadFile(fileObj, target_dir).then(resp => {
            newFile.location = resp.attachments[0];
        })
    }
    const removeAttachment = file => {
        deleteUploadedFile(file.location);
        setAttachments([...attachments.filter(a => a.location !== file.location)]);
    }

    const addBinder = (binder) => {
        setSelectedCatory([...selectedCategory, binder]);
    }

    const removeBinder = (id) => {
        setSelectedCatory(selectedCategory.filter(b => b.id !== id));
    }

    const createTodo = () => {

        const payload = {
            name: name,
            content: description,
            attachments: attachments.map(a => a.location),
            provider: "default",
            sourceId: sourceId,
            sourceName: sourceName || username,
            sourceType: sourceType || "users",
            sourceIcon: sourceIcon,
            binders: selectedCategory.map(s => s.id),
            handler: selectedHandler
        }

        setIsLoading(true);
        dispatch(onCreateTodo(payload));
    }

    useEffect(() => {
        if (!isAdding && isLoading) {
            setIsLoading(false);
            onCloseClick();
        }
    }, [isAdding])

    useEffect(() => {
        setCanCreate(!isLoading && !isEmpty(name) && name.length > 3 && !isEmpty(description))
    }, [name, description]);

    // useEffect(() => {
    //     if (show === true) {
    //     }
    // }, [show])

    return (
        <React.Fragment>

            <ImageLightBox
                isOpen={previewApp}
                image={selectedHandler?.image}
                onClose={() => setPreviewApp(false)}
            />

            <Modal size="lg" isOpen={show} toggle={onCloseClick} centered={true} contentClassName="bg-dark bg-opacity-90 ">
                <div className="modal-header bg-neutral text-white">
                    <div className="vstack">

                        <h5 className="modal-title " id="exampleModalLabel">
                            <div className='hstack gap-1'>
                                <i className='bx bx-task text-success bold font-size-24 me-1' />
                                <span>{t("Nouvelle tache")}</span>
                            </div>
                        </h5>

                        <div className="hstack gap-1 ps-4 pt-1 ms-2">
                            {map(selectedCategory, item => {

                                return <div className="hstack bg-secondary rounded ps-1 pe-1">
                                    <i className="mdi mdi-folder text-warning me-1" />
                                    <small>{item.name}</small>
                                    <Link onClick={() => removeBinder(item.id)}><i className=" ms-1 mdi mdi-close text-danger " /></Link>
                                </div>
                            })}
                            <UncontrolledDropdown>
                                <DropdownToggle tag={"a"}>
                                    <Link className="text-white bg-secondary rounded  pe-2"><i className="mdi mdi-plus" /><small>{t("Nouveau label")}</small></Link>
                                </DropdownToggle>

                                <DropdownMenu>
                                    {map(binders || [], binder => {
                                        return <DropdownItem key={binder.id} onClick={() => addBinder(binder)}>
                                            <i className="mdi mdi-folder text-warning me-1" />
                                            {binder.name}
                                        </DropdownItem>
                                    })}
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        </div>
                    </div>

                    <button type="button"
                        onClick={onCloseClick} className="btn-close"></button>
                </div>
                <div className="modal-content">
                    <ModalBody >
                        <Card>
                            <div className="p-1">
                                <Row>
                                    <Col lg="12">
                                        <div className="mb-3">
                                            <Label className="fw-bold" for="name">
                                                {t("Titre")}:
                                            </Label>
                                            <Input
                                                type="text"
                                                className="form-control"
                                                id="name"
                                                placeholder="Titre de la tache"
                                                value={name}
                                                onChange={e => setName(e.target.value)}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg="12">
                                        <div className="mb-3">
                                            <Label className="fw-bold" for="description">
                                                {("Description")}:
                                            </Label>
                                            {isEdit ? <textarea
                                                type="text"
                                                rows={10}
                                                className="form-control"
                                                id="description"
                                                placeholder="Description de la tache..."
                                                value={description}
                                                onChange={e => setDescription(e.target.value)}
                                            /> : <div className="border border-secondary border-opacity-50 p-2">
                                                <SimpleBar className="scroller-create-task-content">
                                                    <ReactMarkdown className="text-muted markdown" remarkPlugins={[remarkGfm, reHype, rehypeRaw]}>{description}</ReactMarkdown>
                                                </SimpleBar>
                                                <div className="float-end mt-3 "><Link className="text-muted" onClick={() => setIsEdit(true)}>{t("Editer le contenu")}</Link></div>

                                            </div>}


                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg="12">
                                        <div className="mb-3">
                                            <Label className="fw-bold" for="description">
                                                <i className="mdi mdi-apps text-info font-size-14" /> {t("Déleguer cette tache à une application")}:
                                            </Label>
                                            <div>
                                                <ApplicationListSelect
                                                    onAppSelected={onAppSelected}
                                                    appOnly={true}
                                                />
                                                {!isEmpty(selectedHandler?.appId) && <div className="float-end mt-1">
                                                    <Link className="text-muted" onClick={() => setPreviewApp(true)}><i className="mdi mdi-loop" />Aperçu</Link>
                                                </div>}
                                            </div>
                                        </div>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col lg={12}>
                                        {
                                            attachments && <div className="d-flex flex-row">
                                                {map(attachments, file => {
                                                    return <div className="p-2 ">
                                                        <div to="#" className="rounded bg-light bg-opacity-50">
                                                            <div className="hstack gap-1 p-2">
                                                                {getFileIcon(file.name)}
                                                                <span className="font-size-10">{file.name}</span>
                                                                <Link onClick={() => removeAttachment(file)} ><i className="mdi mdi-close text-danger font-size-14" /></Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                })}

                                            </div>
                                        }
                                    </Col>
                                </Row>
                                <Row className="pt-4">
                                    <Col lg="12">
                                        <div className="d-flex justify-content-between">
                                            <div className="">
                                                <input type="file" hidden ref={fileRef} onChange={onNewAttachment} accept="application/pdf, application/vnd.ms-excel, application/vnd.ms-word, .txt" />
                                                <button className='btn btn-info me-2' onClick={() => fileRef.current.click()}>
                                                    <i className="mdi mdi-file-upload font-size-16 " /> {t("Importer")}
                                                </button>
                                                {/* <button className="btn btn-info">
                                                <i className=" mdi mdi-lightbulb-on-outline font-size-16" />
                                                Enrichir
                                            </button> */}
                                            </div>
                                        </div>

                                    </Col>
                                </Row>

                            </div>
                        </Card>
                    </ModalBody>
                </div>
                <div className='modal-footer'>
                    <div className='hstack gap-1'>
                        <button className='btn btn-success' disabled={!canCreate} onClick={createTodo}>Créer</button>
                        <button className='btn btn-danger' onClick={onCloseClick}>{t("Annuler")}</button>
                        {isLoading && <div className="ms-2 vr me-1" />
                        }                    {isLoading && <Spinner />}
                    </div>
                </div>
            </Modal>
        </React.Fragment>

    )
}

CreateTodoItem.propTypes = {
    onCloseClick: PropTypes.func,
    onConfirmlick: PropTypes.func,
    show: PropTypes.any
}

export default withTranslation()(CreateTodoItem);