import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import {
  changeLayout,
  changeLayoutMode,
  changeLayoutWidth,
  changeSidebarTheme,
  changeSidebarThemeImage,
  changeSidebarType,
  changePreloader,
  changeTopbarTheme,
  showRightSidebarAction,
} from "../../store/actions";
import { useSelector, useDispatch } from "react-redux";
import OmniChat from "pages/Workspace/assistant/omnichat";
import { ResourceTypes } from "constants/general";
import Omniapp from "pages/Workspace/assistant/omniapp";
import ResourceProfile from "pages/Monitoring/profile"
import DocumentPreview from "components/DocumentPreview";
import OmniSearch from "pages/Workspace/assistant/omniSearch";
import { AnimatePresence, motion } from "framer-motion";
import OmniConversations from "pages/Workspace/assistant/omniConversations";
import { isEmpty } from "lodash";

const RightSidebar = props => {

  const [show, setShow] = useState(false);

  const { sideBar } = useSelector(state => ({
    sideBar: state.Layout.showRightSidebar,
  }));

  const onClose = () => {
    props.onClose();
    setShow(false);
  }



  const getSideBarView = (content) => {
    switch (content?.type) {

      case ResourceTypes.RESOURCE_PROFILE:
        return <ResourceProfile resource={content.resource} onClose={() => props.showRightSidebarAction(false)} />

      case ResourceTypes.ASSISTANTS:
        return <OmniChat {...props} />

      case ResourceTypes.APPLICATIONS:
        return <Omniapp app={content.app} onClose={() => props.showRightSidebarAction(false)} />

      case ResourceTypes.DOCUMENT:
        return <DocumentPreview resource={content.resource} onClose={() => props.showRightSidebarAction(false)} />

      case ResourceTypes.SEARCH:
        return <OmniSearch  {...content} onClose={() => props.showRightSidebarAction(false)} />

      case ResourceTypes.CONVERSATIONS:
        return <OmniConversations  {...content} onClose={() => props.showRightSidebarAction(false)} />
    }
  }

  useEffect(() => {
    setShow(!isEmpty(sideBar))
  }, [sideBar])


  return (
    <AnimatePresence>
      {show && <motion.div


      >
        <div className={(sideBar?.type === ResourceTypes.ASSISTANTS || sideBar?.type === ResourceTypes.CONVERSATIONS) ? "right-bar " : "right-bar-large "} id="right-bar">
          <div data-simplebar className="h-100">
            <hr className="my-0" />
            <div>
              {getSideBarView(sideBar)}
            </div>
          </div>
        </div>
        <div className="rightbar-overlay"></div>
      </motion.div>}
    </AnimatePresence>
  );
};

RightSidebar.propTypes = {
  changeLayout: PropTypes.func,
  changeLayoutWidth: PropTypes.func,
  changePreloader: PropTypes.func,
  changeSidebarTheme: PropTypes.func,
  changeSidebarThemeImage: PropTypes.func,
  changeSidebarType: PropTypes.func,
  changeTopbarTheme: PropTypes.func,
  isPreloader: PropTypes.any,
  layoutType: PropTypes.any,
  layoutModeType: PropTypes.any,
  changeLayoutMode: PropTypes.func,
  layoutWidth: PropTypes.any,
  leftSideBarTheme: PropTypes.any,
  leftSideBarThemeImage: PropTypes.any,
  leftSideBarType: PropTypes.any,
  showRightSidebarAction: PropTypes.func,
  topbarTheme: PropTypes.any,
  onClose: PropTypes.func,
};

const mapStateToProps = state => {
  return { ...state.Layout };
};

export default connect(mapStateToProps, {
  changeLayout,
  changeLayoutMode,
  changeSidebarTheme,
  changeSidebarThemeImage,
  changeSidebarType,
  changeLayoutWidth,
  changeTopbarTheme,
  changePreloader,
  showRightSidebarAction,
})(RightSidebar);
