import { API_URL } from "helpers/api_helper";
import { RUN_BOARD, RUN_APP } from "helpers/url_helper";
import { fetchEventSource } from "@microsoft/fetch-event-source";

export const runBoardAsStream = async (message, onMessage, onStart, onFinish, accessToken) => {
    await fetchEventSource(`${API_URL}${RUN_BOARD}`,
        {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`,
            },
            body: JSON.stringify(message),
            // signal: ctrl.signal,
            onopen(res) {
                if (res.ok && res.status === 200) {
                    console.log("Connection made ", res);
                    onStart();
                } else if (
                    res.status >= 400 &&
                    res.status < 500 &&
                    res.status !== 429
                ) {
                    console.log("Client side error ", res);
                    onFinish();
                }
            },
            onmessage(event) {
                try {
                    const parsedEvent = JSON.parse(event.data);
                    onMessage(parsedEvent);
                } catch (err) {
                    console.log("Parsing error ", err)
                }
            },
            onclose() {
                console.log("Connection closed by the server");
                onFinish();
            },
            onerror(err) {
                console.log("There was an error from server:", err);
                onFinish();
                throw err
            },
        });
};

export const runCustomAppOverDocuments = async (message, onMessage, onStart, onFinish, accessToken, cancellation) => {
    await fetchEventSource(`${API_URL}${RUN_APP}`,
        {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`,
            },
            body: JSON.stringify(message),
            signal: cancellation,
            onopen(res) {
                if (res.ok && res.status === 200) {
                    console.log("Connection made ", res);
                    onStart();
                } else if (
                    res.status >= 400 &&
                    res.status < 500 &&
                    res.status !== 429
                ) {
                    console.log("Client side error ", res);
                    onFinish();
                }
            },
            onmessage(event) {
                try {
                    const parsedEvent = JSON.parse(event.data);
                    onMessage(parsedEvent);
                } catch (err) {
                    console.log("Parsing error ", err)
                }
            },
            onclose() {
                console.log("Connection closed by the server");
                onFinish();
            },
            onerror(err) {
                console.log("There was an error from server:", err);
                onFinish();
                throw err
            },
        });
};