
import React, { Fragment, memo, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { Input } from "reactstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import { useSelector, useDispatch } from "react-redux";
import {
    getConversationHistory as onGetConversationHistory
} from "store/actions";
import { toLocalDate } from "helpers/to-local-date";
import { askYourTodo } from "service/todo";
import { isEmpty, map } from "lodash";
import { uuidv4 } from "utils";

const PreviewContent = ({ content, size = 200 }) => {

    const [preview, setPreview] = useState("");
    const [isPreview, setIsPreview] = useState(false);
    const showAll = (piece, state) => {
        setPreview(piece);
        setIsPreview(state);
    }

    useEffect(() => {
        if (content && content?.length > size) {
            showAll(content.slice(0, size), true)
        } else {
            showAll(content, false);
        }
    }, [content])

    return <div>
        <p>{preview}
            {isPreview && <Link onClick={() => showAll(content, false)}>... Voir plus</Link>}
            {!isPreview && <Link onClick={() => showAll(content.slice(0, size), true)}> Voir moins</Link>}
        </p>
    </div>
}

const TodoChatHistory = memo(function TodoChatHistory({ onChatReply, todo, onRequest }) {

    const [showMessages, setShowMessages] = useState(false);
    const [showInput, setShowInput] = useState(false);
    const [input, setInput] = useState("");
    const [messages, setMessages] = useState([]);
    const [messgesCount, setMessagesCount] = useState(0);
    const [streamContent, setStreamContent] = useState("");
    const [receiving, setIsReceiving] = useState(false);
    const displayMessageContainerRef = useRef();

    const dispatch = useDispatch();
    const { loadingHistory, conversationsHistory } = useSelector(state => ({
        history: state.Assistant.history,
        conversationsHistory: state.Assistant.conversationsHistory,
        loadingHistory: state.Assistant.loadingHistory
    }));

    const scrollToBottom = () => {
        if (displayMessageContainerRef?.current) {
            const scrollEl = displayMessageContainerRef.current;
            scrollEl?.scroll({
                top: scrollEl?.scrollHeight + 1000,
            });
        }
    }

    const onStreamStart = () => {
        setStreamContent("");
        setIsReceiving(true);
    }

    const onReceptionCompleted = (completeStream) => {
        setMessages([...messages, {
            id: uuidv4(),
            text: completeStream,
            role: "assistant",
            createdAt: new Date().toString()
        }])
        setIsReceiving(false);
    }

    const onNewStream = (e) => {
        setStreamContent(prev => prev + e);
        scrollToBottom();
    }

    const submit = (e) => {

        const { key, value } = e;
        if ((!isEmpty(key) && key !== "Enter") || isEmpty(input))
            return;

        setMessages([...messages, {
            id: uuidv4(),
            text: input,
            role: "user",
            createdAt: new Date().toString()
        }])

        askYourTodo(todo.id, input, todo?.content, onNewStream, onStreamStart, onReceptionCompleted)
        setInput("");
        setShowInput(!showInput);
    }

    useEffect(() => {

        if (onChatReply) {
            setStreamContent(prev => prev + onChatReply);
        }

    }, [onChatReply])

    useEffect(() => {

        if (!showMessages || todo?.linkedConversationMessageCount < 1)
            return;

        scrollToBottom();

        if (todo?.id in conversationsHistory) {
            const items = conversationsHistory[todo.id];
            const subset = items.slice(items.length - 10, items.length);
            setMessages([...subset])
            setMessagesCount(conversationsHistory[todo.id].length)
        } else {
            dispatch(onGetConversationHistory(todo.id));
        }

    }, [showMessages, conversationsHistory])

    return <Fragment>

        {todo?.linkedConversationMessageCount > 0 && <div className="ps-4" >
            <div className="hstack gap-2 mb-2">
                <Link to="#!" className="text-info" onClick={() => setShowMessages(!showMessages)}>
                    <i className="mdi mdi-chat me-1" />
                    <span className="me-1">Messages ({messgesCount})</span>
                    <i className={showMessages ? "mdi mdi-arrow-up" : "mdi mdi-arrow-down"} />
                </Link>
            </div>
            {showMessages &&
                <div  >
                    <div className="p-2 rounded"   >
                        <PerfectScrollbar style={{ maxHeight: '400px' }} containerRef={el => (displayMessageContainerRef.current = el)}>
                            <ul className="list-unstyled">
                                {
                                    map(messages, item => {
                                        const isUser = item.role === "user";

                                        return <li className="mb-2"><div className="flex-grow-1 border" >
                                            <div className=" d-flex justify-content-between border-bottom p-2  bg-soft bg-primary">


                                                {isUser ? (<h5 className="font-size-13 mb-1">
                                                    {/* <i className="bx bx-bot font-size-14 me-1" /> */}
                                                    {"Vous"}
                                                </h5>) : (<h5 className="font-size-13 mb-1">
                                                    {/* <i className="bx bx-bot font-size-14 me-1" /> */}
                                                    {"Jask"}
                                                </h5>)}

                                                <small>{toLocalDate(item.createdAt)}</small>
                                            </div>
                                            <div className="p-2">
                                                {/* <ReactMarkdown>{onChatReply}</ReactMarkdown> */}
                                                {/* <p>{item.text}</p> */}
                                                <PreviewContent content={item.text} size={300} />
                                            </div>
                                        </div>
                                        </li>
                                    })
                                }
                            </ul>

                            {receiving && streamContent && <div className="flex-grow-1 border" >
                                <div className="border-bottom p-2  bg-soft bg-primary">
                                    <h5 className="font-size-13 mb-1">
                                        <i className="bx bx-bot font-size-14 me-1" />
                                        {"Jask"}
                                    </h5>
                                    <small>{toLocalDate(todo.updatedAt)}</small>
                                </div>
                                <div className="p-2">
                                    {/* <ReactMarkdown>{onChatReply}</ReactMarkdown> */}
                                    <p>{streamContent}</p>
                                </div>
                            </div>}
                            {receiving && !streamContent && <div className="flex-grow-1 border" >
                                <div className="flex-grow-1 border" >
                                    <div className="border-bottom p-2  bg-soft bg-primary">
                                        <h5 className="font-size-13 mb-1">
                                            <i className="bx bx-bot font-size-14 me-1" />
                                            {"Jask"}
                                        </h5>
                                        <small>{toLocalDate(new Date().toString())}</small>
                                    </div>
                                    <div className="p-2">
                                        <p className="card-text placeholder-glow">
                                            <span className="placeholder col-6"></span>
                                            <span className="placeholder col-8"></span>
                                            <span className="placeholder col-9"></span>
                                        </p>
                                    </div>
                                </div>
                            </div>}


                            <div className="p-2  bg-soft bg-">
                                {!showInput && <Link onClick={() => setShowInput(!showInput)}>Ecrire</Link>}
                                {showInput && <div className="hstack gap-2">
                                    <Input

                                        type="text"
                                        className="form-control"
                                        placeholder="Entre votre demande"
                                        value={input}
                                        onChange={e => setInput(e.target.value)}
                                        onKeyPress={submit}

                                    />
                                    <Link className="p-2 mt-2" onClick={() => setShowInput(!showInput)}>Annuler</Link>
                                </div>}
                            </div>
                            <div style={{ height: 50 }}></div>
                        </PerfectScrollbar>
                    </div>
                </div>
            }
        </div >}
    </Fragment>
});

export default TodoChatHistory