import { getTime } from "helpers/to-local-date";
import { map } from "lodash";
import React, { useEffect, useState } from "react";
import ReactMarkdown from 'react-markdown'
import { Link } from "react-router-dom";
import { getFileIcon } from "utils";
import { useSelector } from "react-redux";

const Question = ({ message }) => {

    return <div className="ctext-wrap">
        <div className="conversation-name border-bottom">
            <div className="d-flex justify-content-end">
                <small className="">{getTime(message.createdAt)}</small>
            </div>
        </div>
        <ReactMarkdown className="markdown">{message.text}</ReactMarkdown>

        <div className="border-bottom">
            {message.attachments && map(message.attachments, file => {

                return <div className="rounded bg-light bg-opacity-50 border border-info p-1 rounded-3 border-opacity-10 m-1">
                    <div className="hstack gap-1 ">
                        {getFileIcon(file)}
                        <Link><span className="font-size-10">{file.split("/").slice(-1)}</span></Link>
                    </div>
                </div>
            })}
        </div>
    </div>
}

export default Question;