import React, { useEffect, useState } from "react";
import { MsalProvider, useIsAuthenticated, useMsal } from '@azure/msal-react';
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { withTranslation } from "react-i18next";
import { getAccountSettings } from "service/users";
import { profileSuccess } from "store/actions";
import { PlatformRoles } from "constants/roles";
import { isEmpty } from "lodash";

const ProfileConfiguration = ({ role, children }) => {

    const navigate = useNavigate();
    const [validAccount, setValidAccount] = useState(false);
    const dispatch = useDispatch();
    const { account, permissions } = useSelector(state => ({
        account: state.Profile.account,
        permissions: state.Profile.permissions
    }));

    useEffect(() => {
        if (window.location.href.endsWith("/pages-403")) {
            setValidAccount(false);
            return;
        }
        if (account) {
            if (!isEmpty(role) && role in permissions && permissions[role] === true) {
                setValidAccount(true);
                return;
            } else {
                setValidAccount(false);
            }
        };

        getAccountSettings().then(resp => {
            const roles = resp.roles.map(role => role.id);
            const isGA = roles.includes(PlatformRoles.GLOBAL_ADMIN);


            if (!isEmpty(role) && !roles.includes(role)) {
                navigate("/pages-403");
            }

            dispatch(profileSuccess({
                permissions: {
                    workspace: roles.includes(PlatformRoles.WORKSPACE) || isGA,
                    studio: roles.includes(PlatformRoles.APPLICATIONS) || roles.includes(PlatformRoles.ASSISTANTS) || isGA,
                    application: roles.includes(PlatformRoles.APPLICATIONS) || isGA,
                    assistant: roles.includes(PlatformRoles.ASSISTANTS) || isGA,
                    automation: roles.includes(PlatformRoles.TEMPLATES) || roles.includes(PlatformRoles.SCENARIO) || isGA,
                    templates: roles.includes(PlatformRoles.TEMPLATES) || isGA,
                    scenario: roles.includes(PlatformRoles.SCENARIO) || isGA,
                    admin: roles.includes(PlatformRoles.USER_ADMIN) || isGA,
                    users: roles.includes(PlatformRoles.USER_ADMIN) || isGA,
                    groups: roles.includes(PlatformRoles.USER_ADMIN) || isGA,
                    usage: roles.includes(PlatformRoles.USAGE) || isGA,
                    deployment: roles.includes(PlatformRoles.DEPLOYMENT) || isGA,
                    datasources: roles.includes(PlatformRoles.DATASOURCE) || isGA,
                    support: roles.includes(PlatformRoles.ADDS_ON_SUPPORT) || isGA,
                },
                account: resp
            }));
        });

    }, [account, role]);

    return (
        <React.Fragment>
            {validAccount && children}
        </React.Fragment>
    );
};

export default withTranslation()(ProfileConfiguration);
