import React, { Fragment, useEffect, useState } from "react"
import { Col, Row } from "reactstrap";
import AppLineage from "./leftbar";
import AppExecution from "./content";


const CustomAppRunner = ({ app, todo }) => {
    const [selectedStep, setSelectedStep] = useState(null);
    const [board, setBoard] = useState([]);
    const [plan, setPlan] = useState([]);
    const [isRunning, setIsRunning] = useState(false);
    const [context, setContext] = useState("");

    const onStepStart = (id) => {

        const items = [...plan];

        items.forEach(s => {
            if (s.id === id) {
                s.isRunning = true;
                setSelectedStep(s);
            }
        });

        setPlan(items);

    }

    const onStepEnd = (id) => {
        const items = [...plan];

        items.forEach(s => {
            if (s.id === id)
                s.isRunning = false;
        });

        setPlan(items);
    }

    const startRunning = () => {
        setIsRunning(true);
        setSelectedStep(plan[0]);
    }

    useEffect(() => {
        setBoard(app?.board);
    }, [app])

    useEffect(() => {
        setContext(todo?.content)
    }, [todo])



    return <div className="custom-app-runner ">
        <Row>
            <Col lg={3} md={4}>
                <AppLineage board={board} selectedStep={selectedStep} onStepSelected={setSelectedStep} plan={plan} onNewPlan={setPlan} onRunClick={startRunning} isRunning={isRunning} />
            </Col>
            <Col lg={9} md={8}>
                <AppExecution boardOrApp={app} context={context} selectedStep={selectedStep} onStepStart={onStepStart} onStepEnd={onStepEnd} isRunning={isRunning} onFinish={setIsRunning} />
            </Col>
        </Row>

    </div>

}

export default CustomAppRunner;