import { isEmpty } from 'lodash';
import React, { Fragment, memo, ReactNode, useCallback, useEffect, useState } from 'react';
import Select from "react-select";

const tables = [
    {
        label: "Opportunités",
        value: "opportunity"
    },
    {
        label: "Comptes clients",
        value: "account"
    }
]


const CrmSearchNode = ({ data, onChange, onValidityChanged }) => {

    const [selectedTable, setSelectedTable] = useState(null);
    const onNewValue = (e) => {
        onChange({
            ...data,
            providerConfig: {
                table: e.value,
            },
            viewState: !isEmpty(e.value)
        });
        setSelectedTable(e);
    }


    useEffect(() => {
        if (data?.providerConfig?.table) {
            const option = tables.filter(t => t.value === data.providerConfig.table)[0];
            setSelectedTable(option);
        }
    }, [data])

    return <div className='nodrag'>

        <div className='mb-2 '>
            <Select className='select2-selection'
                value={selectedTable}
                options={tables}
                onChange={onNewValue}
            >
            </Select>
        </div>

    </div>
}

export default CrmSearchNode;