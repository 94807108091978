import React, { useEffect, useState, useRef } from "react";
import { withTranslation } from "react-i18next";
import { Card, CardBody, CardTitle } from "reactstrap";


const LoadingPlaceholder = () => {

    return <div className="d-flex flex-column placeholder-glow p-4">
        <Card>
            <CardBody>
                <span className="placeholder col-4"></span>
                <span className="placeholder col-12"></span>
                <span className="placeholder col-12"></span>
                <span className="placeholder col-12"></span>
                <span className="placeholder col-12"></span>

            </CardBody>
            <CardBody>
                <span className="placeholder col-4"></span>
                <span className="placeholder col-12"></span>
                <span className="placeholder col-12"></span>
                <span className="placeholder col-12"></span>
                <span className="placeholder col-12"></span>
            </CardBody>

            <CardBody>
                <span className="placeholder col-4"></span>
                <span className="placeholder col-12"></span>
                <span className="placeholder col-12"></span>
                <span className="placeholder col-12"></span>
                <span className="placeholder col-12"></span>

            </CardBody>

            <CardBody>
                <span className="placeholder col-4"></span>
                <span className="placeholder col-12"></span>
                <span className="placeholder col-12"></span>
                <span className="placeholder col-12"></span>
                <span className="placeholder col-12"></span>

            </CardBody>



        </Card>
    </div>
}

export default withTranslation()(LoadingPlaceholder);