export const GET_SUPPORT_REQUESTS = "GET_SUPPORT_REQUESTS"
export const GET_SUPPORT_REQUESTS_SUCCESS = "GET_SUPPORT_REQUESTS_SUCCESS"
export const GET_SUPPORT_REQUESTS_FAILED = "GET_SUPPORT_REQUESTS_FAILED"

export const UPDATE_SUPPORT_REQUESTS = "UPDATE_SUPPORT_REQUESTS"
export const UPDATE_SUPPORT_REQUESTS_SUCCESS = "UPDATE_SUPPORT_REQUESTS_SUCCESS"
export const UPDATE_SUPPORT_REQUESTS_FAILED = "UPDATE_SUPPORT_REQUESTS_FAILED"

export const GET_SUPPORT_PLATFORM = "GET_SUPPORT_PLATFORM"
export const GET_SUPPORT_PLATFORM_SUCCESS = "GET_SUPPORT_PLATFORM_SUCCESS"
export const GET_SUPPORT_PLATFORM_FAILED = "GET_SUPPORT_PLATFORM_FAILED"


export const ADD_SUPPORT_PLATFORM = "ADD_SUPPORT_PLATFORM"
export const ADD_SUPPORT_PLATFORM_SUCCESS = "ADD_SUPPORT_PLATFORM_SUCCESS"
export const ADD_SUPPORT_PLATFORM_FAILED = "ADD_SUPPORT_PLATFORM_FAILED"

export const DEL_SUPPORT_PLATFORM = "DEL_SUPPORT_PLATFORM"
export const DEL_SUPPORT_PLATFORM_SUCCESS = "DEL_SUPPORT_PLATFORM_SUCCESS"
export const DEL_SUPPORT_PLATFORM_FAILED = "DEL_SUPPORT_PLATFORM_FAILED"


export const GET_SUPPORT_PLATFORM_BOARD = "GET_SUPPORT_PLATFORM_BOARD"
export const GET_SUPPORT_PLATFORM_BOARD_SUCCESS = "GET_SUPPORT_PLATFORM_BOARD_SUCCESS"
export const GET_SUPPORT_PLATFORM_BOARD_FAILED = "GET_SUPPORT_PLATFORM_BOARD_FAILED"
