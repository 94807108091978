import SimpleTableContainer from "components/Common/SimpleTableContainer";
import { groupBy, isEmpty, map } from "lodash";
import React, { useEffect, useRef, useState } from "react"
import { Badge, Card, Modal, ModalBody, Nav, NavItem, NavLink, Spinner, TabContent, TabPane } from "reactstrap"
import { loadTabularFile, runTaskOverTable, saveTabularFile } from "service/documents";
import Select from 'react-select'
import TabularTaskLauncher from "./tasks";
import { useSelector, useDispatch } from "react-redux";
import { getFileIcon, uuidv4 } from "utils";
import { operationFailed, operationInfo } from "utils/notifications";
import { EventTypes } from "constants/events";
import { getModels } from "store/actions";
import { Link } from "react-router-dom";
import classnames from "classnames"
import ColumnSelection from "./ColumnSelection";
import AddChart from "./AddChart";
import { ChartTypes, ResourceTypes } from "constants/general";
import PieChart from "../charts/pieChart";
import SimpleBar from "simplebar-react";
import DeployAssistant from "pages/Workspace/assistant/deploy";

const Chart = ({ data, type, onClose, title }) => {
    switch (type) {

        case ChartTypes.PIE:
            return <PieChart
                labels={data?.labels}
                values={data?.values}
                onClose={onClose}
                title={title}
            />
    }
}

const TabularLauncher = ({ show, onClose, file }) => {

    const [loading, setLoading] = useState(false);
    const [table, setTable] = useState([]);
    const [columns, setColumns] = useState([]);
    const [columnToDisplay, setColumnsToDisplay] = useState([]);
    const [showTaskConfig, setShowTaskConfig] = useState(false);
    const [taskConfig, setTaskConfig] = useState(false);
    const [runningTask, setRunningTask] = useState(false);
    const [records, setRecords] = useState([]);
    const [taskProgression, setTaskProgression] = useState(null);
    const cancellation = useRef(new AbortController());
    const [availableModels, setAvailableModels] = useState([]);
    const [fullScreen, setFullScreen] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [showColumnsSelection, setShowColumnsSelection] = useState(false);
    const [showAddChart, setShowAddChart] = useState(false);
    const [selectedChartType, setSelectedChartType] = useState(null);
    const [activeTab, setactiveTab] = useState(1);
    const [charts, setCharts] = useState([]);
    const [showAddAssistant, setShowAddAssistant] = useState(false);


    const dispatch = useDispatch();

    const { accessToken, models, } = useSelector(state => ({
        models: state.Assistant.models,
        accessToken: state.Login.accessToken,
    }));

    const toggleFullScreen = () => {
        setFullScreen(!fullScreen);
    }
    const TaskResultViewer = ({ cell }) => {
        return <span>{cell.value}</span>
    }
    const onChartAdd = (type) => {
        setSelectedChartType(type);
        setShowAddChart(!showAddChart);
    }

    useEffect(() => {
        if (!show) {
            setLoaded(false);
            setTable([]);
        }
    }, [show]);


    useEffect(() => {
        if (isEmpty(file) || !show || loaded)
            return;

        setLoading(true);
        loadTabularFile(file?.location).then(resp => {
            const names = map(resp.columns || [], column => ({
                Header: column,
                accessor: column,
                label: column,
                value: column,
                disableFilters: true,
            })).filter(col => col.accessor !== '_id');

            setColumns(names);
            setTable(resp);
            setRecords(resp.records)
            setColumnsToDisplay(names.slice(0, 5));
            setLoaded(true);


        }).catch(err => {
            setLoaded(false);
        }).finally(() => {
            setLoading(false);
        })
    }, [file, show]);


    const onConfigChange = (config) => {
        setTaskConfig(config);
    }

    const saveFile = () => {

        const payload = {
            name: file.name,
            location: file.location,
            records: records,
        }

        saveTabularFile(payload).then(resp => {
            console.log(resp)
        })
    }

    const runTask = () => {
        setShowTaskConfig(false);
        setRunningTask(true);

        const existing = columns.find(col => col.accessor === taskConfig.outputCol);

        if (!existing) {
            const outputCol = {
                Header: taskConfig.outputCol,
                accessor: taskConfig.outputCol,
                label: taskConfig.outputCol,
                value: taskConfig.outputCol,
                isNew: true,
                disableFilters: true,
                Cell: cellProps => {
                    return <TaskResultViewer {...cellProps} />
                }
            }

            setColumns([...columns, outputCol]);
            setColumnsToDisplay([...columnToDisplay, outputCol]);
        }


        const task = {
            ...taskConfig,
            id: uuidv4(),
            data: table
        }

        runTaskOverTable(task, onTaskStart, onTaskStream, onTaskEnd, accessToken, cancellation?.current?.signal);
    }

    const onRemoveChart = (chartId) => {
        setCharts(charts.filter(chart => chart.id !== chartId));
    }

    const onTaskStart = () => {

    }

    const onTaskEnd = () => {

    }

    const onTaskStream = (event) => {
        // setTaskResult(prev => [...prev, event]);

        switch (event.type) {

            case EventTypes.STREAMING:
                setRecords(prev => updateRecord(prev, event.id, event.value, false))
                break;

            case EventTypes.PROGRESS:

                setTaskProgression(`${event.value}`)
                break;

            case EventTypes.STEP_START:
                setRecords(prev => updateRecord(prev, event.id, "", true))
                break

            case EventTypes.STEP_END:
                break;

            case EventTypes.END:
                setRunningTask(false);
                break;
        }
    }

    const updateRecord = (items, id, value, init = false) => {
        items.forEach(r => {
            if (r._id === id) {
                r[taskConfig.outputCol] = init ? value : `${r[taskConfig.outputCol] || ""}${value}`;
            }
        });

        return [...items];
    }

    const checkClose = () => {

        if (runningTask) {
            operationInfo("Operation en cours. Patientez svp")
        } else {
            onClose();
        }
    }

    const cancelTask = () => {
        cancellation.current.abort();
        cancellation.current = new AbortController();
        setRunningTask(false);
        operationFailed("Tache annulée.");
    }

    const onNewChart = (chart) => {

        switch (chart.type) {
            case ChartTypes.PIE:
                const selectedDimension = chart.dimensions[0];
                const dimRecords = records.filter(r => !isEmpty(r[selectedDimension]));
                let pieData = groupBy(dimRecords, record => record[selectedDimension])
                pieData = Object.keys(pieData).map(k => {
                    return {
                        name: k,
                        value: pieData[k].length
                    }
                });
                setCharts([...charts, {
                    id: uuidv4(),
                    labels: pieData.map(r => r.name),
                    values: pieData.map(r => r.value),
                    type: chart.type,
                    title: selectedDimension,
                }]);
                break;
            case ChartTypes.BAR:
                break;
            case ChartTypes.LINE:
                break;
        }
    }


    useEffect(() => {

        if (isEmpty(models)) {
            dispatch(getModels());
        }

    }, [dispatch]);


    useEffect(() => {

        setAvailableModels(models?.map(m => ({
            value: m.id,
            label: m.name,
            description: m.description
        })));

    }, [models])

    return <Modal
        isOpen={show}
        toggle={checkClose}
        contentClassName="tabular-launcher"
        size="xl"
        fade={false}
        fullscreen={fullScreen}

    >
        <TabularTaskLauncher
            show={showTaskConfig}
            onClose={() => setShowTaskConfig(false)}
            onConfigChange={onConfigChange}
            onStart={runTask}
            columns={columns}
            models={availableModels}
        />

        {showColumnsSelection && <ColumnSelection
            show={showColumnsSelection}
            onClose={() => setShowColumnsSelection(false)}
            options={columns}
            selection={columnToDisplay}
            onSelectionChanged={setColumnsToDisplay}
        />}

        {showAddChart && <AddChart
            show={showAddChart}
            onClose={() => setShowAddChart(false)}
            chartType={selectedChartType}
            table={table}
            dynamicColumns={columns.filter(c => c.isNew === true)}
            onConfirm={onNewChart}
        />}

        {showAddAssistant && <DeployAssistant
            show={showAddAssistant}
            onCloseClick={e => setShowAddAssistant(false)}
            provider={ResourceTypes.FILESYSTEM}
            knowledgeBase={[file]}
        />}

        <Card>

            <div className="pt-1 ps-1 bg-secondary bg-opacity-25">
                <div className="d-flex justify-content-between bg-secondary bg-opacity-25">
                    {/* <div>
                    <div className="hstack">{getFileIcon(file?.location, 18)}
                        <Badge color="info" className="">{file?.name}</Badge>
                    </div>
                </div> */}

                    <div>
                        <div className="">
                            <Nav tabs className="nav-tabs-custom ">
                                <NavItem>
                                    <NavLink
                                        onClick={() => {
                                            setactiveTab(1)
                                        }}
                                        className={classnames({ active: activeTab === 1 })}
                                    >
                                        <i className="bx bx-data font-size-16" /> Source de Données
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        onClick={() => {
                                            setactiveTab(2)
                                        }}
                                        className={classnames({ active: activeTab === 2 })}
                                    >
                                        <i className="bx bx-bot font-size-16" /> Analyse des données
                                    </NavLink>
                                </NavItem>



                                <NavItem>
                                    <NavLink
                                        onClick={() => {
                                            setactiveTab(5)
                                        }}
                                        className={classnames({ active: activeTab === 5 })}
                                    >
                                        <i className="bx bx-chart font-size-16" />  Visualisation
                                    </NavLink>
                                </NavItem>


                                <NavItem>
                                    <NavLink
                                        onClick={() => {
                                            setactiveTab(4)
                                        }}
                                        className={classnames({ active: activeTab === 4 })}
                                    >
                                        <i className="bx bx-share font-size-16" />  Partager
                                    </NavLink>
                                </NavItem>

                                <NavItem>
                                    <NavLink
                                        onClick={() => {
                                            setactiveTab(3)
                                        }}
                                        className={classnames({ active: activeTab === 3 })}
                                    >
                                        <i className="mdi mdi-display" />  Affichage
                                    </NavLink>
                                </NavItem>

                            </Nav>
                            <TabContent activeTab={activeTab} className="body p-2 toolbar">
                                <TabPane tabId={1}>

                                    <div className="hstack gap-2">

                                        <div onClick={() => setShowColumnsSelection(true)}
                                            className="d-flex flex-column align-items-center tool border border-secondary border-opacity-50 rounded p-1  "
                                        >
                                            <i className="bx bx-task font-size-18" />
                                            <span className="text-muted"> Selection des colonnes</span>
                                        </div>

                                        <div onClick={() => setShowTaskConfig(true)}
                                            className="d-flex flex-column align-items-center tool border border-secondary border-opacity-50 rounded p-1  "
                                        >
                                            <i className="bx bx-filter font-size-18" />
                                            <span className="text-muted"> Filtrer les données</span>
                                        </div>

                                    </div>
                                </TabPane>
                                <TabPane tabId={2}>
                                    <div className="hstack gap-2">

                                        <div onClick={() => setShowTaskConfig(true)}
                                            className="d-flex flex-column align-items-center tool border border-secondary border-opacity-50 rounded p-1  "
                                        >
                                            <i className="bx bx-task font-size-18" />
                                            <span className="text-muted"> Executer une tache</span>
                                        </div>

                                        <div onClick={saveFile}
                                            className="d-flex flex-column align-items-center tool border border-secondary border-opacity-50 rounded p-1  "
                                        >
                                            <i className="bx bx-save font-size-18" />
                                            <span className="text-muted"> Enregistrer les modifications</span>
                                        </div>



                                    </div>

                                </TabPane>

                                <TabPane tabId={4}>
                                    <div className="hstack gap-2">

                                        <div onClick={() => setShowTaskConfig(true)}
                                            className="d-flex flex-column align-items-center tool border border-secondary border-opacity-50 rounded p-1  "
                                        >
                                            <i className="bx bx-export font-size-18" />
                                            <span className="text-muted"> Télécharger les données</span>
                                        </div>

                                        <div onClick={() => setShowAddAssistant(true)}
                                            className="d-flex flex-column align-items-center tool border border-secondary border-opacity-50 rounded p-1  "
                                        >
                                            <i className="bx bx-bot font-size-18" />
                                            <span className="text-muted"> Créer un assistant </span>
                                        </div>

                                        <div onClick={() => setShowTaskConfig(true)}
                                            className="d-flex flex-column align-items-center tool border border-secondary border-opacity-50 rounded p-1  "
                                        >
                                            <i className="bx bx-data font-size-18" />
                                            <span className="text-muted"> Nouvelle source de données </span>
                                        </div>

                                    </div>
                                </TabPane>
                                <TabPane tabId={5}>
                                    <div className="hstack gap-2">

                                        <div onClick={() => onChartAdd(ChartTypes.PIE)}
                                            className="d-flex flex-column align-items-center tool border border-secondary border-opacity-50 rounded p-1  "
                                        >
                                            <i className="bx bx-pie-chart text-info font-size-18" />
                                            <span className="text-muted"> Créer un Pie Chart</span>
                                        </div>

                                        <div onClick={() => onChartAdd(ChartTypes.BAR)}
                                            className="d-flex flex-column align-items-center tool border border-secondary border-opacity-50 rounded p-1  "
                                        >
                                            <i className="bx  bx-bar-chart-square  text-info  font-size-18" />
                                            <span className="text-muted"> Créer un Bar Chart</span>
                                        </div>

                                        <div onClick={() => onChartAdd(ChartTypes.LINE)}
                                            className="d-flex flex-column align-items-center tool border border-secondary border-opacity-50 rounded p-1  "
                                        >
                                            <i className="bx  bx-line-chart  text-info font-size-18" />
                                            <span className="text-muted"> Créer un Line Chart</span>
                                        </div>

                                    </div>
                                </TabPane>

                            </TabContent>
                        </div>
                    </div>

                    <div>
                        <div className="hstack">
                            <Link onClick={toggleFullScreen} className="me-2">
                                <i className="bx bx-fullscreen font-size-24" />
                            </Link>
                        </div>
                    </div>
                </div>
            </div>


            <ModalBody>
                {loading && <div className="d-flex w-100 h-100 align-items-center justify-content-center">
                    <div className="">
                        <div className="vstack gap-2">
                            <Spinner size={"lg"} className="ms-4" />
                            <span>Chargement...</span>
                        </div>

                    </div>
                </div>}

                <Card>

                    <div className="d-flex flex-column  tabular">

                        <div>
                            {!loading && activeTab !== 5 && <SimpleTableContainer
                                columns={columnToDisplay}
                                data={records}
                                isGlobalFilter={false}
                                isAddOptions={false}
                                customPageSize={10}
                            />
                            }
                        </div>

                        <div>
                            {!loading && activeTab === 5 && <div>
                                <SimpleBar className="chartsView">
                                    <div className="d-flex flex-wrap">
                                        {map(charts, (item, index) => {
                                            return <div className="chartItem me-2 border border-secondary mb-2 border-opacity-25">
                                                <Chart key={index}
                                                    type={item.type}
                                                    data={item}
                                                    onClose={() => onRemoveChart(item.id)}
                                                    title={item.title}
                                                />
                                            </div>
                                        })}
                                    </div>

                                </SimpleBar>
                            </div>
                            }

                        </div>

                    </div>
                </Card>

            </ModalBody>

            <div className="pe-3 pb-4">

                <div className="d-flex justify-content-between">
                    <div className="ps-4">
                        {loaded && <Badge className="fw-bold">{records?.length} Lignes </Badge>
                        }                </div>
                    <div>
                        {runningTask && <div className="hstack gap-1">
                            <Spinner size={"sm"} />
                            <small>Traitement encours...</small>
                            {!isEmpty(taskProgression) && `(${taskProgression})`}
                        </div>}
                    </div>

                    <div>
                        {/* {!runningTask && <button className="btn btn-success me-2" onClick={checkClose} disabled={runningTask}>Télécharger</button>
                        }                    */}
                        {runningTask && <button className="btn btn-danger" onClick={cancelTask}  >Annuler</button>}
                        {!runningTask && <button className="btn btn-danger" onClick={onClose}  >Fermer</button>}
                    </div>
                </div>

            </div>
        </Card >

    </Modal >
}

export default TabularLauncher;