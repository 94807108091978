import { call, put, takeEvery } from "redux-saga/effects"

import { GET_DATASOURCE_LIST, ADD_NEW_DATASOURCE_LIST, UPDATE_DATASOURCE_LIST, DELETE_DATASOURCE_LIST, GET_APPLY_DATASOURCE, DELETE_APPLY_DATASOURCE, GET_DATASOURCE_DOMAINS_LIST } from "./actionTypes";
import {
    getDatasourceListSuccess,
    getDatasourceListFail,
    addDatasourceListSuccess,
    addDatasourceListFail,
    updateDatasourceListSuccess,
    updateDatasourceListFail,
    deleteDatasourceListSuccess,
    deleteDatasourceListFail,
    getApplyDatasourceSuccess,
    getApplyDatasourceFail,
    deleteApplyDatasourceSuccess,
    deleteApplyDatasourceFail,
    getDatasourceDomainListSuccess,
    getDatasourceDomainListError

} from "./actions"

import {
    getDatasourceList,
    getDatasourceDomainsList,
    addNewDatasourceList,
    updateDatasourceList,
    deleteDatasourceList,
    getApplyDatasource,
    deleteApplyDatasource
} from "helpers/backend_helper";

function* fetchDatasourcesList() {
    try {
        const response = yield call(getDatasourceList)
        yield put(getDatasourceListSuccess(response))
    } catch (error) {
        yield put(getDatasourceListFail(error))
    }
}

function* fetchDatasourcesDomainsList() {
    try {
        const response = yield call(getDatasourceDomainsList)
        yield put(getDatasourceDomainListSuccess(response))
    } catch (error) {
        yield put(getDatasourceDomainListError(error))
    }
}

function* onAddNewDatasourceList({ payload: data }) {
    try {
        const response = yield call(addNewDatasourceList, data)
        yield put(addDatasourceListSuccess(response))
    } catch (error) {
        yield put(addDatasourceListFail(error))
    }
}

function* onUpdateDatasourceList({ payload: data }) {
    try {
        const response = yield call(updateDatasourceList, data)

        yield put(updateDatasourceListSuccess(response))
    } catch (error) {
        yield put(updateDatasourceListFail(error))
    }
}

function* onDeleteDatasourceList({ payload: data }) {

    try {
        const response = yield call(deleteDatasourceList, data)
        yield put(deleteDatasourceListSuccess(response))
    } catch (error) {
        yield put(deleteDatasourceListFail(error))
    }
}

function* OnGetApplyDatasource() {
    try {
        const response = yield call(getApplyDatasource)
        yield put(getApplyDatasourceSuccess(response))
    } catch (error) {
        yield put(getApplyDatasourceFail(error))
    }
}

function* OnDeleteApplyDatasource({ payload: data }) {
    try {
        const response = yield call(deleteApplyDatasource, data)
        yield put(deleteApplyDatasourceSuccess(response))
    } catch (error) {
        yield put(deleteApplyDatasourceFail(error))
    }
}

function* DatasourcesSaga() {
    yield takeEvery(GET_DATASOURCE_LIST, fetchDatasourcesList)
    yield takeEvery(GET_DATASOURCE_DOMAINS_LIST, fetchDatasourcesDomainsList)
    yield takeEvery(ADD_NEW_DATASOURCE_LIST, onAddNewDatasourceList)
    yield takeEvery(UPDATE_DATASOURCE_LIST, onUpdateDatasourceList)
    yield takeEvery(DELETE_DATASOURCE_LIST, onDeleteDatasourceList)
    yield takeEvery(GET_APPLY_DATASOURCE, OnGetApplyDatasource)
    yield takeEvery(DELETE_APPLY_DATASOURCE, OnDeleteApplyDatasource)
}

export default DatasourcesSaga;