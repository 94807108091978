import { ADD_DEPLOYMENT, ADD_DEPLOYMENT_FAILED, ADD_DEPLOYMENT_KEY, ADD_DEPLOYMENT_KEY_FAILED, ADD_DEPLOYMENT_KEY_SUCCESS, ADD_DEPLOYMENT_SUCCESS, DEL_DEPLOYMENT, DEL_DEPLOYMENT_FAILED, DEL_DEPLOYMENT_KEY, DEL_DEPLOYMENT_KEY_FAILED, DEL_DEPLOYMENT_KEY_SUCCESS, DEL_DEPLOYMENT_SUCCESS, GET_DEPLOYMENTS, GET_DEPLOYMENTS_FAILED, GET_DEPLOYMENTS_SUCCESS } from "./actionTypes"

export const getDeployments = (payload) => ({
  type: GET_DEPLOYMENTS,
  payload: payload
})

export const getDeploymentsSuccess = (payload) => ({
  type: GET_DEPLOYMENTS_SUCCESS,
  payload: payload
})

export const getDeploymentsFailed = (error) => ({
  type: GET_DEPLOYMENTS_FAILED,
  payload: error
})


export const addDeployment = (payload) => ({
  type: ADD_DEPLOYMENT,
  payload: payload
})


export const addDeploymentSuccess = (payload) => ({
  type: ADD_DEPLOYMENT_SUCCESS,
  payload: payload
})

export const addDeploymentFailed = (error) => ({
  type: ADD_DEPLOYMENT_FAILED,
  payload: error
})



export const delDeployment = (payload) => ({
  type: DEL_DEPLOYMENT,
  payload: payload
})

export const delDeploymentSuccess = (payload) => ({
  type: DEL_DEPLOYMENT_SUCCESS,
  payload: payload
})

export const delDeploymentFailed = (payload) => ({
  type: DEL_DEPLOYMENT_FAILED,
  payload: payload
})




export const addDeploymentKey = (payload) => ({
  type: ADD_DEPLOYMENT_KEY,
  payload: payload
})

export const addDeploymentKeySuccess = (payload) => ({
  type: ADD_DEPLOYMENT_KEY_SUCCESS,
  payload: payload
})

export const addDeploymentKeyFailed = (payload) => ({
  type: ADD_DEPLOYMENT_KEY_FAILED,
  payload: payload
})


export const delDeploymentKey = (payload) => ({
  type: DEL_DEPLOYMENT_KEY,
  payload: payload
})

export const delDeploymentKeySuccess = (payload) => ({
  type: DEL_DEPLOYMENT_KEY_SUCCESS,
  payload: payload
})

export const delDeploymentKeyFailed = (payload) => ({
  type: DEL_DEPLOYMENT_KEY_FAILED,
  payload: payload
})