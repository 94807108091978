import React from "react";
import { useMsal } from '@azure/msal-react';
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import Login from "pages/Authentication/Login";
import ProfileConfiguration from "pages/Admin/security/configuration"

const AzureAuthmiddleware = (props) => {

    return (<div>
        <AuthenticatedTemplate
        >
            <ProfileConfiguration role={props.role}>
                {props.children}
            </ProfileConfiguration>
        </AuthenticatedTemplate>

        <UnauthenticatedTemplate>
            <Login />
        </UnauthenticatedTemplate>

    </div>);

};

export default AzureAuthmiddleware;