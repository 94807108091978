import React, { useState, useEffect } from "react"
import { Container, Row, Col, Card, CardBody, DropdownItem } from "reactstrap"
import { MentionsInput, Mention } from 'react-mentions'
import { useSelector, useDispatch } from "react-redux";
import {
    getDataServices, getStudioApps,
} from "store/actions";
import { isEmpty } from "lodash";
import { getFileIcon, getServiceInfo } from "utils";
import { AppIdentityCard } from "components/Common/CustomSelect";
import { ResourceTypes } from "constants/general";
import mentionStyle from "./styles/mentions"
import mentionInputStyle from "./styles/input"
import { layoutModeTypes } from "constants/layout";
import { Link } from "react-router-dom";
import SimpleBar from "simplebar-react";
const AssistantInputChat = ({ onValueChange, onSubmit, readOnly, externalValue, files, references, onCancel, isRunning, isExtendedInput, placeholder }) => {

    const [suggestionsItems, setSuggestionItems] = useState([]);
    const [value, setValue] = useState('');
    const [plainText, setPlainText] = useState();
    const [mentions, setMentions] = useState([]);
    const [assistantSuggestions, setAssistantSuggestions] = useState([]);
    const [filesSuggestions, setFilesSuggestions] = useState([]);

    const dispatch = useDispatch();

    const { theme } = useSelector(state => ({
        theme: state.Layout.layoutModeType,
    }));


    const { isWorking, dataServices, assistants } = useSelector(state => ({

        isWorking: state.Assistant.isWorking,
        dataServices: state.Assistant.dataServices,
        assistants: state.Studio.assistants
    }));


    const onChange = (evt, newValue, plaintTextValue, mentions) => {
        setValue(newValue);
        setMentions(mentions);
        setPlainText(plaintTextValue);

        onValueChange({
            value: plaintTextValue,
            mentions: mentions
        });
    }


    const onKeyPress = (e) => {

        const { key } = e;

        if (key === "Enter" && !e.shiftKey) {

            e.preventDefault();

            let request = plainText;
            const sources = [];
            const apps = [];
            const files = [];

            mentions.forEach(m => {
                request = request.replace(m.display, "");
                const item = suggestionsItems.find(i => i.id === m.id);
                switch (item.type) {

                    case ResourceTypes.DATASOURCES:
                        sources.push(item);
                        break;
                    case ResourceTypes.ASSISTANTS:
                        apps.push(item);
                        break;
                    case ResourceTypes.FILE:
                        files.push(item)
                        break;
                }
            });


            onSubmit(request, sources, apps, files);
            setValue("");
        }

    }


    const defaultRenderSuggestionItem = (theme) => {
        return function RenderItem(suggestion) {
            const itemContent = suggestion.display;

            return (
                <li
                    className={theme === layoutModeTypes.DARK ? "mension-item " : "mension-item bg-info bg-opacity-25 text-dark"}
                    data-testid={`menu-item-${itemContent}`}
                >
                    {suggestion.logo ? <AppIdentityCard name={itemContent} logo={suggestion.logo} description={suggestion.description} /> :
                        <div className="hstack gap-1">
                            {suggestion.type === ResourceTypes.DATASOURCES ? <i className={getServiceInfo(suggestion.id).icon} /> : getFileIcon(suggestion.display)}
                            <span className="text-dark">  {itemContent}</span>
                        </div>}

                </li>
            )
        };
    };

    useEffect(() => {
        if (isEmpty(suggestionsItems)) {
            dispatch(getDataServices())
        }
        if (isEmpty(assistants)) {
            dispatch(getStudioApps())
        }
    }, []);

    useEffect(() => {
        const providers = dataServices.map(s => ({ ...s, id: s.id, display: s.name, isDataProvider: true, type: ResourceTypes.DATASOURCES, providerType: s.type }));
        const applications = assistants.map(s => ({ id: s.id, display: s.name, isApp: true, logo: s.logoUrl, description: s.description, type: ResourceTypes.ASSISTANTS }));
        const attachments = (files || []).map(f => ({ id: f.location, display: f.name, type: ResourceTypes.FILE }))
        const chatReferences = (references || []).map(f => ({ id: f.id, display: f.name, type: ResourceTypes.FILE, location: f.location, providerId: f.providerId, providerType: f.providerType }))

        // setAssistantSuggestions(applications);
        // setFilesSuggestions([...attachments, ...chatReferences]);
        setSuggestionItems([...providers, ...applications, ...attachments, ...chatReferences]);

    }, [dataServices, assistants, files, references]);


    useEffect(() => {
        setValue(`${externalValue || ""}`);
        setPlainText(externalValue || "");

        onValueChange({
            value: externalValue,
            mentions: []
        });

    }, [externalValue])

    return <div className="custom-input-chat d-flex d-flex justify-content-between" >
        <div className="w-100">
            <SimpleBar style={isExtendedInput ? { height: '100px' } : {}}>
                <MentionsInput
                    className="mentions "
                    // style={mentionInputStyle}
                    value={value}
                    singleLine={false}
                    onChange={onChange}
                    allowSuggestionsAboveCursor={true}
                    forceSuggestionsAboveCursor={true}
                    placeholder={placeholder || "Just ask..."}
                    onKeyPress={onKeyPress}
                    readOnly={readOnly}
                >
                    <Mention
                        trigger="@"
                        data={suggestionsItems}
                        displayTransform={(id, display) => display}
                        // style={{ backgroundColor: "#0000", color: 'red' }}
                        renderSuggestion={defaultRenderSuggestionItem(theme)}
                        appendSpaceOnAdd={true}
                    />

                    {/* <Mention
                        trigger="@file"
                        data={filesSuggestions}
                        displayTransform={(id, display) => {
                            return display
                        }}
                        // style={{ backgroundColor: "#0000", color: 'red' }}
                        renderSuggestion={defaultRenderSuggestionItem(theme)}
                    /> */}




                </MentionsInput>
            </SimpleBar>
        </div>

        <div className="">
            {isRunning && <Link to={"#!"} className="pb-2" onClick={onCancel}>
                <i className="mdi mdi-stop-circle text-danger font-size-24 " />
            </Link>}
        </div>
    </div>
}

export default AssistantInputChat
