import React, { useEffect, useState } from "react";
import {
    Badge,
    Card,
    CardBody,
    Col,
    Container,
    Row,
    Spinner,
    Table
} from "reactstrap";
import { withTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { isEmpty, map, truncate } from "lodash";
import { getSupportPlatformBoard, updateSupportRequestsSuccess } from "store/actions";
import SimpleBar from "simplebar-react"
import { Link } from "react-router-dom";
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import reHype from 'remark-rehype'
import { toLocalDate } from "helpers/to-local-date";
import { postSupportRequestReply, resolveSupportRequest } from 'service/addons/support';
import { operationCompleted } from 'utils/notifications';


const AssistantReplyCard = ({ item, assistants, t }) => {
    const [show, setShow] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [content, setContent] = useState(false);
    const assistant = assistants.find(a => a.id === item.assistantId)
    const [isPosting, setIsPosting] = useState(null);
    const dispatch = useDispatch();

    const onPostReply = (selectedRequest, assistant) => {
        setIsPosting(true)

        postSupportRequestReply({
            request: selectedRequest,
            reply: content,
            agentName: assistant?.name,
        }).then(resp => {
            dispatch(updateSupportRequestsSuccess(resp));
            operationCompleted(t("Réponse postée avec succès!"));
        }).finally(() => {
            setIsPosting(false);
        })
    }

    useEffect(() => {
        setContent(item?.assistantReply);
    }, [item])


    return <tr>
        <td>
            <div className="d-flex justify-content-between pb-2 ">
                <div>
                    <h5 className="text-truncate font-size-14 mb-1">
                        <div className="hstack">
                            <i className="bx bxs-right-arrow-circle font-size-16 bx-fade-right me-2 text-primary" />
                            <Link to="#" className="text-dark">
                                {item.summary}
                            </Link>
                        </div>

                    </h5>
                </div>
                <div>
                    <span className="font-size-10" color="secondary">{toLocalDate(item.createdAt)}</span>
                </div>

            </div>

            <p className="text-muted mb-0 text-wrap ms-4 ">{item.description}</p>
            <div>
                <Link onClick={() => setShow(!show)} className="ms-4">

                    <i className="mdi mdi-chat" />{("Voir la réponse")}{show ? <i className="mdi mdi-chevron-down" /> : <i className="mdi mdi-chevron-right" />}

                </Link>
                {show && <div className="bg-secondary bg-opacity-25 p-3 mt-2">
                    <div className="d-flex justify-content-between">
                        <div>
                            <div className="d-flex flex ">
                                <img className=" bg-info bg-opacity-25 rounded-circle avatar-xs " src={assistant.logoUrl} />
                                <Link className="text-muted font-size-12 ms-2 mt-2 ">{truncate(assistant.name, { length: 30 })}</Link>
                            </div>
                        </div>

                        <div>
                            <Badge color="info">{item.status}</Badge>
                        </div>
                    </div>

                    <div className="border-top border-secondary border-opacity-25 mt-1 pt-1">
                        {!isEdit ? <SimpleBar style={{ height: '300px' }}>
                            <ReactMarkdown className="markdown text-wrap" remarkPlugins={[remarkGfm]} rehypePlugins={[reHype]}>{content}</ReactMarkdown>
                        </SimpleBar> : <textarea
                            className="form-control"
                            value={content}
                            onChange={e => setContent(e.target.value)}

                            rows={10}
                        />}
                    </div>

                    <div className="hstack gap-1 border-top border-secondary border-opacity-25 mt-2 pt-1">
                        <button disabled={isPosting || isEdit} onClick={() => onPostReply(item, assistant)} className="btn btn-success">{t("Poster")}</button>
                        <button disabled={isPosting} onClick={() => setIsEdit(!isEdit)} className="btn btn-info">{t(isEdit ? "Terminer" : "Editer")}</button>
                        {isPosting && <div className='vr' />}
                        {isPosting && <Spinner className='sm' />}

                    </div>
                </div>}
            </div>
        </td>
    </tr>
}

const PendingSupportRequest = ({ t, board, assistants, waitingForValidation }) => {

    return (
        <React.Fragment>
            <Card className="board-card border border-secondary border-opacity-25">
                <CardBody className="p-0">
                    <div className="d-flex justify-content-between bg- bg-opacity-25 border-bottom border-secondary border-opacity-25">
                        <div className="pb-1 ps-4 pt-2">
                            <span className="card-title mb"><i className="bx bx-support font-size-16" /> {t("En attente de validation")} ({waitingForValidation.length})</span>
                            <p className="text-muted font-size-12">{t("Réponses d'assistants en attente de validation")}</p>
                        </div>
                        <div className="p-2">
                            <Link
                                to="/addons/support/requests"
                                className="btn btn-primary waves-effect waves-light btn-sm"
                            >
                                {t("Tout voir")} <i className="mdi mdi-arrow-right ms-1"></i>
                            </Link>
                        </div>

                    </div>
                    <SimpleBar className="scroller">
                        <div className="table-responsive p-3">
                            <Table className="table table-nowrap align-middle table-hover mb-0">
                                <tbody>
                                    {map(waitingForValidation || [], item => {

                                        return <AssistantReplyCard item={item} assistants={assistants} t={t} />
                                    })}

                                </tbody>
                            </Table>
                        </div>
                    </SimpleBar>

                </CardBody>
            </Card>

        </React.Fragment>
    );
};


export default withTranslation()(PendingSupportRequest);
