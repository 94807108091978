import React, { createRef, useEffect, useState } from "react";
import Select, { components } from 'react-select'
import { Card, Label } from "reactstrap";
import { LaunchType } from "../constants";


const StartFileTask = ({ onSelectedLaunchTask }) => {


    return <Card>
        <div className="d-flex align-items-center flex-column justify-content-center tasks-files-starter">
            <div className="mb-2">
                <h5 className="fw-bold"><i className="mdi mdi-term" />C'est parti !</h5>
            </div>

            <div>
                <button onClick={() => onSelectedLaunchTask(LaunchType.IMMEDIAT)} className="btn btn-info starter-button me-2">Lancer Immediatement</button>
                <button onClick={() => onSelectedLaunchTask(LaunchType.BACKGROUND)} className="btn btn-secondary starter-button " disabled={true}>Me prevenir quand c'est terminé</button>
            </div>

        </div>
    </Card>
}

export default StartFileTask;