import { call, put, takeEvery, takeLatest } from "redux-saga/effects"
import { CREATE_PROMPT, DELETE_PROMPT, GET_PROMPTS } from "./actionTypes"

import {
  getPromptsFromStore,
  addPromptToStore,
  delPromptFromStore,
} from "helpers/backend_helper"

import { createPromptFailed, createPromptSuccess, deletePromptFailed, deletePromptSuccess, getPromptStoreFailed, getPromptStoreSuccess } from "./actions"

function* loadPrompts(action) {
  try {
    const response = yield call(getPromptsFromStore, new URLSearchParams([...Object.entries(action.payload)]).toString())
    yield put(getPromptStoreSuccess(response))
  } catch (error) {
    yield put(getPromptStoreFailed(error))
  }
}


function* addPrompt(action) {
  try {
    const response = yield call(addPromptToStore, action.payload)
    yield put(createPromptSuccess(response))
  } catch (error) {
    yield put(createPromptFailed(error))
  }
}

function* delPrompt(action) {
  try {
    const response = yield call(delPromptFromStore, action.payload)
    yield put(deletePromptSuccess(response))
  } catch (error) {
    yield put(deletePromptFailed(error))
  }
}


function* PromptStoreSaga() {
  yield takeLatest(GET_PROMPTS, loadPrompts)
  yield takeEvery(CREATE_PROMPT, addPrompt)
  yield takeEvery(DELETE_PROMPT, delPrompt)
}

export default PromptStoreSaga;