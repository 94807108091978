import { isEmpty, map } from "lodash"
import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import SimpleBar from "simplebar-react"
import classNames from "classnames";
import Select from "react-select";

import {
    Card,
    CardBody,
    Col,
    Collapse,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Input,
    Label,
    Row,
    UncontrolledAlert,
    UncontrolledDropdown,
} from "reactstrap"
import { uuidv4 } from "utils";


const expertises = [
    {
        label: "Domaines",
        options: [
            {
                value: "default",
                label: "Par défaut"
            }, {
                value: "office",
                label: "Assistant Réunion"
            },
            {
                value: "legal",
                label: "Expert Juridique"
            },
            {
                value: "insurance",
                label: "Expert en Assurance"
            }
            ,
            {
                value: "rh",
                label: "Expert RH"
            }
            ,
            {
                value: "webMaster",
                label: "Web Master"
            }
            ,
            {
                value: "sales",
                label: "Expert Commercial "
            }
            ,
            {
                value: "others",
                label: "Autre"
            }]
    }

]


const tones = [

    {
        value: "professional",
        label: "Proféssionnel"
    },

    {
        value: "friendly",
        label: "Amical"
    },
    {
        value: "neutral",
        label: "Neutre"
    }
]

const languages = [
    {
        value: "fr-FR",
        label: "Français"
    },

    {
        value: "en-US",
        label: "Anglais"
    },
    {
        value: "it-IT",
        label: "italian"
    },
    {
        value: "es-ES",
        label: "Espagnol"
    }
]


const creativities = [
    {
        value: "low",
        label: "Faible"
    },

    {
        value: "medium",
        label: "Modérée"
    },
    {
        value: "high",
        label: "Elevée"
    },
    {
        value: "max",
        label: "Très élévée"
    }
]

const EditorAssistant = ({ onConfigChange, isLanding }) => {
    const [openRules, setOpenRules] = useState(false);
    const [openSettings, setOpenSettings] = useState(false);
    const [openSeo, setOpenSeo] = useState(false);
    const [openSources, setOpenSources] = useState(false);
    const [openFormat, setOpenFormat] = useState(false);

    const [instructions, setInstructions] = useState([]);
    const [expertise, setExpertise] = useState(expertises[0].options[0]);
    const [tone, setTone] = useState(tones[0]);
    const [language, setLanguage] = useState(languages[0]);
    const [creativity, setCreativity] = useState(creativities[1]);
    const [paragraphesCount, setParagraphesCount] = useState(3);
    const [charsCount, setCharsCount] = useState(500);


    useEffect(() => {

        onConfigChange({
            tone: tone.value,
            expertise: expertise.value,
            creativity: creativity.value,
            language: language.value,
            paragraphesCount: paragraphesCount,
            charsCount: charsCount,
            instructions: instructions.filter(i => i.activate)
        })

    }, [tone, expertise, creativity, language, instructions, paragraphesCount, charsCount])


    const addRules = () => {
        setInstructions([...instructions, {
            id: uuidv4(),
            text: "",
            activate: true
        }]);
    }

    const removeRules = (item) => {
        setInstructions(instructions.filter(i => i.id !== item.id));
    }

    const updateRule = (item, e) => {
        item.text = e.target.value;
        setInstructions([...instructions])
    }

    const updateRuleState = (item, e) => {
        item.activate = e.target.checked;
        setInstructions([...instructions])
    }

    return (
        <div>
            <Card>
                <CardBody>
                    <div className="editor-assistant-sidebar">
                        <SimpleBar className="editor-assistant-sidebar-items">

                            <div className="accordion accordion-flush" id="accordion">
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingOne">
                                        <button
                                            className={classNames(
                                                "accordion-button",
                                                "fw-medium",
                                                { collapsed: !openSettings }
                                            )}
                                            type="button"
                                            onClick={() => setOpenSettings(!openSettings)}
                                            style={{ cursor: "pointer" }}
                                        >
                                            <div className="hstack gap-1">
                                                <i className="mdi mdi-robot-excited font-size-20 text-info" />
                                                <span className="mt-1">Mon assistant</span>
                                            </div>
                                        </button>
                                    </h2>
                                    <Collapse isOpen={openSettings} className="accordion-collapse">
                                        <form>
                                            <Row>
                                                <Col md={12}>
                                                    <div className="vstack gap-2 mt-2 mb-2 ms-2 me-2">
                                                        <div className="mb-2">
                                                            <Label>Expertise:</Label>
                                                            <Select
                                                                value={expertise}
                                                                options={expertises} defaultValue={expertises[0]} className='select2-selection' onChange={e => setExpertise(e)} />
                                                        </div>

                                                        <div className="mb-2">
                                                            <Label>Ton:</Label>
                                                            <Select
                                                                value={tone}
                                                                options={tones} defaultValue={tones[0]} className='select2-selection' onChange={e => setTone(e)} />
                                                        </div>

                                                        <div className="mb-2">
                                                            <Label>Langage:</Label>
                                                            <Select
                                                                value={language}
                                                                options={languages} defaultValue={languages[0]} className='select2-selection' onChange={e => setLanguage(e)} />
                                                        </div>

                                                        <div className="mb-2">
                                                            <Label>Créativité:</Label>
                                                            <Select
                                                                value={creativity}
                                                                options={creativities} defaultValue={creativities[0]} className='select2-selection' onChange={e => setCreativity(e)} />
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </form>
                                    </Collapse>

                                </div>
                            </div>

                            <div className="accordion accordion-flush" id="accordion">
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingOne">
                                        <button
                                            className={classNames(
                                                "accordion-button",
                                                "fw-medium",
                                                { collapsed: !openFormat }
                                            )}
                                            type="button"
                                            onClick={() => setOpenFormat(!openFormat)}
                                            style={{ cursor: "pointer" }}
                                        >
                                            <div className="hstack gap-1">
                                                <i className="mdi mdi-notebook font-size-20 text-info" />
                                                <span className="mt-1">Format du Contenu</span>
                                            </div>
                                        </button>
                                    </h2>
                                    <Collapse isOpen={openFormat} className="accordion-collapse">
                                        <form>
                                            <Row>
                                                <Col md={12}>
                                                    <div className="vstack gap-2 mt-2 mb-2 ms-2 me-2">
                                                        <div className="mb-2">
                                                            <Label>Nb. de paragraphes:</Label>
                                                            <Input
                                                                type="number"
                                                                className="form-control"
                                                                value={paragraphesCount}
                                                                onChange={e => setParagraphesCount(e.target.value)}

                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="vstack gap-2 mt-2 mb-2 ms-2 me-2">
                                                        <div className="mb-2">
                                                            <Label>Nb. de charactères max:</Label>
                                                            <Input
                                                                type="number"
                                                                className="form-control"
                                                                value={charsCount}
                                                                onChange={e => setCharsCount(e.target.value)}
                                                            />
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </form>
                                    </Collapse>

                                </div>
                            </div>

                            <div className="accordion accordion-flush mt-2" id="accordion">
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingOne">
                                        <button
                                            className={classNames(
                                                "accordion-button",
                                                "fw-medium",
                                                { collapsed: !openRules }
                                            )}
                                            type="button"
                                            onClick={() => setOpenRules(!openRules)}
                                            style={{ cursor: "pointer" }}
                                        >
                                            <div className="hstack gap-1">
                                                <i className="mdi mdi-clipboard-list font-size-20 text-danger" />
                                                Règles de rédaction
                                            </div>
                                        </button>
                                    </h2>
                                    <Collapse isOpen={openRules} className="accordion-collapse">

                                        {instructions.length == 0 && <p className="p-2 text-center">Aucune règle spécifiée</p>}
                                        <ul className="list-unstyled">

                                            {map(instructions, item => {

                                                return <li key={item.id}>
                                                    <div className="hstack gap-2 mt-2">
                                                        <input
                                                            type="checkbox"
                                                            value={item.activate}
                                                            className=""
                                                            checked={item.activate}
                                                            onChange={e => updateRuleState(item, e)}
                                                            disabled={isEmpty(item.text)}
                                                        />
                                                        <textarea
                                                            className="form-control"
                                                            rows={1}
                                                            onChange={e => updateRule(item, e)}
                                                            value={item.text}
                                                        />
                                                        <Link className="" onClick={() => removeRules(item)}>
                                                            <i className="mdi mdi-close text-danger me-1"></i>
                                                        </Link>
                                                    </div>
                                                </li>
                                            })}
                                        </ul>
                                        <div className="d-flex justify-content-center">
                                            <div className=" hstack gap-2 p-2 ">

                                                <Link className="" onClick={addRules}>
                                                    <i className="mdi mdi-plus me-1"></i> Nouvelle règle
                                                </Link>
                                                <Link className="">
                                                    <i className="mdi mdi-import me-1"></i> Importer
                                                </Link>
                                            </div>
                                        </div>
                                    </Collapse>
                                </div>
                            </div>
                            <div className="accordion accordion-flush mt-2" id="accordion">
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingOne">
                                        <button
                                            className={classNames(
                                                "accordion-button",
                                                "fw-medium",
                                                { collapsed: !openSources }
                                            )}
                                            type="button"
                                            onClick={() => setOpenSources(!openSources)}
                                            style={{ cursor: "pointer" }}
                                        >
                                            <div className="hstack gap-1">
                                                <i className="mdi mdi-database  font-size-20" />
                                                Sources de données
                                            </div>
                                        </button>
                                    </h2>
                                    <Collapse isOpen={openSources} className="accordion-collapse">
                                    </Collapse>

                                </div>
                            </div>

                            <div className="accordion accordion-flush mt-2" id="accordion">
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingOne">
                                        <button
                                            className={classNames(
                                                "accordion-button",
                                                "fw-medium",
                                                { collapsed: !openSeo }
                                            )}
                                            type="button"
                                            onClick={() => setOpenSeo(!openSeo)}
                                            style={{ cursor: "pointer" }}
                                        >
                                            <div className="hstack gap-2">
                                                <i className="mdi mdi-scoreboard-outline  font-size-20" />
                                                Score SEO
                                            </div>
                                        </button>
                                    </h2>
                                    <Collapse isOpen={openSeo} className="accordion-collapse">
                                    </Collapse>

                                </div>
                            </div>

                        </SimpleBar>
                        <div className="mb-4 mt-4">
                            <hr />
                            <div className="d-flex flex-column align-items-center justify-content-center">
                                <div>
                                    <h6 className="text-muted">
                                        <i className="fa fa-database me-2" />
                                        Génération de contenu</h6>
                                </div>
                                <div className="d-flex flex-row-reverse mt-2">
                                    {<button className="btn btn-success font-size-14" disabled={isLanding}>
                                        Démarrer
                                    </button>}
                                </div>

                            </div>
                        </div>

                    </div>
                </CardBody>
            </Card>
        </div>
    )
}

export default EditorAssistant;
