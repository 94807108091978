import React, { memo, useEffect, useRef, useState } from "react";
import PdfViewer from "components/DocumentViewer/pdfViewer";
import { Card, CardBody, Modal, ModalBody, Spinner } from "reactstrap";
import { getFileIcon, truncateFromMiddle } from "utils";
import { useIsVisible } from "hooks/visibility";
import { isEmpty } from "lodash";
import ResourceViewer, { FileItemViewer } from "./fileviewer";


const ResourceCollectionsViewer = ({ name, type, location, pages, onFinish, providerId, providerType }) => {
    const [show, setShow] = useState(true);
    const [resource, setResource] = useState(null);

    const closeViewer = () => {
        setShow(!show);
        onFinish();
    }

    useEffect(() => {

        if (isEmpty(location))
            return;


        setResource({
            name: name,
            type: type,
            pages: pages,
            location: location,
            providerId: providerId,
            providerType: providerType
        });
        setShow(true);
    }, [name, location])

    return <Modal
        isOpen={show}
        toggle={closeViewer}
        size="lg"
    >
        <ModalBody>
            {resource && <FileItemViewer resource={resource} />}

        </ModalBody>

    </Modal>

}

export default ResourceCollectionsViewer;