import React from "react";
import {
    Container
} from "reactstrap";
//Import Breadcrumb
import Breadcrumbs from "../../../../components/Common/Breadcrumb2";

//i18n
import { withTranslation } from "react-i18next";
import GroupListView from "./list";
const UserGroupManagement = props => {

    //meta title
    document.title = "Groupes d'utilisateurs | Jask";

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs
                        title={props.t("Groupes")}
                        breadcrumbItems={[
                            {
                                title: "Jask"
                            },
                            {
                                title: "sécurité"
                            },
                            {
                                title: "groupes"
                            }
                        ]}
                    />
                    <GroupListView />
                </Container>
            </div>
        </React.Fragment>
    );
};


export default withTranslation()(UserGroupManagement);
