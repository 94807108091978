

import React, { useEffect, useState, useRef } from "react";
import { withTranslation } from "react-i18next";
import { Card, CardBody, CardTitle, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledButtonDropdown } from "reactstrap";
import Dropzone from "react-dropzone";
import { isEmpty, map } from "lodash";
import { getFileIcon, getServiceInfo, uuidv4 } from "utils";
import { Link } from "react-router-dom";
import SimpleBar from "simplebar-react";
import { useSelector } from "react-redux";
import { post } from "helpers/api_helper";
import { GET_BOARD_EXEC_PLAN } from "helpers/url_helper";
import { runCustomAppOverDocuments } from "service/studio";
import { EventTypes } from "constants/events";
import { TaskStatus } from "constants/tasks";
import ApplicationStepView from "components/ApplicationRunner/stepView";
import { ExportType } from "constants/export";
import FileSaver from "file-saver"
import ExportGeneration from "components/Export";
import ShareContent from "components/Common/ShareContent";


const OmniApplicationRunner = ({ app, files, context, t }) => {

    const [appLineage, setAppLineage] = useState([]);
    const [applicationSteps, setApplicationSteps] = useState([]);
    const [status, setStatus] = useState(TaskStatus.NOT_STARTED);
    const [streams, setStreams] = useState({});
    const [exportable, setExportable] = useState([]);
    const [canShare, setCanShare] = useState(false);

    const { accessToken } = useSelector(state => ({
        accessToken: state.Login.accessToken,
    }));

    const cancellation = useRef(new AbortController());


    const onFinish = () => {

        const result = {
            id: uuidv4(),
            content: "",
            name: app?.name,
            parts: []
        }


        appLineage.filter(step => step.isSelected === true).forEach((step, index) => {
            const stepResult = streams[step.id];
            const stepResultStr = `${(index + 1)} ${step.name} \n ${stepResult.text}`;
            result.content = `${result.content}${stepResultStr} \n`;
            result.parts.push({
                name: step.name,
                content: stepResult.text
            });
        });

        setExportable(result);
    }



    const onNewStream = (evt) => {
        const id = evt.id;
        const type = evt.type;
        const parentId = evt.parentId;
        const model = {
            id: evt.modelId,
            name: evt.modelName
        }

        switch (type) {

            case EventTypes.TASK_START:
                setStatus(TaskStatus.STARTED);
                break;

            case EventTypes.TASK_END:
                setStatus(TaskStatus.COMPLETED);
                onFinish();
                break;


            case EventTypes.STEP_START:
                setStreams(prev => {
                    return {
                        ...prev,
                        [id]: {
                            text: "",
                            context: "",
                            status: TaskStatus.STARTED,
                            model: model
                        }
                    }
                });

                break;

            case EventTypes.STREAMING:
            case EventTypes.STEP_END:
            case EventTypes.CONTEXT:
                setStreams(prev => {
                    const items = { ...prev }
                    const parentContent = items[id];
                    return {
                        ...prev,
                        [id]: {
                            ...parentContent,
                            text: type === EventTypes.STREAMING ? `${parentContent.text}${evt.value}` : parentContent.text,
                            context: type === EventTypes.CONTEXT ? evt.value.references : parentContent.context,
                            status: type === EventTypes.STEP_END ? TaskStatus.COMPLETED : parentContent.status
                        }
                    }
                });
                break;
        }
    }

    const onStart = () => {
    }

    const onStreamEnd = () => {
    }

    const cancelTask = () => {
        cancellation.current.abort();
        cancellation.current = new AbortController();
        setStatus(TaskStatus.CANCELLED);

        const items = { ...streams };

        appLineage.forEach(step => {
            const stepContent = streams[step.id];
            if (stepContent && stepContent?.status !== TaskStatus.COMPLETED)
                stepContent.status = TaskStatus.CANCELLED;
        });

        setStreams(items);
    }

    const onStepSelected = (item, state) => {

        const steps = [...appLineage];
        let count = 0;

        steps.forEach(step => {
            if (step.id === item.id) {
                step.isSelected = state;
            }
            if (step.isSelected === true) {
                count++;
            }
        });
        setAppLineage(steps);
        setCanShare(count > 0);
        onFinish();
    }


    const start = () => {

        setStreams({});

        const payload = {
            app: app,
            files: files,
            context: context,
            scenarioType: "multiple",
        }

        post(GET_BOARD_EXEC_PLAN, app.board).then(resp => {
            setAppLineage(resp);
            console.log(resp)
        }).then(() => {
            runCustomAppOverDocuments(payload, onNewStream, onStart, onStreamEnd, accessToken, cancellation?.current?.signal)
        });
    }

    useEffect(() => {
        if (isEmpty(streams))
            start()
    }, [])

    return <Card className="runner">
        <CardBody>
            <SimpleBar className="scroller">

                <div>
                    {
                        map(appLineage, (item, index) => {
                            const stepContent = streams[item.id] || {};
                            const text = stepContent.text;
                            const context = stepContent.context;
                            const status = stepContent.status;
                            const isRunning = stepContent.status === TaskStatus.STARTED;
                            const providerInfo = getServiceInfo(item.providerId || item.type);
                            const model = stepContent.model;
                            const isLastStep = index === appLineage?.length - 1;

                            return <ApplicationStepView
                                item={item}
                                stepContent={stepContent}
                                text={text}
                                context={context}
                                status={status}
                                isRunning={isRunning}
                                providerInfo={providerInfo}
                                model={model}
                                index={index}
                                onItemSelected={onStepSelected}
                                isLastStep={isLastStep}
                            />

                        })
                    }
                </div>
            </SimpleBar>
            <div className="hstack gap-1 float-end">
                {(status === TaskStatus.COMPLETED) && <ShareContent content={exportable} disabled={!canShare} titleButton={t("Partager")} />}
                {(status === TaskStatus.COMPLETED || status === TaskStatus.CANCELLED) && <button onClick={start} className="btn btn-success">{t("Relancer")}</button>}
                {status === TaskStatus.STARTED && <button onClick={cancelTask} className="btn btn-danger">{t("Annuler")}</button>}

            </div>
        </CardBody>

    </Card>

}

export default withTranslation()(OmniApplicationRunner);
