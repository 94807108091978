import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

//i18n
import { withTranslation } from "react-i18next";
// Redux
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import { useSelector, useDispatch } from "react-redux";

// users
import user1 from "../../../assets/images/users/avatar-9.jpeg";
import { getInitiales } from "utils";
import { isEmpty } from "lodash";

const ProfileMenu = props => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false);
  const [username, setusername] = useState("Jask");
  const [email, setEmail] = useState(" ");

  const { account } = useSelector(state => ({
    account: state.Profile.account,
  }));

  useEffect(() => {

    setusername(`${account?.firstName} ${account?.name}`);
    setEmail(account?.email);

  }, [account])

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item "
          id="page-header-user-dropdown"
          tag="button"
        >
          <div className="hstack">
            <div className="avatar-xs ">
              <span className="avatar-title rounded-circle bg-info bg-soft text-info font-size-14">
                {!isEmpty(username) && getInitiales(username)}
              </span>
            </div>
            <span className="d-none d-xl-inline-block ms-2 me-1 mt-1">{username}</span>
            <i className="mdi mdi-chevron-down d-none d-xl-inline-block mt-1" />
          </div>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          {/* <DropdownItem tag="a" href="/profile">
            {" "}
            <i className="bx bx-user font-size-16 align-middle me-1" />
            {props.t("Profile")}{" "}
          </DropdownItem>
          <DropdownItem tag="a" href="#">
            <i className="bx bx-wrench font-size-16 align-middle me-1" />
            {props.t("Réglages")}
          </DropdownItem>
          <DropdownItem tag="a" href="auth-lock-screen">
            <i className="bx bx-lock-open font-size-16 align-middle me-1" />
            {props.t("Mes usages")}
          </DropdownItem>
          <div className="dropdown-divider" /> */}
          <Link to="/logout" className="dropdown-item">
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
            <span>{props.t("Déconnexion")}</span>
          </Link>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any
};

const mapStatetoProps = state => {
  const { error, success } = state.Profile;
  return { error, success };
};

export default withRouter(withTranslation()(ProfileMenu));
