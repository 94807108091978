import React from "react";
import {
    Container
} from "reactstrap";
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb2";

//i18n
import { withTranslation } from "react-i18next";
const OrganizationContacts = props => {

    //meta title
    document.title = "Cellenza | Organization";

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs
                        title={props.t("Tableau de board")}
                        breadcrumbItems={[

                            {
                                title: "Cellenza"
                            },
                            {
                                title: "Contacts"
                            }
                        ]}
                    />
                </Container>
            </div>
        </React.Fragment>
    );
};


export default withTranslation()(OrganizationContacts);
