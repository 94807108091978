export const GET_USAGES = "GET_USAGES"
export const GET_USAGES_SUCCESS = "GET_USAGES_SUCCESS"
export const GET_USAGES_FAILED = "GET_USAGES_FAILED"


export const GET_FEEDBACKS_BOARD = "GET_FEEDBACKS_BOARD"
export const GET_FEEDBACKS_BOARD_SUCCESS = "GET_FEEDBACKS_BOARD_SUCCESS"
export const GET_FEEDBACKS_BOARD_FAILED = "GET_FEEDBACKS_BOARD_FAILED"


export const GET_FEEDBACKS = "GET_FEEDBACKS"
export const GET_FEEDBACKS_SUCCESS = "GET_FEEDBACKS_SUCCESS"
export const GET_FEEDBACKS_FAILED = "GET_FEEDBACKS_FAILED"