import React, { useEffect, useState } from "react";
import {
    Col,
    Container, FormGroup, InputGroup, Label, Row
} from "reactstrap";

//i18n
import { withTranslation } from "react-i18next";
import BoardBanner from "./banner";
import BoardContent from "./content";
import { useSelector, useDispatch } from "react-redux";

import {
    getUsageBoard
} from "store/actions";
import { isEmpty } from "lodash";

//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import { Link } from "react-router-dom";

const MonitoringBoard = props => {
    const [editPeriod, setEditPeriod] = useState(false);
    const [period, setPeriod] = useState([
        new Date(new Date().getFullYear(), new Date().getMonth(), 1),
        new Date()
    ]);
    const [dashboard, setDashboard] = useState({});
    const dispatch = useDispatch();
    const { board, isLoading } = useSelector(state => ({
        board: state.Usages.board,
    }));

    const onPeriodChange = (dates, dateString, instance) => {
        setPeriod([...dates]);
    }


    useEffect(() => {
        if (isEmpty(board))
            return;

        const dashboard = {

            consumers: {
                users: board.users,
                assistants: board.assistants,
                applications: board.applications,
                datasources: board.datasources,
                models: board.models

            },
            metrics: {
                totalRequests: board.totalRequests,
                totalRequestsSuccess: board.totalSuccessRequests,
                totalRequestFailed: board.totalFailedRequests,
                totalTokens: board.totalTokens,
                activeUsersCount: board.users.length,
                totalCosts: board.totalCosts
            },

            distribution: board.distribution
        }

        setDashboard(dashboard)

        setEditPeriod(false)

    }, [board])

    useEffect(() => {
        dispatch(getUsageBoard())
    }, [dispatch])

    useEffect(() => {

        if (isEmpty(period) || period.length == 1)
            return

        const searchParams = new URLSearchParams({
            startDate: period[0].toLocaleDateString(),
            endDate: period[1].toLocaleDateString()
        }).toString();


        dispatch(getUsageBoard(searchParams));


    }, [period])

    return (
        <div className="monitoring-board">
            <div className="d-flex justify-content-between">
                <div><h5><i className="bx bx-bar-chart-alt text-info font-size-16" /> Tableau de board

                </h5>

                </div>
                <div>
                    {
                        !editPeriod && period?.length > 1 &&
                        <div className="hstack gap-1">
                            <span className="text-muted font-size-10"> {period[0].toLocaleDateString()} - {period[1].toLocaleDateString()}</span>
                            <Link onClick={() => setEditPeriod(!editPeriod)}><i className="mdi mdi-calendar" /></Link>
                        </div>
                    }

                    {editPeriod && <FormGroup className="">
                        <InputGroup>
                            <Flatpickr
                                className="form-control d-block"
                                placeholder="Periode"
                                options={{
                                    mode: "range",
                                    dateFormat: "d-m-Y",
                                    locale: 'fr',
                                    maxDate: new Date()
                                }}

                                onChange={onPeriodChange}
                                style={{
                                    height: 30
                                }}
                            />
                            <Link onClick={() => setEditPeriod(!editPeriod)}><i className="mdi mdi-close text-danger font-size-20" /></Link>
                        </InputGroup>
                    </FormGroup>}

                </div>
            </div>
            <Row>
                <Col md={12}>
                    <BoardBanner metrics={dashboard.metrics} />
                </Col>
            </Row>
            <Row>
                <BoardContent board={dashboard} />
            </Row>

        </div>
    );
};


export default withTranslation()(MonitoringBoard);
