import { map } from "lodash";
import React, { memo, useEffect, useRef, useState } from "react";
import { Badge, Table } from "reactstrap";
import SimpleBar from "simplebar-react";
import Styles from "./styles.css"
import { Link } from "react-router-dom";

const CrmReferences = ({ references, size }) => {

    const [open, setOpen] = useState(false);
    const [showFile, setShowFile] = useState(false);
    const [selectedFile, setSelectFile] = useState(null);

    const openFile = (file) => {
        setSelectFile(file);
        setShowFile(true);
    }

    return <div style={{ maxHeight: '300px', minWidth: '1200px' }}   >
        <SimpleBar style={{ maxHeight: '300px', minWidth: '100%' }} >

            <div className="table-responsive  ">

                <Table className="table rounded-3 border border-info border-opacity-25 table-nowrap table-hover mb-0">
                    <thead className="table-nowrap">
                        <th align="col" ><span className="ms-1 ">Titre</span></th>
                        <th align="col" ><span className="ms-1 ">Montant</span></th>
                        <th align="col" ><span className="ms-1">Compte client</span></th>
                        <th align="col" ><span className="ms-1">Status</span></th>
                        <th align="col" ><span className="ms-1"><i className="bx bxs-user" /> Propriétaire</span></th>
                    </thead>
                    <tbody>
                        {map(references, item => {
                            return <tr key={item.id}>
                                <td><Link className="text-break">{item.name}</Link></td>
                                <td>{item.amount}</td>
                                <td>{item.source}</td>
                                <td><Badge>{item.status}</Badge></td>
                                <td>{item.owner}</td>

                            </tr>
                        })}
                    </tbody>

                </Table>
            </div>

        </SimpleBar>

    </div>
}

export default CrmReferences;