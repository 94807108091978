import React, { useEffect, useState } from "react"
import { Card, CardBody, Col, Row } from "reactstrap"
import { withTranslation } from "react-i18next";
import Select from "react-select"

const Settings = ({ t, source, onChange }) => {

    const crmTables = [{
        label: t("Opportunités"),
        value: "oppty"
    },
    {
        label: t("Contacts"),
        value: "contacts"
    },
    ]

    const [selectedTables, setSelectedTables] = useState(crmTables);


    useEffect(() => {

        const tables = source?.settings?.crm?.tables || crmTables.map(t => t.value);
        setSelectedTables(crmTables.filter(table => tables.includes(table.value)))

    }, [source])


    useEffect(() => {
        onChange({
            id: source.id,
            crm: {
                tables: selectedTables.map(e => e.value),
            }
        });

    }, [selectedTables])


    return <div>
        <Row>
            <Col md={4} className="pt-2">
                <span className="font-size-12 fw-bold ">{t("Tables à inclure")}</span>
            </Col>
            <Col md={8}>
                <Select
                    options={crmTables}
                    isMulti={true}
                    value={selectedTables}
                    onChange={setSelectedTables}
                />
            </Col>
        </Row>
    </div>
}

export default withTranslation()(Settings);