export const GET_DEPLOYMENTS = "GET_DEPLOYMENTS"
export const GET_DEPLOYMENTS_SUCCESS = "GET_DEPLOYMENTS_SUCCESS"
export const GET_DEPLOYMENTS_FAILED = "GET_DEPLOYMENTS_FAILED"

export const ADD_DEPLOYMENT = "ADD_DEPLOYMENT"
export const ADD_DEPLOYMENT_SUCCESS = "ADD_DEPLOYMENT_SUCCESS"
export const ADD_DEPLOYMENT_FAILED = "ADD_DEPLOYMENT_FAILED"


export const DEL_DEPLOYMENT = "DEL_DEPLOYMENT"
export const DEL_DEPLOYMENT_SUCCESS = "DEL_DEPLOYMENT_SUCCESS"
export const DEL_DEPLOYMENT_FAILED = "DEL_DEPLOYMENT_FAILED"


export const ADD_DEPLOYMENT_KEY = "ADD_DEPLOYMENT_KEY"
export const ADD_DEPLOYMENT_KEY_SUCCESS = "ADD_DEPLOYMENT_KEY_SUCCESS"
export const ADD_DEPLOYMENT_KEY_FAILED = "ADD_DEPLOYMENT_KEY_FAILED"


export const DEL_DEPLOYMENT_KEY = "DEL_DEPLOYMENT_KEY"
export const DEL_DEPLOYMENT_KEY_SUCCESS = "DEL_DEPLOYMENT_KEY_SUCCESS"
export const DEL_DEPLOYMENT_KEY_FAILED = "DEL_DEPLOYMENT_KEY_FAILED"