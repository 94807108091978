import React, { useEffect, useState } from "react";
import {
    Badge,
    Card,
    CardBody,
    CardFooter,
    CardTitle,
    Col,
    Container,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Row,
    UncontrolledDropdown
} from "reactstrap";
import { Link, useNavigate } from "react-router-dom";

//i18n
import { withTranslation } from "react-i18next";
import SimpleBar from "simplebar-react"
import { useSelector, useDispatch } from "react-redux";
import { isEmpty, map, uniqBy } from "lodash";
import { deletePrompt, getPromptStore } from "store/actions";

import AddPrompt from "../../../components/prompt/addPrompt"
import DeleteModal from "components/Common/DeleteModal";


const PromptCategories = ({ prompts, onCategorychange, t }) => {

    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(null);

    const onSelectCategory = (cat) => {
        onCategorychange(cat);
        setSelectedCategory(cat);

    }

    useEffect(() => {
        let items = prompts.filter(p => !isEmpty(p.category?.trim())).map(p => p.category.split(";")).flat();
        items = [...new Set(items)].sort();
        items = items.map(item => ({
            name: item,
            count: prompts.filter(p => p.category.includes(item)).length
        }));
        const allCategories = {
            id: "*",
            name: t("Tous"),
            count: prompts.length
        };

        console.log("====>", items, prompts)

        setCategories([
            allCategories, ...items,
        ]);

        setSelectedCategory(allCategories)
    }, [prompts]);



    return <Card className="menu h-100 ">
        <CardBody className="p-2 ">
            <ul className=" list-unstyled ">
                {map(categories, item => {
                    const isSelected = item.name === selectedCategory?.name;

                    return <li className={isSelected ? "bg-info bg-opacity-10  m-1 rounded-2" : "m-1"} onClick={() => onSelectCategory(item)}>
                        <div className="d-flex justify-content-between">
                            <h5 className="text-muted"><i className={isSelected ? "mdi mdi-chevron-right-circle text-info me-1" : "mdi mdi-chevron-right-circle text-secondary me-1"} />{item.name}</h5>
                            <div>
                                <Badge className="badge badge-soft-secondary font-size-12" color="info">{item.count}</Badge>
                            </div>
                        </div>
                    </li>
                })}
            </ul>
        </CardBody>
    </Card>
}

const PromptStoreList = ({ t }) => {

    const [showAdd, setShowAdd] = useState(false);
    const [showDelete, setShowDelete] = useState(false);
    const [selectedPrompt, setSelectedPrompt] = useState(null);
    const [categoryFilter, setCategoryFilter] = useState(null);
    const [promptsSelection, setPromptSelection] = useState([]);

    const dispatch = useDispatch();


    const { isLoading, prompts } = useSelector(state => ({
        isLoading: state.PromptStore.isLoading,
        prompts: state.PromptStore.prompts

    }));

    const onDelete = (prompt) => {
        setSelectedPrompt(prompt);
        setShowDelete(!showDelete);
    }

    const onConfirmDelete = () => {
        dispatch(deletePrompt(selectedPrompt.id));
        setShowDelete(!showDelete);
    }

    const onCreatePrompt = () => {
        setSelectedPrompt(null);
        setShowAdd(!showAdd);
    }

    const onPromptClick = (prompt) => {
        setSelectedPrompt(prompt);
        setShowAdd(!showAdd)
    }

    const onCategorychange = (item) => {
        setCategoryFilter(item);
    }

    useEffect(() => {
        if (isEmpty(prompts))
            dispatch(getPromptStore({}))
    }, [dispatch]);

    useEffect(() => {
        if (isEmpty(categoryFilter) || categoryFilter?.id === "*") {
            setPromptSelection([...prompts]);
        } else {
            setPromptSelection(prompts.filter(p => p.category.includes(categoryFilter.name)));

        }

    }, [categoryFilter, prompts])

    return (
        <div className="prompt-store">
            {showAdd && <AddPrompt
                show={showAdd}
                onClose={() => setShowAdd(!showAdd)}
                prompt={selectedPrompt}
            />}
            {showDelete && <DeleteModal
                show={showDelete}
                onCloseClick={() => setShowDelete(!showDelete)}
                onDeleteClick={onConfirmDelete}
            />}
            <div className="d-flex justify-content-between">
                <div>
                    <h5>
                        <div className="hstack gap-1">
                            <i className="mdi mdi-message-text font-size-20" />
                            <span>
                                {t("Gallerie des prompts")} ({prompts?.length})
                            </span>
                        </div>


                    </h5>
                </div>

                <div>
                    <button onClick={onCreatePrompt} className="btn btn-danger">+ {t("Nouveau")}</button>
                </div>
            </div>

            <div>

                <Row>
                    <Col md={2} className="border-danger">
                        <PromptCategories prompts={prompts} onCategorychange={onCategorychange} t={t} />
                    </Col>
                    <Col md={10}>
                        <SimpleBar className="scroller ">

                            <div className="d-flex flex-wrap prompts-list">

                                {
                                    map(promptsSelection, prompt => {
                                        const tags = prompt.category.split(";") || [];
                                        return <Card className="border border-info rounded-3 m-2 prompt border-opacity-25">
                                            <CardBody className="app-content" onClick={() => onPromptClick(prompt)}>
                                                <CardTitle>{prompt.name}
                                                </CardTitle>
                                                {prompt.description}
                                            </CardBody>
                                            <div className="d-flex justify-content-between m-2">
                                                <div>
                                                    <div className="hstack gap-1">
                                                        <Badge color="info">{prompt.createdByName}</Badge>
                                                        {map(tags, tag => {
                                                            return <Badge color="danger">{tag}</Badge>
                                                        })}
                                                    </div>

                                                </div>

                                                <div>
                                                    <UncontrolledDropdown direction="down">
                                                        <DropdownToggle tag="a">
                                                            <Link><i className="mdi mdi-cog font-size-16" /></Link>
                                                        </DropdownToggle>
                                                        <DropdownMenu >
                                                            <DropdownItem >
                                                                Editer
                                                            </DropdownItem>
                                                            <DropdownItem onClick={() => onDelete(prompt)}>
                                                                Supprimer
                                                            </DropdownItem>
                                                        </DropdownMenu>
                                                    </UncontrolledDropdown>
                                                </div>
                                            </div>
                                        </Card>
                                    })
                                }
                                <Card className="border border-info rounded-3 m-2 prompt border-opacity-25" onClick={onCreatePrompt}>
                                    <CardBody className="">
                                        <div className="d-flex justify-content-center h-100 align-items-center">
                                            <i className="mdi mdi-plus prompt-add-button" />
                                        </div>
                                    </CardBody>
                                </Card>
                            </div>
                        </SimpleBar>

                    </Col>
                </Row>

            </div>
        </div>
    );
};


export default withTranslation()(PromptStoreList);
