import { isEmpty, map } from "lodash";
import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { ThreeDots } from "react-loader-spinner";

import {
    Row,
    Col,
    Card,
    CardBody
} from "reactstrap";
//i18n
const BoardBanner = ({ metrics, isLoading, t }) => {

    const [items, setItems] = useState([]);


    useEffect(() => {
        if (isEmpty(metrics))
            return;

        const newItems = [
            {
                name: t('Nombre de requêtes'),
                value: new Intl.NumberFormat("fr-FR").format(metrics.totalRequests),
                icon: "bx bx-user font-size-18 text-info"
            },
            {
                name: "Utilisateurs actifs",
                value: new Intl.NumberFormat("fr-FR").format(metrics.totalUsers),
                icon: "bx bx-user font-size-18 text-info ",
            },
            {
                name: 'Feedbacks Positives',
                value: new Intl.NumberFormat("fr-FR").format(metrics.totalPositive),
                icon: "mdi mdi-thumb-up font-size-18 text-success"
            },
            {
                name: 'Feedbacks Négatives',
                value: metrics.totalNegative,
                icon: "mdi mdi-thumb-down font-size-18 text-danger"

            },
            {
                name: " Negative / Total ",
                value: new Intl.NumberFormat("fr-FR").format(metrics.ratioNeg * 100),
                // icon: "bx bx-user font-size-24 ",
                suffix: "%"
            },

            {
                name: "Problème à résoudre",
                value: new Intl.NumberFormat("fr-FR").format(metrics.totalOpen),
                icon: "mdi mdi-emoticon-sad font-size-18 text-danger ",
            },

        ];

        setItems(newItems);

    }, [metrics])

    return (
        <div>
            <Row>
                {map(items || [], item => {

                    return <Col md="2">
                        <Card className="mini-stats-wid bg-info bg-opacity-25">
                            <CardBody>
                                <div className="d-flex">
                                    <div className="flex-grow-1">
                                        <p className="text-muted fw-medium">
                                            {item.name}
                                        </p>
                                        {isLoading && <ThreeDots
                                            height="20"
                                            width="50"
                                            radius="5"
                                            color="grey"
                                            ariaLabel="three-dots-loading"
                                            wrapperStyle={{}}
                                            wrapperClassName=""
                                            visible={true}
                                        />}
                                        {!isLoading && <h4 className="mb-0">
                                            <div className="hstack gap-1">
                                                <i className={item.icon} />
                                                <span>{item.value} {item.suffix}</span>
                                            </div>
                                        </h4>}

                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                })}
            </Row>
        </div>
    );
};


export default withTranslation()(BoardBanner);