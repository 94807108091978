import React from "react";
import {
    Container
} from "reactstrap";
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb2";

//i18n
import { withTranslation } from "react-i18next";
import TodoListView from "./items";
const TodoList = props => {

    //meta title
    document.title = "Mes taches | Jask";

    return (
        <div className="page-content ">
            <Container fluid>
                <Breadcrumbs
                    title={props.t("Mes taches")}
                    breadcrumbItems={[
                        {
                            title: "Jask"
                        },
                        {
                            title: props.t("Espace de travail")
                        },
                        {
                            title: props.t("Mes taches")
                        }
                    ]}
                />

                <TodoListView />


            </Container>
        </div>
    );
};


export default withTranslation()(TodoList);
