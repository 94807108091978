import React, { useEffect, useState } from "react";
import logoDark from "../../../../../assets/images/logos/jask-8.png";
import logoLight from "../../../../../assets/images/logos/jask-6.png";
import { Card, CardBody, CardTitle } from "reactstrap";
import TaskLoader from "components/Common/Loader";
import { useSelector, useDispatch } from "react-redux";
import { layoutModeTypes } from "constants/layout";

const LandingChat = ({ }) => {

    const { theme } = useSelector(state => ({
        theme: state.Layout.layoutModeType,
    }));

    return <div className="landing-chat" >
        <div className=" chat-info">
            <div className="d-flex justify-content-center flex-column align-items-center mb-4 ">

                <span className="logo-lg" data-layout-mode="dark" >
                    {theme === layoutModeTypes.DARK ? <img src={logoDark} alt="" height="70" /> : <img src={logoLight} alt="" height="70" />}
                </span>
                <h5 className="text-muted mt-2">Configuration du chat...</h5>
                <TaskLoader height={"22vh"} width={100} />
            </div>
        </div>
    </div>
}

export default LandingChat;