import React, { createRef, useEffect, useRef, useState } from "react"
import { Link } from "react-router-dom"
import {
    Table,
    DropdownMenu,
    DropdownToggle,
    UncontrolledDropdown,
    DropdownItem,
    Card,
    CardBody,
    CardFooter,
    UncontrolledTooltip,
    Spinner,
    Modal,
} from "reactstrap"

import { useSelector, useDispatch } from "react-redux";
import { toLocalDate } from "helpers/to-local-date";
import { getFileIcon, truncateFromMiddle } from "utils";
import FileListPlaceHolders from "./placeholders";
import { post, del } from "helpers/api_helper";
import { BROWSE_DIR, GET_DIRECTORY } from "helpers/url_helper";
import NewFolder from "./newFolder";
import DeleteModal from "components/Common/DeleteModal";
import { uploadFile, uploadTaskFiles, bulkDeleteFiles } from "service/documents";
import ContentIndexor from "./indexor";
import DeployAssistant from "pages/Workspace/assistant/deploy";
import SimpleBar from "simplebar-react"

import {
    createOmniChatConversation,
    showRightSidebarAction
} from "store/actions";
import Dropzone from "react-dropzone";
import { operationSucceed } from "utils/notifications";
import AppLauncher from "components/AppLauncher";
import { ResourceTypes } from "constants/general";
import { isTabularFile } from "utils/files";
import TabularLauncher from "components/Analytics/TabularFile";
import PdfViewer from "components/DocumentViewer/pdfViewer";
import { withTranslation } from "react-i18next";

const FileSystem = ({ t }) => {

    const [currentDir, setCurrentDir] = useState({ location: "/", name: "" });
    const [showAddFolder, setShowAddFolder] = useState(false);
    const [showDeleteBlob, setShowDeleteBlob] = useState(false);
    const [showContentIndexor, setShowContentIndexor] = useState(false);
    const [showDeployAssistant, setShowDeployAssistant] = useState(false);
    const [showRunCustomApp, setShowCustomApp] = useState(false);
    const [stats, setStats] = useState({});
    const [selectedApp, setSelectedApp] = useState(null);
    const [uploading, setUploading] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [directoryContent, setDirectoryContent] = useState([]);
    const [ancestors, setAncestors] = useState([]);
    const [selectedBlob, setSelectedBlob] = useState(null);
    const [showDeleteSelection, setShowDeleteSelection] = useState(false);
    const [contentToIndex, setContentToIndex] = useState([]);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [showTabular, setShowTabular] = useState(false);
    const [fileToOpen, setFileToOpen] = useState(null);
    const [showFileViewer, setShowFileViewer] = useState(false);
    const dropzoneRef = createRef()

    const inputRef = useRef(null);
    const dispatch = useDispatch();

    const { apps, account } = useSelector(state => ({
        apps: state.Studio.apps,
        account: state.Profile.account
    }));

    const changeDir = (e, item) => {
        if (e.detail !== 2)
            return;

        setCurrentDir(item);

        if (item.location === currentDir?.location)
            handleFileClick();
    }

    const browseCurrentDir = () => {
        setIsLoading(true);
        const payload = { name: currentDir.location }
        post(BROWSE_DIR, payload).then(resp => {
            setDirectoryContent(resp.content);

            if (currentDir.location !== "/") {
                setAncestors([...resp.ancestors, {
                    name: resp.current,
                    location: currentDir.location,
                    level: resp.ancestors.length
                }])
            } else {
                setAncestors(resp.ancestors);
            }

        }).finally(() => setIsLoading(false))
    }

    const onPathClick = (item) => {
        setCurrentDir(item);
    }

    const onNewDirectory = (item) => {
        setDirectoryContent([item, ...directoryContent]);
    }

    const onBeginDeletion = (item) => {
        setSelectedBlob(item);
        setShowDeleteBlob(true);
    }

    const deleteBlob = () => {
        del(GET_DIRECTORY + "?directory=" + selectedBlob.location).then(location => {
            const remaining = [...directoryContent].filter(i => i.location !== location);
            setDirectoryContent(remaining);

        }).finally(() => {
            setShowDeleteBlob(false);
        })
    }

    const handleAcceptedFiles = (files) => {
        setUploading(true);
        const config = {
            onUploadProgress: function (progressEvent) {
                var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            }
        }

        uploadTaskFiles(files, currentDir.location, config).then(uploadedFiles => {
            setDirectoryContent([...directoryContent, ...uploadedFiles])
            operationSucceed(`${uploadedFiles.length} fichiers téléchargés avec succès !`)
        }).finally(() => {
            setUploading(false);
        });
    }

    const onImport = (e) => {
        const fileObj = e.target.files && e.target.files[0];

        if (!fileObj) {
            return;
        }

        e.target.files = null;

        uploadFile(fileObj, currentDir.location).then(resp => {
            browseCurrentDir();
        })
    }

    const handleCustomApp = (scope) => {
        setSelectedApp(scope);
        setShowCustomApp(!showRunCustomApp);

    }

    const startOmnichat = () => {
        const payload = {
            id: `${currentDir.location}`,
            name: currentDir.name || t("Mes documents"),
            type: ResourceTypes.FILE,
            datasources: [{
                providerId: ResourceTypes.FILESYSTEM,
                id: currentDir.location,
                name: currentDir.name,
                type: currentDir.isDir ? ResourceTypes.DIRECTORY : ResourceTypes.FILE
            }]
        }

        dispatch(createOmniChatConversation(payload));
        dispatch(showRightSidebarAction({
            type: ResourceTypes.ASSISTANTS,
            conversation: payload
        }));
    }

    const openFile = (file) => {
        setFileToOpen(file);
        setShowFileViewer(!showFileViewer)
    }

    const selectAll = (evt) => {
        const files = [...directoryContent];

        files.forEach(f => {
            f.isSelected = evt.target.checked;
        });

        setDirectoryContent(files)
    }

    const onFileSelected = (file) => {
        const files = [...directoryContent];

        files.forEach(f => {
            if (f.location === file.location)
                f.isSelected = file.isSelected === true ? false : true;
        });

        setDirectoryContent(files);
    }

    const handleAssistantDeployment = (item) => {
        if (item) {
            onFileSelected(item);
        }
        setShowDeployAssistant(!showDeployAssistant)
    }

    const handleDeleteSelection = () => {

        setUploading(true);
        const selection = directoryContent.filter(f => f.isSelected).map(f => f.location);
        bulkDeleteFiles({ files: selection }).then(deleted => {

            const remaining = directoryContent.filter(f => !deleted.includes(f.location));
            setDirectoryContent(remaining);

        }).finally(() => {
            setUploading(false);
            setShowDeleteSelection(false);
        });
    }


    const handleIndexing = () => {
        setContentToIndex(directoryContent.filter(f => f.isSelected));
        setShowContentIndexor(true);
    }

    const handleFileClick = () => {
        if (currentDir.isDir === false) {

            if (isTabularFile(currentDir.name)) {
                setShowTabular(true);
            } else {
                startOmnichat();
            }

        } else {
            browseCurrentDir();
        }
    }

    useEffect(() => {
        handleFileClick();
    }, [currentDir]);



    useEffect(() => {
        const totalDir = directoryContent.filter(i => i.isDir === true);
        const totalFiles = directoryContent.filter(i => i.isDir === false);
        const totalSelected = directoryContent.filter(i => i.isSelected === true);

        setStats({
            totalDirs: totalDir.length,
            totalFiles: totalFiles.length,
            totalSelected: totalSelected.length
        });

        setSelectedFiles(directoryContent.filter(f => f.isSelected));

    }, [directoryContent])

    return (
        <React.Fragment>
            <TabularLauncher
                show={showTabular}
                onClose={() => setShowTabular(false)}
                file={currentDir} />


            {showDeployAssistant && <DeployAssistant
                show={showDeployAssistant}
                onCloseClick={() => setShowDeployAssistant(false)}
                knowledgeBase={selectedFiles}
                provider={ResourceTypes.FILESYSTEM}
            />}
            <AppLauncher show={showRunCustomApp} onCloseClick={() => setShowCustomApp(false)} app={selectedApp} directory={currentDir} files={directoryContent} />
            <DeleteModal show={showDeleteBlob} onCloseClick={() => setShowDeleteBlob(false)} onDeleteClick={deleteBlob} />
            <DeleteModal text={`Voulez-vous vraiment supprimer les éléments selectionnés ?`} show={showDeleteSelection} onCloseClick={() => setShowDeleteSelection(false)} onDeleteClick={handleDeleteSelection} />
            <NewFolder currentLocation={currentDir} onNewDirectory={onNewDirectory} show={showAddFolder} onCloseClick={() => setShowAddFolder(!showAddFolder)} />
            <ContentIndexor locations={contentToIndex} show={showContentIndexor} onCloseClick={() => setShowContentIndexor(false)} />
            <Modal isOpen={showFileViewer} toggle={() => setShowFileViewer(!showFileViewer)} centered>
                <PdfViewer location={fileToOpen?.location} />
            </Modal>

            <Card>
                {!isLoading && <CardBody>
                    <div className="recentFiles">
                        <div className="d-flex flex-wrap">
                            {ancestors.map(item => {
                                return <div key={item.location} className="hstack me-1 ">
                                    {item.level > 0 && <span>/</span>}
                                    <Link onClick={() => onPathClick(item)} className="ms-1">{item.name || t("Mes Documents")}</Link>
                                </div>
                            })}

                            <div className="ms-auto">
                                <div className="hstack">
                                    <Link onClick={() => setShowAddFolder(true)} className="me-1 btn btn-primary ">  + {t("Dossier")} </Link>
                                    <Link onClick={() => dropzoneRef?.current?.open()} className="me-1 btn btn-primary ">  <i className="mdi mdi-upload" /> {t("Importer")} </Link>

                                    <UncontrolledDropdown>

                                        <DropdownToggle
                                            href="#"
                                            tag="btn"
                                            className="dropdown-toggle btn-primary"
                                            disabled={stats?.totalSelected < 1}
                                        >
                                            <button className="btn btn-primary  ms-1" disabled={stats?.totalSelected < 1}>{t("Executer")} <i className="mdi mdi-chevron-down"></i></button>
                                        </DropdownToggle>
                                        <DropdownMenu
                                        >

                                            <DropdownItem
                                                onClick={() => handleCustomApp(null)}
                                            >
                                                <i className="mdi mdi-plus font-size-16 text-info me-1" id="processTip"></i>
                                                {t("Nouvelle tache")}
                                                <UncontrolledTooltip placement="top" target="processTip">
                                                    {t("Appliquer une nouvelle instruction")}

                                                </UncontrolledTooltip>
                                            </DropdownItem>

                                            <DropdownItem divider />

                                            <DropdownItem
                                                onClick={() => handleAssistantDeployment(null)}
                                            >
                                                <i className="bx bx-bot font-size-16 text-info me-1" id="processTip"></i>
                                                {t("Créer un assistant")}
                                                <UncontrolledTooltip placement="top" target="processTip">
                                                    {t("Nouvelle assistant")}

                                                </UncontrolledTooltip>
                                            </DropdownItem>

                                            <DropdownItem divider />

                                            <DropdownItem href="#" onClick={handleIndexing}>
                                                <div className="hstack gap-1">
                                                    <i className="mdi mdi-cog text-info font-size-16 " />
                                                    <span className="">{t("Indexer le contenu")}</span>
                                                </div>
                                            </DropdownItem>
                                            <DropdownItem divider />

                                            <DropdownItem
                                                onClick={() => setShowDeleteSelection(!showDeleteSelection)}
                                            >
                                                <div className="hstack gap-1">
                                                    <i className="mdi mdi-close text-danger font-size-16 " />
                                                    <span>  {t("Supprimer les éléments")}</span>
                                                </div>

                                                <UncontrolledTooltip placement="top" target="processTip">
                                                    {t("Supprimer les fichiers selectionnés")}

                                                </UncontrolledTooltip>
                                            </DropdownItem>
                                            <div className="dropdown-divider"></div>
                                            <div className="dropdown-header noti-title">
                                                <h5 className="font-size-13 text-muted text-truncate mn-0">{t("Mes applications")}</h5>
                                            </div>

                                            <div className="dropdown-divider"></div>



                                            {(apps || []).map((action, index) => {

                                                return <DropdownItem key={action.id}
                                                    onClick={() => handleCustomApp(action)}
                                                >
                                                    <i className="mdi mdi-file-tree font-size-16 text-info me-1" id="processTip"></i>
                                                    {action.name}
                                                    <UncontrolledTooltip placement="top" target="processTip">
                                                        {action.name}
                                                    </UncontrolledTooltip>
                                                </DropdownItem>
                                            })}
                                        </DropdownMenu>
                                    </UncontrolledDropdown>

                                    {/* <Link onClick={startOmnichat} className="me-1 btn btn-primary  ms-2">  Chat <i className="mdi mdi-chat"></i></Link> */}

                                    <UncontrolledDropdown>
                                        <DropdownToggle tag="btn" className=" font-size-20 me-2" >
                                            <button className="btn btn-primary ms-2"><i className="mdi mdi-dots-horizontal "></i></button>
                                        </DropdownToggle>

                                        <DropdownMenu className="dropdown-menu-end" >
                                            <DropdownItem href="#" onClick={() => setShowAddFolder(true)}>
                                                <div className="hstack gap-1">
                                                    <i className="mdi mdi-folder text-info font-size-16 " />
                                                    <span className=""> {t("Créer un dossier")}</span>
                                                </div>
                                            </DropdownItem>
                                            <DropdownItem divider />

                                            <DropdownItem href="#" onClick={() => dropzoneRef?.current?.open()}>
                                                <div className="hstack gap-1">
                                                    <i className="mdi mdi-upload text-info font-size-16 " />
                                                    <span className="">{t("Importer un fichier")}</span>
                                                </div>
                                            </DropdownItem>

                                            <DropdownItem divider />
                                            <DropdownItem href="#" onClick={startOmnichat}>
                                                <div className="hstack gap-1">
                                                    <i className="mdi mdi-chat text-info font-size-16 " />
                                                    <span className="">{t("Rechercher une info")}</span>
                                                </div>
                                            </DropdownItem>


                                            <DropdownItem divider />

                                            <DropdownItem href="#" onClick={browseCurrentDir}>
                                                <div className="hstack gap-1">
                                                    <i className="mdi mdi-refresh text-info font-size-16 " />
                                                    <span className="">{t("Actualiser")}</span>
                                                </div>
                                            </DropdownItem>
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                </div>
                            </div>
                        </div>
                        <hr className="mt-2" />
                        <Dropzone
                            onDrop={acceptedFiles => {
                                handleAcceptedFiles(acceptedFiles)
                            }}
                            noClick={true}
                            ref={dropzoneRef}
                        >
                            {({ getRootProps, getInputProps }) => (
                                <div className="table-responsive h-100" {...getRootProps()} >
                                    <input type="file" hidden ref={inputRef} onChange={onImport} />
                                    <input {...getInputProps()} />
                                    <SimpleBar className="scroller">
                                        <Table className="table align-middle table-nowrap table-hover mb-0">
                                            <thead>
                                                <tr>
                                                    <th scope="col">
                                                        <div className="form-check   ms-1">
                                                            <input className="form-check-input  font-size-16" type="checkbox" id="selectAll" onChange={selectAll} />
                                                            <span className="ms-2">{t("Nom")}</span>
                                                            <label className="form-check-label" htmlFor="selectAll"></label>
                                                        </div>

                                                    </th>
                                                    <th scope="col">{t("Date de création")}</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {directoryContent.map((item, key) => {

                                                    return <tr key={key} onClick={(e) => changeDir(e, item)}>

                                                        <td>
                                                            <div className="hstack gap-1">
                                                                <div className="form-check font-size-16">
                                                                    <input className="form-check-input" type="checkbox" id={item.location} checked={item.isSelected} onClick={e => onFileSelected(item)} />
                                                                    <label className="form-check-label" htmlFor="upcomingtaskCheck01"></label>
                                                                </div>

                                                                <Link to="#" className="text-dark fw-medium">
                                                                    <div className="vstack">
                                                                        <div className="hstack gap-1">
                                                                            {item.isDir ? <i className="mdi mdi-folder me-1 font-size-20 text-warning" /> : getFileIcon(item.name, 20)}
                                                                            <span> {truncateFromMiddle(item.name, 100)}</span>
                                                                            {item.metadata && item.metadata.indexingStatus === 'success' && <i className="mdi mdi-cloud-check text-success ms-2" />}
                                                                        </div>
                                                                        {!item.isDir && <small className="ms-4 text-muted">Taille: {(item.size / 1000).toFixed(2)} KB | Modifié le {toLocalDate(item.lastModified)}</small>}
                                                                    </div>

                                                                </Link>
                                                            </div>
                                                        </td>
                                                        <td>{toLocalDate(item.createdAt)}</td>
                                                        <td></td>
                                                        <td>
                                                            <UncontrolledDropdown>

                                                                <DropdownToggle tag="a" className="font-size-16 text-muted" role="button">
                                                                    <i className="mdi mdi-dots-horizontal"></i>
                                                                </DropdownToggle>

                                                                <DropdownMenu className="dropdown-menu-end">
                                                                    <DropdownItem href="#" onClick={() => openFile(item)}>
                                                                        <div className="hstack gap-1">
                                                                            <i className="mdi mdi-search text-info font-size-16 " />
                                                                            <span className="">Visualiser</span>
                                                                        </div>
                                                                    </DropdownItem>

                                                                    <DropdownItem href="#" onClick={() => handleAssistantDeployment(item)}>
                                                                        <div className="hstack gap-1">
                                                                            <i className="mdi mdi-download text-info font-size-16 " />
                                                                            <span className="">Créer un assistant</span>
                                                                        </div>
                                                                    </DropdownItem>
                                                                    <DropdownItem href="#">
                                                                        <div className="hstack gap-1">
                                                                            <i className="mdi mdi-download text-info font-size-16 " />
                                                                            <span className="">Télécharger</span>
                                                                        </div>
                                                                    </DropdownItem>
                                                                    <DropdownItem href="#" onClick={() => onBeginDeletion(item)}>
                                                                        <div className="hstack gap-1">
                                                                            <i className="mdi mdi-close text-danger font-size-16 " />
                                                                            <span className="">Supprimer</span>
                                                                        </div>

                                                                    </DropdownItem>

                                                                </DropdownMenu>
                                                            </UncontrolledDropdown>
                                                        </td>
                                                    </tr>
                                                })}
                                            </tbody>
                                        </Table>
                                    </SimpleBar>
                                </div>
                            )}
                        </Dropzone>

                    </div>


                </CardBody>}

                {isLoading && <CardBody>
                    <FileListPlaceHolders ancestors={ancestors} />
                </CardBody>}

                <CardFooter className="border">
                    <div className="d-flex justify-content-between">
                        <div className="hstack gap-2">

                            <div className="hstack gap-1">
                                <i className="mdi mdi-folder text-warning ms-2 font-size-16" /> :
                                <span className="text-muted">Dossier</span>
                            </div>
                            <div className="hstack gap-1">
                                <i className="mdi mdi-file ms-2 font-size-16" /> :
                                <span className="text-muted">Fichier</span>
                            </div>

                            <div className="hstack gap-1">
                                <i className="mdi mdi-cloud-check text-success ms-2 font-size-16" /> :
                                <span className="text-muted">Indexé</span>
                            </div>
                        </div>

                        <div>
                            {uploading && <div className="hstack gap-1">
                                <Spinner size="sm" />
                                Traitement en cours...
                            </div>}
                        </div>
                        <div className="hstack gap-2">
                            <div className="hstack gap-1">
                                <i className="mdi mdi-folder text-warning ms-2 font-size-16" /> :
                                <span className="text-muted">{stats?.totalDirs || 0}</span>
                            </div>

                            <div className="hstack gap-1">
                                <i className="mdi mdi-file ms-2 font-size-16" /> :
                                <span className="text-muted">{stats?.totalFiles || 0}</span>
                            </div>

                        </div>
                    </div>
                </CardFooter>
            </Card>


        </React.Fragment>
    )
}

export default withTranslation()(FileSystem);
