import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { CREATE_CONVERSATION, DELETE_CONVERSATION, GET_AI_MODELS, GET_CONVERSATIONS, GET_CONVERSATION_HISTORY, GET_DATA_SERVICES, SEARCH_RESOURCES, UPDATE_CONVERSATION_TITLE } from "./actionTypes"
import { getConversationHistorySuccess, getConversationhistoryFailed, createConversationSuccess, createConversationFailed, getConversationSuccess, getConversationsFailed, delConversationSuccess, delConversationFailed, getDataServicesSuccess, getDataServicesFailed, searchResources, searchResourcesSuccess, searchResourcesFailed, getModelsSuccess, getModelsFailed } from "./actions"

//Include Both Helper File with needed methods
import { getConversations, getDataServices as onGetDataServices, search, createConversation, getConversationHistory, deleteConversation, getAiModels, renameConversationTitle } from "helpers/backend_helper"

function* fetchConversations() {
  try {
    const response = yield call(getConversations)
    yield put(getConversationSuccess(response))
  } catch (error) {
    yield put(getConversationsFailed(error))
  }
}

function* fetchConversationHistory(action) {
  try {
    const response = yield call(getConversationHistory, action.payload)
    yield put(getConversationHistorySuccess(response))
  } catch (error) {
    yield put(getConversationhistoryFailed(error))
  }
}

function* createNewConversation(action) {
  try {
    const response = yield call(createConversation, action.payload)
    yield put(createConversationSuccess(response))
  } catch (error) {
    yield put(createConversationFailed(error))
  }
}

function* deleteUserConversation(action) {
  try {
    const response = yield call(deleteConversation, action.payload)
    yield put(delConversationSuccess(response))
  } catch (error) {
    yield put(delConversationFailed(error))
  }
}

function* getDataProviders(action) {
  try {
    const response = yield call(onGetDataServices, action.payload)
    yield put(getDataServicesSuccess(response))
  } catch (error) {
    yield put(getDataServicesFailed(error))
  }
}

function* searchRelevantInfo(action) {
  try {
    const response = yield call(search, action.payload)
    yield put(searchResourcesSuccess(response))
  } catch (error) {
    yield put(searchResourcesFailed(error))
  }
}

function* onGetAiModels(action) {
  try {
    const response = yield call(getAiModels, action.payload)
    yield put(getModelsSuccess(response))
  } catch (error) {
    yield put(getModelsFailed(error))
  }
}

function* onRenameConversation(action) {
  try {
    const response = yield call(renameConversationTitle, action.payload)
  } catch (error) {
    console.log(error)
  }
}


function* assistantSaga() {
  yield takeEvery(GET_CONVERSATIONS, fetchConversations)
  yield takeEvery(GET_CONVERSATION_HISTORY, fetchConversationHistory)
  yield takeEvery(CREATE_CONVERSATION, createNewConversation)
  yield takeEvery(DELETE_CONVERSATION, deleteUserConversation)
  yield takeEvery(GET_DATA_SERVICES, getDataProviders)
  yield takeEvery(SEARCH_RESOURCES, searchRelevantInfo)
  yield takeEvery(GET_AI_MODELS, onGetAiModels)
  yield takeEvery(UPDATE_CONVERSATION_TITLE, onRenameConversation)

}

export default assistantSaga
