import { toDate, toLocalDate } from "helpers/to-local-date";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import {
    Badge,
    Card,
    CardBody,
    CardFooter,
    CardTitle,
    Container,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    UncontrolledDropdown
} from "reactstrap";

import BotIcon from "assets/images/bot.png"

const Assistant = ({ app, onDelete, onOpenApp, onEditApp }) => {
    return <Card className="border border-info rounded-3 m-2 app border-opacity-25"
    >

        <div className="hstack  p-1" onClick={() => onOpenApp(app)}>
            <img src={app.logoUrl} width={60} height={80} />

            <CardBody className="app-content">
                <CardTitle>{app.name}
                </CardTitle>
                {app.description}
            </CardBody>

        </div>
        <div className="border-top app-actions">
            <div className="float-start ms-2 text-muted font-size-10">
                <Badge color="info"><i className="bx bx-user me-1" />{app.principalName}</Badge>
            </div>
            <div className="float-end me-2">
                <UncontrolledDropdown direction="down">
                    <DropdownToggle tag="a">
                        <Link><i className="mdi mdi-cog font-size-16" /></Link>
                    </DropdownToggle>
                    <DropdownMenu >

                        {app.app && <DropdownItem onClick={() => onOpenApp(app)}>
                            Démarrer
                        </DropdownItem>}
                        <DropdownItem onClick={() => onEditApp(app)} >
                            Editer
                        </DropdownItem>
                        <DropdownItem onClick={() => onDelete(app)}>
                            Supprimer
                        </DropdownItem>

                    </DropdownMenu>
                </UncontrolledDropdown>
            </div>
        </div>
    </Card>

    // <Card className="assistant-card ms-2 mt-2  me-2">
    //     <CardTitle className="border-bottom ps-4 pt-2 pe-2 bg-primary rounded-1 ">
    //         <div className="d-flex justify-content-between">
    //             <div>
    //                 <div className="hstack gap-1">
    //                     {app.app && <i className="bx bx-bot font-size-20 mb-2 text-danger" />}
    //                     {!app.app && <i className="bx bx-code font-size-20 mb-2 text-danger" />}
    //                     <h6 className="header mt-1">{app.name}</h6>
    //                 </div>
    //             </div>
    //             <div>
    //                 <button className="btn-close font-size-12" onClick={() => onDelete(app)} />
    //             </div>
    //         </div>
    //     </CardTitle>
    //     <CardBody >
    //         <div className="">

    //             <div onClick={() => onOpenApp(app)} className="d-flex flex-column justify-content-center align-items-center">
    //                 <div className=" hstack gap-2">
    //                     {app.logoUrl ? <img src={app.logoUrl} width={60} height={80} /> : <img src={BotIcon} width={60} />}
    //                     <div className=" mt-2  ms-2" >
    //                         <p className="">{app.description}</p>
    //                     </div>
    //                 </div>

    //             </div>

    //         </div>

    //     </CardBody>
    //     <CardFooter>
    //         <div className="d-flex justify-content-between border-top pt-2">

    //             <div className="hstack gap-2">
    //                 <Badge color="success"> Valide</Badge>
    //                 <Badge color="info"> {app.app?.modelName}</Badge>
    //             </div>
    //             <div>

    //             </div>
    //         </div>
    //     </CardFooter>
    // </Card>
};


export default Assistant;
