import React, { useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { isEmpty } from "lodash";
import TableContainer from 'components/Common/TableContainer';
import DeleteModal from 'components/Common/DeleteModal';

import {
    delDeployment,
    getDeployments
} from "store/actions";

import {
    Status, FullName, DeploymentName, DateValue, AppDeployment
}
    from "./columns";

//redux
import { useSelector, useDispatch } from "react-redux";

import {
    Col,
    Row,
    UncontrolledTooltip,
    Card,
    CardBody,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem
} from "reactstrap";
import DeployAssistant from "pages/Workspace/assistant/deploy";
import AddDeployment from "../add";

function DeploymentList() {

    const [isEdit, setIsEdit] = useState(false);
    const [deployment, setDeployment] = useState(null);
    const [deleteModal, setDeleteModal] = useState(false);
    const [showAdd, setShowAdd] = useState(false);
    const dispatch = useDispatch();
    const { deployments, apps, assistants } = useSelector(state => ({
        deployments: state.Deployments.deployments,
        apps: state.Studio.apps,
        assistants: state.Studio.assistants

    }));

    useEffect(() => {
        if (isEmpty(deployments)) {
            dispatch(getDeployments());
        }
    }, [dispatch]);

    const refresh = () => {
        dispatch(getDeployments());
    }

    const handleDelete = () => {
        if (deployment?.id) {
            dispatch(delDeployment(deployment?.id));
            setDeleteModal(false);
        }
    };

    const handleAddClick = () => {
        setIsEdit(false);
        setDeployment(null);
        setShowAdd(!showAdd);
    }


    const handleDeleteDeployment = (item) => {
        setDeployment(item);
        setDeleteModal(true);
    };

    const handleEditDeployment = (item) => {
        setIsEdit(true);
        setDeployment(item);
        setShowAdd(!showAdd);
    }

    const openTestUrl = (deployment) => {
        window.open(deployment.testUrl, "_blank");
    }


    const columns = useMemo(
        () => [
            {
                Header: '',
                accessor: 'id',
                disableFilters: true,
                Cell: (cellProps) => {
                    return <div className="form-check font-size-16">
                        <input className="form-check-input" type="checkbox" id={"datasource-" + cellProps.value} />
                        <label className="form-check-label" htmlFor="upcomingtaskCheck01"></label>
                    </div>
                }
            },
            {
                Header: 'Nom du déploiement',
                accessor: 'name',
                disableFilters: true,
                Cell: (cellProps) => {
                    return <DeploymentName {...cellProps} />;
                }
            },


            {
                Header: 'Assistant déployé',
                accessor: 'applicationName',
                disableFilters: true,
                Cell: (cellProps) => {
                    return <AppDeployment {...cellProps} assistants={assistants} />;
                }
            },
            {
                Header: 'Crée le',
                accessor: 'createdAt',
                disableFilters: true,
                Cell: (cellProps) => {
                    return <DateValue {...cellProps} />;
                }
            },
            {
                Header: 'Crée par',
                accessor: 'principalName',
                disableFilters: true,
                Cell: (cellProps) => {
                    return <FullName {...cellProps} />;
                }
            },

            {
                Header: 'Status',
                accessor: 'status',
                disableFilters: true,
                Cell: (cellProps) => {
                    return <Status {...cellProps} />;
                }
            },
            {
                Header: 'Actions',
                disableFilters: true,
                Cell: (cellProps) => {
                    return <div>
                        <div className="hstack gap-1">
                            <Link onClick={() => handleDeleteDeployment(cellProps.row.original)}><i className="mdi mdi-close-circle text-danger font-size-20" /></Link>
                            <Link onClick={() => handleEditDeployment(cellProps.row.original)}><i className="mdi mdi-pencil-circle text-info font-size-20" /></Link>
                            {cellProps.row.original?.testUrl && <Link onClick={() => openTestUrl(cellProps.row.original)}><i className="bx bx-search mt-1 text-info font-size-22" /></Link>
                            }
                        </div>
                    </div>;
                }
            }
        ],
        []
    );

    return (
        <React.Fragment>
            <DeleteModal
                show={deleteModal}
                onDeleteClick={handleDelete}
                onCloseClick={() => setDeleteModal(false)}
                text={"Voulez-vous vraiment supprimer ce déploiement?"}
            />

            <AddDeployment
                isOpen={showAdd}
                onCloseClick={handleAddClick}
                isEdit={isEdit}
                initialDeployment={deployment}
            />

            <Card className="datasources-panel">
                <CardBody>
                    <div className="">
                        <div>
                            <TableContainer
                                columns={columns}
                                data={deployments || []}
                                isGlobalFilter={true}
                                isAddOptions={false}
                                handleAddClicks={handleAddClick}
                                addButtonText="Nouveau"
                                isDatasourceListGlobalFilter={false}
                                isGenericAdd={true}
                                customPageSize={10}
                                onRefresh={refresh}
                            />
                        </div>
                    </div>

                </CardBody>
            </Card>

        </React.Fragment>
    );
}


export default DeploymentList;