import React from "react";
import {
    Container
} from "reactstrap";
import Breadcrumbs from "../../../../components/Common/Breadcrumb2";
import { withTranslation } from "react-i18next";
import RequestList from "./list"

const SupportRequests = props => {

    //meta title
    document.title = "Jask For Support";

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs
                        title={props.t("Liste des Demandes")}
                        breadcrumbItems={[
                            {
                                title: "Jask"
                            },
                            {
                                title: "Support"
                            },
                            {
                                title: "Requests"
                            }
                        ]}
                    />

                    <RequestList />

                </Container>
            </div>
        </React.Fragment>
    );
};


export default withTranslation()(SupportRequests);
