import React from 'react';
import { Badge } from 'reactstrap';
import { Link } from 'react-router-dom';
import { getInitiales } from 'utils';
import { map } from 'lodash';
import { toDate } from 'helpers/to-local-date';


const SelectUser = (cell) => {
    return <div className="form-check font-size-16">
        <input className="form-check-input" type="checkbox" id={"datasource-" + cell.value} />
        <label className="form-check-label" htmlFor="upcomingtaskCheck01"></label>
    </div>
}

const Name = (cell) => {
    return <Link className='font-size-14 text-muted'>
        {cell.value ? cell.value : ""}
    </Link>
};

const RoleType = (cell) => {
    return <div className='text-center'>{cell.value === true ? <Badge className='font-size-12' color='primary'>Natif </Badge> : <Badge className='font-size-12' color='primary'>Personnalisé </Badge>}</div>
};

const Date = (cell) => {
    return <Link className='font-size-14 text-muted'>
        {cell.value ? toDate(cell.value) : ""}
    </Link>
};

const Role = (cell) => {
    const roles = cell.row.original.roles;
    return <div className='hstack gap-1'>
        {map(roles, role => {
            return <Badge className="badge-soft-info font-size-12">{role}</Badge>
        })}
    </div>
};

const Permissions = (cell) => {
    const members = cell.row.original.permissions;
    return <Link className='font-size-14 '>
        {members?.length > 0 ? members?.length + " privillege(s)" : "Aucun"}
    </Link>
};
const ConsumptionPlan = (cell) => {

    return <div className='d-flex justify-content-center  '> {cell.value ?
        <div>
            <div className='hstack gap-1'>
                <Link>Plan Actif</Link>
                <i className='mdi mdi-check-circle font-size-18 text-success' />
            </div>
        </div> :
        <div className='hstack gap-1'>
            <Link>Aucun</Link>
            <i className='mdi mdi-alert font-size-18 text-danger' />
        </div>
    }
    </div>
}


const RoleName = (cell) => {

    const roleId = cell.row.original.id;
    let title = null;

    switch (roleId) {

        case "application":
            title = "Administrateur d'applications"
            break;

        case "assistant":
            title = "Administrateur d'assistants"
            break;
        case "workspace":
            title = "Espace de Travail"
            break;
        case "admin":
            title = "Administrateur Globale"
            break;

        case "monitoring":
            title = "Suivi des usages"
            break;
        case "datasources":
            title = "Administrateur des données";
            break;
        case "users":
            title = "Administrateur des utilisateurs";
            break;
        case "templates":
            title = "Administrateur des templates";
            break;
        case "aiAdmin":
            title = "Administrateur des modèles d'IA";
            break;
        default:
            title = cell.row.original.name;
    }

    return <div className='hstack'>
        <div className="avatar-xs me-3">
            <span className="avatar-title rounded-circle bg-info bg-soft text-info font-size-18">
                {getInitiales(title)}
            </span>
        </div>
        <span>{title}</span>

    </div>
};




export { Name, Role, RoleName, SelectUser, Date, Permissions, RoleType, ConsumptionPlan };