import React, { useEffect, useState } from "react";
import {
    Col,
    Container, FormGroup, InputGroup, Label, Row
} from "reactstrap";

//i18n
import { withTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { isEmpty } from "lodash";
import { getFeedbacksBoard } from "store/actions";
import Flatpickr from "react-flatpickr";
import { Link } from "react-router-dom";
import BoardBanner from "./banner";
import BoardContent from "./content";

const FeedbackBoard = ({ t }) => {
    const dispatch = useDispatch();
    const [dashboard, setDashboard] = useState({});
    const [editPeriod, setEditPeriod] = useState(false);
    const [period, setPeriod] = useState([
        new Date(new Date().getFullYear(), new Date().getMonth(), 1),
        new Date()
    ]);
    const { board, isLoading, feedbacks, feedbacksBoard } = useSelector(state => ({
        board: state.Usages.board,
        feedbacks: state.Usages.feedbacks,
        feedbacksBoard: state.Usages.feedbacksBoard,

    }));

    useEffect(() => {
        if (isEmpty(feedbacksBoard)) {
            dispatch(getFeedbacksBoard());
        }
    }, [dispatch])


    useEffect(() => {
        if (isEmpty(feedbacksBoard))
            return;

        const dashboard = {

            consumers: {
                users: feedbacksBoard.resources.users,
                assistants: feedbacksBoard.resources.assistant,
            },
            metrics: {
                totalRequests: feedbacksBoard.totalRequests,
                totalPositive: feedbacksBoard.totalPositive,
                totalNegative: feedbacksBoard.totalNegative,
                ratioNeg: feedbacksBoard.ratio,
                totalUsers: feedbacksBoard.totalUsers,
                totalOpen: feedbacksBoard.totalOpen

            },

            distribution: feedbacksBoard.resources.date,
            latestFeedbacks: feedbacksBoard.latestFeedbacks || []
        }

        console.log(dashboard)
        setDashboard(dashboard)

    }, [feedbacksBoard])

    return <div className="monitoring-board">
        <div className="d-flex justify-content-between">
            <div><h5><i className="bx bx-bar-chart-alt text-info font-size-16" /> {t("Suivi des feedbacks")}
            </h5>
            </div>
            <div>
                {
                    !editPeriod && period?.length > 1 &&
                    <div className="hstack gap-1">
                        <span className="text-muted font-size-10"> {period[0].toLocaleDateString()} - {period[1].toLocaleDateString()}</span>
                        <Link onClick={() => setEditPeriod(!editPeriod)}><i className="mdi mdi-calendar" /></Link>
                    </div>
                }

                {editPeriod && <FormGroup className="">
                    <InputGroup>
                        <Flatpickr
                            className="form-control d-block"
                            placeholder="Periode"
                            options={{
                                mode: "range",
                                dateFormat: "d-m-Y",
                                locale: 'fr',
                                maxDate: new Date()
                            }}

                            onChange={onPeriodChange}
                            style={{
                                height: 30
                            }}
                        />
                        <Link onClick={() => setEditPeriod(!editPeriod)}><i className="mdi mdi-close text-danger font-size-20" /></Link>
                    </InputGroup>
                </FormGroup>}

            </div>
        </div>
        <Row>
            <Col md={12}>
                <BoardBanner metrics={dashboard.metrics} />
            </Col>
        </Row>
        <Row>
            <BoardContent board={dashboard} />
        </Row>
    </div>
}

export default withTranslation()(FeedbackBoard);