import React, { useEffect, useState } from "react"
import classnames from "classnames"
import { Modal, ModalBody, ModalHeader, Nav, NavLink, NavItem, TabContent, TabPane, Row, Col, Label, Input, Card, CardBody, Spinner, Badge, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, UncontrolledTooltip } from "reactstrap"
import SearchAppConfigIcon from "../../../../assets/images/searchAppConfig.png"
import { Link } from "react-router-dom"
import Select from 'react-select';
import { camelCase, isEmpty, map, size } from "lodash"
import SimpleBar from "simplebar-react"
import { getFileIcon, getProviderLogo, truncateFromMiddle, uuidv4 } from "utils"
import { useSelector, useDispatch } from "react-redux";
import Slider from 'react-rangeslider';
import "react-rangeslider/lib/index.css"
import {
    addPlatformApp, getDatasourceList, getGroups, getModels, getUsers
} from "store/actions";
import AssistantIdentity from "./identity"
import { DatasourceProviders, DefaultText, ResourceTypes } from "constants/general"
import DocumentBrowser from "components/Common/DocumentBrowser"
import DatasourceBrowser from "components/Common/DatasourceBrowser"
import { withTranslation } from "react-i18next";
import KnowledgeBase from "./knowledgeBase"

const scopes = [
    { value: 'private', label: 'Moi uniquement' },
    { value: 'shared', label: 'Toute l\'organisation' },
]

const volumeUnits = [
    { value: 'size', label: 'M. Octets' },
    { value: 'token', label: 'Tokens' },
]

const requestUnits = [
    { value: 'messages', label: 'Messages' },
]

const topResult = [
    { value: 'dynamic', label: 'Dynamique' },
    { value: 'custom', label: 'Personnalisé' },
]

const assistantTypes = [
    {
        title: "Qu'est ce qu'un assistant de type Chat ?",
        value: 'rag',
        label: 'Chat',
        icon: 'bx bx-chat',
        description: "Fournit une experience de question réponse sans filtres des sources de données.",
        tags: ["Rapidité", "Performance", "Efficacité", "Rag"],
        paragraphs: ["Ce type d'assistant vous permet d'interroger la documentation sélectionnée et de répondre éfficacement aux questions posées.  ",
            "Lorsque vous exécutez cette application, elle interroge toutes les sources de données sélectionnées en utilisant le contexte fourni. Cette application peut être déclenchée par un utilisateur, une application ou un flux de travail."
        ]
    },
    {
        title: "Qu'est ce qu'un assistant de type Search + Chat?",
        value: 'search',
        label: 'Search + Chat',
        icon: 'bx bx-search',
        description: "Fournit une experience de recherche avancée avec personnalisation des filtres.",
        tags: ["Rapidité", "Performance", "Efficacité", "Rag"],
        paragraphs: ["Ce type d'assistant vous permet d'interroger la documentation sélectionnée et de répondre éfficacement aux questions posées.  ",
            "Vous avez la possibilité de positionner des filtres au niveau de ou des  sources de données disponibles"
        ]
    },
    {
        title: "Qu'est ce qu'un assistant de type Agent ?",
        value: 'agent',
        label: 'Chat Expert',
        description: "Fournit une capacité de recherche et raisonnement approfondie. ",
        paragraphs: ["Ce type d'assistant vous permet de poser un contexte ou un problème à résoudre et de laisser le temps à l'IA de vous apporter la meilleure réponse possible en fonction des ressources à disposition (fichiers, internet, IA, ...)",
            "Lorsque vous posez une question à cet agent, il analyse la question ou contexte sous plusieurs angles, réunit toutes les informations utiles puis formule sa réponse. Il est donc plus lent mais très performant pour des tâches d'analyse."
        ],
        tags: ["Très performant", "Plus lent", "Précis"],
        disabled: true,

    },
]

const DynamicContent = ({ data, onChange, onRemove }) => {

    const [id, setId] = useState(data?.id);
    const [type, setType] = useState(data?.type);
    const [value, setValue] = useState(data?.value);
    const [condition, setCondition] = useState(data?.condition);
    const [isValid, setIsValid] = useState(false);

    useEffect(() => {
        const validity = !isEmpty(type) && !isEmpty(value) && !isEmpty(condition);

        onChange({
            id: id,
            type: type,
            value: value,
            condition: condition,
            isValid: validity
        });

        setIsValid(validity);

    }, [type, value, condition]);


    return <div className="mt-4 mb-2">
        <Row>
            <Col xs={3}>
                <Label>Type de contenu</Label>
                <Input type="text"
                    className="form-control"
                    placeholder="ie: contact client"
                    value={type}
                    onChange={e => setType(e.target.value)}
                />
            </Col>
            <Col xs={4}>
                <Label>Condition</Label>
                <Input type="text"
                    className="form-control"
                    placeholder="si la question est une demande d'information"
                    value={condition}
                    onChange={e => setCondition(e.target.value)}
                />
            </Col>
            <Col xs={5}>
                <Label>Valeur</Label>

                <div className="hstack">
                    <Input type="text" className="form-control"
                        placeholder="Site web: https://mon.portail.client "
                        value={value}
                        onChange={e => setValue(e.target.value)}

                    />
                    <Link onClick={() => onRemove(id)}>
                        <i className="ms-1 mdi mdi-trash-can-outline text-danger font-size-18" />
                    </Link>
                    {!isValid ? < i className="mdi mdi-alert text-danger font-size-20 ms-2" /> : < i className="mdi mdi-check-circle text-success font-size-20 ms-2" />}

                </div>

            </Col>

        </Row>
    </div>
}


const DeployAssistant = ({ show, onCloseClick, knowledgeBase, provider, app, appType, t }) => {

    const [activeTab, setactiveTab] = useState('general');
    const [appName, setAppName] = useState("")
    const [description, setDescription] = useState("")
    const [datasources, setDatasources] = useState([])
    const [selectedDatasource, setSelectedDatasource] = useState(null);
    const [scope, setScope] = useState(scopes[0]);
    const [isLimitedByUser, setIsLimitedByUser] = useState(false);
    const [isLimitedByVolume, setIsLimitedByVolume] = useState(false);
    const [isCustomScope, setIsCustomScope] = useState(false);
    const [messagesQuota, setMessagesQuota] = useState(50);
    const [volumeQuota, setVolumeQuota] = useState(10);
    const [messagesQuotaUnit, setMessagesQuotaUnit] = useState(requestUnits[0]);
    const [volumeQuotaUnit, setVolumeQuotaUnit] = useState(volumeUnits[0]);
    const [model, setModel] = useState(null);
    const [members, setMembers] = useState([]);
    const [selectedAvatar, setSelectedAvatars] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [isValid, setIsValid] = useState(false);
    const [iscreating, setIsCreating] = useState(false);
    const [promptSystem, setPromptSystem] = useState(null);
    const [availableModels, setAvailableModels] = useState([]);
    const [showFSBrowser, setShowFSBrowser] = useState(false);
    const [showDatasourceBrowser, setShowDatasourceBrowser] = useState(false);

    const [conditionalInsertion, setConditionalInsertion] = useState(false);
    const [conditions, setConditions] = useState([]);
    const [validSettings, setValidSettings] = useState(false);
    const [topK, setTopK] = useState(3);
    const [topKMin, setTopKMin] = useState(1);
    const [topKMax, setTopKMax] = useState(20);
    const [topKStrategy, setTopKStrategy] = useState(topResult[0]);
    const [quotaStopMessage, setQuotaMessage] = useState(DefaultText.QUOTA_REACHED);
    const [assistantType, setAssistantType] = useState(assistantTypes[0]);

    const dispatch = useDispatch();

    const { isAdding, users, groups, customSources, models } = useSelector(state => ({
        isAdding: state.Studio.isAdding,
        users: state.Users.users,
        groups: state.Users.groups,
        customSources: state.Datasources.datasources,
        models: state.Assistant.models

    }));

    const handleCreate = () => {
        const config = {
            id: app?.id,
            name: appName,
            description: description,
            logoUrl: selectedAvatar,
            principalId: app?.principalId,
            principalName: app?.principalName,
            app: {
                type: assistantType?.value,
                scope: scope.value,
                isLimitedByUser: isLimitedByUser,
                isLimitedByVolume: isLimitedByVolume,
                isCustomScope: isCustomScope,
                messagesQuota: messagesQuota || 0,
                messagesQuotaUnit: messagesQuotaUnit?.value,
                volumeQuota: volumeQuota || 0,
                volumeQuotaUnit: volumeQuotaUnit?.value,
                model: model?.value,
                modelName: model?.label,
                users: selectedUsers.map(u => u.value),
                providers: datasources,
                promptSystem: promptSystem,
                dynamicContent: conditions,
                retrieverStrategy: topKStrategy?.value,
                topK: topK,
                quotaStopMessage: quotaStopMessage
            }
        }

        setIsCreating(true);
        dispatch(addPlatformApp(config))
    }

    const onSourceConfigChanged = (settings) => {
        const items = [...datasources];
        items.forEach(ds => {
            if (ds.id === settings.id) {
                ds.settings = { ...settings }
            }
        });

        setDatasources(items);
    }

    const removeSource = (item) => {
        setDatasources(datasources.filter(d => d.id !== item.id));
    }

    const onDatasourceFilterClicked = (datasource) => {
        setSelectedDatasource(datasource);
        setShowDatasourceBrowser(true);
    }

    const onRemoveDatasourceFilter = (datasource, filterId) => {

        const sources = [...datasources];
        sources.forEach(s => {
            if (s.id === datasource.id) {
                const filters = s.filters || [];
                s.filters = filters.filter(f => f.id !== filterId);
            }
        });

        setDatasources(sources);
    }

    const getItemDescription = (ds) => {

        switch (ds.type) {

            case ResourceTypes.DIRECTORY:

                return <div className="hstack gap-1">
                    <i className="mdi mdi-folder me-1 text-warning" />
                    {ds.name}
                </div>

            case ResourceTypes.FILE:

                return <div className="vstack">
                    <div className="hstack gap-1">
                        {getFileIcon(ds.name, 16)}
                        {truncateFromMiddle(ds.name, 50)}
                    </div>
                    <div>
                        {ds.principalName && <Badge className="ms-3"> <i className="bx bxs-user" />{ds.principalName}</Badge>
                        }                    </div>
                </div>

            case ResourceTypes.WEB:
                return <div className="hstack gap-1">
                    <i className="mdi mdi-cloud text-info me-1 font-size-16" />
                    {ds.name}
                </div>

            case DatasourceProviders.SALESFORCE:
            case DatasourceProviders.BING_SEARCH:
            case DatasourceProviders.GOOGLE_SEARCH:
            case DatasourceProviders.SHAREPOINT:
                return <div className="vstack">
                    <div className="hstack gap-1">
                        {getProviderLogo(ds.type, size = 18)}
                        {ds.name}
                    </div>
                    {ds.filters && <div>
                        <small className="ms-3"> {(ds.filters || []).length} filtres</small>
                    </div>
                    }
                </div>
        }
    }

    const onSelectedSourceFilter = (selectedItems) => {
        const sources = [...datasources];
        sources.forEach(source => {
            if (source.id === selectedDatasource?.id) {

                const existings = (source.filters || []).map(f => f.id);
                const newFilters = selectedItems.filter(s => !existings.includes(s.id));
                source.filters = [...(source.filters || []), ...newFilters];
            }
        });

        setShowDatasourceBrowser(false);
        setDatasources(sources);

    }

    const addSource = (source) => {
        const newSource = {
            ...source,
            providerId: ResourceTypes.DATASOURCES,

        };

        setDatasources([...datasources, newSource]);
    }

    const addFSSource = (path) => {
        const newSource = {
            providerId: ResourceTypes.FILESYSTEM,
            id: path.location,
            name: path.name,
            type: path.isDir ? ResourceTypes.DIRECTORY : ResourceTypes.FILE,
            principalId: path.principalId,
            principalName: path.principalName
        };

        setDatasources([...datasources, newSource]);
        setShowFSBrowser(!showFSBrowser);
    }

    const updateKb = (knowledgeBase) => {
        const sources = [];
        switch (provider) {
            case ResourceTypes.FILESYSTEM:
                knowledgeBase.forEach(item => {

                    sources.push({
                        id: item.location,
                        providerId: ResourceTypes.FILESYSTEM,
                        name: item.name,
                        type: item.isDir ? ResourceTypes.DIRECTORY : ResourceTypes.FILE,
                        principalId: item.principalId,
                        principalName: item.principalName
                    });
                });

                break;

            default:

                sources.push({
                    providerId: ResourceTypes.DATASOURCES,
                    id: knowledgeBase.id,
                    name: knowledgeBase.name,
                    type: provider
                });
                break;
        }

        setDatasources(sources);
    }

    const addCondition = () => {
        setConditions([...conditions, { id: uuidv4() }]);
    }

    const removeCondition = (id) => {
        setConditions(conditions.filter(cond => cond.id !== id));
    }

    const onConditionChanged = (data) => {

        const items = [...conditions];

        items.forEach(cond => {
            if (cond.id === data.id) {
                cond.type = data.type;
                cond.value = data.value;
                cond.condition = data.condition;
                cond.isValid = data.isValid;
            }
        });

        setConditions(items);
    }

    useEffect(() => {
        const items = conditions || [];
        const all = (arr, fn = Boolean) => arr.every(fn);
        const allValid = all(items, i => i.isValid);
        let validConditions = items.length == 0 || (allValid && items.length > 0);

        if (isLimitedByUser) {
            validConditions = validConditions && messagesQuota > 0 && !isEmpty(quotaStopMessage);
        }

        setValidSettings(!isEmpty(model) && validConditions);

    }, [conditions, model, isLimitedByUser, quotaStopMessage])

    useEffect(() => {

        if (!isEmpty(knowledgeBase))
            updateKb(knowledgeBase);
    }, [knowledgeBase, provider]);


    useEffect(() => {
        if (iscreating && !isAdding) {
            onCloseClick();
        }
        setIsCreating(isAdding);
    }, [isAdding])
    useEffect(() => {

        let canCreate = !isEmpty(appName) && !isEmpty(description) && !isEmpty(selectedAvatar);

        if (isCustomScope)
            canCreate = canCreate && selectedUsers?.length > 0;

        if (isLimitedByUser)
            canCreate = canCreate && messagesQuota > 0 && !isEmpty(quotaStopMessage);

        if (isLimitedByVolume)
            canCreate = canCreate && volumeQuota > 0;

        setIsValid(canCreate && validSettings);


    }, [appName,
        description,
        scope, datasources,
        isLimitedByUser,
        isLimitedByVolume,
        isCustomScope,
        messagesQuota,
        volumeQuota,
        messagesQuotaUnit,
        volumeQuotaUnit,
        model, selectedUsers, selectedAvatar,
        validSettings, quotaStopMessage])


    useEffect(() => {
        if (isEmpty(app))
            return;

        setAppName(app.name);
        setDescription(app.description);
        setVolumeQuota(app.app.volumeQuota);
        setVolumeQuotaUnit(volumeUnits.find(i => i.value === app.app.volumeQuotaUnit));
        setMessagesQuota(app.app.messagesQuota);
        setMessagesQuotaUnit(requestUnits.find(i => i.value === app.app.messagesQuotaUnit));
        setPromptSystem(app.app.promptSystem)
        setModel(availableModels.find(i => i.value === app.app.model))
        setIsCustomScope(app.app.isCustomScope);
        setIsLimitedByUser(app.app.isLimitedByUser);
        setIsLimitedByVolume(app.app.isLimitedByVolume);
        setSelectedUsers(members.filter(m => app.app.users.includes(m.value)));
        setDatasources(app.app.providers);
        setConditions(app.app.dynamicContent || []);
        setScope(scopes.find(i => i.value === app.app.scope));
        setTopK(app.app.topK);
        setTopKStrategy(topResult.find(i => i.value === app.app.retrieverStrategy) || topResult[0]);
        setQuotaMessage(app.app.quotaStopMessage || DefaultText.QUOTA_REACHED);
        setAssistantType(assistantTypes.find(i => i.value === app.app.type) || assistantTypes[0]);


    }, [app, availableModels, assistantTypes]);

    useEffect(() => {

        if (isEmpty(appType)) {

        }

    }, [appType])

    useEffect(() => {

        if (isEmpty(users))
            dispatch(getUsers())

        if (isEmpty(groups))
            dispatch(getGroups())

        if (isEmpty(customSources))
            dispatch(getDatasourceList())

        if (isEmpty(models))
            dispatch(getModels());

    }, [dispatch])

    useEffect(() => {
        const userPrincipals = users.map(u => ({
            label: u.firstName + " " + u.name,
            value: u.id
        }))

        const groupPrincipals = groups.map(u => ({
            label: u.name,
            value: u.id
        }))

        setMembers([...userPrincipals, ...groupPrincipals]);

    }, [users, groups]);

    useEffect(() => {

        setAvailableModels(models?.map(m => ({
            value: m.id,
            label: m.name,
            description: m.description
        })));

    }, [models]);

    return <Modal size="xl"
        isOpen={show}
        toggle={onCloseClick}
        centered={true}
        contentClassName='window-medium'
    >
        <DocumentBrowser
            show={showFSBrowser}
            onCloseClick={() => setShowFSBrowser(false)}
            onConfirm={addFSSource}
            fade={false}
        />

        {selectedDatasource && <DatasourceBrowser
            show={showDatasourceBrowser}
            onCloseClick={() => setShowDatasourceBrowser(false)}
            datasource={selectedDatasource}
            onConfirm={onSelectedSourceFilter}
        />}

        <ModalHeader>
            <h5>{t("Déployer un nouvel assistant")}</h5>
            <button type="button" onClick={onCloseClick} className="btn-close position-absolute end-0 top-0 m-3"></button>
        </ModalHeader>

        <div className="modal-content deploy-assistant">
            <ModalBody>
                <div>
                    <Nav tabs>
                        <NavItem>
                            <NavLink onClick={() => setactiveTab('general')}
                                className={classnames({ active: activeTab === 'general' })}>

                                <div className="hstack gap-1">
                                    <i className=" font-size-20 mdi mdi-tooltip" />
                                    {t("Informations Générales")}

                                </div>
                            </NavLink>
                        </NavItem>
                        <NavItem >
                            <NavLink onClick={() => setactiveTab('knowledge')}
                                className={classnames({ active: activeTab === 'knowledge' })}>
                                <div className="hstack gap-1">
                                    <i className=" font-size-20 mdi mdi-database" />
                                    <span className="text-muted">{t("Base de connaissances")}</span>
                                </div>
                            </NavLink>
                        </NavItem>
                        <NavItem >
                            <NavLink onClick={() => setactiveTab('identity')}
                                className={classnames({ active: activeTab === 'identity' })}>
                                <div className="hstack gap-1">
                                    <i className=" font-size-20 mdi mdi-human-child" />
                                    Avatars
                                    {isEmpty(selectedAvatar) ? <i className="mdi mdi-alert text-danger" /> : <i className="mdi mdi-check-circle text-success" />}
                                </div>
                            </NavLink>
                        </NavItem>


                        <NavItem >
                            <NavLink onClick={() => setactiveTab('security')}
                                className={classnames({ active: activeTab === 'security' })}>
                                <div className="hstack gap-1">
                                    <i className=" font-size-20 mdi mdi-cog-sync" />
                                    {t("Réglages Avancées")}
                                    {!validSettings ? < i className="mdi mdi-alert text-danger font-size-14 " /> : < i className="mdi mdi-check-circle text-success font-size-14" />}
                                </div>
                            </NavLink>
                        </NavItem>

                        <NavItem >
                            <NavLink onClick={() => setactiveTab('security')}
                                className={classnames({ active: activeTab === 'tools' })}>
                                <div className="hstack gap-1">
                                    <i className=" font-size-20 mdi mdi-tools" />
                                    <span className="text-muted">{t("Outils (Plugins)")}</span>
                                    <div className="ms-4">
                                        <Badge color="success">Bientot</Badge>
                                    </div>
                                </div>
                            </NavLink>
                        </NavItem>
                    </Nav>
                    <TabContent activeTab={activeTab}>
                        <TabPane tabId="general">
                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col lg={6}>
                                            <div className="d-flex flex-column">
                                                <div className="mb-3">
                                                    <Label for="basicpill-name-input1">
                                                        {t("Nom de votre assistant")}:
                                                    </Label>
                                                    <Input
                                                        type="text"
                                                        className="form-control"
                                                        id="appName"
                                                        placeholder=""
                                                        value={appName}
                                                        onChange={e => setAppName(e.target.value)}
                                                    />
                                                </div>

                                                <div className="mb-3 ">
                                                    <Label>{t("Type d'assistant")}:</Label>
                                                    {/* <Select value={assistantType} options={assistantTypes} defaultValue={assistantTypes[0]} className='select2-selection' onChange={e => setAssistantType(e)} /> */}

                                                    <div className="assistant-types w-100">
                                                        {map(assistantTypes, item => {
                                                            const isSelected = assistantType?.value === item.value;
                                                            return <div key={item.value} onClick={e => setAssistantType(item)} className={"assistant-type   me-1 p-2 " + (isSelected ? "selected" : "")}>
                                                                <h6 className="fw-bold "><i className={item.icon + " font-size-14 me-1 text-danger"} />{item.label} {item.disabled && <Badge className="ms-4 font-size-10" color="success">{t("Bientot")}</Badge>}</h6>
                                                                <p className="font-size-10 m-0 p-0">{item.description}</p>
                                                            </div>
                                                        })}
                                                    </div>

                                                </div>

                                                <div className="mb-2">
                                                    <Label for="basicpill-address-input1">
                                                        Description:
                                                    </Label>
                                                    <textarea
                                                        id="basicpill-address-input1"
                                                        className="form-control"
                                                        rows="2"
                                                        placeholder={t("Quelques mots pour décrire cette assistant")}
                                                        value={description}
                                                        onChange={e => setDescription(e.target.value)}
                                                    />
                                                </div>

                                                {/* <div className="mb-4">
                                                    <Label>{t("Ressources à disposition")}:</Label>
                                                    <div className="knowledgebase  border border-secondary border-opacity-50 p-2">
                                                        <SimpleBar className="scroller">
                                                            <ul className="list-unstyled">
                                                                {map(datasources || [], (ds, index) => {
                                                                    return <li key={"ds_" + index} className="mb-2 border-bottom border-secondary border-opacity-25">
                                                                        <div className="d-flex justify-content-between">
                                                                            <div>
                                                                                {getItemDescription(ds)}

                                                                            </div>
                                                                            <div className="">
                                                                                <div className="hstack gap-1">
                                                                                    {ds.canBrowse && <Link id={camelCase(ds.name) + "_filter"} onClick={() => onDatasourceFilterClicked(ds)} className="ms-3 font-size-10">
                                                                                        <i className="mdi mdi-filter font-size-16"></i>

                                                                                        <UncontrolledTooltip placement="top" target={camelCase(ds.name) + "_filter"}>
                                                                                            Ajouter des filtres
                                                                                        </UncontrolledTooltip>
                                                                                    </Link>}
                                                                                    <Link id={camelCase(ds.name) + "_del"} to={"#!"} className="text-danger" onClick={e => removeSource(ds)}>
                                                                                        <i className="mdi mdi-trash-can-outline" />
                                                                                        <UncontrolledTooltip placement="top" target={camelCase(ds.name) + "_del"}>
                                                                                            retirer cette source
                                                                                        </UncontrolledTooltip>
                                                                                    </Link>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                })}
                                                            </ul>
                                                        </SimpleBar>

                                                    </div>
                                                    <div className="float-end mt-2">
                                                        <UncontrolledDropdown>
                                                            <DropdownToggle tag="a">
                                                                <i className="mdi mdi-database-plus me-1" />{t("Attacher une source")}
                                                            </DropdownToggle>
                                                            <DropdownMenu>
                                                                <DropdownItem divider />

                                                                {map(customSources, item => {
                                                                    const source = { ...item, id: item.id, name: item.name, type: item.provider.type, canBrowse: item.hasFileSystem };
                                                                    return <DropdownItem
                                                                        onClick={() => addSource(source)}
                                                                        key={item.id}>{getItemDescription(source)}
                                                                    </DropdownItem>;
                                                                })}

                                                                <DropdownItem divider />

                                                                <DropdownItem onClick={() => setShowFSBrowser(true)}><i className="mdi mdi-folder text-warning me-1 font-size-16" />Fichiers ou dossiers</DropdownItem>

                                                            </DropdownMenu>
                                                        </UncontrolledDropdown>

                                                    </div>

                                                </div> */}

                                                <div className="mb-0">
                                                    <Label>{t("Cette assistant sera visible par")}:</Label>
                                                    <Select value={scope} options={scopes} defaultValue={scopes[0]} className='select2-selection' onChange={e => setScope(e)} />
                                                </div>

                                                <div className="hr border mt-3" />

                                                <div className="mb-3 mt-2 hstack gap-2">
                                                    <button className="btn btn-success" onClick={handleCreate} disabled={!isValid || iscreating}>Créer</button>
                                                    <button className="btn btn-danger" onClick={onCloseClick}>Annuler</button>
                                                    {iscreating && <div className="vr" />}
                                                    {iscreating && <Spinner />}
                                                </div>
                                            </div>
                                        </Col>
                                        <Col lg={1}>

                                        </Col>
                                        <Col lg={5}>
                                            <Card>
                                                <CardBody>
                                                    <div className="favorite-icon">
                                                        <Link to="#"><i className="uil uil-heart-alt fs-18"></i></Link>
                                                    </div>
                                                    {selectedAvatar ?
                                                        <img src={selectedAvatar} className="mb-3 ms-4" height={200} /> :
                                                        <img src={SearchAppConfigIcon} alt="" height="120" className="mb-3" />}

                                                    <h5 className="fs-17 mb-2">
                                                        <Link to="#" className="text-dark">{t(assistantType?.title)}</Link> {assistantType.disabled && <Badge>{t("Bientot")}</Badge>}</h5>
                                                    {/* <p className=""> Un assistant vous permet d'interroger la documentation sélectionnée en fonction du contexte fourni en entrée.</p>

                                                    <p>Lorsque vous exécutez cette application, elle interroge toutes les sources de données sélectionnées en utilisant le contexte fourni. Cette application peut être déclenchée par un utilisateur, une application ou un flux de travail.</p> */}
                                                    <p className="text-muted">{t(assistantType?.description)}</p>
                                                    {map(assistantType?.paragraphs || [], content => {
                                                        return <p>{t(content)}</p>
                                                    })}
                                                    <div className="mt-3 hstack gap-2">
                                                        {map(assistantType?.tags || [], tag => {
                                                            return <span className="badge rounded-1 badge-soft-info">{t(tag)}</span>

                                                        })}
                                                    </div>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>

                        </TabPane>

                        <TabPane tabId={"knowledge"}>
                            <KnowledgeBase
                                removeSource={removeSource}
                                selectedSources={datasources}
                                addSource={addSource}
                                customSources={customSources}
                                getItemDescription={getItemDescription}
                                onDatasourceFilterClicked={onDatasourceFilterClicked}
                                onBrowseFileSystem={(state) => setShowFSBrowser(state)}
                                onSourceConfigChanged={onSourceConfigChanged}
                                onRemoveDatasourceFilter={onRemoveDatasourceFilter}

                            />
                        </TabPane>

                        <TabPane tabId="identity">
                            <AssistantIdentity avatar={app?.logoUrl} onAvatarChanged={setSelectedAvatars} />
                            <div className="float-end">
                                <button className="btn btn-info" onClick={() => setactiveTab("general")}> Terminer</button>
                            </div>
                        </TabPane>

                        <TabPane tabId="security">
                            <Card className="assistant-deploy">
                                <CardBody>
                                    <SimpleBar className="scroller">
                                        <div className="border p-4 mb-4 border-secondary border-opacity-50 rounded-4">
                                            <h5 className="font-size-14 ">{t("Modèle IA Générative")}:</h5>
                                            <div className="hr border mb-1 border-secondary border-opacity-25 rounded-4 " />

                                            <div className=" ">
                                                <Select
                                                    options={availableModels}
                                                    value={model}
                                                    className='select2-selection'
                                                    onChange={e => setModel(e)}
                                                ></Select>
                                            </div>
                                        </div>

                                        <div className="border border-secondary border-opacity-50 rounded-4 p-4">
                                            <h5 className="font-size-14 ">{t("Gouvernance")}:</h5>
                                            <div className="hr border mb-1 border-secondary border-opacity-25 rounded-4 " />
                                            <Row>
                                                <Col md={9}>
                                                    <div className="hstack gap-2 mb-2 form-check form-check-info">
                                                        <Input id={"checkbox_userlimit_" + uuidv4()} type="checkbox" checked={isLimitedByUser} className=" font-size-18 mb-2" onClick={e => setIsLimitedByUser(!isLimitedByUser)} />
                                                        <Label className="text-muted mt-2">{t("Limiter le nombre de requetes par utilisateur")}</Label>
                                                    </div>


                                                </Col>
                                                {isLimitedByUser && <Col md={3}>
                                                    <div className="hstack gap-2">
                                                        <input className="form-control" type="number" value={messagesQuota} onChange={e => setMessagesQuota(e.target.value)} style={{ width: 100 }} />
                                                        <Select value={messagesQuotaUnit} options={requestUnits} defaultValue={requestUnits[0]} className='select2-selection' onChange={e => setMessagesQuotaUnit(e)} style={{ width: 100 }} />

                                                    </div>


                                                </Col>}
                                            </Row>
                                            <Row>
                                                <Col md={12}>
                                                    {isLimitedByUser && <div>
                                                        <Label>Message informant de la limite:</Label>
                                                        <textarea
                                                            value={quotaStopMessage}
                                                            onChange={e => setQuotaMessage(e.target.value)}
                                                            className="form-control"
                                                            rows={5}
                                                        />
                                                    </div>}
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col md={9}>
                                                    <div className="hstack gap-2 mb-2 mt-2 form-check form-check-info">
                                                        <Input type="checkbox" checked={isLimitedByVolume} className="mb-2 font-size-18" onClick={e => setIsLimitedByVolume(!isLimitedByVolume)} />
                                                        <h6 className="text-muted mt-2">{t("Limiter la volumetrie de données par utilisateur")}</h6>
                                                    </div>
                                                </Col>
                                                {isLimitedByVolume && <Col md={3}>
                                                    <div className="hstack gap-2">
                                                        <input className="form-control" type="number" value={volumeQuota} onChange={e => setVolumeQuota(e.target.value)} style={{ width: 100 }} />
                                                        <Select value={volumeQuotaUnit} options={volumeUnits} defaultValue={volumeUnits[0]} className='select2-selection' onChange={e => setVolumeQuotaUnit(e)} style={{ width: 100 }} />
                                                    </div>

                                                </Col>}
                                            </Row>


                                        </div>

                                        <div className="border p-4 mt-4 border-secondary border-opacity-50 rounded-4 ">
                                            <h5 className="font-size-14 ">{t("Confidentialité")}:</h5>
                                            <div className="hr border mb-1 border-secondary border-opacity-25 rounded-4 " />

                                            <div className="hstack gap-2 mb-1 mt-1 form-check form-check-info">
                                                <Input type="checkbox" checked={isCustomScope} className="mb-2 font-size-18" onClick={e => setIsCustomScope(!isCustomScope)} />
                                                <h6 className="text-muted mt-2">{t("Limiter la visibilité  par utilisateur ou groupes d'utilisateurs")}</h6>

                                            </div>
                                            {isCustomScope && <div>
                                                <Select
                                                    value={selectedUsers}
                                                    options={members}
                                                    className='select2-selection'
                                                    isMulti={true}
                                                    onChange={setSelectedUsers}
                                                    placeholder="nom ou email des utisateurs ou groupes  ... "

                                                />
                                            </div>}
                                        </div>

                                        <div className="border p-4 mt-4 border-secondary border-opacity-50 rounded-4 ">
                                            <h5 className="font-size-14 ">{t("Rôle et Modération de cette assistant")}:</h5>
                                            <div className="hr border mb-2 border-secondary border-opacity-25 rounded-4 " />

                                            <div className=" ">
                                                <Label>{t("Décrivez son rôle")}:</Label>
                                                <textarea
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Exemple: Tu t'appelles Jask, un assistant conversationnel,  ton role est de ... Réponds uniquement à ..."
                                                    rows={5}
                                                    value={promptSystem}
                                                    onChange={e => setPromptSystem(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="border p-4 mt-4 border-secondary border-opacity-50 rounded-4 ">
                                            <div className="vstack gap-2">
                                                <h5 className="font-size-14 ">{t("Base de connaissances")} :</h5>
                                                <div className="hr border mb-1 border-secondary border-opacity-25 rounded-4 " />
                                                <p>{t("Cette option permet de determiner quel contenu (top K) de la base de connaissances utiliser pour répondre à la question.")} </p>
                                            </div>


                                            <Select
                                                options={topResult}
                                                value={topKStrategy}
                                                onChange={e => setTopKStrategy(e)}
                                                className='select2-selection'
                                            />

                                            {
                                                topKStrategy.value === "custom" &&
                                                <div className="hstack gap-4 mt-2">
                                                    <span className="fw-bold">Top K ({topK})</span>
                                                    <div className="w-100">
                                                        <Slider
                                                            min={topKMin}
                                                            max={topKMax}
                                                            step={1}
                                                            value={topK}
                                                            onChange={e => setTopK(e)}

                                                        />
                                                    </div>
                                                </div>
                                            }

                                        </div>

                                        <div className="border p-4 mt-4 border-secondary border-opacity-50 rounded-4 ">
                                            <div className="hstack gap-2">
                                                <h5 className="font-size-14 ">{t("Forcer l'insertion de contenu")}  :</h5>
                                            </div>

                                            <div className="hr border mb-1 border-secondary border-opacity-25 rounded-4 " />

                                            {
                                                <div>
                                                    {map(conditions, cond => {
                                                        return <DynamicContent key={cond.id} data={cond} onChange={onConditionChanged} className="mt-2" onRemove={removeCondition} />
                                                    })}
                                                    <Link to={"#!"} onClick={addCondition} className="mt-4"><i className="mdi mdi-plus me-1" />{t("Nouvelle condition")}</Link>
                                                </div>
                                            }

                                        </div>
                                    </SimpleBar>
                                </CardBody>
                            </Card>
                        </TabPane>
                    </TabContent>
                </div>


            </ModalBody>

        </div>
    </Modal>

}
export default withTranslation()(DeployAssistant);