import { DELETE_CONVERSATION_SUCCESS } from "store/assistant/actionTypes"
import { GET_CONVERSATIONS_SUCCESS, GET_CONVERSATIONS_FAILED, GET_CONVERSATION_HISTORY_SUCCESS, CREATE_CONVERSATION_SUCCESS, UPDATE_CONVERSATION_TITLE, ADD_MESSAGE_HISTORY, GET_CONVERSATION_HISTORY, DELETE_CONVERSATION, CREATE_OMNICHAT_CONVERSATION_SUCCESS, GET_OMNICHAT_CONVERSATION_HISTORY_SUCCESS, ADD_OMNICHAT_MESSAGE_HISTORY, CREATE_OMNICHAT_CONVERSATION, GET_OMNICHAT_CONVERSATION_HISTORY } from "./actionTypes"

const INIT_STATE = {
  conversation: null,
  history: [],
  error: null,
  isLoading: false,
  shoulRefresh: false,
  datasources: []
}

const omnichat = (state = INIT_STATE, action) => {
  switch (action.type) {

    case CREATE_OMNICHAT_CONVERSATION:
      return {
        ...state,
        isLoading: true,
        datasources: action.payload?.datasources || []
      }

    case GET_OMNICHAT_CONVERSATION_HISTORY:
      return {
        ...state,
        isLoading: true
      }

    case CREATE_OMNICHAT_CONVERSATION_SUCCESS:
      return {
        ...state,
        conversation: action.payload,
        history: [],
        shoulRefresh: true
      }
    case GET_OMNICHAT_CONVERSATION_HISTORY_SUCCESS:
      return {
        ...state,
        history: action.payload,
        shoulRefresh: false,
        isLoading: false,
      }

    case ADD_OMNICHAT_MESSAGE_HISTORY:

      console.log()

      return {
        ...state,
        history: [...state.history, action.payload]
      }

    case DELETE_CONVERSATION_SUCCESS:
      return {
        ...state,
        history: [],
        conversation: null
      }


    default:
      return state
  }
}

export default omnichat