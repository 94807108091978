import React, { useState } from "react";

//Lightbox
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

const ImageLightBox = ({ isOpen, onClose, image, caption }) => {


    return (
        <div className="">

            {isOpen && <Lightbox
                mainSrc={image}
                caption={caption}
                enableZoom={true}
                onCloseRequest={() => {
                    onClose()
                }}



            />}
        </div>
    )

}

export default ImageLightBox;