import React, { useEffect, useRef, useState } from "react";
import { getTime } from "helpers/to-local-date";
import { isEmpty, truncate } from "lodash";
import { Comment } from "react-loader-spinner";
import ReactMarkdown from 'react-markdown'
import { Link } from "react-router-dom";
import { Card, Spinner, UncontrolledTooltip } from "reactstrap";
import { getFileIcon, truncateFromMiddle, uuidv4 } from "utils";
import remarkGfm from "remark-gfm";
import { TaskStatus } from "constants/tasks";
import UserFeedBack from "components/Common/Feedback";
import { ResourceTypes } from "constants/general";
import ResourceReferences from "./references";
import ContentRecommandation from "./recommendations"
import rehypeRaw from 'rehype-raw'
import ResourceCollectionsViewer from "components/ResourceViewer/resourceCollectionsViewer";
import SaveContent from "components/Common/SaveContent";
import { withTranslation } from "react-i18next";
import ShareContent from "components/Common/ShareContent";
import { useSelector, useDispatch } from "react-redux";
import { showRightSidebarAction } from "store/actions";

const QuoteView = props => {
    return <div>test</div>
}
const AnswerReference = ({ location, name, page, highlight, onRefClicked, children }) => {

    const [quoteId, setQuoteId] = useState(`ref_${uuidv4()}`);
    const toggle = () => {
        setShowQuote(!showQuote);
    }

    return <span>
        <Link id={quoteId} to={"#"} target="_blank" onMouseDown={() => onRefClicked(name, location, page, highlight)} onClick={onRefClicked} >

            <span className="border rounded-2  border-secondary ps-1 pe-1 border-opacity-25 bg-opacity-10 bg-secondary">
                {getFileIcon(name, 14)} {name}
            </span>
        </Link>

        <UncontrolledTooltip
            innerClassName="border border-info border-opacity-50"
            className="quote-view-tooltip"
            placement="top" target={quoteId}

        >
            <div className="p-2 ">
                <div className="mb-4 border-bottom"> Source: {getFileIcon(name, 14)} {name}</div>
                {children}</div>
        </UncontrolledTooltip>
    </span>
}

const Answer = ({ message, agentName, agentLogo, size, onOpenAssistant, onSuggestionClicked, assistant, t, onNewSuggestion }) => {
    const [content, setContent] = useState();
    const [sources, setSources] = useState([]);
    const [previewReferences, setPreviewReferences] = useState(null);

    const isWaiting = message.status === TaskStatus.THINKING;
    const isCancelled = message.status === TaskStatus.CANCELLED;
    const isError = message.status === TaskStatus.ERROR;
    const isDoneStreaming = message.status === TaskStatus.COMPLETED || isEmpty(message.status) || isError;
    const isStreaming = message.status === TaskStatus.STARTED;
    const assistantName = message.agentName || agentName;
    const dispatch = useDispatch();

    const previewResources = (rsc) => {
        dispatch(showRightSidebarAction({
            type: ResourceTypes.DOCUMENT,
            resource: rsc
        }));
    }

    const onRefClicked = (name, location, page, highlight) => {
        const source = message.references.find(ref => ref.location === decodeURI(location) || ref.webUrl === location);
        previewResources({
            ...source,
            atPage: page,
            highlight: highlight
        })

        return;


        if (location.startsWith("http")) {
            window.open(location, "_blank");
            return;
        }

        if (!isEmpty(references)) {
            setPreviewReferences({
                name: name,
                location: references[0].location,
                pages: references.map(ref => ref.position),
                type: references[0].type
            });
        }
    }


    useEffect(() => {
        const sourceMentionned = []
        const sourceNotMentionned = []
        const llmSources = []
        let parsedContent = message.text || "";
        // (message?.references || []).forEach(ref => {

        //     if (message?.text?.includes(ref.location) || message?.text?.includes(ref.webUrl)) {

        //         if (!sourceMentionned.includes(ref.location)) {
        //             parsedContent = parsedContent.replace(ref.location, `<${ref.location}>`)
        //         }
        //         sourceMentionned.push(ref.location)
        //         llmSources.push(ref)

        //     } else {
        //         sourceNotMentionned.push(ref);
        //     }
        // });

        setContent(parsedContent);
        setSources(message.references)

    }, [message, agentName, agentLogo]);

    return <React.Fragment>
        {previewReferences && <ResourceCollectionsViewer
            name={previewReferences.name}
            type={previewReferences.type}
            providerId={previewReferences.providerId}
            providerType={previewReferences.providerType}
            location={previewReferences.location}
            pages={previewReferences.pages}
            onFinish={() => setPreviewReferences(null)}

        />}
        <div className="ctext-wrap bg-info bg-opacity-25">
            <div className="conversation-name border-bottom">
                <div className="d-flex justify-content-between">
                    <div className="hstack gap-1 mb-2 ">
                        {(message.agentLogo || agentLogo) ? (<img width={20} height={20} className=" rounded-circle " src={message.agentLogo || agentLogo} />) : (<i className="bx bx-bot font-size-14" />)}

                        <small className="mt-1">{assistantName || "Jask"}</small>
                    </div>
                    <div className="d-flex flex-row">
                        <i className=" bx bx-time me-1 font-size-12" />
                        <span className="font-size-10 ">{getTime(message.createdAt)}</span>
                    </div>
                </div>
            </div>
            {isWaiting && (
                <div className={"markdown--isStartOfStream"}>
                    <Comment
                        visible={true}
                        height="40"
                        width="40"
                        color="#212529"
                        backgroundColor="#a6b0cf"
                    />
                </div>
            )}
            {!isWaiting && <ReactMarkdown
                remarkPlugins={[remarkGfm]}
                rehypePlugins={[rehypeRaw]}
                components={{
                    // a: ({ href, children }) => {
                    //     return <AnswerReference location={href} name={children} onRefClicked={onRefClicked} />
                    // },
                    datasources: ({ children }) => {
                        return <div className="border-top border-info border-opacity-25 pt-2">

                            <h6 className="fw-bold">References:</h6>
                            <div className="vstack gap-2 p-2">
                                {children}
                            </div>
                        </div>
                    },

                    datasource: ({ name, location, page, children }) => {

                        return <AnswerReference name={name} location={location} page={page} highlight={children} onRefClicked={onRefClicked}>
                            <div className="vstack mb-2">
                                {page && <span className="pb-2 fw-bold">Page-{page}</span>}
                                <p> {children}</p>
                            </div>
                        </AnswerReference>
                    },
                    quote: ({ page, children }) => {
                        return <div className="vstack mb-2">
                            {page && <span className="pb-2 fw-bold">Page-{page}</span>}
                            < p> {children}</p>
                        </div>
                    },
                    suggestions: ({ children }) => {
                        return <React.Fragment>{children}</React.Fragment>
                    },
                    suggestion: ({ theme, children }) => {
                        // useEffect(() => {
                        //     if (isStreaming) {
                        //         onNewSuggestion(theme, children)
                        //     }
                        // }, [children])
                        return <React.Fragment></React.Fragment>
                    },
                    person: ({ name, company, title, sourceName, sourceLocation, children }) => {
                        const id = "author_" + uuidv4();
                        return <span className="p-1 rounded-2  ">
                            <span id={id} className="fw-bold bg-warning bg-opacity-25 rounded-2 ps-1 pe-1 border-warning border">{name}</span>:<span className="bg- bg-opacity-25 text-primary fst-italic">{children}</span>
                            <UncontrolledTooltip
                                innerClassName="border border-info border-opacity-50"
                                className="quote-view-tooltip"
                                placement="top" target={id}
                            >
                                <div className="p-2 ">
                                    <div className="mb-1 border-bottom"> <span className="fw-bold">Author</span>: {name}</div>
                                    <div className="mb-1 border-bottom"> <span className="fw-bold">Title</span>: {title}</div>
                                    <div className="mb-1 border-bottom"> <span className="fw-bold">Company</span>: {company}</div>

                                    {children}</div>
                            </UncontrolledTooltip>
                        </span>
                    }

                }}
                className={isWaiting ? "markdown markdown--isThinking text-wrap" : "markdown text-wrap"} >
                {content}
            </ReactMarkdown>
            }

            <div className="mt-2 border-top pt-2">
                {isCancelled && <span className="text-danger">{t("Réponse intérrompue")}</span>}
                {!isWaiting && message.references?.length > 0 && <ResourceReferences content={message?.text} onSuggestionClicked={onSuggestionClicked} references={sources} size={size} />}
                {/* {!isWaiting && message.recommendations && <ContentRecommandation onOpenAssistant={onOpenAssistant} onSuggestioClicked={onSuggestionClicked} recommendations={message.recommendations} />} */}
                {isStreaming && <div className="hstack gap-1"><Spinner size={"sm"} className="me-1 text-success font-size-10" />{assistantName ? `${assistantName} ${t("vous répond...")}` : t("Réponse en cours...")}</div>}
                {isDoneStreaming && <UserFeedBack message={message} assistant={assistant} />}
                {isDoneStreaming && (<SaveContent className="me-2" content={content} sourceId={assistant?.id} sourceName={assistant?.name} sourceType={ResourceTypes.ASSISTANTS} sourceIcon={(message.agentLogo || agentLogo)} />)}
                {isError && <div className=""><i className="mdi mdi-alert text-danger  me-1" />{t("Une erreur est survenue")}</div>}
                {isDoneStreaming && <ShareContent title={agentName} content={{ name: agentName, content: content }} />}
            </div>
        </div >
    </React.Fragment >
}

export default withTranslation()(Answer);
