import { DatasourceProviders, ResourceTypes } from "constants/general";
import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react"
import { Col, Form, Row, Spinner } from "reactstrap";
import { describeDatasource } from "service/datasources";

const BingSearch = ({ onSetConnection }) => {
    const [urls, setUrls] = useState([]);
    const [login, setLogin] = useState("https://api.bing.microsoft.com/bing/v7.0/");
    const [password, setPassword] = useState(null);
    const [filterUrls, setFilterUrls] = useState(false);
    const [isTesting, setIsTesting] = useState(false);
    const [canTest, setCanTest] = useState(false);
    const [isValid, setIsValid] = useState(false);
    const [connected, setConnected] = useState(false);

    const test = () => {

        const creds = {
            endpoint: login,
            key: password,
        };


        const request = {
            id: DatasourceProviders.BING_SEARCH,
            type: ResourceTypes.INTERNET,
            connection: {
                bing: creds
            }
        }

        setIsTesting(true);

        describeDatasource(request.id, request).then(resp => {
            setConnected(true);
        }).finally(() => {
            setIsTesting(false);
        })
    }

    useEffect(() => {
        setIsValid(!isEmpty(login) && !isEmpty(password) && connected);
        setCanTest(!isEmpty(login) && !isEmpty(password));



    }, [login, password, connected]);


    useEffect(() => {

        const creds = {
            endpoint: login,
            key: password,
        };

        onSetConnection({
            bing: creds,
            isValid: isValid
        });
    }, [isValid])

    return <div>
        <div>
            <h5>Connectez-vous à internet grâce <b>Bing Search</b></h5>
            <p>
                Veuillez fournir vos clés d'accès au service
            </p>
        </div>

        <Row>
            <Col md={6}>
                <div>
                    <Form>
                        <div className="form-floating mb-3">
                            <input type="text" className="form-control" id="clientId"
                                placeholder="" value={login}
                                onChange={e => setLogin(e.target.value)} />
                            <label htmlFor="clientId">Endpoint du service </label>
                        </div>
                    </Form>
                </div>
            </Col>
        </Row>

        <Row>
            <Col md={6}>
                <div>
                    <Form>
                        <div className="form-floating mb-3">
                            <input type="password" className="form-control" id="password"
                                placeholder="" value={password}
                                onChange={e => setPassword(e.target.value)} />
                            <label htmlFor="password">Clé d'accès </label>
                        </div>
                    </Form>
                </div>
                <div className="float-end">
                    <button onClick={test} disabled={!canTest} className="btn btn-info">
                        <div className="hstack gap-2">
                            Tester la connexion
                            {isTesting && <Spinner size={"sm"} />}
                        </div>
                    </button>
                    {connected && <i className="mdi mdi-check-circle text-success font-size-18 ms-1" />}

                </div>
            </Col>
        </Row>
        <Row>
            <Col md={6}>
                <div>
                    <input
                        type="checkbox"
                    />
                </div>

            </Col>
        </Row>
    </div>

}

export default BingSearch;