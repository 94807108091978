import React, { useEffect } from "react"
import { Badge, Card, CardBody, CardFooter, Table } from "reactstrap"
import SimpleBar from "simplebar-react"

import {
    searchResources,
} from "store/actions";
import { useSelector, useDispatch } from "react-redux";
import { isEmpty, map } from "lodash";
import { getFileIcon } from "utils";
import { Link } from "react-router-dom";
import ResourceListPlaceHolders from "./placeholders";
import { toDate, toLocalDate } from "helpers/to-local-date";

const FileSearchView = ({ items }) => {

    const getFolderName = (location) => {
        return location.split("/").slice(-1)
    }

    return <div className="table-responsive">
        <Table className="table align-middle table-nowrap table-hover mb-0">
            <thead>
                <tr>
                    <th scope="col">
                        Nom de la ressource
                    </th>
                    <th scope="col">Source</th>
                </tr>
            </thead>
            <tbody>

                {map(items || [], item => {
                    return <tr key={item.id}>
                        <td><Link to="#" className="text-dark fw-medium text-truncate mn-0">{getFileIcon(item.name, 16)} {item.name.length > 50 ? item.name.slice(0, 50) + "..." : item.name}</Link></td>
                        <td>
                            <i className="mdi mdi-folder text-warning me-2" />
                            <Link className="text-muted">{getFolderName(item.location)}</Link>
                        </td>

                    </tr>
                })}
            </tbody>
        </Table></div>
}

const WorkspaceSearchView = ({ items }) => {


    return <div className="table-responsive">
        <Table className="table align-middle table-nowrap table-hover mb-0">
            <thead>
                <tr>
                    <th scope="col">
                        Nom de la Tache
                    </th>
                    <th scope="col">
                        Date de création
                    </th>
                    <th scope="col">Propriétaire</th>
                </tr>
            </thead>
            <tbody>

                {map(items || [], item => {
                    return <tr key={item.id}>
                        <td><Link to="#" className="text-dark fw-medium text-truncate mn-0">
                            {item.content.metadata.task_name.length > 50 ? item.content.metadata.task_name.slice(0, 50) + "..." : item.content.metadata.task_name}
                        </Link></td>
                        <td>
                            <Link className="text-muted">{toDate(item.content.metadata.task_createdAt)}</Link>
                        </td>
                        <td>
                            <i className="bx bx-user text-info me-2" />
                            <Link className="text-muted">{item.content.metadata.principal_id}</Link>
                        </td>

                    </tr>
                })}
            </tbody>
        </Table></div>
}

const CRMSearchView = ({ items }) => {


    return <div className="table-responsive">
        <Table className="table align-middle table-nowrap table-hover mb-0">
            <thead>
                <tr>
                    <th scope="col">
                        Opportunité
                    </th>
                    <th scope="col">
                        Date de création
                    </th>

                    <th scope="col">Propriétaire</th>
                </tr>
            </thead>
            <tbody>

                {map(items || [], item => {
                    return <tr key={item.id}>
                        <td><Link to="#" className="text-dark fw-medium text-truncate mn-0">

                            <div className="vstack gap-1">
                                <div>
                                    {item.content.metadata.crm_oppty_title.length > 50 ? item.content.metadata.crm_oppty_title.slice(0, 50) + "..." : item.content.metadata.crm_oppty_title}
                                </div>
                                <div className="hstack gap-2">
                                    <Badge className="badge-soft-success">{item.content.metadata.crm_lead_company}</Badge>
                                    <Badge className="badge-soft-info">{item.content.metadata.crm_oppty_category}</Badge>
                                </div>
                            </div>
                        </Link></td>
                        <td>
                            <Link className="text-muted">{toDate(item.content.metadata.crm_oppty_createdAt)}</Link>
                        </td>

                        <td>
                            <i className="bx bx-user text-info me-2" />
                            <Link className="text-muted">{item.content.metadata.crm_oppty_owner}</Link>
                        </td>

                    </tr>
                })}
            </tbody>
        </Table></div>
}
const ResourcesList = ({ todo, selectedSource }) => {
    const dispatch = useDispatch();
    const { isWorking, usefulResources, searchProviderId } = useSelector(state => ({
        isWorking: state.Assistant.isWorking,
        usefulResources: state.Assistant.usefulResources,
        searchProviderId: state.Assistant.searchProviderId,
    }));

    const getView = (providerId, items) => {

        switch (providerId) {
            case "filesystem":
                return <FileSearchView items={items} />

            case "workspace":
                return <WorkspaceSearchView items={items} />

            case "crm":
                return <CRMSearchView items={items} />
        }
    }

    const refresh = () => {
        const payload = {
            provider: selectedSource.providerId,
            content: todo.content,
            sourceId: selectedSource.id
        };

        dispatch(searchResources(payload));
    }

    useEffect(() => {

        if (isEmpty(selectedSource))
            return;
        refresh();

    }, [selectedSource])

    return <React.Fragment>
        <Card className="border content-view">
            <CardBody>
                <SimpleBar className="content-view">
                    {!isWorking && selectedSource?.id && getView(searchProviderId, usefulResources)}
                    {isWorking && <ResourceListPlaceHolders />}
                </SimpleBar>
            </CardBody>
            <CardFooter>

            </CardFooter>
        </Card>
    </React.Fragment>
}

export default ResourcesList;