import { GET_FEEDBACKS, GET_FEEDBACKS_BOARD, GET_FEEDBACKS_BOARD_SUCCESS, GET_FEEDBACKS_FAILED, GET_FEEDBACKS_SUCCESS, GET_USAGES, GET_USAGES_FAILED, GET_USAGES_SUCCESS } from "./actionTypes"

export const getUsageBoard = (filters) => ({
  type: GET_USAGES,
  payload: filters
})

export const getUsageBoardSuccess = data => ({
  type: GET_USAGES_SUCCESS,
  payload: data,
})

export const getUsageBoardFailed = error => ({
  type: GET_USAGES_FAILED,
  payload: error,
})

/* Feedbacks */

export const getFeedbacksBoard = (filters) => ({
  type: GET_FEEDBACKS_BOARD,
  payload: filters
})

export const getFeedbacksBoardSuccess = (data) => ({
  type: GET_FEEDBACKS_BOARD_SUCCESS,
  payload: data
})

export const getFeedbacksBoardFailed = (error) => ({
  type: GET_FEEDBACKS_BOARD_SUCCESS,
  payload: error
})


export const getFeedbacks = (filters) => ({
  type: GET_FEEDBACKS,
  payload: filters
})

export const getFeedbacksSuccess = (data) => ({
  type: GET_FEEDBACKS_SUCCESS,
  payload: data
})

export const getFeedbacksFailed = (error) => ({
  type: GET_FEEDBACKS_FAILED,
  payload: error
})






