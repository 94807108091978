import React, { memo, ReactNode, useCallback, useEffect, useState } from 'react';
import { Handle, Position } from '@xyflow/react';
import { Link } from "react-router-dom";
import { Badge, Card, CardBody, CardFooter, CardTitle, Collapse, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import classnames from "classnames";
import { isEmpty, map } from 'lodash';
import DocumentSearchNode from './documents';
import CrmSearchNode from './crm';
import WorkspaceSearchNode from './workspace';
import InternetSearchNode from './internet';
import { getProviderLogo } from 'utils';
import { DatasourceProviders, ResourceTypes } from 'constants/general';
import SharepointSearchNode from './sharepoint';
import SingleHandle from '../../handle/SingleHandle';

const inputTypes = [
    {
        id: 'all',
        title: 'Texte et fichiers ',
        description: 'Concatenation du texte et du contenu des fichiers'
    },
    {
        id: 'textOnly',
        title: 'Texte',
        description: 'Appliquer les instructions le texte fourni'
    },
    {
        id: 'fileOnly',
        title: 'Fichiers',
        description: 'Appliquer les instructions sur chaque fichier uniquement'
    },
    {
        id: 'rowOnly',
        title: 'Ligne par ligne',
        description: 'Appliquer les instructions sur chaque ligne'
    },
]


const getStatusClassName = (status) => {

    if (!status)
        return "text-danger";
    else {
        return "text-success";
    }
}

const SearchNode = ({ llms, data, onChange, type }) => {
    const [collapsed, setCollapsed] = useState(false);
    const [nodeName, setNodeName] = useState(data.name);
    const [showCustomInstruction, setShowCustomInstructions] = useState(false);
    const [selectedModel, setSelectedModel] = useState({
        id: data?.modelId,
        title: data?.modelName
    });
    const [instructions, setInstructions] = useState(data?.instructions);
    const [nodeStatus, setNodeStatus] = useState(false);

    const SelectSearchView = ({ onDataChange }) => {
        switch (data?.providerType) {
            case ResourceTypes.FILESYSTEM:
                return <DocumentSearchNode data={data} onChange={onDataChange} />

            case ResourceTypes.SALESFORCE:
                return <CrmSearchNode data={data} onChange={onDataChange} />

            case ResourceTypes.WORKSPACE:
                return <WorkspaceSearchNode data={data} onChange={onDataChange} />

            case DatasourceProviders.SHAREPOINT:
                return <SharepointSearchNode data={data} onChange={onDataChange} />

            case DatasourceProviders.BING_SEARCH:
            case DatasourceProviders.GOOGLE_SEARCH:
                return <InternetSearchNode data={data} onChange={onDataChange} />

        }
    }

    const openCustomBox = () => {
        const state = !showCustomInstruction
        setShowCustomInstructions(state);
        if (state) {
            setCollapsed(state)
        }
    }

    const onModelChange = (model) => {
        setSelectedModel(model);
    }


    const onChildChange = (newData) => {
        onChange({
            ...data,
            ...newData,
            isValid: newData.viewState && !isEmpty(nodeName) && !isEmpty(selectedModel?.id)
        })

    }

    useEffect(() => {
        onChange({
            ...data,
            name: nodeName,
            modelId: selectedModel?.id,
            modelName: selectedModel?.title,
            instructions: instructions,
            isValid: data.viewState && !isEmpty(nodeName) && !isEmpty(selectedModel?.id)

        })
    }, [nodeName, selectedModel, instructions])

    useEffect(() => {
        setNodeStatus(data.isValid)
    }, [data]);

    useEffect(() => {

        if (isEmpty(selectedModel?.id))
            setSelectedModel(llms[0]);
    }, [selectedModel]);

    console.log(data)

    return <Card>
        <div className="accordion accordion-flush" id="accordion">
            <div className="accordion-item">
                <div className="accordion-header" id="headingOne">
                    <button
                        className={classnames(
                            "accordion-button",
                            "fw-medium",
                            { collapsed: !collapsed }
                        )}
                        type="button"
                        onClick={() => setCollapsed(!collapsed)}
                        style={{ cursor: "pointer" }}
                    >
                        <div className='vstack'>
                            <div className="title hstack gap-0">
                                {/* {data.icon && <i className={data.icon + " text-warning font-size-20"} />} */}
                                {getProviderLogo(data.providerType, 22)}
                                <input className="border-0 form-control" style={{ background: 'transparent' }} type='text' value={nodeName} onChange={e => setNodeName(e.target.value)} />

                            </div>
                            <div className="subline ms-2">

                                {nodeStatus && <div className="text-success ms-4"><small > Valide </small><i className='mdi mdi-check-circle' /></div>}
                                {!nodeStatus && <div className="text-warning ms-4"><small > Invalide </small><i className='mdi mdi-alert' /></div>}

                            </div>
                        </div>


                    </button>
                </div>

                <Collapse isOpen={collapsed}>

                    <div className="accordion-body">
                        <div className='vstack gap-1'>
                            <SelectSearchView onDataChange={onChildChange} />
                            {showCustomInstruction && <div>

                                <textarea rows={5} className='form-control' value={instructions}
                                    onChange={e => setInstructions(e.target.value)} />
                            </div>}

                        </div>

                    </div>
                </Collapse>
            </div>

            <SingleHandle nodeId={data?.id} type="target" position={Position.Left} />
            <SingleHandle nodeId={data?.id} type="source" position={Position.Right} />

        </div>
        <CardFooter className='border-top'>
            <div className='d-flex justify-content-between'>
                <div>

                    <UncontrolledDropdown className="dropup ms-1" >
                        <DropdownToggle
                            href="#"
                            tag="a" className="dropdown-toggle ">
                            <Badge className='badge badge-soft-info' > {selectedModel?.title}</Badge>
                        </DropdownToggle>
                        <DropdownMenu className="dropdown-menu-start dropup">
                            <div className="dropdown-header noti-title border-bottom">
                                <h5 className="font-size-12 text-muted text-truncate mn-0">
                                    <div className="hstack gap-1">
                                        <div className="vstack gap-1 mt-1">
                                            <span className=''>  Modèles d'IA à utiliser </span>
                                        </div>
                                    </div>
                                </h5>
                            </div>

                            {map(llms, (item, index) => {
                                return <DropdownItem key={index} className={index < inputTypes.length - 1 ? "border-bottom p-2 ps-4 " : "p-2 ps-4"}
                                    onClick={() => onModelChange(item)}
                                >
                                    <div className='vstack gap-1'>

                                        <span className="text-muted font-size-12">
                                            {item.title}
                                        </span>
                                        <small className='font-size-10'>{item.description}</small>
                                    </div>

                                </DropdownItem>
                            })}

                        </DropdownMenu>
                    </UncontrolledDropdown>
                </div>
                <div className='d-flex flex-row'>
                    <Link to={"#!"} onClick={openCustomBox}>
                        <i className=" mdi mdi-chat" />
                    </Link>

                    <UncontrolledDropdown className="dropup ms-1" >
                        <DropdownToggle
                            href="#"
                            tag="a" className="dropdown-toggle ">
                            <i className=" mdi mdi-database " />
                        </DropdownToggle>
                        <DropdownMenu className="dropdown-menu-start dropup">
                            <div className="dropdown-header noti-title border-bottom">
                                <h5 className="font-size-12 text-muted text-truncate mn-0">
                                    <div className="hstack gap-1">
                                        <div className="vstack gap-1 mt-1">
                                            <span>  Contexte d'execution </span>
                                            <small>  (Les données à traiter par cette tache)</small>
                                        </div>
                                    </div>
                                </h5>
                            </div>

                            {map(inputTypes, (item, index) => {
                                return <DropdownItem key={index} className={index < inputTypes.length - 1 ? "border-bottom p-2 ps-4 " : "p-2 ps-4"}
                                // onClick={() => resendPrompt(item.text)}
                                >
                                    <div className='vstack gap-1'>

                                        <span className="text-muted font-size-12">
                                            {item.title}
                                        </span>
                                        <small className='font-size-10'>{item.description}</small>
                                    </div>

                                </DropdownItem>
                            })}

                        </DropdownMenu>
                    </UncontrolledDropdown>
                </div>
            </div>

        </CardFooter>
    </Card>
}

export default memo(SearchNode);