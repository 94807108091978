import React, { useEffect, useState, useRef, useMemo } from "react";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import Select from "react-select";

import {
    Card,
    CardBody,
    Col,
    Container,
    Row,
    Modal,
    ModalHeader,
    ModalBody,
    Label,
    FormFeedback,
    UncontrolledTooltip,
    Input,
    Form,
    Spinner,
} from "reactstrap";

import {
    getUsers as onGetUsers,
    addGroup as onAddNewGroup,
    updateUser as onUpdateUser,
    deleteUser as onDeleteUser,
    getUsers,
    getRoles,
} from "store/actions";
import { useSelector, useDispatch } from "react-redux";
import { isEmpty, map } from "lodash";

const platformRoles = [{
    id: 'admin',
    label: 'Administrateur',
    value: 'admin'
},
{
    id: 'user',
    label: 'Utilisateur',
    value: 'user'
}]

const AddGroup = ({ isOpen, onCloseClick, isEdit }) => {

    const [group, setGroup] = useState();
    const [members, setMembers] = useState([]);
    const [userRoles, setUserRoles] = useState([]);

    const [selectedMembers, setSelectedMembers] = useState([]);
    const [selectedRoles, setSelectedRoles] = useState([]);
    const [owners, setOwners] = useState([]);
    const [canSubmit, setCanSubmit] = useState([]);
    const [isCreating, setIsCreating] = useState(false);

    const dispatch = useDispatch();

    const { users, isAdding, roles, isLoading } = useSelector(state => ({
        users: state.Users.users,
        roles: state.Users.roles,
        isAdding: state.Users.isAdding,
        isLoading: state.Users.isLoading,

    }));

    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            name: (group && group.name) || "",
        },
        validationSchema: Yup.object({
            name: Yup.string().required("Champs obligatoire"),
        }),
        onSubmit: values => {
            if (isEdit) {
                const updateUser = {
                    id: group.id,
                    name: values.name,
                    owners: values.owners,
                    members: values.members,
                    roles: values.roles,
                };
                // update user
                dispatch(onUpdateUser(updateUser));
                setIsEdit(false);
                validation.resetForm();
            } else {
                const newGroup = {
                    name: values["name"],
                    owners: owners.map(o => o.value.id),
                    members: selectedMembers.map(o => o.value.id),
                    roles: selectedRoles.map(r => r.value)
                };

                // save new user
                setIsCreating(true);
                dispatch(onAddNewGroup(newGroup));

            }
        },
    });

    useEffect(() => {
        if (!isAdding && isCreating) {
            validation.resetForm();
            setIsCreating(false);
            onCloseClick();
        }
    }, [isAdding])

    useEffect(() => {
        if (!isEmpty(users)) {
            setMembers(users.map(user => ({
                id: user.id,
                label: user.firstName + " " + user.name,
                value: user
            })))
        }
    }, [users])

    useEffect(() => {
        if (!isEmpty(roles)) {
            setUserRoles(roles.map(role => ({
                id: role.id,
                label: role.name,
                value: role
            })))
        }
    }, [roles])


    useEffect(() => {
        if (isEmpty(users)) {
            dispatch(getUsers())
        }

        if (isEmpty(roles)) {
            dispatch(getRoles())
        }
    }, [dispatch])

    useEffect(() => {
        setCanSubmit(!isEmpty(selectedMembers) && !isEmpty(selectedRoles) && !isEmpty(owners))
    }, [selectedMembers, selectedRoles, owners])
    return <Modal isOpen={isOpen} toggle={onCloseClick} centered={true} size="lg">
        <ModalHeader toggle={onCloseClick} tag="h4">
            {!!isEdit ? "Edit User" : "Ajouter un groupe"}
        </ModalHeader>
        <ModalBody>
            <Card>
                <CardBody>
                    <Form
                        onSubmit={e => {
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                        }}
                    >
                        <Row>
                            <Col xs={12}>
                                <div className="mb-3">
                                    <Label className="form-label">Nom</Label>
                                    <Input
                                        name="name"
                                        type="text"
                                        placeholder="Nom du groupe"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.name || ""}
                                        invalid={
                                            validation.touched.name &&
                                                validation.errors.name
                                                ? true
                                                : false
                                        }
                                    />
                                    {validation.touched.name &&
                                        validation.errors.name ? (
                                        <FormFeedback type="invalid">
                                            {validation.errors.name}
                                        </FormFeedback>
                                    ) : null}
                                </div>
                                <div className="mb-3">
                                    <Label className="form-label">Propriétaire</Label>
                                    <Select
                                        name="owners"
                                        label="owners"
                                        options={members}
                                        isLoading={isLoading}
                                        isMulti={true}
                                        className="select2-selection"
                                        value={owners}
                                        onChange={setOwners}
                                        touched={owners}

                                    />
                                    {validation.touched.owners &&
                                        validation.errors.owners ? (
                                        <FormFeedback type="invalid">
                                            {validation.errors.owners}
                                        </FormFeedback>
                                    ) : null}
                                </div>

                                <div className="mb-3">
                                    <Label className="form-label">Membres</Label>
                                    <Select
                                        options={members}
                                        isLoading={isLoading}
                                        isMulti={true}
                                        isClearable={true}
                                        value={selectedMembers}
                                        onChange={setSelectedMembers}
                                        className="select2-selection"
                                    />
                                </div>
                                <div className="mb-3">
                                    <Label className="form-label">Roles</Label>
                                    <Select
                                        options={userRoles}
                                        isLoading={isLoading}
                                        isMulti={true}
                                        className="select2-selection"
                                        value={selectedRoles}
                                        onChange={setSelectedRoles}
                                    />

                                </div>

                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div className="hstack gap-1 text-end">
                                    <button
                                        type="submit"
                                        className="btn btn-success save-user"
                                        disabled={!canSubmit}
                                    >
                                        Sauvegarder
                                    </button>
                                    {isAdding && <Spinner />}
                                </div>
                            </Col>
                        </Row>
                    </Form>
                </CardBody>
            </Card>
        </ModalBody>
    </Modal>
}

export default AddGroup;