import { call, put, takeLatest } from "redux-saga/effects"
import { GET_FEEDBACKS, GET_FEEDBACKS_BOARD, GET_USAGES } from "./actionTypes"
import { getUsageBoardSuccess, getUsageBoardFailed, getFeedbacksBoardSuccess, getFeedbacksBoardFailed, getFeedbacksSuccess, getFeedbacksFailed } from "./actions"
import { getUsagesBoard, getFeedbacksBoard, getFeedbacks } from "helpers/backend_helper"

function* onLoadUsage(action) {
  try {
    const response = yield call(getUsagesBoard, action.payload)
    yield put(getUsageBoardSuccess(response))
  } catch (error) {
    yield put(getUsageBoardFailed(error))
  }
}

function* onLoadFeedbacksBoard(action) {
  try {
    const response = yield call(getFeedbacksBoard, action.payload)
    yield put(getFeedbacksBoardSuccess(response))
  } catch (error) {
    yield put(getFeedbacksBoardFailed(error))
  }
}

function* onLoadFeedbacks(action) {
  try {
    const response = yield call(getFeedbacks, action.payload)
    yield put(getFeedbacksSuccess(response))
  } catch (error) {
    yield put(getFeedbacksFailed(error))
  }
}



function* monitoringSaga() {
  yield takeLatest(GET_USAGES, onLoadUsage)
  yield takeLatest(GET_FEEDBACKS_BOARD, onLoadFeedbacksBoard)
  yield takeLatest(GET_FEEDBACKS, onLoadFeedbacks)

}

export default monitoringSaga;