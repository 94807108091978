import React, { useEffect, useState } from "react"
import { Card, CardBody, CardFooter, Col, Row } from "reactstrap"
import { withTranslation } from "react-i18next";
import SimpleBar from "simplebar-react";
import { Link } from "react-router-dom";
import { isEmpty, map } from "lodash";
import { uuidv4 } from "utils";

const Settings = ({ t, source, onChange }) => {
    const [selectedUrls, setSelectedUrls] = useState((source?.settings?.internet?.urls || []).map(url => ({
        id: uuidv4(),
        location: url,
        isValid: !isEmpty(location),
    })));

    const onAddUrl = () => {

        setSelectedUrls([...selectedUrls, {
            id: uuidv4(),
            location: null,
        }]);
    }

    const onRemoveUrl = (url) => {
        const remaining = selectedUrls.filter(s => s.id !== url.id)
        setSelectedUrls([...remaining]);
    }

    const updateLink = (link, value) => {

        const links = [...selectedUrls];
        links.forEach(l => {

            if (l.id === link.id) {
                l.location = value
                l.isValid = !isEmpty(value)
            }
        });

        setSelectedUrls(links);
    }

    useEffect(() => {

        onChange({
            id: source.id,
            internet: {
                urls: selectedUrls.filter(url => !isEmpty(url.location)).map(url => url.location)
            }
        })
    }, [selectedUrls]);


    useEffect(() => {
        const urls = source?.settings?.internet?.urls || [];
        console.log(source)
        setSelectedUrls(urls.map(url => ({
            id: uuidv4(),
            location: url,
            isValid: !isEmpty(location),
        })))
        // if (urls.length !== selectedUrls?.length) {

        // }
    }, [source])

    return <div>

        <Row className="mb-3">
            <Col md={3} className=" pt-2">
                <span className="fw-bold font-size-12">{t("Filtrer les liens:")}</span>
            </Col>
            <Col md={9}>
                <div className="vstack gap-1">
                    <Card className="" style={{ height: "280px" }}>
                        <CardBody className="border  border-secondary border-opacity-25 ">
                            <SimpleBar className="scroller-browser-internet">
                                {map(selectedUrls || [], item => {
                                    return <div key={item.id} className="hstack mb-2">
                                        <input
                                            name={"url_input_" + item.id}
                                            type="text"
                                            value={item.location}
                                            className="form-control"
                                            placeholder="https://www.example.com"
                                            onChange={e => updateLink(item, e.target.value)}
                                        />

                                        <div>
                                            <Link onClick={() => onRemoveUrl(item)}><i className="mdi mdi-trash-can me-2 font-size-18 text-danger" /></Link>
                                        </div>

                                    </div>
                                })}

                            </SimpleBar>

                        </CardBody>
                        <CardFooter>
                            <Link className="font-size-10 float-end" onClick={onAddUrl}>
                                <i className="mdi mdi-plus" />{t("Nouveau lien")}
                            </Link>
                        </CardFooter>
                    </Card>
                </div>

            </Col>
        </Row>

    </div>
}

export default withTranslation()(Settings);