import { call, put, takeEvery, takeLatest } from "redux-saga/effects"
import { getDeploymentsSuccess, getDeploymentsFailed, addDeploymentSuccess, addDeploymentFailed, delDeploymentSuccess, delDeploymentFailed, addDeploymentKeySuccess, addDeploymentKeyFailed, delDeploymentKeySuccess, delDeploymentKeyFailed } from "./actions"
import { getDeployments as onGetDeployments, deleteDeployment as onDeleteDeployment, addDeployment as onAddDeployment, addDeploymentKey as onAddDeploymentKey, delDeploymentKey as onDeleteDeploymentKey } from "helpers/backend_helper"
import { ADD_DEPLOYMENT, ADD_DEPLOYMENT_KEY, DEL_DEPLOYMENT, DEL_DEPLOYMENT_KEY, GET_DEPLOYMENTS } from "./actionTypes"

function* onGetAll(action) {
  try {
    const response = yield call(onGetDeployments, action.payload)
    yield put(getDeploymentsSuccess(response))
  } catch (error) {
    yield put(getDeploymentsFailed(error))
  }
}

function* onAdd(action) {
  try {
    const response = yield call(onAddDeployment, action.payload)
    yield put(addDeploymentSuccess(response))
  } catch (error) {
    yield put(addDeploymentFailed(error))
  }
}

function* onAddKey(action) {
  try {
    const response = yield call(onAddDeploymentKey, action.payload)
    yield put(addDeploymentKeySuccess(response))
  } catch (error) {
    yield put(addDeploymentKeyFailed(error))
  }
}

function* onDelete(action) {
  try {
    const response = yield call(onDeleteDeployment, action.payload)
    yield put(delDeploymentSuccess(response))
  } catch (error) {
    yield put(delDeploymentFailed(error))
  }
}

function* onDeleteKEY(action) {
  try {
    const response = yield call(onDeleteDeploymentKey, action.payload)
    yield put(delDeploymentKeySuccess(response))
  } catch (error) {
    yield put(delDeploymentKeyFailed(error))
  }
}


function* deploymentsSaga() {
  yield takeEvery(GET_DEPLOYMENTS, onGetAll);
  yield takeEvery(ADD_DEPLOYMENT, onAdd);
  yield takeEvery(ADD_DEPLOYMENT_KEY, onAddKey);
  yield takeEvery(DEL_DEPLOYMENT, onDelete);
  yield takeEvery(DEL_DEPLOYMENT_KEY, onDeleteKEY)

}

export default deploymentsSaga;