import { ADD_SUPPORT_PLATFORM, ADD_SUPPORT_PLATFORM_FAILED, ADD_SUPPORT_PLATFORM_SUCCESS, DEL_SUPPORT_PLATFORM, DEL_SUPPORT_PLATFORM_FAILED, DEL_SUPPORT_PLATFORM_SUCCESS, GET_SUPPORT_PLATFORM, GET_SUPPORT_PLATFORM_BOARD, GET_SUPPORT_PLATFORM_BOARD_FAILED, GET_SUPPORT_PLATFORM_BOARD_SUCCESS, GET_SUPPORT_PLATFORM_FAILED, GET_SUPPORT_PLATFORM_SUCCESS, GET_SUPPORT_REQUESTS, GET_SUPPORT_REQUESTS_FAILED, GET_SUPPORT_REQUESTS_SUCCESS, UPDATE_SUPPORT_REQUESTS, UPDATE_SUPPORT_REQUESTS_FAILED, UPDATE_SUPPORT_REQUESTS_SUCCESS, } from "./actionTypes"

export const getSupportRequests = (filters) => ({
  type: GET_SUPPORT_REQUESTS,
  payload: filters
})

export const getSupportRequestsSuccess = data => ({
  type: GET_SUPPORT_REQUESTS_SUCCESS,
  payload: data,
})

export const getSupportRequestsFailed = error => ({
  type: GET_SUPPORT_REQUESTS_FAILED,
  payload: error,
})



export const updateSupportRequests = data => ({
  type: UPDATE_SUPPORT_REQUESTS,
  payload: data,
});

export const updateSupportRequestsSuccess = data => ({
  type: UPDATE_SUPPORT_REQUESTS_SUCCESS,
  payload: data,
});

export const updateSupportRequestFailed = error => ({
  type: UPDATE_SUPPORT_REQUESTS_FAILED,
  payload: error,
});


export const getSupportPlatforms = (filters) => ({
  type: GET_SUPPORT_PLATFORM,
  payload: filters
})


export const getSupportPlatformsSuccess = (data) => ({
  type: GET_SUPPORT_PLATFORM_SUCCESS,
  payload: data
})


export const getSupportPlatformsFailed = (error) => ({
  type: GET_SUPPORT_PLATFORM_FAILED,
  payload: error
})


export const addSupportPlatform = (data) => ({
  type: ADD_SUPPORT_PLATFORM,
  payload: data
})

export const addSupportPlatformSuccess = (data) => ({
  type: ADD_SUPPORT_PLATFORM_SUCCESS,
  payload: data
})

export const addSupportPlatformFailed = (error) => ({
  type: ADD_SUPPORT_PLATFORM_FAILED,
  payload: error
})


export const delSupportPlatform = (platformId) => ({
  type: DEL_SUPPORT_PLATFORM,
  payload: platformId
})

export const delSupportPlatformSuccess = (platformId) => ({
  type: DEL_SUPPORT_PLATFORM_SUCCESS,
  payload: platformId
})

export const delSupportPlatformFailed = (error) => ({
  type: DEL_SUPPORT_PLATFORM_FAILED,
  payload: error
})



export const getSupportPlatformBoard = (params) => ({
  type: GET_SUPPORT_PLATFORM_BOARD,
  payload: params
})

export const getSupportPlatformBoardSuccess = (board) => ({
  type: GET_SUPPORT_PLATFORM_BOARD_SUCCESS,
  payload: board
})


export const getSupportPlatformBoardFailed = (error) => ({
  type: GET_SUPPORT_PLATFORM_BOARD_FAILED,
  payload: error
})

