import { LogLevel } from "@azure/msal-browser";


export const b2cPolicies = {
    names: {
        signUpSignIn: "B2C_1_singup_signin",
        editProfile: "b2c_1_edit_profile_v2"
    },
    authorities: {
        signUpSignIn: {
            authority: `https://${process.env.REACT_APP_TENANT_NAME}.b2clogin.com/${process.env.REACT_APP_TENANT_NAME}.onmicrosoft.com/B2C_1_singup_signin`,
        },
        editProfile: {
            authority: `https://${process.env.REACT_APP_TENANT_NAME}.b2clogin.com/${process.env.REACT_APP_TENANT_NAME}.onmicrosoft.com/b2c_1_edit_profile_v2`
        }
    },
    authorityDomain: `${process.env.REACT_APP_TENANT_NAME}.b2clogin.com`
};

export const msalConfig = {
    auth: {
        clientId: process.env.REACT_APP_CLIENT_ID_AAD,
        authority: `https://login.microsoftonline.com/${process.env.REACT_APP_TENANT_ID}`,
        redirectUri: '/',
    },
    cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: false,
    },
};



export const msalConfigB2C = {
    auth: {
        clientId: process.env.REACT_APP_CLIENT_ID,
        authority: b2cPolicies.authorities.signUpSignIn.authority,
        knownAuthorities: [b2cPolicies.authorityDomain],
        redirectUri: "/",//window.location.href,
        postLogoutRedirectUri: '/',
        navigateToLoginRequestUrl: false
    },
    cache: {
        cacheLocation: "sessionStorage",
        storeAuthStateInCookie: false,
    },
    system: {
        loggerOptions: {
            loggerCallback: (level, message, containsPii) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    case LogLevel.Info:
                        console.info(message);
                        return;
                    case LogLevel.Verbose:
                        console.debug(message);
                        return;
                    case LogLevel.Warning:
                        console.warn(message);
                        return;
                    default:
                        return;
                }
            },
        },
    },
    // More configuration here
}

export const loginRequest = {
    scopes: [],
};


// export const protectedResources = {
//     apiTodoList: {
//         endpoint: 'http://localhost:5000/api/todolist',
//         scopes: {
//             read: ['https://fabrikamb2c.onmicrosoft.com/TodoList/ToDoList.Read'],
//             write: ['https://fabrikamb2c.onmicrosoft.com/TodoList/ToDoList.ReadWrite'],
//         },
//     },
// };

// export const loginRequest = {
//     scopes: [...protectedResources.apiTodoList.scopes.read, ...protectedResources.apiTodoList.scopes.write],
// };