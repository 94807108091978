import { post, post_form, del, get, axiosApi } from "helpers/api_helper";
import { GET_DEPLOYMENTS, GET_FEEDBACKS } from "helpers/url_helper";

export async function generateDeploymentKey(deploymentId, payload) {
    return post(`${GET_DEPLOYMENTS}/${deploymentId}/keys`, payload)
}

export async function getDeploymentKeys(deploymentId) {
    return get(`${GET_DEPLOYMENTS}/${deploymentId}/keys`)
}

export async function deleteDeploymentKey(deploymentId, keyId) {
    return del(`${GET_DEPLOYMENTS}/${deploymentId}/keys/${keyId}`)
}

export async function getResourceFeedbacks(resourceId, type) {
    return get(`${GET_FEEDBACKS}/resources/${type}/${resourceId}`)
}

