import React from "react"
import { Navigate } from "react-router-dom"

// Profile
import UserProfile from "../pages/Authentication/user-profile"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"

// Dashboard
import Dashboard from "../pages/Dashboard/index"

// Functions 

//adds on
import JaskSupportRequest from "../pages/Addons/support/requests"
import JaskSupportPlatforms from "../pages/Addons/support/platforms"
import SupportPlatformBoard from "../pages/Addons/support/board"

// workspace
import WorkspaceBoard from "../pages/Workspace/board"
import WorkspaceTasks from "../pages/Workspace/todolist"
import WorkspaceDocs from "../pages/Workspace/documents"
import WorkspaceSkills from "../pages/Workspace/skills"
import WorkspaceAssistant from "../pages/Workspace/assistant"
import WorkspaceResources from "../pages/Workspace/resources"
import WorkspacePromptStore from "../pages/Workspace/promptStore"



//Admin 
import DatasourcePanel from "../pages/Admin/datasources"
import NewDatasource from "../pages/Admin/datasources/add"
import DeploymentPanel from "../pages/Admin/deployment"
import DeploymentPanelAdd from "../pages/Admin/deployment/add"

import UserManagement from "../pages/Admin/security/users"
import GroupManagement from "../pages/Admin/security/groups"
import RolesManagement from "../pages/Admin/security/roles"

//Monitoring Tracking
import Monitoring from "../pages/Monitoring"
import Feedbacks from "../pages/Monitoring/feedbacks"

//Organization
import OrganizationBoard from "../pages/Organization/board"
import OrganizationContacts from "../pages/Organization/contacts"
import OrganizationDocumentation from "../pages/Organization/documentation"

//Studio
import AppStudio from "../pages/Studio/applications"
import AppDesigner from "../pages/Studio/designer"
import Assistants from "../pages/Studio/assistants"

//Automation
import TemplateList from "../pages/Automation/templates"
import ScenarioBuilder from "../pages/Automation/scenario"
import { PlatformRoles } from "constants/roles"
import Pages403 from "pages/Errors/pages-403"
import Pages404 from "pages/Errors/pages-404"

const authProtectedRoutes = [
  { path: "/dashboard", component: <Dashboard /> },

  // //profile
  { path: "/profile", component: <UserProfile />, role: [] },

  { path: "/organization/board", component: <OrganizationBoard /> },
  { path: "/organization/documentation", component: <OrganizationDocumentation /> },
  { path: "/organization/contacts", component: <OrganizationContacts /> },

  //Addons 

  { path: "/addons/support/platforms", component: <JaskSupportPlatforms />, role: PlatformRoles.ADDS_ON_SUPPORT },
  { path: "/addons/support/requests", component: <JaskSupportRequest />, role: PlatformRoles.ADDS_ON_SUPPORT },
  { path: "/addons/support/board", component: <SupportPlatformBoard />, role: PlatformRoles.ADDS_ON_SUPPORT },



  // { path: "/crm/offers", component: <CrmOffers /> },
  // { path: "/crm/contacts", component: <CrmContacts /> },

  { path: "/workspace/board", component: <WorkspaceBoard /> },
  { path: "/workspace/todolist", component: <WorkspaceTasks />, role: PlatformRoles.WORKSPACE },
  { path: "/workspace/documents", component: <WorkspaceDocs />, role: PlatformRoles.WORKSPACE },
  { path: "/workspace/skills", component: <WorkspaceSkills /> },
  { path: "/workspace/assistant", component: <WorkspaceAssistant />, role: PlatformRoles.ASSISTANTS },
  { path: "/workspace/resources", component: <WorkspaceResources />, role: PlatformRoles.WORKSPACE },
  { path: "/workspace/prompt/store", component: <WorkspacePromptStore />, role: PlatformRoles.WORKSPACE },

  { path: "/studio/applications", component: <AppStudio />, role: PlatformRoles.APPLICATIONS },
  { path: "/studio/assistants", component: <Assistants />, role: PlatformRoles.ASSISTANTS },
  { path: "/studio/designer", component: <AppDesigner />, role: PlatformRoles.APPLICATIONS },



  { path: "/admin/datasources", component: <DatasourcePanel />, role: PlatformRoles.DATASOURCE },
  { path: "/admin/datasources/add", component: <NewDatasource />, role: PlatformRoles.DATASOURCE },

  { path: "/admin/deployment", component: <DeploymentPanel />, role: PlatformRoles.DEPLOYMENT },
  { path: "/admin/deployment/add", component: <DeploymentPanelAdd />, role: PlatformRoles.DEPLOYMENT },

  { path: "/admin/security/users", component: <UserManagement />, role: PlatformRoles.USER_ADMIN },
  { path: "/admin/security/groups", component: <GroupManagement />, role: PlatformRoles.USER_ADMIN },
  { path: "/admin/security/roles", component: <RolesManagement />, role: PlatformRoles.USER_ADMIN },

  { path: "/monitoring", component: <Monitoring />, role: PlatformRoles.USAGE },
  { path: "/feedbacks", component: <Feedbacks />, role: PlatformRoles.USAGE },


  { path: "/automation/templates", component: <TemplateList />, role: PlatformRoles.TEMPLATES },
  { path: "/automation/scenario", component: <ScenarioBuilder />, role: PlatformRoles.SCENARIO },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: "/",
    exact: true,
    component: < Navigate to="/workspace/resources" />,
    role: PlatformRoles.WORKSPACE
  },
]

const publicRoutes = [
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },
  { path: "/pages-403", component: <Pages403 /> },
  { path: "*", component: <Pages404 /> },
  // { path: "/forgot-password", component: <ForgetPwd /> },
  // { path: "/register", component: <Register /> },
]

export { authProtectedRoutes, publicRoutes }

