import React from 'react';
import { Badge } from 'reactstrap';
import { Link } from 'react-router-dom';
import { getInitiales, getItemType, getProviderInfo, getProviderLogo } from 'utils';
import { RotatingLines } from 'react-loader-spinner';
import { AppIdentityCard } from 'components/Common/CustomSelect';
import TeamsIcon from "assets/images/providers/microsoft-teams-icon.png"
import WebIcon from "assets/images/providers/webIcon.png"


const DeploymentName = (cell) => {
    const row = cell.row.original;

    switch (row.channelId) {

        case "web":
            return <div className='hstack gap-2'><img src={WebIcon} width={25} height={25} /> <span className='fw- font-size-12'>{cell.value}</span></div>
        case "msteams":
            return <div className='hstack gap-2'><img src={TeamsIcon} width={25} height={25} /> <span className='fw- font-size-12'>{cell.value}</span></div>

    }
    return cell.value ? cell.value : "";
};
const DateValue = (cell) => {
    return cell.value ? new Date(cell.value).toLocaleDateString() : "";
};

const AppDeployment = ({ cell, assistants }) => {
    const row = cell.row.original;
    const assistant = assistants.find(a => a.id === row.applicationId);
    return <div>
        <AppIdentityCard logo={assistant?.logoUrl} name={row.applicationName} description={assistant?.description} />
    </div>
}

const FullName = (cell) => {

    return <div className='hstack'>
        <div className="avatar-xs me-3">
            <span className="avatar-title rounded-circle bg-info bg-soft text-info font-size-18">
                {getInitiales(cell.value || "")}
            </span>
        </div>
        <span>{cell.value}</span>

    </div>
};

const Status = (cell) => {
    const status = cell.value ? cell.value : "unknow";
    switch (status) {
        case "active":
            return <Badge color='success' >Valide</Badge>
        case "suspended":
            return <Badge color='danger'>Suspendu</Badge>
        case "stop":
            return <Badge color='danger'>Arretée</Badge>
        default:
            return <Badge className="badge-soft-info" >N/A</Badge>
    }
};


export { DeploymentName, Status, FullName, DateValue, AppDeployment };