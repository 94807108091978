import content from "pages/Workspace/resources/content";
import { ABORT_ASSISTANT_REQUEST, ASK_ASSISTANT, ON_ASSISTANT_REPLY, ON_CHANNEL_HISTORY } from "./actionTypes"
import { TaskStatus } from "constants/tasks";
import { EventTypes } from "constants/events";
import { messages } from "common/data";
import { isEmpty } from "lodash";

const INIT_STATE = {
  channels: {},
  liveStreamHandlerId: []
}

const streams = (state = INIT_STATE, action) => {

  switch (action.type) {

    case ON_CHANNEL_HISTORY:

      const history = action.payload;
      const channels = state.channels;
      const channelData = channels[history.channelId] || {};
      const conversationData = channelData[history.conversationId] || {};
      const updatedStreams = {
        ...channels,
        [history.channelId]: {
          ...channelData,
          [history.conversationId]: {
            ...conversationData,
            messages: [...history.messages]
          }
        }
      }

      return {
        ...state,
        channels: updatedStreams,
      }

    case ABORT_ASSISTANT_REQUEST:
      {
        const request = action.payload;
        const conversationId = request.conversationId;
        const channelId = request.channelId;


        let liveStreams = state.channels;
        let channelStreams = liveStreams[channelId];
        const conversationStreams = channelStreams[conversationId];
        let historical = conversationStreams.messages;

        historical.forEach(msg => {
          if (msg.status == TaskStatus.STARTED) {
            msg.status = TaskStatus.CANCELLED;
          }
        });

        liveStreams = {
          ...liveStreams,
          [channelId]: {
            ...channelStreams,
            isTyping: false,
            lastUpdate: new Date(),
            [conversationId]: {
              ...conversationStreams,
              messages: [...historical]
            }
          }
        }

        return {
          ...state,
          channels: liveStreams,
          liveStreamHandlerId: [...state.liveStreamHandlerId, channelId]
        }
      }

    case ASK_ASSISTANT:
      {
        const request = action.payload;
        let liveStreams = state.channels;
        let channelStreams = liveStreams[request.channelId] || {};
        let conversationStreams = channelStreams[request.conversationId] || {};

        liveStreams = {
          ...liveStreams,
          [request.channelId]: {
            ...channelStreams,
            [request.conversationId]: {
              ...conversationStreams,
              messages: request.isSearch === true ? conversationStreams.messages : [...(conversationStreams.messages || []), request.message],
              cancellationToken: request.cancellationToken,
              suggestions: [],
              search: conversationStreams.search || "",
              searchFilters: conversationStreams.searchFilters || [],
              searchContext: conversationStreams.searchContext || [],
              searchTaskId: request.isSearch ? request.taskId : conversationStreams.searchTaskId,
              isSearch: request.isSearch,
              searchRequest: request.isSearch ? request.message.text : conversationStreams.searchRequest,
              status: TaskStatus.WAITING
            }
          }
        }
        return {
          ...state,
          channels: liveStreams,
          liveStreamHandlerId: [...state.liveStreamHandlerId, request.channelId]
        }
      }

    case ON_ASSISTANT_REPLY:
      const event = action.payload.event;
      const messageId = event.id;
      const channelId = action.payload.channelId;
      const conversationId = action.payload.conversationId;
      let liveStreams = { ...state.channels };
      const channelStreams = liveStreams[channelId];
      const status = getStreamTAskStatus(event.type);
      const conversationStreams = channelStreams[conversationId];
      let historical = conversationStreams.messages;

      //last streamMessage: 

      if (!conversationStreams.isSearch) {
        let lastStreamMessage = historical.find(m => m.id === messageId)

        if (isEmpty(lastStreamMessage)) {
          historical.push({
            id: event.id,
            text: "",
            recommendations: [],
            status: status,
            references: [],
            role: "assistant",
            conversationId: conversationId,
            createdAt: new Date().toISOString(),
            agentName: event.agentName,
            agentLogo: event.agentLogo,

          });
        } else {
          //update reply
          const reply = {
            ...lastStreamMessage,

            text: event.type === EventTypes.STREAMING ? lastStreamMessage.text + event.value : lastStreamMessage.text,
            agentName: event.type === EventTypes.START ? event.agentName : lastStreamMessage.agentName,
            agentLogo: event.type === EventTypes.START ? event.agentLogo : lastStreamMessage.agentLogo,
            recommendations: event.type === EventTypes.CONTENT_RECOMMANDATIONS ? event.value : lastStreamMessage.recommendations,
            suggestions: event.type === EventTypes.SUGGESTIONS ? event.value : lastStreamMessage.suggestions,
            status: status,
            references: event.type === EventTypes.CONTEXT ? event.value : lastStreamMessage.references,
            role: "assistant",
            conversationId: conversationId,

          }

          const index = historical.findIndex(m => m.id === event.id);
          historical[index] = reply;
        }
      }

      //update streams
      liveStreams = {
        ...liveStreams,
        [channelId]: {
          ...channelStreams,
          isTyping: status === TaskStatus.STARTED,
          lastUpdate: new Date(),
          [conversationId]: {
            ...conversationStreams,
            messages: [...historical],
            suggestions: event.type === EventTypes.SUGGESTIONS ? event.value : conversationStreams.suggestions,
            search: event.type === EventTypes.SEARCH_RESULT ? conversationStreams.search + event.value : conversationStreams.search,
            searchFilters: event.type === EventTypes.SEARCH_FILTERS ? mergeFilters(conversationStreams.searchFilters, event.value) : conversationStreams.searchFilters,
            searchContext: event.type === EventTypes.SEARCH_CONTEXT ? mergeSearchContext(conversationStreams.searchContext, event.value) : conversationStreams.searchContext,
            isSearching: (status === TaskStatus.STARTED || status === TaskStatus.THINKING) && conversationStreams.isSearch === true,
            status: status,
          }
        }
      }

      return {
        ...state,
        channels: liveStreams,
        liveStreamHandlerId: [...state.liveStreamHandlerId, channelId]
      }


    default:
      return state
  }
}

const mergeSearchContext = (oldContext, newContext) => {

  const old_ids = oldContext.map(old => old.id);
  const final = [...oldContext];

  newContext.forEach(_new => {
    if (!old_ids.includes(_new.id))
      final.push(_new);
  })

  return final;
}

const mergeFilters = (oldFilters, newFilters) => {
  const current = { ...oldFilters };

  Object.keys(newFilters).forEach(type => {
    if (!current[type]) {
      current[type] = newFilters[type].map(f => ({ ...f, isNew: true }));
    } else {
      const oldFilters = current[type].map(old => ({ ...old, isNew: false }));
      const oldValues = oldFilters.map(i => i.value);
      const toAdd = newFilters[type].filter(f => !oldValues.includes(f.value)).map(f => ({ ...f, isNew: true }));
      current[type] = [...oldFilters, ...toAdd]
    }
  })
  return current;
}

const getStreamTAskStatus = (eventype) => {
  switch (eventype) {

    case EventTypes.MESSAGE_RECEIVED:
      return TaskStatus.THINKING;

    case EventTypes.END:
      return TaskStatus.COMPLETED;

    case EventTypes.ERROR:
      console.log("ERROR OCCURED")
      return TaskStatus.ERROR;

    default:
      return TaskStatus.STARTED
  }
}

export default streams;