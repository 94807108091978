import { ASSISTANT_STATE_CHANGED, CREATE_STUDIO_APP, CREATE_STUDIO_APP_FAILED, CREATE_STUDIO_APP_SUCCESS, DEL_STUDIO_APP, DEL_STUDIO_APP_SUCCESS, GET_STUDIO_APP, GET_STUDIO_APP_FAILED, GET_STUDIO_APP_SUCCESS } from "./actionTypes"

export const getStudioApps = (payload) => ({
  type: GET_STUDIO_APP,
  payload: payload
})

export const getStudioAppsSuccess = (payload) => ({
  type: GET_STUDIO_APP_SUCCESS,
  payload: payload
})

export const getStudioAppsFailed = (payload) => ({
  type: GET_STUDIO_APP_FAILED,
  payload: payload
})



export const addPlatformApp = (data) => ({
  type: CREATE_STUDIO_APP,
  payload: data
})

export const addStudioAppSuccess = (payload) => ({
  type: CREATE_STUDIO_APP_SUCCESS,
  payload: payload
})

export const addStudioAppFailed = (payload) => ({
  type: CREATE_STUDIO_APP_FAILED,
  payload: payload
})


export const delStudioApp = (payload) => ({
  type: DEL_STUDIO_APP,
  payload: payload
})

export const delStudioAppSuccess = (payload) => ({
  type: DEL_STUDIO_APP_SUCCESS,
  payload: payload
})

export const delStudioAppFailed = (payload) => ({
  type: DEL_STUDIO_APP_SUCCESS,
  payload: payload
})


export const onAssistantStateChanged = (payload) => ({
  type: ASSISTANT_STATE_CHANGED,
  payload: payload
})