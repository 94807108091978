import React, { createRef, useEffect, useState } from "react";
import { TaskStatus } from "constants/tasks";
import { Badge, Card, CardBody, CardTitle, Spinner } from "reactstrap";
import SimpleBar from "simplebar-react";
import { isEmpty, map } from "lodash";
import { getFileIcon, getProviderInfo, getServiceInfo, truncateFromMiddle } from "utils";
import GenerationTextArea from "components/Assistant/Generation";
import { Link } from "react-router-dom";

const StreamFIelds = {
    APP_STEPS: "substeps",
    LINEAGE: "lineage"
}

const AppStreamView = ({ streams, file }) => {

    const [lineage, setLineage] = useState([]);

    useEffect(() => {
        const steps = (streams || {})[StreamFIelds.LINEAGE] || [];

        const stepWithData = steps.map(step => {

            const stepContent = streams[StreamFIelds.APP_STEPS][step.id];

            return {
                name: step.name,
                text: stepContent?.text,
                providerId: step.providerId || step.type,
                model: step?.modelName,
                isRunning: stepContent?.status === TaskStatus.STARTED,
                isCompleted: stepContent?.status === TaskStatus.COMPLETED,
                isPending: stepContent?.status === TaskStatus.NOT_STARTED,
                context: stepContent?.context || []
            }

        });

        setLineage(stepWithData);

    }, [streams])

    return <div>
        {map(lineage, (step, index) => {

            const providerInfo = getServiceInfo(step.providerId || step.type);

            return <div>

                <div className="  mb-2 rounded-3 p-2 pt-3 ps-4 item-banner">
                    <div className=" d-flex justify-content-between">
                        <div className="d-flex flex-row">
                            <h5 className="text-muted font-size-16">{index + 1}. {step.name} <Badge color="info" className="ms-1">{step?.model}</Badge></h5>
                            {providerInfo.name && <div>
                                <i className="bx bx bx-right-arrow-alt ms-2 font-size-20" />
                            </div>}
                            {providerInfo.name && <div className="ms-2">
                                <Badge color="danger" >{providerInfo.name}</Badge>
                            </div>}
                        </div>
                        <div>
                            <div>
                                {step.isRunning && <Spinner className="ms-2 text-success" color="primary" size="sm" />}
                            </div>
                            <div>
                                {step.isCompleted && <div className="hstack gap-1">
                                    <i className="mdi mdi-check-circle text-success font-size-16" />
                                </div>}
                            </div>
                            <div>
                                {step.isPending && <Badge color="primary" >En attente</Badge>}
                            </div>

                        </div>
                    </div>
                    <div className="ms-2"><Link className="font-size-10"> Voir la description</Link></div>
                </div>
                <div>
                    <GenerationTextArea text={step.text} isGenerating={step.isRunning} />
                </div>

                <div>
                    {step.context && <div>
                        {!isEmpty(step.context) && <h6>Références:</h6>}
                        <ul>
                            {map(step.context, ref => {

                                return <li><Link>
                                    <div className="vstack gap-1">
                                        <span>{ref.name}</span>
                                        <div className="hstack gap-2">
                                            <Badge className="badge badge-soft-info"> {ref.source}</Badge>
                                            <Badge className="badge badge-soft-info"> {ref.owner}</Badge>
                                            {
                                                map(Object.values(ref.tags), tag => {
                                                    return <Badge className="badge badge-soft-danger"> {tag}</Badge>
                                                })
                                            }
                                        </div>
                                    </div>
                                </Link>
                                </li>
                            })}
                        </ul>
                    </div>

                    }
                </div>

            </div>

        })}
    </div>
}

export default AppStreamView;