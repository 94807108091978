import React, { useEffect, useState } from "react"
import { withTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Alert, Label } from "reactstrap";
import CustomSelect, { AppIdentityCard } from "components/Common/CustomSelect";
import { ResourceTypes } from "constants/general";
import { isEmpty } from "lodash";
import { createFilesFromText } from "service/documents";
import { OperationStatus } from "constants/tasks";
import { AddFilesToAssistant } from "service/assistant";


const ShareWithAssistant = ({ data, directory, onConfigChange, t }) => {
    const [applications, setApplications] = useState(null);
    const [selectedApp, setSelectedApp] = useState(null);

    const { assistants } = useSelector(state => ({
        assistants: state.Studio.assistants,
    }));

    const onSubmit = (config) => {
        onConfigChange({ ...config, isLoading: true });
        const files = config.documents.map(doc => ({
            content: doc.content,
            name: `${config.contextName || ""}_${doc.name.split(".")[0]}_${new Date().getTime()}.txt`,
            format: "text"
        }));

        createFilesFromText(config.directory, files).then(locations => {

            const request = {
                assistantId: config.assistant.id,
                assistantOwnerId: config.assistant.principalId,
                files: locations
            }

            AddFilesToAssistant(request).then(resp => {
                onConfigChange({
                    ...config, isLoading: false,
                    operationStatus: OperationStatus.SUCCESS,
                })
            })

        }).catch(error => {
            onConfigChange({
                ...config, isLoading: false,
                operationStatus: OperationStatus.ERROR,
                error: error
            });
        });

    }

    useEffect(() => {
        const assistantItems = (assistants || []).map(app => ({ value: app, label: app.name, type: ResourceTypes.APPLICATIONS, logo: app.logoUrl, description: app.description }));
        setApplications(assistantItems);
    }, [assistants]);

    useEffect(() => {
        console.log(data);
        onConfigChange({
            assistant: selectedApp?.value,
            isValid: !isEmpty(selectedApp),
            documents: data?.parts,
            contextName: data?.name,
            directory: `workspace/assistants/${selectedApp?.label}/content/shared`,
            onSubmit: onSubmit
        })
    }, [selectedApp, data])


    return <div>

        <div className="mb-3">
            <Label>{t("Avec quel assistant souhaitiez-vous partagé ce contenu?")}</Label>
            <CustomSelect
                options={applications}
                className="select2-selection text-muted"
                value={selectedApp}
                onChange={setSelectedApp}
                isMulti={false}
                isClearable={true}
            />


        </div>
        {selectedApp?.value && <div>
            <Alert color="warning">
                <i className="mdi mdi-information  font-size-18 me" /> <b>{t("Note Importante")}: </b> {t("Ce contenu sera rajouté dans la base de connaissance de cette assistant et donc accessible dans toutes conversation avec cet assistant.")}
            </Alert>

        </div>}
    </div>
};

export default withTranslation()(ShareWithAssistant);
