export const ResourceTypes = {
    APPLICATIONS: "applications",
    FILESYSTEM: "filesystem",
    TEMPLATES: "templates",
    ASSISTANTS: "assistants",
    DATASOURCES: "datasources",
    WEB: "web",
    DIRECTORY: "directory",
    FILE: "file",
    TASK: 'task',
    FileTask: 'fileTask',
    CRM: 'crm',
    CRM_OBJECT: 'crmObject',
    SALESFORCE: 'salesforce',
    INTERNET: 'internet',
    AGENT_ASSISTANT: 'agent',
    FILES: "files",
    WORKSPACE: "workspace",
    SUPPORT: "support",
    RESOURCE_PROFILE: "resourceProfile",
    DOCUMENT: "document",
    SEARCH: "search",
    CONVERSATIONS: "CONVERSATIONS"

}

export const FileExtensions = [{
    label: "PowerPoint",
    value: "ppt"
},
{
    label: "Word",
    value: "word"
}, {
    label: "Excel",
    value: "excel"
}, {
    label: "Csv",
    value: "csv"
},
{
    label: "Texte",
    value: "text"
}
]

export const DatasourceProviders = {
    SALESFORCE: "salesforce",
    SHAREPOINT: "sharepoint",
    BING_SEARCH: "bingSearch",
    GOOGLE_SEARCH: "googleSearch",
    AZURE: "azure",
    LOCAL: "local",
    WEB: "web",
    FILESYSTEM: "filesystem",
    JIRA: "jira",

}


export const DefaultText = {
    QUOTA_REACHED: "Vous avez atteint les quotas disponibles. Veuillez demander ue augmentation pour continuer la conversation."
}

export const ChartTypes = {
    PIE: "pie",
    BAR: "bar",
    LINE: "line"
}

export const AssistantTypes = {
    RAG: "rag",
    SEARCH: "search",
    AGENT: "agent"
}