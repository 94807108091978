import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
    Table,
    DropdownMenu,
    DropdownToggle,
    UncontrolledDropdown,
    DropdownItem,
    Card,
    CardBody,
    Button,
} from "reactstrap"

const RequestsListPlaceHolders = ({ t }) => {


    return <React.Fragment>
        <Card className="addons-support">
            <div className="table-responsive">
                <Table className="table align-middle table-nowrap table-hover mb-0">
                    <thead>
                        <tr>
                            <th scope="col"></th>
                            <th scope="col" >Résumé</th>
                            <th scope="col" >Crée par</th>
                            <th scope="col" >status</th>
                            <th scope="col" >Equipe</th>
                            <th scope="col" >Plateforme</th>
                            <th scope="col" >Nom de la source</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr className="placeholder-glow">
                            <td><span className="placeholder col-12"></span></td>
                            <td><span className="placeholder col-12"></span></td>
                            <td><span className="placeholder col-12"></span></td>
                            <td><span className="placeholder col-12"></span></td>
                            <td><span className="placeholder col-12"></span></td>
                            <td><span className="placeholder col-12"></span></td>
                            <td><span className="placeholder col-12"></span></td>
                        </tr>
                        <tr className="placeholder-glow">
                            <td><span className="placeholder col-12"></span></td>
                            <td><span className="placeholder col-12"></span></td>
                            <td><span className="placeholder col-12"></span></td>
                            <td><span className="placeholder col-12"></span></td>
                            <td><span className="placeholder col-12"></span></td>
                            <td><span className="placeholder col-12"></span></td>
                            <td><span className="placeholder col-12"></span></td>
                        </tr>

                        <tr className="placeholder-glow">
                            <td><span className="placeholder col-12"></span></td>
                            <td><span className="placeholder col-12"></span></td>
                            <td><span className="placeholder col-12"></span></td>
                            <td><span className="placeholder col-12"></span></td>
                            <td><span className="placeholder col-12"></span></td>
                            <td><span className="placeholder col-12"></span></td>
                            <td><span className="placeholder col-12"></span></td>
                        </tr>
                        <tr className="placeholder-glow">
                            <td><span className="placeholder col-12"></span></td>
                            <td><span className="placeholder col-12"></span></td>
                            <td><span className="placeholder col-12"></span></td>
                            <td><span className="placeholder col-12"></span></td>
                            <td><span className="placeholder col-12"></span></td>
                            <td><span className="placeholder col-12"></span></td>
                            <td><span className="placeholder col-12"></span></td>
                        </tr>
                        <tr className="placeholder-glow">
                            <td><span className="placeholder col-12"></span></td>
                            <td><span className="placeholder col-12"></span></td>
                            <td><span className="placeholder col-12"></span></td>
                            <td><span className="placeholder col-12"></span></td>
                            <td><span className="placeholder col-12"></span></td>
                            <td><span className="placeholder col-12"></span></td>
                            <td><span className="placeholder col-12"></span></td>
                        </tr>
                        <tr className="placeholder-glow">
                            <td><span className="placeholder col-12"></span></td>
                            <td><span className="placeholder col-12"></span></td>
                            <td><span className="placeholder col-12"></span></td>
                            <td><span className="placeholder col-12"></span></td>
                            <td><span className="placeholder col-12"></span></td>
                            <td><span className="placeholder col-12"></span></td>
                            <td><span className="placeholder col-12"></span></td>
                        </tr>
                        <tr className="placeholder-glow">
                            <td><span className="placeholder col-12"></span></td>
                            <td><span className="placeholder col-12"></span></td>
                            <td><span className="placeholder col-12"></span></td>
                            <td><span className="placeholder col-12"></span></td>
                            <td><span className="placeholder col-12"></span></td>
                            <td><span className="placeholder col-12"></span></td>
                            <td><span className="placeholder col-12"></span></td>
                        </tr>
                        <tr className="placeholder-glow">
                            <td><span className="placeholder col-12"></span></td>
                            <td><span className="placeholder col-12"></span></td>
                            <td><span className="placeholder col-12"></span></td>
                            <td><span className="placeholder col-12"></span></td>
                            <td><span className="placeholder col-12"></span></td>
                            <td><span className="placeholder col-12"></span></td>
                            <td><span className="placeholder col-12"></span></td>
                        </tr>


                    </tbody>
                </Table>

            </div>
        </Card>

    </React.Fragment>
}

export default RequestsListPlaceHolders;