import React, { useEffect, useState } from "react";
import {
    Badge,
    Card,
    CardBody,
    CardFooter,
    CardTitle,
    Container
} from "reactstrap";

import { withTranslation } from "react-i18next";
import SimpleBar from "simplebar-react"
import { getResourceFeedbacks } from "service/admin";
import { map } from "lodash";

import FeedbackProfile from "../profile/feedback"

const ResourceProfile = ({ resource, onClose, t }) => {

    const [feedbacks, setFeedbacks] = useState([])

    useEffect(() => {
        getResourceFeedbacks(resource.id, resource.type).then(data => {
            setFeedbacks(data);
        })
    }, [resource])


    return <div className="resource-profile">
        <div className="d-flex justify-content-between bg-danger bg-opacity- text-white">
            <div className="p-2">
                <div className="hstack gap-2">
                    {resource.logoUrl && <img src={resource.logoUrl} width={60} height={60} />}

                    <div className={!resource.logoUrl ? "ms-2" : ""}>
                        <span className="font-size-16 fw-bold">{resource.name}</span>
                        <p>{resource.description}</p>
                    </div>

                </div>

            </div>

            <div className="p-2">
                <button className="btn btn-close text-white" onClick={onClose} />
            </div>
        </div>

        <div className="d-flex   p-3">
            <div className="p-2 w-100">
                <Card className="mini-stats-wid bg-info bg-opacity-25">
                    <CardBody>
                        <div className="d-flex">
                            <div className="flex-grow-1">
                                <p className="text-muted fw-medium">
                                    {t("Requetes ")}
                                </p>

                                <h4 className="mb-0">
                                    <div className="hstack gap-1">
                                        <i className={resource.icon} />
                                        <span>{resource.totalRequests} {resource.suffix}</span>
                                    </div>
                                </h4>

                            </div>
                        </div>
                    </CardBody>
                </Card>
            </div>

            <div className="p-2 w-100">
                <Card className="mini-stats-wid bg-info bg-opacity-25">
                    <CardBody>
                        <div className="d-flex">
                            <div className="flex-grow-1">
                                <p className="text-muted fw-medium">
                                    {t(" Positives")}
                                </p>

                                <h4 className="mb-0">
                                    <div className="hstack gap-1">
                                        <i className="mdi mdi-thumb-up font-size-18 text-success" />
                                        <span>{resource.totalPositive} {resource.suffix}</span>
                                    </div>
                                </h4>

                            </div>
                        </div>
                    </CardBody>
                </Card>
            </div>
            <div className="p-2 w-100">
                <Card className="mini-stats-wid bg-info bg-opacity-25">
                    <CardBody>
                        <div className="d-flex">
                            <div className="flex-grow-1">
                                <p className="text-muted fw-medium">
                                    {t(" Negatives")}
                                </p>

                                <h4 className="mb-0">
                                    <div className="hstack gap-1">
                                        <i className="mdi mdi-thumb-down font-size-18 text-danger" />
                                        <span>{resource.totalNegative} {resource.suffix}</span>
                                    </div>
                                </h4>

                            </div>
                        </div>
                    </CardBody>
                </Card>
            </div>

            <div className="p-2 w-100">
                <Card className="mini-stats-wid bg-info bg-opacity-25">
                    <CardBody>
                        <div className="d-flex">
                            <div className="flex-grow-1">
                                <p className="text-muted fw-medium">
                                    {t(" Neg. / Total")}
                                </p>

                                <h4 className="mb-0">
                                    <div className="hstack gap-1">
                                        {/* <i className="mdi mdi-thumb-down font-size-18 text-danger" /> */}
                                        <span>{new Intl.NumberFormat('fr-FR').format(resource.ratio * 100)} %</span>
                                    </div>
                                </h4>

                            </div>
                        </div>
                    </CardBody>
                </Card>
            </div>


        </div>

        <div className="p-3">
            <h5 className="border-bottom border-secondary border-opacity-25 pb-2">{t("Feedbacks les plus récents:")}</h5>

            <div className="mt-3">
                <SimpleBar className="scroller">
                    {
                        map(feedbacks, item => {
                            return <FeedbackProfile item={item} />
                        })
                    }

                </SimpleBar>
            </div>
        </div>
    </div>
};


export default withTranslation()(ResourceProfile);
