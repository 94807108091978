import React, { memo, useEffect, useRef, useState } from "react";
import PdfViewer from "components/DocumentViewer/pdfViewer";
import { Card, CardBody, Modal, ModalHeader, Spinner } from "reactstrap";
import { getFileIcon, truncateFromMiddle } from "utils";
import { useIsVisible } from "hooks/visibility";

export const FileItemViewer = memo(function ItemViewer({ resource, width, height }) {
    const ref = useRef();
    const isVisible = useIsVisible(ref);
    const [shouldLoad, setShouldLoad] = useState(false);

    useEffect(() => {
        if (isVisible) {
            setShouldLoad(true);
        }
    }, [isVisible, resource])


    return <div ref={ref} >
        {shouldLoad && <PdfViewer
            location={resource?.location}
            page={resource?.position}
            pages={resource?.pages}
            width={width}
            height={height}
            owner={resource?.owner}
            providerId={resource?.providerId}
            providerType={resource?.providerType}

        />}

    </div>
})
const ResourceViewer = memo(function ResourceViewer({ resource, size }) {
    const [open, setOpen] = useState(false);
    const [panelSize, setPanelSize] = useState(100);

    useEffect(() => {
        if (size === "sm") {
            setPanelSize(50);
        }
    }, [size])

    return <Card className="chat-references" style={{ cursor: 'pointer' }} onClick={() => setOpen(!open)}>
        <Modal isOpen={open}

            toggle={() => setOpen(!open)}
            contentClassName="modal"
            centered
            size="lg"

        >
            <div className="modal-header">
                <h5>{resource.name}</h5>
            </div>
            <FileItemViewer resource={resource} />
        </Modal>

        <div className="p-2 text-break">
            <div className="vstack gap-1">
                <FileItemViewer resource={resource} width={panelSize} height={panelSize} />
                <div className="hstack">{getFileIcon(resource.name, 14)}<span className="font-size-10">{truncateFromMiddle(resource.name, 30)}</span>
                    -  <span className="text-muted font-size-10">Page {resource.position}</span>
                </div>

            </div>
        </div>

    </Card>
});


export default ResourceViewer;