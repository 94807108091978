

import React, { useEffect, useState } from "react";
import SimpleBar from "simplebar-react"

//i18n
import { withTranslation } from "react-i18next";
import { isEmpty, map } from "lodash";
import { generateContent } from "service/documents";
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import reHype from 'remark-rehype'
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

const DocumentContent = ({ task, structure }) => {
    const [content, setContent] = useState("");
    const [sections, setSections] = useState([]);
    const [currentSection, setCurrentSection] = useState("");
    const { accessToken } = useSelector(state => ({
        accessToken: state.Login.accessToken,
    }));

    const onReception = (parsedEvent) => {

        switch (parsedEvent.type) {
            case "beginSection":
                setContent(prev => prev + "\n #### " + parsedEvent.value.text + " \n")
                break;
            case "endSection":
                setSections([...sections, parsedEvent.value])
                break;

            case "content":
                setContent(prev => prev + parsedEvent.value);
                break;

            case "end":
                break;
        }
    }
    const onGenerate = () => {

        const payload = {
            ...task,
            sections: structure,
            type: "content"
        }

        generateContent(payload, onReception, null, null, accessToken);

    }

    return (<div className=" border workspace-document-maincontent">
        <div className="d-flex flex-row-reverse border-bottom mt-2 pb-3">
            <div className="hstack gap-2">
                <Link className=""> <i className="mdi mdi-content-save font-size-18" /></Link>
                <Link className=""> <i className="mdi mdi-pencil-box font-size-18" /></Link>
                <Link className=""> <i className="mdi mdi-close font-size-18 text-danger" /></Link>

            </div>

        </div>
        <SimpleBar className="rich-text-editor ">

            {isEmpty(content) && <div className="d-flex justify-content-center flex-column align-items-center  confirm-structure-panel" >
                <div className="d-flex flex-column align-items-center mb-4">
                    <h5 className="text-muted font-size-18">La structure vous convient ? </h5>
                    <small style={{ width: 250, textAlign: "center" }}> Vous pouvez désactiver ou modifier les éléments qui ne vous conviennent pas</small>
                </div>
                <div>
                    <button className="btn btn-success me-2 font-size-14" onClick={onGenerate} >Oui, Génères le contenu </button>
                    <button className="btn btn-secondary  font-size-14" >Non, Changes de plan </button>
                </div>
            </div>}

            {content &&
                <div className="p-4"> <ReactMarkdown remarkPlugins={[remarkGfm, reHype]}>{content}</ReactMarkdown>
                </div>}
        </SimpleBar>
    </div>
    );
};


export default withTranslation()(DocumentContent);

