import React, { useEffect, useState, useRef } from "react"
import { runCustomAppOverDocuments } from "service/studio";
import SimpleBar from "simplebar-react";
import { isEmpty, map } from "lodash";
import { Link } from "react-router-dom";
import { Badge, Spinner } from "reactstrap";
import { getServiceInfo } from "utils";
import ExecutionLandingPage from "./starter";
import { GET_BOARD_EXEC_PLAN } from "helpers/url_helper";
import { post } from "helpers/api_helper";
import GenerationTextArea from "components/Assistant/Generation";
import { useSelector, useDispatch } from "react-redux";
import ApplicationStepView from "components/ApplicationRunner/stepView";
import { TaskStatus } from "constants/tasks";

// const AppStepView = ({ item, stepContent, text, context, status, isRunning, providerInfo, model, index }) => {
//     const [collapsed, setCollapsed] = useState(true);

//     useEffect(() => {
//         if (isRunning === true && collapsed)
//             setCollapsed(false);
//     }, [isRunning])

//     return <div key={item.id} className="d-flex flex-column border-secondary border border-opacity-25 rounded-3" >
//         <div className="  mb-2 rounded-3 p-2  ps-4 item-banner bg-secondary bg-opacity-25 " onClick={() => setCollapsed(!collapsed)}>
//             <div className=" d-flex justify-content-between ">
//                 <div className="d-flex flex-row">
//                     <h5 className="text-muted font-size-16">{index + 1}. {item.name} <Badge color="info" className="ms-1">{model?.name}</Badge></h5>
//                     {providerInfo.name && <div>
//                         <i className="bx bx bx-right-arrow-alt ms-2 font-size-20" />
//                     </div>}
//                     {providerInfo.name && <div className="ms-2">
//                         <Badge color="danger" >{providerInfo.name}</Badge>
//                     </div>}
//                 </div>
//                 <div>
//                     <div className="hstack gap-1">

//                         <div>
//                             {status === 'running' && <Spinner className="ms-2 text-success" color="primary" size="sm" />}
//                         </div>
//                         <div>
//                             {status === 'complete' && <div className="hstack gap-1">
//                                 <i className="mdi mdi-check-circle text-success font-size-16" />
//                             </div>}
//                         </div>
//                         <div>
//                             {isEmpty(status) && <Badge color="primary" >En attente</Badge>}
//                         </div>
//                         <div>
//                             <Link onClick={() => setCollapsed(!collapsed)}>{collapsed ? <i className="mdi mdi-chevron-right font-size-20" /> : <i className="mdi mdi-chevron-down font-size-20" />}</Link>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//             <div className="ms-2"><Link className="font-size-10"> Voir la description</Link></div>


//         </div>
//         {!collapsed && <div >

//             {text && <div className="mb-2 p-2">
//                 <GenerationTextArea text={text} isGenerating={isRunning} />
//             </div>}

//             <div>
//                 {
//                     context && (
//                         <div className="">
//                             {text && <h6>Références:</h6>}
//                             <ul className="list-unstyled ">
//                                 {
//                                     map(context, ref => {
//                                         return <li><Link>
//                                             <div className="vstack gap-1">
//                                                 <span>{ref.name}</span>
//                                                 <div className="hstack gap-2">
//                                                     <Badge className="badge badge-soft-info"> {ref.source}</Badge>
//                                                     <Badge className="badge badge-soft-info"> {ref.owner}</Badge>

//                                                     {
//                                                         map(Object.values(ref.tags), tag => {
//                                                             return <Badge className="badge badge-soft-danger"> {tag}</Badge>
//                                                         })
//                                                     }
//                                                 </div>
//                                             </div>
//                                         </Link>
//                                         </li>
//                                     })
//                                 }
//                             </ul>
//                         </div>
//                     )
//                 }
//             </div>
//         </div>}

//     </div>
// }

const AppExecution = ({ app, files, selectedFile, onFileStart, onFileEnd, onFinish, isRunning, onConfigChange, startTask, taskConfig }) => {

    const [appLineage, setAppLineage] = useState([]);
    const [currentStepId, setCurrentStepId] = useState(null);
    const [selectedAppSteps, setSelectedAppSteps] = useState(null);
    const [steps, setSteps] = useState({});
    const [listItems, setListItems] = useState([]);
    const [showConfig, setShowConfig] = useState(true);
    const [streamingInProgress, setStreamingInProgress] = useState(false);
    const contentPanelRef = useRef();

    const { accessToken } = useSelector(state => ({
        accessToken: state.Login.accessToken,
    }));

    const getTasksResult = (steps) => {

        return Object.keys(steps).map(taskId => {

            const task = steps[taskId];
            const result = {
                id: taskId,
                content: task.text || ""
            }

            listItems.forEach((appStepInfo, index) => {
                const stepResult = task.substeps[appStepInfo.id];
                const stepResultStr = `${(index + 1)} ${appStepInfo.name} \n ${stepResult.text}`;
                result.content = `${result.content}\n ${stepResultStr} \n`
            });

            return result;
        })

    }

    const onNewStream = (evt) => {

        const id = evt.id;
        const type = evt.type;
        const parentId = evt.parentId;
        const model = {
            id: evt.modelId,
            name: evt.modelName
        }

        switch (type) {

            case "fileStart":
                setCurrentStepId(id);
                onFileStart(id);
                setSteps(prev => {
                    return {
                        ...prev, [id]: {
                            id: id,
                            substeps: {},
                            text: "",
                            context: null,
                            isRunning: true

                        }
                    }
                });
                break;

            case "fileEnd":
                setSteps(prev => {

                    const items = { ...prev }
                    const oldContent = items[id];
                    const merge = {
                        ...items, [id]: {
                            ...oldContent,
                            isRunning: false
                        }
                    }
                    return merge
                });
                onFileEnd(id, true);
                break;


            case "stepStart":
                // onStepStart(id);
                setSteps(prev => {
                    const items = { ...prev }
                    const parentContent = items[parentId];
                    const parentSubsteps = { ...parentContent.substeps };

                    return {
                        ...items, [parentId]: {
                            ...parentContent,
                            substeps: {
                                ...parentSubsteps,
                                [id]: {
                                    text: "",
                                    context: "",
                                    status: TaskStatus.STARTED,
                                    model: model
                                }
                            }
                        }
                    }
                })
                break;

            case "streaming":
            case "context":
            case "stepEnd":

                if (isEmpty(parentId)) {
                    setSteps(prev => {

                        const items = { ...prev }
                        const oldContent = items[id];
                        const merge = {
                            ...items, [id]: {
                                ...oldContent,
                                text: type === "streaming" ? oldContent.text + evt.value : oldContent.text,
                                context: type === "context" ? evt.value.references : oldContent.context,
                                status: type === "stepEnd" ? TaskStatus.COMPLETED : oldContent.status

                            }
                        }
                        return merge

                    });
                } else {
                    setSteps(prev => {

                        const items = { ...prev }
                        const parentContent = items[parentId];
                        const parentSubsteps = { ...parentContent.substeps };
                        const stepContent = parentSubsteps[id];

                        return {
                            ...items, [parentId]: {
                                ...parentContent,
                                substeps: {
                                    ...parentSubsteps,
                                    [id]: {
                                        ...stepContent,
                                        text: type === "streaming" ? stepContent.text + evt.value : stepContent.text,
                                        context: type === "context" ? evt.value.references : stepContent.context,
                                        status: type === "stepEnd" ? TaskStatus.COMPLETED : stepContent.status
                                    }
                                }
                            }
                        }
                    });
                }


                scrollToBottom();

                break;
        }
    }

    const onStart = () => {
        setStreamingInProgress(true);
    }

    const onStreamEnd = () => {
        setStreamingInProgress(false);
    }

    const runTask = (config) => {
        const payload = {
            ...config,
            app: app,
            files: files
        }

        setShowConfig(false);

        if (isEmpty(app)) {
            runCustomAppOverDocuments(payload, onNewStream, onStart, onStreamEnd, accessToken)
        } else {
            post(GET_BOARD_EXEC_PLAN, app.board).then(resp => {
                setAppLineage(resp);
            }).then(resp => {
                runCustomAppOverDocuments(payload, onNewStream, onStart, onStreamEnd, accessToken)
            })
        }
    }

    const scrollToBottom = () => {
        if (contentPanelRef?.current) {
            const scrollEl = contentPanelRef.current;
            scrollEl?.scroll({
                top: scrollEl?.scrollHeight + 1000,
                // behavior: 'smooth',
            });
        }
    }


    useEffect(() => {
        if (startTask) {
            runTask(taskConfig);
            setSteps({});
        }
    }, [startTask])


    useEffect(() => {
        const items = [...appLineage];

        items.forEach(item => {
            item.isOpen = true;
        });
        setListItems(items);

    }, [appLineage]);

    useEffect(() => {

        const id = selectedFile?.location;

        if (isEmpty(id))
            return;

        setCurrentStepId(id);

        if (!isEmpty(app) && steps[id]) {
            setSelectedAppSteps(steps[id].substeps);
        }

    }, [selectedFile])

    useEffect(() => {

        const id = selectedFile?.location;

        if (!isEmpty(id) && !isEmpty(app) && steps[id]) {
            setSelectedAppSteps(steps[id].substeps);
        }

    }, [steps, selectedFile])

    useEffect(() => {

        if (!isEmpty(steps) && !streamingInProgress) {
            onFinish(getTasksResult(steps));
        }
    }, [steps, streamingInProgress])


    return <div className="board-execution-plan border " >
        <SimpleBar className="scroller" scrollableNodeProps={{ ref: contentPanelRef }} >
            <div className="d-flex flex-column h-100">

                {isEmpty(app) && !showConfig && <div className="p-4">
                    {/* <ReactMarkdown remarkPlugins={[remarkGfm, reHype]}>{steps[currentStepId]?.text}</ReactMarkdown> */}

                    <GenerationTextArea title={selectedFile?.name} text={steps[currentStepId]?.text} isGenerating={steps[currentStepId]?.isRunning} />
                </div>
                }

                {!showConfig && <div className="">
                    {map(listItems || [], (item, index) => {

                        const stepContent = selectedAppSteps && selectedAppSteps[item.id] ? selectedAppSteps[item.id] : {};
                        const text = stepContent.text;
                        const context = stepContent.context;
                        const status = stepContent.status;
                        const isRunning = stepContent.status === TaskStatus.STARTED;
                        const providerInfo = getServiceInfo(item.providerId || item.type);
                        const model = stepContent.model;

                        return <ApplicationStepView
                            item={item}
                            stepContent={stepContent}
                            text={text}
                            context={context}
                            status={status}
                            isRunning={isRunning}
                            providerInfo={providerInfo}
                            model={model}
                            index={index}


                        />
                    })}
                </div>}
                {
                    !isRunning && showConfig && <ExecutionLandingPage app={app} onChange={onConfigChange} />
                }
            </div>


        </SimpleBar >
    </div >
}

export default AppExecution;