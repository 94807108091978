import React, { memo, useEffect, useState, useCallback } from 'react';
import { Handle, Position, useUpdateNodeInternals, useStore, useHandleConnections } from '@xyflow/react';
import { Badge, Card, CardFooter, Collapse, DropdownItem, DropdownMenu, DropdownToggle, Label, UncontrolledDropdown } from 'reactstrap';
import { isEmpty } from 'lodash';


const MultiHandle = ({ id, nodeId, position, type, className, label, onTargetConnected, onSourceConnected, onStaticValueChange, handleValue }) => {

    const [isConnected, setIsConnected] = useState(false);
    const [value, setValue] = useState(handleValue);


    const istargetConnected = useStore((s) => {
        return s.edges.find((e) => e.target === nodeId && e.targetHandle === id)
    }
    );

    const isSourceConnected = useStore((s) => {
        return s.edges.find((e) => e.source === nodeId);
    }
    );

    useEffect(() => {

        if (onTargetConnected) {
            onTargetConnected(id, istargetConnected?.targetHandle, istargetConnected?.source);
        }
    }, [istargetConnected]);

    useEffect(() => {

        if (onSourceConnected) {
            onSourceConnected(id, isSourceConnected?.sourceHandle, isSourceConnected?.source);
        }
    }, [isSourceConnected])


    useEffect(() => {

        if (type === "target") {
            setIsConnected(istargetConnected || !isEmpty(value))
        }
        else {
            setIsConnected(isSourceConnected || !isEmpty(value))
        }
    }, [isSourceConnected, istargetConnected, value])


    useEffect(() => {
        if (onStaticValueChange) {
            onStaticValueChange(id, value)
        }
    }, [value]);

    useEffect(() => {
        setValue(handleValue);
    }, [handleValue]);


    // useEffect(() => {
    //     if (isConnected)
    //         setValue("");

    // }, [isConnected])

    return <div className='namedHandle'>

        {isEmpty(value) && <Handle
            id={id}
            position={position}
            type={type}
            className={isConnected ? "handle-connected" : "handle-not-connected"}
        >

        </Handle>
        }
        <div className='hstack gap-2' >
            <label htmlFor={id} className="label">
                {isConnected ? <Badge className='badge badge-soft-success'> {label}</Badge> : <Badge className='badge badge-soft-danger'> {label}</Badge>}
            </label>
            {onStaticValueChange && <input id={nodeId}
                type="text"
                className='form-control mb-2 me-4'
                style={{ height: '20px' }}
                placeholder="..."
                value={value}
                onChange={e => setValue(e.target.value)}
            // readOnly={isConnected}
            />}
        </div>

    </div>
}

export default MultiHandle;