import PropTypes from 'prop-types'
import React, { useEffect, useState, useRef } from "react"
import { Badge, Button, Card, CardBody, CardFooter, CardTitle, Col, DropdownItem, DropdownMenu, DropdownToggle, Modal, ModalBody, ModalFooter, ModalHeader, Progress, Row, Spinner, UncontrolledButtonDropdown } from "reactstrap"
import { withTranslation } from "react-i18next";
import SimpleBar from 'simplebar-react';
import { isEmpty, map, truncate } from 'lodash';
import { Link } from 'react-router-dom';
import { getProviderLogo } from 'utils';
import { toLocalDate } from 'helpers/to-local-date';
import { useSelector, useDispatch } from "react-redux";
import { postSupportRequestReply, resolveSupportRequest } from 'service/addons/support';
import { TaskStatus } from 'constants/tasks';
import { EventTypes } from 'constants/events';
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import reHype from 'remark-rehype'
import { operationCompleted } from 'utils/notifications';
import { updateSupportRequestsSuccess } from 'store/actions';

const ResolutionPlaceHolder = () => {

    return <Card className="card shadow-none border mb-0" aria-hidden="true">

        <CardBody>
            <h5 className="card-title placeholder-glow">
                <span className="placeholder col-3"></span>
            </h5>
            <p className="card-text placeholder-glow">
                <span className="placeholder col-7"></span>
                <span className="placeholder col-4"></span>
                <span className="placeholder col-4"></span>
                <span className="placeholder col-6"></span>
                <span className="placeholder col-10"></span>
                <span className="placeholder col-8"></span>
            </p>
            <div className='hstack  gap-1 float-end'>
                <Link to="#" className="btn btn-secondary disabled placeholder col-2"></Link>
                <Link to="#" className="btn btn-secondary disabled placeholder col-2"></Link>
            </div>
        </CardBody>

    </Card>
}

const getScoreColor = (score) => {

    if (score < 2)
        return "danger"

    if (score >= 2 && score < 4)
        return "warning"

    return "success"
}

const AutosuggestSupport = ({ show,
    onDeleteClick,
    onCloseClick,
    requests,
    t }) => {

    const [selectedRequest, setSelectedRequest] = useState(null);
    const [resolution, setResolution] = useState(null);
    const [isPosting, setIsPosting] = useState(null);
    const [resolutionStatus, setResolutionStatus] = useState(TaskStatus.NOT_STARTED);
    const [isEdit, setIsEdit] = useState(false);
    const dispatch = useDispatch();

    const cancellation = useRef(new AbortController());

    const { assistants, teams, accessToken } = useSelector(state => ({
        datasources: state.Datasources.datasources,
        assistants: state.Studio.assistants,
        teams: state.Support.platforms,
        accessToken: state.Login.accessToken,

    }));

    const onStart = () => {
        setResolutionStatus(TaskStatus.THINKING);
    }
    const onStream = (event) => {

        switch (event.type) {
            case EventTypes.START:
                setResolution(e => {
                    return {
                        agentName: event.agentName,
                        agentLogo: event.agentLogo,
                        content: "",
                        status: TaskStatus.STARTED
                    }
                })
                break;

            case EventTypes.MESSAGE_RECEIVED:
                setResolution(e => ({ ...e, status: TaskStatus.THINKING }))
                break;

            case EventTypes.STREAMING:
                setResolution(e => {
                    return {
                        ...e,
                        content: `${(e.content || "")}${event.value}`,
                    }
                })
                break;

            case EventTypes.ASSESSMENT:
                console.log(event.value)
                setResolution(e => ({ ...e, evaluation: event.value }))
                break;

            case EventTypes.END:
                setResolution(e => ({ ...e, status: TaskStatus.COMPLETED }))
                break;
        }
    }

    const onError = (err) => {

    }

    const onComplete = (err) => {

    }
    const cancelTask = () => {
        cancellation.current.abort();
        cancellation.current = new AbortController();
    }

    const openRemoteLocation = () => {
        window.open(selectedRequest.webUrl, "_blank");
    }

    const onEditResolution = (value) => {
        setResolution(e => ({
            ...e,
            content: value
        }));
    }


    const onResolve = (team) => {

        const payload = {
            request: selectedRequest,
            team: team
        }

        resolveSupportRequest(payload, onStream, onStart, onComplete, onError, accessToken, cancellation?.current?.signal)
    }

    const onPostReply = () => {
        setIsPosting(true)

        postSupportRequestReply({
            request: selectedRequest,
            reply: resolution?.content,
            agentName: resolution?.agentName,
        }).then(resp => {
            dispatch(updateSupportRequestsSuccess(resp));
            operationCompleted(t("Réponse postée avec succès!"))
        }).finally(() => {
            setIsPosting(false);
        })
    }

    const onEditReply = () => {
        setIsEdit(!isEdit);
    }

    useEffect(() => {
        if (requests?.length > 0) {
            setSelectedRequest(requests[0]);
        }
    }, [requests]);

    useEffect(() => {
        if (!isEmpty(selectedRequest)) {
            const assistant = assistants?.find(a => a.id === selectedRequest?.assistantId);

            if (!isEmpty(assistant)) {
                setResolution({
                    agentName: assistant.name,
                    agentLogo: assistant.logoUrl,
                    content: selectedRequest?.assistantReply,
                    status: TaskStatus.COMPLETED
                })
            }
            else {
                setResolution(null);
            }

        }
    }, [selectedRequest])


    return (
        <Modal size="xl"
            isOpen={show}
            toggle={onCloseClick}
            centered={true}
            fade={false}
            contentClassName='window-medium'

        >
            <ModalHeader>
                <h5><i className='bx bx-support me-1' />{t("Traitement des demandes")}</h5>
                <button type="button" onClick={onCloseClick} className="btn-close position-absolute end-0 top-0 m-3"></button>
            </ModalHeader>

            <div className="modal-content autosuggest p-1">

                <Row>
                    {requests?.length > 1 && <Col md={4}>
                        <Card className='border border-secondary border-opacity-25 request-list'>

                            <SimpleBar className='h-100'>
                                <ul className='list-unstyled'>
                                    {
                                        map(requests, request => {
                                            const isSelected = request.id === selectedRequest?.id;
                                            return <li >

                                                <Link className={isSelected ? "active" : ""} onClick={() => setSelectedRequest(request)}>
                                                    <div className='d-flex justify-content-between'>
                                                        <div className="d-flex flex-grow-1 flex-row overflow-hidden">
                                                            <div>
                                                                <h5 className="text-truncate text-muted font-size-14 fw-bold mb-1">
                                                                    {getProviderLogo(request.providerType)}
                                                                    {truncate(request.summary, { length: 155 })}
                                                                </h5>
                                                                <p className="text-truncate text-muted mb-0">
                                                                    {request.description}
                                                                </p>
                                                                <div className='hstack gap-1 mt-1'>
                                                                    <Badge color='primary'>{request.providerType.toUpperCase()}</Badge>
                                                                    <Badge color='primary'>{request.status}</Badge>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </Link>
                                            </li>
                                        })
                                    }
                                </ul>
                            </SimpleBar>

                        </Card>

                    </Col>}

                    <Col md={requests?.length > 1 ? 8 : 12}>
                        <Card className='border border-secondary border-opacity-25 request-list'>
                            <div className='p-2'>
                                <div className='d-flex justify-content-between'>
                                    <div>
                                        <h5 className='fw-bold'>
                                            {selectedRequest?.summary}
                                        </h5>
                                        <small className='text-muted'>Demande crée par {selectedRequest?.createdByName} le {toLocalDate(selectedRequest?.createdAt)}</small>
                                    </div>
                                    <div>
                                        {!isEmpty(selectedRequest?.webUrl) && <Link onClick={openRemoteLocation}>
                                            <div className='hstack'>
                                                <i className='bx bx-search  ' />
                                                <span> {t("Consuler la source")}</span>
                                            </div>

                                        </Link>}
                                    </div>
                                </div>
                            </div>
                            <div className='p-2'>
                                <h6 className='fw-bold'>Description:</h6>
                                <p>{selectedRequest?.description}</p>
                            </div>

                            {isEmpty(resolution) && <div className='p-1'>
                                <UncontrolledButtonDropdown>
                                    <DropdownToggle>
                                        {t("Déléguer")} <i className='mdi mdi-chevron-down' />
                                    </DropdownToggle>

                                    <DropdownMenu>

                                        {
                                            map(teams, item => {
                                                return <DropdownItem onClick={() => onResolve(item)}>
                                                    <div className='vstack m-2'>
                                                        <span> {item.name}</span>
                                                        <small>{item.members?.length} agents</small>
                                                    </div>
                                                </DropdownItem>
                                            })
                                        }

                                    </DropdownMenu>
                                </UncontrolledButtonDropdown>
                            </div>}

                            {(resolution?.status === TaskStatus.STARTED || resolution?.status === TaskStatus.COMPLETED) && <div className='p-2 '>
                                <h6 className='fw-bold'>Réponse suggérée:</h6>
                                <Card className='border border-secondary border-opacity-25 ps-2 pt-2 pe-1 bg-warning bg-opacity-25'>
                                    <div className='d-flex justify-content-between border-bottom border-secondary border-opacity-25 pb-1 '>
                                        <div className=''>
                                            <div className='hstack'>
                                                <img src={resolution.agentLogo} width={20} height={20} />
                                                <div className='vstack'>
                                                    <span className='fw-bold'>{resolution.agentName}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            {resolution?.status === TaskStatus.STARTED && <Spinner size={"sm"} />}
                                        </div>
                                    </div>
                                    <CardBody className='p-2'>
                                        {!isEdit && <SimpleBar className='resolution-suggestion-content-scroller'>
                                            <ReactMarkdown className="markdown" remarkPlugins={[remarkGfm]} rehypePlugins={[reHype]}>{resolution.content}</ReactMarkdown>
                                        </SimpleBar>}
                                        {
                                            isEdit && <div>
                                                <textarea
                                                    rows={10}
                                                    className='form-control'
                                                    value={resolution?.content}
                                                    onChange={e => onEditResolution(e.target.value)}
                                                />
                                            </div>
                                        }

                                    </CardBody>


                                    {resolution?.status === TaskStatus.COMPLETED &&
                                        <CardFooter className=' border-top border-secondary border-opacity-25'>
                                            <Row>
                                                <Col md={6}>
                                                    {!isEmpty(resolution?.evaluation) && <div className='vstack' >
                                                        <h6>{t("Auto evaluation")}:</h6>

                                                        <div className='custom-progress '>
                                                            <Progress multi >
                                                                <Progress bar className='border' color={getScoreColor(resolution?.evaluation?.relevancy)} value={resolution?.evaluation?.relevancy} min={0} max={5}></Progress>
                                                                <Progress bar className='border' color={getScoreColor(resolution?.evaluation?.completeness)} value={resolution?.evaluation?.completeness} min={0} max={5}></Progress>
                                                                <Progress bar className='border' color={getScoreColor(resolution?.evaluation?.correctness)} value={resolution?.evaluation?.correctness} min={0} max={5}></Progress>
                                                            </Progress>
                                                        </div>
                                                    </div>}
                                                </Col>
                                                <Col md={6}>
                                                    <div className='hstack gap-1 float-end'>

                                                        {!isEdit && <Button onClick={onPostReply} className='btn btn-success font-size-10'>{t("Poster")}</Button>
                                                        }                                                <Button onClick={onEditReply} className='btn btn-secondary font-size-10'>{isEdit ? t("Terminer") : t("Editer")}</Button>
                                                        {!isEdit && <UncontrolledButtonDropdown >
                                                            <DropdownToggle className='font-size-10'>
                                                                {t("Soumettre")} <i className='mdi mdi-chevron-down' />
                                                            </DropdownToggle>

                                                            <DropdownMenu>

                                                                {
                                                                    map(teams, item => {
                                                                        return <DropdownItem onClick={() => onResolve(item)}>
                                                                            <div className='vstack m-2'>
                                                                                <span> {item.name}</span>
                                                                                <small>{item.members?.length} agents</small>
                                                                            </div>
                                                                        </DropdownItem>
                                                                    })
                                                                }

                                                            </DropdownMenu>
                                                        </UncontrolledButtonDropdown>}

                                                        {isPosting && <div className='vr' />}
                                                        {isPosting && <Spinner className='sm' />}
                                                    </div>
                                                </Col>
                                            </Row>



                                        </CardFooter>}
                                </Card>

                            </div>}
                            {
                                resolution?.status === TaskStatus.THINKING && <ResolutionPlaceHolder />
                            }
                        </Card>
                    </Col>
                </Row>

            </div>
            {/* <ModalFooter>
                <Button>{t("Annuler")}</Button>

            </ModalFooter> */}


        </Modal>
    )
}

export default withTranslation()(AutosuggestSupport);