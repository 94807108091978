import { isEmpty, map } from "lodash";
import React, { useEffect, useState } from "react";
import { ThreeDots } from "react-loader-spinner";
import {
    Row,
    Col,
    Card,
    CardBody
} from "reactstrap";
//i18n
const BoardBanner = ({ metrics, isLoading }) => {

    const [items, setItems] = useState([]);


    useEffect(() => {
        if (isEmpty(metrics))
            return;

        const newItems = [
            {
                name: 'Nombre de requêtes',
                value: new Intl.NumberFormat("fr-FR").format(metrics.totalRequests),
                icon: "bx bx-user font-size-24"
            },
            {
                name: 'Requêtes réussies',
                value: new Intl.NumberFormat("fr-FR").format(metrics.totalRequestsSuccess),
                icon: "bx bx-user font-size-24 "
            },
            {
                name: 'Requêtes échouées',
                value: metrics.totalRequestFailed,
                icon: "bx bx-user font-size-24 "

            },
            {
                name: "Tokens générés",
                value: new Intl.NumberFormat("fr-FR").format(metrics.totalTokens),
                icon: "bx bx-user font-size-24 "
            },
            {
                name: "Utilisateurs actifs",
                value: new Intl.NumberFormat("fr-FR").format(metrics.activeUsersCount),
                icon: "bx bx-user font-size-24 "
            },
            {
                name: "Estimation Coût ($)",
                value: new Intl.NumberFormat("en-US").format(metrics.totalCosts),
                icon: "bx bx-user font-size-24 "
            },
        ];

        setItems(newItems);

    }, [metrics])

    return (
        <div>
            <Row>
                {map(items || [], item => {

                    return <Col md="2">
                        <Card className="mini-stats-wid bg-info bg-opacity-25">
                            <CardBody>
                                <div className="d-flex">
                                    <div className="flex-grow-1">
                                        <p className="text-muted fw-medium">
                                            {item.name}
                                        </p>
                                        {isLoading && <ThreeDots
                                            height="20"
                                            width="50"
                                            radius="5"
                                            color="grey"
                                            ariaLabel="three-dots-loading"
                                            wrapperStyle={{}}
                                            wrapperClassName=""
                                            visible={true}
                                        />}
                                        {!isLoading && <h4 className="mb-0">{item.value}</h4>}

                                    </div>
                                    {/* <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                                        <span className="avatar-title rounded-circle bg-primary">
                                            <i
                                                className={item.icon}
                                            ></i>
                                        </span>
                                    </div> */}
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                })}
            </Row>
        </div>
    );
};


export default BoardBanner;