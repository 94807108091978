import { isEmpty, map } from "lodash";
import React, { useEffect, useState } from "react";
import { ThreeDots } from "react-loader-spinner";
import {
    Row,
    Col,
    Card,
    CardBody
} from "reactstrap";
//i18n
const SupportBoardBanner = ({ board, isLoading, waitingForValidation }) => {

    const [items, setItems] = useState([]);


    useEffect(() => {
        if (isEmpty(board))
            return;

        const newItems = [
            {
                name: 'Nombre de demandes',
                value: new Intl.NumberFormat("fr-FR").format(board.total || 0),
                icon: "bx bx-user font-size-24"
            },
            {
                name: 'Délégués aux assist.',
                value: new Intl.NumberFormat("fr-FR").format(board.totalReplied || 0),
                icon: "bx bx-user font-size-24 "
            },
            {
                name: 'Réponses postées',
                value: board.totalPosted,
                icon: "bx bx-user font-size-24 "

            },
            {
                name: "A Valider",
                value: new Intl.NumberFormat("fr-FR").format(waitingForValidation?.length || 0),
                icon: "bx bx-user font-size-24 "
            },
            {
                name: "Plateformes connectées",
                value: new Intl.NumberFormat("fr-FR").format(board.totalPlatforms || 0),
                icon: "bx bx-user font-size-24 "
            },
            {
                name: "Assistants actifs",
                value: new Intl.NumberFormat("en-US").format(board.totalAssistants),
                icon: "bx bx-user font-size-24 "
            },
        ];

        setItems(newItems);

    }, [board])

    return (
        <div>
            <Row>
                {map(items || [], item => {

                    return <Col md="2">
                        <Card className="mini-stats-wid bg-info bg-opacity-25">
                            <CardBody>
                                <div className="d-flex">
                                    <div className="flex-grow-1">
                                        <p className="text-muted fw-medium">
                                            {item.name}
                                        </p>
                                        {isLoading && <ThreeDots
                                            height="20"
                                            width="50"
                                            radius="5"
                                            color="grey"
                                            ariaLabel="three-dots-loading"
                                            wrapperStyle={{}}
                                            wrapperClassName=""
                                            visible={true}
                                        />}
                                        {!isLoading && <h4 className="mb-0">{item.value}</h4>}

                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                })}
            </Row>
        </div>
    );
};


export default SupportBoardBanner;