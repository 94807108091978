import { post, post_form, del, get, axiosApi } from "helpers/api_helper";
import { DATASOURCES_PROVIDER, DATASOURCES_BROWSE } from "helpers/url_helper";

export async function describeDatasource(providerId, payload) {
    return post(`${DATASOURCES_PROVIDER}/${providerId}/connect`, payload)
}

export async function indexDatasource(providerId, datasourceId, payload) {
    return post(`${DATASOURCES_PROVIDER}/${providerId}/index/${datasourceId}`, payload)
}

export async function browseDatasource(payload) {
    return post(`${DATASOURCES_BROWSE}`, payload)
}