import React, { useCallback, useEffect, useState } from 'react';
import Dagre from '@dagrejs/dagre';
import { toPng } from 'html-to-image';
import {
    ReactFlow, useReactFlow, ReactFlowProvider,
    getViewportForBounds,
    getNodesBounds,
    Panel,
    Controls, useNodesState, useEdgesState, addEdge, Background,
    getNode,


} from '@xyflow/react';

// import {
//     Panel,
//     Controls, useNodesState, useEdgesState, addEdge, Node, Edge, Background,
//     getRectOfNodes,
//     getTransformForBounds,
// } from '@xyflow/react';


// import 'reactflow/dist/base.css';
import '@xyflow/react/dist/style.css';

import { ButtonGroup, DropdownMenu, DropdownToggle, DropdownItem, UncontrolledDropdown, Button, Dropdown } from 'reactstrap';
import {
    getDataServices,
    getStudioApps,
    showRightSidebarAction
} from "store/actions";
import { useSelector, useDispatch } from "react-redux";
import { getProviderInfo, getProviderLogo, getServiceInfo, uuidv4 } from 'utils';
import BoardNode from "./components/nodes"
import BoardRunner from '../runner';
import { isEmpty } from 'lodash';
import { useLocation } from "react-router";
import {
    useNavigate,
} from 'react-router-dom';

import StudioToolbar from "./components/toolbar"
import { ResourceTypes } from 'constants/general';
const nodeTypes = {
    dynamic: BoardNode
};

const edgeTypes = {

};

const defaultEdgeOptions = {
    type: 'default',
    markerEnd: 'edge-circle',
};

const proOptions = { hideAttribution: true };
const flowKey = "jask-workflow"

const contextNode = {
    id: "context",
    name: "Contexte",
    provider: { type: "context", name: 'Context' },
    icon: 'mdi mdi-message-plus me-2 text-info font-size-24',
    output: 'text',
    description: 'Contexte d\'execution',
    isValid: true,
}

const tools = [{
    name: "Nouveau Prompt",
    provider: { type: "App", name: 'App' },
    icon: 'mdi mdi-message-plus me-2 text-info font-size-24',
    output: 'text',
    description: 'Renseigner un prompt personnalisé à executer.'
},
]

const agents = [{
    name: "Extraire des connaissances",
    provider: { type: "knowledge", name: 'knowledge' },
    icon: 'mdi mdi-brain me-2',
    output: 'text'
},
]

const g = new Dagre.graphlib.Graph().setDefaultEdgeLabel(() => ({}));
const getLayoutedElements = (nodes, edges, options) => {
    const isHorizontal = options?.direction === 'LR';
    g.setGraph({ rankdir: options.direction });
    edges.forEach((edge) => g.setEdge(edge.source, edge.target));
    nodes.forEach((node) => g.setNode(node.id, { width: node.measured.width, height: node.measured.height }));
    Dagre.layout(g);


    return {
        nodes: nodes.map((node) => {
            const nodeWithPosition = g.node(node.id);
            console.log("Width ", node)
            return {
                ...node,
                targetPosition: isHorizontal ? 'left' : 'top',
                sourcePosition: isHorizontal ? 'right' : 'bottom',
                position: {
                    x: nodeWithPosition.x - node.measured.width,
                    y: nodeWithPosition.y - node.measured.height / 2,
                },
            }
        }),
        edges,
    };
};

const DesignerBoard = () => {
    const reactFlowInstance = useReactFlow();
    const [nodes, setNodes, onNodesChange] = useNodesState([]);
    const [edges, setEdges, onEdgesChange] = useEdgesState([]);
    const [rfInstance, setRfInstance] = useState(null);
    const [canSubmit, setCanSubmit] = useState(false);
    const [showBoardRunner, setShowBoardRunner] = useState(false);
    const [board, setBoard] = useState(null);
    const [app, setApp] = useState(null);
    const [showToolBar, setShowToolBar] = useState(false);

    const { setViewport } = useReactFlow();
    const locationState = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [appNodes, setAppNodes] = useState([]);
    const [assistantsNodes, setAssistantNodes] = useState([]);
    const [datasourceNodes, setDatasourceNodes] = useState([]);
    const [promptStoreNodes, setPromptStoreNodes] = useState([]);


    const { isWorking, dataServices, apps, assistants, prompts } = useSelector(state => ({
        isWorking: state.Assistant.isWorking,
        dataServices: state.Assistant.dataServices,
        apps: state.Studio.apps,
        assistants: state.Studio.assistants,
        prompts: state.PromptStore.prompts
    }));


    const mapSourceToNode = (item, icon, type, logoComponent) => {
        return {
            name: item.name,
            icon: icon,
            provider: { type: "Search", name: item.name },
            providerId: item.id,
            providerType: type,
            logoType: type,
            description: item.description
        }
    }

    const mapAppToNode = (item) => {

        return {
            id: item.id,
            name: item.name,
            icon: 'fa fa-solid fa-code font-size-12',
            logo: item?.logoUrl,
            provider: { type: "Custom", name: item.name },
            providerId: item.id,
            isValid: true,
            applicationId: item.id,
            owner: item.principalName || item.principalId,
            ownerId: item.principalId,
            createdAt: item.createdAt,
            lastUpdate: item.lastUpdate,
            description: item.description
        }
    }

    const mapAssistantToNode = (item) => {
        return {
            id: item.id,
            name: item.name,
            logo: item?.logoUrl,
            provider: { type: "Assistant", name: item.name },
            providerId: item.id,
            isValid: true,
            applicationId: item.id,
            owner: item.principalName || item.principalId,
            ownerId: item.principalId,
            createdAt: item.createdAt,
            lastUpdate: item.lastUpdate,
            description: item.description,
        }
    }



    const onChange = (nodeToUpdate) => {
        // console.log("New change ", nodeToUpdate)
        setNodes((nds) =>
            nds.map(node => {
                if (node.id !== nodeToUpdate.id)
                    return node;

                // node.data = {
                //     ...node.data,
                //     ...nodeToUpdate
                // }
                return {
                    ...node,
                    data: {
                        ...node.data,
                        ...nodeToUpdate
                    }
                }
            })
        );
    }

    const addNodeToBoard = (newNode) => {
        const toAdd = { ...newNode, id: newNode.id === contextNode.id ? contextNode.id : uuidv4() }
        const newItem = {
            id: toAdd.id,
            position: {
                x: Math.random() * 200,
                y: Math.random() * 200,
            },
            sourcePosition: 'right',
            type: 'dynamic',
            data: {
                ...toAdd,
                id: toAdd.id,
                icon: toAdd.icon,
                name: `${toAdd.name}`,
                subline: `${toAdd.provider.type}`,
                type: toAdd.provider.type,
                providerName: toAdd.provider.name,
                onConfigure: onChange
            },
        };

        reactFlowInstance.addNodes(newItem);
    }

    const onConnect = useCallback((params) => setEdges((els) => {
        // params.label = "> Vector";
        // console.log(params)
        return addEdge({
            ...params, animated: true,
            // type: 'smoothstep' 
        }, els);
    }), [setEdges]);

    const onLayout = useCallback(
        (direction) => {
            const layouted = getLayoutedElements(nodes, edges, { direction });

            setNodes([...layouted.nodes]);
            setEdges([...layouted.edges]);

            window.requestAnimationFrame(() => {
                reactFlowInstance.fitView();
                // onLayout('LR');
            });
        },
        [nodes, edges]
    );

    const canConnectNodes = (connection) => {
        return connection.source !== connection.target;
    }

    const saveWork = () => {
        if (rfInstance) {
            const flow = rfInstance.toObject();
            localStorage.setItem(flowKey, JSON.stringify(flow));
        }
    }

    const buildBoard = (state) => {

        setBoard({
            edges: edges.map(e => ({ source: e.source, target: e.target })),
            nodes: nodes.map(n => ({ ...n.data })),
            isValid: state,
            rawGraph: rfInstance?.toObject()
        });
    }

    const closeBoard = () => {
        saveWork();
        navigate("/studio/applications")
    }
    const getImage = () => {

        reactFlowInstance.fitView();
        const imageWidth = 1920;
        const imageHeight = 1080;
        const nodesBounds = getNodesBounds(reactFlowInstance.getNodes());
        const transform = getViewportForBounds(nodesBounds, imageWidth, imageHeight, 0, 0);
        return toPng(document.querySelector('.react-flow__viewport'), {
            // backgroundColor: '#00000',
            width: imageWidth,
            height: imageHeight,
            style: {
                width: imageWidth,
                height: imageHeight,
                transform: `translate(${transform[0]}px, ${transform[1]}px) scale(${transform[2]})`,
            },
        })
    };

    const onRunnerClick = () => {
        setShowBoardRunner(!showBoardRunner)
    }

    const onTest = () => {
        dispatch(showRightSidebarAction({
            type: ResourceTypes.APPLICATIONS,
            app: {
                id: app?.id || uuidv4(),
                principalId: app?.principalId,
                principalName: app?.principalName,
                board: board,
                name: app?.name || "Live Test",
                description: app?.description || "Live test from board"
            }
        }));
    }

    const onDeleteRequest = ({ nodes, edges }) => {
        const systemNode = nodes?.find(n => n.id === contextNode.id);
        return !systemNode;
    }

    const onNodeShouldUpdate = (changes) => {
        console.log("changes ", changes)
        onNodesChange(changes)
    }

    useEffect(() => {
        dispatch(getDataServices());
        dispatch(getStudioApps());

    }, [dispatch])

    useEffect(() => {

        saveWork();
        const all = (arr, fn = Boolean) => arr.every(fn);
        const allValid = all(nodes, i => i.data.isValid);
        const isValid = allValid && nodes.length > 0;

        console.log("Board is valid ", isValid, allValid, nodes)
        setCanSubmit(isValid);
        buildBoard(isValid);


    }, [nodes, edges])

    useEffect(() => {
        const newApp = locationState.state?.new;

        if (newApp === true) {
            addNodeToBoard(contextNode);
            return;
        }

        let flow = locationState.state?.app?.board?.rawGraph;

        if (!flow) {
            flow = JSON.parse(localStorage.getItem(flowKey));
        } else {
            setApp(locationState.state.app);
        }

        if (flow) {
            const context = flow.nodes.find(n => n.id === contextNode.id);

            if (!context) {
                addNodeToBoard(contextNode);
            }

            const { x = 0, y = 0, zoom = 2 } = flow.viewport;

            flow.nodes.forEach(node => {
                node.data.onConfigure = onChange;
            });

            flow.edges.forEach(edge => {
                onConnect(edge);
            })

            setNodes(flow.nodes || []);
            setViewport({ x, y, zoom });
        }

    }, []);


    useEffect(() => {
        setAppNodes(apps.map(app => mapAppToNode(app)));
    }, [apps]);

    useEffect(() => {
        setAssistantNodes(assistants.map(app => mapAssistantToNode(app)));
    }, [assistants]);

    useEffect(() => {
        setDatasourceNodes(dataServices.map(item => {
            const type = item.type || item.providerId;
            const rsc = getProviderLogo(type, 24)
            return mapSourceToNode(item, null, type, rsc)
        }));
    }, [datasourceNodes]);

    useEffect(() => {
        const promptNodes = prompts.map(prompt => {
            return {
                name: prompt.name,
                provider: { type: "promptStore", name: 'App' },
                icon: 'mdi mdi-message-reply-text me-2 text-danger font-size-24',
                output: 'text',
                description: prompt.description,
                owner: prompt.createdByName,
                content: prompt.content,
                parameters: prompt.parameters,
                promptId: prompt.id,
                promptOwner: prompt.createdById,
                tags: (prompt.category?.split(";") || [])
            }
        });

        setPromptStoreNodes([...tools, ...promptNodes])

    }, [tools, prompts]);

    return <div>

        <div className='board'>
            {showBoardRunner && <BoardRunner show={showBoardRunner}
                onCloseClick={onRunnerClick}
                board={board} onScreenshot={getImage}
                app={app} />}

            <ReactFlow
                nodes={nodes}
                edges={edges}
                onConnect={onConnect}
                onNodesChange={onNodeShouldUpdate}
                onEdgesChange={onEdgesChange}
                fitView
                attributionPosition="bottom-left"
                proOptions={proOptions}
                nodeTypes={nodeTypes}
                edgeTypes={edgeTypes}
                defaultEdgeOptions={defaultEdgeOptions}
                isValidConnection={canConnectNodes}
                onInit={setRfInstance}
                colorMode="dark"
                onBeforeDelete={onDeleteRequest}

            >
                <Controls showInteractive={false} />
                <Panel position='left'>
                    <Dropdown isOpen={showToolBar}
                        toggle={() => { }}

                    >
                        <DropdownToggle className='btn btn-rounded btn-danger' onClick={() => setShowToolBar(!showToolBar)}>
                            <i className='mdi mdi-plus' />
                        </DropdownToggle>

                        <DropdownMenu className='dropdown-menu-lg mt-2'>
                            <StudioToolbar

                                apps={appNodes}
                                assistants={assistantsNodes}
                                datasources={datasourceNodes}
                                onNodeClick={addNodeToBoard}
                                tools={promptStoreNodes}
                                onClose={() => setShowToolBar(!showToolBar)}
                            />
                        </DropdownMenu>
                    </Dropdown>
                </Panel>
                {app && <Panel position='top-center'>
                    <div className='hstack gap-1'>
                        <i className='mdi mdi-apps mt-1' />
                        <span className='font-size-16'> {app?.name}</span>
                    </div>
                </Panel>}
                <Panel position='right'>
                    <div className='hstack gap-1'>
                        <UncontrolledDropdown>
                            <DropdownToggle className='btn btn-rounded btn-danger' onClick={saveWork} >
                                <i className='mdi mdi-content-save-outline font-size-18' />
                            </DropdownToggle>
                        </UncontrolledDropdown>
                        <UncontrolledDropdown>
                            <DropdownToggle className='btn btn-rounded btn-danger' >
                                <i className='mdi mdi-format-align-justify font-size-18' />
                            </DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem disabled={nodes?.length == 0} onClick={() => onLayout('LR')}>
                                    Recentrer horizontalement
                                </DropdownItem>
                                <DropdownItem divider />
                                <DropdownItem disabled={nodes?.length == 0} onClick={() => onLayout('VR')}>
                                    Recentrer verticalement
                                </DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>

                        <UncontrolledDropdown>
                            <DropdownToggle className='btn btn-rounded btn-danger' >
                                <i className='mdi mdi-play-box-outline font-size-18' />
                            </DropdownToggle>
                        </UncontrolledDropdown>

                        <UncontrolledDropdown>
                            <DropdownToggle className='btn btn-rounded btn-danger' disabled={!canSubmit} onClick={onRunnerClick} >
                                <i className='mdi mdi-rocket-launch font-size-18' />
                            </DropdownToggle>
                        </UncontrolledDropdown>

                        <UncontrolledDropdown>
                            <DropdownToggle className='btn btn-rounded btn-danger' disabled={!canSubmit} onClick={onTest} >
                                <i className='mdi mdi-play font-size-18' />
                            </DropdownToggle>
                        </UncontrolledDropdown>

                    </div>


                </Panel>

                <svg>
                    <defs>
                        <linearGradient id="edge-gradient">
                            <stop offset="0%" stopColor="#ae53ba" />
                            <stop offset="100%" stopColor="#2a8af6" />
                        </linearGradient>

                        <marker
                            id="edge-circle"
                            viewBox="-5 -5 10 10"
                            refX="0"
                            refY="0"
                            markerUnits="strokeWidth"
                            markerWidth="10"
                            markerHeight="10"
                            orient="auto"
                        >
                            <circle stroke="#2a8af6" strokeOpacity="0.75" r="2" cx="0" cy="0" />
                        </marker>
                    </defs>
                </svg>
                <Background variant="dots" gap={10} size={0.3} />
            </ReactFlow>
        </div>
    </div>

}

function FlowWithProvider(props) {
    return (
        <ReactFlowProvider>
            <DesignerBoard {...props} />
        </ReactFlowProvider>
    );
}


export default FlowWithProvider;