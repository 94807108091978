import { DatasourceProviders, ResourceTypes } from "constants/general";
import React, { useEffect, useState } from "react"
import CrmSettings from "./crm"
import InternetSettings from "./internet"
import FileSettings from "./file"
import SharepointSetings from "./sharepoint"
import DirectorySettings from "./directory"
import { withTranslation } from "react-i18next";

const Settings = ({ t, source, onChange, onDatasourceFilterClicked, onRemoveDatasourceFilter }) => {
    const getSettingsView = (source, type, onChange) => {

        switch (type) {

            case ResourceTypes.INTERNET:
            case DatasourceProviders.BING_SEARCH:
            case DatasourceProviders.GOOGLE_SEARCH:
                return <InternetSettings source={source} onChange={onChange} />

            case ResourceTypes.CRM:
            case ResourceTypes.SALESFORCE:
                return <CrmSettings source={source} onChange={onChange} />

            case ResourceTypes.DIRECTORY:
                return <DirectorySettings source={source} onChange={onChange} />

            case ResourceTypes.FILE:
                return <FileSettings source={source} onChange={onChange} />

            case DatasourceProviders.SHAREPOINT:
                return <SharepointSetings source={source}
                    onChange={onChange}
                    onDatasourceFilterClicked={onDatasourceFilterClicked}
                    onRemoveDatasourceFilter={onRemoveDatasourceFilter}
                />
        }
    }

    return <div className="h-100 ">
        {getSettingsView(source, source?.type, onChange)}
    </div>
}

export default withTranslation()(Settings);