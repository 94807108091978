import React, { useEffect, useState } from "react";
import {
    Card,
    CardBody,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    UncontrolledButtonDropdown
} from "reactstrap";

import {
    delStudioApp,
    getStudioApps,
    showRightSidebarAction

} from "store/actions";

import { useSelector, useDispatch } from "react-redux";
import { withTranslation } from "react-i18next";
import { isEmpty, map } from "lodash";
import Application from "./app";
import DeleteModal from "components/Common/DeleteModal";
import { useNavigate } from "react-router-dom";
import SimpleBar from "simplebar-react"
import AppLauncher from "components/AppLauncher";
import { ResourceTypes } from "constants/general";

const ApplicationList = ({ t }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [showDelete, setShowDelete] = useState(false);
    const [selectedApp, setSelectedApp] = useState(null);
    const [showRunApp, setShowRunApp] = useState(false);
    const [selectedFiles, setSelectedFiles] = useState(false);
    const [tagFilter, setTagFilter] = useState(null);
    const [seachFilter, setSearchFilter] = useState(null);
    const [tags, setTags] = useState([]);
    const [appList, setAppList] = useState([]);


    const { apps } = useSelector(state => ({
        apps: state.Studio.apps,
    }));


    const handleTagFilter = (item) => {
        setTagFilter(item);
    }

    const getTagName = (item) => {

        return item === "*" ? t("Toutes") : item;
    }

    const handleDeleteRequest = (item) => {
        setSelectedApp(item);
        setShowDelete(!showDelete)
    }

    const handleDelete = () => {
        setShowDelete(!showDelete);
        dispatch(delStudioApp(selectedApp?.id));
    }

    const runapplication = (app, files) => {
        setShowRunApp(!showRunApp);
        setSelectedApp(app);
        setSelectedFiles(files.map(f => ({ ...f, isSelected: true, isDir: false })));
    }


    const onAppClick = (app) => {
        dispatch(showRightSidebarAction({
            type: ResourceTypes.APPLICATIONS,
            app: app
        }));
    }

    const onEditApp = (app) => {
        navigate("/studio/designer", {
            state: {
                app: app
            }
        });
    }


    const onCreateApp = () => {
        navigate("/studio/designer", {
            state: {
                new: true
            }
        });
    }

    useEffect(() => {
        isEmpty(apps)
        dispatch(getStudioApps())
    }, [dispatch]);

    useEffect(() => {
        let availableTags = {};

        apps.forEach(app => {
            app.tags.forEach(tag => {
                availableTags[tag] = tag;
            });
        });

        setTags(["*", ...Object.keys(availableTags)]);

    }, [apps]);

    useEffect(() => {

        let view = [...apps];

        if (tagFilter && tagFilter !== "*") {
            view = view.filter(app => app.tags.includes(tagFilter));
        }

        if (!isEmpty(seachFilter?.trim())) {
            const searchExpr = seachFilter.toLowerCase();

            view = view.filter(app => {
                const inInTags = (app.tags || []).map(tag => tag.toLowerCase()).join(" ").includes(searchExpr)
                return app.name.toLowerCase().includes(searchExpr) || app.description?.toLowerCase().includes(searchExpr) || inInTags || app.principalName.toLowerCase().includes(searchExpr);
            });

        }

        setAppList(view);


    }, [tagFilter, apps, seachFilter]);



    return (<div className="app-list" >

        {showDelete && <DeleteModal
            show={showDelete}
            onCloseClick={() => setShowDelete(!showDelete)}
            onDeleteClick={handleDelete}
            text={"Voulez-vous vraiment supprimer cette application ?"}

        />}

        {showRunApp && <AppLauncher
            show={showRunApp} onCloseClick={() => setShowRunApp(false)}
            app={selectedApp} files={selectedFiles}
        />}

        <div className="d-flex justify-content-between mb-4 me-4">
            <div className="mt-3 ms-2">
                <h5>{t("Mes applications")} ({appList.length}) </h5>
            </div>
            <div>
                <div className="hstack gap-1">
                    <input type="text"
                        className="form-control rounded-4"
                        style={{ width: '300px' }}
                        value={seachFilter}
                        onChange={e => setSearchFilter(e.target.value)}

                        placeholder={t("Rechercher des applications")}
                    />


                    <UncontrolledButtonDropdown>
                        <DropdownToggle className="rounded-4">
                            <i className="mdi mdi-filter" />   {isEmpty(tagFilter) ? t("Filtrer") : getTagName(tagFilter)} <i className="mdi mdi-chevron-down" />
                        </DropdownToggle>

                        <DropdownMenu>

                            {map(tags, item => {

                                const isSelected = tagFilter === item;
                                const name = getTagName(item);
                                return <DropdownItem onClick={() => handleTagFilter(item)}>
                                    {isSelected ? <span> <i className="mdi mdi-check me-1" />{name}</span> : <span className="ps-3">{name}</span>}
                                </DropdownItem>
                            })}
                        </DropdownMenu>
                    </UncontrolledButtonDropdown>
                </div>
            </div>

        </div>


        <SimpleBar className="scroller">
            <div className="d-flex flex-wrap app-list-mini">
                {
                    map(appList, item => {
                        return <Application app={item} onDelete={handleDeleteRequest} onOpenApp={onAppClick} onEditApp={onEditApp} onRunApp={runapplication} />
                    })
                }
                <Card className="border border-info rounded-3 m-2 app border-opacity-25" onClick={onCreateApp}>
                    <CardBody className="">
                        <div className="d-flex justify-content-center h-100 align-items-center">
                            <i className="mdi mdi-plus app-add-button" />
                        </div>
                    </CardBody>
                </Card>
            </div>
        </SimpleBar>
    </div>

    );
};


export default withTranslation()(ApplicationList);