import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import {
    Table,
    DropdownMenu,
    DropdownToggle,
    UncontrolledDropdown,
    DropdownItem,
    Card,
    CardBody,
    CardFooter,
} from "reactstrap"

import {
    getRecentFiles,
} from "store/actions";

import { recentfile } from "../../../../../common/data";
import { useSelector, useDispatch } from "react-redux";
import { isEmpty } from "lodash";
import { toLocalDate } from "helpers/to-local-date";
import { getFileIcon } from "utils";
import FileListPlaceHolders from "./placeholders";

const RecentFile = () => {
    const [stats, setStats] = useState({});
    const dispatch = useDispatch();

    const { recentFiles, isLoading } = useSelector(state => ({
        recentFiles: state.Documents.recentFiles,
        isLoading: state.Documents.isLoading
    }));

    useEffect(() => {
        dispatch(getRecentFiles())
    }, [])

    useEffect(() => {
        const totalDir = recentFiles.filter(i => i.isDir === true);
        const totalFiles = recentFiles.filter(i => i.isDir === false);
        setStats({
            totalDirs: totalDir.length,
            totalFiles: totalFiles.length
        });
    }, [recentFiles])

    return (
        <React.Fragment>
            <Card>
                {!isLoading && <CardBody>
                    <div className="recentFiles table-rep-plugin">
                        <div className="d-flex flex-wrap">
                            <h5 className="font-size-16 me-3">Fichiers récents</h5>

                            <div className="ms-auto">
                                <Link onClick={() => dispatch(getRecentFiles())} to="#" className="fw-medium text-reset"><i className="mdi mdi-refresh font-size-16" /></Link>
                            </div>
                        </div>
                        <hr className="mt-2" />

                        <div className="table-responsive">
                            <Table className="table align-middle table-nowrap table-hover mb-0">
                                <thead>
                                    <tr>
                                        <th scope="col">Nom</th>
                                        <th scope="col">Date de création</th>
                                        <th scope="col">Dernière modification</th>
                                        <th scope="col" colSpan="2">
                                            Taille
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {recentFiles.map((item, key) => (
                                        <tr key={key}>
                                            <td><Link to="#" className="text-dark fw-medium">{getFileIcon(item.name, 16)} {item.name}</Link></td>
                                            <td>{toLocalDate(item.createdAt)}</td>
                                            <td>{toLocalDate(item.lastModified)}</td>
                                            <td>{(item.size / 1000).toFixed(2)} KB</td>
                                            <td>
                                                <UncontrolledDropdown>
                                                    <DropdownToggle tag="a" className="font-size-16 text-muted" role="button">
                                                        <i className="mdi mdi-dots-horizontal"></i>
                                                    </DropdownToggle>

                                                    <DropdownMenu className="dropdown-menu-end">
                                                        <DropdownItem href="#">
                                                            <div className="hstack gap-1">
                                                                <i className="mdi mdi-download text-info font-size-16 " />
                                                                <span className="">Télécharger</span>
                                                            </div>
                                                        </DropdownItem>
                                                        <DropdownItem href="#">
                                                            <div className="hstack gap-1">
                                                                <i className="mdi mdi-close text-danger font-size-16 " />
                                                                <span className="">Supprimer</span>
                                                            </div>

                                                        </DropdownItem>

                                                    </DropdownMenu>
                                                </UncontrolledDropdown>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </CardBody>}

                {isLoading && <CardBody>
                    <FileListPlaceHolders />
                </CardBody>}

                <CardFooter className="border">
                    <div className="d-flex justify-content-between">
                        <div className="hstack gap-2">

                            <div className="hstack gap-1">
                                <i className="mdi mdi-folder text-warning ms-2 font-size-16" /> :
                                <span className="text-muted">Dossier</span>
                            </div>
                            <div className="hstack gap-1">
                                <i className="mdi mdi-file ms-2 font-size-16" /> :
                                <span className="text-muted">Fichier</span>
                            </div>

                            <div className="hstack gap-1">
                                <i className="mdi mdi-cloud-check text-success ms-2 font-size-16" /> :
                                <span className="text-muted">Indexé</span>
                            </div>
                        </div>
                        <div className="hstack gap-2">
                            <div className="hstack gap-1">
                                <i className="mdi mdi-folder text-warning ms-2 font-size-16" /> :
                                <span className="text-muted">{stats?.totalDirs || 0}</span>
                            </div>

                            <div className="hstack gap-1">
                                <i className="mdi mdi-file ms-2 font-size-16" /> :
                                <span className="text-muted">{stats?.totalFiles || 0}</span>
                            </div>
                        </div>
                    </div>
                </CardFooter>
            </Card>
        </React.Fragment>
    )
}

export default RecentFile;
