import { useIsVisible } from "hooks/visibility";
import { Link } from "react-router-dom";
import { Card, CardBody, Modal, ModalHeader, Spinner } from "reactstrap";
import { getFileIcon, truncateFromMiddle } from "utils";
import React, { memo, useEffect, useRef, useState } from "react";
import { map } from "lodash";
import PdfViewer from "components/DocumentViewer/pdfViewer";
import ResourceViewer from "components/ResourceViewer/fileviewer";
import { withTranslation } from "react-i18next";

const FileReferences = ({ references, size, onSuggestionClicked, t }) => {

    const [open, setOpen] = useState(false);
    const [showFile, setShowFile] = useState(false);
    const [selectedFile, setSelectFile] = useState(null);
    const [sources, setSources] = useState([]);

    const openFile = (file) => {
        const location = file.webUrl || file.location;
        if (location.startsWith("http")) {
            window.open(location, "_blank");
            return;
        }

        setSelectFile(file);
        setShowFile(true);
    }

    useEffect(() => {
        const distinctSources = {}

        references.forEach(ref => {
            if (!(ref.location in distinctSources)) {
                distinctSources[ref.location] = {
                    ref: ref,
                    pages: [ref.position]
                }
            } else {
                distinctSources[ref.location].pages.push(ref.position)
            }
        });

        setSources(Object.values(distinctSources));

    }, [references])


    return <div className="vstack gap-1 answer-references">


        < div className="hstack gap-2" >
            {map(references?.slice(0, 3), item => {
                return <ResourceViewer resource={item} size={size} />
            })
            }
        </div >
        <div className="hstack gap-1">

            <Link className="" onClick={e => setOpen(!open)}>{t("Toutes les réferences")}</Link>
            {!open && <i className="mdi mdi-chevron-right-circle" />
            }
            {open && <i className="mdi mdi-chevron-down-circle" />
            }
        </div>
        {
            open && <ul className="list-unstyled">
                {map(sources, source => {
                    return <li className="text-muted mb-2 ms-2">
                        <div className="hstack gap-1">
                            <i className="mdi mdi-chevron-right-circle" />
                            {getFileIcon(source.ref.name, 14)}
                            <a href="#!" onClick={() => openFile(source.ref)}> {truncateFromMiddle(source.ref.name, 50)}</a>
                            {source.pages && <span>- page {source.pages.slice(0, 10).join(" | ")}</span>}
                        </div>
                    </li>
                })}
            </ul>
        }
    </div >
}

export default withTranslation()(FileReferences);
