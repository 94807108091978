

import React, { useEffect, useState } from "react";
import {
    Card,
    CardBody,
    CardTitle,
    Col,
    Container, Row
} from "reactstrap";
import SimpleBar from "simplebar-react"

//i18n
import { withTranslation } from "react-i18next";
import { map } from "lodash";

const ItemVIew = ({ item, id, onSelectionChange }) => {

    const [selected, setSelected] = useState(true);

    useEffect(() => {
        onSelectionChange({
            id: id,
            state: selected
        });
    }, [selected])

    switch (item.type) {

        case "Title":
            return <div className="hstack gap-2 mb-2 mt-2">
                <input type="checkbox" checked={true} className="mb-2" />
                <h6>{item.text}</h6>
            </div>

        case "ListItem":
            return <div className="ms-4 hstack gap-2">
                <input type="checkbox" checked={selected} className="" value={selected} onChange={e => setSelected(e.target.checked)} />
                <span className="">{item.text}</span>
            </div>
    }
}

const DocumentStructure = ({ structure }) => {

    const onSelectionChange = (item) => {

    }

    return (
        <div className="p-2 border workspace-document-structure">
            <SimpleBar className="rich-text-editor">
                <div className=" ps-2 pt-2 ">
                    <h5>Structure du document </h5>
                    <hr />
                </div>

                <div className="">
                    {map(structure, (item, index) => {
                        return <ItemVIew item={item} id={index} onSelectionChange={onSelectionChange} />
                    })}
                </div>
            </SimpleBar>

        </div>
    );
};


export default withTranslation()(DocumentStructure);

