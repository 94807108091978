import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
    Table,
    DropdownMenu,
    DropdownToggle,
    UncontrolledDropdown,
    DropdownItem,
    Card,
    CardBody,
} from "reactstrap"

const TodoListPlaceHolders = () => {


    return (
        <React.Fragment>

            <div className="assistant-leftsidebar leftbar">
                <div className="assistant-leftsidebar-nav">
                    <ul className="list-unstyled assistant-chat-list">
                        <li className="placeholder-glow mb-3">
                            <div className="d-flex justify-content-between">
                                <div className="flex-grow-1 overflow-hidden">
                                    <span className="placeholder col-6"></span>
                                    <span className="placeholder col-12"></span>
                                </div>
                                <div className="font-size-11">
                                    <span className="placeholder col-3"></span>
                                </div>

                            </div>
                        </li>

                        <li className="placeholder-glow mb-3">
                            <div className="d-flex justify-content-between">
                                <div className="flex-grow-1 overflow-hidden">
                                    <span className="placeholder col-6"></span>
                                    <span className="placeholder col-12"></span>
                                </div>
                                <div className="font-size-11">
                                    <span className="placeholder col-3"></span>
                                </div>

                            </div>
                        </li>

                        <li className="placeholder-glow mb-3">
                            <div className="d-flex justify-content-between">
                                <div className="flex-grow-1 overflow-hidden">
                                    <span className="placeholder col-6"></span>
                                    <span className="placeholder col-12"></span>
                                </div>
                                <div className="font-size-11">
                                    <span className="placeholder col-3"></span>
                                </div>

                            </div>
                        </li>

                        <li className="placeholder-glow mb-3">
                            <div className="d-flex justify-content-between">
                                <div className="flex-grow-1 overflow-hidden">
                                    <span className="placeholder col-6"></span>
                                    <span className="placeholder col-12"></span>
                                </div>
                                <div className="font-size-11">
                                    <span className="placeholder col-3"></span>
                                </div>

                            </div>
                        </li>

                        <li className="placeholder-glow mb-3">
                            <div className="d-flex justify-content-between">
                                <div className="flex-grow-1 overflow-hidden">
                                    <span className="placeholder col-6"></span>
                                    <span className="placeholder col-12"></span>
                                </div>
                                <div className="font-size-11">
                                    <span className="placeholder col-3"></span>
                                </div>

                            </div>
                        </li>

                    </ul>
                </div>
            </div>
        </React.Fragment>
    )
}

export default TodoListPlaceHolders;
