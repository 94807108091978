import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
    Table,
    DropdownMenu,
    DropdownToggle,
    UncontrolledDropdown,
    DropdownItem,
    Card,
    CardBody,
} from "reactstrap"

const FileListPlaceHolders = () => {


    return (
        <React.Fragment>
            <div>
                <Card>
                    <CardBody>
                        <div className="recentFiles">
                            <div className="d-flex flex-wrap">
                                <h5 className="font-size-16 me-3">Fichiers récents</h5>

                                <div className="ms-auto">
                                    <Link to="#" className="fw-medium text-reset">Tout voir</Link>
                                </div>
                            </div>
                            <hr className="mt-2" />

                            <div className="table-responsive">
                                <Table className="table align-middle table-nowrap table-hover mb-0">
                                    <thead>
                                        <tr>
                                            <th scope="col">Nom</th>
                                            <th scope="col" >Date de création</th>
                                            <th scope="col">Dernière modification</th>
                                            <th scope="col" >Taille</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className="placeholder-glow">
                                            <td><span className="placeholder col-12"></span></td>
                                            <td><span className="placeholder col-6"></span></td>
                                            <td><span className="placeholder col-7"></span></td>
                                            <td><span className="placeholder col-12"></span></td>

                                        </tr>

                                        <tr className="placeholder-glow">
                                            <td><span className="placeholder col-12"></span></td>
                                            <td><span className="placeholder col-6"></span></td>
                                            <td><span className="placeholder col-7"></span></td>
                                            <td><span className="placeholder col-12"></span></td>
                                        </tr>
                                        <tr className="placeholder-glow">
                                            <td><span className="placeholder col-12"></span></td>
                                            <td><span className="placeholder col-6"></span></td>
                                            <td><span className="placeholder col-7"></span></td>
                                            <td><span className="placeholder col-12"></span></td>
                                        </tr>
                                        <tr className="placeholder-glow">
                                            <td><span className="placeholder col-12"></span></td>
                                            <td><span className="placeholder col-6"></span></td>
                                            <td><span className="placeholder col-7"></span></td>
                                            <td><span className="placeholder col-12"></span></td>
                                        </tr>
                                        <tr className="placeholder-glow">
                                            <td><span className="placeholder col-12"></span></td>
                                            <td><span className="placeholder col-6"></span></td>
                                            <td><span className="placeholder col-7"></span></td>
                                            <td><span className="placeholder col-12"></span></td>
                                        </tr>

                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    </CardBody>
                </Card>
            </div>
        </React.Fragment>
    )
}

export default FileListPlaceHolders;
