import React, { Fragment, useEffect, useState } from "react"
import { Col, Row } from "reactstrap";
import AppLineage from "./leftbar";
import AppExecution from "./content";
import { isEmpty } from "lodash";


const CustomAppRunner = ({ app, directory, files, onConfigChange, runTask, taskConfig, isRunning, onFinish, onProgressChange }) => {
    const [filesToRun, setFilesToRun] = useState(null);
    const [selectedFile, setSelectedFile] = useState(null);
    const [progress, setProgress] = useState(0);

    const onFileStateChange = (location, state, status, isSelected = null) => {
        const items = [...filesToRun];
        items.forEach(file => {
            if (file.location === location) {
                file.isRunning = state;
                file.status = status || file.status;
                file.isProcessed = file.status === 'success' || file.status === 'error';
                file.isSelected = isSelected !== null ? isSelected : file.isSelected;
            }
        });
        setFilesToRun(items);
    }
    const onFileStart = (location) => {
        onFileStateChange(location, true, 'pending');
    }

    const onFileEnd = (location, status) => {
        onFileStateChange(location, false, status ? 'success' : 'error');
    }

    const onFileChecked = (file, isSelected) => {
        onFileStateChange(file.location, file.isRunning, file.status, isSelected);
    }


    useEffect(() => {

        if (isEmpty(files))
            return;
        const items = files.filter(f => f.isSelected);

        items.forEach(s => {
            s.isRunning = false;
            s.isSelected = true;
        });

        setFilesToRun(items);

    }, [files])

    useEffect(() => {
        const items = filesToRun || [];
        const processed = (items).filter(f => f.isProcessed);
        setProgress(processed.length);

    }, [filesToRun])

    useEffect(() => {
        if (runTask)
            setProgress(0);
    }, [runTask])

    useEffect(() => {
        onProgressChange(progress)
    }, [progress])

    return <div className="custom-app-runner">
        <Row>
            <Col lg={4} xs={5}>
                <AppLineage
                    app={app}
                    selectedFile={selectedFile}
                    onFileSelected={setSelectedFile}
                    isRunning={isRunning}
                    directory={directory}
                    files={filesToRun}
                    onFileChecked={onFileChecked}
                    progress={progress}
                />
            </Col>

            <Col lg={8} xs={7}>
                <AppExecution
                    app={app}
                    files={filesToRun}
                    selectedFile={selectedFile}
                    onFileStart={onFileStart}
                    onFileEnd={onFileEnd}
                    isRunning={isRunning}
                    onConfigChange={onConfigChange}
                    startTask={runTask}
                    taskConfig={taskConfig}
                    onFinish={onFinish}
                />
            </Col>
        </Row>

    </div>

}

export default CustomAppRunner;