import React, { memo, useEffect, useState, useCallback } from 'react';
import { Handle, Position, useUpdateNodeInternals, useStore, useHandleConnections } from '@xyflow/react';
import { Badge, Card, CardFooter, Collapse, DropdownItem, DropdownMenu, DropdownToggle, Label, UncontrolledDropdown } from 'reactstrap';
import classnames from "classnames";
import { isEmpty, map } from 'lodash';
import { runPromptOptimization } from 'service/tasks';
import { useSelector } from "react-redux";
import { withTranslation } from "react-i18next";

import rehypeRaw from 'rehype-raw'
import remarkGfm from "remark-gfm";
import ReactMarkdown from 'react-markdown'
import MultiHandle from '../../handle/MultiHandle';
import SingleHandle from '../../handle/SingleHandle';
import Select from "react-select"

const splitsTypes = [
    {
        label: 'Page',
        value: 'page',
        defaultSize: 1,
        sizeLabel: "Nombre de page par découpe"
    },
    {
        label: 'Caractères',
        value: 'chars',
        defaultSize: 10000,
        sizeLabel: "Nombre de caractères par découpe"

    },
    {
        label: 'Tokens',
        value: 'tokens',
        defaultSize: 3000,
        sizeLabel: "Nombre de tokens par découpe"

    }
]

const SplitNode = ({ llms, data, onChange, type, t }) => {
    const [collapsed, setCollapsed] = useState(true);
    const [nodeName, setNodeName] = useState(data.name);

    const [selectedModel, setSelectedModel] = useState({
        id: data?.modelId,
        title: data?.modelName
    });
    const [instructions, setInstructions] = useState(data?.content);
    const [nodeStatus, setNodeStatus] = useState(false);
    const [isLoading, setIsLoading] = useState(data?.isLoading);
    const [splitMode, setSplitMode] = useState(splitsTypes.find(s => s.value === data?.splits?.mode));
    const [splitSize, setSplitSize] = useState(data?.splits?.size);


    useEffect(() => {

        onChange({
            ...data,
            name: nodeName,
            isLoading: isLoading,
            isValid: !isEmpty(nodeName) && !isEmpty(splitMode?.value) && splitSize > 0,
            splits: {
                mode: splitMode?.value,
                size: splitSize
            }
        })
    }, [nodeName, isLoading, splitMode, splitSize])

    useEffect(() => {
        setNodeStatus(data.isValid);
    }, [data]);

    useEffect(() => {
        if (isEmpty(splitMode))
            return;

        setSplitSize(splitMode.defaultSize);
    }, [splitMode])

    return <Card style={{ maxWidth: '350px' }}>
        <div className="accordion accordion-flush" id="accordion">
            <div className="accordion-item">
                <div className="accordion-header" id="headingOne">
                    <button
                        className={classnames(
                            "accordion-button",
                            "fw-medium",
                            { collapsed: !collapsed }
                        )}
                        type="button"
                        onClick={() => setCollapsed(!collapsed)}
                        style={{ cursor: "pointer" }}
                    >
                        <div className='vstack'>
                            <div className="title hstack gap-0">
                                {data.icon && <i className={data.icon + " font-size-20"} />}
                                <input className="border-0 form-control" style={{ background: 'transparent' }} type='text' value={nodeName} onChange={e => setNodeName(e.target.value)} />

                            </div>
                            <small className='ps-4'>{data.description}</small>
                            <div className="subline ">
                                {nodeStatus && <div className="text-success ms-4"><small > Valide </small><i className='mdi mdi-check-circle' /></div>}
                                {!nodeStatus && <div className="text-warning ms-4"><small > Invalide </small><i className='mdi mdi-alert' /></div>}
                            </div>
                        </div>


                    </button>
                </div>

                <Collapse isOpen={collapsed}>
                    <div className="accordion-body nodrag">
                        <div className='vstack gap-3'>

                            <div>
                                <Label>{t("Type de découpe")}:</Label>
                                <Select
                                    options={splitsTypes}
                                    className='select2-selection'
                                    value={splitMode}
                                    onChange={setSplitMode}
                                />
                            </div>
                            {splitMode && <div>
                                <Label>{t(splitMode?.sizeLabel)}:</Label>
                                <input type='number'
                                    value={splitSize}
                                    className='form-control'
                                    onChange={e => setSplitSize(e.target.value)}
                                    min={1}
                                />
                            </div>}
                        </div>

                    </div>
                </Collapse>
                <div className='pb-2'>
                    <SingleHandle nodeId={data?.id} type="target" position={Position.Left} />
                </div>

                <SingleHandle nodeId={data?.id} type="source" position={Position.Right} />
            </div>




            {/* <Handle type="target" position={Position.Left} id='1' key={"1"} />
            <Handle type="target" position={Position.Left} id='2' key={"2"} />
            <Handle type="source" position={Position.Right} /> */}

        </div>
        <CardFooter className='border-top'>
            <div className='d-flex justify-content-between'>
                <div>

                </div>
                <div className=''>
                    <div className='hstack gap-1 mt-1'>
                        <Badge className='badge badge-soft-info'>{data.owner}</Badge>
                        {data.tags && map(data.tags.slice(0, 2), tag => {
                            return <Badge className='badge badge-soft-info'>{tag}</Badge>
                        })}
                    </div>

                </div>
            </div>
        </CardFooter>
    </Card >
}

export default withTranslation()(memo(SplitNode));