import React from 'react';
import { Badge } from 'reactstrap';
import { Link } from 'react-router-dom';
import { getInitiales, getItemType, getProviderLogo } from 'utils';
import { RotatingLines } from 'react-loader-spinner';
import { map } from 'lodash';


const SelectUser = (cell) => {
    return <div className="form-check font-size-16">
        <input className="form-check-input" type="checkbox" id={"datasource-" + cell.value} />
        <label className="form-check-label" htmlFor="upcomingtaskCheck01"></label>
    </div>
}

const Name = (cell) => {
    return <Link className='font-size-14 text-muted'>
        {cell.value ? cell.value : ""}
    </Link>
};

const FullName = (cell) => {
    const name = cell.row.original.name || "";
    const firstName = cell.row.original.firstName || "";
    const fullName = name + ' ' + firstName;

    return <div className='hstack'>
        <div className="avatar-xs me-3">
            <span className="avatar-title rounded-circle bg-info bg-soft text-info font-size-18">
                {getInitiales(fullName || "")}
            </span>
        </div>
        <span>{fullName}</span>

    </div>
};



const Role = (cell) => {

    let roles = cell.row.original.roles || [];
    let remaining = 0;

    if (roles.length > 3) {
        remaining = roles.length - 3;
        roles = roles.slice(0, 3)
    }

    return <div className='hstack gap-1'>
        {map(roles, role => {
            return <Badge className="badge-soft-info font-size-12">{role?.name}</Badge>
        })}
        {remaining > 0 && <Badge className='badge-soft-info' >+{remaining} autres</Badge>}
    </div>
};

const Scope = (cell) => {


    switch (cell.value) {

        case "private":
            return <Badge className="badge-soft-info">Personal</Badge>
        default:
            return <Badge className="badge-soft-info">Organization</Badge>
    }
};




export { Name, Role, Scope, SelectUser, FullName };