import React from "react";
import {
    Container
} from "reactstrap";
//Import Breadcrumb
import Breadcrumbs from "../../../../components/Common/Breadcrumb2";

//i18n
import { withTranslation } from "react-i18next";
import RolesListView from "./list";
const RoleManagement = props => {

    //meta title
    document.title = "Rôles et permissions | Jask";

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs
                        title={props.t("Rôles et Usages")}
                        breadcrumbItems={[
                            {
                                title: "Jask"
                            },
                            {
                                title: "sécurité"
                            },
                            {
                                title: "Rôles"
                            }
                        ]}
                    />
                    <RolesListView />
                </Container>
            </div>
        </React.Fragment>
    );
};


export default withTranslation()(RoleManagement);
