import { ADD_OMNICHAT_MESSAGE_HISTORY, CREATE_OMNICHAT_CONVERSATION, CREATE_OMNICHAT_CONVERSATION_FAILED, CREATE_OMNICHAT_CONVERSATION_SUCCESS, GET_OMNICHAT_CONVERSATION_HISTORY, GET_OMNICHAT_CONVERSATION_HISTORY_FAILED, GET_OMNICHAT_CONVERSATION_HISTORY_SUCCESS } from "./actionTypes"

export const createOmniChatConversation = payload => ({
  type: CREATE_OMNICHAT_CONVERSATION,
  payload: payload,
})

export const createOmniChatConversationSuccess = payload => ({
  type: CREATE_OMNICHAT_CONVERSATION_SUCCESS,
  payload: payload,
})

export const createOmniChatConversationFailed = payload => ({
  type: CREATE_OMNICHAT_CONVERSATION_FAILED,
  payload: payload,
})


export const getOmniChatConversationHistory = payload => ({
  type: GET_OMNICHAT_CONVERSATION_HISTORY,
  payload: payload,
})

export const getOmniChatConversationHistorySuccess = payload => ({
  type: GET_OMNICHAT_CONVERSATION_HISTORY_SUCCESS,
  payload: payload,
})

export const getOmniChatConversationHistoryFailed = payload => ({
  type: GET_OMNICHAT_CONVERSATION_HISTORY_FAILED,
  payload: payload,
})

export const addOmniChatMessageHistory = payload => ({
  type: ADD_OMNICHAT_MESSAGE_HISTORY,
  payload: payload,
})