import React, { createRef, useEffect, useState } from "react";


const CompareFilseInstruction = ({ }) => {

    return <div>

    </div>
}

export default CompareFilseInstruction;