import React, { Fragment, useEffect, useState } from "react";
import PropTypes from 'prop-types';
import classnames from "classnames"
import { Link, useLocation } from "react-router-dom";
import SimpleBar from "simplebar-react"
import EmptyTodoList from "../empty"
import {
    Row, Col,
    Nav, NavItem, NavLink, TabContent,
    TabPane,
    DropdownMenu,
    DropdownItem,
    DropdownToggle,
    UncontrolledDropdown,
    UncontrolledTooltip
} from "reactstrap";
import TodoItemView from "./item";
import CreateTodoItem from "../create";
import { useSelector, useDispatch } from "react-redux";

import {
    delProfileTodoCategory,
    deleteTodoWorks,
    getWorkspaceSettings,
    getTodoWorks as onGetTodos,
    updateTodoWorks
} from "store/actions";
import { isEmpty, map } from "lodash";
import TodoLeftBar from "./leftbar";
import UsefulResources from "../actions/resources";
import CustomAppRunner from "../actions/customApp";
import DeleteModal from "components/Common/DeleteModal";
import AddTodoCategory from "../folder";
import { withTranslation } from "react-i18next";


const TodoListView = ({ t }) => {

    const [activeTab, setactiveTab] = useState(1);
    const [selectedTodo, setSelectedTodo] = useState(null);
    const [selectedApp, setSelectedApp] = useState(null);
    const [selectedBinder, setSelectedBinder] = useState(null);
    const [showCreate, setShowCreate] = useState(false);
    const [showDelete, setShowDelete] = useState(false);
    const [showAddTodo, setShowAddTodo] = useState(false);
    const [showResources, setShowResources] = useState(false);
    const [showCustomAppRunner, setShowCustomAppRunner] = useState(false);
    const [tasks, setTasks] = useState([]);
    const [showDeleteBinder, setShowDeleteBinder] = useState(false);

    const dispatch = useDispatch();
    const { todoItems, isLoading, binders } = useSelector(state => ({
        todoItems: state.Todos.todos,
        isLoading: state.Todos.isLoading,
        binders: state.Todos.settings.binders,
    }));

    const refresh = () => {
        dispatch(onGetTodos());
        dispatch(getWorkspaceSettings())
    }

    const runCustomApp = (app) => {
        setSelectedApp(app);
        setShowCustomAppRunner(!showCustomAppRunner);
    }

    const handleDeletion = () => {
        dispatch(deleteTodoWorks(selectedTodo?.id));
        setShowDelete(!showDelete)
    }

    const startBinderDeletion = (binderId) => {
        setSelectedBinder(binderId);
        setShowDeleteBinder(!showDeleteBinder);
    }

    const handleBinderDeletion = () => {
        dispatch(delProfileTodoCategory(selectedBinder))
        setShowDeleteBinder(!showDeleteBinder)
    }

    useEffect(() => {

        dispatch(onGetTodos());
        dispatch(getWorkspaceSettings())

    }, [dispatch])

    useEffect(() => {
        setTasks([...todoItems]);
    }, [todoItems]);

    useEffect(() => {
        if (tasks)
            setSelectedTodo(tasks[0])
    }, [tasks]);

    useEffect(() => {

        if (!isEmpty(binders) && !binders.map(b => b.id).includes(activeTab))
            setactiveTab(1);

    }, [binders])

    useEffect(() => {
        const defaults = [1, 2, 3];
        if (!defaults.includes(activeTab)) {
            setTasks(todoItems.filter(i => i.binders.includes(activeTab)));
        } else {
            setTasks(todoItems)
        }

    }, [activeTab])

    return <div className=" ">
        <CustomAppRunner show={showCustomAppRunner} onCloseClick={() => setShowCustomAppRunner(!showCustomAppRunner)} todo={selectedTodo} app={selectedApp} />
        <UsefulResources todo={selectedTodo} show={showResources} onCloseClick={() => setShowResources(!showResources)} />
        <CreateTodoItem show={showCreate} onCloseClick={() => setShowCreate(!showCreate)} />
        <DeleteModal show={showDelete} onCloseClick={() => setShowDelete(!showDelete)} onDeleteClick={handleDeletion} />
        <DeleteModal show={showDeleteBinder} onCloseClick={() => setShowDeleteBinder(!showDeleteBinder)} onDeleteClick={handleBinderDeletion} text={"Voulez-vous vraiment supprimer ce classseur ?"} />
        <AddTodoCategory show={showAddTodo} onCloseClick={() => setShowAddTodo(!showAddTodo)} />

        <Row className="mb-1">
            <Col lg={12}>
                <div className="d-flex align-items-center">
                    <h5 className="mb-0 card-title flex-grow-1">{t("Ci-dessous la liste de vos tâches")}  </h5>
                    <div className="flex-shrink-0">
                        <div className="text-sm-end">
                            <div className="hstack gap-2">
                                <Link to="#!" onClick={refresh} className="btn btn-light  me-1"><i className="mdi mdi-refresh"></i></Link>
                                <UncontrolledDropdown>
                                    <DropdownToggle tag="button" className="btn btn-success me-2" >
                                        {t("Créer")}
                                    </DropdownToggle>
                                    <DropdownMenu className="dropdown-menu-start">
                                        <DropdownItem
                                            onClick={() => setShowAddTodo(true)}
                                        >
                                            <div className="hstack gap-2">
                                                <i className="fa fa-folder font-size-14 text-warning " id="edittooltip"></i>
                                                <span>{t("Un classeur")}</span>
                                            </div>
                                            <UncontrolledTooltip placement="top" target="edittooltip">
                                                {t("Nouvelle categorie")}
                                            </UncontrolledTooltip>
                                        </DropdownItem>
                                        <div className="dropdown-divider" />
                                        <DropdownItem
                                            onClick={() => {
                                                setShowCreate(!showCreate)
                                            }}>
                                            <div className="hstack gap-2">
                                                <i className="bx bx-task font-size-16 text-danger " id="processTip"></i>
                                                <span> {t("Nouvelle tache")}</span>
                                            </div>
                                            <UncontrolledTooltip placement="top" target="processTip">
                                                Nouvelle tache
                                            </UncontrolledTooltip>
                                        </DropdownItem>

                                        {/* <DropdownItem
                                            onClick={() => {
                                                setShowCreate(!showCreate)
                                            }}>
                                            <div className="hstack gap-2">
                                                <i className="bx bx-task font-size-16 text-danger " id="processTip"></i>
                                                <span> Une tache prédéfinie</span>
                                            </div>
                                            <UncontrolledTooltip placement="top" target="processTip">
                                                Nouvelle tache
                                            </UncontrolledTooltip>
                                        </DropdownItem> */}
                                    </DropdownMenu>

                                </UncontrolledDropdown>
                            </div>
                        </div>
                    </div>
                </div>
            </Col>
        </Row>
        <Nav pills className="bg-light  rounded" role="tablist">
            <NavItem>
                <NavLink
                    className={classnames({ active: activeTab === 1 })}
                    onClick={() => {
                        setactiveTab(1)
                    }}
                >
                    <i className="bx bx-task text-info align-middle me-1" />
                    {t("Toutes")}  {activeTab === 1 && <span>({tasks.length})</span>}
                </NavLink>
            </NavItem>
            {/* <NavItem>
                <NavLink
                    className={classnames({ active: activeTab === 2 })}
                    onClick={() => {
                        setactiveTab(2)
                    }}
                >
                    <i className="bx bx-task text-danger align-middle me-1" />
                    En attentes
                </NavLink>
            </NavItem> */}
            {/* <NavItem>
                <NavLink
                    className={classnames({ active: activeTab === 3 })}
                    onClick={() => {
                        setactiveTab(3)
                    }}
                >
                    <i className="bx bx-task text-success align-middle me-1" />
                    Terminées
                </NavLink>
            </NavItem> */}
            {map(binders || [], item => {
                return <NavItem key={item.id}>
                    <NavLink
                        className={classnames({ active: activeTab === item.id })}
                        onClick={() => {
                            setactiveTab(item.id)
                        }}
                    >
                        <div className="hstack gap-1">
                            <i className="mdi mdi-folder text-warning align-middle " />
                            {item.name}
                            {activeTab === item.id && <div className="d-flex"><span>({tasks.length})</span><Link onClick={() => startBinderDeletion(item.id)}><i className="bx bxs-trash text-danger font-size-14 ms-2 " /></Link></div>}
                        </div>
                    </NavLink>
                </NavItem>
            })}
            <NavItem>
                <NavLink
                >

                    <Link className="text-success"
                        onClick={() => setShowAddTodo(true)}
                    >
                        <i className="fa fa-plus font-size-16" />
                    </Link>

                </NavLink>
            </NavItem>
        </Nav>

        <div className="todolist">
            {tasks?.length > 0 &&
                <TabPane activeTab={activeTab} className="mt-4">
                    <div className="d-flex flex-row ">
                        <TodoLeftBar isLoading={isLoading}
                            items={tasks}
                            selectedItem={selectedTodo}
                            onItemSelected={setSelectedTodo} />

                        {!isEmpty(selectedTodo) &&
                            <TodoItemView
                                todo={selectedTodo}
                                onShowResourceClick={() => setShowResources(!showResources)} onRunCustomApp={runCustomApp} onDelete={() => setShowDelete(!showDelete)} />
                        }

                    </div>

                </TabPane>
            }

            {tasks?.length === 0 && <EmptyTodoList onCreate={() => {
                setShowCreate(!showCreate)
            }} />}
        </div>
    </div>

}

export default withTranslation()(TodoListView);
