import { toDate, toLocalDate } from "helpers/to-local-date";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Dropzone from "react-dropzone";

import {
    Badge,
    Card,
    CardBody,
    CardFooter,
    CardTitle,
    Container,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    UncontrolledDropdown
} from "reactstrap";

import BotIcon from "assets/images/bot.png"
import { isEmpty, map } from "lodash";
import { uploadTaskFiles } from "service/documents";
import { uuidv4 } from "utils";

const Application = ({ app, onDelete, onOpenApp, onRunApp, onEditApp }) => {

    const [activated, setActivated] = useState(false);
    const [uploading, setUploading] = useState(false);

    function handleAcceptedFiles(files) {
        setUploading(true);
        const target_dir = `workspace/applications/${app?.name}`
        uploadTaskFiles(files, target_dir).then(uploadedFiles => {
            setActivated(false);
            onRunApp(app, uploadedFiles)
        });
    }

    const onDrapEnter = () => {
        setActivated(true);
    }

    const onDrapLeave = () => {
        setActivated(false);
    }

    return <Card className={"border border-info rounded-3 m-2 app border-opacity-25" + (!activated ? "" : "bg-success")}
    >
        <Dropzone
            onDragEnter={onDrapEnter}
            onDragLeave={onDrapLeave}

            onDrop={acceptedFiles => {
                handleAcceptedFiles(acceptedFiles)
            }}
            noClick={true}
        >

            {({ getRootProps, getInputProps }) => (
                <div  {...getRootProps()}>
                    <input {...getInputProps()} />
                    <div className="hstack  p-1" onClick={() => onOpenApp(app)}>
                        <img src={app.image} width={50} height={80} />

                        <CardBody className="app-content">
                            <CardTitle>{app.name}
                            </CardTitle>
                            {app.description}
                        </CardBody>

                    </div>
                    <div className={"border-top app-actions "}>
                        <div className="float-start ms-2">
                            <div className="hstack gap-1 mt-1">
                                <Badge color="info">{app.principalName}</Badge>

                                {map(app.tags || [], item => {
                                    return <Badge color="danger">{item}</Badge>
                                })}
                            </div>

                        </div>
                        <div className="float-end me-2">
                            <UncontrolledDropdown direction="down">
                                <DropdownToggle tag="a">
                                    <Link><i className="mdi mdi-cog font-size-16" /></Link>
                                </DropdownToggle>
                                <DropdownMenu >
                                    {app.app && <DropdownItem onClick={() => onOpenApp(app)}>
                                        Démarrer
                                    </DropdownItem>}
                                    <DropdownItem onClick={() => onEditApp(app)}>
                                        Editer
                                    </DropdownItem>
                                    <DropdownItem onClick={() => onDelete(app)}>
                                        Supprimer
                                    </DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        </div>
                    </div>
                </div>
            )}
        </Dropzone>

    </Card>

};


export default Application;
