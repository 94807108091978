import React, { useEffect, useState, useRef, createRef, useCallback } from "react";
import { Badge, Col, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Row, Spinner, UncontrolledDropdown, UncontrolledTooltip } from "reactstrap";
import "react-perfect-scrollbar/dist/css/styles.css";

import { getFileIcon, truncateFromMiddle, uuidv4 } from "utils";
import { capitalize, isEmpty, map, truncate } from "lodash";
import Question from "./message/question";
import Answer from "./message/answer";
import { Link } from "react-router-dom";
import LandingChat from "./landing";
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import { withTranslation } from "react-i18next";

import usFlag from "../../../../assets/images/flags/us.jpg"
import french from "../../../../assets/images/flags/french.jpg"
import { uploadTaskFiles } from "service/documents";
import SimpleBar from "simplebar-react"
import AssistantInputChat from "components/Assistant/InputChat";
import { useSelector, useDispatch } from "react-redux";
import { addAssistantSuggestion, AddFilesToConversation, deleteFileFromConversation } from "service/assistant";
import DeleteModal from "components/Common/DeleteModal";
import { onAbortAssistantRequest, onAskAssistant } from "store/actions";
import { TaskStatus } from "constants/tasks";
import Dropzone from "react-dropzone";
import { operationCompleted } from "utils/notifications";
import { ResourceTypes } from "constants/general";
import PromptStoreSelector from "components/prompt/browser";
import ResourceCard from "./ResourceCard";




const BaseChat = ({
    title,
    description,
    logoUrl,
    icon,
    currentConversation,
    onShowConversationList,
    onNewConversation,
    assistant,
    datasource,
    openAssistant,
    onDeleteConversation,
    conversations,
    t
}) => {

    const [channelId, setChannelId] = useState();
    const [currentMessage, setCurrentMessage] = useState("");
    const [streamData, setStreamData] = useState({ text: "" });
    const [isReceiving, setIsReceiving] = useState(false);
    const [promptHistory, setPromptHistory] = useState([]);
    const [attachments, setAttachments] = useState([]);
    const [isLiveAgent, setIsLiveAgent] = useState(false);
    const displayMessageContainerRef = useRef();
    const [confirmClear, setShowConfirmClear] = useState(false);
    const [conversationFiles, setConversationFiles] = useState([]);
    const [conversationReferences, setConversationReferences] = useState([]);
    const [showChatResources, setShowChatResources] = useState(false);
    const [resourceFilter, setResourceFilters] = useState(null);
    const [isTyping, setIsTyping] = useState(false);
    const [showPromptStore, setShowPromptStore] = useState(false);
    const [externalValue, setExternValue] = useState(null);
    const [isExtendedInput, setIsExtendedInput] = useState(false);
    const [suggestions, setSuggestions] = useState({});
    const suggestionsRef = useRef(suggestions);

    const dispatch = useDispatch();

    const { accessToken, channels } = useSelector(state => ({
        accessToken: state.Login.accessToken,
        channels: state.Streams.channels
    }));

    const dropzoneRef = createRef()

    const {
        transcript,
        listening,
        resetTranscript,
        browserSupportsSpeechRecognition
    } = useSpeechRecognition();

    const resourceViewFilter = (item, resourceFilter) => {
        if (isEmpty(resourceFilter))
            return true;

        return item.name.toLowerCase().startsWith(resourceFilter.toLowerCase()) || item.name.toLowerCase().includes(resourceFilter.toLowerCase());
    }

    const onSubmit = (request, sources, apps, files) => {

        if (isEmpty(request))
            return;

        sendCurrentMessage(request, sources, apps, files);
    }

    const resendPrompt = prompt => {
        sendCurrentMessage(prompt);
    }


    const scrollToBottom = (smooth) => {
        if (displayMessageContainerRef?.current) {
            const scrollEl = displayMessageContainerRef.current;
            scrollEl?.scroll({
                top: scrollEl?.scrollHeight + 1000,
                behavior: smooth ? 'smooth' : 'auto',
            });
        }
    }

    const clearChat = () => {
        onDeleteConversation(currentConversation?.id);
        setShowConfirmClear(!confirmClear);
    }

    const sendCurrentMessage = (prompt, sources, apps, files) => {
        const attachments = (files || []).map(f => f.location)
        const message = {
            id: uuidv4(),
            sentByUser: true,
            role: "user",
            text: prompt,
            conversationId: currentConversation.id,
            attachments: attachments,
            datasources: (sources || []).map(source => ({ providerId: ResourceTypes.DATASOURCES, id: source.id, name: source.name, type: source.providerType })),
            applications: (apps || []).map(app => app.id),
            fileReferences: attachments
        }

        setCurrentMessage("");
        setExternValue("")
        resetTranscript();
        dispatch(onAskAssistant({
            channelId: assistant?.id || datasource?.id,
            conversationId: currentConversation?.id,
            message: message,
            token: accessToken,
            agentName: assistant?.agentName,
            agentLogo: assistant?.agentLogo,
            createdAt: new Date(),
            cancellationToken: new AbortController(),

        }))
    }

    function handleAcceptedFiles(files) {
        const target_dir = `workspace/assistants/${title}/conversations/${currentConversation.id}/attachments`

        uploadTaskFiles(files, target_dir)
            .then(uploadedFiles => {
                const existing = attachments.map(a => a.location);
                const toAdd = uploadedFiles.filter(f => !existing.includes(f.location)).map(f => ({
                    ...f, status: TaskStatus.NOT_STARTED
                }));

                return toAdd

            }).then(async (newFiles) => {

                const files = newFiles.map(f => ({ ...f, status: TaskStatus.STARTED }))
                const allFiles = [...conversationFiles, ...files]
                setConversationFiles(allFiles);

                const request = {
                    assistantId: currentConversation.appId,
                    assistantOwner: currentConversation.appOwner,
                    conversationId: currentConversation.id,
                    files: newFiles
                }

                return AddFilesToConversation(currentConversation.id, request).then(resp => {
                    const updatedFiles = newFiles.map(f => f.location);
                    const files = [...allFiles];

                    files.forEach(f => {
                        if (updatedFiles.includes(f.location)) {
                            f.status = TaskStatus.COMPLETED;
                        }
                    });

                    setConversationFiles(files);
                });
            });
    }

    const removeAttachment = file => {

        const task = {
            location: file.location,
            assistantId: currentConversation.appId,
            assistantOwner: currentConversation.appOwner,
            conversationId: currentConversation.id
        }
        deleteFileFromConversation(currentConversation.id, task).then(() => {
            // deleteUploadedFile(file.location).then(() => {
            setConversationFiles([...conversationFiles.filter(a => a.location !== file.location)]);
            operationCompleted("Fichier supprimé avec succès!");
            // });
        })
    }

    const handleAudioConversation = (continuous = false) => {
        setIsLiveAgent(continuous);
        if (!listening) {
            SpeechRecognition.startListening({ continuous: continuous, language: 'en-US' })
        }
        else {
            SpeechRecognition.stopListening();
        }
    }

    const handleInputChange = ({ value, mentions }) => {
        setCurrentMessage(value);
    }

    const openResourceLocation = (location) => {
        if (location.startsWith("http")) {
            window.open(location, "_blank");
            return;
        }
    }

    const onCancelRequest = () => {

        const conversationId = currentConversation?.id;
        const streamId = channelId;
        const channelData = channels[streamId];
        const request = channelData[conversationId];
        request.cancellationToken.abort();

        dispatch(onAbortAssistantRequest({
            channelId: streamId,
            conversationId: conversationId,
        }));

    }

    const onPromptSelected = (prompt) => {
        setExternValue(prompt.content);
        setShowPromptStore(!showPromptStore);
    }

    const onNewSuggestion = (theme, suggestion) => {
        suggestionsRef.current[theme] = suggestion;

    }

    useEffect(() => {

    }, [])


    useEffect(() => {
        setPromptHistory(streamData?.messages?.filter(item => item.role === "user") || []);
        scrollToBottom(false);

        let references = {}

        if (streamData?.messages?.length > 0) {
            streamData?.messages.forEach(m => {
                (m.references || []).filter(ref => ref.type === ResourceTypes.FILE).forEach(ref => {
                    references[ref.location] = ref;
                });
            });


            setConversationReferences(Object.values(references));
        } else {
            setConversationReferences([]);
        }

    }, [streamData])

    useEffect(() => {
        if (!listening && transcript) {
            sendCurrentMessage(transcript);
            resetTranscript();
        }

    }, [listening])

    useEffect(() => {
        setConversationFiles((currentConversation?.attachments || []));
        suggestionsRef.current = {}
    }, [currentConversation]);

    useEffect(() => {

        if (isEmpty(channelId))
            return;

        const currentConversationId = currentConversation?.id;
        const channelData = channels[channelId];
        const typing = channelData?.isTyping;
        setIsTyping(typing || false);

        if (!isEmpty(channelData) && currentConversationId in channelData) {
            setStreamData(prev => channelData[currentConversationId]);
        }

        if (!typing && !isEmpty(suggestionsRef.current)) {
            const suggestions = Object.keys(suggestionsRef.current).map(key => {
                return {
                    category: key,
                    content: suggestionsRef.current[key]
                }
            });
            const payload = {
                assistantId: channelId,
                suggestions: suggestions
            }

            addAssistantSuggestion(channelId, payload)
        }

    }, [channels, channelId, currentConversation]);



    const onChannelOrConversationChanged = (channelId) => {
        setChannelId(channelId);
    }


    useEffect(() => {

        if (isEmpty(assistant?.id))
            return;

        onChannelOrConversationChanged(assistant.id);

    }, [assistant]);

    useEffect(() => {

        if (isEmpty(datasource?.id))
            return;

        onChannelOrConversationChanged(datasource.id);

    }, [datasource]);


    useEffect(() => {
        setExternValue(transcript);
    }, [transcript])


    useEffect(() => {
        const state = currentMessage?.split("\n")?.length > 1 || conversationFiles?.length > 0 || currentMessage?.length > 100 || externalValue?.length > 100;
        setIsExtendedInput(state);
    }, [currentMessage, conversationFiles]);



    return <div className="assistant-chat  ">
        <DeleteModal
            show={confirmClear}
            onCloseClick={() => setShowConfirmClear(false)}
            onDeleteClick={clearChat}
            text={t("Voulez-vous vraiment vider l'historique ?")}
        />
        <div className="chat-header  p-2 rounded">
            <div className="d-flex justify-content-between">
                <div>
                    <div className="vstack ">
                        {<ResourceCard
                            name={title}
                            description={description}
                            logo={logoUrl}
                            icon={icon}
                            modelName={assistant?.app?.modelName}
                            type={assistant?.app?.type}
                        />}

                        <div className="hstack gap-1 ms-4">
                            {map(conversationFiles, file => {
                                return <div className="">
                                    <div className="rounded bg-light bg-opacity-50 border border-info p-1 border-opacity-10 ">
                                        <div className="hstack gap-1 attachment">
                                            {getFileIcon(file.name)}
                                            <span className="font-size-10">{truncateFromMiddle(file.name, 30)}</span>

                                            {file.status === TaskStatus.STARTED && <Spinner className="ms-2" size={"sm"} />}
                                            {(file.status === TaskStatus.COMPLETED || file.status === TaskStatus.CANCELLED) && <Link onClick={() => removeAttachment(file)} ><i className="mdi mdi-close text-danger font-size-14" /></Link>}
                                        </div>
                                    </div>
                                </div>
                            })}
                        </div>
                    </div>

                </div>
                <div className="m-2">
                    <UncontrolledDropdown>
                        <DropdownToggle tag={"a"}>
                            <div className="hstack gap-1 text-dark">
                                <i className="bx bxs-chat font-size-18" />
                                <span className="text-muted">Conversations <Badge color="info">{conversations?.length}</Badge></span>
                            </div>

                        </DropdownToggle>

                        <DropdownMenu>
                            <DropdownItem onClick={onNewConversation}>{t("Nouvelle conversation")}</DropdownItem>
                            <DropdownItem onClick={onShowConversationList}>{t("Mes conversations")}</DropdownItem>
                            <DropdownItem onClick={() => setShowConfirmClear(true)}>{t("Effacer l'historique")}</DropdownItem>
                        </DropdownMenu>


                    </UncontrolledDropdown>
                </div>
            </div>
        </div>
        <div className={isExtendedInput ? "chat-content-reduced" : "chat-content"}>
            <SimpleBar className="scroller" scrollableNodeProps={{ ref: displayMessageContainerRef }}>
                <Dropzone
                    onDrop={acceptedFiles => {
                        handleAcceptedFiles(acceptedFiles)
                    }}
                    noClick={true}
                    ref={dropzoneRef}
                >
                    {({ getRootProps, getInputProps }) => (

                        <div className="chat-history p-2"  {...getRootProps()} ref={displayMessageContainerRef}>
                            <input {...getInputProps()} />

                            {streamData?.messages?.length == 0 && <LandingChat assistant={assistant} onExampleClicked={resendPrompt} />}
                            {streamData?.messages?.length > 0 && <div className=" p-3"  >

                                <ul className="list-unstyled">
                                    <li>
                                        <div className="chat-day-title">
                                            <span className="title">{t(currentConversation.title) || "Just Ask"}</span>
                                        </div>

                                    </li>

                                    {streamData?.messages && map(streamData.messages, message => {
                                        return <li className={message.role === "user" ? "right" : ""} >
                                            <div className="conversation-list ">
                                                {message.role === "user" && <Question message={message} />}
                                                {message.role === "assistant" &&
                                                    <Answer message={message}
                                                        agentName={currentConversation?.agentName}
                                                        agentLogo={currentConversation?.agentLogo}
                                                        onOpenAssistant={openAssistant}
                                                        onSuggestionClicked={resendPrompt}
                                                        assistant={assistant}
                                                        onNewSuggestion={onNewSuggestion}

                                                    />}
                                            </div>
                                        </li>
                                    })}

                                    <li>
                                        <div style={{ height: 10 }}></div>
                                    </li>

                                </ul>
                            </div>}
                            {/* {history?.length == 0 && <TaskLoader />} */}
                        </div>
                    )}
                </Dropzone>
            </SimpleBar >
        </div>

        <div className=" assistant-input    ps-4 pe-4  d-flex flex-column align-self-end " >
            <div className="d-flex justify-content-between">
                <div>
                    <div className="suggestions gap-2 ">

                        {map(streamData.suggestions || [], suggestion => {
                            return <div onClick={() => resendPrompt(suggestion.content)} className="suggestion-button flex-grow-1 p-1 border border-info border-opacity-50 rounded-3">
                                {/* <div className="border-bottom pb-1">{capitalize(key)}</div> */}
                                {suggestion.content}
                            </div>
                        })}
                    </div>

                </div>

                <div className="pb-2">
                    <div className="hstack gap-1 bg-danger bg-opacity-25 rounded-4 p-1 ps-2 pe-2">
                        <i className="mdi mdi-comment-text mt-1" />
                        <Link onClick={() => setShowPromptStore(!showPromptStore)} className="font-size-12 text-muted" >{t("Prompts Store")}</Link>
                        {showPromptStore && <PromptStoreSelector
                            show={showPromptStore}
                            onCloseClick={() => setShowPromptStore(!showPromptStore)}
                            onPromptSelected={onPromptSelected}
                        />}
                    </div>

                </div>
            </div>
            <div className="assistant-input-section ps-4 pe-4 mb " >
                <Row>
                    <Col>
                        <div className="position-relative p-2">
                            <AssistantInputChat
                                onValueChange={handleInputChange}
                                onSubmit={onSubmit}
                                readOnly={isReceiving || isEmpty(currentConversation?.id)}
                                externalValue={externalValue}
                                files={conversationFiles}
                                references={conversationReferences}
                                isRunning={isTyping}
                                onCancel={onCancelRequest}
                                isExtendedInput={isExtendedInput}
                            />

                        </div>
                    </Col>
                </Row>
                {
                    attachments && <div className="d-flex flex-wrap ">
                        {map(attachments, file => {
                            return <div className="p-2 ">
                                <div className="rounded bg-light bg-opacity-50 border border-info p-1 border-opacity-10 ">
                                    <div className="hstack gap-1 attachment">
                                        {getFileIcon(file.name)}
                                        <span className="font-size-10">{truncateFromMiddle(file.name, 30)}</span>
                                        <Link onClick={() => removeAttachment(file)} ><i className="mdi mdi-close text-danger font-size-14" /></Link>
                                    </div>
                                </div>
                            </div>
                        })}

                    </div>
                }
            </div>
            <div className="d-flex justify-content-between">
                <div className="hstack gap-3 ps-4 pb-2">
                    <Link id="importFile" onClick={() => dropzoneRef?.current?.open()}>
                        <i className="mdi mdi-file-upload font-size-20" />
                        <UncontrolledTooltip placement="top" target="importFile">
                            {t("Importer un fichier")}
                        </UncontrolledTooltip>
                    </Link>
                    {/* <Link><i className=" mdi mdi-history font-size-20" />

                    </Link> */}
                    {/* <Link><i className=" mdi mdi-export-variant font-size-20" /></Link> */}
                    {/* <UncontrolledDropdown id="lang" className="dropup">
                        <DropdownToggle
                            href="#"
                            tag="a" className="dropdown-toggle ">
                            <img src={lang.flag} height="12" className="me-2" />
                            <UncontrolledTooltip placement="top" target="lang">
                                {t("Langue de préference")}
                            </UncontrolledTooltip>
                        </DropdownToggle>

                        <DropdownMenu className="dropdown-menu-start dropup">
                            <div className="dropdown-header noti-title">
                                <h5 className="font-size-12 text-muted text-truncate mn-0">{t("Langue de préférence")}</h5>
                            </div>
                            <div className="dropdown-divider"></div>

                            {map(languages, lang => {
                                return <DropdownItem onClick={() => setLang(lang)}
                                >
                                    <img src={lang.flag} height="12" className="me-2" />
                                    {lang.title}
                                </DropdownItem>
                            })}

                        </DropdownMenu>
                    </UncontrolledDropdown> */}
                    <UncontrolledDropdown className="dropup" disabled={history.length == 0}>
                        <DropdownToggle
                            id="promptHisto"
                            href="#"
                            tag="a" className="dropdown-toggle ">
                            <i className=" mdi mdi-comment-text font-size-20" />
                            <UncontrolledTooltip placement="top" target="promptHisto">
                                {t("Historique des prompts")}
                            </UncontrolledTooltip>
                        </DropdownToggle>
                        <DropdownMenu className="dropdown-menu-start dropup">
                            <div className="dropdown-header noti-title border-bottom">
                                <h5 className="font-size-12 text-muted text-truncate mn-0"> {t("Historique des prompts")}</h5>
                            </div>


                            {map(promptHistory.reverse(), (item, index) => {
                                return <DropdownItem key={item.id} className={index < promptHistory.length - 1 ? "border-bottom p-2 ps-4" : "p-2 ps-4"}
                                    onClick={() => resendPrompt(item.text)}
                                > <span className="text-muted font-size-12">{item.text.slice(0, 100)}</span>
                                </DropdownItem>
                            })}

                        </DropdownMenu>
                    </UncontrolledDropdown>

                    <Dropdown
                        className="dropup"
                        direction="up"
                        isOpen={showChatResources}
                        toggle={() => setShowChatResources(!showChatResources)}

                    >
                        <DropdownToggle
                            id="references"
                            href="#"
                            tag="a" className="dropdown-toggle "
                            onClick={() => setShowChatResources(!showChatResources)}

                        >
                            <div>
                                <i className=" mdi mdi-database font-size-20" />
                                <span className="position-absolute ms-1 mt-1 top-0 start-100 translate-middle badge rounded-pill bg-danger">{conversationReferences?.length < 100 ? conversationReferences?.length : "+99"} <span className="visually-hidden">unread messages</span></span>
                            </div>
                            <UncontrolledTooltip placement="top" target="references">
                                {t("Ressources consultées")}
                            </UncontrolledTooltip>
                        </DropdownToggle>

                        <DropdownMenu className="dropdown-menu- dropup border border-info border-opacity-25">
                            <div className="dropdown-header noti-title border-bottom">
                                <h5 className="font-size-12 text-muted text-truncate mn-0"><i className="mdi mdi-database me" /> {t("Ressources consultées")} ({conversationReferences?.length})</h5>
                            </div>
                            <div className="vstack gap-2">
                                <div className="ms-4 me-4 mb-2">
                                    <input type="text" className="form-control rounded-4"
                                        placeholder={t("Rechercher des fichiers") + "..."}
                                        onChange={e => setResourceFilters(e.target.value)}
                                        value={resourceFilter}
                                    />
                                </div>
                                <SimpleBar className="scroller-references" >
                                    {map(conversationReferences.filter(item => resourceViewFilter(item, resourceFilter)), (ref, index) => {

                                        return <DropdownItem key={index} onClick={() => openResourceLocation(ref.webUrl || ref.location)} >

                                            <div className="hstack gap-1">
                                                {getFileIcon(ref.name)}
                                                {truncate(ref.name, { length: 60 })}
                                            </div>
                                        </DropdownItem>
                                    })}
                                </SimpleBar>
                            </div>


                        </DropdownMenu>
                    </Dropdown>

                    {/* <UncontrolledDropdown className="dropup" disabled={history.length == 0}>
                        <DropdownToggle
                            href="#"
                            tag="a" className="dropdown-toggle ">
                            <i className=" mdi mdi-lightbulb-on-outline font-size-20" />
                        </DropdownToggle>
                        <DropdownMenu className="dropdown-menu-start dropup">
                            <div className="dropdown-header noti-title border-bottom">
                                <h5 className="font-size-12 text-muted text-truncate mn-0">
                                    <div className="hstack gap-1">
                                        <i className=" mdi mdi-lightbulb-on-outline font-size-20 " />
                                        <div className="vstack gap-1 mt-1">
                                            <span>  Actions  rapides</span>
                                            <small>  (à partir de cette conversation)</small>
                                        </div>
                                    </div>
                                </h5>
                            </div>

                            {map(smartActions, (item, index) => {
                                return <DropdownItem className={index < smartActions.length - 1 ? "border-bottom p-2 ps-4 " : "p-2 ps-4"}
                                // onClick={() => resendPrompt(item.text)}
                                > <span className="text-muted font-size-12"> {index + 1}. {item.title}</span>
                                </DropdownItem>
                            })}

                        </DropdownMenu>
                    </UncontrolledDropdown>
                    <UncontrolledDropdown className="dropup" disabled={history.length == 0}>
                        <DropdownToggle
                            href="#"
                            tag="a" className="dropdown-toggle ">
                            <i className=" mdi mdi-apps font-size-20" />
                        </DropdownToggle>
                        <DropdownMenu className="dropdown-menu-start dropup">
                            <div className="dropdown-header noti-title border-bottom">
                                <div className="hstack gap-1">
                                    <i className=" mdi mdi-apps font-size-20" />
                                    <h5 className="font-size-12 text-muted text-truncate mn-0 mt-2">Executer une application ou workflow</h5>
                                </div>
                            </div>

                            {map(smartActions, (item, index) => {
                                return <DropdownItem className={index < smartActions.length - 1 ? "border-bottom p-2" : "p-2"}
                                // onClick={() => resendPrompt(item.text)}
                                > <span className="text-muted font-size-12"> {index + 1}. {item.title}</span>
                                </DropdownItem>
                            })}

                        </DropdownMenu>
                    </UncontrolledDropdown> */}

                    <div>
                        {!browserSupportsSpeechRecognition ?
                            <Link disabled>
                                <i className=" mdi   mdi-microphone-off font-size-20" />
                            </Link>
                            : <div className="hstack gap-3">
                                <Link id="mic" onClick={() => handleAudioConversation(false)}>
                                    <UncontrolledTooltip placement="top" target="mic">
                                        {t("Démarrer le micro")}
                                    </UncontrolledTooltip>
                                    <i className={" mdi   mdi-microphone font-size-20 " + (listening && !isLiveAgent ? "text-success" : "")} /></Link>
                                <Link id="mic-live" onClick={() => handleAudioConversation(true)}><i className={" mdi   mdi-microphone-plus font-size-20 " + (listening && isLiveAgent ? "text-success" : "")} />
                                    <UncontrolledTooltip placement="top" target="mic-live">
                                        {t("Démarrer le micro en continue")}
                                    </UncontrolledTooltip>

                                </Link>
                            </div>
                        }
                    </div>
                </div>


                <div>
                    <small className="text-muted">{t("Les réponses sont générées par l'IA")}</small>
                </div>
                <div className="ml-auto  pe-4">
                    <Link onClick={() => onSubmit(currentMessage)}><i className="mdi mdi-send-outline font-size-20" /></Link>
                </div>

            </div>
        </div>

    </div >
}

export default withTranslation()(BaseChat);
