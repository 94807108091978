import React, { memo, ReactNode, useCallback, useEffect, useState } from 'react';
import { Handle, Position } from '@xyflow/react';
import { Link } from "react-router-dom";
import { Badge, Card, CardBody, CardFooter, CardTitle, Collapse, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import classnames from "classnames";
import { isEmpty, map } from 'lodash';
import Select from "react-select"

const inputTypes = [
    {
        id: 'all',
        title: 'Texte et fichiers ',
        description: 'Concatenation du texte et du contenu des fichiers'
    },
    {
        id: 'textOnly',
        title: 'Texte',
        description: 'Appliquer les instructions le texte fourni'
    },
    {
        id: 'fileOnly',
        title: 'Fichiers',
        description: 'Appliquer les instructions sur chaque fichier uniquement'
    },
    {
        id: 'rowOnly',
        title: 'Ligne par ligne',
        description: 'Appliquer les instructions sur chaque ligne'
    },
]

const analyzers = [

    {
        id: 'fast',
        title: 'Rapide',
        avalaible: true,
        description: 'Analyse uniquement le texte',
        tags: ["Rapide", "Moins couteux", "éfficace"]
    },
    {
        id: 'advanced',
        title: 'Avancée',
        description: 'Analyse  texte,  images, graphiques, ....',
        avalaible: true,
        tags: ["Lent", "Plus couteux", "Performant"]
    }
]

const getStatusClassName = (status) => {

    if (!status)
        return "text-danger";
    else {
        return "text-success";
    }
}

const KnowledgeNode = ({ llms, data, onChange, type }) => {
    const [collapsed, setCollapsed] = useState(false);
    const [nodeName, setNodeName] = useState(data.name);
    const [selectedModel, setSelectedModel] = useState({});
    const [instructions, setInstructions] = useState(data?.instructions);
    const [nodeStatus, setNodeStatus] = useState(false);
    const [processingType, setProcessingType] = useState(analyzers[0]);

    const onModelChange = (model) => {
        setSelectedModel(model);
    }

    useEffect(() => {
        if (isEmpty(selectedModel))
            setSelectedModel(llms[0]);
    }, [llms])

    useEffect(() => {
        onChange({
            ...data,
            name: nodeName,
            modelId: selectedModel?.id,
            modelName: selectedModel?.title,
            instructions: instructions,
            processingType: processingType?.id,
            isValid: !isEmpty(nodeName) && !isEmpty(instructions) && instructions.length > 3 && !isEmpty(selectedModel?.id)
        })
    }, [nodeName, selectedModel, instructions, processingType])

    useEffect(() => {
        setNodeStatus(data.isValid);
    }, [data])

    return <Card>
        <div className="accordion accordion-flush" id="accordion">
            <div className="accordion-item">
                <div className="accordion-header" id="headingOne">
                    <button
                        className={classnames(
                            "accordion-button",
                            "fw-medium",
                            { collapsed: !collapsed }
                        )}
                        type="button"
                        onClick={() => setCollapsed(!collapsed)}
                        style={{ cursor: "pointer" }}
                    >
                        <div className='vstack'>
                            <div className="title hstack gap-0">
                                {data.icon && <i className={data.icon + " font-size-20"} />}
                                <input className="border-0 form-control" style={{ background: 'transparent' }} type='text' value={nodeName} onChange={e => setNodeName(e.target.value)} />

                            </div>
                            <div className="subline ms-3">
                                {nodeStatus && <div className="text-success ms-4"><small > Valide </small><i className='mdi mdi-check-circle' /></div>}
                                {!nodeStatus && <div className="text-warning ms-4"><small > Invalide </small><i className='mdi mdi-alert' /></div>}                            </div>
                        </div>


                    </button>
                </div>

                <Collapse isOpen={collapsed}>

                    <div className="accordion-body ">
                        <div className='mb-2 nodrag'>
                            <textarea
                                placeholder="Décrivez votre domaine ou expertise métier, axes d'analyse ..."
                                className='form-control font-size-10'
                                rows={5}
                                value={instructions}
                                onChange={e => setInstructions(e.target.value)}
                            />
                        </div>

                    </div>
                </Collapse>
            </div>
            <Handle type="target" position={Position.Left} />
            <Handle type="source" position={Position.Right} />
        </div>
        <CardFooter className='border-top'>
            <div className='d-flex justify-content-between'>
                <div>
                    <div className='hstack'>
                        <UncontrolledDropdown className="dropup ms-1" disabled={history.length == 0}>
                            <DropdownToggle
                                href="#"
                                tag="a" className="dropdown-toggle ">
                                <Badge className='badge badge-soft-info' > {selectedModel?.title}</Badge>
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-start dropup">
                                <div className="dropdown-header noti-title border-bottom">
                                    <h5 className="font-size-12 text-muted text-truncate mn-0">
                                        <div className="hstack gap-1">
                                            <div className="vstack gap-1 mt-1">
                                                <span className=''>  Modèles d'IA à utiliser </span>
                                            </div>
                                        </div>
                                    </h5>
                                </div>

                                {map(llms, (item, index) => {
                                    return <DropdownItem className={index < inputTypes.length - 1 ? "border-bottom p-2 ps-4 " : "p-2 ps-4"}
                                        onClick={() => onModelChange(item)}
                                    >
                                        <div className='vstack gap-1'>
                                            <div className='d-flex justify-content-between'>
                                                <span className="text-muted font-size-12">
                                                    {item.title}
                                                </span>
                                                {item.isRecommended && <small><i className='mdi mdi-star text-warning font-size-14' />
                                                </small>}
                                            </div>

                                            <small className='font-size-10'>{item.description}</small>
                                        </div>

                                    </DropdownItem>
                                })}


                            </DropdownMenu>
                        </UncontrolledDropdown>


                    </div>
                </div>
                <div className='d-flex flex-row'>
                    <UncontrolledDropdown className="dropup ms-1" disabled={history.length == 0}>
                        <DropdownToggle
                            href="#"
                            tag="a" className="dropdown-toggle ">
                            <div className='hstack gap-1 mt-1'>
                                <small className='text-muted font-size-10'>Traitement:</small>  <Badge className='badge badge-soft-info' >  {processingType?.title}</Badge>

                            </div>
                        </DropdownToggle>
                        <DropdownMenu className="dropdown-menu-start dropup">
                            <div className="dropdown-header noti-title border-bottom">
                                <h5 className="font-size-12 text-muted text-truncate mn-0">
                                    <div className="hstack gap-1">
                                        <div className="vstack gap-1 mt-1">
                                            <span className=''>  Traitement des données </span>
                                        </div>
                                    </div>
                                </h5>
                            </div>

                            {map(analyzers, (item, index) => {
                                return <DropdownItem className={index < inputTypes.length - 1 ? "border-bottom p-2 ps-4 " : "p-2 ps-4"}
                                    onClick={() => setProcessingType(item)}
                                >
                                    <div className='vstack gap-1'>

                                        <span className="text-muted font-size-12">
                                            {item.title}
                                        </span>
                                        <small className='font-size-10'>{item.description}</small>
                                        <div className='hstack gap-1 mt-1'>
                                            {map(item.tags || [], tag => {
                                                return <Badge color='info' style={{ fontSize: 8 }}>{tag}</Badge>
                                            })}

                                        </div>
                                    </div>

                                </DropdownItem>
                            })}


                        </DropdownMenu>
                    </UncontrolledDropdown>
                </div>
            </div>
        </CardFooter>
    </Card>
}

export default memo(KnowledgeNode);