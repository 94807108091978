import React, { Fragment, useEffect, useMemo, useState } from "react";
import {

} from "reactstrap";

import LocalFS from "./Local"
import Web from "./Web"
import SharePointConnectivity from "./Sharepoint";
import AzureStorageConnectivity from "./Azure"
import SalesforceConnectivity from "./Salesforce"
import { DatasourceProviders } from "constants/general";
import { DATASOURCES_PROVIDER } from "helpers/url_helper";
import BingSearch from "./BingSearch";
import GoogleSearch from "./Google Search";
import Jira from "./Jira";

function Connectivity({ type, onSetConnection }) {

    const renderConnectivity = () => {

        if (!type) {
            return <Fragment></Fragment>
        }
        switch (type) {

            case DatasourceProviders.LOCAL:
                return <LocalFS onSetConnection={onSetConnection} />
            case DatasourceProviders.WEB:
                return <Web onSetConnection={onSetConnection} />
            case DatasourceProviders.SHAREPOINT:
                return <SharePointConnectivity onSetConnection={onSetConnection} />
            case DatasourceProviders.AZURE:
                return <AzureStorageConnectivity onSetConnection={onSetConnection} />

            case DatasourceProviders.SALESFORCE:
                return <SalesforceConnectivity onSetConnection={onSetConnection} />

            case DatasourceProviders.BING_SEARCH:
                return <BingSearch onSetConnection={onSetConnection} />

            case DatasourceProviders.GOOGLE_SEARCH:
                return <GoogleSearch onSetConnection={onSetConnection} />

            case DatasourceProviders.JIRA:
                return <Jira onSetConnection={onSetConnection} />
        }
    }

    return (<React.Fragment>

        {renderConnectivity()}

    </React.Fragment>)
}

export default Connectivity;