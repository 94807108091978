import React, { useState } from "react"
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import CreateTodoItem from "pages/Workspace/todolist/create";
import { copyToClipboard } from "utils";
import { withTranslation } from "react-i18next";


const SaveContent = ({ content, className, sourceId, sourceType, sourceName, sourceIcon, t }, props) => {
    const [showCreate, setShowCreate] = useState(false);
    return <div >

        <CreateTodoItem
            show={showCreate}
            onCloseClick={() => setShowCreate(!showCreate)}
            content={content}
            sourceId={sourceId}
            sourceType={sourceType}
            sourceName={sourceName}
            sourceIcon={sourceIcon}
        />

        <UncontrolledDropdown>
            <DropdownToggle tag={"a"}>
                <i className={"mdi mdi-content-copy " + className} />
            </DropdownToggle>
            <DropdownMenu>
                <DropdownItem onClick={() => setShowCreate(!showCreate)}>{t("Nouvelle tache")}</DropdownItem>
                <DropdownItem divider />
                <DropdownItem onClick={() => copyToClipboard(content)}>{t("Copier le contenu")}</DropdownItem>
            </DropdownMenu>
        </UncontrolledDropdown>
    </div>
}

export default withTranslation()(SaveContent);
