export const CREATE_STUDIO_APP = "CREATE_STUDIO_APP"
export const CREATE_STUDIO_APP_SUCCESS = "CREATE_STUDIO_APP_SUCCESS"
export const CREATE_STUDIO_APP_FAILED = "CREATE_STUDIO_APP_FAILED"

export const GET_STUDIO_APP = "GET_STUDIO_APP"
export const GET_STUDIO_APP_SUCCESS = "GET_STUDIO_APP_SUCCESS"
export const GET_STUDIO_APP_FAILED = "GET_STUDIO_APP_FAILED"

export const DEL_STUDIO_APP = "DEL_STUDIO_APP"
export const DEL_STUDIO_APP_SUCCESS = "DEL_STUDIO_APP_SUCCESS"
export const DEL_STUDIO_APP_FAILED = "DEL_STUDIO_APP_FAILED"

export const ASSISTANT_STATE_CHANGED = "ASSISTANT_STATE_CHANGED"
