import { API_URL, del, get, post, put } from "helpers/api_helper";
import { fetchEventSource } from "@microsoft/fetch-event-source";
import { ADD_CONVERSATION_FILES, GET_ASSISTANTS, GET_CONVERSATIONS, GET_OMNICHAT_CONVERSATIONS } from "helpers/url_helper";

export const ask = async (message, onConnect, onMessage, onError, onclose, accessToken) => {

    await fetchEventSource(`${API_URL}/assistant/ask/sse`,
        {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`,
            },
            body: JSON.stringify(message),
            // signal: ctrl.signal,
            openWhenHidden: true,
            onopen(res) {
                if (res.ok && res.status === 200) {
                    console.log("Connection made ", res);
                    onConnect();

                } else if (
                    res.status >= 400 &&
                    res.status < 500 &&
                    res.status !== 429
                ) {
                    console.log("Client side error ", res);
                    onError(res);
                }
            },
            onmessage(event) {
                onMessage(event)
            },
            onclose() {
                console.log("Connection closed by the server");
                onclose();

            },
            onerror(err) {
                console.log("There was an error from server:", err);
                onError(err);
                throw err
            },
        });
};

export const getConversationMessages = (conversationId) => {
    return get(`${GET_CONVERSATIONS}/${conversationId}/messages`)
}

export const getOrCreateConversation = (info) => {
    return post(`${GET_OMNICHAT_CONVERSATIONS}`, info)
}

export const createConversationWithApp = (conversationId, appid, appOwner) => {
    return post(`${GET_CONVERSATIONS}/${conversationId}/applications/${appid}/owners/${appOwner}`)
}

export const createConversationWithDatasource = (conversationId, datasourceId) => {
    return post(`${GET_CONVERSATIONS}/${conversationId}/datasources/${datasourceId}`)
}


export const getAssistantConversations = (assistant_id) => {
    return get(`${GET_ASSISTANTS}/${assistant_id}/conversations`)
}

export const getDatasourceConversations = (datasource_id) => {
    return get(`${GET_ASSISTANTS}/datasources/${datasource_id}/conversations`)
}

export const AddFilesToConversation = (conversationId, payload) => {
    return put(`${GET_CONVERSATIONS}/${conversationId}/files`, payload)
}

export const AddFilesToAssistant = (payload) => {
    return put(`${GET_ASSISTANTS}/content/share/files`, payload)
}

export const deleteFileFromConversation = (conversationId, payload) => {
    return post(`${GET_CONVERSATIONS}/${conversationId}/files`, payload)
}

export const addAssistantFeedback = (conversationId, payload) => {
    return post(`${GET_ASSISTANTS}/${conversationId}/feedbacks`, payload)
}

export const addAssistantSuggestion = (assistantId, payload) => {
    return post(`${GET_ASSISTANTS}/${assistantId}/suggestions`, payload)
}

export const getAssistantSuggestions = (assistantId, top = 5) => {
    return get(`${GET_ASSISTANTS}/${assistantId}/suggestions?last_n=${top}`)
}
