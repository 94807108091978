import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { Badge, Card, CardTitle, Col, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Row, Spinner, UncontrolledDropdown, UncontrolledTooltip } from "reactstrap";
import { capitalize, isEmpty, map, truncate } from "lodash";
import { Link } from "react-router-dom";
import ResourceCard from "../ResourceCard";
import { TaskStatus } from "constants/tasks";
import logoDark from "../../../../../assets/images/logos/jask-8.png";
import logoLight from "../../../../../assets/images/logos/jask-6.png";
import { useSelector, useDispatch } from "react-redux";
import { layoutModeTypes } from "constants/layout";
import { getAssistantSuggestions } from "service/assistant";
import SearchChat from "./chat"
import { onAskAssistant } from "store/actions";
import { uuidv4 } from "utils";
import { motion } from "framer-motion";
import DeleteModal from "components/Common/DeleteModal";

const SearchGPT = ({ title,
    description,
    logoUrl,
    icon,
    conversations,
    onDeleteConversation,
    onNewConversation,
    onShowConversationList,
    assistant,
    currentConversation,
    t
}) => {

    const [suggestions, setSuggestions] = useState([]);
    const [searchValue, setSearchValue] = useState(null);
    const [channelId, setChannelId] = useState(null);
    const [hasSearched, setHasSearched] = useState(false);
    const [confirmClear, setShowConfirmClear] = useState(false);

    const dispatch = useDispatch();

    const { theme, account, channels, accessToken } = useSelector(state => ({
        theme: state.Layout.layoutModeType,
        account: state.Profile.account,
        channels: state.Streams.channels,
        accessToken: state.Login.accessToken,

    }));

    const clearChat = () => {
        onDeleteConversation(currentConversation?.id);
        setShowConfirmClear(!confirmClear);
    }

    const onSubmit = () => {
        sendSearchRequest()
    }
    const onKeyPress = (e) => {
        const { key } = e;
        if (key === "Enter" && !e.shiftKey && !isEmpty(searchValue)) {
            e.preventDefault();
            onSubmit();
        }
    }

    const onSuggestionClicked = (content) => {
        setSearchValue(content);
        sendSearchRequest(content)
    }



    const sendSearchRequest = (value) => {
        const task_id = uuidv4()
        const message = {
            id: task_id,
            sentByUser: true,
            role: "user",
            mode: "search",
            text: value || searchValue,
            conversationId: currentConversation.id,
        }

        dispatch(onAskAssistant({
            channelId: assistant?.id,
            conversationId: currentConversation?.id,
            message: message,
            token: accessToken,
            agentName: assistant?.agentName,
            agentLogo: assistant?.agentLogo,
            createdAt: new Date(),
            cancellationToken: new AbortController(),
            type: "search",
            continuation: null,
            isSearch: true,
            taskId: task_id,

        }))
    }



    useEffect(() => {
        getAssistantSuggestions(assistant?.id, 3).then(data => {
            setSuggestions(data);
        });
        setChannelId(assistant?.id);
    }, [assistant]);


    useEffect(() => {

        if (isEmpty(channelId)) {
            return;
        }

        const currentConversationId = currentConversation?.id;
        const channelData = channels[channelId];

        if (!isEmpty(channelData) && currentConversationId in channelData) {
            const data = channelData[currentConversationId];
            const isRunning = data?.isSearching === true;
            setHasSearched(!isEmpty(data?.search) || isRunning)
            setSearchValue(data?.searchRequest)

        }

    }, [channels, channelId, currentConversation]);

    return <Card className="search-gpt  ">
        {setShowConfirmClear && <DeleteModal
            show={confirmClear}
            onCloseClick={() => setShowConfirmClear(false)}
            onDeleteClick={clearChat}
            text={t("Voulez-vous vraiment vider l'historique ?")}
        />}

        <div className="chat-header p-2 rounded">
            <div className="d-flex justify-content-between">
                <div>
                    <div className="vstack">
                        {<ResourceCard
                            name={title}
                            description={description}
                            logo={logoUrl}
                            icon={icon}
                            modelName={assistant?.app?.modelName}
                            type={assistant?.app?.type}
                        />}
                    </div>

                </div>
                <div className="m-2">
                    <UncontrolledDropdown>
                        <DropdownToggle tag={"a"}>
                            <div className="hstack gap-1 text-">
                                <i className="bx bxs-chat text-info font-size-16" />
                                <span className="text-white">Conversations <Badge color="danger">{conversations?.length}</Badge></span>
                            </div>

                        </DropdownToggle>

                        <DropdownMenu>
                            <DropdownItem onClick={onNewConversation}>{t("Nouvelle conversation")}</DropdownItem>
                            <DropdownItem onClick={onShowConversationList}>{t("Mes conversations")}</DropdownItem>
                            <DropdownItem onClick={() => setShowConfirmClear(true)}>{t("Effacer l'historique")}</DropdownItem>
                        </DropdownMenu>


                    </UncontrolledDropdown>
                </div>
            </div>
        </div>
        {!hasSearched &&
            <motion.div className="landing  h-100 w-100 "



            >
                <div className=" ">
                    <span className="logo-lg" data-layout-mode="dark" >
                        {theme === layoutModeTypes.DARK ? <img src={logoDark} alt="" height="70" /> : <img src={logoLight} alt="" height="70" />}
                    </span>
                </div>
                <div className="d-flex flex-column p-4 align-items-center">
                    <motion.div
                        initial={{
                            width: 0,
                        }}
                        animate={{
                            width: "100%",
                            transition: {
                                width: {
                                    duration: 0.15,
                                    delay: 0.1,
                                },
                            },
                        }}
                        exit={{
                            width: 0,
                            transition: {
                                width: {
                                    duration: 0.4,
                                },
                            },
                        }}


                        className="search bg-info bg-opacity-10 rounded-4 ps-4 pe-4 mb-4">
                        <span className="search-icon">
                            <i className="bx bx-search font-size-20" />
                        </span>
                        <textarea
                            className="form-control w-100 "
                            rows={1}
                            value={searchValue}
                            onChange={e => setSearchValue(e.target.value)}
                            onKeyDown={onKeyPress}
                            placeholder={account?.firstName + ", " + t("que recherchez vous?")}
                        />
                        <button disabled={isEmpty(searchValue)} className="send btn btn-danger float-end mb-1" onClick={onSubmit}>
                            <i className="mdi mdi-arrow-right" />
                        </button>
                    </motion.div>
                    <div className="d-flex flex-row suggestions gap-2">

                        {map(suggestions, item => {
                            return <Card className=" item  ps-2 pe-1  bg-info bg-opacity-25 rounded-2 " onClick={() => onSuggestionClicked(item.content)}>
                                <CardTitle>

                                    <div className="hstack gap-1">
                                        <i className=" mdi mdi-lightbulb-on text-danger font-size-20" />
                                        <small>{capitalize(item.category)}</small>
                                    </div>

                                </CardTitle>

                                <p>{truncate(item.content, { length: 80 })}</p>
                            </Card>
                        })}

                    </div>
                </div>

            </motion.div>}

        {hasSearched && <div>
            <SearchChat
                search={searchValue}
                currentConversation={currentConversation}
                channelId={channelId}
                assistant={assistant}
            />

        </div>}
    </Card>
}

export default withTranslation()(SearchGPT);