

import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { Card, CardBody } from "reactstrap";
import Dropzone from "react-dropzone";
import { isEmpty, map } from "lodash";
import { getFileIcon, uuidv4 } from "utils";
import { Link } from "react-router-dom";
import OmniApplicationRunner from "./runner"
import { deleteUploadedFile, uploadTaskFiles } from "service/documents";
import { operationCompleted, operationFailed } from "utils/notifications";

const OmniApplication = ({ app, t, onClose }) => {

    const [attachments, setAttachments] = useState([]);
    const [isRunning, setIsRunning] = useState(false);
    const [context, setContext] = useState(null);
    const [canRun, setCanRun] = useState(false);

    function handleAcceptedFiles(files) {
        const target_dir = `workspace/applications/files/${app.name}`

        uploadTaskFiles(files, target_dir).then(uploadedFiles => {
            setAttachments([...attachments, ...uploadedFiles.map(f => ({
                ...f,
                id: uuidv4(),
                locaton: f.location,
            }))]);

        })
    }

    const onDeleteFile = (file) => {
        deleteUploadedFile(file.location).then(() => {
            setAttachments(attachments.filter(f => f.id !== file.id))
        })
    }

    const onSubmit = (e) => {
        const { key } = e;

        if (key === "Enter" && !e.shiftKey && canRun) {
            setIsRunning(true);
        }
    }

    useEffect(() => {
        setCanRun(!isEmpty(context) || attachments?.length > 0);
    }, [context, attachments])

    return <div className="omni-app  pb-4" >
        <div className="d-flex justify-content-between bg-danger bg-opacity- text-white">
            <div className="p-2">
                <div className="hstack gap-2">
                    {app.image && <img src={app.image} width={60} height={60} />}
                    <div className={!app.image ? "ms-2" : ""}>
                        <span className="font-size-16 fw-bold">{app.name}</span>
                        <p>{app.description}</p>
                    </div>

                </div>

            </div>

            <div className="p-2">
                <button className="btn btn-close text-white" onClick={onClose} />
            </div>
        </div>

        {!isRunning && <div className="d-flex flex-column text-center justify-content-center align-items-center h-100">

            <div className=" context  border-secondary border-opacity-25 mt-2 p-4 border">
                <Dropzone
                    onDrop={acceptedFiles => {
                        handleAcceptedFiles(acceptedFiles)
                    }}
                >
                    {({ getRootProps, getInputProps }) => (
                        <div className="dropzone d-flex flex-column justify-content-center align-items-center h-100">
                            <div
                                className="dz-message needsclick"
                                {...getRootProps()}
                            >
                                <input {...getInputProps()} />
                                <div className="">
                                    <div className="mb-3">
                                        <i className="display-4 text-muted bx bxs-cloud-upload" />
                                    </div>
                                    <h4 className="text-muted">{t("Déposez vos fichiers ici ou cliquez pour importer")}.</h4>
                                </div>

                            </div>
                        </div>
                    )}
                </Dropzone>
            </div>

            <div className="d-flex flex-column w-100 p-2 app-input ">
                <div className="d-flex flex-row flex-wrap mb-2">
                    {map(attachments, file => {

                        return <div className="me-1 border border-secondary border-opacity-10 p-1 rounded-2">
                            <div className="hstack gap-1 ">
                                {getFileIcon(file.name)}
                                <span className="font-size-10">{file.name}</span>
                                <Link onClick={() => onDeleteFile(file)}><i className="mdi mdi-close font-size-12 text-danger" /></Link>
                            </div>
                        </div>
                    })}
                </div>


                <input type="text"
                    className="form-control p-4 rounded-4"
                    value={context}
                    onChange={e => setContext(e.target.value)}
                    onKeyDown={onSubmit}
                    placeholder={t("Ou Saisissez le contexte à fournir cette application ")}
                />

            </div>

        </div>}

        {isRunning && <OmniApplicationRunner
            app={app}
            files={attachments}
            context={context}
        />}

    </div>
}

export default withTranslation()(OmniApplication);
