import React, { Fragment, useEffect, useState } from "react"
import { Card, CardBody } from "reactstrap";
import { isEmpty, map } from "lodash";
import SimpleBar from "simplebar-react";

const importAll = (r) => {
    let images = {};
    r.keys().forEach((item, index) => {
        if (!item.startsWith("./"))
            images[item.replace('./', '')] = r(item);
    });
    return images;
}

const AssistantIdentity = ({ avatar, onAvatarChanged }) => {

    const [avatars, setAvatars] = useState([]);
    const [selectedAvatar, setSelectedAvatars] = useState(null);

    useEffect(() => {
        const others = importAll(require.context('assets/images/avatars/others', false, /\.(png|jpe?g|svg)$/));
        // const males = importAll(require.context('assets/images/avatars/males', false, /\.(png|jpe?g|svg)$/));
        // const females = importAll(require.context('assets/images/avatars/females', false, /\.(png|jpe?g|svg)$/));

        setAvatars({ ...others });
    }, []);

    useEffect(() => {
        if (onAvatarChanged) {
            onAvatarChanged(selectedAvatar);
        }
    }, [selectedAvatar]);

    useEffect(() => {
        if (!isEmpty(avatar))
            setSelectedAvatars(avatar);
    }, [avatar])

    return <Card className="assistant-avatars">
        <CardBody>
            <div className="">
                <SimpleBar className="scroller">
                    <div className="d-flex flex-wrap mb-4">
                        {map(avatars, item => {
                            return <div key={item} className="m-1 avatar p-2" onClick={() => setSelectedAvatars(item)}>
                                {selectedAvatar === item && <div className="float-end">
                                    <i className="mdi mdi-check-circle font-size-20 text-success" />
                                </div>}
                                <img
                                    className="rounded-circle  avatar-xl "
                                    src={item}
                                    alt=""
                                // width={100}
                                // height={150}
                                />

                            </div>
                        })}

                    </div>
                </SimpleBar>

            </div>

        </CardBody>
    </Card>
}

export default AssistantIdentity;