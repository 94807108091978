import { call, put, takeEvery, takeLatest } from "redux-saga/effects"
import { ADD_SUPPORT_PLATFORM, DEL_SUPPORT_PLATFORM, GET_SUPPORT_PLATFORM, GET_SUPPORT_PLATFORM_BOARD, GET_SUPPORT_REQUESTS, UPDATE_SUPPORT_REQUESTS } from "./actionTypes"
import { getSupportRequestsSuccess, getSupportRequestsFailed, getSupportPlatformsSuccess, getSupportPlatformsFailed, updateSupportRequestsSuccess, updateSupportFailed, updateSupportRequestFailed, addSupportPlatformSuccess, addSupportPlatformFailed, delSupportPlatformSuccess, delSupportPlatformFailed, getSupportPlatformBoardSuccess, getSupportPlatformBoardFailed } from "./actions"
import {
  getSupportRequests as onGetSupportRequests,
  getSupportPlatforms as onGetSupportPlatforms,
  addSupportPlatform as onAddSupportPlatform,
  updateSupportRequest as onUpdateSupportPlatform,
  delSupportPlatform as onDeleteSupportPlatform,
  getSupportPlatformBoard as onGetSupportBoard


} from "helpers/backend_helper"

function* loadSupportRequests(action) {
  try {
    const response = yield call(onGetSupportRequests, new URLSearchParams([...Object.entries(action.payload)]).toString())
    yield put(getSupportRequestsSuccess(response))
  } catch (error) {
    yield put(getSupportRequestsFailed(error))
  }
}

function* UpdateSupportRequest(action) {
  try {
    const response = yield call(onUpdateSupportPlatform, action.payload)
    yield put(updateSupportRequestsSuccess(response))
  } catch (error) {
    yield put(updateSupportRequestFailed(error))
  }
}

function* addSupportPlatform(action) {
  try {
    const response = yield call(onAddSupportPlatform, action.payload)
    yield put(addSupportPlatformSuccess(response))
  } catch (error) {
    yield put(addSupportPlatformFailed(error))
  }
}

function* deleteSupportPlatform(action) {
  try {
    const response = yield call(onDeleteSupportPlatform, action.payload)
    yield put(delSupportPlatformSuccess(response))
  } catch (error) {
    yield put(delSupportPlatformFailed(error))
  }
}


function* loadSupportPlatforms(action) {
  try {
    const response = yield call(onGetSupportPlatforms, action.payload)
    yield put(getSupportPlatformsSuccess(response))
  } catch (error) {
    yield put(getSupportPlatformsFailed(error))
  }
}


function* loadSupportPlatformsBoard(action) {
  try {
    const response = yield call(onGetSupportBoard, action.payload)
    yield put(getSupportPlatformBoardSuccess(response))
  } catch (error) {
    yield put(getSupportPlatformBoardFailed(error))
  }
}



function* JaskSupportSaga() {
  yield takeLatest(GET_SUPPORT_REQUESTS, loadSupportRequests)
  yield takeLatest(GET_SUPPORT_PLATFORM, loadSupportPlatforms)
  yield takeLatest(GET_SUPPORT_PLATFORM_BOARD, loadSupportPlatformsBoard)

  yield takeEvery(UPDATE_SUPPORT_REQUESTS, UpdateSupportRequest)
  yield takeEvery(ADD_SUPPORT_PLATFORM, addSupportPlatform)
  yield takeEvery(DEL_SUPPORT_PLATFORM, deleteSupportPlatform)
}

export default JaskSupportSaga;