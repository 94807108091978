import React, { useEffect } from "react";
import {
    Card,
    CardBody,
    Col,
    Container,
    Row,
    Table
} from "reactstrap";
import { withTranslation } from "react-i18next";
import SimpleBar from "simplebar-react"
import { isEmpty, map } from "lodash";
import ReactApexChart from "react-apexcharts";
const humanizeDuration = require("humanize-duration");

const getChartOptions = index => {
    var options = {
        chart: { sparkline: { enabled: !0 } },
        dataLabels: { enabled: !1 },
        colors: ["#556ee6"],
        plotOptions: {
            radialBar: {
                hollow: { margin: 0, size: "60%" },
                track: { margin: 0 },
                dataLabels: { show: !1 },
            },
        },
    };
    switch (index) {
        case 1:
            options["colors"][0] = "#556ee6";
            break;
        case 2:
            options["colors"][0] = "#34c38f";
            break;
        case 3:
            options["colors"][0] = "#f46a6a";
            break;
        default:
            break;
    }

    return options;
};
const options = {
    plotOptions: {
        radialBar: {
            startAngle: -135,
            endAngle: 135,
            dataLabels: {
                name: {
                    fontSize: "13px",
                    color: void 0,
                    offsetY: 60,
                },
                value: {
                    offsetY: 22,
                    fontSize: "13px",
                    color: void 0,
                    formatter: function (e) {
                        return e + "%";
                    },
                },
            },
        },
    },
    // colors: apexRadialChartColors,
    fill: {
        type: "gradient",
        gradient: {
            shade: "dark",
            shadeIntensity: 0.15,
            inverseColors: !1,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 2, 4, 10],
        },
    },
    stroke: {
        dashArray: 4,
    },
    labels: [""],
};

const AssistantUsageCard = ({ data, assistants }) => {

    const assistant = assistants.find(a => a.id === data.id);
    // const options = getChartOptions(1);

    const usage = (data.usagePercentage * 100).toFixed(2);
    const posted = (data.postedUsagePercentage * 100).toFixed(2);

    return <tr>
        <td style={{ width: "150px" }}>
            <div className="d-flex flex-column  rounded justify-content-center align-items-center  border-opacity-25 border-secondary p-1">
                <img className=" bg-info bg-opacity-25 rounded-circle avatar-xs text-center" src={assistant?.logoUrl} />
                <span className="font-size-10">{assistant?.name}</span>
            </div>
        </td>


        <td>
            {/* <p className="text-muted mb-1"> Demandes reçues</p> */}
            <ReactApexChart
                options={options}
                series={[usage]}
                type="radialBar"
                height={120}
                width={120}
                className="apex-charts"
            />

        </td>
        <td>
            <ReactApexChart
                options={options}
                series={[posted]}
                type="radialBar"
                height={120}
                width={120}
                className="apex-charts"
            />
            {/* <p className="text-muted mb-1"> Réponses Postées</p> */}
            {/* <h6 className="mb-0  text-center">{} %</h6> */}
        </td>
        <td>
            {/* <p className="text-muted mb-1"> Réponses Postées</p> */}
            <h6 className="mb-0  text-center text-wrap">{!data.resolutionDurationAvg ? "-" : humanizeDuration(data.resolutionDurationAvg * 1000)}</h6>
        </td>
    </tr>
}

const TopSupportAssistants = ({ board, assistants, t }) => {

    return (
        <Card className="board-card-mini border border-secondary border-opacity-25">
            <CardBody className="p-0">
                <div className="d-flex justify-content-between bg- bg-opacity-25 border-bottom border-secondary border-opacity-25">
                    <div className="pb-1 ps-4 pt-2">
                        <span className="card-title mb"><i className="bx bx-bot font-size-18 pt-1 me-1" />{t("Top Assistants Support")}</span>
                        <p className="text-muted font-size-12">{t("Répartition des sollicitations par assistant support")}</p>
                    </div>

                </div>

                <SimpleBar className="scroller">

                    <div className="table-responsive ">
                        <Table className="table align-middle mb-0">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th className="text-center">
                                        <span className="text-muted "> <i className="bx bx-support " /> Reçues</span>
                                    </th>
                                    <th className="text-center">
                                        <span className="text-muted "> <i className="bx bx-support " /> Postées</span>
                                    </th>
                                    <th className="text-center">
                                        <span className="text-muted "> <i className="bx bx-support " />Temps Moyen</span>
                                    </th>

                                </tr>
                            </thead>
                            <tbody>
                                {map(board?.assistants || [], item => {
                                    return <AssistantUsageCard data={item} assistants={assistants} />
                                })}
                            </tbody>
                        </Table>
                    </div>

                </SimpleBar>
            </CardBody>

        </Card>

    );
};


export default withTranslation()(TopSupportAssistants);
