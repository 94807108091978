import React, { useEffect, useState } from "react";
import logoJask from "../../../../../assets/images/logos/jask-8.png";
import { Card, CardBody, CardTitle } from "reactstrap";
import { isEmpty } from "lodash";

const LandingDocumentGenerator = ({ onStartGeneration }) => {

    const [theme, setTheme] = useState();
    const [canSubmit, setCanSubmit] = useState();
    const onSubmit = () => {
        onStartGeneration({
            theme: theme
        });
    }

    useEffect(() => {
        setCanSubmit(!isEmpty(theme) && theme.length > 3);
    }, [theme])

    return <div className="">
        <Card className=" workspace-document-landing me-2">
            <CardBody><div className="landing-chat" >
                <div className=" ">
                    <div className="d-flex justify-content-center flex-column align-items-center mb-4 ">

                        <span className="logo-lg ">
                            <img src={logoJask} alt="" height="70" />
                        </span>
                        <h5 className="text-muted mt-2">Sur quoi souhaitiez-vous écrire ?</h5>
                    </div>
                    <div className="d-flex flex-wrap justify-content-center mt-4 ">
                        <input
                            type="text"
                            className="form-control"
                            rows={1}
                            value={theme}
                            onChange={e => setTheme(e.target.value)}
                        />
                    </div>
                    <div className="mb-4 mt-4">
                        <div className="d-flex flex-column align-items-center justify-content-center">
                            <div className="d-flex flex-row mt-2">
                                <div className="p-2">
                                    <button className="btn btn-success font-size-14 " disabled={!canSubmit} onClick={onSubmit} >
                                        Démarrer
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            </CardBody>
        </Card>
    </div>
}

export default LandingDocumentGenerator;