import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
    Table,
    DropdownMenu,
    DropdownToggle,
    UncontrolledDropdown,
    DropdownItem,
    Card,
    CardBody,
} from "reactstrap"

const ResourceListPlaceHolders = () => {


    return (
        <React.Fragment>

            <div className="table-responsive">
                <Table className="table align-middle table-nowrap table-hover mb-0">
                    <thead>
                        <tr>
                            <th scope="col">Nom de la ressource	</th>
                            <th scope="col" >Source</th>

                        </tr>
                    </thead>
                    <tbody>
                        <tr className="placeholder-glow">
                            <td><span className="placeholder col-7"></span></td>
                            <td><span className="placeholder col-5"></span></td>

                        </tr>

                        <tr className="placeholder-glow">
                            <td><span className="placeholder col-7"></span></td>
                            <td><span className="placeholder col-5"></span></td>

                        </tr>


                        <tr className="placeholder-glow">
                            <td><span className="placeholder col-7"></span></td>
                            <td><span className="placeholder col-5"></span></td>

                        </tr>


                        <tr className="placeholder-glow">
                            <td><span className="placeholder col-7"></span></td>
                            <td><span className="placeholder col-5"></span></td>

                        </tr>


                        <tr className="placeholder-glow">
                            <td><span className="placeholder col-7"></span></td>
                            <td><span className="placeholder col-5"></span></td>

                        </tr>

                        <tr className="placeholder-glow">
                            <td><span className="placeholder col-7"></span></td>
                            <td><span className="placeholder col-5"></span></td>

                        </tr>

                    </tbody>
                </Table>
            </div>

        </React.Fragment>
    )
}

export default ResourceListPlaceHolders;
