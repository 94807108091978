import React from 'react';
import { Badge, Spinner } from 'reactstrap';
import { Link } from 'react-router-dom';
import { getInitiales, getItemType, getProviderInfo, getProviderLogo } from 'utils';
import { RotatingLines } from 'react-loader-spinner';



const DatasourceName = (cell) => {
    return cell.value ? cell.value : "";
};

const FullName = (cell) => {

    return <div className='hstack'>
        <div className="avatar-xs me-3">
            <span className="avatar-title rounded-circle bg-info bg-soft text-info font-size-18">
                {getInitiales(cell.value || "")}
            </span>
        </div>
        <span>{cell.value}</span>

    </div>
};

const DatasourceDesc = (cell) => {
    return cell.value ? cell.value.substring(0, 80) + " ..." : "";
};

const DatasourceLocation = (cell) => {
    return <div className='hstack gap-2'>
        {getProviderInfo(cell.value)}
    </div>
};

const SelectDatasourceAction = (cell) => {
    return <div className="form-check font-size-16">
        <input className="form-check-input" type="checkbox" id={"datasource-" + cell.value} />
        <label className="form-check-label" htmlFor="upcomingtaskCheck01"></label>
    </div>
}



const Type = (cell) => {
    switch (cell.value) {
        case "Full Time":
            return <Badge className="badge-soft-success">Full Time</Badge>
        case "Part Time":
            return <Badge className="badge-soft-danger">Part Time</Badge>
        case "Freelance":
            return <Badge className="badge-soft-info">Freelance</Badge>
        case "Internship":
            return <Badge className="badge-soft-warning">Internship</Badge>
        default:
            return <Badge className="badge-soft-success">Online</Badge>


    };
};

const Scope = (cell) => {


    switch (cell.value) {

        case "private":
            return <Badge className="badge-soft-info">Personal</Badge>
        default:
            return <Badge className="badge-soft-info">Organization</Badge>
    }
};

const Status = (cell) => {
    const source = cell.row.original;
    const status = source?.lastIndexingRun?.status || cell.value;

    switch (status) {
        case "INDEXING":
        case "running":
            const totalCompleted = source.totalItemsFailed + source.totalItemsSuccess;
            return <div className='hstack gap-1'>
                <Spinner size={"sm"} />
                <Badge color='info' >En cours... ({source?.lastIndexingRun?.completed}/{source?.lastIndexingRun.totalItems})</Badge>
            </div>
        case "NOT_READY":
            return <Badge color='info'>En préparation</Badge>
        case "INDEXING_ERROR":
            return <Badge color='danger'>Erreur</Badge>
        case "INDEXED":
            return <Badge color='success'>Pret</Badge>

        default:
            return <Badge className="badge-soft-info" >N/A</Badge>
    }
};


export { DatasourceName, DatasourceDesc, Type, Scope, DatasourceLocation, Status, SelectDatasourceAction, FullName };