import { GET_TODO_WORK, GET_TODO_WORK_SUCCESS, GET_TODO_WORK_FAIL, ADD_TODO_WORK, ADD_TODO_WORK_SUCCESS, ADD_TODO_WORK_FAIL, DEL_TODO_WORK, DEL_TODO_WORK_SUCCESS, DEL_TODO_WORK_FAIL, ADD_TODO_WORK_START, SUBMIT_TODO_WORK, SUBMIT_TODO_WORK_SUCCESS, SUBMIT_TODO_WORK_FAIL, ON_TODO_WORK_UPDATED, ADD_TODO_DRAFT, ADD_PROFILE_TODO_SETTINGS_CATEGORY, ADD_TODO_CATEGORY_SUCCESS, ADD_TODO_CATEGORY_FAILED, GET_WORKSPACE_PREFERENCES, GET_WORKSPACE_PREFERENCES_SUCCESS, GET_WORKSPACE_PREFERENCES_FAILED, ON_TODO_WORK_UPDATED_SUCCESS, ON_TODO_WORK_UPDATED_FAILED, DEL_TODO_CATEGORY, DEL_TODO_CATEGORY_SUCCESS, DEL_TODO_CATEGORY_FAILED } from "./actionTypes"

export const getTodoWorks = () => ({
  type: GET_TODO_WORK,
})

export const getTodoWorksSuccess = data => ({
  type: GET_TODO_WORK_SUCCESS,
  payload: data,
})

export const getTodoWorksFail = error => ({
  type: GET_TODO_WORK_FAIL,
  payload: error,
})


export const addTodoWorks = (data) => ({
  type: ADD_TODO_WORK,
  payload: data
})

export const addTodoWorkStart = () => ({
  type: ADD_TODO_WORK_START
})

export const addTodoWorksSuccess = data => ({
  type: ADD_TODO_WORK_SUCCESS,
  payload: data,
})

export const addTodoWorksFail = error => ({
  type: ADD_TODO_WORK_FAIL,
  payload: error,
})

export const updateTodoWorks = (data) => ({
  type: ON_TODO_WORK_UPDATED,
  payload: data
})

export const updateTodoWorksSucess = (data) => ({
  type: ON_TODO_WORK_UPDATED_SUCCESS,
  payload: data
})

export const updateTodoWorkFailed = (data) => ({
  type: ON_TODO_WORK_UPDATED_FAILED,
  payload: data
})



export const deleteTodoWorks = (data) => ({
  type: DEL_TODO_WORK,
  payload: data,

})

export const deleteTodoWorksSuccess = data => ({
  type: DEL_TODO_WORK_SUCCESS,
  payload: data,
})

export const deteleTodoWorksFail = error => ({
  type: DEL_TODO_WORK_FAIL,
  payload: error,
})


export const submitTodoWork = data => ({
  type: SUBMIT_TODO_WORK,
  payload: data,
})

export const submitTodoWorkSuccess = ticket => ({
  type: SUBMIT_TODO_WORK_SUCCESS,
  payload: ticket,
})

export const submitTodoWorkFail = error => ({
  type: SUBMIT_TODO_WORK_FAIL,
  payload: error,
})

export const addTodoDraft = payload => ({
  type: ADD_TODO_DRAFT,
  payload: payload,
})

export const addProfileTodoCategory = payload => {
  return {
    type: ADD_PROFILE_TODO_SETTINGS_CATEGORY,
    payload: payload
  }
}

export const addTodoCatgorySuccess = payload => {
  return {
    type: ADD_TODO_CATEGORY_SUCCESS,
    payload: payload
  }
}
export const addTodoCategoryFailed = payload => {
  return {
    type: ADD_TODO_CATEGORY_FAILED,
    payload: payload
  }
}


export const delProfileTodoCategory = payload => {
  return {
    type: DEL_TODO_CATEGORY,
    payload: payload
  }
}
export const delProfileTodoCategorySuccess = payload => {
  return {
    type: DEL_TODO_CATEGORY_SUCCESS,
    payload: payload
  }
}

export const delProfileTodoCategoryFailed = payload => {
  return {
    type: DEL_TODO_CATEGORY_FAILED,
    payload: payload
  }
}



export const getWorkspaceSettings = () => {
  return {
    type: GET_WORKSPACE_PREFERENCES,
  }
}

export const getWorkspaceSettingsSuccess = payload => {
  return {
    type: GET_WORKSPACE_PREFERENCES_SUCCESS,
    payload: payload
  }
}

export const getWorkspaceSettingsFailed = payload => {
  return {
    type: GET_WORKSPACE_PREFERENCES_FAILED,
    payload: payload
  }
}



