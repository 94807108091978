import { post, post_form, del, get, getBlob, axiosApi } from "../helpers/api_helper";
import { BULK_DELETE, CREATE_FILES, DOWNLOAD_FILE, READ_TABULAR, RUN_TASK_OVER_TABLE, SAVE_TABULAR, UPLOAD_FILES, UPLOAD_TASK_FILES } from "../helpers/url_helper";
import { API_URL } from "helpers/api_helper";
import { fetchEventSource } from "@microsoft/fetch-event-source";
import { isEmpty, omitBy } from "lodash";

export async function uploadFile(file, directory) {
    const form = new FormData();
    form.append("files", file);
    form.append("directory", directory)
    return post_form(UPLOAD_FILES, form)
}

export async function downloadFile(location, owner, providerId, providerType) {
    const params = {
        location: location,
        owner: owner,
        providerId: providerId,
        providerType,
    }

    const query = new URLSearchParams(omitBy(params, isEmpty)).toString();
    console.log(query)
    return getBlob(`${API_URL}/documents/download?${query}`).then(blob => {
        return URL.createObjectURL(blob.data)
    })
}

export async function uploadTaskFiles(files, directory, config) {
    const form = new FormData();
    form.append("directory", directory)

    files.forEach(f => {
        form.append("files", f);
    })

    return post_form(UPLOAD_TASK_FILES, form, config)
}


export async function deleteUploadedFile(location) {
    del(UPLOAD_FILES + "?location=" + location).catch(error => {

    })
}

export async function bulkDeleteFiles(locations) {
    return post(BULK_DELETE, locations)
}

export function createFilesFromText(directory, files) {

    return post(CREATE_FILES, {
        directory: directory,
        files: files
    })

}

export const generateContentStructure = async (task, eventConsumer, onStructureReceived, onStreamStart, onStreamEnd, accessToken) => {
    console.log("sending request")

    const ctrl = new AbortController();
    let stream = "";

    await fetchEventSource(`${API_URL}/workspace/documents/generate/sse`,
        {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`,
            },
            body: JSON.stringify(task),
            cache: "no-cache",
            signal: ctrl.signal,
            onopen(res) {
                if (res.ok && res.status === 200) {

                    if (onStreamStart) {
                        onStreamStart();
                    }
                    console.log("Connection made ", res);
                } else if (
                    res.status >= 400 &&
                    res.status < 500 &&
                    res.status !== 429
                ) {
                    console.log("Client side error ", res);
                }
            },
            onmessage(event) {
                const parsedEvent = JSON.parse(event.data);
                switch (parsedEvent.type) {
                    case "summary":
                        eventConsumer(parsedEvent.value)
                        stream += parsedEvent.value;
                        break;
                    case "structure":
                        onStructureReceived(parsedEvent.value)
                        break;
                    case "end":
                        if (onStreamEnd) {
                            onStreamEnd(stream);
                        }
                        break;
                }
            },
            onclose() {
                console.log("Connection closed by the server");
            },
            onerror(err) {
                console.log("There was an error from server:", err);
            },
        });

}

export const generateContent = async (task, eventConsumer, onStreamStart, onStreamEnd, accessToken) => {
    console.log("sending request")

    const ctrl = new AbortController();
    let stream = "";

    await fetchEventSource(`${API_URL}/workspace/documents/generate/sse`,
        {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`,
            },
            body: JSON.stringify(task),
            cache: "no-cache",
            signal: ctrl.signal,
            onopen(res) {
                if (res.ok && res.status === 200) {

                    if (onStreamStart) {
                        onStreamStart();
                    }
                    console.log("Connection made ", res);
                } else if (
                    res.status >= 400 &&
                    res.status < 500 &&
                    res.status !== 429
                ) {
                    console.log("Client side error ", res);
                }
            },
            onmessage(event) {

                try {
                    const parsedEvent = JSON.parse(event.data);
                    eventConsumer(parsedEvent);
                } catch (error) {
                    console.log("Error , raw event : ", event)
                }


            },
            onclose() {
                console.log("Connection closed by the server");
            },
            onerror(err) {
                console.log("There was an error from server:", err);
            },
        });

}


export const generateContentFromTemplate = async (task, eventConsumer, onStreamStart, onStreamEnd, accessToken) => {
    const ctrl = new AbortController();
    let stream = "";

    await fetchEventSource(`${API_URL}/workspace/templates/generate/document`,
        {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`,
            },
            body: JSON.stringify(task),
            cache: "no-cache",
            signal: ctrl.signal,
            onopen(res) {
                if (res.ok && res.status === 200) {

                    if (onStreamStart) {
                        onStreamStart();
                    }
                    console.log("Connection made ", res);
                } else if (
                    res.status >= 400 &&
                    res.status < 500 &&
                    res.status !== 429
                ) {
                    console.log("Client side error ", res);
                }
            },
            onmessage(event) {

                try {
                    const parsedEvent = JSON.parse(event.data);
                    eventConsumer(parsedEvent);
                } catch (error) {
                    console.log("Error , raw event : ", event)
                }


            },
            onclose() {
                console.log("Connection closed by the server");
                if (onStreamEnd())
                    onStreamEnd();
            },
            onerror(err) {
                console.log("There was an error from server:", err);
                if (onStreamEnd())
                    onStreamEnd();
                throw err
            },
        });

}


export const downloadGeneratedFile = (payload, onLinkAvailable, onFileError) => {

    post(`${API_URL}/workspace/templates/generate/document/download`, payload).then(response => {
        getBlob(`${API_URL}/documents/download?location=${response.location}&filename=${response.filename}`).then(blob => onLinkAvailable(URL.createObjectURL(blob.data), response.filename))
    }).catch(onFileError);
}


export const loadTabularFile = async (location) => {
    return get(`${READ_TABULAR}?location=${location}`);
}

export const saveTabularFile = async (payload) => {
    return post(`${SAVE_TABULAR}`, payload);
}


export const runTaskOverTable = async (task, onStart, onStream, onClose, accessToken, cancellation) => {

    await fetchEventSource(`${API_URL}${RUN_TASK_OVER_TABLE}`, {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(task),
        cache: "no-cache",
        signal: cancellation,
        onopen(res) {
            if (res.ok && res.status === 200) {
                onStart();
                console.log("Connection made ", res);
            } else if (
                res.status >= 400 &&
                res.status < 500 &&
                res.status !== 429
            ) {
                onClose();
            }
        },
        onmessage(event) {

            try {
                const parsedEvent = JSON.parse(event.data);
                onStream(parsedEvent);
            } catch (error) {
                console.log("Error , raw event : ", event)
            }


        },
        onclose() {
            console.log("Connection closed by the server");
            onClose();
        },
        onerror(err) {
            console.log("There was an error from server:", err);
            onClose();
            throw err;
        },
    });
}