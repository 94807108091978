import { SUBMIT_GENERATION_TASK, SUBMIT_GENERATION_TASK_SUCCES as SUBMIT_GENERATION_TASK_SUCCESS, SUBMIT_GENERATION_TASK_FAILED, PROCESS_DOC_TEMPLATE_SUCCESS, PROCESS_DOC_TEMPLATE, PROCESS_DOC_TEMPLATE_FAILED, ADD_TEMPLATE_SUCCESS, ADD_TEMPLATE, ADD_TEMPLATE_FAILED, GET_TEMPLATE_SUCCESS, GET_TEMPLATE, GET_TEMPLATE_FAILED, DOWNLOAD_GENERATED_TEMPLATE, DOWNLOAD_GENERATED_TEMPLATE_SUCCES, DOWNLOAD_GENERATED_TEMPLATE_FAILED, DEL_TEMPLATE_SUCCESS, DEL_TEMPLATE_FAILED, GET_RECENT_FILES, GET_RECENT_FILES_SUCCESS, GET_RECENT_FILES_FAILED } from "./actionTypes"

const INIT_STATE = {
  tasks: {},
  error: {},
  isGenerating: false,
  isExporting: false,
  isProcessingTemplate: false,
  isLoading: false,
  processedTemplates: [],
  templates: [],
  recentFiles: []
}

const documents = (state = INIT_STATE, action) => {
  switch (action.type) {


    case DOWNLOAD_GENERATED_TEMPLATE:
    case SUBMIT_GENERATION_TASK:

      const alltasks = { ...state.tasks }
      alltasks[action.payload.id] = {
        ...action.payload,
        status: 'pending'
      }

      return {
        ...state,
        isGenerating: action.type === SUBMIT_GENERATION_TASK,
        isExporting: action.type === DOWNLOAD_GENERATED_TEMPLATE,
        tasks: { ...alltasks }
      }

    case DOWNLOAD_GENERATED_TEMPLATE_SUCCES:
    case SUBMIT_GENERATION_TASK_SUCCESS:
      return {
        ...state,
        isGenerating: action.type === SUBMIT_GENERATION_TASK_SUCCESS ? false : state.isGenerating,
        isExporting: action.type === DOWNLOAD_GENERATED_TEMPLATE_SUCCES ? false : state.isExporting,
        tasks: {
          ...state.tasks,
          [action.payload.id]: { ...action.payload, status: 'success' }

        }

      }

    case DOWNLOAD_GENERATED_TEMPLATE_FAILED:
    case SUBMIT_GENERATION_TASK_FAILED:
      return {
        ...state,
        isGenerating: false,
        error: action.payload,
        tasks: {
          ...state.tasks,
          [action.payload.id]: { ...action.payload, status: 'error' }

        }
      }

    case PROCESS_DOC_TEMPLATE:
      return {
        ...state,
        isProcessingTemplate: true
      }

    case PROCESS_DOC_TEMPLATE_SUCCESS:
      return {
        ...state,
        processedTemplates: action.payload,
        isProcessingTemplate: false
      }

    case PROCESS_DOC_TEMPLATE_FAILED:
      return {
        ...state,
        error: action.payload,
        isProcessingTemplate: false
      }



    case ADD_TEMPLATE:
      return {
        ...state,
        isLoading: true
      }

    case ADD_TEMPLATE_SUCCESS:

      return {
        ...state,
        templates: [...state.templates, action.payload],
        isLoading: false

      }

    case ADD_TEMPLATE_FAILED:

      return {
        ...state,
        error: action.payload,
        isLoading: false

      }

    case GET_TEMPLATE:

      return {
        ...state,
        isLoading: true
      }

    case GET_TEMPLATE_SUCCESS:

      return {
        ...state,
        templates: action.payload,
        isLoading: false
      }

    case GET_TEMPLATE_FAILED:

      return {
        ...state,
        error: action.payload,
        isLoading: false
      }

    case DEL_TEMPLATE_SUCCESS:

      return {
        ...state,
        templates: state.templates.filter(t => t.id !== action.payload)
      }

    case DEL_TEMPLATE_FAILED:
      return {
        ...state,
        error: action.payload
      }


    case GET_RECENT_FILES:

      return {
        ...state,
        isLoading: true
      }

    case GET_RECENT_FILES_SUCCESS:

      return {
        ...state,
        isLoading: false,
        recentFiles: action.payload
      }

    case GET_RECENT_FILES_FAILED:

      return {
        ...state,
        isLoading: false,
        error: action.payload
      }

    default:
      return state
  }

}

export default documents;