import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import TableContainer from 'components/Common/TableContainer';

//import components
import DeleteModal from 'components/Common/DeleteModal';

import {
    getGroups,
    getRoles,
    getUsers
} from "store/actions";

import {
    Name, Role, SelectUser, Date, Permissions, RoleName, RoleType, ConsumptionPlan
} from "./columns";

//redux
import { useSelector, useDispatch } from "react-redux";

import {
    Card,
    CardBody
} from "reactstrap";
import AddRoleProfile from "../add";

function RolesListView() {

    const [modal, setModal] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [singlebtn, setSinglebtn] = useState(false)
    const [canDeploy, setCanDeploy] = useState(true)
    const [Datasource, setDatasource] = useState(null);
    const [datasourcesToMonitor, setDatasourcesToMonitor] = useState([]);
    const [showAddProfile, setShowAddProfile] = useState(false);

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { roles, isLoading } = useSelector(state => ({
        roles: state.Users.roles,
        isLoading: state.Users.isLoading,

    }));

    const toggle = () => {
        if (modal) {
            setModal(false);
            setDatasource(null);
        } else {
            setModal(true);
        }
    };

    //delete Datasource
    const [deleteModal, setDeleteModal] = useState(false);

    const onClickDelete = (Datasource) => {
        setDatasource(Datasource);
        setDeleteModal(true);
    };

    const handleDeleteUser = () => {

    };

    const handleAddProfile = () => {
        setShowAddProfile(!showAddProfile)
    };

    const columns = useMemo(
        () => [
            {
                Header: '',
                accessor: 'id',
                disableFilters: true,
                Cell: (cellProps) => {
                    return <SelectUser {...cellProps} />;
                }
            },
            {
                Header: 'Nom du rôle',
                accessor: 'name',
                disableFilters: true,
                Cell: (cellProps) => {
                    return <RoleName {...cellProps} />;
                }
            },
            {
                Header: 'Description',
                accessor: 'description',
                disableFilters: true,
                Cell: (cellProps) => {
                    return <Name {...cellProps} />;
                }
            },
            {
                Header: 'Plan de consommation',
                accessor: 'consumptionPlan',
                disableFilters: true,
                Cell: (cellProps) => {
                    return <ConsumptionPlan {...cellProps} />;
                }
            },
            {
                Header: 'Type de rôle',
                accessor: 'builtin',
                disableFilters: true,
                Cell: (cellProps) => {
                    return <RoleType {...cellProps} />;
                }
            },
            {
                Header: 'Permissions',
                accessor: 'members',
                disableFilters: true,
                Cell: (cellProps) => {
                    return <Permissions {...cellProps} />;
                }
            }
        ],
        []
    );

    const onRefresh = () => {
        dispatch(getRoles())
    }

    useEffect(() => {
        onRefresh();
    }, [dispatch]);


    return (
        <React.Fragment>
            <DeleteModal
                show={deleteModal}
                onDeleteClick={handleDeleteUser}
                onCloseClick={() => setDeleteModal(false)}
            />
            <Card className="datasources-panel">
                {!showAddProfile && <CardBody>
                    <TableContainer
                        columns={columns}
                        data={roles || []}
                        isGlobalFilter={true}
                        isAddOptions={false}
                        handleAddClicks={handleAddProfile}
                        addButtonText="Nouveau"
                        isDatasourceListGlobalFilter={false}
                        isGenericAdd={true}
                        customPageSize={10}
                        onRefresh={onRefresh}
                    />
                </CardBody>}
                {showAddProfile && <CardBody><AddRoleProfile onClose={() => setShowAddProfile(!showAddProfile)} /></CardBody>}
            </Card>
        </React.Fragment>
    );
}


export default RolesListView;