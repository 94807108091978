import {
  GET_USERS_SUCCESS,
  GET_USERS_FAIL,
  ADD_USER_SUCCESS,
  ADD_USER_FAIL,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAIL,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAIL,
  GET_USER_PROFILE_SUCCESS,
  GET_USER_PROFILE_FAIL,
  ADD_NEW_USER,
  GET_USERS,
  ADD_GROUP,
  GET_GROUPS,
  GET_GROUPS_SUCCESS,
  GET_GROUPS_FAILED,
  ADD_GROUP_SUCCESS,
  ADD_GROUP_FAILED,
  ADD_ROLE,
  GET_ROLES,
  GET_ROLES_SUCCESS,
  GET_ROLES_FAILED,
  ADD_ROLE_SUCCESS,
  ADD_ROLE_FAILED,
  DELETE_ROLE_SUCCESS,
  DELETE_GROUP_SUCCESS,
} from "./actionTypes"

const INIT_STATE = {
  isAdding: false,
  isLoading: false,
  users: [],
  groups: [],
  roles: [],
  userProfile: {},
  error: {},
}

const users = (state = INIT_STATE, action) => {
  switch (action.type) {

    case ADD_ROLE:
    case ADD_GROUP:
    case ADD_NEW_USER:
      return {
        ...state,
        isAdding: true,
        error: null
      }

    case GET_ROLES:
    case GET_GROUPS:
    case GET_USERS:
      return {
        ...state,
        isLoading: true
      }
    case GET_USERS_SUCCESS:
      return {
        ...state,
        users: action.payload,
        isLoading: false
      }

    case GET_GROUPS_SUCCESS:
      return {
        ...state,
        groups: action.payload,
        isLoading: false
      }


    case GET_ROLES_SUCCESS:
      return {
        ...state,
        roles: action.payload,
        isLoading: false
      }

    case GET_ROLES_FAILED:
    case GET_GROUPS_FAILED:
    case GET_USERS_FAIL:
      return {
        ...state,
        error: action.payload,
        isLoading: false
      }

    case ADD_USER_SUCCESS:

      return {
        ...state,
        isAdding: false,
        users: [...state.users, action.payload],
        error: null
      }

    case ADD_GROUP_SUCCESS:

      return {
        ...state,
        isAdding: false,
        groups: [...state.groups, action.payload],
        error: null
      }

    case ADD_ROLE_SUCCESS:

      return {
        ...state,
        isAdding: false,
        roles: [...state.groups, action.payload],
        error: null
      }

    case DELETE_ROLE_SUCCESS:
      return {
        ...state,
        roles: state.roles.filter(r => r.id !== action.payload)
      }

    case DELETE_GROUP_SUCCESS:
      return {
        ...state,
        groups: state.groups.filter(r => r.id !== action.payload)
      }

    case ADD_ROLE_FAILED:
    case ADD_GROUP_FAILED:
    case ADD_USER_FAIL:
    case DELETE_USER_FAIL:
      return {
        ...state,
        isAdding: false,
        error: action.payload,
      }

    case GET_USER_PROFILE_SUCCESS:
      return {
        ...state,
        userProfile: action.payload,
      }

    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        users: state.users.map(user =>
          user.id.toString() === action.payload.id.toString()
            ? { user, ...action.payload }
            : user
        ),
      }

    case UPDATE_USER_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_USER_SUCCESS:
      return {
        ...state,
        users: state.users.filter(
          user => user.id.toString() !== action.payload.toString()
        ),
      }

    case DELETE_USER_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_USER_PROFILE_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    default:
      return state
  }
}

export default users
