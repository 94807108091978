import React, { useEffect, useState } from "react";
import { RotatingLines } from 'react-loader-spinner'
import toastr from "toastr";
import * as url from "helpers/url_helper";
import { post_form } from "helpers/api_helper";
import Select from "react-select";

import {
    useNavigate,
} from 'react-router-dom';

import {
    Card,
    CardBody,
    Col,
    Container,
    Form,
    Input,
    Label,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane,
    CardTitle,
    Table, Badge
} from "reactstrap"

import {
    addNewDatasourceList as onAddNewDatasourceList,
    getDatasourceDomainList as onGetDatasourceDomainsList,
    getUsers,
    getRoles,
} from "store/actions";

import { useSelector, useDispatch } from "react-redux";
import classnames from "classnames"
import { Link } from "react-router-dom"
import Connectivity from "../Connectivity"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import { isEmpty, map } from "lodash";
import { operationCompleted } from "utils/notifications";
import { DatasourceProviders } from "constants/general";
import DatasourceCustomSelect from "components/Common/DatasourceProvidersSelect";

//Icons
import AzureIcon from "assets/images/providers/azure.svg"
import SalesforceIcon from "assets/images/providers/salesforce.png"
import SharePointIcon from "assets/images/providers/sharepoint.png"
import DirectoryIcon from "assets/images/providers/directory.png"
import WebIcon from "assets/images/providers/web.png"
import BingIcon from "assets/images/providers/bing.png"
import GoogleSearchIcon from "assets/images/providers/googleSearch.png"
import JiraIcon from "assets/images/providers/Jira.png"

const datasourceProviders = [
    {
        value: DatasourceProviders.LOCAL,
        label: "Local",
        description: "Importer des fichiers depuis votre poste ",
        icon: DirectoryIcon
    },
    {
        value: DatasourceProviders.AZURE,
        label: "Microsoft Azure",
        icon: AzureIcon,
        description: "Connectez-vous aux ressources Azure Cloud "
    },
    {
        value: DatasourceProviders.GOOGLE_SEARCH,
        label: "Google Search",
        icon: GoogleSearchIcon,
        description: "Connectez-vous à internet grâce à Google Search "

    },
    {
        value: DatasourceProviders.BING_SEARCH,
        label: "Bing Search",
        icon: BingIcon,
        description: "Connectez-vous à internet grâce à Bing "

    },
    {
        value: DatasourceProviders.SALESFORCE,
        label: "Salesforce",
        icon: SalesforceIcon,
        description: "Connectez-vous à votre CRM et consulter vos opportunités "

    },
    {
        value: DatasourceProviders.SHAREPOINT,
        label: "Sharepoint Online",
        icon: SharePointIcon,
        description: "Connectez-vous à votre Sharepoint "
    },
    {
        value: DatasourceProviders.JIRA,
        label: "JIRA",
        description: "Gérer les tickets et les équipes supports",
        icon: JiraIcon
    },
    {
        value: DatasourceProviders.WEB,
        label: "Web Urls",
        description: "Integrer du contenu depuis des sites webs  ",
        icon: WebIcon
    }
]

const FormWizard = () => {

    //meta title
    document.title = "Jask | New Datasource";
    const navigate = useNavigate();
    const [activeTab, setactiveTab] = useState(1)
    const [passedSteps, setPassedSteps] = useState([1])
    const [appName, setAppName] = useState(null)
    const [provider, setProvider] = useState()
    const [scope, setScope] = useState("private")
    const [description, setDescription] = useState(null)
    const [connection, setConnection] = useState({})
    const [indexName, setIndexName] = useState("my-index")
    const [useOcr, setUseOcr] = useState("false")
    const [indexMethod, setIndexMethod] = useState("default")
    const [selectedOcr, setSelectedOcr] = useState("default")
    const [indexingFrequency, setIndexingFrequency] = useState("default")
    const [metadataBuilder, setMetadataBuilder] = useState("default");
    const [isLoading, setIsLoading] = useState(false);
    const [canSubmit, setCanSubmit] = useState(false);
    const [selectedMembers, setSelectedMembers] = useState([]);
    const [members, setMembers] = useState([]);
    const [showMembersSelection, setShowMemberSelection] = useState(false);

    const handleProvider = (value) => {
        setProvider(value);
        setConnection({})
    }

    const dispatch = useDispatch();
    const { datasources, users, groups } = useSelector(state => ({
        datasources: state.Datasources.datasources,
        users: state.Users.users,
        groups: state.Users.groups,
    }));

    useEffect(() => {
        setCanSubmit(!isEmpty(appName) && !isEmpty(scope) && connection.isValid
        );
    }, [appName, connection]);


    useEffect(() => {

        if (isLoading) {
            navigate('/admin/datasources');
            operationCompleted("Indexation en cours...");
        }

    }, [datasources]);


    const handleAddDatasource = () => {

        if (activeTab !== 4) {
            toggleTab(4);
            return;
        }

        setIsLoading(true);

        const payload = {

            name: appName,
            description: description,
            scope: scope,
            useOcr: useOcr,
            ocr: {
                selectedOcr: selectedOcr
            },
            provider: {
                type: provider?.value,
                connection: connection,
            },

            index: {
                name: indexName,
                method: indexMethod,
                frequency: indexingFrequency,
                metadataBuilder: metadataBuilder
            },
            attachments: []
        }

        if (connection.files) {

            const formData = new FormData();
            connection.files.forEach(file => {
                formData.append(
                    "files",
                    file
                );
            });

            post_form(url.UPLOAD_FILES, formData).then(response => {
                const new_connection = { ...connection, files: response.attachments }
                payload.attachments = response.attachments
                payload.provider.connection = new_connection
                setConnection(new_connection)
                dispatch(onAddNewDatasourceList(payload));
            }).catch(error => console.log(error))
        } else {
            dispatch(onAddNewDatasourceList(payload));
        }
    }

    function toggleTab(tab) {

        if (activeTab !== tab) {
            var modifiedSteps = [...passedSteps, tab]
            if (tab >= 1 && tab <= 4) {
                setactiveTab(tab)
                setPassedSteps(modifiedSteps)
            }
        }

        if (!indexName) {
            setIndexName(appName + "-index")
        }
    }

    useEffect(() => {

        const userPrincipals = (users || []).map(u => ({
            label: u.firstName + " " + u.name,
            value: u.id
        }))

        const groupPrincipals = (groups || []).map(u => ({
            label: u.name,
            value: u.id
        }))

        setMembers([...userPrincipals, ...groupPrincipals]);

    }, [users, groups]);

    useEffect(() => {
        setShowMemberSelection(scope === "custom");
    }, [scope])

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs title="Jask" breadcrumbItem="Nouvelle source de données" />

                    <Row >
                        <Col lg="12">
                            <Card className="datasources-panel">
                                <CardBody>
                                    <h4 className="card-title mb-4">Configuration</h4>
                                    <div className="wizard clearfix">
                                        <div className="steps clearfix">
                                            <ul>
                                                <NavItem
                                                    className={classnames({ current: activeTab === 1 })}
                                                >
                                                    <NavLink
                                                        className={classnames({ current: activeTab === 1 })}
                                                        onClick={() => {
                                                            setactiveTab(1)
                                                        }}
                                                        disabled={!(passedSteps || []).includes(1)}
                                                    >
                                                        <span className="number">1.</span> General
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem
                                                    className={classnames({ current: activeTab === 2 })}
                                                >
                                                    <NavLink
                                                        className={classnames({ active: activeTab === 2 })}
                                                        onClick={() => {
                                                            setactiveTab(2)
                                                        }}
                                                        disabled={!(passedSteps || []).includes(2)}
                                                    >
                                                        <span className="number">2.</span> Connectivité
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem
                                                    className={classnames({ current: activeTab === 3 })}
                                                >
                                                    <NavLink
                                                        className={classnames({ active: activeTab === 3 })}
                                                        onClick={() => {
                                                            setactiveTab(3)
                                                        }}
                                                        disabled={!(passedSteps || []).includes(3)}
                                                    >
                                                        <span className="number">3.</span> Indexation
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem
                                                    className={classnames({ current: activeTab === 4 })}
                                                >
                                                    <NavLink
                                                        className={classnames({ active: activeTab === 4 })}
                                                        onClick={() => {
                                                            setactiveTab(4)
                                                        }}
                                                        disabled={!(passedSteps || []).includes(4)}
                                                    >
                                                        <span className="number">4.</span> Revoir & Créer
                                                    </NavLink>
                                                </NavItem>
                                            </ul>
                                        </div>
                                        <div className="content clearfix">
                                            <TabContent activeTab={activeTab} className="body">
                                                <TabPane tabId={1}>
                                                    <Form>
                                                        <Row>
                                                            <Col lg="6">
                                                                <div className="mb-3">
                                                                    <Label for="basicpill-name-input1">
                                                                        Nom
                                                                    </Label>
                                                                    <Input
                                                                        type="text"
                                                                        className="form-control"
                                                                        id="basicpill-name-input1"
                                                                        placeholder="Enter the datasource name"
                                                                        value={appName}
                                                                        onChange={e => setAppName(e.target.value)}
                                                                    />
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col lg="6">
                                                                <div className="mb-3">
                                                                    <Label>Fournisseur de données</Label>
                                                                    {/* <select className="form-select"
                                                                        value={provider}
                                                                        onChange={e => handleProvider(e.target.value)}
                                                                    >
                                                                        <option defaultValue>
                                                                            Select provider
                                                                        </option>
                                                                        <option value="local">
                                                                            Local
                                                                        </option>
                                                                        <option value="azure">Azure</option>
                                                                        <option value="salesforce">Salesforce</option>
                                                                        <option value="sharepoint">Sharepoint</option>
                                                                        <option value="web">Web</option>

                                                                    </select> */}

                                                                    <DatasourceCustomSelect

                                                                        options={datasourceProviders}
                                                                        onChange={handleProvider}
                                                                    />
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        {/* <Row>
                                                            <Col lg="6">
                                                                <div className="mb-3">
                                                                    <Label>Storage Type</Label>
                                                                    <select className="form-select">
                                                                        <option defaultValue>
                                                                            Select provider
                                                                        </option>
                                                                        <option value="AE">
                                                                            Azure Storage Account
                                                                        </option>
                                                                        <option value="VI">Azure Datalake Gen2</option>
                                                                    </select>
                                                                </div>
                                                            </Col>
                                                        </Row> */}


                                                        <Row>
                                                            <Col lg="6">
                                                                <div className="mb-3">
                                                                    <Label>Confidentialité</Label>
                                                                    <select className="form-select"
                                                                        value={scope}
                                                                        onChange={e => setScope(e.target.value)}
                                                                    >
                                                                        <option value="private"> Privé</option>
                                                                        <option value="shared">Partagé</option>
                                                                        <option value="custom">personnalisé</option>

                                                                    </select>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        {showMembersSelection && <Row>
                                                            <Col lg="6">
                                                                <div className="mb-3">
                                                                    <Label>Utilisateurs / groupes autorisés</Label>

                                                                    <Select className='select2-selection'
                                                                        value={selectedMembers}
                                                                        options={members}
                                                                        onChange={setSelectedMembers}
                                                                        isMulti={true}
                                                                    >
                                                                    </Select>
                                                                </div>
                                                            </Col>
                                                        </Row>}

                                                        <Row>
                                                            <Col lg="6">
                                                                <div className="mb-3">
                                                                    <Label for="basicpill-address-input1">
                                                                        Description
                                                                    </Label>
                                                                    <textarea
                                                                        id="basicpill-address-input1"
                                                                        className="form-control"
                                                                        rows="5"
                                                                        placeholder="Describe ths datasources and type of contents"
                                                                        value={description}
                                                                        onChange={e => setDescription(e.target.value)}
                                                                    />
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </Form>
                                                </TabPane>
                                                <TabPane tabId={2}>
                                                    <Connectivity type={provider?.value} onSetConnection={(conn) => setConnection(conn)} />
                                                </TabPane>
                                                <TabPane tabId={3}>
                                                    <Row>
                                                        <Card>
                                                            <CardBody>
                                                                <CardTitle className="h5">Index this datasource </CardTitle>
                                                                <p className="card-title-desc">Useful if you want to query this datasource from chatbot apps (ChatGPT like experience). It will be done automatically with default settings if you link this datasource with a chatbot app. </p>
                                                                <Row>
                                                                    <Col xl={6}>

                                                                        <Form>
                                                                            <div className="form-floating mb-3">
                                                                                <input type="text" className="form-control" id="floatingnameInput" placeholder="Enter Name" value={indexName}
                                                                                    onChange={e => setIndexName(e.target.value)} />
                                                                                <label htmlFor="floatingnameInput">Index Name</label>
                                                                            </div>
                                                                        </Form>

                                                                    </Col>
                                                                    <Col xl={6}>

                                                                        <div className="form-floating mb-3">
                                                                            <select defaultValue="0" className="form-select"
                                                                                value={useOcr}
                                                                                onChange={e => setUseOcr(e.target.value)}
                                                                            >
                                                                                <option value="false">No</option>
                                                                                <option value="true">Yes</option>
                                                                            </select>
                                                                            <label htmlFor="floatingSelectGrid">Use OCR</label>
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </CardBody>
                                                        </Card>
                                                    </Row>
                                                    <Row>
                                                        <Card>
                                                            <CardBody>
                                                                <CardTitle className="h5">Advanced Options </CardTitle>
                                                                <p className="card-title-desc">Customize the indexing settings to improve quality based on the type of documents exposed by this datasource. Leave default settings for good average result.</p>

                                                                <Row>
                                                                    <Col xl={6}>

                                                                        <Form>
                                                                            <Row>
                                                                                <Col md={12}>
                                                                                    <div className="form-floating mb-3">
                                                                                        <select defaultValue="0" className="form-select"
                                                                                            value={indexMethod}
                                                                                            onChange={e => setIndexMethod(e.target.value)}
                                                                                        >
                                                                                            <option value="default">Default</option>
                                                                                            <option value="rag">Retriver Augmented Generation (RAG)</option>
                                                                                            <option value="raa">Random Augmentation Approach </option>
                                                                                            <option value="baa">Bounded Augmentation Approach </option>

                                                                                        </select>
                                                                                        <label htmlFor="floatingSelectGrid">Indexing Method</label>
                                                                                    </div>
                                                                                </Col>
                                                                            </Row>

                                                                            <Row>
                                                                                <Col md={12}>
                                                                                    <div className="form-floating mb-3">
                                                                                        <select defaultValue="0" className="form-select"
                                                                                            value={selectedOcr}
                                                                                            onChange={e => setSelectedOcr(e.target.value)}
                                                                                        >
                                                                                            <option value="default">Default</option>
                                                                                            <option value="azureFormRec">Azure Form Recognizer</option>
                                                                                            <option value="easyOcr">Easy OCR </option>
                                                                                        </select>
                                                                                        <label htmlFor="floatingSelectGrid">OCR Available</label>
                                                                                    </div>
                                                                                </Col>
                                                                            </Row>




                                                                        </Form>

                                                                    </Col>
                                                                    <Col xl={6}>

                                                                        <Row>
                                                                            <div className="form-floating mb-3">
                                                                                <select defaultValue="0" className="form-select"

                                                                                    value={indexingFrequency}
                                                                                    onChange={e => setIndexingFrequency(e.target.value)}
                                                                                >
                                                                                    <option value="default">Default</option>
                                                                                    <option value="daily">Daily</option>
                                                                                    <option value="weekly">Weekly</option>
                                                                                    <option value="monthly">Monthly</option>

                                                                                </select>
                                                                                <label htmlFor="floatingSelectGrid">Indexing Frequency</label>
                                                                            </div>
                                                                        </Row>
                                                                        <Row>
                                                                            <Col md={12}>
                                                                                <div className="form-floating mb-3">
                                                                                    <select defaultValue="0" className="form-select" value={metadataBuilder}
                                                                                        onChange={e => setMetadataBuilder(e.target.value)}>
                                                                                        <option value="default">Default</option>
                                                                                        <option value="gen">Generative  Approach </option>
                                                                                        <option value="custom">Custom Table</option>
                                                                                    </select>
                                                                                    <label htmlFor="floatingSelectGrid">Metadata builder</label>
                                                                                </div>
                                                                            </Col>
                                                                        </Row>
                                                                    </Col>
                                                                </Row>
                                                                <Row>

                                                                </Row>
                                                            </CardBody></Card>
                                                    </Row>

                                                </TabPane>
                                                <TabPane tabId={4}>
                                                    <Row>
                                                        <Col md={8} lg={6}>
                                                            <div className=" mt-0">
                                                                <div className="table-responsive">
                                                                    <Table className="table align-middle table-nowrap table-hover mb-0">
                                                                        {/* <thead>
                                                    <tr>
                                                        <th scope="col">Item</th>
                                                        <th scope="col"> </th>
                                                    </tr>
                                                </thead> */}
                                                                        <tbody>
                                                                            <tr >
                                                                                <td>
                                                                                    <div className='hstack gap-2'> Datasource Name</div></td>
                                                                                <td>
                                                                                    <div className='hstack gap-2'>
                                                                                        {appName}
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                            <tr >
                                                                                <td>
                                                                                    <div className='hstack gap-2'>Default Scope</div></td>
                                                                                <td>
                                                                                    <div className='hstack gap-2'>
                                                                                        {scope}
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                            <tr >
                                                                                <td>
                                                                                    <div className='hstack gap-2'> Connector Type</div></td>
                                                                                <td>
                                                                                    <div className='hstack gap-2'>
                                                                                        <Badge className="badge-soft-info font-size-12">{provider?.label}</Badge>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                            <tr >
                                                                                <td>
                                                                                    <div className='hstack gap-2'> Index Name</div></td>
                                                                                <td>
                                                                                    <div className='hstack gap-2'>
                                                                                        <Badge className="badge-soft-info font-size-12">{indexName}</Badge>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                            <tr >
                                                                                <td>
                                                                                    <div className='hstack gap-2'> Indexing Frequency</div></td>
                                                                                <td>
                                                                                    <div className='hstack gap-2'>
                                                                                        <Badge className="badge-soft-info font-size-12">{indexingFrequency}</Badge>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                            <tr >
                                                                                <td>
                                                                                    <div className='hstack gap-2'> Indexing Method</div></td>
                                                                                <td>
                                                                                    <div className='hstack gap-2'>
                                                                                        <Badge className="badge-soft-info font-size-12">{indexMethod}</Badge>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                            <tr >
                                                                                <td>
                                                                                    <div className='hstack gap-2'> Metadata  Table</div></td>
                                                                                <td>
                                                                                    <div className='hstack gap-2'>
                                                                                        <Badge className="badge-soft-info font-size-12">{metadataBuilder}</Badge>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                            <tr >
                                                                                <td>
                                                                                    <div className='hstack gap-2'> Use OCR</div></td>
                                                                                <td>
                                                                                    <div className='hstack gap-2'>
                                                                                        {useOcr}
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </Table>
                                                                </div>

                                                            </div>
                                                        </Col>

                                                        <Col lg={6} md={4} className="d-flex justify-content-center">
                                                            <RotatingLines
                                                                strokeColor="white"
                                                                strokeWidth="5"
                                                                animationDuration="0.75"
                                                                width="150"
                                                                visible={isLoading}
                                                            />
                                                        </Col>
                                                    </Row>
                                                </TabPane>
                                            </TabContent>
                                        </div>
                                        <div className="actions clearfix">
                                            <ul>
                                                <li
                                                    className={
                                                        activeTab === 1 ? "previous disabled" : "previous"
                                                    }
                                                >
                                                    <Link
                                                        to="#"
                                                        onClick={() => {
                                                            toggleTab(activeTab - 1)
                                                        }}
                                                    >
                                                        Prev.
                                                    </Link>
                                                </li>
                                                <li
                                                    className={activeTab === 4 ? "next disabled" : "next"}
                                                >
                                                    <Link
                                                        to="#"
                                                        onClick={() => {
                                                            toggleTab(activeTab + 1)
                                                        }}
                                                    >
                                                        Next
                                                    </Link>
                                                </li>
                                                <li>
                                                    {canSubmit && <button className="btn btn-success me-1" onClick={handleAddDatasource}> Review and Create <i className="bx bx-send align-middle"></i></button>}
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment >
    )
}

export default FormWizard
