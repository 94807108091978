import React, { useEffect, useState, useRef } from "react"
import { Badge, Spinner } from "reactstrap";
import SimpleBar from "simplebar-react";
import { isEmpty, map } from "lodash";
import { Link } from "react-router-dom";
import GenerationTextArea from "components/Assistant/Generation";
import { TaskStatus } from "constants/tasks";

const ApplicationStepView = ({ item, stepContent, text, context, status, isRunning, providerInfo, model, index, onItemSelected, isLastStep }) => {
    const [collapsed, setCollapsed] = useState(true);
    const [collapseAuto, setCollapseAuto] = useState(true);
    const [selected, setSelected] = useState(false);


    useEffect(() => {
        if (onItemSelected) {
            onItemSelected(item, selected);
        }
    }, [selected])

    useEffect(() => {
        if (isRunning === true && collapsed && collapseAuto) {
            setCollapsed(false);
        } else if (!isRunning && collapseAuto && !isLastStep) {
            setCollapsed(true);
        }
    }, [isRunning, isLastStep])

    return <div key={item.id} className="d-flex flex-column border-secondary  border-opacity-25 rounded-3" >

        <div className="hstack gap-2  rounded-3 p-2  ps-4 item-banner bg-primary bg-opacity-25 mb-2 ">
            <div>
                <input type="checkbox"
                    className="form-check-input font-size-16 mb-3 border-info"
                    checked={selected}
                    onClick={e => setSelected(!selected)}
                />
            </div>
            <div className=" w-100  step-view" onClick={() => setCollapsed(!collapsed)}>
                <div className=" d-flex justify-content-between ">
                    <div className="d-flex flex-row">
                        <h5 className="text-muted font-size-16">{index + 1}. {item.name} <Badge color="info" className="ms-1">{model?.name}</Badge></h5>
                        {providerInfo.name && <div>
                            <i className="bx bx bx-right-arrow-alt ms-2 font-size-20" />
                        </div>}
                        {providerInfo.name && <div className="ms-2">
                            <Badge color="danger" >{providerInfo.name}</Badge>
                        </div>}
                    </div>
                    <div>
                        <div className="hstack gap-1">

                            <div>
                                {status === TaskStatus.STARTED && <Spinner className="ms-2 text-success" color="primary" size="sm" />}
                            </div>
                            <div>
                                {status === TaskStatus.COMPLETED && <div className="hstack gap-1">
                                    <i className="mdi mdi-check-circle text-success font-size-16" />
                                </div>}

                            </div>
                            <div>
                                {status === TaskStatus.CANCELLED && <div className="hstack gap-1">
                                    <Badge color="danger" >Annulée</Badge>
                                </div>}

                            </div>
                            <div>
                                {isEmpty(status) && <Badge color="primary" >En attente</Badge>}
                            </div>
                            <div>
                                <Link onClick={() => setCollapsed(!collapsed)}>{collapsed ? <i className="mdi mdi-chevron-right font-size-20" /> : <i className="mdi mdi-chevron-down font-size-20" />}</Link>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="ms-2"><Link className="font-size-10"> Voir la description</Link></div> */}


            </div>
        </div>

        {!collapsed && <div >

            {text && <div className="mb-2 p-2">
                <GenerationTextArea text={text} isGenerating={isRunning} />
            </div>}

            <div>
                {
                    context && (
                        <div className="">
                            {text && <h6>Références:</h6>}
                            <ul className="list-unstyled ">
                                {
                                    map(context, ref => {
                                        return <li><Link>
                                            <div className="vstack gap-1">
                                                <span>{ref.name}</span>
                                                <div className="hstack gap-2">
                                                    <Badge className="badge badge-soft-info"> {ref.source}</Badge>
                                                    <Badge className="badge badge-soft-info"> {ref.owner}</Badge>

                                                    {
                                                        map(Object.values(ref.tags), tag => {
                                                            return <Badge className="badge badge-soft-danger"> {tag}</Badge>
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        </Link>
                                        </li>
                                    })
                                }
                            </ul>
                        </div>
                    )
                }
            </div>
        </div>}

    </div>
}

export default ApplicationStepView;