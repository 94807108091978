import React, { useEffect, useState } from "react"
import { Card, CardBody, CardFooter, Col, Row } from "reactstrap"
import { withTranslation } from "react-i18next";
import Select from "react-select"
import { FileExtensions, ResourceTypes } from "constants/general";
import { Link } from "react-router-dom";
import SimpleBar from "simplebar-react";
import { map, truncate } from "lodash";
import { getFileIcon } from "utils";

const Settings = ({ t, source, onChange, onDatasourceFilterClicked, onRemoveDatasourceFilter }) => {

    const [extensions, setExtensions] = useState(FileExtensions);

    useEffect(() => {
        console.log(source.filters)
        onChange({
            id: source.id,
            sharepoint: {
                includeExtensions: extensions.map(e => e.value),
                locations: (source.filters || []).map(s => s.location)
            }
        });
    }, [source])

    return <div>

        <Row className="mb-3">
            <Col md={3} className=" pt-2">
                <span className="fw-bold font-size-12"> {t("Traiter les fichiers:")}</span>
            </Col>
            <Col md={9}>
                <Select
                    options={FileExtensions}
                    isMulti={true}
                    value={extensions}
                    onChange={setExtensions}
                />
            </Col>
        </Row>


        <Row className="mb-3">
            <Col md={3} className=" pt-2">
                <span className="fw-bold font-size-12">{t("Filtrer les données:")}</span>
            </Col>
            <Col md={9}>
                <div className="vstack gap-1">
                    <Card className="" style={{ height: "180px" }}>
                        <CardBody className="border  border-secondary border-opacity-50 ">
                            <SimpleBar className="scroller-browser-datasource">
                                {map(source?.filters || [], item => {
                                    return <div className="d-flex justify-content-between">
                                        <div>
                                            <div className="hstack gap-1 m-1">
                                                {(item.isDir || item.type === ResourceTypes.DIRECTORY) ? <i className="mdi mdi-folder text-warning" /> : getFileIcon(item.name, 14)}
                                                <span>{truncate(item.name, { length: 50 })}</span>
                                            </div>
                                        </div>

                                        <div>
                                            <Link onClick={() => onRemoveDatasourceFilter(source, item.id)}><i className="mdi mdi-trash-can me-2" /></Link>
                                        </div>

                                    </div>
                                })}

                            </SimpleBar>

                        </CardBody>
                        <CardFooter>
                            <Link className="font-size-10 float-end" onClick={() => onDatasourceFilterClicked(source)}>
                                <i className="mdi mdi-filter" />{t("Parcourir")}
                            </Link>
                        </CardFooter>
                    </Card>
                </div>

            </Col>
        </Row>


        {/* <Row className="mb-3">
            <Col md={3} className=" pt-2">
                <span className="fw-bold font-size-12"> {t("Exclure les chemins:")}</span>
            </Col>
            <Col md={9}>
                <input type="text"
                    className="form-control"
                    placeholder={t("liste des chemins séparés par une virgule")}

                />
            </Col>
        </Row> */}



    </div>
}

export default withTranslation()(Settings);