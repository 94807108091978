import React, { createRef, useEffect, useRef, useState } from "react";
import { TaskStatus } from "constants/tasks";
import { LaunchType, ScenarioType } from "../constants";
import SingleFileTaskResult from "./simple";
import { runFileTask } from "service/streams/fileTask";
import { useSelector } from "react-redux";
import { EventTypes } from "constants/events";
import { isEmpty } from "lodash";
import { operationCompleted, operationFailed } from "utils/notifications";
import { ExportType } from "constants/export";
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import ExportGeneration from "components/Export";
import FileSaver from "file-saver"
import MultipleFileTaskResult from "./grouped";
import { uuidv4 } from "utils";

const getView = (scenarioId, files, instructions, onStartTask, streams, taskStatus, taskId) => {

    switch (scenarioId) {
        case ScenarioType.FILE_BY_FILE:
            return <SingleFileTaskResult
                files={files}
                instructions={instructions}
                onStart={onStartTask}
                streams={streams}
                status={taskStatus}
            />

        case ScenarioType.MULTIPLE_FILE:

            return <MultipleFileTaskResult
                files={files}
                instructions={instructions}
                onStart={onStartTask}
                streams={streams}
                status={taskStatus}
                taskId={taskId}
            />

    }
}

const TaskResult = ({ scenario, files, instructions }) => {

    const [taskId, setTaskId] = useState(null);
    const [taskFiles, setTaskFiles] = useState([]);
    const [status, setStatus] = useState(TaskStatus.NOT_STARTED);
    const [streams, setStreams] = useState({});
    const [showExport, setShowExport] = useState(false);
    const [exportable, setExportable] = useState([]);
    const [exportType, setExportType] = useState(null);
    const [lineage, setLineage] = useState([]);

    const { accessToken } = useSelector(state => ({
        accessToken: state.Login.accessToken,
    }));

    const updateFileStatus = (id, status) => {
        const files = [...taskFiles];

        files.forEach(f => {
            if (f.location === id) {
                f.status = status;
            }
        });

        setTaskFiles(files);
    }

    const cancellation = useRef(new AbortController());

    const onFileStart = (id) => {
        updateFileStatus(id, TaskStatus.STARTED);
        setStreams(prev => {
            return {
                ...prev,
                [id]: {
                    id: id,
                    substeps: {},
                    text: "",
                    context: null,
                    status: TaskStatus.STARTED,
                }
            }
        })
    }

    const onFileEnd = (id) => {
        updateFileStatus(id, TaskStatus.COMPLETED);
        setStreams(prev => {
            const items = { ...prev }
            const oldContent = items[id];
            const merge = {
                ...items, [id]: {
                    ...oldContent,
                    status: TaskStatus.COMPLETED
                }
            }

            return merge
        })
    }
    const onStepStart = (id, parentId, model, name) => {
        setStreams(prev => {
            const items = { ...prev }
            const parentContent = items[parentId];
            const parentSubsteps = { ...parentContent.substeps };

            return {
                ...items, [parentId]: {
                    ...parentContent,
                    substeps: {
                        ...parentSubsteps,
                        [id]: {
                            name: name,
                            text: "",
                            context: "",
                            status: TaskStatus.STARTED,
                            model: model,
                        }
                    }
                }
            }
        })
    }

    const onLineage = (parentId, lineage) => {
        setLineage(lineage);
        setStreams(prev => {
            const items = { ...prev }
            const parentContent = items[parentId];

            return {
                ...items, [parentId]: {
                    ...parentContent,
                    lineage: lineage
                }
            }
        })
    }

    const onStreamUpdate = (id, parentId, type, evt) => {

        if (isEmpty(parentId)) {
            setStreams(prev => {
                const items = { ...prev }
                const oldContent = items[id];
                const merge = {
                    ...items, [id]: {
                        ...oldContent,
                        text: type === EventTypes.STREAMING ? oldContent.text + evt.value : oldContent.text,
                        context: type === EventTypes.CONTEXT ? evt.value.references : oldContent.context,
                        status: type === EventTypes.STEP_END ? TaskStatus.COMPLETED : oldContent.status

                    }
                }
                return merge

            });
        } else {
            setStreams(prev => {

                const items = { ...prev }
                const parentContent = items[parentId];
                const parentSubsteps = { ...parentContent.substeps };
                const stepContent = parentSubsteps[id];

                return {
                    ...items, [parentId]: {
                        ...parentContent,
                        substeps: {
                            ...parentSubsteps,
                            [id]: {
                                ...stepContent,
                                text: type === EventTypes.STREAMING ? stepContent.text + evt.value : stepContent.text,
                                context: type === EventTypes.CONTEXT ? evt.value.references : stepContent.context,
                                status: type === EventTypes.STEP_END ? TaskStatus.COMPLETED : stepContent.status
                            }
                        }
                    }
                }
            });
        }
    }

    const onTaskEnd = () => {
        setStatus(TaskStatus.COMPLETED);
        operationCompleted("Execution terminée. Vous pouvez désormais exporter les résultats si nécessaire.")
    }

    const onTaskStream = (evt) => {

        const id = evt.id;
        const type = evt.type;
        const parentId = evt.parentId;
        const model = {
            id: evt.modelId,
            name: evt.modelName
        }

        switch (type) {

            case EventTypes.TASK_START:
                setStatus(TaskStatus.STARTED);
                break;

            case EventTypes.TASK_END:
                onTaskEnd();
                break;

            case EventTypes.APPLICATION_LINEAGE:
                onLineage(parentId, evt.value)
                break;

            case EventTypes.FILE_START:
                onFileStart(id)
                break;

            case EventTypes.FILE_END:
                onFileEnd(id);
                break;

            case EventTypes.LOADING_FILE:
                break;

            case EventTypes.STEP_START:
                onStepStart(id, parentId, model, evt.value);
                break;

            case EventTypes.STREAMING:
            case EventTypes.CONTEXT:
            case EventTypes.STEP_END:
                onStreamUpdate(id, parentId, type, evt)
                break;
        }
    }

    const onExport = (type) => {
        setExportType(type);
        setShowExport(!showExport);
    }

    const getResultAsText = (streams) => {
        console.log("Processing streams ....", streams)
        return Object.keys(streams).map(taskId => {

            const task = streams[taskId];
            const result = {
                id: taskId,
                content: task.text || ""
            }

            lineage.forEach((appStepInfo, index) => {
                const stepResult = task.substeps[appStepInfo.id];
                const stepResultStr = `${(index + 1)} ${appStepInfo.name} \n ${stepResult.text}`;
                result.content = `${result.content}\n ${stepResultStr} \n`
            });

            console.log("Lineage ", lineage)

            return result;
        })

    }



    const onStartTask = (launcher) => {

        const taskId = uuidv4()
        setTaskId(taskId);

        const task = {
            id: taskId,
            app: instructions.app,
            instructions: instructions.instruction,
            model: instructions.model,
            processingType: instructions.type,
            files: files,
            scenarioType: scenario.id
        }


        if (launcher === LaunchType.IMMEDIAT) {
            runFileTask(task, onTaskStream, console.log, console.log, console.log, accessToken, cancellation?.current)
        }
    }

    const cancelTask = () => {
        cancellation.current.abort();
        cancellation.current = new AbortController();
        setStatus(TaskStatus.COMPLETED);

        const files = [...taskFiles];

        files.forEach(f => {
            if (f.status !== TaskStatus.COMPLETED)
                f.status = TaskStatus.CANCELLED;
        });

        setTaskFiles(files);

        operationFailed("Tache annulée avec succès.");
    }

    const downloadContent = () => {
        FileSaver.saveAs(new Blob([exportable[0].content], { type: "text/plain;charset=utf-8" }), `${new Date().toLocaleTimeString()}.txt`)
    }

    useEffect(() => {
        setTaskFiles(files.map(f => ({
            ...f,
            status: TaskStatus.NOT_STARTED,
        })))
    }, [files]);

    useEffect(() => {
        if (status === TaskStatus.COMPLETED) {
            const result = getResultAsText(streams);
            let exportable;

            switch (scenario.id) {
                case ScenarioType.MULTIPLE_FILE:
                    exportable = [{
                        ...result[0],
                        name: "Group"
                    }]
                    break;

                case ScenarioType.FILE_BY_FILE:
                    exportable = taskFiles.map(file => {
                        const taskResult = result.find(r => r.id === file.location);
                        if (taskResult) {
                            return {
                                ...taskResult,
                                name: file.name,
                                location: file.location,
                            }
                        }
                    });
                    break;
            }

            console.log("Exportable ", exportable)
            setExportable(exportable);
        }
    }, [streams, status])

    return <div>
        <ExportGeneration
            taskName={instructions?.app?.name}
            type={exportType}
            data={exportable}
            isOpen={showExport}
            onCloseClick={() => setShowExport(false)}
            directory={"/"}
        />
        {getView(scenario?.id, taskFiles, instructions, onStartTask, streams, status, taskId)}

        <div className="float-end">
            <div className="hstack gap-1">

                <button disabled={status === TaskStatus.STARTED} onClick={() => onStartTask(LaunchType.IMMEDIAT)} className="btn btn-success me-2">Relancer</button>
                {status === TaskStatus.STARTED && <button onClick={cancelTask} className="btn btn-danger">Arreter</button>}
                {status === TaskStatus.COMPLETED && <UncontrolledDropdown>
                    <DropdownToggle className="btn btn-secondary">
                        Exporter vers<i className="mdi mdi-chevron-right" />
                    </DropdownToggle>
                    <DropdownMenu>

                        <DropdownItem onClick={() => onExport(ExportType.DOCUMENT)}> <i className="bx bxs-folder" /> Mes documents</DropdownItem>
                        <DropdownItem divider />
                        <DropdownItem onClick={downloadContent}> <i className="bx bx-download" /> Mon Poste de travail</DropdownItem>

                        <DropdownItem divider />
                        <DropdownItem onClick={() => onExport(ExportType.TODO)}> <i className="bx bx-task" /> Mes tâches</DropdownItem>
                        <DropdownItem divider />
                        <DropdownItem disabled onClick={() => onExport(ExportType.EMAIL)}> <i className="bx bxs-envelope" /> Boite Mail</DropdownItem>

                        <DropdownItem divider />
                        <DropdownItem disabled onClick={() => onExport(ExportType.APPS)}> <i className="bx bx-code" /> Une application</DropdownItem>

                    </DropdownMenu>
                </UncontrolledDropdown>}
            </div>

        </div>
    </div>
}

export default TaskResult;