import { API_URL, del, get, post, put } from "helpers/api_helper";
import { GET_DEPLOYMENTS } from "helpers/url_helper";


export const getChannelUsers = (channelId, deploymentId) => {
    return get(`${GET_DEPLOYMENTS}/channels/${channelId}/deployment/${deploymentId}`)
}

export const shareContentToUsers = (channelId, deploymentId, payload) => {
    return post(`${GET_DEPLOYMENTS}/channels/${channelId}/deployment/${deploymentId}/share`, payload)
}