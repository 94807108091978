import React, { useEffect, useState } from "react"
import { Label, Modal, ModalBody, Card, CardBody, Input, NavItem, NavLink, TabContent, TabPane, ModalFooter, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Badge } from "reactstrap"
import AppExecution from "./runner/content";
import CustomAppRunner from "./runner";
import ExportGeneration from "components/Export";
import { ExportType } from "constants/export";
import FileSaver from "file-saver"

const AppLauncher = ({ show, onCloseClick, directory, files, app }) => {

    const [config, setConfig] = useState("");
    const [runTask, setRunTask] = useState(false);
    const [isRunning, setIsRunning] = useState(false);
    const [progress, setProgress] = useState(0);
    const [showExport, setShowExport] = useState(false);
    const [exportable, setExportable] = useState([]);
    const [exportType, setExportType] = useState(null);


    const onTaskConfigurationChange = (config) => {
        setConfig(config)
    }

    const runJob = () => {
        setRunTask(!runTask);
        setIsRunning(true);
    }

    const onFinish = (result) => {
        setIsRunning(false);
        setRunTask(false);

        const exportable = files.filter(f => f.isSelected).map(file => {
            const taskResult = result.filter(r => r.id === file.location);
            if (taskResult) {
                return {
                    ...taskResult[0],
                    name: file.name,
                    location: file.location,
                }
            }
        });

        setExportable(exportable);
    }

    const downloadContent = () => {
        FileSaver.saveAs(new Blob([exportable[0].content], { type: "text/plain;charset=utf-8" }), `${new Date().toLocaleTimeString()}.txt`)
    }

    const onExport = (type) => {
        setExportType(type);
        setShowExport(!showExport);
    }


    return <React.Fragment>
        <Modal size="xl" isOpen={show} toggle={onCloseClick} centered={true} contentClassName='document-window-board-runner' fade={false}>
            <ExportGeneration
                taskName={app?.name}
                type={exportType}
                data={exportable}
                isOpen={showExport}
                onCloseClick={() => setShowExport(false)}
                directory={directory}
            />
            <div className="d-flex justify-content-between pt-4 ps-4 pe-4 border-bottom pb-2">
                <div className="">
                    <h5> Nouvelle tâche: {app?.name || config?.instructions?.slice(0, 100)}</h5>
                    <div className="hstack gap-1 ">
                        <Badge className="">Traitement : Fichier par fichier</Badge>
                        <Badge className="">Model : {config.model || "Hérité"}</Badge>
                    </div>

                </div>
                <div>
                    <button onClick={onCloseClick} className="btn-close float-end"></button>
                </div>
            </div>

            <div className="modal-body">
                <CustomAppRunner
                    app={app}
                    directory={directory}
                    files={files}
                    onConfigChange={onTaskConfigurationChange}
                    runTask={runTask}
                    taskConfig={config}
                    isRunning={isRunning}
                    onFinish={onFinish}
                    onProgressChange={setProgress}
                />
            </div>

            <ModalFooter>
                <div className="d-flex justify-content-end">
                    {config.isValid && !isRunning && <button className="btn btn-success" onClick={runJob} >Démarrer</button>}
                    {(!config.isValid || isRunning) && <button className="btn btn-secondary" disabled={true} >Démarrer</button>}
                    <button className="btn btn-danger ms-2" onClick={onCloseClick} disabled={isRunning}>Annuler</button>
                    {!isRunning && progress > 0 && <div className="vr ms-2 me-2" />}

                    {!isRunning && progress > 0 && <UncontrolledDropdown>
                        <DropdownToggle className="btn btn-secondary">
                            Exporter vers<i className="mdi mdi-chevron-right" />
                        </DropdownToggle>
                        <DropdownMenu>

                            <DropdownItem onClick={() => onExport(ExportType.DOCUMENT)}> <i className="bx bxs-folder" /> Mes documents</DropdownItem>
                            <DropdownItem divider />
                            <DropdownItem onClick={downloadContent}> <i className="bx bx-download" /> Mon Poste de travail</DropdownItem>

                            <DropdownItem divider />
                            <DropdownItem onClick={() => onExport(ExportType.TODO)}> <i className="bx bx-task" /> Mes tâches</DropdownItem>
                            <DropdownItem divider />
                            <DropdownItem disabled onClick={() => onExport(ExportType.EMAIL)}> <i className="bx bxs-envelope" /> Boite Mail</DropdownItem>

                            <DropdownItem divider />
                            <DropdownItem disabled onClick={() => onExport(ExportType.APPS)}> <i className="bx bx-code" /> Une application</DropdownItem>

                        </DropdownMenu>
                    </UncontrolledDropdown>}

                </div>
            </ModalFooter>
        </Modal >
    </React.Fragment>

}

export default AppLauncher;