import {
  GET_DATASOURCE_LIST,
  GET_DATASOURCE_DOMAINS_LIST,
  GET_DATASOURCE_LIST_FAIL,
  GET_DATASOURCE_LIST_SUCCESS,
  ADD_NEW_DATASOURCE_LIST,
  ADD_DATASOURCE_LIST_SUCCESS,
  ADD_DATASOURCE_LIST_FAIL,
  UPDATE_DATASOURCE_LIST,
  UPDATE_DATASOURCE_LIST_SUCCESS,
  UPDATE_DATASOURCE_LIST_FAIL,
  DELETE_DATASOURCE_LIST,
  DELETE_DATASOURCE_LIST_SUCCESS,
  DELETE_DATASOURCE_LIST_FAIL,
  GET_APPLY_DATASOURCE,
  GET_APPLY_DATASOURCE_SUCCESS,
  GET_APPLY_DATASOURCE_FAIL,
  DELETE_APPLY_DATASOURCE,
  DELETE_APPLY_DATASOURCE_SUCCESS,
  DELETE_APPLY_DATASOURCE_FAIL,
  GET_DATASOURCE_DOMAINS_LIST_SUCCESS,
  GET_DATASOURCE_DOMAINS_LIST_ERROR,
  STOP_CHECKING_INDEX
} from "./actionTypes";

export const getDatasourceList = () => ({
  type: GET_DATASOURCE_LIST,
})
export const getDatasourceDomainList = () => ({
  type: GET_DATASOURCE_DOMAINS_LIST,
})

export const getDatasourceDomainListSuccess = Domains => ({
  type: GET_DATASOURCE_DOMAINS_LIST_SUCCESS,
  payload: Domains
})

export const getDatasourceDomainListError = () => ({
  type: GET_DATASOURCE_DOMAINS_LIST_ERROR,
})

export const getDatasourceListSuccess = Datasources => ({
  type: GET_DATASOURCE_LIST_SUCCESS,
  payload: Datasources,
})

export const getDatasourceListFail = error => ({
  type: GET_DATASOURCE_LIST_FAIL,
  payload: error,
})

export const addNewDatasourceList = data => ({
  type: ADD_NEW_DATASOURCE_LIST,
  payload: data,
})

export const stopCheckingIndexing = data => ({
  type: STOP_CHECKING_INDEX,
  payload: data,
})

export const addDatasourceListSuccess = Datasource => ({
  type: ADD_DATASOURCE_LIST_SUCCESS,
  payload: Datasource,
})

export const addDatasourceListFail = error => ({
  type: ADD_DATASOURCE_LIST_FAIL,
  payload: error,
})

export const updateDatasourceList = Datasource => ({
  type: UPDATE_DATASOURCE_LIST,
  payload: Datasource,
})

export const updateDatasourceListSuccess = Datasource => ({
  type: UPDATE_DATASOURCE_LIST_SUCCESS,
  payload: Datasource,
})

export const updateDatasourceListFail = error => ({
  type: UPDATE_DATASOURCE_LIST_FAIL,
  payload: error,
})

export const deleteDatasourceList = data => ({
  type: DELETE_DATASOURCE_LIST,
  payload: data,
})

export const deleteDatasourceListSuccess = data => ({
  type: DELETE_DATASOURCE_LIST_SUCCESS,
  payload: data,
})

export const deleteDatasourceListFail = error => ({
  type: DELETE_DATASOURCE_LIST_FAIL,
  payload: error,
})

export const getApplyDatasource = () => ({
  type: GET_APPLY_DATASOURCE,
})

export const getApplyDatasourceSuccess = data => ({
  type: GET_APPLY_DATASOURCE_SUCCESS,
  payload: data
})

export const getApplyDatasourceFail = error => ({
  type: GET_APPLY_DATASOURCE_FAIL,
  payload: error
})

export const deleteApplyDatasource = data => ({
  type: DELETE_APPLY_DATASOURCE,
  payload: data
})

export const deleteApplyDatasourceSuccess = data => ({
  type: DELETE_APPLY_DATASOURCE_SUCCESS,
  payload: data
})

export const deleteApplyDatasourceFail = error => ({
  type: DELETE_APPLY_DATASOURCE_FAIL,
  payload: error
})