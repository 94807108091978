
import { AppIdentityCard } from 'components/Common/CustomSelect';
import { toLocalDate } from 'helpers/to-local-date';
import { isEmpty, truncate } from 'lodash';
import React from 'react';
import { Link } from 'react-router-dom';
import { Badge } from 'reactstrap';
import { getProviderInfo, getProviderLogo, getServiceInfo } from 'utils';

const Default = (cell) => {
    return cell.value ? cell.value : "";
};

const LocalDate = (cell) => {
    return toLocalDate(cell.value)
}

const SourceInfo = ({ cell, onOpen, onProcess }) => {
    const source = cell.row.original
    return <div className='vstack text-'>

        <span>{getProviderLogo(source.providerType)}
            {cell.value}</span>
        <div className='hstack gap-1 ms'>
            <Link className='font-size-10' onClick={() => onProcess(source)}>Traiter</Link>
            <div className='vr font-size-10' />
            <Link className='font-size-10' onClick={() => onOpen(source)}>Voir</Link>
            <div className='vr font-size-10' />
            <Link className='font-size-10' onClick={() => onOpen(source)}>Archiver</Link>
        </div>
    </div>
};


const Status = (cell) => {
    return <Badge>{cell.value}</Badge>
};

const AssitantHandler = ({ cell, assistants }) => {
    const row = cell.row.original;
    const assistant = assistants.find(a => a.id === row.assistantId);
    return <div>
        {
            isEmpty(assistant) ? "" : <div className="" >
                <div className="d-flex flex-column p-0  rounded  align-items-center">
                    <img className=" bg-info bg-opacity-25 rounded-circle avatar-xs text-center" src={assistant.logoUrl} />
                    <Link className="text-muted font-size-10 text-center">{truncate(assistant.name, { length: 30 })}</Link>
                </div>
            </div>
        }

    </div >
};

const Description = ({ cell, onOpen }) => {
    const source = cell.row.original;
    return <Link className='text-dark' onClick={() => onOpen(source)}>
        <div className='vstack'>
            <div className='hstack gap-1'>
                <span className='fw-bold'>{source.summary}</span>
                <Badge color='info'>{source.status}</Badge>
                {source.assistantReply && <i className='mdi mdi-checkbox-marked text-success font-size-14' />}
            </div>
            <span>{truncate(cell.value, { length: 80 })}</span>
        </div>
    </Link>


};

const SupportPlatform = (cell) => {
    const source = cell.row.original;
    return <div>
        <div className='hstack gap-1'>
            {getProviderInfo(source.providerType)}
        </div>

    </div>
};

const SelectAllAction = ({ isAllSelected, onClick }) => {
    return <div className="form-check font-size-16">
        <input className="form-check-input" checked={isAllSelected} onClick={onClick} type="checkbox" id={"action-selectAll"} />
        <label className="form-check-label" htmlFor="id"></label>
    </div>
}



const SelectAction = ({ data, onClick }) => {

    return <div className="form-check font-size-16">
        <input className="form-check-input" checked={data.isSelected} onClick={() => onClick(data)} type="checkbox" id={"action-" + data.id} />
        <label className="form-check-label" htmlFor="id"></label>
    </div>
}

export {
    Default,
    SupportPlatform,
    SelectAction,
    SelectAllAction,
    Description, AssitantHandler, Status, SourceInfo, LocalDate
};