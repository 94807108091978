import React, { useState } from "react";
import {
    Container
} from "reactstrap";

import PromptList from "components/prompt/store"
//i18n
import { withTranslation } from "react-i18next";

//meta title
document.title = "Prompt store | Jask";

const PromptStore = ({ t }) => {
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {/* <Breadcrumbs
                        title={props.t("Mes documents")}
                        breadcrumbItems={[
                            {
                                title: "Jask"
                            },
                            {
                                title: "Espace de travail"
                            },
                            {
                                title: "Mes documents"
                            }
                        ]}
                    /> */}

                    <PromptList />
                </Container>
            </div>
        </React.Fragment >
    );
};


export default withTranslation()(PromptStore);
