import React, { useEffect, useState } from "react";
import {
    Card,
    CardBody,
    Container
} from "reactstrap";

import {
    delStudioApp,
    getStudioApps,
    createOmniChatConversation,
    showRightSidebarAction

} from "store/actions";

import { useSelector, useDispatch } from "react-redux";
import { withTranslation } from "react-i18next";
import { isEmpty, map } from "lodash";
import Assistant from "./app";
import DeleteModal from "components/Common/DeleteModal";
import { useNavigate } from "react-router-dom";
import SimpleBar from "simplebar-react"
import DeployAssistant from "pages/Workspace/assistant/deploy";
import { ResourceTypes } from "constants/general";

const AssistantList = props => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [showDelete, setShowDelete] = useState(false);
    const [showEdit, setShowEdit] = useState(false);
    const [selectedApp, setSelectedApp] = useState(null);

    const { assistants } = useSelector(state => ({
        assistants: state.Studio.assistants,
    }));

    const handleDeleteRequest = (item) => {
        setSelectedApp(item);
        setShowDelete(!showDelete)
    }

    const handleDelete = () => {
        setShowDelete(!showDelete);
        dispatch(delStudioApp(selectedApp?.id));
    }

    const startAssistant = (app) => {
        const conversationInfo = {
            id: app.id,
            name: app.name,
            type: app.type,
            appId: app.id,
            agentLogo: app.logoUrl,
            agentName: app.name,
            description: app.description,
            appOwner: app.principalId
        }

        dispatch(createOmniChatConversation(conversationInfo));
        dispatch(showRightSidebarAction({
            type: ResourceTypes.ASSISTANTS,
            conversation: conversationInfo,
        }));
    }

    const onEditApp = (app) => {
        setSelectedApp(app);
        setShowEdit(!showEdit);
    }

    const onAppClick = (app) => {

        //Assistant 
        if (app.app) {
            startAssistant(app);
        }
    }

    const onCreateApp = () => {
        setShowEdit(!showEdit);
    }

    useEffect(() => {
        isEmpty(assistants)
        dispatch(getStudioApps())
    }, [dispatch])

    return (<div className="assistant-list">

        <DeleteModal
            show={showDelete}
            onCloseClick={() => setShowDelete(!showDelete)}
            onDeleteClick={handleDelete}
            text={"Voulez-vous vraiment supprimer cette application ?"}

        />
        {showEdit && <DeployAssistant
            show={showEdit}
            onCloseClick={() => setShowEdit(false)}
            app={selectedApp}
        />}

        <SimpleBar className="scroller">
            <div className="d-flex flex-wrap app-list-mini">
                {
                    map(assistants, item => {
                        return <Assistant
                            app={item}
                            onDelete={handleDeleteRequest}
                            onOpenApp={onAppClick}
                            onEditApp={onEditApp}
                        />
                    })
                }
                <Card className="border border-info rounded-3 m-2 app border-opacity-25" onClick={onCreateApp}>
                    <CardBody className="">
                        <div className="d-flex justify-content-center h-100 align-items-center">
                            <i className="mdi mdi-plus app-add-button" />
                        </div>
                    </CardBody>
                </Card>
            </div>
        </SimpleBar>
    </div>

    );
};


export default withTranslation()(AssistantList);