/* TASKS */
export const CREATE_OMNICHAT_CONVERSATION = "CREATE_OMNICHAT_CONVERSATION"
export const CREATE_OMNICHAT_CONVERSATION_SUCCESS = "CREATE_OMNICHAT_CONVERSATION_SUCCESS"
export const CREATE_OMNICHAT_CONVERSATION_FAILED = "CREATE_OMNICHAT_CONVERSATION_FAILED"

export const GET_OMNICHAT_CONVERSATION_HISTORY = "GET_OMNICHAT_CONVERSATION_HISTORY"
export const GET_OMNICHAT_CONVERSATION_HISTORY_SUCCESS = "GET_OMNICHAT_CONVERSATION_HISTORY_SUCCESS"
export const GET_OMNICHAT_CONVERSATION_HISTORY_FAILED = "GET_OMNICHAT_CONVERSATION_HISTORY_FAILED"


export const ADD_OMNICHAT_MESSAGE_HISTORY = "ADD_OMNICHAT_MESSAGE_HISTORY"