import React, { createRef, useEffect, useState } from "react";
import { TaskStatus } from "constants/tasks";
import { Col, Row } from "reactstrap";
import FileToProcessList from "./leftbar";
import StartFileTask from "../starter";
import { isEmpty } from "lodash";
import TaskContentView from "./content";

const MultipleFileTaskResult = ({ files, onStart, streams, status, instructions, taskId }) => {

    const [selectedFile, setSelectedFile] = useState(null);
    const [taskType, setTaskType] = useState(null);

    useEffect(() => {

        if (isEmpty(taskType))
            return;

        onStart(taskType);


    }, [taskType]);

    useEffect(() => {
        if (isEmpty(selectedFile?.location))
            setSelectedFile(files[0])

    }, [files]);

    return <div>

        <Row>
            <Col md={4}>
                <FileToProcessList files={files}
                    selectedFile={selectedFile}
                    onFileSelectedChanged={setSelectedFile}
                    status={status}
                />
            </Col>

            <Col md={8}>
                {status === TaskStatus.NOT_STARTED && <StartFileTask onSelectedLaunchTask={setTaskType} />}
                {status !== TaskStatus.NOT_STARTED && <TaskContentView
                    file={selectedFile}
                    instructions={instructions}
                    streams={streams[taskId]}
                    status={status}
                    taskId={taskId}
                />}
            </Col>
        </Row>
    </div>
}

export default MultipleFileTaskResult;