export const convertToUTCDate = (date) => {
  let newDateString = date;
  if (!date.includes("Z")) newDateString = `${date}Z`;
  const convertedDate = new Date(newDateString);
  return convertedDate;
};

export const toLocalDate = (date) => {
  if (!date) return "";
  const validPart = date.split(".")[0]
  const convertedDate = convertToUTCDate(validPart);
  if (!convertedDate) return "";
  return convertedDate.toLocaleString();
};
export const StringToDate = (date) => {
  if (!date) return "";
  const validPart = date.split(".")[0]
  const convertedDate = convertToUTCDate(validPart);
  if (!convertedDate) return "";
  return convertedDate;
};


export const toDate = (date) => {
  if (!date) return "";
  const validPart = date.split(".")[0]
  const convertedDate = convertToUTCDate(validPart);
  if (!convertedDate) return "";
  return convertedDate.toLocaleDateString();
};

export const getTime = (date) => {
  if (!date) return;
  return convertToUTCDate(date).toLocaleTimeString('fr-FR')
};
