import React, { useEffect, useState } from "react";
import { Card, CardBody, CardTitle, Col } from "reactstrap";
import SimpleBar from "simplebar-react"

import {
    getDataServices,
} from "store/actions";
import { useSelector, useDispatch } from "react-redux";
import { isEmpty, map } from "lodash";
import { Link } from "react-router-dom";

const ResourcesLeftBar = ({ selectedSource, onSelectionChange }) => {

    const dispatch = useDispatch();
    const { isWorking, dataServices } = useSelector(state => ({
        isWorking: state.Assistant.isWorking,
        dataServices: state.Assistant.dataServices,
        usefulResources: state.Assistant.usefulResources,
    }));

    const getServiceInfo = (service) => {

        switch (service.providerId) {

            case "crm":
                return { name: 'CRM', icon: 'bx bx-receipt text-danger' }

            case "internet":
                return { name: 'Internet', icon: 'bx bx-cloud text-info' }

            case "workspace":
                return { name: 'Espace de Travail', icon: 'bx bx-task text-info' }


            case "filesystem":
                return { name: 'Documents', icon: 'bx bxs-folder text-warning' }

            default:
                return { name: service.name, icon: 'bx bx-data text-info' }

        }
    }

    useEffect(() => {
        dispatch(getDataServices())
    }, [])

    useEffect(() => {
        if (dataServices?.length > 0 && isEmpty(selectedSource)) {
            onSelectionChange(dataServices[0]);
        }
    }, [dataServices])

    return <React.Fragment>

        <div className="todo-resources-leftsidebar">
            <div className="todo-resources-leftsidebar-nav">
                <ul className="list-unstyled resources-list ">
                    <SimpleBar>
                        {map(dataServices, item => {
                            const data = getServiceInfo(item);
                            const current = selectedSource?.id === item.id;
                            return <li className={current ? "active" : ""} onClick={() => onSelectionChange(item)}>
                                <Link> <div className="d-flex justify-content-between">
                                    <div className="flex-grow-1 overflow-hidden">
                                        <h5 className="text-truncate font-size-14 mb-1">
                                            <div className="hstack gap-1">
                                                <i className={data.icon + " me-1 font-size-20"} />
                                                {data.name}
                                            </div>
                                        </h5>

                                    </div>
                                    <div className="font-size-11">
                                    </div>
                                </div>
                                </Link>
                            </li>
                        })}
                    </SimpleBar>
                </ul>
            </div></div>

    </React.Fragment>
}

export default ResourcesLeftBar;