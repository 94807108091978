import { map } from "lodash"
import React, { useState } from "react"
import { Link } from "react-router-dom"
import {
    Card,
    CardBody,
    Collapse,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    UncontrolledAlert,
    UncontrolledDropdown,
} from "reactstrap"
import { uuidv4 } from "utils"

const rootFolders = [
    {
        id: "personal",
        title: "Personnel",
        icon: "mdi mdi-folder font-size-16 text-danger me-2",
        isOpen: false,
        children: [
            {
                id: 'documents',
                title: 'Mes Documents',
                icon: "mdi mdi-folder  font-size-16  me-1",

            },

            // {
            //     id: 'assistant',
            //     title: 'Conversations',
            //     icon: "bx bx-bot font-size-16 me-1",
            // },
            {
                id: 'template',
                title: 'Mes Templates',
                icon: "mdi mdi-file font-size-16  me-1",

            }, {
                id: 'recent',
                title: 'Fichies récents',
                icon: "mdi mdi-folder font-size-16  me-1",

            }
        ]
    },
    {
        id: "organization",
        title: "Organisation",
        icon: "mdi mdi-folder font-size-16 text-info me-2",
        isOpen: false
    }
]

const FilesLeftBar = ({ onAddTemplate, onActionClicked }) => {
    const [folders, setFolders] = useState(rootFolders);

    const toggle = (item) => {
        item.isOpen = !item.isOpen;
        setFolders([...folders]);
    }

    return (
        <React.Fragment>
            <Card className="filemanager-sidebar me-md-2 ">
                <CardBody>
                    <div className="d-flex flex-column h-100">
                        <div className="">
                            <div className="mb-3">
                                <UncontrolledDropdown>
                                    <DropdownToggle className="btn btn-light w-100" type="button">
                                        <i className="mdi mdi-plus me-1"></i> Nouveau
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem href="#"><i className="bx bx-folder me-1"></i> Dossier</DropdownItem>
                                        <DropdownItem href="#"><i className="bx bx-file me-1"></i> Document</DropdownItem>
                                        <DropdownItem href="#" onClick={onAddTemplate} ><i className="bx bx-file me-1"></i> Template</DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                            </div>
                            <ul className="list-unstyled categories-list">

                                {
                                    map(folders, item => {

                                        return <li key={uuidv4()}>
                                            <div className="custom-accordion">
                                                <Link
                                                    className="text-body fw-medium py-1 d-flex align-items-center"
                                                    onClick={() => toggle(item)}
                                                    to="#"
                                                >
                                                    <i className={item.icon}></i>{" "}
                                                    {item.title}{" "}

                                                    <i
                                                        className={
                                                            item.isOpen
                                                                ? "mdi mdi-chevron-up accor-down-icon ms-auto"
                                                                : "mdi mdi-chevron-down accor-down-icon ms-auto"
                                                        }
                                                    />
                                                </Link>
                                                <Collapse isOpen={item.isOpen}>
                                                    <div className="card border-0 shadow-none ps-2 mb-0">
                                                        <ul className="list-unstyled mb-0">

                                                            {
                                                                map(item.children || [], child => {
                                                                    return <li key={uuidv4()}>
                                                                        <Link to="#" onClick={() => onActionClicked({ ...child, type: child.id })} className="d-flex align-items-center">
                                                                            <i className={child.icon}></i>{" "}
                                                                            <span className="me-auto">{child.title}</span>
                                                                        </Link>
                                                                    </li>
                                                                })
                                                            }

                                                        </ul>
                                                    </div>
                                                </Collapse>
                                            </div>
                                        </li>
                                    })
                                }


                            </ul>
                        </div>
                    </div>
                </CardBody>
            </Card>
        </React.Fragment>)
}

export default FilesLeftBar
