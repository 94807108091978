import { isEmpty } from 'lodash';
import React, { Fragment, memo, ReactNode, useCallback, useEffect, useState } from 'react';
import Select from "react-select";

const tables = [
    {
        label: "Mes taches",
        value: "task"
    }
]


const WorkspaceSearchNode = ({ data, onChange, onValidityChanged }) => {

    const [selectedTable, setSelectedTable] = useState(null);
    const onNewValue = (e) => {
        onChange({
            ...data,
            providerConfig: {
                object: e.value,
            },
            viewState: !isEmpty(e.value)
        });
        setSelectedTable(e);
    }


    useEffect(() => {
        if (data?.workspaceObject) {
            const option = tables.filter(t => t.value === data.workspaceObject)[0];
            setSelectedTable(option);
        }
    }, [data])

    return <div className='nodrag' >

        <div className='mb-2 ' >
            <Select className='select2-selection'
                value={selectedTable}
                options={tables}
                onChange={onNewValue}
            >
            </Select>
        </div>

    </div>
}


export default WorkspaceSearchNode;