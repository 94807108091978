import React from "react"
import { Link } from "react-router-dom"
import {
  Table,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  DropdownItem,
  UncontrolledTooltip
} from "reactstrap"
import SimpleBar from "simplebar-react";


const RecentFile = ({ files, onDeleteFile }) => {

  return (
    <React.Fragment>
      <div className="mt-4 border p-4 selected-files">
        <div className="d-flex flex-wrap">
          <h5 className="font-size-16 me-3">Fichiers selectionnés</h5>
        </div>
        <hr className="mt-2" />

        <div className="table-responsive">
          <SimpleBar className="scroller">
            <Table className="table align-middle table-nowrap table-hover mb-0">
              <thead>
                <tr>
                  <th scope="col">Nom</th>
                  <th scope="col"> Modifié le</th>
                  <th scope="col" colSpan="2">

                  </th>
                </tr>
              </thead>

              <tbody>
                {files.map((item, key) => (
                  <tr key={key}>
                    <td><Link to="#" className="text-dark fw-medium"><i className={item.icon}></i> {item.name}</Link></td>
                    <td>{item.lastModifiedDate.toLocaleDateString("fr-FR")}</td>
                    <td>{item.formattedSize}</td>
                    <td>
                      <ul className="list-unstyled hstack gap-1 mb-0">
                        <li>
                          <Link
                            to="#!"
                            className="btn btn-sm btn-soft-danger"
                            onClick={() => {
                              onDeleteFile(item);
                            }}
                          >
                            <i className="mdi mdi-delete-outline" id="deletetooltip" />
                            <UncontrolledTooltip placement="top" target="deletetooltip">
                              Supprimer
                            </UncontrolledTooltip>
                          </Link>
                        </li>
                      </ul>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </SimpleBar>
        </div>
      </div>
    </React.Fragment>
  )
}

export default RecentFile;
