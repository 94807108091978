import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { ADD_PROFILE_TODO_SETTINGS_CATEGORY, ADD_TODO_WORK, DEL_TODO_CATEGORY, DEL_TODO_WORK, GET_TODO_WORK, GET_WORKSPACE_PREFERENCES, ON_TODO_WORK_UPDATED, SUBMIT_TODO_WORK } from "./actionTypes"
import { getTodoWorksSuccess, getTodoWorksFail, addTodoWorksSuccess, addTodoWorksFail, deteleTodoWorksFail, deleteTodoWorksSuccess, addTodoWorkStart, submitTodoWorkSuccess, submitTodoWorkFail, addTodoCatgorySuccess as addTodoCategorySuccess, addTodoCategoryFailed, getWorkspaceSettingsSuccess, getWorkspaceSettingsFailed, updateTodoWorks, updateTodoWorksSucess, updateTodoWorkFailed, delProfileTodoCategory, delProfileTodoCategorySuccess, delProfileTodoCategoryFailed } from "./actions"

//Include Both Helper File with needed methods
import { getTodoWorks, addTodoWorks as addTodoWorksApi, deleteTodoWorks as deleteTodoWorksApi, addUserProfileTodoBinders, getWorkspaceSettings, delUserProfileTodoBinders } from "helpers/backend_helper"

function* fetchTodoWorks() {
  try {
    const response = yield call(getTodoWorks)
    yield put(getTodoWorksSuccess(response))
  } catch (error) {
    yield put(getTodoWorksFail(error))
  }
}


function* onAddNewTodoList({ payload: data }) {
  try {
    yield put(addTodoWorkStart())
    const response = yield call(addTodoWorksApi, data)
    yield put(addTodoWorksSuccess(response))
  } catch (error) {
    yield put(addTodoWorksFail(error))
  }
}

function* onUpdateTodoList({ payload: data }) {
  try {
    const response = yield call(addTodoWorksApi, data)
    yield put(updateTodoWorksSucess(response))
  } catch (error) {
    yield put(updateTodoWorkFailed(error))
  }
}


function* onDeleteTodoList({ payload: data }) {

  try {
    const response = yield call(deleteTodoWorksApi, data)
    yield put(deleteTodoWorksSuccess(response))
  } catch (error) {
    yield put(deteleTodoWorksFail(error))
  }
}


function* onSubmitTodoJob({ payload: data }) {

  try {
    const response = yield call(submitTodoJob, data)
    yield put(submitTodoWorkSuccess(response))
  } catch (error) {
    yield put(submitTodoWorkFail(error))
  }
}

function* addTodoCategory({ payload }) {
  try {
    const response = yield call(addUserProfileTodoBinders, payload)
    yield put(addTodoCategorySuccess(response))
  } catch (error) {
    yield put(addTodoCategoryFailed(error))
  }
}

function* delTodoCategory({ payload }) {
  try {
    const response = yield call(delUserProfileTodoBinders, payload)
    yield put(delProfileTodoCategorySuccess(response))
  } catch (error) {
    yield put(delProfileTodoCategoryFailed(error))
  }
}


function* onGetWorkspaceSettings({ payload }) {
  try {
    const response = yield call(getWorkspaceSettings, payload)
    yield put(getWorkspaceSettingsSuccess(response))
  } catch (error) {
    yield put(getWorkspaceSettingsFailed(error))
  }
}


function* todoSaga() {

  yield takeEvery(GET_TODO_WORK, fetchTodoWorks)
  yield takeEvery(ADD_TODO_WORK, onAddNewTodoList)
  yield takeEvery(ON_TODO_WORK_UPDATED, onUpdateTodoList)
  yield takeEvery(DEL_TODO_WORK, onDeleteTodoList)
  yield takeEvery(SUBMIT_TODO_WORK, onSubmitTodoJob)
  yield takeEvery(ADD_PROFILE_TODO_SETTINGS_CATEGORY, addTodoCategory)
  yield takeEvery(GET_WORKSPACE_PREFERENCES, onGetWorkspaceSettings)
  yield takeEvery(DEL_TODO_CATEGORY, delTodoCategory)

}

export default todoSaga;