import React, { useState, useEffect } from "react"
import { DropdownToggle, DropdownMenu, Dropdown, Spinner } from "reactstrap"

import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import reHype from 'remark-rehype'
import { isEmpty } from "lodash";
import { Link } from "react-router-dom"
import { runPromptTask } from "service/tasks"
import { useSelector } from "react-redux";
import ShareContent from "components/Common/ShareContent"

const GenerationTextArea = ({ title, text, onContentChange, isGenerating }) => {

    const [isEdit, setIsEdit] = useState(false);
    const [content, setContent] = useState("");
    const [originalContent, setOriginalContent] = useState("");
    const [rowCount, setRowCount] = useState(15);
    const [showInstructions, setShowInstructions] = useState(false);
    const [instructions, setInstructions] = useState(null);
    const [isLoading, setIsLoading] = useState(null);
    const { accessToken } = useSelector(state => ({
        accessToken: state.Login.accessToken,
    }));

    const onNewInstruction = (e) => {
        const { key, v } = e;

        if ((!isEmpty(key) && key === "Enter")) {
            setShowInstructions(!showInstructions);
            runPromptTask(content, instructions, () => setIsLoading(true), onTaskStream, () => setIsLoading(false), accessToken)
        }
    }

    const toggleLoading = () => {
        setIsLoading(!isLoading ? true : false);
    }

    const onTaskStream = (data) => {
        setContent(prev => prev + data);
    }

    const copyMsg = (ele) => {
        navigator.clipboard.writeText(content);
    };

    useEffect(() => {

        if (isLoading) {
            setContent("");
        }

    }, [isLoading])

    useEffect(() => {
        setContent(text);
        setOriginalContent(text);
    }, [text]);

    useEffect(() => {
        if (isEdit && !isLoading && onContentChange) {
            onContentChange(content);
        }
    }, [content]);


    return <div className="">
        <div className="d-flex justify-content-between mb-3 border-bottom pb-2">
            <div>
                <h6>{title}</h6>
            </div>
            <div>
                <div className="hstack gap-1">
                    {content && !isGenerating && <Link className="me-2" onClick={(e) => copyMsg(e.target)}><i className="mdi mdi-content-copy" /> </Link>}
                    {content && !isGenerating && <ShareContent content={{ name: title || "noname", content: content }} />}
                    {!isGenerating && !isEdit && <Link onClick={() => setIsEdit(!isEdit)}><i className="mdi mdi-file-edit" /> </Link>}
                    {isEdit && <Link onClick={() => setIsEdit(!isEdit)}> Terminer</Link>}

                </div>
            </div>
        </div>
        {!isEdit &&
            <div className="">
                <ReactMarkdown className="markdown" remarkPlugins={[remarkGfm, reHype]}>{content}</ReactMarkdown>
            </div>
        }
        {isEdit &&
            <div className="generationPanel p-1">
                <div className="">
                    <textarea
                        value={content}
                        onChange={e => setContent(e.target.value)}
                        className="form-control "
                        rows={rowCount}
                        readOnly={isLoading}
                    />

                </div>
                <div className="mt-3">
                    <div className="hstack gap-2">
                        <Dropdown
                            isOpen={showInstructions}
                            toggle={() => setShowInstructions(!showInstructions)}
                        >
                            <DropdownToggle className="btn btn-info" onClick={() => setShowInstructions(!showInstructions)} disabled={isLoading} >
                                <i className="mdi mdi-refresh" /> Nouvelle tache
                            </DropdownToggle>

                            <DropdownMenu data-popper-placement="left-start">

                                <div className="p-2" style={{ width: 300, overflow: "visible" }}>
                                    <div className="d-flex justify-content-between border-bottom mb-2 p-1">
                                        <div>
                                            <h6 className="font-size-14 text-muted">
                                                <i className="mdi mdi-chat me-1" />
                                                Dites moi tout
                                            </h6>
                                            <small className="ms-2 text-muted">Par exemple, résumes ce contenu, etc...</small>
                                        </div>

                                        <button className="btn-close" onClick={() => setShowInstructions(!showInstructions)}></button>
                                    </div>
                                    <input type="text"
                                        className="form-control"
                                        onKeyPress={onNewInstruction}
                                        value={instructions}
                                        onChange={e => setInstructions(e.target.value)}
                                    />
                                </div>
                            </DropdownMenu>
                        </Dropdown>

                        {isLoading && <div className="vr" />}
                        {isLoading && <Spinner />}
                    </div>
                </div>
            </div>
        }
    </div>
}

export default GenerationTextArea;