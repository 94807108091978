import { ABORT_ASSISTANT_REQUEST, ASK_ASSISTANT, ON_ASSISTANT_REPLY, ON_CHANNEL_HISTORY } from "./actionTypes"

export const onAskAssistant = (request) => ({
  type: ASK_ASSISTANT,
  payload: request
})

export const onAssistantReply = (response) => ({
  type: ON_ASSISTANT_REPLY,
  payload: response
})


export const onChannelHistory = (channelData) => ({
  type: ON_CHANNEL_HISTORY,
  payload: channelData
})


export const onAbortAssistantRequest = (request) => ({
  type: ABORT_ASSISTANT_REQUEST,
  payload: request
})
