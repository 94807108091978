import React, { Fragment, useEffect, useState, useRef, createRef } from "react"
import { runBoardAsStream } from "service/studio";
import SimpleBar from "simplebar-react";
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import reHype from 'remark-rehype'
import { map } from "lodash";
import { Link } from "react-router-dom";
import { Badge } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";

const AppExecution = ({ boardOrApp, context, selectedStep, onStepStart, onStepEnd, onFinish, isRunning }) => {

    const [currentStepId, setCurrentStepId] = useState(null);
    const [steps, setSteps] = useState({});
    const contentPanelRef = useRef();
    const { accessToken } = useSelector(state => ({
        accessToken: state.Login.accessToken,
    }));

    const onNewStream = (evt) => {

        const id = evt.id;
        const type = evt.type;

        switch (type) {

            case "stepStart":
                onStepStart(id);
                setSteps(prev => {
                    return {
                        ...prev, [id]: {
                            id: id,
                            text: "",
                            context: null
                        }
                    }
                })
                break;

            case "streaming":

                setSteps(prev => {

                    const items = { ...prev }
                    const oldContent = items[id];
                    const merge = { ...items, [id]: { ...oldContent, text: oldContent.text + evt.value } }
                    return merge

                });

                scrollToBottom();

                break;
            case "context":
                setSteps(prev => {

                    const items = { ...prev }
                    const oldContent = items[id];
                    const merge = { ...items, [id]: { ...oldContent, context: evt.value.references } }
                    return merge

                });
                break;

            case "stepEnd":
                onStepEnd(id)
                break;
        }
    }

    const onStart = () => {
    }
    const playBoard = () => {
        const payload = {
            board: boardOrApp,
            context: context
        }
        runBoardAsStream(payload, onNewStream, onStart, onFinish, accessToken)
    }

    const scrollToBottom = () => {
        if (contentPanelRef?.current) {
            const scrollEl = contentPanelRef.current;
            scrollEl?.scroll({
                top: scrollEl?.scrollHeight + 1000,
                // behavior: 'smooth',
            });
        }
    }

    useEffect(() => {
        if (isRunning)
            playBoard();
    }, [isRunning])

    useEffect(() => {
        if (selectedStep)
            setCurrentStepId(selectedStep?.id);
    }, [selectedStep])

    return <div className="board-execution-plan border p-4 border border-secondary rounded-2 border-opacity-50" >

        <SimpleBar className="scroller" scrollableNodeProps={{ ref: contentPanelRef }} >
            <div className="pb-4" ref={contentPanelRef}>

                {currentStepId && <ReactMarkdown className="markdown" remarkPlugins={[remarkGfm, reHype]}>{steps[currentStepId]?.text}</ReactMarkdown>
                }

                {
                    steps[currentStepId]?.context && (
                        <div className="mt-2 mb-2 ms-2 me-2 pt-4">
                            <h6>Références:</h6>
                            <ul className="list-unstyled ">
                                {
                                    map(steps[currentStepId]?.context, ref => {
                                        return <li><Link>
                                            <div className="vstack gap-1">
                                                <span>{ref.name}</span>
                                                <div className="hstack gap-2">
                                                    <Badge className="badge badge-soft-info"> {ref.source}</Badge>
                                                    <Badge className="badge badge-soft-info"> {ref.owner}</Badge>

                                                    {
                                                        map(Object.values(ref.tags), tag => {
                                                            return <Badge className="badge badge-soft-danger"> {tag}</Badge>
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        </Link>
                                        </li>
                                    })
                                }
                            </ul>
                        </div>
                    )
                }

            </div>

        </SimpleBar >
    </div >
}

export default AppExecution;