import React, { useEffect, useMemo, useState } from "react";
import TableContainer from 'components/Common/TableContainer';
import { withTranslation } from "react-i18next";

import {
    getSupportRequests, getSupportPlatforms
} from "store/actions";
import { useSelector, useDispatch } from "react-redux";

import {
    Default, SupportPlatform, SelectAction, SelectAllAction, Description, AssitantHandler, Status,
    SourceInfo, LocalDate
}

    from "./columns";
import { Card, UncontrolledDropdown } from "reactstrap";
import { isEmpty } from "lodash";
import RequestsListPlaceHolders from "./placeholders";
import { Link } from "react-router-dom";
import AutosuggestSupport from "../assistant/autosuggest";

const RequestsList = ({ t }) => {

    const [requests, setRequests] = useState([]);
    const [isSelectedAll, setIsSelectAll] = useState(false);
    const [totalSelected, setTotalSelected] = useState(0);
    const [showAutoSuggest, setShowAutoSuggest] = useState(false);
    const [selectedRequests, setSelectedRequests] = useState([]);

    const dispatch = useDispatch();

    const { supportRequests, isLoading, assistants, platforms } = useSelector(state => ({
        supportRequests: state.Support.requests || [],
        isLoading: state.Support.isLoading,
        assistants: state.Studio.assistants,
        platforms: state.Support.platforms,


    }));

    const selectAll = () => {
        setIsSelectAll(!isSelectedAll)
    }

    const openSource = (request) => {
        window.open(request.webUrl, "_blank");
    }

    const onRowSelected = (row) => {
        const items = [...requests];

        items.forEach(i => {
            if (i.id === row.id) {
                i.isSelected = !i.isSelected;

                if (!i.isSelected && isSelectedAll)
                    setIsSelectAll(false);
            }
        });

        setRequests(items);
    }

    const onProcess = (item) => {
        setSelectedRequests([item]);
        setShowAutoSuggest(!showAutoSuggest)
    }

    const columns = useMemo(
        () => [
            {
                Header: (cellProps) => {
                    return <SelectAllAction
                        onClick={selectAll}
                        isAllSelected={isSelectedAll}
                    />;
                },
                accessor: 'id',
                disableFilters: true,
                disableSortBy: true,
                Cell: (cellProps) => {
                    const data = cellProps.row.original;
                    return <SelectAction data={data} onClick={onRowSelected} />;
                }
            },

            // {
            //     Header: 'Titre',
            //     accessor: 'summary',
            //     disableFilters: true,
            //     Cell: (cellProps) => {
            //         return <Default {...cellProps} />;
            //     }
            // },
            {
                Header: 'Demandes de support',
                accessor: 'description',
                disableFilters: true,
                Cell: (cellProps) => {
                    return <Description {...cellProps} onOpen={onProcess} />;
                }
            },

            // {
            //     Header: 'Status',
            //     accessor: 'status',
            //     disableFilters: true,
            //     Cell: (cellProps) => {
            //         return <Status {...cellProps} />;
            //     }
            // },
            {
                Header: 'Crée par',
                accessor: 'createdByName',
                disableFilters: true,
                Cell: (cellProps) => {
                    return <Default {...cellProps} />;
                }
            },
            {
                Header: 'Crée le',
                accessor: 'createdAt',
                disableFilters: true,
                Cell: (cellProps) => {
                    return <LocalDate {...cellProps} />;
                }
            },
            {
                Header: 'Prise en charge',
                accessor: 'assistantId',
                disableFilters: true,
                Cell: (cellProps) => {
                    return <AssitantHandler {...cellProps} assistants={assistants} />;
                }
            },
            // {
            //     Header: 'Equipe',
            //     accessor: 'team.name',
            //     disableFilters: true,
            //     Cell: (cellProps) => {
            //         return <Default {...cellProps} />;
            //     }
            // },

            // {
            //     Header: 'Plateforme',
            //     accessor: 'providerType',
            //     disableFilters: true,
            //     Cell: (cellProps) => {
            //         return <SupportPlatform {...cellProps} />;
            //     }
            // },
            {
                Header: (cell) => {
                    return <div>
                        <i className="mdi mdi-database" /> Source
                    </div>
                },
                accessor: 'providerName',
                disableFilters: true,
                Cell: (cellProps) => {
                    return <SourceInfo {...cellProps} onOpen={openSource} onProcess={onProcess} />;
                }
            },
            // {
            //     Header: '',
            //     accessor: 'provider',
            //     disableFilters: true,
            //     Cell: (cellProps) => {
            //         const data = cellProps.row.original;
            //         return <div className="hstack">
            //         </div>

            //     }
            // },

        ],
        [isSelectedAll, requests, assistants]
    );

    const onRefresh = (type) => {
        dispatch(getSupportRequests({
            force: type
        }))
    }

    const onSuggestSupportResponse = () => {
        setShowAutoSuggest(!showAutoSuggest)
    }

    useEffect(() => {

        if (isEmpty(supportRequests))
            return;

        if (supportRequests?.length === 0) {
            onRefresh(false);
        } else {
            setRequests([...supportRequests]);
        }

        if (isEmpty(platforms)) {
            dispatch(getSupportPlatforms())
        }

    }, [dispatch])

    useEffect(() => {
        if (!isEmpty(supportRequests))
            setRequests([...supportRequests]);
    }, [supportRequests])

    useEffect(() => {
        const items = requests.filter(f => f.isSelected)
        setTotalSelected(items.length);
        setSelectedRequests(items);
    }, [requests])

    useEffect(() => {
        const items = [...supportRequests];

        items.forEach(i => {
            i.isSelected = isSelectedAll;
        });

        setRequests(items);
    }, [isSelectedAll])


    return <React.Fragment>
        <AutosuggestSupport
            show={showAutoSuggest}
            onCloseClick={() => setShowAutoSuggest(!showAutoSuggest)}
            requests={selectedRequests}
        />

        <Card className="addons-support p-2">
            {!isLoading && <div>
                <TableContainer
                    columns={columns}
                    data={requests}
                    isGlobalFilter={true}
                    isAddOptions={false}
                    // handleAddClicks={handleDatasourceClicks}
                    addButtonText="Plateforme"
                    isDatasourceListGlobalFilter={false}
                    isGenericAdd={false}
                    customPageSize={20}
                    onRefresh={onRefresh}
                    isSupportTable={true}
                    totalSelected={totalSelected}
                    onSuggestSupportResponse={onSuggestSupportResponse}
                />
            </div>}

            {isLoading && <RequestsListPlaceHolders />}
        </Card>
    </React.Fragment>
}

export default withTranslation()(RequestsList);