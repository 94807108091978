import { isEmpty, map } from "lodash"
import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import {
    Table,
    DropdownMenu,
    DropdownToggle,
    UncontrolledDropdown,
    DropdownItem,
    Card,
    CardBody,
    Row,
    Col,
} from "reactstrap"
import { uploadFile } from "service/documents"
import { getFileIcon, uuidv4 } from "utils"

import {
    processTemplate,
} from "store/actions";
import { useSelector, useDispatch } from "react-redux";
import TaskLoader from "components/Common/Loader"
import TemplateStructure from "../structure"

const ConfigureTemplate = ({ name, files, onConfigurationChanged, selectionState }) => {

    const [selectedFile, setSelectedFile] = useState(null);
    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const [alreadyProcessed, setAlreadyProcessed] = useState(false);
    const [configuredItems, setConfiguredItems] = useState({});
    const [selectedFileState, setSelectedFileState] = useState(false);

    const { isWorking, processedTemplates } = useSelector(state => ({
        isWorking: state.Documents.isProcessingTemplate,
        processedTemplates: state.Documents.processedTemplates

    }));

    const dispatch = useDispatch();
    const onItemChange = (location, item) => {

        if (isEmpty(location) || isEmpty(item?.id))
            return;
        const items = { ...configuredItems }
        const newItemsState = location in items ? items[location] : {};
        newItemsState[item.id] = item
        const newState = { ...items, [location]: { ...newItemsState } }
        setConfiguredItems(prev => {
            return { ...prev, ...newState }
        })
    }

    useEffect(() => {
        onConfigurationChanged(configuredItems);
    }, [configuredItems])


    useEffect(() => {
        if (files) {
            const target_dir = "workspace/templates/" + name
            files.forEach(file => {
                uploadFile(file, target_dir).then(resp => {
                    file.location = resp.attachments[0];
                })
            });
        }
    }, [files])


    useEffect(() => {

        if (!isEmpty(selectedFile?.location) && !alreadyProcessed) {
            dispatch(processTemplate({
                name: name,
                documents: files.filter(file => !isEmpty(file.location)).map(f => f.location)
            }))
        }


    }, [selectedFile])

    useEffect(() => {

        if (!isEmpty(processedTemplates)) {
            const items = [...processedTemplates];
            const initialState = {}

            items.forEach(file => {
                initialState[file.location] = {}
                file.items.forEach(i => {
                    initialState[file.location][i.id] = {
                        id: i.id,
                        selected: false,
                        instructions: null,
                        item: i
                    }
                })
            })

            setConfiguredItems(initialState)
            setAlreadyProcessed(true);

        }
    }, [processedTemplates])


    useEffect(() => {
        if (selectionState?.files && selectedFile?.location) {
            setSelectedFileState(selectionState.files[selectedFile.location])
        }
    }, [selectionState])

    useEffect(() => {
        if (selectedFile?.location && alreadyProcessed) {
            setSelectedTemplate(Object.values(configuredItems[selectedFile.location]))
        }
    }, [alreadyProcessed, selectedFile])


    return (
        <React.Fragment>
            <div className="template-config">
                <Row>
                    <Col md={4}>
                        <div className="files">
                            <ul className="list-unstyled ">
                                {map(files || [], file => {

                                    return <li key={file.name} className={selectedFile?.name === file.name ? "active" : ""}>
                                        <Link onClick={e => setSelectedFile(file)}>
                                            <div className="d-flex">
                                                <div className="flex-grow-1 overflow-hidden">
                                                    <div className="hstack gap-2">
                                                        {!selectionState?.files[file.location] && <i className="mdi mdi-alert text-danger font-size-16" />}
                                                        {selectionState?.files[file.location] && <i className="mdi mdi-check text-success font-size-16" />}
                                                        {getFileIcon(file.name)}
                                                        {file.name}
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>
                                    </li>

                                })}
                            </ul>
                        </div>
                    </Col>

                    <Col md={8}>
                        <Card className=" template-structure border">
                            <CardBody>
                                {isWorking && <TaskLoader height={"40vh"} color={"white"} />}
                                {!isWorking && alreadyProcessed && <TemplateStructure location={selectedFile?.location} structure={selectedTemplate} onItemChange={onItemChange} />}
                            </CardBody>
                        </Card>

                    </Col>
                </Row>
            </div>

        </React.Fragment >
    )
}

export default ConfigureTemplate;
