import React, { createRef, useEffect, useState } from "react";
import { TaskStatus } from "constants/tasks";
import { isEmpty } from "lodash";
import { Card } from "reactstrap";
import StreamView from "../streams";
import SimpleBar
    from "simplebar-react";
const TaskContentView = ({ file, instructions, streams, status, taskId }) => {
    return <div className="tasks-files-content-view">
        <SimpleBar className="scroller">
            <StreamView
                file={file}
                type={instructions?.type}
                streams={streams}
                taskId={taskId}
                taskName={"Résulat du Traitement"}
            />
        </SimpleBar>

    </div>
}

export default TaskContentView;