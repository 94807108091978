import React, { memo, ReactNode, useCallback, useEffect, useState } from 'react';
import { Handle, Position } from '@xyflow/react';
import { Link } from "react-router-dom";
import { Badge, Card, CardBody, CardFooter, CardTitle, Collapse, DropdownItem, DropdownMenu, DropdownToggle, Spinner, UncontrolledDropdown } from 'reactstrap';
import classnames from "classnames";
import { isEmpty, map } from 'lodash';
import { runPromptOptimization } from 'service/tasks';
import { useSelector } from "react-redux";
import SingleHandle from '../../handle/SingleHandle';

const inputTypes = [
    {
        id: 'all',
        title: 'Texte et fichiers ',
        description: 'Concatenation du texte et du contenu des fichiers'
    },
    {
        id: 'textOnly',
        title: 'Texte',
        description: 'Appliquer les instructions le texte fourni'
    },
    {
        id: 'fileOnly',
        title: 'Fichiers',
        description: 'Appliquer les instructions sur chaque fichier uniquement'
    },
    {
        id: 'rowOnly',
        title: 'Ligne par ligne',
        description: 'Appliquer les instructions sur chaque ligne'
    },
]

const getStatusClassName = (status) => {

    if (!status)
        return "text-danger";
    else {
        return "text-success";
    }
}

const ContextNode = ({ llms, data, onChange, type }) => {
    const [collapsed, setCollapsed] = useState(false);
    const [nodeName, setNodeName] = useState(data.name);
    const [selectedModel, setSelectedModel] = useState({
        id: data?.modelId,
        title: data?.modelName
    });
    const [instructions, setInstructions] = useState(data?.instructions);
    const [nodeStatus, setNodeStatus] = useState(false);
    const [isLoading, setIsLoading] = useState(data?.isLoading);
    const { accessToken } = useSelector(state => ({
        accessToken: state.Login.accessToken,
    }));

    const onModelChange = (model) => {
        setSelectedModel(model);
    }

    const optimizePrompt = () => {
        setCollapsed(true);
        setIsLoading(true);
        runPromptOptimization(instructions, onPromptOptimizationStart, onPromptToken, onPromptOptimizationStop, accessToken);
    }

    const onPromptOptimizationStart = () => {
        setInstructions(prev => "");
    }

    const onPromptToken = (token) => {
        setInstructions(prev => `${prev}${token}`);
    }

    const onPromptOptimizationStop = (status) => {
        setIsLoading(false);
    }

    useEffect(() => {
        if (isEmpty(selectedModel?.id)) {
            setSelectedModel(llms[0]);
        }
    }, [llms, selectedModel]);


    useEffect(() => {
        onChange({
            ...data,
            name: nodeName,
            isValid: true
        })
    }, [nodeName, selectedModel, instructions, isLoading])

    useEffect(() => {
        setNodeStatus(data.isValid);
    }, [data])

    return <Card className='context-node border border-danger border-opacity-25'>
        <div className=' d-flex  justify-content-center align-items-center flex-column '>
            <span className='text-muted fw-bold'>{nodeName}</span>
        </div>
        <SingleHandle nodeId={data?.id} type="source" position={Position.Right} />

    </Card >
}

export default memo(ContextNode);