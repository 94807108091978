import React, { useState } from "react";
import {
    Button,
    Card,
    CardBody,
    Modal
} from "reactstrap";

const DocumentContext = ({ onGenerateDocument, template, isOpen, onClose }) => {

    const [context, setContext] = useState();
    const handleGeneration = () => {
        onGenerateDocument({
            context: context,
            template: template
        });
        onClose();
    }

    return <Modal isOpen={isOpen} toggle={onClose} size="lg"
        className='modal-dialog-centered '
        contentClassName=''
    >

        <div className="modal-header">
            <h5 className="modal-title" id="modalLabel">
                Générer un {template?.name}
            </h5>
            <button type="button"
                onClick={onClose} className="btn-close"></button>
        </div>

        <div className="modal-body">

            <Card>
                <CardBody>
                    <div>
                        <p> Veuillez saisir toutes les informations  contextuelles importantes à rajouter lors de la génération de ce document. Les données telles que la date du jour, ... seront automatiquement prises en compte. </p>
                    </div>

                    <textarea
                        className="form-control"
                        rows={10}
                        value={context}
                        onChange={e => setContext(e.target.value)}
                    />

                </CardBody>
            </Card>
        </div>

        <div className="modal-footer">

            <div className="hstack gap-2">
                <Button className="btn btn-success" onClick={handleGeneration}>Générer</Button>
                <Button className="btn btn-secondary" onClick={onClose}>Annuler</Button>

            </div>
        </div>
    </Modal>
}

export default DocumentContext;