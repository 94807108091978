/* DOCUMENT GENERATIONS */
export const SUBMIT_GENERATION_TASK = "SUBMIT_GENERATION_TASK"
export const SUBMIT_GENERATION_TASK_SUCCES = "SUBMIT_GENERATION_TASK_SUCCES"
export const SUBMIT_GENERATION_TASK_FAILED = "SUBMIT_GENERATION_TASK_FAILED"

/*TEMPLATE*/
export const PROCESS_DOC_TEMPLATE = "PROCESS_DOC_TEMPLATE"
export const PROCESS_DOC_TEMPLATE_SUCCESS = "PROCESS_DOC_TEMPLATE_SUCCESS"
export const PROCESS_DOC_TEMPLATE_FAILED = "PROCESS_DOC_TEMPLATE_FAILED"


export const ADD_TEMPLATE = "ADD_TEMPLATE"
export const ADD_TEMPLATE_SUCCESS = "ADD_TEMPLATE_SUCCESS"
export const ADD_TEMPLATE_FAILED = "ADD_TEMPLATE_FAILED"


export const GET_TEMPLATE = "GET_TEMPLATE"
export const GET_TEMPLATE_SUCCESS = "GET_TEMPLATE_SUCCESS"
export const GET_TEMPLATE_FAILED = "GET_TEMPLATE_FAILED"

export const DEL_TEMPLATE = "DEL_TEMPLATE"
export const DEL_TEMPLATE_SUCCESS = "DEL_TEMPLATE_SUCCESS"
export const DEL_TEMPLATE_FAILED = "DEL_TEMPLATE_FAILED"

export const DOWNLOAD_GENERATED_TEMPLATE = "DOWNLOAD_GENERATED_TEMPLATE"
export const DOWNLOAD_GENERATED_TEMPLATE_SUCCES = "DOWNLOAD_GENERATED_TEMPLATE_SUCCES"
export const DOWNLOAD_GENERATED_TEMPLATE_FAILED = "DOWNLOAD_GENERATED_TEMPLATE_FAILED"


export const GET_RECENT_FILES = "GET_RECENT_FILES"
export const GET_RECENT_FILES_SUCCESS = "GET_RECENT_FILES_SUCCESS"
export const GET_RECENT_FILES_FAILED = "GET_RECENT_FILES_FAILED"
