
import { camelCase, capitalize, isEmpty, map } from "lodash";
import React, { useEffect, useState } from "react"
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Card, CardBody, CardTitle, UncontrolledDropdown, UncontrolledTooltip, DropdownToggle, DropdownItem, DropdownMenu, Label, Row, Col } from "reactstrap";
import SimpleBar from "simplebar-react";
import { getProviderInfo } from "utils";
import KbSettings from "./settings"

const knowledgeBaseSettings = ({
    t,
    customSources,
    getItemDescription,
    addSource,
    selectedSources,
    onBrowseFileSystem,
    removeSource,
    onSourceConfigChanged,
    onDatasourceFilterClicked,
    onRemoveDatasourceFilter
}) => {

    const [selectedSource, setSelectedSource] = useState(null);

    const handleRemove = (ds) => {
        if (ds.id === selectedSource?.id) {
            console.log("settings null")
            setSelectedSource(null);
        }

        removeSource(ds);
    }

    useEffect(() => {

        if (isEmpty(selectedSource))
            return;

        // const current = selectedSources.find(s => s.id === selectedSource?.id);
        // setSelectedSource({ ...current });

    }, [selectedSources])

    return <div className="knowledgebase-config d-flex flex-row mt-4  ">
        <div className="h-100 leftbar">
            <Card className="border border-secondary rounded border-opacity-50 h-100">
                <div className=" pt-2 ps-2 pe-2 border-bottom border-secondary border-opacity-50">
                    <div className="d-flex justify-content-between">
                        <h6 className="fw-bold "><i className="mdi mdi-database me-1" />{t("Ressources")}</h6>
                        <div>
                            <div>
                                <UncontrolledDropdown>
                                    <DropdownToggle tag="a">
                                        <i className="mdi mdi-database-plus me-1" />{t("Attacher une source")}
                                    </DropdownToggle>
                                    <DropdownMenu>

                                        {map(customSources, item => {
                                            const source = { ...item, id: item.id, name: item.name, type: item.provider.type, canBrowse: item.hasFileSystem };
                                            return <DropdownItem
                                                onClick={() => addSource(source)}
                                                key={item.id}>{getItemDescription(source)}
                                            </DropdownItem>;
                                        })}

                                        <DropdownItem divider />

                                        <DropdownItem onClick={() => onBrowseFileSystem(true)}><i className="mdi mdi-folder text-warning me-1 font-size-16" />Fichiers ou dossiers</DropdownItem>

                                    </DropdownMenu>
                                </UncontrolledDropdown>

                            </div>

                        </div>
                    </div>
                </div>

                <CardBody>

                    <SimpleBar className="scroller">
                        <ul className="list-unstyled">
                            {map(selectedSources || [], (ds, index) => {
                                const isActive = ds.id === selectedSource?.id;
                                return <li key={"ds_" + index}
                                    className={"mb-2 border-bottom border-secondary border-opacity-25 " + (isActive ? "active" : "")}

                                >
                                    <Row>
                                        <Col md={10}>
                                            <div onClick={() => setSelectedSource(ds)}>
                                                {getItemDescription(ds)}

                                            </div>
                                        </Col>
                                        <Col md={2}>
                                            <div className="float-end pe-2">
                                                <div className="hstack gap-1">
                                                    {ds.canBrowse && <Link id={camelCase(ds.name) + "_filter"} onClick={() => onDatasourceFilterClicked(ds)} className="ms-3 font-size-10">
                                                        <i className="mdi mdi-filter font-size-16"></i>

                                                        <UncontrolledTooltip placement="top" target={camelCase(ds.name) + "_filter"}>
                                                            Ajouter des filtres
                                                        </UncontrolledTooltip>
                                                    </Link>}
                                                    <Link id={camelCase(ds.name) + "_del"} to={"#!"} className="text-danger" onClick={e => handleRemove(ds)}>
                                                        <i className="mdi mdi-trash-can-outline" />
                                                        <UncontrolledTooltip placement="top" target={camelCase(ds.name) + "_del"}>
                                                            retirer cette source
                                                        </UncontrolledTooltip>
                                                    </Link>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>

                                </li>
                            })}
                        </ul>


                    </SimpleBar>

                </CardBody>
            </Card>
        </div>
        <div className="h-100 rightbar ms-2 ">
            <Card className="border border-secondary rounded border-opacity-50 h-100 bg-secondary bg-opacity-10">
                <div className="  pt-2 ps-2 pe-2  border-bottom border-secondary border-opacity-50">
                    <div className="d-flex justify-content-between">
                        <h6 className="fw-bold "><i className="mdi mdi-cog me-1" />{t("Paramétrage de la source")}</h6>
                        <div>

                        </div>
                    </div>
                </div>

                {selectedSource && <CardBody>
                    <div className="vstack gap-2">
                        <Row >
                            <Col md={5}>
                                <h6 className="h-100 fw-bold"><i className="mdi mdi-database" /> {t("Nom de la source")}:</h6>
                            </Col>
                            <Col md={7}>
                                <div>{selectedSource && selectedSource?.name}</div>
                            </Col>
                        </Row>
                        <div className="hr border border-secondary border-opacity-25" />
                        <Row >
                            <Col md={5}>
                                <h6 className="h-100 fw-bold"><i className="mdi mdi-database" /> {t("Fournisseur de données")}:</h6>
                            </Col>
                            <Col md={7}>
                                <div>{selectedSource && getProviderInfo(selectedSource.provider?.type || selectedSource?.type)}</div>
                            </Col>
                        </Row>
                        <div className="hr border border-secondary border-opacity-25" />
                        <h6 className="h-100 fw-bold"><i className="mdi mdi-cog" /> {t("Paramétrages")}:</h6>
                        <KbSettings
                            source={selectedSource}
                            onDatasourceFilterClicked={onDatasourceFilterClicked}
                            onRemoveDatasourceFilter={onRemoveDatasourceFilter}
                            onChange={settings => {
                                onSourceConfigChanged(settings)
                            }}
                        />
                    </div>
                </CardBody>}
            </Card>
        </div>
    </div>
}

export default withTranslation()(knowledgeBaseSettings);