
import { API_URL } from "helpers/api_helper";
import { fetchEventSource } from "@microsoft/fetch-event-source";

export const askYourTodo = async (todoId, instruction, content, eventConsumer, onStreamStart, onStreamEnd, accessToken) => {

    const ctrl = new AbortController();
    let stream = "";

    await fetchEventSource(`${API_URL}/workspace/tasks/asks/sse`,
        {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`,
            },
            body: JSON.stringify({
                todoId: todoId,
                instruction: instruction,
                content: content
            }),
            cache: "no-cache",
            signal: ctrl.signal,
            onopen(res) {
                if (res.ok && res.status === 200) {

                    if (onStreamStart) {
                        onStreamStart();
                    }
                } else if (
                    res.status >= 400 &&
                    res.status < 500 &&
                    res.status !== 429
                ) {
                    console.log("Client side error ", res);
                }
            },
            onmessage(event) {
                const parsedEvent = JSON.parse(event.data);
                switch (parsedEvent.type) {
                    case "streaming":
                        eventConsumer(parsedEvent.value)
                        stream += parsedEvent.value;
                        break;
                    case "conversationTitleRenamed":
                        break;
                    case "end":
                        if (onStreamEnd) {
                            onStreamEnd(stream);
                        }
                        break;
                }
            },
            onclose() {
                console.log("Connection closed by the server");
            },
            onerror(err) {
                console.log("There was an error from server:", err);
            },
        });

    fetchEventSource();
}