import { isEmpty } from 'lodash';
import React from 'react';
import Salesforce from "assets/images/providers/salesforce.png"
import Bing from "assets/images/providers/bing.png"
import GoogleSearchIcon from "assets/images/providers/googleSearch.png"
import SharePoint from "assets/images/providers/sharepoint.png"
import Jira from "assets/images/providers/Jira.png"
import TeamsIcon from "assets/images/providers/microsoft-teams-icon.png"

import { DatasourceProviders, ResourceTypes } from 'constants/general';

export function uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'
        .replace(/[xy]/g, function (c) {
            const r = Math.random() * 16 | 0,
                v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
}

export function getFileIcon(name, size = 20, notFound = null) {

    try {
        const extension = name.split(".").pop();

        switch (extension) {

            case "pdf":
                return <i className={'mdi mdi-file-pdf font-size-' + size + ' text-danger'} />
            case "xls":
            case "xlsx":
            case "csv":
                return <i className={'mdi mdi-microsoft-excel font-size-' + size} />
            case "docx":
            case "doc":
                return <i className={'mdi mdi-microsoft-word font-size-' + size} />

            case "pptx":
                return <i className={"mdi mdi-microsoft-powerpoint  text-danger  font-size-" + size} />
            default:

                return notFound ? notFound : <i className={'mdi mdi-file-document-outline text-info font-size-' + size} />
        }
    } catch (err) {
        return <i className='' />
    }

}

export const getServiceInfo = (service) => {
    const id = service?.id || service?.providerId || service;

    switch (id) {

        case "workspace":
            return { name: 'Espace de Travail', icon: 'bx bx-task text-info' }


        case "filesystem":
            return { name: 'Documents', icon: 'bx bxs-folder text-warning' }

        case "App":
            return { name: service?.name, icon: 'mdi mdi-apps text-info' }

        default:
            return { name: service?.name, icon: 'bx bx-data text-info' }

    }
}

export const truncateFromMiddle = (
    fullStr,
    strLen,
    middleStr = '...',
) => {
    if (fullStr.length <= strLen) return fullStr;

    const midLen = middleStr.length;
    const charsToShow = strLen - midLen;
    const frontChars = Math.ceil(charsToShow / 2);
    const backChars = Math.floor(charsToShow / 2);
    return (
        fullStr.substr(0, frontChars) +
        middleStr +
        fullStr.substr(fullStr.length - backChars)
    );
};

export function getProviderLogo(type, size = 18) {
    switch (type) {
        case "msteams":
            return <img src={TeamsIcon} width={size} height={size} />
        case "azure":
            return <img src={Azure} height={size} width={size} />
        case DatasourceProviders.SHAREPOINT:
            return <img src={SharePoint} height={size} width={size} />
        case DatasourceProviders.SALESFORCE:
            return <img src={Salesforce} height={size} width={size} />
        case DatasourceProviders.BING_SEARCH:
            return <img src={Bing} height={size} width={size} />
        case DatasourceProviders.GOOGLE_SEARCH:
            return <img src={GoogleSearchIcon} height={size} width={size} />
        case DatasourceProviders.JIRA:
            return <img src={Jira} height={size} width={size} />


        case ResourceTypes.WORKSPACE:
            return <i className={'bx bx-task text-info font-size-' + size} />

        case ResourceTypes.FILESYSTEM:
        case "local":
        case "csv":
            return <i className='fas fa-folder text-warning' width={size} height={size} />

        case "web":
            return <i className={"fas fa-cloud text-info font-size-" + size} />

        case "llm":
            return <i className='fas fa-brain text-info' width={size} height={size} />
    }
}

export function getProviderInfo(type, size = 18, name = null) {

    switch (type) {
        case "web":
            return <div className='hstack gap-1'>
                {getProviderLogo(type, size)}
                <span className='mt-1'>{name || "Site Web"}</span>
            </div>

        case ResourceTypes.FILESYSTEM:
            return <div className='hstack gap-1'>
                {getProviderLogo("local", size)}
                <span className=''>{name || "Documents"}</span>
            </div>

        case ResourceTypes.WORKSPACE:
            return <div className='hstack gap-1'>
                {getProviderLogo(ResourceTypes.WORKSPACE, size)}
                <span className=''>{name || "Espace de travail"}</span>
            </div>

        case ResourceTypes.DIRECTORY:
        case ResourceTypes.FILE:
            return <div className='hstack gap-1'>
                {getProviderLogo("local", size)}
                <span className=''>{name || "Local"}</span>
            </div>

        case DatasourceProviders.SALESFORCE:
            return <div className='hstack gap-1'>
                {getProviderLogo(type, size)}
                <span className=''>{name || "Salesforce"}</span>
            </div>

        case DatasourceProviders.SHAREPOINT:
            return <div className='hstack gap-1'>
                {getProviderLogo(type, size)}
                <span className=''>{name || "Sharepoint"}</span>
            </div>

        case DatasourceProviders.BING_SEARCH:
            return <div className='hstack gap-1'>
                {getProviderLogo(type, size)}
                <span className=''>{name || "Bing Search"}</span>
            </div>

        case DatasourceProviders.JIRA:
            return <div className='hstack gap-1'>
                {getProviderLogo(type, size)}
                <span className=''>{name || "JIRA"}</span>
            </div>


        case DatasourceProviders.GOOGLE_SEARCH:
            return <div className='hstack gap-1'>
                {getProviderLogo(type, size)}
                <span className=''>{name || "Google Search"}</span>
            </div>
        default:
            return getProviderLogo(type)
    }
}


export function getInitiales(text) {

    if (isEmpty(text))
        return text;
    const splits = text.trim().split(" ");

    if (splits.length > 1) {
        return splits[0][0].toUpperCase() + splits[1][0].toUpperCase()
    }
    return splits[0][0].toUpperCase()
}

export const copyToClipboard = (content) => {
    navigator.clipboard.writeText(content);
};

export function uniqueFilter(value, index, self) {
    return self.indexOf(value) === index;
}

export const businessDomains = [
    {
        name: "Général",
        description: "Prompt pour une tache générale telle qu'un résumé de texte, une traduction,... "
    },
    {
        name: "Business",
        description: "Prompt to perform a business task , analysis,... "
    },
    {
        name: "Commerce",
        description: "Prompt to perform a commercial task , prospection,... "
    },
    {
        name: "Finance",
        description: "Gestion des ressources financières, investissements, et planification budgétaire."
    },
    {
        name: "Juridique",
        description: "Conseils juridiques, représentation légale, et conformité aux lois et réglementations."
    },
    {
        name: "Marketing",
        description: "Promotion des produits ou services, études de marché, et développement de stratégies de communication."
    },
    {
        name: "Ressources humaines",
        description: "Recrutement, gestion du personnel, formation, et développement des compétences."
    },
    {
        name: "Technologies de l'information",
        description: "Gestion des systèmes informatiques, développement de logiciels, et support technique."
    },
    {
        name: "Ventes",
        description: "Prospection de clients, négociation de contrats, et réalisation des objectifs de vente."
    },
    {
        name: "Opérations",
        description: "Gestion des processus opérationnels, amélioration de l'efficacité, et optimisation des ressources."
    },
    {
        name: "Gestion de la chaîne d'approvisionnement",
        description: "Planification, approvisionnement, logistique, et gestion des fournisseurs."
    },
    {
        name: "Fabrication",
        description: "Production de biens matériels, contrôle de la qualité, et gestion des opérations de fabrication."
    },
    {
        name: "Santé",
        description: "Prestation de soins médicaux, gestion des établissements de santé, et recherche médicale."
    },
    {
        name: "Immobilier",
        description: "Achat, vente, location, et gestion de biens immobiliers."
    },
    {
        name: "Hôtellerie et tourisme",
        description: "Gestion d'hôtels, restaurants, agences de voyage, et promotion du tourisme."
    },
    {
        name: "Commerce de détail",
        description: "Vente de produits aux consommateurs, gestion des magasins, et expérience client."
    },
    {
        name: "Éducation",
        description: "Enseignement, développement de programmes éducatifs, et gestion des établissements scolaires."
    },
    {
        name: "Conseil",
        description: "Fourniture de conseils et d'expertise dans divers domaines pour aider les entreprises à prendre des décisions stratégiques."
    }
];

export const ResourcesScopes = [{
    label: "Privé",
    value: "private"
},
{
    label: "Partagé",
    value: "shared"
}]