import React, { useEffect } from "react";
import {
    Col,
    Container,
    Row
} from "reactstrap";
import Breadcrumbs from "../../../../components/Common/Breadcrumb2";
import { withTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { isEmpty } from "lodash";
import { getSupportPlatformBoard, getSupportRequests } from "store/actions";
import SupportBoardBanner from "./banner";
import TopAssistants from "./topAssistants";
import PendingSupportRequest from "./requests"
import SupportPlatformBoardHisto from "./historical"
import SimpleBar from "simplebar-react"

const SupportPlatformBoard = props => {

    //meta title
    document.title = "Jask For Support";


    const dispatch = useDispatch();
    const { board, assistants, waitingForValidation } = useSelector(state => ({
        board: state.Support.board,
        assistants: state.Studio.assistants,
        waitingForValidation: state.Support.requests?.filter(r => r.waitingForValidation === true),

    }));


    useEffect(() => {
        if (isEmpty(board)) {
            dispatch(getSupportPlatformBoard({}))
        }

        if (isEmpty(waitingForValidation)) {
            dispatch(getSupportRequests({
                force: false
            }));
        }
    }, [dispatch])
    return (
        <React.Fragment>
            <div className="page-content board ">
                <Container fluid className="">
                    <Breadcrumbs
                        title={props.t("Tableau de bord")}
                        breadcrumbItems={[
                            {
                                title: "Jask"
                            },
                            {
                                title: "Support"
                            },
                            {
                                title: "Board"
                            }
                        ]}
                    />
                    <div className="">
                        <Row>
                            <Col lg={12}>
                                <SupportBoardBanner board={board} waitingForValidation={waitingForValidation} />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <SimpleBar className="board-stats-card">

                                    <Row>
                                        <Col md={12}>
                                            <SupportPlatformBoardHisto board={board} assistants={assistants} />
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col md={12}>
                                            <TopAssistants board={board} assistants={assistants} />
                                        </Col>
                                    </Row>
                                </SimpleBar>
                            </Col>

                            <Col md={6}>
                                <PendingSupportRequest board={board} assistants={assistants} waitingForValidation={waitingForValidation} />
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>
        </React.Fragment>
    );
};


export default withTranslation()(SupportPlatformBoard);
