import { CREATE_PROMPT, CREATE_PROMPT_FAILED, CREATE_PROMPT_SUCCESS, DELETE_PROMPT, DELETE_PROMPT_FAILED, DELETE_PROMPT_SUCCESS, GET_PROMPTS, GET_PROMPTS_FAILED, GET_PROMPTS_SUCCESS } from "./actionTypes";

export const getPromptStore = (filters) => ({
  type: GET_PROMPTS,
  payload: filters
})

export const getPromptStoreSuccess = (data) => ({
  type: GET_PROMPTS_SUCCESS,
  payload: data
});

export const getPromptStoreFailed = (error) => ({
  type: GET_PROMPTS_FAILED,
  payload: error
});


export const createPrompt = (data) => ({
  type: CREATE_PROMPT,
  payload: data
});

export const createPromptSuccess = (data) => ({
  type: CREATE_PROMPT_SUCCESS,
  payload: data
});

export const createPromptFailed = (error) => ({
  type: CREATE_PROMPT_FAILED,
  payload: error
});


export const deletePrompt = (data) => ({
  type: DELETE_PROMPT,
  payload: data
});

export const deletePromptSuccess = (data) => ({
  type: DELETE_PROMPT_SUCCESS,
  payload: data
});

export const deletePromptFailed = (data) => ({
  type: DELETE_PROMPT_FAILED,
  payload: data
});


