import React, { createRef, useEffect, useState } from "react";
import { TaskStatus } from "constants/tasks";
import { Badge, Card, CardBody, CardTitle, Spinner } from "reactstrap";
import SimpleBar from "simplebar-react";
import { map } from "lodash";
import { getFileIcon, truncateFromMiddle } from "utils";


const FileToProcessList = ({ files, instructions, selectedFile, onFileSelectedChanged, status }) => {


    return <Card className="border task-files-list">
        <div>
            <CardTitle className="ps-4">Groupe de fichiers à traiter ({files?.length})</CardTitle>

            <ul className="list-unstyled ">
                <SimpleBar className="scroller">
                    {map(files, file => {

                        return <li onClick={() => onFileSelectedChanged(file)} key={file?.location} >

                            <div className="d-flex justify-content-between">
                                <div>
                                    <div className="hstack gap-1">
                                        {getFileIcon(file.name, 18)}
                                        <small className="font-size-11">{truncateFromMiddle(file.name, 30)}</small>
                                    </div>
                                </div>
                            </div>
                        </li>
                    })}
                </SimpleBar>
            </ul>
        </div>
    </Card>
}

export default FileToProcessList;