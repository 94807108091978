import React, { useState } from "react";
import Dropzone from "react-dropzone";


import {
    Col,
    Row,
    Form,
    Label,
    Card,
    CardBody,
    CardSubtitle
} from "reactstrap";

import FilesSelection from "./FilesSelection"

function LocalFSConnectivity({ onSetConnection }) {

    const [selectedFiles, setselectedFiles] = useState([]);

    function handleAcceptedFiles(files) {
        setselectedFiles(files);
        onSetConnection({
            files: files,
            isValid: files.length > 0
        })
    }

    const onDeleteFile = (file) => {
        setselectedFiles(selectedFiles.filter(f => f.path !== file.path))
    }

    /**
 * Formats the size
 */
    function formatBytes(bytes, decimals = 2) {
        if (bytes === 0) return "0 Bytes"
        const k = 1024
        const dm = decimals < 0 ? 0 : decimals
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

        const i = Math.floor(Math.log(bytes) / Math.log(k))
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
    }

    return (<div className="connectivy-local">
        <Row>
            <Col md={7}>
                <Row>
                    <Col className="col-12">
                        <Card>
                            <CardBody>
                                <Form>
                                    <Dropzone
                                        onDrop={acceptedFiles => {
                                            handleAcceptedFiles(acceptedFiles)
                                        }}
                                    >
                                        {({ getRootProps, getInputProps }) => (
                                            <div className="dropzone d-flex flex-column justify-content-center align-items-center">
                                                <div
                                                    className="dz-message needsclick"
                                                    {...getRootProps()}
                                                >
                                                    <input {...getInputProps()} />
                                                    <div className="">
                                                        <div className="mb-3">
                                                            <i className="display-4 text-muted bx bxs-cloud-upload" />
                                                        </div>
                                                        <h4>Déposez vos fichiers ici ou cliquez pour importer.</h4>
                                                    </div>

                                                </div>
                                            </div>
                                        )}
                                    </Dropzone>
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Col>
            <Col md={5}>
                {selectedFiles?.length > 0 && <FilesSelection files={selectedFiles} onDeleteFile={onDeleteFile} />
                }            </Col>
        </Row>



    </div >)
}

export default LocalFSConnectivity