import React, { useEffect, useState } from "react"
import { Button, DropdownItem, DropdownMenu, DropdownToggle, Label, Modal, ModalBody, Spinner, UncontrolledDropdown } from "reactstrap";
import CreateTodoItem from "pages/Workspace/todolist/create";
import { copyToClipboard, getProviderLogo } from "utils";
import { withTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { isEmpty, map } from "lodash";
import Select from "react-select"
import { getChannelUsers, shareContentToUsers } from "service/channels/gateway";
import { operationCompleted } from "utils/notifications";
import { getDeployments } from "store/actions";
import { ExportType } from "constants/export";
import FileSaver from "file-saver"
import ExportGeneration from "components/Export";

const SharePanel = ({ show, onCloseClick, content, platform, t }) => {

    const [contentToShare, setContentToShare] = useState(content);
    const [principals, setPrincipals] = useState(content);
    const [selectedPrincipals, setSelectedPrincipals] = useState(content);
    const [isSharing, setIsSharing] = useState(false);


    const onConfirm = () => {
        setIsSharing(true);
        shareContentToUsers(platform.channelId, platform.id, {
            referenceIds: selectedPrincipals.map(i => i.value),
            content: contentToShare,
        }).then(resp => {
            console.log(resp)
            operationCompleted("Contenu partagé avec succès!");
            onCloseClick();
        }).finally(() => {
            setIsSharing(false);
        })
    }



    useEffect(() => {
        setContentToShare(content)
    }, [content])


    useEffect(() => {

        if (isEmpty(platform))
            return;

        getChannelUsers(platform.channelId, platform.id).then(resp => {
            setPrincipals(resp.map(item => ({
                label: item.principalName,
                value: item
            })));
        })

    }, [platform])

    return <Modal size="lg" isOpen={show}
        toggle={onCloseClick}
        centered={true}
        fade={false}
        contentClassName="share-panel "
        zIndex={10000}

    >
        <div className="modal-content">
            <ModalBody className="">
                <div className="d-flex justify-content-between">
                    <div>
                        <h5>{getProviderLogo(platform?.channelId)} Partager du contenu</h5>
                    </div>
                    <div>
                        <button type="button" onClick={onCloseClick} className="btn-close position-absolute end-0 top-0 m-3"></button>
                    </div>
                </div>
                <div className="share-panel-content">
                    <div className="mb-4">

                    </div>
                    <div className="mb-4">
                        <Label className="fw-bold">Contenu à partager:</Label>
                        <textarea
                            className="form-control"
                            value={contentToShare}
                            onChange={e => setContentToShare(e.target.value)}
                            rows={10}
                        />
                    </div>
                    <div className="mb-4">
                        <Label className="fw-bold">A qui souhaitiez-vous partager:</Label>

                        <Select

                            options={principals}
                            isMulti={true}
                            onChange={setSelectedPrincipals}
                            value={selectedPrincipals}

                        />
                    </div>
                </div>

                <div className="hstack gap-2 justify-content-end mb-0">
                    <button type="button" className="btn btn-success" onClick={onConfirm}>Partager</button>
                    <button type="button" className="btn btn-secondary" onClick={onCloseClick}>Annuler</button>
                    {isSharing && <div className="vr" />}
                    {isSharing && <Spinner />}
                </div>
            </ModalBody>
        </div>
    </Modal>
}

const ShareContent = ({ content, t, disabled, title, titleButton }, props) => {
    const [showShare, setShowShare] = useState(false);
    const [showExport, setShowExport] = useState(false);
    const [exportType, setExportType] = useState(false);
    const [platform, setPlatform] = useState(null);
    const dispatch = useDispatch();

    const { deployments } = useSelector(state => ({
        deployments: state.Deployments.deployments?.filter(d => d.canShare === true),
    }));

    const downloadContent = () => {
        FileSaver.saveAs(new Blob([content?.content || content], { type: "text/plain;charset=utf-8" }), `${new Date().toLocaleTimeString()}.txt`)
    }


    const startSharing = (platform) => {
        setPlatform(platform);
        setShowShare(!showShare);
    }

    const onExport = (type, item) => {

        switch (type) {
            case ExportType.TEAMS:
                startSharing(item);
                break;

            default:
                setExportType(type);
                setShowExport(!showExport);
                break;
        }
    }

    useEffect(() => {
        if (isEmpty(deployments))
            dispatch(getDeployments())
    }, [dispatch]);

    return <div >

        {showShare && <SharePanel show={showShare}
            content={content?.content || content}
            onCloseClick={() => setShowShare(false)}
            platform={platform}
            disabled={disabled || false}
        />}

        {showExport && <ExportGeneration
            taskName={title}
            type={exportType}
            data={content}
            isOpen={showExport}
            onCloseClick={() => setShowExport(false)}
        />}


        <UncontrolledDropdown>

            {!titleButton ? <DropdownToggle tag={"a"} disabled={disabled || false}>
                <i className={"mdi mdi-share-variant-outline font-size-14  me-1"} />
            </DropdownToggle> :

                <DropdownToggle className={disabled ? "disabled" : "btn btn-info"} disabled={disabled || false}>
                    {titleButton}
                </DropdownToggle>}

            <DropdownMenu>
                <DropdownItem onClick={() => onExport(ExportType.DOCUMENT)}> <i className="bx bxs-folder" /> {t("Mes documents")}</DropdownItem>
                <DropdownItem divider />
                <DropdownItem onClick={() => onExport(ExportType.TODO)}> <i className="bx bx-task" /> {t("Mes taches")}</DropdownItem>
                <DropdownItem divider />
                <DropdownItem onClick={() => onExport(ExportType.ASSISTANTS)}> <i className="bx bx-bot" /> {t("Mes assistants")}</DropdownItem>
                <DropdownItem divider />
                <DropdownItem onClick={downloadContent}> <i className="bx bx-download" /> {t("Mon Poste de travail")}</DropdownItem>
                <DropdownItem divider />

                {map(deployments, item => {
                    return <DropdownItem onClick={() => onExport(ExportType.TEAMS, item)}>
                        <div className="hstack gap-1">
                            {getProviderLogo(item.channelId, 14)}
                            {item.name}
                        </div>
                    </DropdownItem>
                })}
            </DropdownMenu>
        </UncontrolledDropdown>
    </div>
}

export default withTranslation()(ShareContent);
