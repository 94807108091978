/* OPPTY */
export const GET_CRM_OPPORTUNITIES = "GET_CRM_OPPORTUNITIES"
export const GET_CRM_OPPORTUNITIES_SUCCESS = "GET_CRM_OPPORTUNITIES_SUCCESS"
export const GET_CRM_OPPORTUNITIES_FAILED = "GET_CRM_OPPORTUNITIES_FAILED"

export const GET_CRM_OPPORTUNITIES_STAGES = "GET_CRM_OPPORTUNITIES_STAGES"
export const GET_CRM_OPPORTUNITIES_STAGES_SUCCESS = "GET_CRM_OPPORTUNITIES_STAGES_SUCCESS"
export const GET_CRM_OPPORTUNITIES_STAGES_FAILED = "GET_CRM_OPPORTUNITIES_STAGES_FAILED"


/* CONTACTS */
export const GET_CRM_CONTACTS = "GET_CRM_CONTACTS"
export const GET_CRM_CONTACTS_SUCCESS = "GET_CRM_CONTACTS_SUCCESS"
export const GET_CRM_CONTACTS_FAILED = "GET_CRM_CONTACTS_FAILED"

/* USERS */
export const GET_CRM_USERS = "GET_CRM_USERS"
export const GET_CRM_USERS_SUCCESS = "GET_CRM_USERS_SUCCESS"
export const GET_CRM_USERS_FAILED = "GET_CRM_USERS_FAILED"

/* BOARD */

export const GET_CRM_BOARD = "GET_CRM_BOARD"
export const GET_CRM_BOARD_SUCCESS = "GET_CRM_BOARD_SUCCESS"
export const GET_CRM_BOARD_FAILED = "GET_CRM_BOARD_FAILED"


/* ACCOUNT */
export const GET_CRM_ACCOUNT = "GET_CRM_ACCOUNT"
export const GET_CRM_ACCOUNT_SUCCESS = "GET_CRM_ACCOUNT_SUCCESS"
export const GET_CRM_ACCOUNT_FAILED = "GET_CRM_ACCOUNT_FAILED"


/* PRODUCT BOARD  */
export const GET_CRM_OPPORTUNITIES_BY_PRODUCT = "GET_CRM_OPPORTUNITIES_BY_PRODUCT"
export const GET_CRM_OPPORTUNITIES_BY_PRODUCT_SUCCESS = "GET_CRM_OPPORTUNITIES_BY_PRODUCT_SUCCESS"
export const GET_CRM_OPPORTUNITIES_BY_PRODUCT_FAILED = "GET_CRM_OPPORTUNITIES_BY_PRODUCT_FAILED"

/* Historical Board */
export const GET_CRM_OPPORTUNITIES_HISTORICAL = "GET_CRM_OPPORTUNITIES_HISTORICAL"
export const GET_CRM_OPPORTUNITIES_HISTORICAL_SUCCESS = "GET_CRM_OPPORTUNITIES_HISTORICAL_SUCCESS"
export const GET_CRM_OPPORTUNITIES_HISTORICAL_FAILED = "GET_CRM_OPPORTUNITIES_HISTORICAL_FAILED"


/* OPPTY Trend */
export const GET_CRM_OPPTY_TREND = "GET_CRM_OPPTY_TREND"
export const GET_CRM_OPPTY_TREND_SUCCESS = "GET_CRM_OPPTY_TREND_SUCCESS"
export const GET_CRM_OPPTY_TREND_FAILED = "GET_CRM_OPPTY_TREND_FAILED"
