import { GET_SUPPORT_PLATFORM, GET_SUPPORT_PLATFORM_SUCCESS, GET_SUPPORT_REQUESTS, GET_SUPPORT_REQUESTS_SUCCESS, ADD_SUPPORT_PLATFORM, ADD_SUPPORT_PLATFORM_SUCCESS, UPDATE_SUPPORT_REQUESTS, UPDATE_SUPPORT_REQUESTS_SUCCESS, DEL_SUPPORT_PLATFORM_SUCCESS, DEL_SUPPORT_PLATFORM_FAILED, GET_SUPPORT_PLATFORM_FAILED, GET_SUPPORT_REQUESTS_FAILED, ADD_SUPPORT_PLATFORM_FAILED, GET_SUPPORT_PLATFORM_BOARD, GET_SUPPORT_PLATFORM_BOARD_SUCCESS, GET_SUPPORT_PLATFORM_BOARD_FAILED } from "./actionTypes"

const INIT_STATE = {
  platforms: [],
  requests: [],
  isLoading: false,
  isAdding: false,
  error: {},
  board: null,
}

const JaskSupport = (state = INIT_STATE, action) => {
  switch (action.type) {

    case GET_SUPPORT_PLATFORM_BOARD:
    case GET_SUPPORT_PLATFORM:
      return {
        ...state,
        isLoading: true
      }

    case GET_SUPPORT_PLATFORM_SUCCESS:
      return {
        ...state,
        isLoading: false,
        platforms: action.payload,
      }

    case GET_SUPPORT_PLATFORM_BOARD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        board: action.payload
      }

    case GET_SUPPORT_REQUESTS:
      return {
        ...state,
        isLoading: true,
      }

    case GET_SUPPORT_REQUESTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        requests: action.payload,
      }


    case ADD_SUPPORT_PLATFORM:
      return {
        ...state,
        isAdding: true,
      }

    case ADD_SUPPORT_PLATFORM_SUCCESS:
      return {
        ...state,
        platforms: [action.payload, ...state.platforms]
      }

    case UPDATE_SUPPORT_REQUESTS_SUCCESS:
      const others = state.requests.filter(r => r.id !== action.payload.id)
      return {
        ...state,
        requests: [action.payload, ...others]
      }

    case DEL_SUPPORT_PLATFORM_SUCCESS:
      return {
        ...state,
        platforms: state.platforms.filter(r => r.id !== action.payload)
      }

    case DEL_SUPPORT_PLATFORM_FAILED:
    case GET_SUPPORT_PLATFORM_FAILED:
    case GET_SUPPORT_REQUESTS_FAILED:
    case ADD_SUPPORT_PLATFORM_FAILED:
    case GET_SUPPORT_PLATFORM_BOARD_FAILED:
      return {
        ...state,
        isLoading: false,
        isAdding: false,
        error: action.payload
      }

    default:
      return state
  }
}

export default JaskSupport;