import { API_URL, post } from "helpers/api_helper";
import { GET_DATASOURCE_LIST } from "helpers/url_helper";
import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import {
    Row, Col
} from "reactstrap"
import WebSelection from "./WebSelection";


const WebConnectivity = ({ onSetConnection }) => {

    const [urls, setUrls] = useState([{ id: 1, url: null }]);
    const [isValid, setIsValid] = useState(false);
    const [webResources, setWebResources] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const onAddUrl = () => {
        const modifiedRows = [...urls];
        modifiedRows.push({ id: modifiedRows.length + 1, url: null });
        setUrls(modifiedRows);
    };

    const onDeleteUrl = id => {
        if (id !== 1) {
            var modifiedRows = [...urls];
            modifiedRows = modifiedRows.filter(x => x["id"] !== id);
            setUrls(modifiedRows);
        }
    };

    const handleUrl = (url, newVal) => {

        const modifiedRows = [...urls];
        const objIndex = modifiedRows.findIndex((obj => obj.id == url.id));
        modifiedRows[objIndex].url = newVal;
        const validUrls = modifiedRows.filter(x => !isEmpty(x.url))
        setUrls(validUrls);


        setIsValid(validUrls.length > 0);
    }

    const onSelection = (link) => {
        const items = [...webResources];
        items.forEach(item => {
            if (item.link === link)
                item.selected = !item.selected;
        });

        setWebResources(items);
    }

    const browseUrls = () => {
        setIsLoading(true);
        const payload = {
            urls: urls.map(i => i.url)
        }

        post(`${GET_DATASOURCE_LIST}/web/discover`, payload).then(response => {
            setWebResources(response.map(i => {
                return {
                    selected: true,
                    link: i
                }
            }));
        }).then(() => {
            setIsLoading(false);
        })
    }

    useEffect(() => {
        const selectedUrls = webResources.filter(i => i.selected).map(i => i.link);
        onSetConnection({
            urls: selectedUrls,
            isValid: selectedUrls.length > 0
        });
    }, [webResources])

    useEffect(() => {
        const validUrls = urls.filter(x => !isEmpty(x.url));

        if (validUrls.length == 0)
            setWebResources([])
    }, [urls])

    return (
        <div className="connectivy-web">

            <div>
                <h5>Nouveau liens </h5>
            </div>
            <Row style={{ height: "55vh" }}>
                <Col lg="6">

                    <div>
                        {(urls || []).map((url, key) => (
                            <Row key={key} className="mb-2">
                                <div className="hstack gap-2 font-size-18 text-danger">
                                    <input className="form-control" type="text" id={key + "-url"} placeholder="ie: https://www.jask.me" onChange={e => handleUrl(url, e.target.value)} />
                                    <Link to="#!" onClick={e => onDeleteUrl(url.id)}><i className="fas fa-trash" /></Link>
                                </div>
                            </Row>
                        ))}
                    </div>
                    <input
                        type="button"
                        className="btn btn-success mt-3 mt-lg-0"
                        value="Ajouter"
                        onClick={() => onAddUrl()}
                    />
                    <button className="btn btn-primary mt-3 mt-lg-0 ms-2" disabled={!isValid} onClick={browseUrls}>
                        Parcourir
                    </button>
                </Col>
                <Col md={6}>
                    {(webResources?.length > 0 || isLoading) && <WebSelection onSelected={onSelection} isLoading={isLoading} resources={webResources} />
                    }            </Col>
            </Row>

        </div>
    )
}

export default WebConnectivity;
