import { GET_CRM_ACCOUNT, GET_CRM_ACCOUNT_FAILED, GET_CRM_ACCOUNT_SUCCESS, GET_CRM_BOARD, GET_CRM_BOARD_FAILED, GET_CRM_BOARD_SUCCESS, GET_CRM_CONTACTS, GET_CRM_CONTACTS_FAILED, GET_CRM_CONTACTS_SUCCESS, GET_CRM_OPPORTUNITIES, GET_CRM_OPPORTUNITIES_BY_PRODUCT, GET_CRM_OPPORTUNITIES_BY_PRODUCT_FAILED, GET_CRM_OPPORTUNITIES_BY_PRODUCT_SUCCESS, GET_CRM_OPPORTUNITIES_FAILED, GET_CRM_OPPORTUNITIES_HISTORICAL, GET_CRM_OPPORTUNITIES_HISTORICAL_FAILED, GET_CRM_OPPORTUNITIES_HISTORICAL_SUCCESS, GET_CRM_OPPORTUNITIES_STAGES, GET_CRM_OPPORTUNITIES_STAGES_FAILED, GET_CRM_OPPORTUNITIES_STAGES_SUCCESS, GET_CRM_OPPORTUNITIES_SUCCESS, GET_CRM_OPPTY_TREND, GET_CRM_OPPTY_TREND_FAILED, GET_CRM_OPPTY_TREND_SUCCESS, GET_CRM_USERS, GET_CRM_USERS_FAILED, GET_CRM_USERS_SUCCESS } from "./actionTypes";

export const getCrmOppty = payload => ({
  type: GET_CRM_OPPORTUNITIES,
  payload: payload,
})



export const getCrmOpptySuccess = payload => ({
  type: GET_CRM_OPPORTUNITIES_SUCCESS,
  payload: payload,
})

export const getCrmOpptyFailed = payload => ({
  type: GET_CRM_OPPORTUNITIES_FAILED,
  payload: payload,
})


export const getCrmContacts = payload => ({
  type: GET_CRM_CONTACTS,
  payload: payload,
})

export const getCrmContactsSuccess = payload => ({
  type: GET_CRM_CONTACTS_SUCCESS,
  payload: payload,
})

export const getCrmContactsFailed = payload => ({
  type: GET_CRM_CONTACTS_FAILED,
  payload: payload,
})


export const getCrmUsers = payload => ({
  type: GET_CRM_USERS,
  payload: payload,
})

export const getCrmUsersSuccess = payload => ({
  type: GET_CRM_USERS_SUCCESS,
  payload: payload,
})

export const getCrmUsersFailed = payload => ({
  type: GET_CRM_USERS_FAILED,
  payload: payload,
})

/* BOARD */

export const getCrmBoard = payload => ({
  type: GET_CRM_BOARD,
  payload: payload,
})

export const getCrmBoardSuccess = payload => ({
  type: GET_CRM_BOARD_SUCCESS,
  payload: payload,
})


export const getCrmBoardFailed = payload => ({
  type: GET_CRM_BOARD_FAILED,
  payload: payload,
})

/* ACCOUNT */

export const getCrmAccount = payload => ({
  type: GET_CRM_ACCOUNT,
  payload: payload,
})

export const getCrmAccountSuccess = payload => ({
  type: GET_CRM_ACCOUNT_SUCCESS,
  payload: payload,
})

export const getCrmAccountFailed = payload => ({
  type: GET_CRM_ACCOUNT_FAILED,
  payload: payload,
})

/* STAGES */

export const getCrmAStages = payload => ({
  type: GET_CRM_OPPORTUNITIES_STAGES,
  payload: payload,
})

export const getCrmAStagesSuccess = payload => ({
  type: GET_CRM_OPPORTUNITIES_STAGES_SUCCESS,
  payload: payload,
})

export const getCrmAStagesFailed = payload => ({
  type: GET_CRM_OPPORTUNITIES_STAGES_FAILED,
  payload: payload,
})


export const getCrmProductShare = payload => ({
  type: GET_CRM_OPPORTUNITIES_BY_PRODUCT,
  payload: payload,
})

export const getCrmProductShareSuccess = payload => ({
  type: GET_CRM_OPPORTUNITIES_BY_PRODUCT_SUCCESS,
  payload: payload,
})

export const getCrmProductShareFailed = payload => ({
  type: GET_CRM_OPPORTUNITIES_BY_PRODUCT_FAILED,
  payload: payload,
})


export const getCrmHisto = payload => ({
  type: GET_CRM_OPPORTUNITIES_HISTORICAL,
  payload: payload,
})

export const getCrmHistoSuccess = payload => ({
  type: GET_CRM_OPPORTUNITIES_HISTORICAL_SUCCESS,
  payload: payload,
})

export const getCrmHistoFailed = payload => ({
  type: GET_CRM_OPPORTUNITIES_HISTORICAL_FAILED,
  payload: payload,
})



export const getCrmTrend = payload => ({
  type: GET_CRM_OPPTY_TREND,
  payload: payload,
})

export const getCrmTrendSuccess = payload => ({
  type: GET_CRM_OPPTY_TREND_SUCCESS,
  payload: payload,
})

export const getCrmTrendFailed = payload => ({
  type: GET_CRM_OPPTY_TREND_FAILED,
  payload: payload,
})