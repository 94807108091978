import PropTypes from 'prop-types'
import React, { useEffect, useState } from "react"
import { Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"
import { post } from "helpers/api_helper";
import { GET_DIRECTORY } from "helpers/url_helper";
import { withTranslation } from "react-i18next";

const NewFolder = ({ currentLocation, show, onCloseClick, onNewDirectory, t }) => {

    const [folderLocation, setFolderLocation] = useState(null);
    const createDir = () => {
        const payload = { name: folderLocation, parent: currentLocation.location }
        post(GET_DIRECTORY, payload).then(resp => {
            onNewDirectory(resp);
            onCloseClick();
        })
    }

    return (
        <Modal size="md" isOpen={show} toggle={onCloseClick} centered={true} fade={false}>
            <ModalHeader>
                <h5>{t("Nouveau dossier")}</h5>
                <button type="button" onClick={onCloseClick} className="btn-close position-absolute end-0 top-0 m-3"></button>
            </ModalHeader>
            <div className="modal-content">
                <ModalBody className="px-4 py-4 text-center">
                    <div className="mb-0">
                        <div className='hstack gap-1'>
                            <div className='hstack gap-1'>
                                <i className='mdi mdi-folder text-warning font-size-18' />
                                <span>{currentLocation.name + "/"}  </span>
                            </div>

                            <Input
                                type="text"
                                className="form-control"
                                id="folderLocation"
                                placeholder=""
                                value={folderLocation}
                                onChange={e => setFolderLocation(e.target.value)}
                            />
                        </div>

                    </div>

                </ModalBody>
                <ModalFooter>
                    <div className="hstack gap-2 ">
                        <button type="button" className="btn btn-success" onClick={createDir}>{t("Valider")}</button>
                        <button type="button" className="btn btn-secondary" onClick={onCloseClick}>{t("Annuler")}</button>
                    </div>
                </ModalFooter>
            </div>
        </Modal>
    )
}

NewFolder.propTypes = {
    onCloseClick: PropTypes.func,
    onDeleteClick: PropTypes.func,
    show: PropTypes.any
}

export default withTranslation()(NewFolder);
