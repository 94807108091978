import { getTime, toLocalDate } from "helpers/to-local-date";
import { map, truncate } from "lodash";
import React, { useState, useMemo, memo, Fragment } from "react"
import { Link } from "react-router-dom";
import { Badge, Card, CardBody, CardHeader } from "reactstrap";
import SimpleBar from "simplebar-react"
import TodoListPlaceHolders from "./placeholders";
import { getInitiales } from "utils";
import { ResourceTypes } from "constants/general";

const LeftarItem = ({ item }) => {


    return <Link> <div className="d-flex justify-content-between">
        <div className="d-flex flex-grow-1 flex-row overflow-hidden">
            {item.sourceIcon ? <span className="me-2 p-1 bg-info bg-opacity-25 rounded-circle ">
                <img className="" width={50} height={50} src={item.sourceIcon} />

            </span>

                : <span className="avatar pt-3 me-2 p-2 bg-info bg-opacity-25 rounded-circle" >
                    <h2>{getInitiales(item.sourceName || "")}</h2>
                </span>
            }
            <div>
                <h5 className="text-truncate text-muted font-size-14 fw-bold mb-1">
                    <Badge color="info" className="me-1 font-size-10">{item.sourceType === ResourceTypes.ASSISTANTS ? "Assistant" : "Vous"}</Badge>
                    {truncate(item.sourceName, { length: 25 })}
                </h5>
                <h5 className="text-truncate text-muted font-size-14 mb-1">
                    {item.name}
                </h5>
                <p className="text-truncate text-muted mb-0">
                    {item.content.slice(0, 200)}
                </p>
            </div>

        </div>
        <div className="font-size-11">
            {getTime(item.createdAt)}
        </div>

    </div>
    </Link>

}


const TodoLeftBar = memo(function TodoLeftBar({ items, selectedItem, onItemSelected, isLoading }) {

    return <React.Fragment>
        {!isLoading &&
            <div className=" leftbar">
                <div className="assistant-leftsidebar-nav">
                    <ul className="list-unstyled todolist-items-list">
                        <SimpleBar className="todoleftBar">
                            {
                                map(items, item => {
                                    const isCurrent = item.id === selectedItem?.id
                                    return <li key={item.id} className={isCurrent ? "bg-info bg-opacity-25 rounded-2" : ""} onClick={() => onItemSelected(item)}>
                                        <LeftarItem item={item} />
                                    </li>
                                })
                            }
                        </SimpleBar>
                    </ul>
                </div>
            </div>}

        {isLoading && <TodoListPlaceHolders />}
    </React.Fragment>
});

export default TodoLeftBar;