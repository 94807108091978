import React, { useEffect, useRef, useCallback } from "react";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";


// //Import Scrollbar
import SimpleBar from "simplebar-react";

// MetisMenu
import MetisMenu from "metismenujs";
import withRouter from "components/Common/withRouter";
import { Link } from "react-router-dom";

//i18n
import { withTranslation } from "react-i18next";

const SidebarContent = props => {
  const { account, permissions, isGA } = useSelector(state => ({
    account: state.Profile.account,
    permissions: state.Profile.permissions,
    isGA: state.Profile.isGA,
  }));

  const ref = useRef();
  const activateParentDropdown = useCallback((item) => {
    item.classList.add("active");
    const parent = item.parentElement;
    const parent2El = parent.childNodes[1];

    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show");
    }

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show"); // ul tag

        const parent3 = parent2.parentElement; // li tag

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add("mm-show"); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add("mm-show"); // li
              parent5.childNodes[0].classList.add("mm-active"); // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false;
    }
    scrollElement(item);
    return false;
  }, []);

  const removeActivation = (items) => {
    for (var i = 0; i < items.length; ++i) {
      var item = items[i];
      const parent = items[i].parentElement;

      if (item && item.classList.contains("active")) {
        item.classList.remove("active");
      }
      if (parent) {
        const parent2El =
          parent.childNodes && parent.childNodes.lenght && parent.childNodes[1]
            ? parent.childNodes[1]
            : null;
        if (parent2El && parent2El.id !== "side-menu") {
          parent2El.classList.remove("mm-show");
        }

        parent.classList.remove("mm-active");
        const parent2 = parent.parentElement;

        if (parent2) {
          parent2.classList.remove("mm-show");

          const parent3 = parent2.parentElement;
          if (parent3) {
            parent3.classList.remove("mm-active"); // li
            parent3.childNodes[0].classList.remove("mm-active");

            const parent4 = parent3.parentElement; // ul
            if (parent4) {
              parent4.classList.remove("mm-show"); // ul
              const parent5 = parent4.parentElement;
              if (parent5) {
                parent5.classList.remove("mm-show"); // li
                parent5.childNodes[0].classList.remove("mm-active"); // a tag
              }
            }
          }
        }
      }
    }
  };

  const path = useLocation();
  const activeMenu = useCallback(() => {
    const pathName = path.pathname;

    let matchingMenuItem = null;
    const ul = document.getElementById("side-menu");
    const items = ul.getElementsByTagName("a");
    removeActivation(items);

    for (let i = 0; i < items.length; ++i) {
      if (pathName === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {

      activateParentDropdown(matchingMenuItem);
    }
  }, [path.pathname, activateParentDropdown]);

  useEffect(() => {
    ref.current.recalculate();
  }, []);

  useEffect(() => {
    new MetisMenu("#side-menu");
    activeMenu();
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    activeMenu();
  }, [activeMenu]);

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop;
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300;
      }
    }
  }

  return (
    <React.Fragment>
      <SimpleBar className="h-100" ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            {/* <li className="menu-title">{props.t("Mon Organisation")} </li>
            <li>
              <Link to="/#" className="has-arrow">
                <i className="bx bx-home-circle"></i>
                <span>{props.t("Cellenza")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/organization/board">{props.t("Tableau de bord")}</Link>
                </li>
                <li>
                  <Link to="/organization/documentation">{props.t("Documentation")}</Link>
                </li>
                <li>
                  <Link to="/organization/contacts">{props.t("Contacts")}</Link>
                </li>
              </ul>
            </li> */}
            {permissions?.workspace && <li className="menu-title">{props.t("Personnel")} </li>}

            {permissions?.workspace && <li>
              <Link to="/#" className="has-arrow">
                <i className="bx bx-task "></i>
                <span>{props.t("Espace de travail")}</span>
              </Link>
              <ul className="sub-menu">
                {/* <li>
                  <Link to="/workspace/board">
                    <i className="bx bx-home-circle text-primary"></i>
                    <span>{props.t("Tableau de bord")}</span>
                  </Link>
                </li> */}
                <li>
                  <Link to="/workspace/todolist">
                    <i className="bx bx-task text-info"></i>
                    <span>{props.t("Mes taches")}</span>
                  </Link>
                </li>

                {/* <li>
                  <Link to="/workspace/documents">
                    <i className="bx bxs-folder text-warning"></i>
                    <span>{props.t("Mes documents")}</span>
                  </Link>
                </li> */}

                <li>
                  <Link to="/workspace/resources">
                    <i className="mdi mdi-database-search"></i>
                    <span>{props.t("Mes ressources")}</span>
                  </Link>
                </li>

                <li>
                  <Link to="/workspace/prompt/store">
                    <i className="mdi mdi-message-reply-text"></i>
                    <span>{props.t("Prompts Store")}</span>
                  </Link>
                </li>


                {/* <li>
                  <Link to="/workspace/skills">
                    <i className="bx bx-briefcase-alt text-danger"></i>

                    <span>{props.t("Mes compétences")}</span>
                  </Link>
                </li> */}
              </ul>
            </li>}

            {permissions?.studio &&
              <li>
                <Link to="/#" className="has-arrow">
                  <i className="mdi mdi-family-tree "></i>
                  <span>{props.t("Jask Studio")}</span>
                </Link>
                <ul className="sub-menu">
                  <li>
                    <Link to="/studio/designer">
                      <i className="bx bx-play font-size-22 text-info"></i>
                      <span>{props.t("Démarrer le studio ")}</span>
                    </Link>
                  </li>
                  {permissions?.application && <li>
                    <Link to="/studio/applications">
                      <i className="bx bx-code  font-size-18  text-info"></i>
                      <span>{props.t("Mes applications")}</span>
                    </Link>
                  </li>}
                  {permissions?.assistant && <li>
                    <Link to="/studio/assistants">
                      <i className="bx bxs-bot font-size-16 text-info"></i>
                      <span>{props.t("Mes assistants")}</span>
                    </Link>
                  </li>}
                </ul>
              </li>
            }

            <li className="menu-title">{props.t("Compléments de services")} </li>
            {permissions?.support && <li>
              <Link to="/#" className="has-arrow">
                <i className="bx bx-support "></i>
                <span>{props.t("Jask Support")}</span>
              </Link>
              <ul className="sub-menu">
                {permissions?.support && <li>
                  <Link to="/addons/support/board">
                    <i className="bx bx-chart font-size-16   text-info"></i>
                    <span>{props.t("Tableau de Bord")}</span>
                  </Link>
                </li>}


                {permissions?.support && <li>
                  <Link to="/addons/support/requests">
                    <i className="bx bx-table font-size-16   text-info"></i>
                    <span>{props.t("Toutes les Demandes")}</span>
                  </Link>
                </li>}

                {permissions?.support && <li>
                  <Link to="/addons/support/platforms">
                    <i className="bx bx-cog font-size-16  text-info"></i>
                    <span>{props.t("Equipes Support")}</span>
                  </Link>
                </li>}
              </ul>
            </li>}

            {permissions?.automation && <li className="menu-title">{props.t("Automatisation")} </li>}

            {permissions?.automation && <li>
              <Link to="/automation/templates" className="">
                <i className="bx bxs-detail" />
                <span>{props.t("Templates")}</span>
              </Link>
            </li>}

            {permissions?.automation && <li>
              <Link to="/automation/scenario" className="">
                <span className="badge rounded-pill bg-success float-end" key="t-new">Bientot</span>
                <i className="mdi mdi-file-tree"></i>
                <span>{props.t("Scénario")}</span>
              </Link>

            </li>}



            {permissions?.admin && <li className="menu-title">{props.t("Administration")} </li>}

            {permissions?.admin && <li>
              <Link to="/#" className="has-arrow">
                <i className="bx bxs-user-circle"></i>
                <span>{props.t("Sécurité")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/admin/security/users" className="">
                    <i className="bx bx-user-circle"></i>
                    <span>{props.t("Utilisateurs")}</span>
                  </Link>
                </li>

                <li>
                  <Link to="/admin/security/groups" className="">
                    <i className="bx  bxs-group"></i>
                    <span>{props.t("Groupes")}</span>
                  </Link>
                </li>

                <li>
                  <Link to="/admin/security/roles" className="">
                    <i className="bx  bxs-group"></i>
                    <span>{props.t("Roles et usages")}</span>
                  </Link>
                </li>
              </ul>
            </li>}

            {permissions?.datasources && <li>
              <Link to="/#" className="has-arrow">
                <i className="mdi mdi-database-cog-outline"></i>
                <span>{props.t("Sources de données")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/admin/datasources/add" className="">
                    <i className="mdi mdi-database-plus"></i>
                    <span>{props.t("Nouvelle source")}</span>
                  </Link>
                </li>

                <li>
                  <Link to="/admin/datasources" className="">
                    <i className="mdi mdi-database-search-outline"></i>
                    <span>{props.t("Consulter la liste")}</span>
                  </Link>
                </li>
              </ul>
            </li>}

            {permissions?.deployment && <li>
              <Link to="/#" className="has-arrow">
                <i className="mdi mdi-rocket-launch"></i>
                <span>{props.t("Déploiement")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/admin/deployment/add" className="">
                    <i className="mdi mdi-plus-box-outline"></i>
                    <span>{props.t("Nouveau")}</span>
                  </Link>
                </li>

                <li>
                  <Link to="/admin/deployment" className="">
                    <i className="mdi mdi-database-search-outline"></i>
                    <span>{props.t("Consulter la liste")}</span>
                  </Link>
                </li>
              </ul>
            </li>}

            {permissions?.usage && <li className="menu-title">{props.t("Surveillance")} </li>}

            {permissions?.usage && <li>
              <Link to="/#" className="has-arrow">
                <i className="mdi mdi-rocket-launch"></i>
                <span>{props.t("Monitoring")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/monitoring" className="">
                    <i className="mdi mdi-chart-bar"></i>
                    <span>{props.t("Suivi des usages")}</span>
                  </Link>
                </li>

                <li>
                  <Link to="/feedbacks" className="">
                    <i className="mdi mdi-message"></i>
                    <span>{props.t("Suivi des feedbacks")}</span>
                  </Link>
                </li>
              </ul>
            </li>}

            {permissions?.usage && <li>

            </li>}

            {/* <li className="menu-title">{props.t("Corporate")}</li> */}

            {/* <li>
              <Link to="/#" className="has-arrow">
                <i className="bx bx-receipt"></i>
                <span>{props.t("CRM")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/crm/board">
                    {props.t("Tableau de bord")}</Link>
                </li>
                <li>
                  <Link to="/crm/opportunities">
                    {props.t("Opportunités")}
                  </Link>
                </li>
                <li>
                  <Link to="/crm/offers">
                    {props.t("Nos offres")}
                  </Link>
                </li>
                <li>
                  <Link to="/crm/contacts">
                    {props.t("Contacts")}</Link>
                </li>
              </ul>
            </li> */}
            {/* 
            <li>
              <Link to="/#" className="has-arrow ">
                <i className="bx bx-briefcase-alt"></i>
                <span>{props.t("RH Admin")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/hr/board">{props.t("Tableau de bord")}</Link>
                </li>
                <li>
                  <Link to="/hr/hiring">{props.t("Recrutement")}</Link>
                </li>
                <li>
                  <Link to="/hr/onboarding">{props.t("Onboarding")}</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="has-arrow ">
                <i className="bx bx-envelope"></i>
                <span>{props.t("Operations")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/operations/board">{props.t("Tableau de bord")}</Link>
                </li>
                <li>
                  <Link to="/operations/staffing">{props.t("Staffing Manager")} </Link>
                </li>
                <li>
                  <Link to="/operations/projects">{props.t("Nos Réalisations")} </Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="has-arrow ">
                <i className="bx bx-receipt"></i>
                <span>{props.t("Facturation")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/invoices/board">{props.t("Tableau de bord")}</Link>
                </li>
                <li>
                  <Link to="/invoices/items">{props.t("Facturation")}</Link>
                </li>
                <li>
                  <Link to="/invoices/contacts">{props.t("Fiche Clients")}</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="has-arrow ">
                <i className="bx bx-briefcase-alt-2"></i>
                <span>{props.t("Juridique")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/legal/board">{props.t("Tableau de bord")}</Link>
                </li>
                <li>
                  <Link to="/legal/contracts">{props.t("Contractualisation")}</Link>
                </li>

              </ul>
            </li> */}





            {/* <li className="menu-title">Administration</li>
            <li>
              <Link to="/#" className="has-arrow">
                <i className="bx bx-user-circle"></i>
                <span>{props.t("Authentication")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="#">{props.t("Login")}</Link>
                </li>
                <li>
                  <Link to="#">{props.t("Login 2")}</Link>
                </li>
                <li>
                  <Link to="#">{props.t("Register")}</Link>
                </li>
                <li>
                  <Link to="#">{props.t("Register 2")}</Link>
                </li>
                <li>
                  <Link to="#">
                    {props.t("Recover Password")}
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    {props.t("Recover Password 2")}
                  </Link>
                </li>
                <li>
                  <Link to="#">{props.t("Lock Screen")}</Link>
                </li>
                <li>
                  <Link to="#">
                    {props.t("Lock Screen 2")}
                  </Link>
                </li>
                <li>
                  <Link to="#">{props.t("Confirm Mail")}</Link>
                </li>
                <li>
                  <Link to="#">
                    {props.t("Confirm Mail 2")}
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    {props.t("Email Verification")}
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    {props.t("Email Verification 2")}
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    {props.t("Two Step Verification")}
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    {props.t("Two Step Verification 2")}
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              <Link to="/#" className="has-arrow ">
                <i className="bx bx-file"></i>
                <span>{props.t("Utility")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="#">{props.t("Starter Page")}</Link>
                </li>
                <li>
                  <Link to="#">{props.t("Maintenance")}</Link>
                </li>
                <li>
                  <Link to="#">{props.t("Coming Soon")}</Link>
                </li>
                <li>
                  <Link to="#">{props.t("Timeline")}</Link>
                </li>
                <li>
                  <Link to="#">{props.t("FAQs")}</Link>
                </li>
                <li>
                  <Link to="#">{props.t("Pricing")}</Link>
                </li>
                <li>
                  <Link to="#">{props.t("Error 404")}</Link>
                </li>
                <li>
                  <Link to="#">{props.t("Error 500")}</Link>
                </li>
              </ul>
            </li> */}

          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  );
};

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(SidebarContent));
