import { map } from "lodash"
import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import classnames from "classnames"

import {
    Card,
    CardBody,
    Collapse,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    UncontrolledAlert,
    UncontrolledDropdown,
    Nav, NavItem, NavLink, TabContent, TabPane,
    UncontrolledTooltip,
    Dropdown
} from "reactstrap"
import RecentFile from "./recentFiles"
import { uuidv4 } from "utils"
import DocumentEditor from "../editor"
import TemplateList from "../templates/list"
import DocumentGeneration from "../templates/generation/document"
import FileSystem from "./list"

const FilesBrowser = ({ handleAction, onAddTemplate }) => {

    const [activeTab, setactiveTab] = useState('recent');
    const [customTabs, setCustomTabs] = useState([]);
    const [openAddOptions, setOpenAddOptions] = useState(false);

    const createDocument = (type, title, data, icon) => {
        const id = uuidv4();
        setCustomTabs([...customTabs, {
            id: id,
            title: title,
            type: type,
            data: data,
            icon: icon
        }])

        setactiveTab(id);
    }

    const GetDocumentType = (tab) => {

        switch (tab.type) {

            case 'recent':
                return <RecentFile />

            case 'documents':
                return <FileSystem />

            case 'generateFromTemplate':
                return <DocumentGeneration context={tab.data} />
            case 'template':
                return <TemplateList onAddTemplate={onAddTemplate} onGenerateDocument={ctx => createDocument('generateFromTemplate', 'Nouveau document', ctx)} />
            default:
                return <DocumentEditor />
        }
    }


    const removeDocument = (id, index) => {
        const remaining = customTabs.filter(tab => tab.id !== id);
        setCustomTabs([...remaining]);

        if (remaining.length > 0) {

            if (activeTab === id) {
                const newIndex = remaining[index - 1].id;
                setactiveTab(newIndex);
            }
            else {

            }
        }

    }

    useEffect(() => {
        setCustomTabs([{
            id: 'recent',
            type: 'recent'
        }])
    }, [])

    useEffect(() => {
        if (handleAction) {
            createDocument(handleAction.type, handleAction.title, handleAction.data, handleAction.icon)
        }
    }, [handleAction])


    return (
        <React.Fragment>
            <div className="workspace-documents me-2">
                <Nav tabs>

                    {map(customTabs, (item, index) => {
                        return item.id === 'recent' ? (<NavItem key={uuidv4()}>
                            <NavLink

                                className={classnames({ active: activeTab === 'recent' })}
                                onClick={() => {
                                    setactiveTab('recent')
                                }}
                            >
                                <i className="mdi mdi-folder text-danger align-middle me-1" />
                                Fichiers recents

                                {customTabs.length > 1 && <Link className="ms-2">
                                    <i className="mdi mdi-close text-danger" onClick={() => removeDocument(item.id, 0)} />
                                </Link>}
                            </NavLink>
                        </NavItem>) : (<NavItem key={uuidv4()}>
                            <NavLink className={classnames({ active: activeTab === item.id })}
                            >
                                <span onClick={() => {
                                    activeTab === item.id ? console.log("Nothing") : setactiveTab(item.id)
                                }}><i className={item.icon} />{item.title}</span>
                                <Link className="ms-2" onClick={() => removeDocument(item.id, index)}>
                                    <i className="mdi mdi-close text-danger" />
                                </Link>
                            </NavLink>
                        </NavItem>)
                    })}

                    <NavItem key={uuidv4()}>

                        <Dropdown
                            className="mt-0 mt-sm-0 add-options"
                            isOpen={openAddOptions}
                            toggle={() => setOpenAddOptions(!openAddOptions)}
                        >
                            <Link className="ms-2" onClick={() => setOpenAddOptions(!openAddOptions)}>
                                <i className="mdi mdi-plus text-info font-size-24" />
                            </Link>

                            <DropdownMenu>
                                <div className="dropdown-header noti-title text-center ">
                                    <h6 className="font-size-12 text-muted text-truncate mn-0 mt-2">Type de document</h6>
                                </div>
                                <div className="dropdown-divider" />
                                <DropdownItem> Un compte rendu</DropdownItem>
                                <div className="dropdown-divider" />
                                <DropdownItem> Un article media</DropdownItem>
                                <div className="dropdown-divider" />
                                <DropdownItem> Nouveau contrat </DropdownItem>
                                <div className="dropdown-divider" />
                                <DropdownItem onClick={() => createDocument("others", "Nouveau document")}>Autre document</DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
                    </NavItem>


                </Nav>
                <div className="filesView">
                    <TabContent activeTab={activeTab} className="mt-4">

                        {
                            map(customTabs, item => {
                                return <TabPane key={item.id} tabId={item.id} >
                                    {GetDocumentType(item)}
                                </TabPane>
                            })
                        }
                    </TabContent>
                </div>
            </div>
        </React.Fragment>
    )
}

export default FilesBrowser
