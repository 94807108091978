import React, { useEffect, useState } from "react"
import ReactApexChart from "react-apexcharts";
import { Card, CardBody, CardTitle, Col, Row } from "reactstrap";
import { truncateFromMiddle } from "utils";

const PieChart = ({ id, labels, values, title, height, onClose }) => {
    const options = {
        labels: labels,
        legend: {
            show: 1,
            width: 120,
            horizontalAlign: "right"


        },
        plotOptions: {
            pie: {
                donut: {
                    size: "60%",
                },
            },
        },
    };
    return <Card className="border h-100">
        <CardTitle className="border-bottom">
            <div className="d-flex justify-content-between p-1 bg-info bg-opacity-25">
                <h5 className="text-muted">{title}</h5>
                <button onClick={onClose} className="btn-close"></button>
            </div>
        </CardTitle>
        <div id=" h-100">
            <ReactApexChart
                options={options}
                series={values}
                type="donut"
                // height={height || 350}
                width={350}
                className="apex-charts"
            />
        </div>
    </Card>
}

export default PieChart;