import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import TableContainer from 'components/Common/TableContainer';

//import components
import DeleteModal from 'components/Common/DeleteModal';

import {
    getGroups,
    getUsers
} from "store/actions";

import {
    Name, Role, SelectUser, Date, Members, GroupName
} from "./columns";

//redux
import { useSelector, useDispatch } from "react-redux";

import {
    Card,
    CardBody
} from "reactstrap";
import AddUser from "../add";

function GroupListView() {

    const [modal, setModal] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [singlebtn, setSinglebtn] = useState(false)
    const [canDeploy, setCanDeploy] = useState(true)
    const [Datasource, setDatasource] = useState(null);
    const [datasourcesToMonitor, setDatasourcesToMonitor] = useState([]);
    const [watcherId, setWatcherId] = useState(null);

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { groups, isLoading } = useSelector(state => ({
        groups: state.Users.groups,
        isLoading: state.Users.isLoading,

    }));

    const toggle = () => {
        if (modal) {
            setModal(false);
        } else {
            setModal(true);
        }
    };

    //delete Datasource
    const [deleteModal, setDeleteModal] = useState(false);

    const onClickDelete = (Datasource) => {
        setDatasource(Datasource);
        setDeleteModal(true);
    };

    const handleDeleteUser = () => {

    };

    const handleAdduser = () => {
        toggle();
    };

    const columns = useMemo(
        () => [
            {
                Header: '',
                accessor: 'id',
                disableFilters: true,
                Cell: (cellProps) => {
                    return <SelectUser {...cellProps} />;
                }
            },
            {
                Header: 'Nom du groupe',
                accessor: 'name',
                disableFilters: true,
                Cell: (cellProps) => {
                    return <GroupName {...cellProps} />;
                }
            },
            {
                Header: 'crée le ',
                accessor: 'createdAt',
                disableFilters: true,
                Cell: (cellProps) => {
                    return <Date {...cellProps} />;
                }
            },

            {
                Header: 'Membres',
                accessor: 'members',
                disableFilters: true,
                Cell: (cellProps) => {
                    return <Members {...cellProps} />;
                }
            },
            {
                Header: 'Role',
                accessor: 'roles',
                disableFilters: true,
                Cell: (cellProps) => {
                    return <Role {...cellProps} />;
                }
            }
        ],
        []
    );

    const onRefresh = () => {
        dispatch(getGroups())
    }

    useEffect(() => {
        onRefresh();
    }, [dispatch]);


    return (
        <React.Fragment>
            <DeleteModal
                show={deleteModal}
                onDeleteClick={handleDeleteUser}
                onCloseClick={() => setDeleteModal(false)}
            />
            <AddUser isOpen={modal} onCloseClick={toggle} isEdit={isEdit} />
            <Card className="datasources-panel">
                <CardBody>
                    <TableContainer
                        columns={columns}
                        data={groups || []}
                        isGlobalFilter={true}
                        isAddOptions={false}
                        handleAddClicks={handleAdduser}
                        addButtonText="Nouveau"
                        isDatasourceListGlobalFilter={false}
                        isGenericAdd={true}
                        customPageSize={10}
                        onRefresh={onRefresh}
                    />
                </CardBody>
            </Card>
        </React.Fragment>
    );
}


export default GroupListView;