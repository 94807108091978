import { call, put, takeEvery, take } from "redux-saga/effects"
import { channel } from 'redux-saga'
import { fetchEventSource } from "@microsoft/fetch-event-source";
import { API_URL } from "helpers/api_helper";
import { ASK_ASSISTANT, ON_ASSISTANT_REPLY, } from "./actionTypes"
import { onAssistantReply } from "./actions"
import { } from "helpers/backend_helper"
import { onAssistantStateChanged } from "store/actions";
import { EventTypes } from "constants/events";


function* askAssistant({ payload }) {
  const assistantStreamChannel = channel();

  try {
    onSSE(payload, assistantStreamChannel);
    while (true) {
      const action = yield take(assistantStreamChannel);
      yield put(action);
      yield put(onAssistantStateChanged({
        id: payload.channelId,
        updatedAt: new Date()
      }));

    }
  } catch (error) {

  }
}

function onSSE(payload, channel) {
  fetchEventSource(`${API_URL}/assistant/ask/sse`,
    {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${payload.token}`,
      },
      body: JSON.stringify(payload.message),
      signal: payload.cancellationToken.signal,
      openWhenHidden: true,
      onopen(res) {
        if (res.ok && res.status === 200) {
          console.log("Connection made ", res);
        } else if (
          res.status >= 400 &&
          res.status < 500 &&
          res.status !== 429
        ) {
          console.log("Client side error ", res);
        }
      },
      onmessage(event) {
        try {
          channel.put(onAssistantReply({
            ...payload,
            event: JSON.parse(event.data)
          }));

        } catch (err) {
          console.log(err)
        }

      },
      onclose() {
        console.log("Done!");
      },
      onerror(err) {

        channel.put(onAssistantReply({
          ...payload,
          event: {
            type: EventTypes.ERROR,
            value: err
          }
        }));

        throw err
      },
    });

}


function* streamSaga() {
  yield takeEvery(ASK_ASSISTANT, askAssistant)
}

export default streamSaga;