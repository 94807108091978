import { GET_CONVERSATIONS_SUCCESS, GET_CONVERSATIONS_FAILED, GET_CONVERSATION_HISTORY_SUCCESS, CREATE_CONVERSATION_SUCCESS, UPDATE_CONVERSATION_TITLE, ADD_MESSAGE_HISTORY, GET_CONVERSATION_HISTORY, DELETE_CONVERSATION, DELETE_CONVERSATION_SUCCESS, GET_DATA_SERVICES, GET_DATA_SERVICES_SUCCESS, SEARCH_RESOURCES, SEARCH_RESOURCES_SUCCESS, SEARCH_RESOURCES_FAILED, GET_AI_MODELS, GET_AI_MODELS_SUCCESS, GET_AI_MODELS_FAILED } from "./actionTypes"

const INIT_STATE = {
  conversations: [],
  history: [],
  error: {},
  loadingHistory: false,
  newConversation: null,
  latestConversationId: null,
  conversationsHistory: {},
  dataServices: [],
  usefulResources: [],
  searchProviderId: null,
  isWorking: false,
  models: []
}

const assistant = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_CONVERSATION_HISTORY:

      return {
        ...state,
        loadingHistory: true,
        latestConversationId: action.payload
      }

    case CREATE_CONVERSATION_SUCCESS:
      return {
        ...state,
        conversations: [action.payload, ...state.conversations],
        newConversation: action.payload
      }

    case GET_CONVERSATIONS_SUCCESS:
      return {
        ...state,
        conversations: action.payload,
      }

    case GET_CONVERSATION_HISTORY_SUCCESS:
      const historyCache = { ...state.conversationsHistory }
      historyCache[state.latestConversationId] = action.payload;
      return {
        ...state,
        history: action.payload,
        loadingHistory: false,
        conversationsHistory: { ...historyCache }
      }

    case GET_CONVERSATIONS_FAILED:
      return {
        ...state,
        error: action.payload,
      }

    case UPDATE_CONVERSATION_TITLE:

      state.conversations.forEach(conv => {
        if (conv.id === action.payload.id) {
          conv.title = action.payload.title
        }
      })

      return state;

    case ADD_MESSAGE_HISTORY:
      const others = state.conversations.filter(c => c.id !== action.payload.conversationId)
      const conversation = state.conversations.filter(c => c.id === action.payload.conversationId)[0];
      conversation.lastMessage = action.payload.message?.text;

      return {
        ...state,
        conversations: [conversation, ...others],
        history: [...state.history, action.payload.message],
      }



    case DELETE_CONVERSATION:
    case DELETE_CONVERSATION_SUCCESS:
      return {
        ...state,
        conversations: state.conversations.filter(c => c.id !== action.payload)
      }


    case GET_DATA_SERVICES:
      return {
        ...state,
        isWorking: true
      }
    case SEARCH_RESOURCES:
      return {
        ...state,
        isWorking: true,
        usefulResources: [],
        searchProviderId: action.payload.provider
      }

    case GET_DATA_SERVICES_SUCCESS:
      return {
        ...state,
        isWorking: false,
        dataServices: action.payload
      }

    case SEARCH_RESOURCES_SUCCESS:
      return {
        ...state,
        isWorking: false,
        usefulResources: action.payload
      };

    case SEARCH_RESOURCES_FAILED:
      return {
        ...state,
        isWorking: false,
        usefulResources: [],
        searchProviderId: null
      };

    case GET_AI_MODELS:
      return {
        ...state,
        isWorking: true
      }

    case GET_AI_MODELS_SUCCESS:

      return {
        ...state,
        isWorking: false,
        models: action.payload
      }

    case GET_AI_MODELS_FAILED:

      return {
        ...state,
        isWorking: false,
        error: action.payload
      }

    default:
      return state
  }
}

export default assistant