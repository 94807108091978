import React, { Fragment, useEffect, useMemo, useState } from "react"
import { Modal, Spinner } from "reactstrap"
import { pdfjs, Document, Page } from 'react-pdf';
import { isEmpty, map } from "lodash";
import { downloadFile } from "service/documents";


// pdfjs.GlobalWorkerOptions.workerSrc = new URL(
//     'pdfjs-dist/build/pdf.worker.min.js',
//     import.meta.url,
// ).toString();

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;


import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';


const PdfViewer = ({ location, owner, page, pages, width, height, providerId, providerType }) => {

    const [data, setData] = useState(null);

    const onLoadSuccess = () => {
    }

    useEffect(() => {

        if (isEmpty(location))
            return;

        // if (location.startsWith("http")) {
        //     setData({
        //         url: location

        //     })
        // } else {
        downloadFile(location, owner, providerId, providerType).then(resp => {
            setData({
                url: resp
            });
        })
        // }
    }, [location]);


    return <div className="d-flex justify-content-center">
        <div>
        </div>
        <Document
            onLoadStart={() => console.log("Loading start")}
            onLoadSuccess={onLoadSuccess}
            noData={<div>Ouverture en cours...</div>}
            error={<p></p>}
            loading={<Spinner className="p-4" size={"sm"}></Spinner>}
            pageLayout="oneColumn"


            // onLoadProgress={console.log}
            file={data} >

            {isEmpty(pages) ? (<Page pageNumber={page || 1}
                width={width}
                height={height}
                size="A4"
            />) : (map(pages, number => {
                return <Page pageNumber={number}
                    width={width}
                    height={height}
                    size="A4"
                />
            }))}


        </Document>
        {/* {data && <object width="100%" height="400" data={data} type="application/pdf">   </object>} */}
    </div>
}

export default PdfViewer;