import {
  GET_USER_PROFILE,
  GET_USER_PROFILE_FAIL,
  GET_USER_PROFILE_SUCCESS,
  GET_USERS,
  GET_USERS_FAIL,
  GET_USERS_SUCCESS,
  ADD_NEW_USER,
  ADD_USER_SUCCESS,
  ADD_USER_FAIL,
  UPDATE_USER,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAIL,
  DELETE_USER,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAIL,
  GET_GROUPS,
  GET_GROUPS_SUCCESS,
  GET_GROUPS_FAILED,
  DELETE_GROUP,
  ADD_GROUP,
  ADD_GROUP_SUCCESS,
  ADD_GROUP_FAILED,
  ADD_ROLE,
  ADD_ROLE_SUCCESS,
  ADD_ROLE_FAILED,
  GET_ROLES,
  GET_ROLES_SUCCESS,
  GET_ROLES_FAILED,
  DELETE_ROLE,
  DELETE_ROLE_SUCCESS,
  DELETE_ROLE_FAILED,
} from "./actionTypes"

export const getUsers = () => ({
  type: GET_USERS,
})

export const getUsersSuccess = users => ({
  type: GET_USERS_SUCCESS,
  payload: users,
})

export const addNewUser = user => ({
  type: ADD_NEW_USER,
  payload: user,
})

export const addUserSuccess = user => ({
  type: ADD_USER_SUCCESS,
  payload: user,
})

export const addUserFail = error => ({
  type: ADD_USER_FAIL,
  payload: error,
})

export const getUsersFail = error => ({
  type: GET_USERS_FAIL,
  payload: error,
})

export const getUserProfile = () => ({
  type: GET_USER_PROFILE,
})

export const getUserProfileSuccess = userProfile => ({
  type: GET_USER_PROFILE_SUCCESS,
  payload: userProfile,
})

export const getUserProfileFail = error => ({
  type: GET_USER_PROFILE_FAIL,
  payload: error,
})

export const updateUser = user => ({
  type: UPDATE_USER,
  payload: user,
})

export const updateUserSuccess = user => ({
  type: UPDATE_USER_SUCCESS,
  payload: user,
})

export const updateUserFail = error => ({
  type: UPDATE_USER_FAIL,
  payload: error,
})

export const deleteUser = user => ({
  type: DELETE_USER,
  payload: user,
})

export const deleteUserSuccess = user => ({
  type: DELETE_USER_SUCCESS,
  payload: user,
})

export const deleteUserFail = error => ({
  type: DELETE_USER_FAIL,
  payload: error,
})



export const getGroups = () => ({
  type: GET_GROUPS,
})

export const getGroupsSuccess = (data) => ({
  type: GET_GROUPS_SUCCESS,
  payload: data
})

export const getGroupsFailed = () => ({
  type: GET_GROUPS_FAILED,
})


export const deleteGroup = (id) => ({
  type: DELETE_GROUP,
  payload: id
})

export const deleteGroupSuccess = (data) => ({
  type: DELETE_GROUP,
  payload: data
})

export const deleteGroupFailed = (data) => ({
  type: DELETE_GROUP,
  payload: data
})

export const addGroup = (data) => ({
  type: ADD_GROUP,
  payload: data
})

export const addGroupSuccess = (data) => ({
  type: ADD_GROUP_SUCCESS,
  payload: data
})

export const addGroupFailed = (error) => ({
  type: ADD_GROUP_FAILED,
  payload: error
})


export const addRole = (data) => ({
  type: ADD_ROLE,
  payload: data
})
export const addRoleSuccess = (data) => ({
  type: ADD_ROLE_SUCCESS,
  payload: data
})

export const addRoleFailed = (data) => ({
  type: ADD_ROLE_FAILED,
  payload: data
})

export const getRoles = () => ({
  type: GET_ROLES,
})

export const getRolesSuccess = (data) => ({
  type: GET_ROLES_SUCCESS,
  payload: data
})

export const getRolesFailed = (error) => ({
  type: GET_ROLES_FAILED,
  payload: error
})

export const deleteRole = (id) => ({
  type: DELETE_ROLE,
  payload: id

})

export const deleteRoleSuccess = (data) => ({
  type: DELETE_ROLE_SUCCESS,
  payload: data

})

export const deleteRoleFailed = (error) => ({
  type: DELETE_ROLE_FAILED,
  payload: error

})
