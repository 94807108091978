import { isEmpty, map, truncate } from "lodash";
import React, { memo, useEffect, useRef, useState } from "react";
import { Badge, Card, CardBody, CardTitle, Table } from "reactstrap";
import SimpleBar from "simplebar-react";
import Styles from "./styles.css"
import { Link } from "react-router-dom";
import LinkPreview from "components/LinkPreview";
import { toLocalDate } from "helpers/to-local-date";
import { uniqueFilter } from "utils";
import { withTranslation } from "react-i18next";

const InternetReferences = ({ references, size, t }) => {

    const [questions, setQuestions] = useState([]);
    const [sources, setSources] = useState([]);


    useEffect(() => {
        if (isEmpty(references))
            return;


        const distinctSources = {}

        references.forEach(ref => {
            if (!(ref.location in distinctSources))
                distinctSources[ref.location] = ref
        });

        const best = Object.values(distinctSources).slice(0, 5);
        setSources(best);



        const frequentQuestion = references.filter(ref => !isEmpty(ref.webContent?.sourceQuery)).map(ref => ref.webContent?.sourceQuery).filter(uniqueFilter);
        setQuestions(frequentQuestion);

    }, [references])
    return <div className="internet"  >
        <div className="d-flex flex-row flex-wrap">
            {
                map(sources, item => {
                    const preview = item.webContent?.preview
                    return <   a href={item.location} target="_blank" rel="noreferrer">
                        <Card className="resource text-dark m-1 border border-secondary border-opacity-25 bg-secondary bg-opacity-10 rounded-3">
                            <div className="hstack gap-1">
                                {preview?.imageUrl && <img className="border rounded-4 p-2" src={preview?.imageUrl} width={80} height={80} />
                                }
                                <div className={!preview?.imageUrl ? "p-2" : ""}>
                                    <CardTitle className="title text-break fw-bold text-lowercase">
                                        {truncate(item.name?.trim(), { length: preview?.imageUrl ? 40 : 80 })}
                                    </CardTitle>
                                    <span className="source text-lowercase">{truncate(preview?.sietName || item.webContent?.hostUrl, { length: preview?.imageUrl ? 15 : 30 })}</span>
                                </div>
                            </div>

                        </Card></a>
                })
            }

        </div>

        <div className="mt-4">
            {questions?.length > 0 && <Link><i className="mdi mdi-information" /> {t("D'autres utilisateurs ont également recherché")}:</Link>
            }            <ul className="mt-1">
                {
                    map(questions.slice(0, 5), question => {
                        return <Link>
                            <li>{question}</li>
                        </Link>
                    })
                }
            </ul>
        </div>

    </div >
}

export default withTranslation()(InternetReferences);
