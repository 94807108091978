import { isEmpty } from "lodash";
import React, { Fragment, useEffect, useState } from "react"
import Select, { components } from 'react-select'
import { Alert, Card, CardBody, CardFooter, Label, UncontrolledAlert } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import { getModels } from "store/actions";

const types = [
    {
        label: "Chacun des fichiers",
        value: "separate",
        description: 'Les instructions seront appliquées sur chaque fichier de manière itérative.'
    },
    {
        label: "Tous les fichiers", value: "combine",
        description: 'Les instructions seront appliquées l\' ensemble des fichiers en une fois.'

    },
]

const taskType = [
    {
        label: "Immediatement",
        value: "stream",
        description: 'Les instructions seront appliquées sur chaque fichier de manière itérative.'
    },
    {
        label: "Me prévenir quand c'est terminé",
        value: "background",
        description: 'Les instructions seront appliquées l\' ensemble des fichiers en une fois.'

    },
]

const ExecutionLandingPage = ({ app, onChange }) => {

    const [selectedTaskType, setSelectedTaskType] = useState(taskType[0]);
    const [instructions, setInstructions] = useState(null)
    const [selectedModel, setSelectedModel] = useState(null)
    const [processingType, setProcessingType] = useState(null);
    const [isCustom, setIsCustom] = useState(false);
    const [availableModels, setAvailableModels] = useState(false);

    const dispatch = useDispatch();

    const { models } = useSelector(state => ({
        models: state.Assistant.models
    }));


    useEffect(() => {
        setIsCustom(isEmpty(app));
    }, [app])

    useEffect(() => {

        const isValid = !isEmpty(selectedTaskType?.value) && !isEmpty(processingType?.value);

        onChange({

            task: selectedTaskType.value,
            instructions: instructions,
            model: selectedModel?.value,
            processingType: processingType?.value,
            isValid: isCustom ? isValid && !isEmpty(selectedModel?.value) && !isEmpty(instructions) : isValid

        });

    }, [selectedTaskType, instructions, selectedModel, processingType]);

    useEffect(() => {

        if (isEmpty(models)) {
            dispatch(getModels());
        }

    }, [dispatch]);

    useEffect(() => {

        setAvailableModels(models?.map(m => ({
            value: m.id,
            label: m.name,
            description: m.description
        })));

    }, [models])

    return <Card>
        <CardBody>
            {isCustom && <div className="mb-4 border p-4">
                <div>
                    <Label> Instructions: </Label>
                    <textarea
                        className="form-control"
                        rows={5}
                        placeholder=""
                        value={instructions}
                        onChange={e => setInstructions(e.target.value)}
                    />
                </div>
                <div className="mt-4">

                    <Label> Modèles d'IA à utiliser: </Label>
                    <Select
                        className="select2-selection"
                        options={availableModels}
                        value={selectedModel}
                        onChange={setSelectedModel}
                    >
                    </Select>
                </div>

            </div>}




            <div className=" border p-4">
                {!isCustom &&
                    <div>

                        <Label> Executer cette application sur : </Label>
                        <Select
                            className="select2-selection"
                            options={types}
                            value={processingType}
                            onChange={setProcessingType}
                        >
                        </Select>

                    </div>

                }

                {isCustom && <div >
                    <Label> Appliquer les instructions sur : </Label>
                    <Select
                        className="select2-selection"
                        options={types}
                        value={processingType}
                        onChange={setProcessingType}
                    >

                    </Select>
                </div>}



                <div className="mt-4">

                    <Label>Executer cette tache : </Label>
                    <Select
                        className="select2-selection"
                        options={taskType}
                        value={selectedTaskType}
                        onChange={setSelectedTaskType}
                    >

                    </Select>
                </div>

            </div>

        </CardBody>
    </Card >
}

export default ExecutionLandingPage;