import React, { useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { isEmpty } from "lodash";
import TableContainer from 'components/Common/TableContainer';

//import components
import Breadcrumbs from 'components/Common/Breadcrumb';
import DeleteModal from 'components/Common/DeleteModal';

import {
    getDatasourceList as onGetDatasourceList,
    deleteDatasourceList as onDeleteDatasourceList,
    createOmniChatConversation,
    showRightSidebarAction
} from "store/actions";

import {
    DatasourceName, Type, Scope, DatasourceLocation, Status, SelectDatasourceAction, FullName
}
    from "./columns";

//redux
import { useSelector, useDispatch } from "react-redux";

import {
    Col,
    Row,
    UncontrolledTooltip,
    Card,
    CardBody,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem
} from "reactstrap";
import DeployAssistant from "pages/Workspace/assistant/deploy";
import { browseDatasource, indexDatasource } from "service/datasources";
import DatasourceBrowser from "components/Common/DatasourceBrowser";
import { ResourceTypes } from "constants/general";

function DatasourceListView() {

    const [modal, setModal] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [singlebtn, setSinglebtn] = useState(false)
    const [canDeploy, setCanDeploy] = useState(true)
    const [DatasourcesList, setDatasourcesList] = useState([]);
    const [Datasource, setDatasource] = useState(null);
    const [datasourcesToMonitor, setDatasourcesToMonitor] = useState([]);
    const [showAddAssistant, setShowAddAssistant] = useState(false);
    const [showDatasourceBrowser, setShowDatasourceBrowser] = useState(false);

    //delete Datasource
    const [deleteModal, setDeleteModal] = useState(false);

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { Datasources, checkIndexing } = useSelector(state => ({
        Datasources: state.Datasources.datasources,
        checkIndexing: state.Datasources.checkIndexing,
    }));

    const startOmnichat = (datasource) => {

        const payload = {
            id: datasource.id,
            name: datasource.name,
            datasourceId: datasource.id,
            description: datasource.description,
        }

        dispatch(createOmniChatConversation(payload));
        dispatch(showRightSidebarAction({
            type: ResourceTypes.ASSISTANTS,
            conversation: payload
        }));
    }
    const handleAssistantCreation = (datasource) => {
        setDatasource(datasource);
        setShowAddAssistant(true);
    }



    useEffect(() => {
        if (isEmpty(Datasources)) {
            dispatch(onGetDatasourceList());
        }
    }, [dispatch]);

    useEffect(() => {
        setDatasourcesList(Datasources);
    }, [Datasources]);


    useEffect(() => {
        if (!isEmpty(Datasources)) {
            setDatasourcesList(Datasources);
        }

    }, [Datasources]);

    const toggle = () => {
        if (modal) {
            setModal(false);
            setDatasource(null);
        } else {
            setModal(true);
        }
    };

    const refresh = () => {
        dispatch(onGetDatasourceList());
    }


    const handleDatasourceClick = arg => {
        const Datasource = arg;
        setDatasource({
            id: Datasource.id,
            DatasourceId: Datasource.DatasourceId,
            DatasourceTitle: Datasource.DatasourceTitle,
            companyName: Datasource.companyName,
            location: Datasource.location,
            experience: Datasource.experience,
            position: Datasource.position,
            type: Datasource.type,
            status: Datasource.status,
        });

        setIsEdit(true);

        toggle();
    };



    const indexSource = (source) => {
        indexDatasource(source.provider.type, source.id)
    }

    const browseSource = (source) => {
        setDatasource(source);
        setShowDatasourceBrowser(!showDatasourceBrowser);


    }

    const onClickDelete = (Datasource) => {
        setDatasource(Datasource);
        setDeleteModal(true);
    };

    const handleDeleteDatasource = () => {
        if (Datasource && Datasource.id) {
            dispatch(onDeleteDatasourceList(Datasource.id));
            setDeleteModal(false);
        }
    };
    const handleDatasourceClicks = () => {
        navigate("/admin/datasources/add")
    };

    const columns = useMemo(
        () => [
            {
                Header: '',
                accessor: 'id',
                disableFilters: true,
                Cell: (cellProps) => {
                    return <SelectDatasourceAction {...cellProps} />;
                }
            },
            {
                Header: 'Nom',
                accessor: 'name',
                disableFilters: true,
                Cell: (cellProps) => {
                    return <DatasourceName {...cellProps} />;
                }
            },
            {
                Header: 'Type de Fournisseur',
                accessor: 'provider.type',
                disableFilters: true,
                Cell: (cellProps) => {
                    return <DatasourceLocation {...cellProps} />;
                }
            },
            {
                Header: 'Crée par',
                accessor: 'principalName',
                disableFilters: true,
                Cell: (cellProps) => {
                    return <FullName {...cellProps} />;
                }
            },
            {
                Header: 'Confidentialité',
                accessor: 'scope',
                disableFilters: true,
                Cell: (cellProps) => {
                    return <Scope {...cellProps} />;
                }
            },
            {
                Header: 'Status',
                accessor: 'status',
                disableFilters: true,
                Cell: (cellProps) => {
                    return <Status {...cellProps} />;
                }
            },
            {
                Header: 'Actions',
                disableFilters: true,
                Cell: (cellProps) => {
                    const DatasourceData = cellProps.row.original;

                    return <UncontrolledDropdown>

                        <DropdownToggle href="#"
                            tag="a">
                            <i className="mdi mdi-dots-horizontal font-size-16"></i>
                        </DropdownToggle>

                        <DropdownMenu>
                            <DropdownItem onClick={e => browseSource(DatasourceData)}>
                                <div className="hstack gap-1">
                                    <i className="mdi mdi-folder font-size-18 text-warning" /> Parcourir
                                </div>
                            </DropdownItem>
                            <DropdownItem divider />

                            <DropdownItem onClick={e => indexSource(DatasourceData)}>
                                Indexer cette source
                            </DropdownItem>

                            <DropdownItem divider />

                            <DropdownItem onClick={e => startOmnichat(DatasourceData)}>
                                Rechercher une info
                            </DropdownItem>
                            <DropdownItem divider />

                            <DropdownItem onClick={e => handleAssistantCreation(DatasourceData)}>
                                Créer un assistant
                            </DropdownItem>
                            <DropdownItem divider />

                            <DropdownItem>
                                Editer
                            </DropdownItem>
                            <DropdownItem divider />

                            <DropdownItem onClick={e => onClickDelete(DatasourceData)}>
                                Supprimer
                            </DropdownItem>
                        </DropdownMenu>

                    </UncontrolledDropdown>
                }
            }
        ],
        []
    );

    return (
        <React.Fragment>
            <DeleteModal
                show={deleteModal}
                onDeleteClick={handleDeleteDatasource}
                onCloseClick={() => setDeleteModal(false)}
            />
            <DeployAssistant
                show={showAddAssistant}
                onCloseClick={e => setShowAddAssistant(false)}
                provider={Datasource?.provider?.type}
                knowledgeBase={Datasource}
            />


            <DatasourceBrowser
                show={showDatasourceBrowser}
                onCloseClick={() => setShowDatasourceBrowser(false)}
                datasource={Datasource} />

            <Card className="datasources-panel">
                <CardBody>
                    <div className="">
                        <div>
                            <TableContainer
                                columns={columns}
                                data={DatasourcesList}
                                isGlobalFilter={true}
                                isAddOptions={false}
                                handleAddClicks={handleDatasourceClicks}
                                addButtonText="Nouvelle source"
                                isDatasourceListGlobalFilter={false}
                                isGenericAdd={true}
                                customPageSize={10}
                                onRefresh={refresh}
                            />
                        </div>
                    </div>

                </CardBody>
            </Card>

        </React.Fragment>
    );
}


export default DatasourceListView;