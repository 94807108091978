import React, { useState } from "react";
import {
    Container
} from "reactstrap";

//i18n
import { withTranslation } from "react-i18next";
import FilesLeftBar from "./leftbar";
import FilesBrowser from "./browser";
import AddTemplate from "./templates/add"


//meta title
document.title = "Mes documents | Jask";

const WorkspaceDocuments = props => {

    const [showAddTemplate, setShowAddTemplate] = useState(false);
    const [openAction, setOpenAction] = useState(null);

    const onMenuActionClicked = (actionInfo) => {
        setOpenAction(actionInfo)
    }


    return (
        <React.Fragment>
            <AddTemplate isOpen={showAddTemplate} onClose={() => setShowAddTemplate(!showAddTemplate)} />
            <div className="page-content">
                <Container fluid>
                    {/* <Breadcrumbs
                        title={props.t("Mes documents")}
                        breadcrumbItems={[
                            {
                                title: "Jask"
                            },
                            {
                                title: "Espace de travail"
                            },
                            {
                                title: "Mes documents"
                            }
                        ]}
                    /> */}

                    <div className="d-flex flex-column ">
                        <div className="d-xl-flex">
                            <div className="w-100">
                                <div className="d-md-flex">
                                    <FilesLeftBar onAddTemplate={() => setShowAddTemplate(!showAddTemplate)} onActionClicked={onMenuActionClicked} />
                                    <div className="w-100">
                                        <FilesBrowser handleAction={openAction} onAddTemplate={() => setShowAddTemplate(!showAddTemplate)} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </React.Fragment >
    );
};


export default withTranslation()(WorkspaceDocuments);
