import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import {
    Row, Col, Form, Card, CardBody,
    Spinner
} from "reactstrap"

import SharePoint from "assets/images/providers/sharepoint.png"
import { isEmpty } from "lodash"
import { DatasourceProviders, ResourceTypes } from "constants/general"
import { describeDatasource } from "service/datasources"


const SharePointConnectivity = ({ onSetConnection }) => {

    const [url, setUrl] = useState("<name>.sharepoint.com,<ID>,<ID>");
    const [accountType, setAccountType] = useState("service");
    const [login, setLogin] = useState(null);
    const [password, setPassword] = useState(null);
    const [tenantId, setTenantId] = useState(null);
    const [isTesting, setIsTesting] = useState(false);
    const [canTest, setCanTest] = useState(false);
    const [description, setDescription] = useState(null);
    const [isValid, setIsValid] = useState(false);

    const test = () => {

        const creds = {
            clientId: login,
            clientSecret: password,
            type: accountType,
            siteId: url,
            tenantId: tenantId
        };

        const request = {
            id: DatasourceProviders.SHAREPOINT,
            type: ResourceTypes.FILES,
            connection: {
                sharepoint: creds
            }
        }

        setIsTesting(true);

        describeDatasource(request.id, request).then(resp => {
            setDescription(resp);
            setIsValid(true);
        }).finally(() => {
            setIsTesting(false);
        })
    }

    useEffect(() => {

        const state = !isEmpty(login) && !isEmpty(password) && !isEmpty(accountType) && !isEmpty(url) && !isEmpty(tenantId);;

        const conn = {
            clientId: login,
            clientSecret: password,
            type: accountType,
            siteId: url,
            tenantId: tenantId
        };

        onSetConnection({
            sharepoint: conn,
            isValid: isValid

        });
        setCanTest(state);


    }, [login, password, accountType, url, tenantId, isValid])

    return (
        <React.Fragment >
            <div className="">
                <div>
                    <h5>Connect to your sharepoint</h5>
                    <p>
                        Provide connection details below to connect your data
                    </p>
                </div>

                <Row >
                    <Col lg={8}>
                        <Row>
                            <Col lg="8">
                                <div>
                                    <Form>
                                        <div className="form-floating mb-3">
                                            <input type="text" className="form-control" id="clientId" placeholder="ie: https://<tenant>.sharepoint.com/sites/<site>" value={url}
                                                onChange={e => setUrl(e.target.value)} />
                                            <label htmlFor="clientId">Your SharePoint Site Id</label>
                                        </div>
                                    </Form>
                                </div>
                            </Col>

                        </Row>
                        <Row>
                            <Col lg="4" className="mb-3">
                                <div className="hstack gap-3">

                                    <div className="form-check">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="accountType"
                                            id="service"
                                            value="service"
                                            defaultChecked
                                            onChange={e => setAccountType(e.target.value)}
                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor="service"
                                        >
                                            Service Account
                                        </label>
                                    </div>

                                    <div className="form-check">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="accountType"
                                            id="personal"
                                            value="personal"
                                            disabled

                                            onChange={e => setAccountType(e.target.value)}
                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor="personal"
                                        >
                                            Personal Account
                                        </label>
                                    </div>
                                </div>
                            </Col>

                        </Row>

                        {accountType === "personal" && <Row>
                            <Col lg="4">
                                <div>
                                    <Row className="mb-2">
                                        <Form>
                                            <div className="form-floating mb-3">
                                                <input type="text" className="form-control" id="floatingappIdInput" placeholder=""

                                                    value={login}
                                                    onChange={e => setLogin(e.target.value)}

                                                />
                                                <label htmlFor="floatingappIdInput">Login</label>
                                            </div>
                                        </Form>
                                    </Row>
                                </div>
                            </Col>
                            <Col lg="4">
                                <div>
                                    <Form>
                                        <div className="form-floating mb-3">
                                            <input type="password" className="form-control" id="floatingappKeyInput" placeholder=""
                                                value={password}
                                                onChange={e => setPassword(e.target.value)}

                                            />
                                            <label htmlFor="floatingappKeyInput">Password</label>
                                        </div>
                                    </Form>
                                </div>
                            </Col>
                        </Row>}

                        {accountType === "service" &&
                            <div>
                                <Row>
                                    <Col lg="8">
                                        <div>
                                            <Row className="mb-2">
                                                <Form>
                                                    <div className="form-floating mb-3">
                                                        <input type="text" className="form-control" id="floatingappIdInput" placeholder=""
                                                            value={tenantId}
                                                            onChange={e => setTenantId(e.target.value)}
                                                        />
                                                        <label htmlFor="floatingappIdInput">Tenant Id</label>
                                                    </div>
                                                </Form>
                                            </Row>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg="4">
                                        <div>
                                            <Row className="mb-2">
                                                <Form>
                                                    <div className="form-floating mb-3">
                                                        <input type="text" className="form-control" id="floatingappIdInput" placeholder=""
                                                            value={login}
                                                            onChange={e => setLogin(e.target.value)}
                                                        />
                                                        <label htmlFor="floatingappIdInput">Application Id</label>
                                                    </div>
                                                </Form>
                                            </Row>
                                        </div>
                                    </Col>
                                    <Col lg="4">
                                        <div>
                                            <Form>
                                                <div className="form-floating mb-3">
                                                    <input type="password" className="form-control" id="floatingappKeyInput" placeholder=""
                                                        value={password}
                                                        onChange={e => setPassword(e.target.value)}

                                                    />
                                                    <label htmlFor="floatingappKeyInput">Application Secret</label>
                                                </div>
                                            </Form>
                                        </div>
                                    </Col>
                                </Row>
                            </div>}
                        <Row>
                            <Col md={9}>
                                <div className="float-end">
                                    <button onClick={test} disabled={!canTest} className="btn btn-info">
                                        <div className="hstack gap-2">
                                            Tester la connexion
                                            {isTesting && <Spinner size={"sm"} />}
                                        </div>
                                    </button>
                                    {!isTesting && !isEmpty(description) && <i className="mdi mdi-check-circle text-success font-size-18 ms-1" />}

                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col lg={4}>

                        <Card>
                            <CardBody>
                                <div className="favorite-icon">
                                    <Link to="#"><i className="uil uil-heart-alt fs-18"></i></Link>
                                </div>
                                <img src={SharePoint} alt="" height="100" className="mb-3" />
                                <h5 className="fs-17 mb-2">
                                    <Link to="/job-details" className="text-dark">Description</Link></h5>
                                <p className="text-muted fs-14 mb-1"> A versatile collaboration and document management platform developed by Microsoft. It offers a wide range of features and
                                    capabilities to enhance teamwork, streamline business processes, and manage content effectively within organizations</p>

                                <div className="mt-3 hstack gap-2">
                                    <span className="badge rounded-1 badge-soft-info">pdf</span>
                                    <span className="badge rounded-1 badge-soft-info">excel</span>
                                    <span className="badge rounded-1 badge-soft-info">word</span>
                                    <span className="badge rounded-1 badge-soft-info">...</span>
                                </div>

                            </CardBody>
                        </Card>
                        {/* <div className="d-flex justify-content-center" >
                            <div className="hstack gap-2">
                                <img src={SharePoint} width={150} height={150} className="mb-4" />
                                <div>
                                    <h5>Description</h5>
                                    <p>
                                        A versatile collaboration and document management platform developed by Microsoft. It offers a wide range of features and
                                        capabilities to enhance teamwork, streamline business processes, and manage content effectively within organizations
                                    </p>
                                </div>
                            </div>
                        </div> */}
                    </Col>
                </Row>
            </div >
        </React.Fragment >
    )
}

export default SharePointConnectivity;
