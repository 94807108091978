import React, { useEffect, useState } from "react"
import { Card, CardBody, Col, Row } from "reactstrap"
import { withTranslation } from "react-i18next";
import Select from "react-select"
import { FileExtensions } from "constants/general";






const Settings = ({ t, source, onChange }) => {


    const responsesType = [{
        label: t("Peuvent provenir de plusieurs fichiers."),
        value: "multiple"
    },
    {
        label: t("Doivent provenir d'un seul  fichier à la fois"),
        value: "single"
    },

    ]

    const [extensions, setExtensions] = useState(FileExtensions);
    const [responseType, setResponseType] = useState(responsesType[0]);

    useEffect(() => {

        const selectedExtensions = source?.settings?.directory?.includeExtensions || [];
        const responseSource = source?.settings?.directory?.responseSource;

        setExtensions(FileExtensions.filter(f => selectedExtensions.includes(f.value)));
        setResponseType(responsesType.find(i => i.value === responseSource) || responsesType[0])

    }, [source])


    useEffect(() => {
        onChange({
            id: source.id,
            directory: {
                includeExtensions: extensions.map(e => e.value),
                responseSource: responseType.value
            }
        });

    }, [extensions, responseType])
    return <div className="p-2">

        <Row className="mb-3">
            <Col md={3} className=" pt-2">
                <span className="fw-bold font-size-12"><i className="mdi mdi-file" /> {t("Inclure les fichiers:")}</span>
            </Col>
            <Col md={9}>
                <Select
                    options={FileExtensions}
                    isMulti={true}
                    value={extensions}
                    onChange={setExtensions}
                />
            </Col>
        </Row>

        <Row>
            <Col md={3} className=" pt-2">
                <span className="fw-bold font-size-12"><i className="mdi mdi-chat" /> {t("Réponses")}</span>
            </Col>
            <Col md={9}>
                <Select
                    options={responsesType}
                    isMulti={false}
                    value={responseType}
                    onChange={setResponseType}
                />
            </Col>
        </Row>

    </div>
}

export default withTranslation()(Settings);