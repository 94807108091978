import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import {
    Table,
    DropdownMenu,
    DropdownToggle,
    UncontrolledDropdown,
    DropdownItem,
    Card,
    CardBody,
    Button,
} from "reactstrap"

import {
    getTemplates, delTemplates
} from "store/actions";
import { useSelector, useDispatch } from "react-redux";
import { isEmpty } from "lodash";
import { toLocalDate } from "helpers/to-local-date";
import TemplateListPlaceHolders from "./placeholders";
import DocumentContext from "../generation/context"
import DeleteModal from "components/Common/DeleteModal";
import AddTemplate from "../add"
import DocumentGeneration from "../generation/document"

const TemplateList = ({ create }) => {
    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const [showGenerateDocumentContext, setShowGenerateDocumentContext] = useState(false);
    const [showGenerateDocument, setShowGenerateDocument] = useState(false);
    const [showConfirmDelete, setShowConfirmDelete] = useState(false);
    const [showAddTemplate, setShowAddTemplate] = useState(create || false);
    const [generationContext, setGeneration] = useState(null);


    const dispatch = useDispatch();
    const { isLoading, templates } = useSelector(state => ({
        isLoading: state.Documents.isLoading,
        templates: state.Documents.templates

    }));

    const handleGenerationClick = (item) => {
        setSelectedTemplate(item);
        setShowGenerateDocumentContext(!showGenerateDocumentContext);
    }

    const handleDeleteClick = (item) => {
        setSelectedTemplate(item);
        setShowConfirmDelete(true);
    }

    const handleConfirmDeletion = () => {
        dispatch(delTemplates(selectedTemplate.id))
        setShowConfirmDelete(false);
    }

    const onGenerate = (info) => {
        setGeneration(info.context);
        setSelectedTemplate(info.template);
        setShowGenerateDocument(!showGenerateDocument);

    }


    useEffect(() => {
        if (isEmpty(templates)) {
            dispatch(getTemplates())
        }
    }, [dispatch])


    return (
        <React.Fragment>
            <AddTemplate isOpen={showAddTemplate} onClose={() => setShowAddTemplate(!showAddTemplate)} />
            <DeleteModal show={showConfirmDelete} onDeleteClick={handleConfirmDeletion} onCloseClick={() => setShowConfirmDelete(!showConfirmDelete)} />
            <DocumentContext onGenerateDocument={onGenerate} template={selectedTemplate} isOpen={showGenerateDocumentContext} onClose={() => setShowGenerateDocumentContext(!showGenerateDocumentContext)} />
            {!isLoading && !showGenerateDocument && <div>
                <Card>
                    <CardBody>
                        <div className="template-list">
                            <div className="d-flex flex-wrap">
                                <h5 className="font-size-16 me-3">Liste de vos templates</h5>

                                <div className="ms-auto">
                                    <Button className="btn btn-success" onClick={() => setShowAddTemplate(!showAddTemplate)}><i className="mdi mdi-plus me-1 font-size-14" />Créer</Button>
                                </div>
                            </div>
                            <hr className="mt-2" />

                            <div className="table-responsive  h-100">
                                <Table className="table align-middle table-nowrap table-hover">
                                    <thead>
                                        <tr>
                                            <th scope="col">Nom</th>
                                            <th scope="col" >Date de création</th>
                                            <th scope="col">Crée par</th>
                                            <th scope="col" colSpan={2}>Description</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {templates.map((item, key) => (
                                            <tr key={key}>
                                                <td><Link to="#" className="text-dark fw-medium"><i className={item.icon}></i> {item.name}</Link></td>
                                                <td>{toLocalDate(item.createdAt)} </td>
                                                <td>{item.createdBy}</td>
                                                <td>{item.description || "N/A"}</td>
                                                < td >
                                                    <UncontrolledDropdown>
                                                        <DropdownToggle tag="a" className="font-size-16 text-muted" role="button">
                                                            <i className="mdi mdi-dots-horizontal"></i>
                                                        </DropdownToggle>

                                                        <DropdownMenu className="dropdown-menu-end">
                                                            <DropdownItem href="#" onClick={e => handleGenerationClick(item)}>Générer un document</DropdownItem>
                                                            <DropdownItem href="#">Editer</DropdownItem>
                                                            <DropdownItem href="#">Renommer</DropdownItem>
                                                            <div className="dropdown-divider"></div>
                                                            <DropdownItem href="#" onClick={() => handleDeleteClick(item)}>Supprimer</DropdownItem>
                                                        </DropdownMenu>
                                                    </UncontrolledDropdown>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    </CardBody>
                </Card>
            </div>}

            {
                isLoading && <TemplateListPlaceHolders />
            }

            {showGenerateDocument && <DocumentGeneration onClose={() => setShowGenerateDocument(!showGenerateDocument)} template={selectedTemplate} context={generationContext} />
            }


        </React.Fragment>
    )
}

export default TemplateList;
