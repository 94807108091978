import { TaskStatus } from "constants/tasks";
import { isEmpty, map } from "lodash";
import React, { createRef, useEffect, useState } from "react";
import Dropzone from "react-dropzone";
import { Link } from "react-router-dom";
import { Card, CardBody, CardTitle, Spinner, Table } from "reactstrap";
import { deleteUploadedFile, uploadFile } from "service/documents";
import SimpleBar from "simplebar-react";
import { getFileIcon, truncateFromMiddle, uuidv4 } from "utils";


const FileToProcess = ({ file, onDelete, onFileLoaded }) => {
    const [status, setStatus] = useState(file?.status);
    const target_dir = "workspace/temporary";

    useEffect(() => {
        if (status === TaskStatus.NOT_STARTED) {
            setStatus(TaskStatus.STARTED)
            uploadFile(file.content, target_dir).then(resp => {
                setStatus(TaskStatus.COMPLETED);
                onFileLoaded({
                    id: file?.id,
                    name: file.name,
                    location: resp.attachments[0]
                });
            })
        }
    }, [status]);



    return <tr>
        <td>
            <div className="hstack gap-1">
                {getFileIcon(file.name)}
                {truncateFromMiddle(file.name, 50)}
            </div>
        </td>
        <td>{file.content.size}</td>
        <td>{status === TaskStatus.NOT_STARTED && <span>En attente</span>}
            {status === TaskStatus.STARTED && <span><Spinner size={"sm"} className="me-1" /> Chargement...</span>}
            {status === TaskStatus.COMPLETED && <span><i className="mdi mdi-check-circle text-success me-1" /> Chargé</span>}

        </td>
        <td><Link onClick={() => onDelete(file)}><i className="mdi mdi-trash-can text-danger" /></Link></td>

    </tr>
}


const TaskFilePicker = ({ onFinish }) => {

    const [selectedFiles, setSelectedFiles] = useState([]);
    const [locations, setLocations] = useState([]);
    const [isValid, setIsValid] = useState(false);
    const dropzoneRef = createRef()

    const handleAcceptedFiles = (files) => {

        const existingPath = selectedFiles.map(f => f.content.path);
        const toAdd = files.filter(f => !existingPath.includes(f.path)).map(f => ({
            id: uuidv4(),
            name: f.name,
            content: f,
            status: TaskStatus.NOT_STARTED
        }));

        setSelectedFiles([...selectedFiles, ...toAdd]);
    }

    const onDeleteFile = (file) => {
        setSelectedFiles(selectedFiles.filter(i => file.name !== i.name));

        const loaded = locations.find(loc => loc.id === file.id);
        if (loaded) {
            deleteUploadedFile(loaded.location);
            setLocations(locations.filter(loc => loc.location !== loaded.location));
        }

    }

    const onFileLoaded = (file) => {
        setLocations(prev => [...prev, file]);
    }

    useEffect(() => {
        setIsValid(locations?.length === selectedFiles?.length);
    }, [locations, selectedFiles])

    return <div className="file-picker">
        {selectedFiles?.length == 0 && <Dropzone

            onDrop={acceptedFiles => {
                handleAcceptedFiles(acceptedFiles)
            }}
        >
            {({ getRootProps, getInputProps }) => (
                <div className="dropzone d-flex flex-column justify-content-center align-items-center"  {...getRootProps()}>
                    <input {...getInputProps()} />

                    <div
                        className="dz-message needsclick mt-2"
                        {...getRootProps()}
                    >
                        <input {...getInputProps()} />
                        <div className="mb-3">
                            <i className="display-4 text-muted bx bxs-cloud-upload" />
                        </div>
                        <h5>Déposez des fichiers ou cliquez ici </h5>
                    </div>
                </div>

            )}
        </Dropzone>}

        {selectedFiles?.length > 0 && <Dropzone

            onDrop={acceptedFiles => {
                handleAcceptedFiles(acceptedFiles)
            }}
            noClick={true}
            ref={dropzoneRef}
        >
            {({ getRootProps, getInputProps }) => (
                <div className="table-responsive h-100" {...getRootProps()} >
                    {/* <input type="file" hidden ref={inputRef} onChange={onImport} /> */}
                    <input {...getInputProps()} />
                    <SimpleBar className="scroller">
                        <Table className="table align-middle table-nowrap table-hover mb-0">
                            <thead>
                                <tr>
                                    <th scope="col">
                                        <div className="form-check   ms-1">
                                            <span className="ms-2">Nom du fichier</span>
                                            <label className="form-check-label" htmlFor="selectAll"></label>
                                        </div>

                                    </th>
                                    <th scope="col">Taille  </th>
                                    <th scope="col">Status  </th>
                                    <th scope="col">  </th>
                                </tr>
                            </thead>
                            <tbody>
                                {map(selectedFiles, file => <FileToProcess key={file.id} file={file} onDelete={onDeleteFile} onFileLoaded={onFileLoaded} />)}
                            </tbody>
                        </Table>
                    </SimpleBar>
                    <div>
                        <button className="btn btn-secondary" onClick={() => dropzoneRef?.current?.open()}>importer depuis mon PC</button>
                    </div>
                </div>

            )}

        </Dropzone>}
        <div className="float-end">
            {/* <button className="btn btn-secondary me-1">Précedent</button> */}
            {selectedFiles?.length > 0 && <button disabled={!isValid} onClick={() => onFinish(locations)} className="btn btn-info">Suivant</button>}
        </div>
    </div>
}

export default TaskFilePicker;