import { isEmpty, map } from "lodash";
import React, { useEffect, useState } from "react"
import { Card, CardBody, Col, Row, Spinner } from "reactstrap"
import { loadTabularFile } from "service/documents";
import { isTabularFile } from "utils/files";
import { withTranslation } from "react-i18next";
import SimpleBar from "simplebar-react";
import { uuidv4 } from "utils";


const Settings = ({ t, source, onChange }) => {

    const [table, setTable] = useState(null);
    const [mappings, setMappings] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isTable, setIsTable] = useState(false);
    const [treatAsTable, setTreatAsTable] = useState(false);

    // const [processTypes,setProcessTypes] = useState([{
    //     label:,
    //     value:""
    // }])

    const onMappingClicked = (mapping) => {
        const items = [...mappings];
        items.forEach(item => {
            if (item.id === mapping.id) {
                item.isSelected = !item.isSelected;
            }
        });

        setMappings(items);
    }

    const onColumnDescription = (mapping, value) => {
        const items = [...mappings];
        items.forEach(item => {
            if (item.id === mapping.id) {
                item.description = value;

            }
        });

        setMappings(items);
    }

    useEffect(() => {

        onChange({
            id: source.id,
            file: {
                columns: treatAsTable ? mappings.filter(c => c.isSelected) : [],
                processAsTable: treatAsTable
            }
        });

    }, [mappings, treatAsTable])

    useEffect(() => {

        const isExcelOrCsv = isTabularFile(source.id);
        setIsTable(isExcelOrCsv);

        if (isExcelOrCsv) {
            setMappings(source?.settings?.file?.columns || [])
            setTreatAsTable(source?.settings?.file?.processAsTable === true);
        } else {
            setTreatAsTable(false);
        }


        setTable(null);
    }, [source])

    useEffect(() => {
        if (treatAsTable && isTable && isEmpty(mappings)) {
            setIsLoading(true);
            loadTabularFile(source.id).then(resp => {
                setTable(resp);
                setMappings(resp.columns.map(c => ({
                    id: uuidv4(),
                    name: c,
                    description: "",
                    isSelected: true
                })))
            }).finally(() => {
                setIsLoading(false);
            })
        }

    }, [treatAsTable, isTable, mappings]);

    return <div className="h-100">



        {isTable && <Row className="mb-1">

            <Col md={12}>
                <div className="hstack gap-1">
                    <input id={source?.id} name={source?.id} type="checkbox" checked={treatAsTable} onClick={e => setTreatAsTable(!treatAsTable)} />
                    <small className="fw-bold font-size-12">{t("Traiter comme un tableau")}</small>

                </div>
            </Col>

        </Row>
        }

        {
            !isTable && <div>{t("Aucun paramétrage disponible.")}</div>
        }


        {isLoading && <div className="h-100 d-flex justify-content-center align-items-center p-4">
            <div className="p-4">
                <div className="hstack gap-1 p-4">
                    <Spinner size={"sm"} /> {t("Loading")}...
                </div>
            </div>

        </div>}

        <ul>
            {treatAsTable && <SimpleBar className="scroller-table">

                {!isLoading && <li> <Row className="border-top border-secondary border-opacity-25 pt-1 pb-2">
                    <Col md={4}>
                        <small className="fw-bold">{t("Inclure les colonnes")}</small>
                    </Col>
                    <Col md={6}>
                        <small className="fw-bold float-end">{t("Description (Optionnel)")}</small>
                    </Col>
                </Row>
                </li>}
                {!isLoading && map(mappings || [], mapping => {

                    return <li className="mb-1"><Row key={mapping.name}>
                        <Col md={4}>
                            <div className="hstack gap-1">
                                <input type="checkbox"
                                    checked={mapping.isSelected}
                                    onClick={() => onMappingClicked(mapping)}
                                />
                                <span>{mapping.name}</span>

                            </div>
                        </Col>
                        <Col md={6}>

                            <input type="text"
                                disabled={!mapping.isSelected}
                                className="form-control"
                                value={mapping.description}
                                onChange={e => onColumnDescription(mapping, e.target.value)}
                                placeholder={t("Description du contenu de ") + " " + mapping.name}
                            />
                        </Col>
                    </Row>
                    </li>
                })}
            </SimpleBar>}
        </ul>


    </div>
}

export default withTranslation()(Settings);