import React, { useEffect, useState, useRef, useMemo } from "react";
import CustomSelect, { AppIdentityCard } from "components/Common/CustomSelect";
import { useSelector, useDispatch } from "react-redux";
import { ResourceTypes } from "constants/general";


const ApplicationListSelect = ({ isMulti, onAppSelected, appOnly }) => {

    const [applications, setApplications] = useState(null);
    const [selectedApp, setSelectedApp] = useState(null);


    const { assistants, apps } = useSelector(state => ({
        apps: state.Studio.apps,
        assistants: state.Studio.assistants,
    }));

    const handleSelectedApp = (app) => {
        setSelectedApp(app);
        if (onAppSelected) {
            onAppSelected(app?.value);
        }
    }

    useEffect(() => {

        const appItems = (apps || []).map(app => ({ value: app, label: app.name, type: ResourceTypes.APPLICATIONS, description: app.description }));
        const assistantItems = (assistants || []).map(app => ({ value: app.id, label: app.name, type: ResourceTypes.ASSISTANTS, logo: app.logoUrl, description: app.description }));

        if (appOnly === true) {
            setApplications([...appItems])
        } else {
            setApplications([...appItems, ...assistantItems]);
        }

    }, [apps, assistants]);


    return <CustomSelect
        options={applications}
        className="select2-selection text-muted"
        value={selectedApp}
        onChange={handleSelectedApp}
        isMulti={isMulti ? true : false}
        isClearable={true}
    />

}

export default ApplicationListSelect;