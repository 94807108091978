import PropTypes from 'prop-types'
import React, { useEffect, useState } from "react"
import { Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"
import { post } from "helpers/api_helper";
import { BROWSE_DIR, GET_DIRECTORY } from "helpers/url_helper";
import SimpleBar from "simplebar-react";
import { map } from 'lodash';
import { Link } from 'react-router-dom';
import { getFileIcon } from 'utils';

const DocumentBrowserPlaceHolders = () => {

    return <div className='panel border-end p-2'>
        <ul className='list-unstyled placeholder-glow'>
            <li className="placeholder col-8"></li>
            <li className="placeholder col-7"></li>
            <li className="placeholder col-12"></li>
            <li className="placeholder col-9"></li>
            <li className="placeholder col-8"></li>
            <li className="placeholder col-7"></li>
            <li className="placeholder col-12"></li>
            <li className="placeholder col-9"></li>
        </ul>
    </div>
}

const DocumentBrowser = ({ show, onCloseClick, onConfirm }, props) => {
    const [currentDir, setCurrentDir] = useState({ location: "/", name: "", level: 0 });
    const [directoryContent, setDirectoryContent] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [ancestors, setAncestors] = useState([]);

    const browseCurrentDir = () => {
        setIsLoading(true);
        const payload = { name: currentDir.location, metadata: false }
        post(BROWSE_DIR, payload).then(resp => {

            const newContent = { ...directoryContent };
            newContent[currentDir.location] = resp.content;
            setDirectoryContent(newContent);

            if (currentDir.location !== "/") {
                setAncestors([...resp.ancestors, {
                    name: resp.current,
                    location: currentDir.location,
                    level: resp.ancestors.length
                }])
            } else {
                setAncestors(resp.ancestors);
            }

        }).finally(() => setIsLoading(false))
    }

    const browsePath = (item, level) => {
        const previousParent = ancestors.filter(i => i.level <= level);
        setAncestors(previousParent);
        setCurrentDir(item);
    }

    useEffect(() => {
        if (show === true)
            browseCurrentDir();
    }, [currentDir, show])


    return (
        <Modal size="xl"
            isOpen={show}
            toggle={onCloseClick}
            contentClassName="browseFile"
            centered={true}
            fade={false}
            {...props}
            zIndex={10000}

        >
            <ModalHeader>
                <h5>Selectionner un dossier ou fichier</h5>
                <button type="button" onClick={onCloseClick} className="btn-close position-absolute end-0 top-0 m-3"></button>
            </ModalHeader>
            <div className="modal-content">
                <ModalBody className="">
                    <SimpleBar className='window-scroll'>

                        <div className='d-flex flex-row'>
                            {map(ancestors, item => {
                                return <div key={item.location} className='panel border-end p-2'>
                                    <ul className='list-unstyled'>
                                        <SimpleBar className='scroller'>

                                            {map(directoryContent[item.location], doc => {
                                                const ancestorNames = ancestors.map(a => a.name);
                                                const activeClass = ancestorNames.includes(doc.name) ? "active" : "";
                                                return <li key={doc.location} className={'p-2 ' + activeClass}>
                                                    <Link className='text-muted' onClick={() => browsePath(doc, item.level)}>
                                                        <div className='hstack gap-1'>
                                                            {doc.isDir ? <i className="mdi mdi-folder me-1 text-warning" /> : getFileIcon(item.name, 14)}
                                                            <span className='truncate'>{doc.name}</span>
                                                        </div>
                                                    </Link>
                                                </li>
                                            })}
                                        </SimpleBar>

                                    </ul>
                                </div>
                            })}

                            {isLoading && <DocumentBrowserPlaceHolders />}
                        </div>
                    </SimpleBar>

                </ModalBody>
                <ModalFooter>
                    <div className="hstack gap-2 ">
                        <button type="button" className="btn btn-success" onClick={() => onConfirm(currentDir)}>Valider</button>
                        <button type="button" className="btn btn-secondary" onClick={onCloseClick}>Annuler</button>
                    </div>
                </ModalFooter>
            </div>
        </Modal>
    )
}

DocumentBrowser.propTypes = {
    onCloseClick: PropTypes.func,
    onDeleteClick: PropTypes.func,
    show: PropTypes.any
}

export default DocumentBrowser
