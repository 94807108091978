export const ScenarioType = {
    FILE_BY_FILE: "simple",
    MULTIPLE_FILE: "multiple",
    COMPARAISON: "compare"
}

export const InstructionType = {
    CUSTOM: "custom",
    APP: "application"
}

export const LaunchType = {
    IMMEDIAT: "immediat",
    BACKGROUND: "background"
}
