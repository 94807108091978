import React, { useEffect, useState } from "react";
import { Card, CardBody, CardTitle, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Label, Nav, NavItem, NavLink, TabContent, TabPane, UncontrolledDropdown } from "reactstrap";
import classnames from "classnames";
import Select from "react-select"
import { isEmpty, map } from "lodash";
import PromptStoreBrowser from "components/prompt/browser";
import { InstructionType } from "../constants";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import SimpleBar from "simplebar-react";

const SingleFileInstruction = ({ models, apps, onFinish, t }) => {

    const [activeTab, setActiveTab] = useState(InstructionType.CUSTOM);
    const [selectedModel, setSelectedModel] = useState(models[0])
    const [selectedApp, setSelectedApp] = useState(null);
    const [instruction, setInstruction] = useState(null);
    const [instructionType, setInstructionType] = useState(null);
    const [isValid, setIsValid] = useState(false);
    const [showPromptStore, setShowPromptStore] = useState(false);

    const toggleTab = (tab) => {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
    };

    const onPromptSelected = (prompt) => {
        setInstruction(prompt.content);
        setShowPromptStore(!showPromptStore)
    }

    const onNext = () => {

        const task = {
            instruction: instructionType === InstructionType.CUSTOM ? instruction : null,
            type: instructionType,
            app: instructionType === InstructionType.APP ? selectedApp : null,
            model: selectedModel.value
        }

        onFinish(task);
    }

    useEffect(() => {
        setInstructionType(activeTab);
    }, [activeTab]);

    useEffect(() => {

        switch (activeTab) {

            case InstructionType.CUSTOM:
                setIsValid(!isEmpty(instruction) && !isEmpty(selectedModel));
                break;

            case InstructionType.APP:
                setIsValid(!isEmpty(selectedApp?.id) && !isEmpty(selectedModel));
                break;

        }

    }, [instructionType, instruction, selectedApp, selectedModel])

    return <Card>

        <Nav tabs className="nav-tabs-custom" role="tablist">
            <NavItem>
                <NavLink
                    className={classnames({
                        active: activeTab === InstructionType.CUSTOM,
                    })}
                    onClick={() => {
                        toggleTab(InstructionType.CUSTOM);
                    }}
                >
                    <i className="mdi mdi-comment" /> Nouvelle instruction
                </NavLink>
            </NavItem>

            <NavItem>
                <NavLink
                    className={classnames({
                        active: activeTab === InstructionType.APP,
                    })}
                    onClick={() => {
                        toggleTab(InstructionType.APP);
                    }}
                >
                    <i className="mdi mdi-apps" /> Mes applications
                </NavLink>
            </NavItem>
        </Nav>
        <TabContent activeTab={activeTab}>
            <TabPane tabId={InstructionType.CUSTOM} >
                <div className="p-4">

                    <div className="mb-3">
                        <textarea

                            placeholder="Que souhaitiez-vous appliquer sur vos fichiers ?"
                            rows={10}
                            className="form-control"
                            value={instruction}
                            onChange={e => setInstruction(e.target.value)}
                        >

                        </textarea>
                        <div className="d-flex justify-content-end mt-2 ">
                            <Link onClick={() => setShowPromptStore(!showPromptStore)}> <i className="mdi mdi-comment" /> {t("Prompts store")}</Link>
                            <PromptStoreBrowser
                                show={showPromptStore}
                                onCloseClick={() => setShowPromptStore(false)}
                                onPromptSelected={onPromptSelected}
                            />
                        </div>
                    </div>

                    <div className="mt-4">
                        <Label> Modèles d'IA à utiliser: </Label>
                        <Select
                            className="select2-selection"
                            options={models}
                            value={selectedModel}
                            onChange={setSelectedModel}
                        >
                        </Select>
                    </div>
                </div>
            </TabPane>

            <TabPane tabId={InstructionType.APP} >
                <div className="app-list-mini">

                    <SimpleBar className="scroller" >

                        <div className="d-flex flex-wrap p-4  h-100">
                            {
                                map(apps, app => {
                                    return <Card className="border border-info rounded-3 m-2 app border-opacity-25" onClick={() => setSelectedApp(app)}>
                                        <div className="hstack ">
                                            <div className="ms-2">
                                                <img src={app.image} width={50} height={100} />
                                            </div>
                                            <CardBody>
                                                <CardTitle>{app.name}
                                                    {selectedApp?.id === app.id && < div className="float-end"><i className="mdi mdi-check-circle text-success" /></div>}
                                                </CardTitle>
                                                {app.description}
                                            </CardBody>
                                        </div>

                                    </Card>
                                })
                            }

                        </div>
                    </SimpleBar>
                </div>

            </TabPane>

        </TabContent>

        <div className="d-flex mt-4 justify-content-end">
            <button onClick={onNext} disabled={!isValid} className="btn btn-info">Suivant</button>
        </div>

    </Card >
}

export default withTranslation()(SingleFileInstruction);
