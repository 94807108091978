import React from "react";
import {
    Container
} from "reactstrap";
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb2";

import ApplicationList from "./list"
//i18n
import { withTranslation } from "react-i18next";
const WorkspaceAssistants = props => {

    //meta title
    document.title = "Mes assistants | Jask Studio";

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs
                        title={props.t("Mes assistants")}
                        breadcrumbItems={[
                            {
                                title: "Jask"
                            },
                            {
                                title: "Studio"
                            },
                            {
                                title: "Mes assistants"
                            }
                        ]}
                    />
                    <ApplicationList />
                </Container>
            </div>
        </React.Fragment>
    );
};


export default withTranslation()(WorkspaceAssistants);
