import SimpleTableContainer from "components/Common/SimpleTableContainer";
import TableContainer from "components/Common/TableContainer";
import { isEmpty, map } from "lodash";
import React, { useEffect, useMemo, useState } from "react"
import { Card, CardBody, Label, Modal, ModalBody, ModalFooter, ModalHeader, Spinner } from "reactstrap"
import { loadTabularFile } from "service/documents";
import SimpleBar from "simplebar-react"
import Select from 'react-select'
import { withTranslation } from "react-i18next";


const ColumnSelection = ({ show, onClose, onConfirm, options, selection, onSelectionChanged }) => {



    return <Modal
        isOpen={show}
        toggle={onClose}
        contentClassName="tabular-task-launcher"
        size="xs"
        fade={false}
        centered={true}

    >
        <div className="p-2 bg-info bg-opacity-50">
            <h5> <i className="mdi mdi-table font-size-18" /> Colonnes à afficher</h5>
        </div>

        <ModalBody className="ps-4">
            <Card>
                <div className="p-2">
                    <div className="d-flex flex-row justify-content-between">
                        <div>
                            <div className="hstack gap-1">
                                <Select className="select2-selection"
                                    options={options}
                                    isMulti={true}
                                    value={selection}
                                    onChange={onSelectionChanged}
                                />
                            </div>

                        </div>
                        <div>
                        </div>
                    </div>
                </div>
            </Card>
        </ModalBody>
        <ModalFooter className="bg-info bg-opacity-25">
            <button className="btn btn-info" onClick={onClose}>Terminer</button>
        </ModalFooter>

    </Modal>
}

export default withTranslation()(ColumnSelection);