import { all, fork } from "redux-saga/effects";

//public
import AccountSaga from "./auth/register/saga";
import AuthSaga from "./auth/login/saga";
import ForgetSaga from "./auth/forgetpwd/saga";
import ProfileSaga from "./auth/profile/saga";
import LayoutSaga from "./layout/saga";
import AssistantSaga from "./assistant/saga";
import TodosSaga from "./todos/saga";
import DocumentsSaga from "./documents/saga";
import OmniChat from "./omnichat/saga";
import Crm from "./crm/saga";
import Studio from "./studio/saga";
import Datasources from "./datasources/saga";
import Users from "./users/saga";
import Usages from "./usages/saga";
import DeploymentsSaga from "./deployments/saga";
import StreamsSaga from "./streams/saga";
import PromptStoreSaga from "./promptStore/saga";

//ADD ONS
import JaskForSupportSaga from "./addons/support/saga";

export default function* rootSaga() {
  yield all([
    //public
    fork(AccountSaga),
    fork(AuthSaga),
    fork(ForgetSaga),
    fork(ProfileSaga),
    fork(LayoutSaga),
    fork(AssistantSaga),
    fork(TodosSaga),
    fork(DocumentsSaga),
    fork(OmniChat),
    fork(Crm),
    fork(Studio),
    fork(Datasources),
    fork(Users),
    fork(Usages),
    fork(DeploymentsSaga),
    fork(StreamsSaga),
    fork(JaskForSupportSaga),
    fork(PromptStoreSaga)
  ]);
}
