export const GET_PROMPTS = "GET_PROMPTS"
export const GET_PROMPTS_SUCCESS = "GET_PROMPTS_SUCCESS"
export const GET_PROMPTS_FAILED = "GET_PROMPTS_FAILED"

export const CREATE_PROMPT = "CREATE_PROMPT"
export const CREATE_PROMPT_SUCCESS = "CREATE_PROMPT_SUCCESS"
export const CREATE_PROMPT_FAILED = "CREATE_PROMPT_FAILED"

export const DELETE_PROMPT = "DELETE_PROMPT"
export const DELETE_PROMPT_SUCCESS = "DELETE_PROMPT_SUCCESS"
export const DELETE_PROMPT_FAILED = "DELETE_PROMPT_FAILED"
