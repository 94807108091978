import { ExportType } from "constants/export"
import React, { useEffect, useState } from "react"
import { Modal, ModalBody, ModalFooter, Spinner } from "reactstrap"
import SaveDocumentView from "./saveDocument"
import SaveTodoView from "./todo"
import { operationFailed, operationSucceed } from "utils/notifications"
import { OperationStatus } from "constants/tasks"
import ShareWithAssisant from "./shareWithAssisant"

const ExportGeneration = ({ taskName, type, data, isOpen, onCloseClick, directory }) => {

    const [config, setConfig] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    const onConfigChange = (config) => {
        setConfig(config);
    }

    const handleSubmit = () => {
        setIsLoading(true);
        config.onSubmit(config);
    }

    const getExportView = (exportType, data) => {

        switch (exportType) {

            case ExportType.DOCUMENT:
                return <SaveDocumentView taskName={taskName} data={data} directory={directory} onConfigChange={onConfigChange} />

            case ExportType.TODO:
                return <SaveTodoView taskName={taskName} data={data} directory={directory} onConfigChange={onConfigChange} />

            case ExportType.ASSISTANTS:
                return <ShareWithAssisant taskName={taskName} data={data} directory={directory} onConfigChange={onConfigChange} />
            default:
                return <SaveDocumentView data={data} directory={directory} onConfigChange={onConfigChange} />
        }
    }

    useEffect(() => {
        if (!config.isLoading && isLoading) {
            setIsLoading(false);
            onCloseClick();

            if (config.operationStatus === OperationStatus.SUCCESS)
                operationSucceed(config.message)
            else
                operationFailed(config.error)
        }
    }, [config])

    return <Modal size="lg" isOpen={isOpen} toggle={onCloseClick} centered={true} fade={false}
        trapFocus={false}
        contentClassName="export-window"
        zIndex={10000}
    >
        <div className="p-2  border-bottom border-secondary border-opacity-25">
            <div>
                <h5><i className="mdi mdi-share" /> Partage de contenu </h5>
            </div>
            <div>
                <button type="button" onClick={onCloseClick} className="btn-close position-absolute end-0 top-0 m-2"></button>
            </div>
        </div>

        <ModalBody className="m-0">
            <div className="export-view-panel p-1">
                {getExportView(type, data)}
            </div>


        </ModalBody>


        <ModalFooter>
            <div className="hstack gap-2 justify-content-end me-3">
                <button type="button" className="btn btn-success" disabled={!config.isValid} onClick={handleSubmit}>Valider</button>
                <button type="button" className="btn btn-secondary" onClick={onCloseClick}>Annuler</button>

                {config.isLoading && <div className="vr" />}
                {config.isLoading && <Spinner />}
            </div>
        </ModalFooter>

    </Modal>
}

export default ExportGeneration;