import { GET_CRM_ACCOUNT, GET_CRM_ACCOUNT_SUCCESS, GET_CRM_BOARD, GET_CRM_BOARD_FAILED, GET_CRM_BOARD_SUCCESS, GET_CRM_CONTACTS, GET_CRM_CONTACTS_FAILED, GET_CRM_CONTACTS_SUCCESS, GET_CRM_OPPORTUNITIES, GET_CRM_OPPORTUNITIES_BY_PRODUCT_FAILED, GET_CRM_OPPORTUNITIES_BY_PRODUCT_SUCCESS, GET_CRM_OPPORTUNITIES_FAILED, GET_CRM_OPPORTUNITIES_HISTORICAL_FAILED, GET_CRM_OPPORTUNITIES_HISTORICAL_SUCCESS, GET_CRM_OPPORTUNITIES_STAGES_FAILED, GET_CRM_OPPORTUNITIES_STAGES_SUCCESS, GET_CRM_OPPORTUNITIES_SUCCESS, GET_CRM_OPPTY_TREND, GET_CRM_OPPTY_TREND_FAILED, GET_CRM_OPPTY_TREND_SUCCESS, GET_CRM_USERS, GET_CRM_USERS_FAILED, GET_CRM_USERS_SUCCESS } from "./actionTypes"

const INIT_STATE = {
  isLoading: false,
  isLoadingTrend: false,
  oppty: [],
  contacts: [],
  users: [],
  error: null,
  board: null,
  productsBoard: null,
  histoBoard: null,
  trendBoard: null,
  account: null,
  stages: []
}

const crm = (state = INIT_STATE, action) => {
  switch (action.type) {

    case GET_CRM_OPPORTUNITIES:
    case GET_CRM_CONTACTS:
    case GET_CRM_USERS:
    case GET_CRM_BOARD:
    case GET_CRM_ACCOUNT:

      return {
        ...state,
        isLoading: true,
      }


    case GET_CRM_OPPORTUNITIES_SUCCESS:
      return {
        ...state,
        oppty: action.payload,
        isLoading: false,
      }

    case GET_CRM_CONTACTS_SUCCESS:
      return {
        ...state,
        contacts: action.payload,
        isLoading: false,
      }

    case GET_CRM_USERS_SUCCESS:
      return {
        ...state,
        users: action.payload,
        isLoading: false,
      }

    case GET_CRM_OPPORTUNITIES_FAILED:
    case GET_CRM_USERS_FAILED:
    case GET_CRM_CONTACTS_FAILED:
    case GET_CRM_BOARD_FAILED:
    case GET_CRM_OPPORTUNITIES_STAGES_FAILED:
    case GET_CRM_OPPORTUNITIES_BY_PRODUCT_FAILED:
    case GET_CRM_OPPORTUNITIES_HISTORICAL_FAILED:

      return {
        ...state,
        error: action.payload,
        isLoading: false
      }

    case GET_CRM_OPPTY_TREND_FAILED:

      return {
        ...state,
        error: action.payload,
        isLoadingTrend: false
      }

    case GET_CRM_BOARD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        board: action.payload
      }

    case GET_CRM_OPPTY_TREND:
      return {
        ...state,
        isLoadingTrend: true,
        trendBoard: []
      }
    case GET_CRM_OPPTY_TREND_SUCCESS:
      return {
        ...state,
        isLoadingTrend: false,
        trendBoard: action.payload
      }

    case GET_CRM_OPPORTUNITIES_BY_PRODUCT_SUCCESS:
      return {
        ...state,
        productsBoard: action.payload
      }

    case GET_CRM_OPPORTUNITIES_HISTORICAL_SUCCESS:
      return {
        ...state,
        histoBoard: action.payload
      }

    case GET_CRM_ACCOUNT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        account: action.payload
      }

    case GET_CRM_OPPORTUNITIES_STAGES_SUCCESS:
      return {
        ...state,
        stages: action.payload
      }
    default:
      return state
  }
}

export default crm