import { isEmpty } from 'lodash';
import PropTypes from 'prop-types'
import React, { useEffect, useState } from "react"
import { Label, Modal, ModalBody, Card, CardBody, Input } from "reactstrap"
import Select from "react-select";
import { useSelector, useDispatch } from "react-redux";
import { addProfileTodoCategory } from 'store/actions';
import { withTranslation } from "react-i18next";


const autoApplyChoices = [
    { value: true, label: 'Yes' },
    { value: false, label: 'No' },
]

const AddTodoCategory = ({ show, onConfirmlick, onCloseClick, t }) => {
    const [description, setDescription] = useState("");
    const [name, setName] = useState([]);
    const [autoApply, setAutoApply] = useState(autoApplyChoices[1]);
    const [canCreate, setCanCreate] = useState(false);
    const dispatch = useDispatch();
    const { } = useSelector(state => ({

    }));

    const onCreateCategory = () => {

        const data = {
            name: name,
            description: description,
            autoApply: autoApply.value
        }

        dispatch(addProfileTodoCategory(data));
        onCloseClick();
    }

    useEffect(() => {
        setCanCreate(!isEmpty(name) && !isEmpty(autoApply))
    }, [name, description, autoApply])


    return (
        <Modal size="lg" isOpen={show} toggle={onCloseClick} centered={true} fade={false}>
            <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel"><div className='hstack gap-1'><i className='fa fa-folder text-warning bold font-size-24 me-1' />{t("Nouveau Classeur")} {name && ": " + name}</div></h5>
                <button type="button"
                    onClick={onCloseClick} className="btn-close"></button>
            </div>
            <div className="modal-content">
                <ModalBody >
                    <Card>
                        <CardBody>
                            <div className="mb-3">
                                <Input
                                    type="text"
                                    className="form-control"
                                    id="basicpill-name-input1"
                                    placeholder={t("le nom de votre classeur")}
                                    value={name}
                                    onChange={e => setName(e.target.value)}
                                />
                            </div>
                            <div className="mb-3">
                                <Label>Automatic classification:</Label>
                                <Select value={autoApply} options={autoApplyChoices} className='select2-selection' onChange={e => setAutoApply(e)} />
                            </div>
                            {autoApply.value === true && <div className='vstack gap-2 mb-3'>
                                <textarea
                                    id="basicpill-address-input1"
                                    className="form-control font-size-12"
                                    rows="4"
                                    placeholder="Details or example of content about this category"
                                    value={description}
                                    onChange={(e => setDescription(e.target.value))}
                                />
                            </div>}


                            <div className="hstack gap-2 mt-4 mb-0 ">
                                {!canCreate && <button type="button" className="btn btn-secondary" disabled={true} >Create</button>
                                }
                                {canCreate && <button type="button" className="btn btn-success" onClick={onCreateCategory}>Create</button>
                                }
                                <button type="button" className="btn btn-secondary" onClick={onCloseClick}>Close</button>
                            </div>
                        </CardBody>
                    </Card>
                </ModalBody>
            </div>
        </Modal>
    )
}

AddTodoCategory.propTypes = {
    onCloseClick: PropTypes.func,
    onConfirmlick: PropTypes.func,
    show: PropTypes.any
}

export default withTranslation()(AddTodoCategory);
