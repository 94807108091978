
import { API_URL } from "helpers/api_helper";
import { fetchEventSource } from "@microsoft/fetch-event-source";

export const runPromptTask = async (context, instructions, onStreamStart, onMessage, onStreamEnd, accessToken) => {
    console.log("sending request")
    const ctrl = new AbortController();
    let stream = "";

    await fetchEventSource(`${API_URL}/assistant/run/prompt`,
        {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`,
            },
            body: JSON.stringify({
                context: context,
                instructions: instructions
            }),
            cache: "no-cache",
            // signal: ctrl.signal,
            onopen(res) {
                if (res.ok && res.status === 200) {

                    if (onStreamStart) {
                        onStreamStart();
                    }
                    console.log("Connection made ", res);
                } else if (
                    res.status >= 400 &&
                    res.status < 500 &&
                    res.status !== 429
                ) {
                    console.log("Client side error ", res);
                }
            },
            onmessage(event) {

                try {
                    onMessage(event.data);
                } catch (err) {
                    console.log(err)
                }
            },
            onclose() {
                console.log("Connection closed by the server");
                onStreamEnd();
            },
            onerror(err) {
                console.log("There was an error from server:", err);
                onStreamEnd();
                throw err;
            },
        });
}


export const runPromptOptimization = async (context, onStreamStart, onMessage, onStreamEnd, accessToken) => {
    console.log("sending request")
    const ctrl = new AbortController();
    let stream = "";

    await fetchEventSource(`${API_URL}/assistant/optimize/prompt`,
        {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`,
            },
            body: JSON.stringify({
                text: context
            }),
            cache: "no-cache",
            // signal: ctrl.signal,
            onopen(res) {
                if (res.ok && res.status === 200) {

                    if (onStreamStart) {
                        onStreamStart();
                    }
                    console.log("Connection made ", res);
                } else if (
                    res.status >= 400 &&
                    res.status < 500 &&
                    res.status !== 429
                ) {
                    console.log("Client side error ", res);
                }
            },
            onmessage(event) {

                try {
                    onMessage(event.data);
                } catch (err) {
                    console.log(err)
                }
            },
            onclose() {
                console.log("Connection closed by the server");
                onStreamEnd();
            },
            onerror(err) {
                console.log("There was an error from server:", err);
                onStreamEnd();
                throw err;
            },
        });
}
