import React, { useEffect, useState } from 'react';
import { Handle, useStore } from '@xyflow/react';


const SingleHandle = ({ nodeId, position, type, className, onSourceConnected, onTargetConnected }) => {

    const [isConnected, setIsConnected] = useState(false);

    const istargetConnected = useStore((s) => {
        return s.edges.find((e) => e.target === nodeId)
    }
    );

    const isSourceConnected = useStore((s) =>
        s.edges.find((e) => e.source === nodeId)
    );

    useEffect(() => {

        if (type === "target") {
            setIsConnected(istargetConnected);

            if (onTargetConnected)
                onTargetConnected(istargetConnected)
        }
        else {
            setIsConnected(isSourceConnected)

            if (onSourceConnected)
                onSourceConnected(isSourceConnected)
        }
    }, [isSourceConnected, istargetConnected])

    return <Handle
        position={position}
        type={type}
        className={isConnected ? "handle-connected" : "handle-not-connected"}
    >

    </Handle>
}

export default SingleHandle;