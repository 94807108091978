/* TODO_WORK */
export const GET_TODO_WORK = "GET_TODO_WORK"
export const GET_TODO_WORK_SUCCESS = "GET_TODO_WORK_SUCCESS"
export const GET_TODO_WORK_FAIL = "GET_TODO_WORK_FAIL"


export const ADD_TODO_WORK_START = "ADD_TODO_WORK_START"
export const ADD_TODO_WORK = "ADD_TODO_WORK"
export const ADD_TODO_DRAFT = "ADD_TODO_DRAFT"
export const ADD_TODO_WORK_SUCCESS = "ADD_TODO_WORK_SUCCESS"
export const ADD_TODO_WORK_FAIL = "ADD_TODO_WORK_FAIL"


export const DEL_TODO_WORK = "DEL_TODO_WORK"
export const DEL_TODO_WORK_SUCCESS = "DEL_TODO_WORK_SUCCESS"
export const DEL_TODO_WORK_FAIL = "DEL_TODO_WORK_FAIL"


export const SUBMIT_TODO_WORK = "SUBMIT_TODO_WORK"
export const SUBMIT_TODO_WORK_SUCCESS = "SUBMIT_TODO_WORK_SUCCESS"
export const SUBMIT_TODO_WORK_FAIL = "SUBMIT_TODO_WORK_FAIL"
export const DELETE_TODO_WORK_JOB = "DELETE_TODO_WORK_JOB"

export const ON_TODO_WORK_UPDATED = "ON_TODO_WORK_UPDATED"
export const ON_TODO_WORK_UPDATED_SUCCESS = "ON_TODO_WORK_UPDATED_SUCCESS"
export const ON_TODO_WORK_UPDATED_FAILED = "ON_TODO_WORK_UPDATED_FAILED"

//Preferences
export const ADD_PROFILE_TODO_SETTINGS_CATEGORY = "ADD_PROFILE_TODO_SETTINGS_CATEGORY"
export const ADD_TODO_CATEGORY_SUCCESS = "ADD_TODO_CATEGORY_SUCCESS"
export const ADD_TODO_CATEGORY_FAILED = "ADD_TODO_CATEGORY_FAILED"

export const GET_WORKSPACE_PREFERENCES = "GET_WORKSPACE_PREFERENCES"
export const GET_WORKSPACE_PREFERENCES_SUCCESS = "GET_WORKSPACE_PREFERENCES_SUCCESS"
export const GET_WORKSPACE_PREFERENCES_FAILED = "GET_WORKSPACE_PREFERENCES_FAILED"

export const DEL_TODO_CATEGORY = "DEL_TODO_CATEGORY"
export const DEL_TODO_CATEGORY_SUCCESS = "DEL_TODO_CATEGORY_SUCCESS"
export const DEL_TODO_CATEGORY_FAILED = "DEL_TODO_CATEGORY_FAILED"
