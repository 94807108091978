import React, { useEffect, useState, useRef } from "react";
import { withTranslation } from "react-i18next";
import { Badge, Card, CardBody, CardTitle, Col, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Input, Label, Row, Spinner, UncontrolledDropdown, UncontrolledTooltip } from "reactstrap";
import { capitalize, isEmpty, map, truncate } from "lodash";
import AssistantInputChat from "components/Assistant/InputChat";
import LoadingPlaceholder from "./loader";
import { useSelector, useDispatch } from "react-redux";
import { getFileIcon, uuidv4 } from "utils";
import { onAskAssistant, showRightSidebarAction } from "store/actions";
import ReactMarkdown from 'react-markdown'
import remarkGfm from "remark-gfm";
import rehypeRaw from 'rehype-raw'
import SimpleBar from "simplebar-react";
import { Link } from "react-router-dom";
import { ResourceTypes } from "constants/general";
import Question from "../message/question";
import Answer from "../message/answer";
import StreamingText from "components/StreamText";
import { TaskStatus } from "constants/tasks";
import { AnimatePresence, motion } from "framer-motion";

const orderedFilters = ["source", "folder", "file", "tags"];

const searchFilterTypes = {
    "folder": {
        label: "Dossier",
        icon: "mdi mdi-folder text-warning"
    },
    "file": {
        label: "Fichier",
        icon: "mdi mdi-file text-info",
        isFinal: true,
        component: (filename) => <div className="hstack">{getFileIcon(filename)}<span>{filename}</span></div>
    },
    "source": {
        label: "Source",
        icon: "mdi mdi-database text-info"
    },
    "suggestedTags": {
        label: "Tags",
        icon: "mdi mdi- text-info"
    },
}

const SearchFilterItem = ({ filter, isWorking, searchSpace, onFilterChange, t }) => {
    const [selected, setSelected] = useState([]);
    const [filterValues, setFilterValues] = useState(filter.values);
    const [title, setTitle] = useState(null);
    const filterObj = searchFilterTypes[filter.type];

    const toggleSelect = (item) => {
        let items = null;
        if (selected.includes(item.value))
            items = selected.filter(s => s !== item.value);
        else
            items = [...selected, item.value];

        // onFilterChange({
        //     ...filter,
        //     selection: items
        // })

        setSelected(items);
    }

    const selectAll = () => {
        const items = filter.values.map(f => f.value);
        setSelected(items);
        // onFilterChange({
        //     ...filter,
        //     selection: items
        // })
    }

    const unselectAll = () => {
        setSelected([]);
        // onFilterChange({
        //     ...filter,
        //     selection: []
        // })
    }

    useEffect(() => {
        if (filterObj.isFinal === true) {
            const filterSearchSpace = searchSpace.map(i => i.filters[filter.type]);
            const currentValidSelection = selected.filter(s => filterSearchSpace.map(f => f.value).includes(s))
            setFilterValues(filterSearchSpace);
            // setSelected([...currentValidSelection]);

        }
    }, [searchSpace])

    useEffect(() => {
        setFilterValues(filter.values);
        const newFilters = filter.values.filter(f => f.isNew === true && !selected.includes(f.value)).map(f => f.value);
        setSelected([...selected, ...newFilters]);
    }, [filter])

    useEffect(() => {
        if (selected.length === filterValues.length)
            setTitle(filterValues.length)
        else
            setTitle(`${Math.min(selected?.length, filterValues.length)}/${filterValues.length}`)


    }, [selected, filterValues]);


    useEffect(() => {

        if (filter.selection !== selected) {
            onFilterChange({
                ...filter,
                selection: selected
            })
            // console.log("Seafch space ", filter.type, selected)
        }

    }, [selected])

    return <UncontrolledDropdown tag={"btn"}>
        <DropdownToggle disabled={isWorking} className="btn btn-filter   rounded-4 font-size-10">
            <div className="hstack gap-1">
                <i className={filterObj.icon} />
                <span>{t(searchFilterTypes[filter.type].label)} <small>({title})</small></span>
                <i className="mdi mdi-chevron-down" />

            </div>

        </DropdownToggle>
        <DropdownMenu>
            <DropdownItem onClick={selectAll}><i className="mdi mdi-check-circle text-success me-2" />{t("Tout selectionner")}</DropdownItem>
            <DropdownItem onClick={unselectAll}><i className="mdi mdi-close-circle text-danger me-2" />{t("Tout déselectionner")}</DropdownItem>
            <DropdownItem divider className="border border-secondary border-opacity-25" />

            {map(filterValues, item => {
                const isSelected = selected.includes(item.value)
                return <DropdownItem key={item.value} onClick={() => toggleSelect(item)}>

                    <div className="hstack gap-1">
                        {isSelected ? <i className="mdi mdi-check-circle text-success" /> : <i className="me-3" />}
                        {!filterObj?.component ? <React.Fragment>
                            <i className={filterObj.icon} />
                            <span className="">  {capitalize(item.label)}</span>
                        </React.Fragment> : filterObj.component(item.label)}

                    </div>
                </DropdownItem>
            })}
        </DropdownMenu>
    </UncontrolledDropdown>
}

const SearchFilter = ({ filters, isWorking, searchSpace, t, onFilterChange }) => {

    const [items, setItems] = useState([]);

    useEffect(() => {
        const filterItems = orderedFilters.map(type => filters.find(f => f.type === type)).filter(f => !isEmpty(f));
        setItems(filterItems);
    }, [filters])

    return <div className="hstack gap-1">
        {map(items, filter => {
            return <SearchFilterItem onFilterChange={onFilterChange} searchSpace={searchSpace} key={filter.type} isWorking={isWorking} filter={filter} t={t} />
        })}
    </div>
}
const SearchChat = ({ search, isSearching, currentConversation, assistant, datasource, channelId, t }) => {

    const [conversationReferences, setConversationReferences] = useState([]);
    const [isTyping, setIsTyping] = useState(false);
    const [question, setQuestion] = useState(null);
    const [isExtendedInput, setIsExtendedInput] = useState(false);
    const [streamData, setStreamData] = useState({ text: "" });
    const [isWaiting, setIsWaiting] = useState(false);
    const [filters, setFilters] = useState([]);
    const [selectAll, setSelectAll] = useState(true);
    const [openChat, setOpenChat] = useState(false);
    const [isWorking, setIsWorking] = useState(false);
    const [scope, setScope] = useState([]);
    const [currentFilters, setCurrentFilters] = useState({});
    const [localSearch, setLocalSearch] = useState(search);
    const [searchSelection, setSearchSelection] = useState([]);
    const [searchContent, setSearchContent] = useState("");
    const [chatAutoScroll, setChatAutoScroll] = useState(true);

    const displayMessageContainerRef = useRef();
    const chatRef = useRef();

    const dispatch = useDispatch();
    const { accessToken, channels } = useSelector(state => ({
        accessToken: state.Login.accessToken,
        channels: state.Streams.channels
    }));

    const onSubmitLocalSearch = (e) => {
        const { key } = e;
        if (key === "Enter" && !e.shiftKey && !isEmpty(localSearch)) {
            e.preventDefault();
            submitSearch();
        }
    }

    const onSubmit = (e) => {
        const { key } = e;
        if (key === "Enter" && !e.shiftKey && !isEmpty(question)) {
            e.preventDefault();
            askFollowUp();
        }
    }

    const askFollowUp = (content) => {

        const message = {
            id: uuidv4(),
            sentByUser: true,
            role: "user",
            mode: "chat",
            text: content || question,
            conversationId: currentConversation.id,
            searchScopeFilters: searchSelection.filter(sc => sc.selected).map(sc => ({
                id: sc.id,
                providerId: sc.providerId,
                type: sc.resourceType,
                name: sc.name,
                location: sc.location,
            }))
        }

        dispatch(onAskAssistant({
            channelId: assistant?.id,
            conversationId: currentConversation?.id,
            message: message,
            token: accessToken,
            agentName: assistant?.agentName,
            agentLogo: assistant?.agentLogo,
            createdAt: new Date(),
            cancellationToken: new AbortController(),
        }))

        setQuestion("");
    }

    const onFilterChange = (filter) => {
        const items = { ...currentFilters, [filter.type]: { ...filter } };
        // console.log("CurrentFilters ", items)
        setCurrentFilters(prev => items);

        const remaining = scope.filter(item => {
            item.included = true;
            for (const type of orderedFilters) {
                const currentFilter = items[type];
                if (currentFilter) {
                    // console.log("check filter ", currentFilter)
                    const selection = currentFilter.selection;
                    const itemFilterValue = item.filters[type].value;
                    const isContainer = !(searchFilterTypes[type].isFinal === true);
                    item.included = (isContainer && selection.includes(itemFilterValue)) || (!isContainer)
                    item.selected = selection.includes(itemFilterValue);
                    if (!item.included) {
                        break;
                    }
                }
            }

            return item.included;
        });

        setSearchSelection(remaining);
    }

    const filterSatisfified = (ref) => {
        // console.log("check ", searchSelection)
        const item = searchSelection.find(i => i.id === ref.id && i.selected);
        return item;
    }


    const continueSearch = () => {
        sendSearchRequest();
    }


    const resendPrompt = prompt => {
        askFollowUp(prompt)
    }

    const onCancelRequest = () => {

    }

    const openDocument = (location, providerType, providerId, page) => {
        dispatch(showRightSidebarAction({
            type: ResourceTypes.DOCUMENT,
            resource: {
                location: location,
                providerId: providerId,
                providerType: providerType,
                atPage: page
            }

        }))
    }

    const startChat = () => {
        setOpenChat(!openChat)
    }

    const scrollToBottom = (smooth) => {
        if (displayMessageContainerRef?.current) {
            const scrollEl = displayMessageContainerRef.current;
            scrollEl?.scroll({
                top: scrollEl?.scrollHeight + 1000,
                behavior: smooth ? 'smooth' : 'auto',
            });
        }

        chatRef.current?.scrollIntoView({ behavior: 'smooth' })
    }




    const submitSearch = () => {
        setOpenChat(false);
        sendSearchRequest(localSearch);
    }

    const sendSearchRequest = (value) => {
        const task_id = uuidv4()
        const message = {
            id: task_id,
            sentByUser: true,
            role: "user",
            mode: "search",
            text: value || search,
            conversationId: currentConversation.id,
            searchScopeFilters: scope.map(sc => ({
                id: sc.id,
                providerId: sc.providerId,
                type: sc.resourceType,
                name: sc.name,
                location: sc.location,
                filterType: "exclude"
            }))
            // // attachments: attachments,
            // datasources: (sources || []).map(source => ({ providerId: ResourceTypes.DATASOURCES, id: source.id, name: source.name, type: source.providerType })),
            // applications: (apps || []).map(app => app.id),
            // fileReferences: attachments
        }

        dispatch(onAskAssistant({
            channelId: assistant?.id || datasource?.id,
            conversationId: currentConversation?.id,
            message: message,
            token: accessToken,
            agentName: assistant?.agentName,
            agentLogo: assistant?.agentLogo,
            createdAt: new Date(),
            cancellationToken: new AbortController(),
            type: "search",
            continuation: streamData?.search,
            isSearch: true,
            taskId: task_id,

        }))
    }

    useEffect(() => {

        if (isEmpty(channelId))
            return;

        const currentConversationId = currentConversation?.id;
        const channelData = channels[channelId];


        if (!isEmpty(channelData) && currentConversationId in channelData) {
            const data = channelData[currentConversationId];
            setIsWorking(data?.status === TaskStatus.WAITING || data?.status === TaskStatus.THINKING || data?.status === TaskStatus.STARTED)
            setStreamData(prev => data);
            scrollToBottom(true);
        }

    }, [channels, channelId, currentConversation]);


    useEffect(() => {
        if (streamData?.searchFilters && streamData?.isSearch) {
            const items = Object.keys(streamData?.searchFilters).map(key => {
                const newFilters = streamData?.searchFilters[key] || [];
                return {
                    type: key,
                    values: newFilters
                }
            });
            setFilters(items);
        }

        if (streamData?.searchContext && streamData?.isSearch) {
            setScope(streamData?.searchContext)
        }

        if (streamData?.isSearch) {
            const clean = streamData?.search?.replace(/(\r\n|\n|\r)/gm, '');
            setSearchContent(prev => clean);
            // setSearchContent(streamData?.search);

        }

    }, [streamData]);


    useEffect(() => {
        if (openChat) {
            setTimeout(() => chatRef.current.scrollIntoView({ behavior: 'auto' }), 100)
        }
    }, [openChat])

    return <div className="chat card" >

        <div className="context ">
            <div className="filters d-flex justify-content-between mb-1 mt-2 ">
                <div>
                    <div className="hstack ms-3 border border-secondary border-opacity-25 rounded-4">
                        <input type="text"
                            className="form-control ms-3 rounded-4 border-0 "
                            value={localSearch}
                            style={{ minWidth: "300px" }}
                            readOnly={isWorking}
                            onChange={e => setLocalSearch(e.target.value)}
                            onKeyDown={onSubmitLocalSearch}
                        />
                        <button onClick={submitSearch} disabled={isWorking}
                            className="btn btn- rounded-4 custom-background"><i className="mdi mdi-database-search" />
                        </button>
                    </div>

                </div>
                <div>
                    {isTyping && <div className="hstack ">
                        <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i>{" "}
                        Searching...
                    </div>}
                </div>
                <div className="me-3">
                    <SearchFilter isWorking={isWorking} filters={filters} searchSpace={searchSelection} t={t} onFilterChange={onFilterChange} />
                </div>

            </div>

            <motion.div data-isOn={openChat} className="search-chat-panel h-100"  >
                <SimpleBar className="scroller   mb-4" scrollableNodeProps={{ ref: displayMessageContainerRef }}>
                    <div className="mb-4">
                        {!isEmpty(streamData?.search) && <ReactMarkdown
                            className="markdown"
                            remarkPlugins={[remarkGfm]}
                            rehypePlugins={[rehypeRaw]}
                            components={{
                                code: () => {
                                    return <React.Fragment></React.Fragment>
                                },
                                a: ({ children }) => {
                                    return <React.Fragment></React.Fragment>
                                },
                                datasources: ({ children }) => {
                                    const values = (children instanceof Array) ? children.filter(i => typeof i != "string") : [];
                                    return <div>
                                        <ul className="list-unstyled">

                                            {/* {
                                            map(children.filter(i => typeof i != "string"), item => {
                                                return item;
                                            })
                                        } */}
                                            {values}
                                            {/* <li>
                                           
                                        </li> */}
                                        </ul>
                                    </div>
                                },
                                datasource: ({ name, location, page, tags, children }) => {
                                    const reference = (scope || []).find(sc => sc.openUrl === location)
                                    const isSelected = reference && filterSatisfified(reference);
                                    return <React.Fragment key={"datasource_" + uuidv4()}>
                                        {(isSelected || isWorking) && <li className="datasource" onClick={() => openDocument(reference?.location, reference?.providerType, reference?.providerId, reference.pages[0].page)}>
                                            <div className="p-2 border border-info border-opacity-25 m-3 rounded-3" >
                                                <div className="d-flex justify-content-between mb-2 ">
                                                    <div>
                                                        <div className="border-info border-bottom border-opacity-25">

                                                            <div className="hstack gap-1  ">
                                                                {/* <input type="checkbox" defaultChecked /> */}
                                                                <span >{getFileIcon(name)}</span>
                                                                <span className="fw-bold">{name} </span>
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div className="hstack gap-1">
                                                            {tags && map(tags.split(";") || [], tag => {
                                                                return <Badge color="secondary">{capitalize(tag)}</Badge>
                                                            })}
                                                        </div>

                                                    </div>

                                                </div>

                                                <div>
                                                    {children}
                                                </div>
                                            </div>
                                        </li>}
                                    </React.Fragment>
                                },

                                pages: ({ children }) => {
                                    return <ul className="list-unstyled">
                                        {children}
                                    </ul>
                                },

                                page: ({ number, children }) => {
                                    return <div className="">
                                        {number && <p className="">
                                            <Link ><span className="fw-bold">Page {number}:</span></Link>
                                            <span className="ms-1 fst-">
                                                {children}
                                                {/* <StreamingText text={children} speed={10} /> */}
                                            </span>

                                            <span></span>
                                            {/* <div className=" custom-cursor custom-background rounded-4"> </div> */}
                                        </p>}

                                        <div className="hr" />

                                    </div>
                                }
                            }}

                        >
                            {searchContent}
                        </ReactMarkdown>}
                    </div>
                    {streamData?.status === TaskStatus.STARTED && <div>
                        <div className="d-flex flex-column placeholder-glow p-2">
                            <div>
                                <span className="placeholder col-4"></span>
                                <span className="placeholder col-12"></span>
                                <span className="placeholder col-12"></span>
                            </div>
                        </div>
                    </div>}
                    {(streamData?.status === TaskStatus.WAITING || streamData?.status === TaskStatus.THINKING) && <LoadingPlaceholder />}
                </SimpleBar>

                <div className="d-flex justify-content-center mt-4 pb-2">
                    <div>
                        <div className="hstack gap-1">
                            <button disabled={isWorking} className="btn btn-secondary btn-searchGPT rounded-4 " onClick={continueSearch}>
                                <div className="hstack gap-1">
                                    {!isWorking ? <i className="bx bx-search  text-danger" /> : <i className="bx bx-hourglass bx-spin font-size-16 align-middle"></i>}
                                    <span>{t("Voir plus")}</span>
                                </div>
                            </button>
                            <button disabled={isWorking} className="btn btn-secondary btn-searchGPT rounded-4 " onClick={startChat}>
                                <div className="hstack gap-1">
                                    <i className="mdi mdi-chat-plus text-danger" />
                                    <span>{t("Conversation")}</span>
                                    <i className="mdi mdi-chevron-up text-danger" />
                                </div>
                            </button>
                        </div>

                    </div>
                </div>
            </motion.div>

            {openChat && <motion.div
                // data-isOn={openChat}
                className="search-chat h-100"
                initial={{
                    opacity: 0,
                }}
                animate={{
                    opacity: 1,
                    transition: {
                        opacity: {
                            duration: 0.25,
                            delay: 0.15,
                        },
                    },
                }}
                exit={{
                    bottom: 0,
                    opacity: 0,
                    transition: {
                        bottom: {
                            duration: 0.4,
                        },
                        opacity: {
                            duration: 0.25,
                        },
                    },
                }}

            >
                <div className="chat-day-title ">
                    <span className="title "><span className="btn rounded-4 custom-background custom-border" onClick={() => setOpenChat(!openChat)}>Conversation <i className=" mdi mdi-chevron-down " /></span></span>
                </div>
                <SimpleBar className="chat-scroller  m-0" scrollableNodeProps={{ ref: displayMessageContainerRef }}>
                    <div className="search-chat-history h-100">
                        {<div className=" p-3"  >

                            <ul className="list-unstyled">
                                {streamData?.messages && map(streamData.messages, message => {
                                    return <li className={message.role === "user" ? "right" : ""} >
                                        <div className="conversation-list ">
                                            {message.role === "user" && <Question message={message} />}
                                            {message.role === "assistant" &&
                                                <Answer message={message}
                                                    agentName={currentConversation?.agentName}
                                                    agentLogo={currentConversation?.agentLogo}
                                                    onOpenAssistant={console.log}
                                                    onSuggestionClicked={console.log}
                                                    assistant={assistant}
                                                    onNewSuggestion={console.log}

                                                />}
                                        </div>
                                    </li>
                                })}
                            </ul>
                        </div>
                        }
                    </div>
                    <div ref={chatRef}></div>
                </SimpleBar>



            </motion.div>}
        </div>
        {openChat && <motion.div

            initial={{
                width: 0,
            }}
            animate={{
                width: "auto",
                transition: {
                    width: {
                        duration: 0.35,
                        delay: 0.1,
                    },
                },
            }}
        >
            <div className="d-flex  justify-content-between   ms-4  me-4 ">
                <div>
                    <div className="suggestions gap-2 ">

                        {map(streamData.suggestions || [], suggestion => {
                            return <motion.div
                                initial={{ scale: 0.2 }}
                                animate={{
                                    scale: 1,
                                    transition: {
                                        scale: {
                                            duration: 0.25,
                                            delay: 0.15,
                                        },
                                    },
                                }}
                                onClick={() => resendPrompt(suggestion.content)} className="suggestion-button flex-grow-1 p-1 border border-info border-opacity-50 rounded-3">
                                {/* <div className="border-bottom pb-1">{capitalize(key)}</div> */}
                                {suggestion.content}
                            </motion.div>
                        })}
                    </div>
                </div>

                <div></div>
            </div>
            <div className=" hstack gap-1 search-chat-input ms-4 me-4 ">
                <div className="hstack border border-secondary border-opacity-50 rounded-3 w-100">
                    <div className="avatar-circle bg-danger bg-opacity-10 rounded-circle p- ms-2">
                        <img src={assistant.logoUrl} width={40} height={40} className="p-1" />
                    </div>
                    <textarea
                        className="form-control border-0 mt-2 border-opacity-50 "
                        placeholder="Just ask"
                        onKeyDown={onSubmit}
                        rows={1}
                        value={question}
                        onChange={e => setQuestion(e.target.value)}
                    />
                </div>

                <div>
                    <button id="openChat" className="btn custom-background rounded-4" onClick={() => setOpenChat(!openChat)}>
                        <i className="mdi mdi-chevron-down font-size-18 fw-bold" />
                        <UncontrolledTooltip target="openChat">
                            {t("Fermer le chat")}
                        </UncontrolledTooltip>
                    </button>
                </div>

            </div>
        </motion.div>}

    </div>
}

export default withTranslation()(SearchChat);