import classNames from "classnames";
import { map } from "lodash";
import React, { useEffect, useState } from "react";
import { Card, CardBody, CardTitle, Col, Collapse, FormGroup, Input, Label, Row } from "reactstrap";
import "react-perfect-scrollbar/dist/css/styles.css";
import { getAssistantConversations, getDatasourceConversations } from "service/assistant";
import { getTime } from "helpers/to-local-date";
import SimpleBar from "simplebar-react";
import { withTranslation } from "react-i18next";

const RightBar = ({ onHideConversations, onConversationSelected, currentConversation, conversations, t }) => {


    return <div className=" rightbar ">
        <div className="d-flex justify-content-between header rounded-1 text-dark p-2 border-bottom">
            <div className="p-2 ps-3">
                <h5><i className="mdi mdi-chat me-2" />Conversations</h5>
                <p>{t("Liste de vos conversations")}</p>
            </div>
            <div> <button className="btn-close " onClick={onHideConversations} /></div>
        </div>
        <div className="p-2">
            <SimpleBar>
                <ul className="list-unstyled assistant-chat-list">

                    {map(conversations, conversation => {
                        const isSelected = currentConversation?.id === conversation.id;

                        return <li onClick={() => onConversationSelected(conversation)} className={" border-top border-secondary border-opacity-25 p-1 " + (isSelected ? "bg-info bg-opacity-25" : "")}> <div className="d-flex justify-content-row">
                            <div className="flex-grow-1 overflow-hidden ps-2">
                                <h5 className="text-truncate font-size-14 mb-1 fw-bold">
                                    {conversation.title}
                                </h5>
                                <p className="text-truncate  mb-0">
                                    {conversation.lastMessage || t("Nouvelle conversation")}
                                </p>
                            </div>
                            {<div className="font-size-11 ms-auto">
                                {getTime(conversation.createdAt)}

                            </div>}
                        </div>
                        </li>
                    })}
                </ul>
            </SimpleBar>
        </div>
    </div>
}

export default withTranslation()(RightBar);
