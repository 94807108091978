import React, { useEffect, useState } from "react";
import {
    ButtonGroup,
    Card,
    Container, DropdownToggle, Row, UncontrolledButtonDropdown, UncontrolledDropdown
} from "reactstrap";
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb2";

//i18n
import { withTranslation } from "react-i18next";
import DesignerBoard from "./board";
import { useSelector, useDispatch } from "react-redux";
import { layoutModeTypes } from "constants/layout";
import { getModels, getPromptStore } from "store/actions";
import { isEmpty } from "lodash";

const AppStudio = props => {
    document.title = "Studio | Jask";

    const dispatch = useDispatch();
    const [studioTheme, setStudioTheme] = useState("studio-dark")

    const { theme, models, prompts } = useSelector(state => ({
        theme: state.Layout.layoutModeType,
        models: state.Assistant.models,
        prompts: state.PromptStore.prompts

    }));

    useEffect(() => {
        if (theme === layoutModeTypes.DARK)
            setStudioTheme("studio-dark")
        else
            setStudioTheme("studio-light")

    }, [theme])


    useEffect(() => {

        if (isEmpty(models)) {
            dispatch(getModels());
        }

    }, [dispatch]);

    useEffect(() => {
        if (isEmpty(prompts))
            dispatch(getPromptStore({}))
    }, [dispatch]);

    return (
        <div className={"studio-dark"} >
            <div data-layout-mode="dark">
                <DesignerBoard />
            </div>
        </div>
    );
};


export default withTranslation()(AppStudio);
