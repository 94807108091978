import axios from "axios";
import { del, get, post, put } from "./api_helper";
import * as url from "./url_helper";

// Gets the logged in user data from local session
const getLoggedInUser = () => {
  const user = localStorage.getItem("user");
  if (user) return JSON.parse(user);
  return null;
};

//is user is logged in
const isUserAuthenticated = () => {
  return getLoggedInUser() !== null;
};

export const postSocialLogin = data => post(url.SOCIAL_LOGIN, data);

// assistant
export const createConversation = id => post(`${url.GET_CONVERSATIONS}/${id}`);
export const getConversations = () => get(url.GET_CONVERSATIONS);
export const getConversationHistory = (conversation_id) => get(`${url.GET_CONVERSATIONS}/${conversation_id}/messages`);
export const deleteConversation = (conversation_id) => del(`${url.GET_CONVERSATIONS}/${conversation_id}`);
export const createOmniChatConversation = data => post(`${url.GET_OMNICHAT_CONVERSATIONS}`, data);
export const getAiModels = () => get(`${url.GET_AI_MODELS}`);
export const renameConversationTitle = (data) => put(`${url.GET_CONVERSATIONS}/${data.id}/rename`, data);


// CRM
export const getCrmOpportunities = (params) => get(`${url.GET_CRM_OPPTY}/${params || ''}`);
export const getCrmContacts = () => get(url.GET_CRM_CONTACTS);
export const getCrmUsers = () => get(url.GET_CRM_USERS);
export const getCrmBoard = (params) => get(`${url.GET_CRM_OPPTY_BOARD}/${params || ''}`);
export const getCrmAccount = (params) => get(`${url.GET_CRM_ACCOUNT}/${params || ''}`);
export const getCrmStages = () => get(`${url.GET_CRM_OPPTY_STAGES}`);
export const getCrmTrend = (params) => get(`${url.GET_CRM_OPPTY_TREND}/${params || ''}`);

//todos 
export const getTodoWorks = () => get(url.GET_TODOS);
export const addTodoWorks = (data) => post(url.GET_TODOS, data);
export const deleteTodoWorks = (id) => del(`${url.GET_TODOS}/${id}`);
export const getDataServices = () => get(`${url.GET_DATA_SERVICES}`);
export const search = data => post(`${url.SEARCH_INFO}`, data);
export const addUserProfileTodoBinders = (data) => post(url.ADD_USER_TODO_BINDERS, data)
export const getWorkspaceSettings = () => get(`${url.GET_WORKSPACE_SETTINGS}`);
export const delUserProfileTodoBinders = (binderId) => del(`${url.ADD_USER_TODO_BINDERS}/${binderId}`);

//templates 
export const processTemplate = (data) => post(url.PROCESS_TEMPLATE, data);
export const createTemplate = (data) => post(url.GET_TEMPLATE, data);
export const getTemplates = () => get(url.GET_TEMPLATE);
export const generateDocumentFromTemplate = (data) => post(url.GENERATE_FROM_TEMPLATE, data);
export const downloadGeneratedDocumentFromTemplate = (data) => post(url.DOWNLOAD_GENERATE_FROM_TEMPLATE, data);
export const delTemplate = (id) => del(`${url.GET_TEMPLATE}/${id}`);
export const getRecentFiles = () => get(url.GET_RECENT_FILES);

// Studio 
export const createStudioApp = (data) => post(url.CREATE_BOARD_APP, data);
export const getStudioApps = () => get(`${url.GET_BOARD_APPS}`);
export const delStudioApp = (id) => del(`${url.GET_BOARD_APPS}/${id}`);

//datasources
export const getDatasourceList = () => get(url.GET_DATASOURCE_LIST);
export const getDatasourceDomainsList = () => get(url.GET_DATASOURCE_DOMAINS_LIST);
export const addNewDatasourceList = Datasource => post(url.ADD_NEW_DATASOURCE_LIST, Datasource);
export const updateDatasourceList = Datasource => put(url.UPDATE_DATASOURCE_LIST, Datasource);
export const deleteDatasourceList = Datasource => del(`${url.DELETE_DATASOURCE_LIST}/${Datasource}`);
export const deleteApplyDatasource = data => del(url.DELETE_APPLY_Datasource, { headers: { data } });
export const getApplyDatasource = () => get(url.GET_APPLY_Datasource);

//users
export const getUsers = () => get(url.GET_USERS);
export const addNewUser = user => post(url.ADD_NEW_USER, user);
export const updateUser = user => put(url.UPDATE_USER, user);
export const deleteUser = user => del(`${url.DELETE_USER}/${user}`);
export const getUserProfile = () => get(url.GET_USER_PROFILE);

//groups 
export const getGroups = () => get(url.GET_GROUPS);
export const addGroup = (data) => post(url.GET_GROUPS, data);
export const deleteGroup = (id) => del(`${url.GET_GROUPS}/${id}`);

//roles 
export const getRoles = () => get(url.GET_ROLES);
export const addRole = (role) => post(url.GET_ROLES, role);
export const deleteRole = (id) => del(`${url.GET_ROLES}/${id}`);

// usages
export const getUsagesBoard = (params) => get(`${url.GET_USAGES_BOARD}${params ? '?' + params : ''}`);
export const getFeedbacksBoard = (params) => get(`${url.GET_FEEDBACKS_BOARD}${params ? '?' + params : ''}`);
export const getFeedbacks = (params) => get(`${url.GET_FEEDBACKS}${params ? '?' + params : ''}`);

//deployments
export const getDeployments = () => get(`${url.GET_DEPLOYMENTS}`);
export const addDeployment = (data) => post(`${url.GET_DEPLOYMENTS}`, data);
export const deleteDeployment = (id) => del(`${url.GET_DEPLOYMENTS}/${id}`);
export const addDeploymentKey = (data) => post(`${url.GET_DEPLOYMENTS}/${data.deploymentId}/keys`, data);
export const delDeploymentKey = (data) => del(`${url.GET_DEPLOYMENTS}/${data.deploymentId}/keys/${data.keyId}`);


//Addons

//Support requests
export const getSupportRequests = (params) => get(`${url.GET_SUPPORT_REQUESTS}${params ? '?' + params : ''}`);
export const updateSupportRequest = (data) => post(`${url.GET_SUPPORT_REQUESTS}`, data);

//Support Platforms
export const addSupportPlatform = (data) => post(`${url.GET_SUPPORT_PLATFORMS}`, data);
export const getSupportPlatforms = (params) => get(`${url.GET_SUPPORT_PLATFORMS}${params ? '?' + params : ''}`);
export const delSupportPlatform = (platformId) => del(`${url.GET_SUPPORT_PLATFORMS}/${platformId}`);

//Board

export const getSupportPlatformBoard = (params) => get(`${url.GET_SUPPORT_PLATFORM_BOARD}/board${params ? '?' + params : ''}`);

// Prompt Store
export const getPromptsFromStore = (params) => get(`${url.GET_PROMPT_STORE}${params ? '?' + params : ''}`);
export const addPromptToStore = (data) => post(`${url.GET_PROMPT_STORE}`, data);
export const delPromptFromStore = (promptId) => del(`${url.GET_PROMPT_STORE}/${promptId}`);

export {
  getLoggedInUser,
  isUserAuthenticated
};
