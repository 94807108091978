import { map } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { withTranslation } from "react-i18next";
import { Badge, Card, CardBody, Collapse } from 'reactstrap';
import classnames from "classnames";
import SimpleBar from 'simplebar-react';
import { getProviderLogo } from 'utils';



const StudioToolbar = ({ apps, assistants, datasources, tools, onNodeClick, onClose, t }) => {

    const [selectedToolBarItem, setSelectedToolBarItem] = useState(null);

    const controls = [
        {
            id: "loop",
            name: "Boucle",
            provider: { type: "loop", name: 'Loop' },
            icon: 'mdi mdi-format-list-bulleted-square me-2 text-info font-size-24',
            output: 'text',
            isDynamicProvider: true,
            description: t('Boucle sur une liste d\'éléments et applique un prompt')
        },
        {
            id: "split",
            name: "Découper",
            provider: { type: "split", name: 'Split' },
            icon: 'mdi mdi-set-split me-2 text-info font-size-24',
            output: 'text',
            description: t('Découpe le contenu fourni (texte, document) selon vos instructions')
        }
    ]

    const toolbar = [
        {
            id: "tools",
            title: "Structure de Contrôles",
            items: controls,
            icon: 'mdi mdi-chat font-size-14  text-danger'
        },
        {
            id: "prompts",
            title: "Prompts Store",
            items: tools,
            icon: 'mdi mdi-chat font-size-14  text-danger'

        },

        {
            id: "apps",
            title: "Mes applications",
            items: apps,
            icon: 'mdi mdi-apps font-size-14  text-danger'

        },
        {
            id: "assistants",
            title: "Mes assistants",
            items: assistants,
            icon: 'bx bx-bot font-size-16 text-danger'
        },
        {
            id: "datasource",
            title: "Mes source de données",
            items: datasources,
            icon: 'mdi mdi-database font-size-14  text-danger'
        }

    ]

    const onItemClicked = (item) => {
        if (item.id === selectedToolBarItem) {
            setSelectedToolBarItem(null);
        } else {
            setSelectedToolBarItem(item.id);
        }
    }

    return <div className='p-2'>
        <div className='d-flex justify-content-between '>
            <div>
                <div className='hstack mb-3'>
                    <i className='bx bx-search-alt-2 font-size-20' />
                    <input
                        type='text'
                        className='form-control rounded-4'
                        style={{ height: '30px', width: '250px' }}
                        placeholder={t("Rechercher...")}
                    />
                </div>

            </div>
            <button className='btn-close mt-1' onClick={onClose} />
        </div>
        <div
            className="accordion accordion-flush "
            id="toolbarStudio"
        >

            {
                map(toolbar, item => {
                    const collapsed = selectedToolBarItem !== item.id;

                    return <div className="accordion-item ">
                        <h2 className="accordion-header" id="headingFlushOne">
                            <button
                                className={classnames(
                                    "accordion-button",
                                    "fw-medium",
                                    { collapsed: !collapsed }
                                )}
                                type="button"
                                onClick={() => onItemClicked(item)}
                                style={{ cursor: "pointer" }}
                            >
                                <div className='hstack gap-1'>
                                    <i className={item.icon} />
                                    <span className='text-dark'>{t(item.title)}({item.items?.length})</span>
                                </div>

                            </button>
                        </h2>

                        <Collapse
                            isOpen={!collapsed}
                            className="accordion-collapse "
                        >
                            <div className="accordion-body p-0 ps-2">
                                <SimpleBar className='nodes'>
                                    {

                                        map(item.items, node => {
                                            return <Card className='node-item border-top p-1 border m-0 mb-1' onClick={() => onNodeClick(structuredClone(node))}>
                                                <CardBody className='p-0 '>
                                                    <div className='hstack gap-1 p-1'>
                                                        {node.logo && <img className="rounded-circle avatar-circle bg-info bg-opacity-10 p" src={node.logo} width={40} height={40} />}
                                                        {node.icon && <i className={node.icon} />}
                                                        <div className=''>
                                                            {node.logoType && getProviderLogo(node.logoType, 24)}
                                                        </div>
                                                        <div className='vstack'>
                                                            <span className='fw-bold'>{node.name}</span>
                                                            <p className='text-wrap'>{node.description || t("Aucune description")}</p>
                                                            <div className=' hstack  gap-1 float- me-1 mb-1 font-size-12'>
                                                                {node.owner && <Badge className='badge badge-soft-danger'><i className='bx bx-user' />{node.owner}</Badge>}
                                                                {node.tags && map(node.tags.slice(0, 2), tag => {
                                                                    return <Badge className='badge badge-soft-info'>{t(tag)}</Badge>
                                                                })}
                                                            </div>

                                                        </div>


                                                    </div>

                                                </CardBody>
                                            </Card>

                                        })
                                    }

                                </SimpleBar>

                            </div>
                        </Collapse>
                    </div>
                })
            }

        </div>
    </div>
}

export default withTranslation()(StudioToolbar);