import { map, truncate } from "lodash"
import React, { useEffect } from "react"
import { Link } from "react-router-dom"
import { Card } from "reactstrap"
import { withTranslation } from "react-i18next";

const ContentRecommendation = ({ recommendations, onOpenAssistant, onSuggestioClicked, t }) => {
    return <div className="chat-recommendations">

        {recommendations?.similarQuestions?.length > 0 && <div className="mt-4">
            <Link><i className="mdi mdi-information" /> {t("Questions liées")}:</Link>
            <ul className="mt-1">
                {
                    map(recommendations.similarQuestions.slice(0, 5), question => {
                        return <li>
                            <Link onClick={() => onSuggestioClicked(question.content)}>
                                {truncate(question.content, { length: 100 })}
                            </Link>
                        </li>
                    })
                }
            </ul>
        </div>}


        {recommendations?.assistants?.length > 0 && <div className="mt-4 mb-4">
            <Link><i className="mdi mdi-information" />{t("Autres assistants liés")}  :</Link>
            <div className="d-flex flew-row flew-wrap  p-2">
                {
                    map(recommendations.assistants.slice(0, 5), assistant => {
                        return <div className="assistant me-2 mb-4" onClick={() => onOpenAssistant(assistant)}>
                            <div className="d-flex flex-column p-2 border border-secondary border-opacity-25 rounded  align-items-center">
                                <img className=" rounded-circle avatar-xs text-center" src={assistant.logo} />
                                <span className="text-muted">{truncate(assistant.name, { length: 10 })}</span>
                            </div>
                        </div>
                    })
                }
            </div>
        </div>}
    </div>
}

export default withTranslation()(ContentRecommendation);
