import React, { useEffect, useState } from "react"
import { Label, Modal, ModalBody, Card, CardBody, Input, NavItem, NavLink, TabContent, TabPane, ModalFooter } from "reactstrap"
import AppExecution from "./runner/content";
import CustomAppRunner from "./runner";


const CustomAppLauncher = ({ show, onCloseClick, todo, app }) => {

    return <Modal size="xl" isOpen={show} toggle={onCloseClick} centered={true}

        contentClassName='window-board-runner '>
        <div className="modal-header bg-primary">
            <div className="text-white">
                <h5>{app?.name} : {todo?.name} </h5>
            </div>
        </div>
        <div className="modal-body">
            <CustomAppRunner app={app} todo={todo} />
        </div>
        <ModalFooter className="bg-primary">

        </ModalFooter>
    </Modal>

}

export default CustomAppLauncher;