

import React, { useEffect, useRef, useState } from "react";
import ReactDiffViewer from 'react-diff-viewer'
import {
    Card,
    CardBody,
    CardFooter,
    CardTitle,
    Col,
    Container, Row
} from "reactstrap";
import SimpleBar from "simplebar-react"

import {
    downloadTemplateGeneratedDocument,
} from "store/actions";
import { useSelector, useDispatch } from "react-redux";

//i18n
import { withTranslation } from "react-i18next";
import { isEmpty, map } from "lodash";
import { downloadGeneratedFile, generateContentFromTemplate } from "service/documents";
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import reHype from 'remark-rehype'
import { Link } from "react-router-dom";
import { uuidv4 } from "utils";
import { saveAs } from "file-saver";

const CompareContent = ({ before, after }) => {

    return <ReactDiffViewer
        oldValue={before}
        newValue={after}
        splitView={true}
    />
}

const ItemToolBar = ({ item, onEdit, showDetails, onShowDetails }) => {

    return <div className="hstack gap-1 mb-2">
        {item.selected && <Link className="" onClick={onShowDetails}><i className={!showDetails ? "  mdi mdi-eye font-size-16" : " mdi mdi-eye-off font-size-16"} /></Link>}
        <Link onClick={onEdit}><i className="mdi mdi-pencil font-size-16" /></Link>
        {item.selected && <Link onClick={onEdit}><i className="mdi mdi-refresh font-size-16" /></Link>}

    </div>
}

const ItemVIew = ({ item, onItemValueChanged }) => {

    const [showDetails, setShowDetails] = useState(false);
    const [showToolBar, setShowToolbar] = useState(false);
    const [edit, setEdit] = useState(false);
    const [value, setValue] = useState(null);

    const handleMouseEnter = () => {
        setShowToolbar(!showToolBar)
    }

    const handleKeyboard = (e) => {

        const { key, v } = e;

        if ((!isEmpty(key) && key === "Enter")) {
            setEdit(!edit);
            setShowToolbar(false)
            onItemValueChanged(item, value);
        }
    }

    useEffect(() => {
        setValue(item.text)
    }, [item])


    switch (item.type) {

        case "Title":
            return <div className="mb-2 mt-2" >
                <div className="hstack gap-2" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseEnter}>
                    {!edit && <h5 className={item.selected ? "bg-danger bg-soft" : ""} >
                        {value}
                    </h5>}

                    {edit && <input
                        className={item.selected ? "bg-danger bg-soft form-control" : "form-control"}
                        value={value}
                        onChange={e => setValue(e.target.value)}
                        onKeyPress={handleKeyboard}
                    />

                    }
                    {showToolBar && <ItemToolBar item={item} onEdit={() => setEdit(!edit)} onShowDetails={() => setShowDetails(!showDetails)} showDetails={showDetails} />}
                </div>
                {item.selected && showDetails && <CompareContent before={item.template} after={value || item.text} />}
            </div>

        case "NarrativeText":
        case "UncategorizedText":

            return <div className="mb-2 mt-2">
                <div className="hstack gap-2">
                    <ReactMarkdown className={item.selected ? "bg-danger bg-soft markdown" : " markdown"} remarkPlugins={[remarkGfm, reHype]}>{item.text}</ReactMarkdown>
                    {item.selected && <Link onClick={() => setShowDetails(!showDetails)}><i className=" font-size-16  mdi mdi-eye" /></Link>}
                </div>
                {item.selected && showDetails && <CompareContent before={item.template} after={item.text} />}
            </div>

        case "ListItem":
            return <div className="ms-4 hstack gap-2">
                <ReactMarkdown className={item.selected ? "bg-danger bg-soft markdown" : " markdown"} remarkPlugins={[remarkGfm, reHype]}>{item.text}</ReactMarkdown>
            </div>
    }
}

const GenerateDocumentContent = ({ context, task }) => {
    const [sections, setSections] = useState({});
    const [docItems, setDocItems] = useState([]);
    const [changeCount, setChangeCount] = useState(null);
    const [downloadLink, setDownloadLink] = useState(null);
    const { accessToken } = useSelector(state => ({
        accessToken: state.Login.accessToken,
    }));

    const dispatch = useDispatch();
    const downloadRef = useRef();

    const onReception = (parsedEvent) => {

        const id = parsedEvent.value.id;
        const type = parsedEvent.type;
        const text = parsedEvent.value.text;

        switch (type) {

            case "BeginTitle":
                setSections(prev => {
                    return {
                        ...prev, [id]: {
                            id: id,
                            type: "Title",
                            text: "",
                            selected: true,
                            template: text
                        }
                    }
                })
                break;
            case "BeginNarrativeText":
                setSections(prev => {
                    return {
                        ...prev, [id]: {
                            id: id,
                            type: "NarrativeText",
                            text: "",
                            selected: true,
                            template: text
                        }
                    }
                })
                break;

            case "content":
                setSections(prev => {
                    const items = { ...prev }
                    const oldContent = items[id];
                    const merge = { ...items, [id]: { ...oldContent, text: oldContent.text + parsedEvent.value.text } }
                    return merge
                })
                break;

            case "end":
            case "EndNarrativeText":
            case "EndTitle":
                break;

            default:
                setSections(prev => {
                    return {
                        ...prev, [id]: {
                            id: id,
                            type: type,
                            text: parsedEvent.value.text
                        }
                    }
                })

                break;

        }
    }
    const onGenerate = () => {

        setSections([])
        const payload = {
            id: task.id,
            templateId: context.template.id,
            source: task.sourceLocation,
            context: context.context,
            stage: 'content'
        }

        generateContentFromTemplate(payload, onReception, null, null, accessToken);

    }

    const onDownload = () => {

        const payload = {
            id: uuidv4(),
            items: docItems,
            templateId: context.template.id,
            sourceLocation: task.sourceLocation,
        }

        downloadGeneratedFile(payload, link => {
            saveAs(link)
        })
    }

    const onItemValueChanged = (item, value) => {
        setSections({ ...sections, [item.id]: { ...item, text: value, updated: true } })
    }

    useEffect(() => {
        setDocItems(prev => Object.keys(sections).map(i => sections[i]))

    }, [sections])

    useEffect(() => {
        setChangeCount(docItems.filter(i => i.selected).length)
    }, [docItems])




    return (<div className=" border workspace-document-maincontent">
        <div className="d-flex  border-bottom ps-2 mt-2 pb-3 pe-3 justify-content-between">
            <div className="hstack gap-2">
                <div className="border bg-danger bg-soft" style={{ width: 15, height: 15 }}></div>
                <span className="font-size-10">Changements effectués par Jask ({changeCount})</span>
            </div>

            <div className="">
                <div className="hstack gap-2">
                    <Link className="text-muted" onClick={onDownload}> <i className="mdi mdi-file-word font-size-14 me-1" />Télécharger</Link>
                    <a hidden ref={downloadRef} to={downloadLink} className="hidden" > <i className="mdi mdi-file-word font-size-14 me-1" />Télécharger</a>
                    <Link className="text-muted" onClick={onGenerate}> <i className="mdi mdi-refresh font-size-14 me-1" />Regenerer</Link>
                </div>

            </div>
        </div>
        <SimpleBar className="rich-text-editor">

            {isEmpty(sections) && <div className="d-flex justify-content-center flex-column align-items-center  confirm-generation-panel" >
                <div className="d-flex flex-column align-items-center mb-4">
                    <h5 className="text-muted font-size-18">La structure vous convient ? </h5>
                    <small style={{ width: 250, textAlign: "center" }}> Les éléments selectionnés seront contextualisé à partir du contexte ci-dessous:</small>
                </div>

                <div className="p-4  mb-4 d-flex justify-content-center align-items-center">

                    <Card>
                        <CardTitle className="">
                            <div className="pt-4 ps-4 pb-1 border-bottom">
                                <h6 className="font-size-16 text-muted">Rappel du contexte</h6>
                            </div>

                        </CardTitle>
                        <CardBody>
                            {<p>{context.context}</p>}
                        </CardBody>

                        <CardFooter className="border-top">
                            <div className="hstack float-end">
                                <button className="btn btn-success me-2 font-size-12" onClick={onGenerate} > Démarrer </button>
                                <button className="btn btn-secondary  font-size-12" >Annuler </button>
                            </div>
                        </CardFooter>
                    </Card>
                    {/* <textarea
                        className="form-control"
                        rows={2}
                        value={context}
                        style={{ width: 500 }}

                    /> */}
                </div>

            </div>}

            {/* {content &&
                <div className="p-4"> <ReactMarkdown remarkPlugins={[remarkGfm, reHype]}>{content}</ReactMarkdown>
                </div>} */}
            <div className="p-4">
                {map(docItems, item => {
                    return <ItemVIew item={item} onItemValueChanged={onItemValueChanged} />

                })}
            </div>
        </SimpleBar>
    </div>
    );
};


export default withTranslation()(GenerateDocumentContent);

