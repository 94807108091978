import React, { useEffect, useState } from "react";
import {
    Card,
    CardBody,
    CardTitle,
    Container
} from "reactstrap";

import {
    getSupportPlatforms, delSupportPlatform,
} from "store/actions";

import { useSelector, useDispatch } from "react-redux";
import { withTranslation } from "react-i18next";
import { isEmpty, map } from "lodash";
import DeleteModal from "components/Common/DeleteModal";
import { Link, useNavigate } from "react-router-dom";
import SimpleBar from "simplebar-react"
import AddSupportTeam from "./addTeam"

const SupportTeamList = props => {
    const dispatch = useDispatch();

    const [showAddTeam, setShowAddTeam] = useState(false);
    const [showDelTeam, setShowDelTeam] = useState(false);
    const [selectedTeam, setSelectedTeam] = useState(null);

    const { assistants, platforms } = useSelector(state => ({
        assistants: state.Studio.assistants,
        platforms: state.Support.platforms,
    }));

    const openTeam = (team) => {
        setSelectedTeam(team);
        setShowAddTeam(!showAddTeam);
    }
    const deleteTeam = () => {
        dispatch(delSupportPlatform(selectedTeam?.id));
        setShowDelTeam(!showDelTeam);
    }

    const onDeleteStarted = (team) => {
        setSelectedTeam(team);
        setShowDelTeam(!showDelTeam);
    }

    const oncreateTeam = () => {
        setSelectedTeam(null);
        setShowAddTeam(!showAddTeam);
    }
    useEffect(() => {
        if (isEmpty(platforms)) {
            dispatch(getSupportPlatforms())
        }
    }, [dispatch])



    return (<div className="support-teams" >

        <AddSupportTeam
            show={showAddTeam}
            onCloseClick={() => setShowAddTeam(!showAddTeam)}
            team={selectedTeam}
        />

        <DeleteModal
            show={showDelTeam}
            onCloseClick={() => setShowDelTeam(false)}
            text={props.t("Voulez-vous vraiment supprimer cette équipe ?")}
            onDeleteClick={deleteTeam}
        />

        <SimpleBar className="scroller">
            <div className="d-flex flex-wrap app-list-mini">
                {
                    map(platforms, item => {
                        const members = assistants.filter(a => item.members.includes(a.id));
                        return <Card className="app border border-info rounded-3 m-2 border-opacity-25" onClick={() => openTeam(item)}>
                            <div className="d-flex justify-content-between">

                                <div className="app-content pt-3 ps-3">
                                    <CardTitle>{item.name}
                                    </CardTitle>
                                    {item.description}
                                </div>
                                <div className=" p-1">
                                    <button className="btn-close" onClick={() => onDeleteStarted(item)} />
                                </div>
                            </div>
                            <div className="card-footer pb-2">
                                <div className="avatar-group">
                                    {
                                        map(members, member => {
                                            return <div className="avatar-group-item bg-primary bg-opacity-25">
                                                <Link to="#" className="d-inline-block">
                                                    <img src={member.logoUrl} alt="" className="rounded-circle avatar-xs" />
                                                </Link>
                                            </div>
                                        })
                                    }
                                </div>

                            </div>

                        </Card>
                    })
                }
                <Card className="border border-info rounded-3 m-2 app border-opacity-25" onClick={oncreateTeam}>
                    <CardBody className="">
                        <div className="d-flex justify-content-center h-100 align-items-center">
                            <i className="mdi mdi-plus app-add-button" />
                        </div>
                    </CardBody>
                </Card>
            </div>
        </SimpleBar>
    </div>

    );
};


export default withTranslation()(SupportTeamList);