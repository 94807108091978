import { GET_DEPLOYMENTS, GET_DEPLOYMENTS_SUCCESS, ADD_DEPLOYMENT, ADD_DEPLOYMENT_SUCCESS, GET_DEPLOYMENTS_FAILED, ADD_DEPLOYMENT_FAILED, ADD_DEPLOYMENT_KEY, ADD_DEPLOYMENT_KEY_SUCCESS, ADD_DEPLOYMENT_KEY_FAILED, DEL_DEPLOYMENT_SUCCESS } from "./actionTypes"

const INIT_STATE = {
  deployments: [],
  isLoading: false,
  isAdding: false,
  newDeployment: null,
  error: {},
}

const deployments = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_DEPLOYMENTS:
      return {
        ...state,
        isLoading: true
      }

    case GET_DEPLOYMENTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        deployments: action.payload,
      }

    case GET_DEPLOYMENTS_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      }

    case ADD_DEPLOYMENT:
      return {
        ...state,
        newDeployment: null,
        isAdding: true
      }

    case ADD_DEPLOYMENT_SUCCESS:
      return {
        ...state,
        isAdding: false,
        newDeployment: action.payload,
        deployments: [action.payload, ...state.deployments]
      }


    case ADD_DEPLOYMENT_FAILED:
      return {
        ...state,
        isAdding: false,
        error: action.payload,
      }


    case ADD_DEPLOYMENT_KEY:
      return {
        ...state,
        isAdding: true
      }

    case DEL_DEPLOYMENT_SUCCESS:
      return {
        ...state,
        isAdding: false,
        deployments: state.deployments.filter(d => d.id !== action.payload)
      }


    case ADD_DEPLOYMENT_KEY_SUCCESS:
      const remaining = state.deployments?.filter(d => d.id !== action.payload.id);

      return {
        ...state,
        isAdding: false,
        deployments: [action.payload, ...remaining]
      }

    case ADD_DEPLOYMENT_KEY_FAILED:
      return {
        ...state,
        isAdding: false,
        error: action.payload,
      }

    default:
      return state
  }
}

export default deployments;