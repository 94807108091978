import React, { memo, useEffect, useState, useCallback } from 'react';
import { Handle, Position, useUpdateNodeInternals, useStore, useHandleConnections, useReactFlow } from '@xyflow/react';
import { Badge, Card, CardFooter, Collapse, DropdownItem, DropdownMenu, DropdownToggle, Label, UncontrolledDropdown } from 'reactstrap';
import classnames from "classnames";
import { isEmpty, map } from 'lodash';
import { runPromptOptimization } from 'service/tasks';
import { useSelector } from "react-redux";
import { withTranslation } from "react-i18next";

import rehypeRaw from 'rehype-raw'
import remarkGfm from "remark-gfm";
import ReactMarkdown from 'react-markdown'
import MultiHandle from '../../handle/MultiHandle';
import SingleHandle from '../../handle/SingleHandle';

const inputTypes = [
    {
        id: 'all',
        title: 'Texte et fichiers ',
        description: 'Concatenation du texte et du contenu des fichiers'
    },
    {
        id: 'textOnly',
        title: 'Texte',
        description: 'Appliquer les instructions le texte fourni'
    },
    {
        id: 'fileOnly',
        title: 'Fichiers',
        description: 'Appliquer les instructions sur chaque fichier uniquement'
    },
    {
        id: 'rowOnly',
        title: 'Ligne par ligne',
        description: 'Appliquer les instructions sur chaque ligne'
    },
]

const handleStyle = { left: -5, top: 10 };


const getStatusClassName = (status) => {

    if (!status)
        return "text-danger";
    else {
        return "text-success";
    }
}

const ForEachNode = ({ llms, data, onChange, type, t }) => {
    const [collapsed, setCollapsed] = useState(false);
    const [nodeName, setNodeName] = useState(data.name);
    const [promptId, setPromptId] = useState(data.promptId);
    const [promptOwner, setPromptOwner] = useState(data.promptOwner);
    const flow = useReactFlow();
    const nodeUpdater = useUpdateNodeInternals();
    const [selectedModel, setSelectedModel] = useState({
        id: data?.modelId,
        title: data?.modelName
    });
    const [instructions, setInstructions] = useState(data?.content);
    const [parameters, setParameters] = useState([]);
    const [nodeStatus, setNodeStatus] = useState(false);
    const [isLoading, setIsLoading] = useState(data?.isLoading);
    const [promptSourceId, setPromptSourceId] = useState(data?.loop?.promptSourceId);
    const [itemsSourceId, setItemsSourceId] = useState(data?.loop?.itemsSourceId);

    const { accessToken } = useSelector(state => ({
        accessToken: state.Login.accessToken,
    }));

    const onModelChange = (model) => {
        setSelectedModel(model);
    }

    const optimizePrompt = () => {
        setCollapsed(true);
        setIsLoading(true);
        runPromptOptimization(instructions, onPromptOptimizationStart, onPromptToken, onPromptOptimizationStop, accessToken);
    }

    const onPromptOptimizationStart = () => {
        setInstructions(prev => "");
    }

    const onPromptToken = (token) => {
        setInstructions(prev => `${prev}${token}`);
    }

    const onPromptOptimizationStop = (status) => {
        setIsLoading(false);
    }

    const onHandleValueChanged = (param, value) => {
        const items = [...parameters];

    }

    const addDynamicLink = (sourceId) => {
        const sourceNode = flow.getNode(sourceId);

        if (!sourceNode)
            return;


        const nodeParams = sourceNode.data?.parameters || [];

        const newNode = {
            ...sourceNode,
            data: {
                ...sourceNode.data,
                parameters: nodeParams.map(p => {

                    return {
                        ...p,
                        source: data?.id,
                        defaultValue: `@${nodeName}`,
                        isDynamic: true,
                    }
                })
            }
        }

        updateFlowNode(newNode);
        setPromptSourceId(sourceId);
    }

    const removeDynamicLink = (sourceId) => {
        const sourceNode = flow.getNode(sourceId);

        if (!sourceNode)
            return;
        const nodeParams = sourceNode.data?.parameters || [];

        nodeParams.forEach(param => {
            console.log(param, sourceId)
            if (param.source === data?.id) {
                param.source = null;
                param.defaultValue = "";
                param.isLoopInjected = false;
            }
        });

        const newNode = {
            ...sourceNode,
            data: {
                ...sourceNode.data,
                parameters: [...nodeParams]
            }
        }
        updateFlowNode(newNode);
        setPromptSourceId(null);
    }


    const updateFlowNode = (sourceNode) => {
        flow.updateNode(sourceNode.id, sourceNode);
    }

    const onParamConnected = (paramId, paramHandleId, sourceId) => {
        console.log(paramId)
        switch (paramId) {

            case "prompt":
                if (!isEmpty(sourceId)) {
                    addDynamicLink(sourceId);

                } else {
                    removeDynamicLink(promptSourceId)
                }
                break;

            case "items":
                setItemsSourceId(sourceId)
                break;
        }
    }

    useEffect(() => {
        if (isEmpty(selectedModel?.id)) {
            setSelectedModel(llms[0]);
        }
    }, [llms, selectedModel]);

    // console.log("Updating...", data)

    useEffect(() => {

        const hasValidParams = !isEmpty(promptSourceId) && !isEmpty(itemsSourceId);

        onChange({
            ...data,
            name: nodeName,
            modelId: selectedModel?.id,
            modelName: selectedModel?.title,
            promptId: promptId,
            promptOwner: promptOwner,
            instructions: instructions,
            isLoading: isLoading,
            loop: {
                promptSourceId: promptSourceId,
                itemsSourceId: itemsSourceId
            },
            isValid: !isEmpty(nodeName) && !isEmpty(selectedModel?.id) && hasValidParams
        })
    }, [nodeName, selectedModel, instructions, isLoading, promptSourceId, itemsSourceId])

    useEffect(() => {
        setNodeStatus(data.isValid);
    }, [data])

    return <Card style={{ maxWidth: '350px' }}>
        <div className="accordion accordion-flush" id="accordion">
            <div className="accordion-item">
                <div className="accordion-header" id="headingOne">
                    <button
                        className={classnames(
                            "accordion-button",
                            "fw-medium",
                            { collapsed: !collapsed }
                        )}
                        type="button"
                        onClick={() => setCollapsed(!collapsed)}
                        style={{ cursor: "pointer" }}
                    >
                        <div className='vstack'>
                            <div className="title hstack gap-0">
                                {data.icon && <i className={data.icon + " font-size-20"} />}
                                <input className="border-0 form-control" style={{ background: 'transparent' }} type='text' value={nodeName} onChange={e => setNodeName(e.target.value)} />

                            </div>
                            <small className='ps-4'>{data.description}</small>
                            <div className="subline ">
                                {nodeStatus && <div className="text-success ms-4"><small > Valide </small><i className='mdi mdi-check-circle' /></div>}
                                {!nodeStatus && <div className="text-warning ms-4"><small > Invalide </small><i className='mdi mdi-alert' /></div>}
                            </div>
                        </div>


                    </button>
                </div>

                <Collapse isOpen={collapsed}>
                </Collapse>
                <div className='pb-2'>
                    <MultiHandle
                        id={"items"}
                        nodeId={data?.id}
                        type="target"
                        position={Position.Left}
                        label={t("Liste d'éléments")}
                        index={0}
                        onTargetConnected={onParamConnected}
                    // onStaticValueChange={(id, value) => onHandleValueChanged(param, value)}
                    // handleValue={param.defaultValue}
                    />

                    <MultiHandle
                        id={"prompt"}
                        nodeId={data?.id}
                        type="target"
                        position={Position.Left}
                        label={t("Prompt à appliquer")}
                        index={1}
                        onTargetConnected={onParamConnected}
                    // onStaticValueChange={(id, value) => onHandleValueChanged(param, value)}
                    // handleValue={param.defaultValue}
                    />


                </div>

                <SingleHandle nodeId={data?.id} type="source" position={Position.Right} />
            </div>




            {/* <Handle type="target" position={Position.Left} id='1' key={"1"} />
            <Handle type="target" position={Position.Left} id='2' key={"2"} />
            <Handle type="source" position={Position.Right} /> */}

        </div>
        <CardFooter className='border-top'>
            <div className='d-flex justify-content-between'>
                <div>


                </div>
                <div className=''>
                    <div className='hstack gap-1 mt-1'>
                        <Badge className='badge badge-soft-info'>{data.owner}</Badge>
                        {data.tags && map(data.tags.slice(0, 2), tag => {
                            return <Badge className='badge badge-soft-info'>{tag}</Badge>
                        })}
                    </div>

                </div>
            </div>
        </CardFooter>
    </Card >
}

export default withTranslation()(ForEachNode);