import { isEmpty, map } from "lodash"
import React, { useState } from "react"
import { Link } from "react-router-dom"
import SimpleBar from "simplebar-react"

import {
    Card,
    CardBody,
    Col,
    Collapse,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Row,
    UncontrolledAlert,
    UncontrolledDropdown,
} from "reactstrap"
import RichTextEditor from "./text"
import EditorAssistant from "./assistant"
import LandingDocumentGenerator from "./landing"
import { uuidv4 } from "utils"


const DocumentEditor = () => {

    const [conf, setConf] = useState(null);
    const [task, setTask] = useState(null);
    const [isLanding, setIsLanding] = useState(true);

    const onConfigChange = (conf) => {
        setConf(conf);
    }

    const onStartGeneration = (request) => {
        const payload = {
            id: uuidv4(),
            ...conf, ...request
        }
        setTask(payload);
        setIsLanding(false);

    }

    return (
        <React.Fragment>
            <div className="workspace-documents-editor">
                <div className="d-flex flex-column">
                    <div className="d-xl-flex">
                        <div className="w-100">
                            <div className="d-md-flex">
                                <div className="w-100 me-2">
                                    {!task?.id && <LandingDocumentGenerator onStartGeneration={onStartGeneration} />}
                                    {task?.id &&
                                        <RichTextEditor task={task} />
                                    }

                                </div>
                                <EditorAssistant onConfigChange={onConfigChange} isLanding={isLanding} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>)
}

export default DocumentEditor;
