import React, { Fragment, useEffect, useState } from "react"
import SimpleBar from "simplebar-react";
import { GET_BOARD_EXEC_PLAN } from "helpers/url_helper";
import { post } from "helpers/api_helper";
import { isEmpty, map } from "lodash";
import { Link } from "react-router-dom";
import { Badge, Spinner } from "reactstrap";

const AppLineage = ({ board, selectedStep, onStepSelected, plan, onNewPlan, onRunClick, isRunning }) => {


    useEffect(() => {
        if (!isEmpty(board)) {
            post(GET_BOARD_EXEC_PLAN, board).then(resp => {
                onNewPlan(resp);
                onStepSelected(resp[0]);
            })
        }
    }, [board]);


    return <div className="board-execution-plan bg-secondary bg-opacity-25 ">
        <ul className="list-unstyled  ">

            <SimpleBar className="scroller">
                {
                    map(plan, (item, index) => {
                        const current = selectedStep?.id === item.id;

                        return <li key={item.id} className={current ? "active" : ""} onClick={console.log}>
                            <Link onClick={() => onStepSelected(item)}>

                                <div className="hstack">
                                    <span className="number">{index + 1}</span>
                                    <div className="vstack gap-1">

                                        <div className="d-flex justify-content-between">
                                            <span>{item.name}</span>
                                            {item.isRunning === true && <Spinner className="ms-2" color="primary" size="sm" />
                                            }
                                        </div>
                                        <div>
                                            <Badge className="badge badge-soft-info">{item.modelName || item.modelId}</Badge>
                                        </div>
                                    </div>
                                </div>

                            </Link>
                        </li>

                    })
                }
            </SimpleBar>
            <li>   <div className="d-flex justify-content-center">
                <button className="btn btn-soft-info  mb-4" onClick={onRunClick} disabled={isRunning}>
                    <i className="fa fa-play" />
                    <span> Executer</span></button>
            </div></li>
        </ul>

    </div>

}

export default AppLineage;