import PropTypes from 'prop-types'
import React, { useEffect, useState } from "react"
import { Alert, Card, CardBody, Col, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner } from "reactstrap"
import { post } from "helpers/api_helper";
import { GET_DIRECTORY } from "helpers/url_helper";
import Select from "react-select"
import { Link } from 'react-router-dom';
import Slider from 'react-rangeslider';
import "react-rangeslider/lib/index.css"
import { estimateIndexing, indexWorkspaceItems } from 'service/indexing';
import { operationCompleted, operationSucceed } from 'utils/notifications';

const strategies = [
    {
        label: "Rapide",
        value: "fast",
        description: " Indexation plus rapide et moins coûteuse, se basant uniquement sur le contenu textuel . Peut s'averer suffisant dans la plupart des cas.",
        color: "info"
    },
    {
        label: "Approfondie",
        value: "advanced",
        description: " Indexation plus lente et plus coûteuse.  Optimale si le contenu de vos documents contient des éléments enrichis (tableau, graphiques, images, ...).",
        color: "info"
    },

]

const splitter = [
    {
        label: "Page",
        value: "page",
        description: " Indexation plus rapide et moins coûteuse, se basant uniquement sur le contenu textuel . Peut s'averer suffisant dans la plupart des cas.",
        color: "info",
        canCustom: false,
    },
    {
        label: "Charactères",
        value: "chars",
        description: " Indexation plus lente et plus coûteuse.  Optimale si le contenu de vos documents contient des éléments enrichis (tableau, graphiques, images, ...).",
        color: "danger",
        min: 400,
        max: 10000,
        canCustom: true,
        recommendedSize: 3000,
        sizeLabel: 'Nombre de chars.'

    },
    {
        label: "Tokens",
        value: "tokens",
        description: " Indexation plus lente et plus coûteuse.  Optimale si le contenu de vos documents contient des éléments enrichis (tableau, graphiques, images, ...).",
        color: "danger",
        min: 400,
        max: 4000,
        canCustom: true,
        recommendedSize: 2000,
        sizeLabel: 'Nombre de tokens.'
    }
]

const ContentIndexor = ({ locations, show, onCloseClick }) => {

    const [selectedMode, setSelectedMode] = useState(strategies[0]);
    const [showAdvanced, setShowAdvanced] = useState(false);
    const [selectedSplitter, setSelectedSplitter] = useState(splitter[0]);
    const [chunkSize, setChunkSize] = useState(100);
    const [canSubmit, setCanSubmit] = useState(false);
    const [isWorking, setIsWorking] = useState(false);
    const [costEstimation, setCostimation] = useState(null);

    const submit = () => {

        setIsWorking(true);

        const payload = {
            locations: locations,
            mode: selectedMode?.value,
            splitter: selectedSplitter?.value,
            chunkSize: chunkSize,
        }

        indexWorkspaceItems(payload).then(resp => {
            operationSucceed("Indexation terminée avec succès.")
        }).finally(() => {
            setIsWorking(false);
        })
    }

    const estimateCost = () => {
        setIsWorking(true);

        const payload = {
            locations: locations,
            mode: selectedMode?.value,
            splitter: selectedSplitter?.value,
            chunkSize: chunkSize,
        }

        estimateIndexing(payload).then(resp => {
            setCostimation(resp);
        }).finally(() => {
            setIsWorking(false);
        })
    }


    useEffect(() => {

        if (selectedSplitter?.canCustom) {
            setChunkSize(selectedSplitter.recommendedSize)
        }
    }, [selectedSplitter])

    useEffect(() => {
        setCostimation(null);
    }, [selectedMode])


    return (
        <Modal size="md" isOpen={show} toggle={onCloseClick} centered={true}>
            <ModalHeader>
                <h5>Nouvelle indexation
                </h5>
                <button type="button" onClick={onCloseClick} className="btn-close position-absolute end-0 top-0 m-3"></button>
            </ModalHeader>
            <div className="modal-content">
                <ModalBody className="px-4 py-4 ">
                    <Card>
                        <CardBody>

                            <h6 className=''>
                                <i className='mdi mdi-cog me-1' />
                                Stratégie d'indexation:</h6>

                            <div className='p-2 '>
                                <Select
                                    options={strategies}
                                    value={selectedMode}
                                    onChange={e => setSelectedMode(e)}
                                    className='select2-selection'
                                />

                            </div>


                            <div className='mt-4'>

                                <div className=''>
                                    <Alert className='' color={selectedMode?.color}>
                                        <div> <i className="mdi mdi-information  font-size-18 me" /> <b>Bon à savoir</b> : {selectedMode?.description}</div>
                                        <div className='d-flex justify-content-'>
                                            <Link onClick={estimateCost} to={"#!"} className=' mt-2'><i className='mdi mdi-currency-usd-circle' /> Estimer le coût</Link>
                                        </div>
                                    </Alert>
                                </div>

                                {costEstimation && <div className=''>
                                    <Alert className='' color="warning">
                                        <div> <i className="mdi mdi-information  font-size-18 me" /> <b>Estimation de coùt</b> : Le nombre total de tokens est estimé a <b>{new Intl.NumberFormat('fr-FR').format(costEstimation.tokens)}</b> et une estimation de coùt de $ <b>{costEstimation.cost.toFixed(6)}</b>. Cette estimation ne prend pas en compte d'éventuelles analyse d'images,etc. </div>
                                        <div className='d-flex justify-content-'>
                                            <Link onClick={estimateCost} to={"#!"} className=' mt-2'><i className='mdi mdi-currency-usd-circle' /> Estimer le coût</Link>
                                        </div>
                                    </Alert>
                                </div>}
                            </div>


                            {selectedMode?.value !== "fast" && <div className='mt-4'>
                                <Link className=' mt-2' onClick={() => setShowAdvanced(!showAdvanced)}> Options avancées {!showAdvanced ? <i className='mdi mdi-chevron-right-circle' /> : <i className='mdi mdi-chevron-down-circle' />}</Link>
                                {showAdvanced && <div className='p-4 border mt-3'>

                                    <div>
                                        <h5 className="font-size-14 mb-3 mt-0">Découpage:</h5>
                                        <Select
                                            options={splitter}
                                            value={selectedSplitter}
                                            onChange={e => setSelectedSplitter(e)}
                                            className='select2-selection'
                                        />
                                    </div>
                                    {selectedSplitter?.canCustom && <div className="p-1  mt-3">
                                        <h5 className="font-size-14 mb-3 mt-0">{selectedSplitter?.sizeLabel} ({chunkSize})</h5>
                                        <span className="float-start mt-4">{selectedSplitter?.min}</span>{" "}
                                        <span className="float-end mt-4">{selectedSplitter?.max}</span>
                                        <Slider
                                            min={selectedSplitter?.min}
                                            max={selectedSplitter?.max}
                                            step={10}
                                            // format={formatkg}
                                            value={chunkSize}
                                            onChange={value => {
                                                setChunkSize(value)
                                            }}
                                        />
                                    </div>}


                                </div>}
                            </div>}
                        </CardBody>
                    </Card>

                </ModalBody>
                <ModalFooter>
                    <div className="hstack gap-2 ">
                        <button type="button" className="btn btn-success" disabled={isWorking} onClick={submit}>Indexer</button>
                        <button type="button" className="btn btn-secondary" onClick={onCloseClick}>Annuler</button>
                        {isWorking && <div className='vr' />}

                        {isWorking && <Spinner size={"md"} />}
                    </div>
                </ModalFooter>
            </div>
        </Modal>
    )
}

ContentIndexor.propTypes = {
    onCloseClick: PropTypes.func,
    onDeleteClick: PropTypes.func,
    show: PropTypes.any
}

export default ContentIndexor
