import { ADD_TEMPLATE, ADD_TEMPLATE_FAILED, ADD_TEMPLATE_SUCCESS, DEL_TEMPLATE, DEL_TEMPLATE_FAILED, DEL_TEMPLATE_SUCCESS, DOWNLOAD_GENERATED_TEMPLATE, DOWNLOAD_GENERATED_TEMPLATE_FAILED, DOWNLOAD_GENERATED_TEMPLATE_SUCCES, GET_RECENT_FILES, GET_RECENT_FILES_FAILED, GET_RECENT_FILES_SUCCESS, GET_TEMPLATE, GET_TEMPLATE_FAILED, GET_TEMPLATE_SUCCESS, PROCESS_DOC_TEMPLATE, PROCESS_DOC_TEMPLATE_FAILED, PROCESS_DOC_TEMPLATE_SUCCESS, SUBMIT_GENERATION_TASK, SUBMIT_GENERATION_TASK_FAILED, SUBMIT_GENERATION_TASK_SUCCES } from "./actionTypes"

export const generateDocument = (data) => ({
  type: SUBMIT_GENERATION_TASK,
  payload: data,
})

export const generateDocumentSuccess = data => ({
  type: SUBMIT_GENERATION_TASK_SUCCES,
  payload: data,
})

export const generateDocumentFailed = error => ({
  type: SUBMIT_GENERATION_TASK_FAILED,
  payload: error,
});

export const processTemplate = (data) => ({
  type: PROCESS_DOC_TEMPLATE,
  payload: data
})

export const processTemplateSuccess = (data) => ({
  type: PROCESS_DOC_TEMPLATE_SUCCESS,
  payload: data,
})

export const processTemplateFailed = (error) => ({
  type: PROCESS_DOC_TEMPLATE_FAILED,
  payload: error,
})


export const addTemplate = (data) => ({
  type: ADD_TEMPLATE,
  payload: data,
})


export const addTemplateSuccess = (data) => ({
  type: ADD_TEMPLATE_SUCCESS,
  payload: data,
})


export const addTemplateFailed = (error) => ({
  type: ADD_TEMPLATE_FAILED,
  payload: error,
})


export const getTemplates = () => ({
  type: GET_TEMPLATE
})


export const getTemplatesSuccess = (data) => ({
  type: GET_TEMPLATE_SUCCESS,
  payload: data
})


export const getTemplatesFailed = (error) => ({
  type: GET_TEMPLATE_FAILED,
  payload: error
})

export const delTemplates = (data) => ({
  type: DEL_TEMPLATE,
  payload: data
})

export const delTemplatesSuccess = (data) => ({
  type: DEL_TEMPLATE_SUCCESS,
  payload: data

})

export const delTemplatesFailed = (error) => ({
  type: DEL_TEMPLATE_FAILED,
  payload: error

})


export const downloadTemplateGeneratedDocument = (data) => ({
  type: DOWNLOAD_GENERATED_TEMPLATE,
  payload: data,
})

export const downloadTemplateGeneratedDocumentSuccess = (data) => ({
  type: DOWNLOAD_GENERATED_TEMPLATE_SUCCES,
  payload: data,
})

export const downloadTemplateGeneratedDocumentFailed = (error) => ({
  type: DOWNLOAD_GENERATED_TEMPLATE_FAILED,
  payload: error,
})



export const getRecentFiles = () => ({
  type: GET_RECENT_FILES
})

export const getRecentFilesSuccess = (data) => ({
  type: GET_RECENT_FILES_SUCCESS,
  payload: data
})

export const getRecentFilesFailed = (error) => ({
  type: GET_RECENT_FILES_FAILED,
  payload: error
})