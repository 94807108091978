import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { Card, CardBody, CardTitle, Col, Row } from "reactstrap";
import { truncateFromMiddle } from "utils";
import ReactApexChart from "react-apexcharts";
import { isEmpty } from "lodash";

const SupportPlatformBoardHisto = ({ board, t }) => {
    const [series, setSeries] = useState([]);
    const [labels, setLabels] = useState([]);
    const [maxY, setMaxY] = useState(100);

    const options = {
        chart: {
            height: 300,
            type: 'bar',
            zoom: {
                enabled: false
            },
            toolbar: {
                show: false
            }
        },
        // colors: apaexlineChartColors,
        dataLabels: {
            enabled: false,
        },
        stroke: {
            width: [3, 3],
            curve: 'straight'
        },
        title: {
            text: t('Support des 7 derniers jours '),
            align: 'left',
            style: {
                fontWeight: '50',
            },
        },
        grid: {
            row: {
                colors: ['transparent', 'transparent'], // takes an array which will be repeated on columns
                opacity: 1
            },
            borderColor: '#f1f1f1'
        },
        markers: {
            style: 'inverted',
            size: 6
        },
        xaxis: {
            categories: labels,
            title: {
                text: 'Date',
                offsetY: 100,
            }
        },
        yaxis: {
            title: {
                text: 'Nombre de requêtes'
            },
            min: 0,
            max: maxY
        },
        legend: {
            position: 'top',
            horizontalAlign: 'right',
            floating: true,
            offsetY: -35,
            offsetX: -5
        },
        responsive: [{
            breakpoint: 600,
            options: {
                chart: {
                    toolbar: {
                        show: false
                    }
                },
                legend: {
                    show: false
                },
            }
        }]
    }


    useEffect(() => {

        if (isEmpty(board))
            return;


        setMaxY(Math.max(board.dailyMetrics.map(i => i.total)));

        setSeries([{
            name: "Total",
            data: board.dailyMetrics.map(i => i.total)
        },
        {
            name: "Traitées",
            data: board.dailyMetrics.map(i => i.totalReplied)
        },
        {
            name: "Postées",
            data: board.dailyMetrics.map(i => i.totalPosted)
        },

        ]);
        setLabels(board.dailyMetrics.map(i => new Date(i.date).toLocaleDateString("fr-FR")))

    }, [board])

    return (
        <React.Fragment>
            <Card className="board-card-mini border border-secondary border-opacity-25">
                <CardBody>
                    <ReactApexChart
                        options={options}
                        series={series}
                        type="bar"
                        height="300"
                        className="apex-charts"
                    />
                </CardBody>
            </Card>

        </React.Fragment>
    );
};


export default withTranslation()(SupportPlatformBoardHisto);
