import React, { useEffect, useState } from "react"
import { Label, Modal, ModalBody, Card, CardBody, Input, NavItem, NavLink, TabContent, TabPane, ModalFooter, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Badge } from "reactstrap"
import ExportGeneration from "components/Export";
import { ExportType } from "constants/export";
import FileSaver from "file-saver"
import Dropzone from "react-dropzone";
import classnames from "classnames"
import ScenarioPicker from "./scenarioPicker";
import { isEmpty } from "lodash";
import TaskFilePicker from "./filePicker";
import TaskInstruction from "./instructions";
import TaskResult from "./result";

const FileTaskLauncher = ({ show, onCloseClick }) => {
    const [activeTab, setactiveTab] = useState(1)
    const [passedSteps, setPassedSteps] = useState([1]);
    const [scenario, setScenario] = useState(null);
    const [files, setFiles] = useState([]);
    const [instructions, setInstructions] = useState([]);

    const onScenarioSelected = (scenario) => {
        setScenario(scenario);
        setactiveTab(2);
    }

    const onFilesSelected = (files) => {
        setPassedSteps([...passedSteps, 2])
        setactiveTab(3);
        setFiles(files);
    }

    const onInstructionCompleted = (config) => {
        setPassedSteps([...passedSteps, 3])
        setactiveTab(4);
        setInstructions(config);
    }

    return <React.Fragment>
        <Modal size="xl"
            isOpen={show}
            toggle={onCloseClick}
            centered={true}
            contentClassName='file-task-window'
            fade={false}
        >

            <div className="d-flex justify-content-between pt-4 ps-4 pe-4 border-bottom pb-2">
                <div className="">
                    <h5>Traitement de fichiers</h5>
                    {!isEmpty(scenario?.id) && <div><Badge>Scenario: {scenario.title}</Badge></div>}
                </div>
                <div>
                    <button onClick={onCloseClick} className="btn-close float-end"></button>
                </div>
            </div>

            <div className="modal-body ">
                <div className="wizard clearfix">
                    <div className="steps clearfix">
                        <ul>

                            <NavItem
                                className={classnames({ current: activeTab === 1 })}
                            >

                                <NavLink
                                    className={classnames({ current: activeTab === 1 })}
                                    onClick={() => {
                                        setactiveTab(1)
                                    }}
                                    disabled={!(passedSteps || []).includes(1)}
                                >
                                    <span className="number">1.</span> Mon scénario d'analyse
                                </NavLink>


                            </NavItem>

                            <NavItem
                                className={classnames({ current: activeTab === 2 })}
                            >

                                <NavLink
                                    className={classnames({ current: activeTab === 2 })}
                                    onClick={() => {
                                        setactiveTab(2)
                                    }}
                                    disabled={!(passedSteps || []).includes(2)}
                                >
                                    <span className="number">2.</span> Mes fichiers à traiter
                                </NavLink>


                            </NavItem>

                            <NavItem
                                className={classnames({ current: activeTab === 3 })}
                            >

                                <NavLink
                                    className={classnames({ current: activeTab === 3 })}
                                    onClick={() => {
                                        setactiveTab(3)
                                    }}
                                    disabled={!(passedSteps || []).includes(2)}
                                >
                                    <span className="number">3.</span> Mes instructions
                                </NavLink>


                            </NavItem>


                            <NavItem
                                className={classnames({ current: activeTab === 4 })}
                            >

                                <NavLink
                                    className={classnames({ current: activeTab === 4 })}
                                    onClick={() => {
                                        setactiveTab(4)
                                    }}
                                    disabled={!(passedSteps || []).includes(4)}
                                >
                                    <span className="number">4.</span> Groovy !
                                </NavLink>
                            </NavItem>

                        </ul>
                    </div>

                    <div className="content clearfix " >
                        <TabContent activeTab={activeTab} className="body">
                            <TabPane tabId={1}>
                                <ScenarioPicker onScenarionSelected={onScenarioSelected} scenario={scenario} />
                            </TabPane>

                            <TabPane tabId={2}>
                                <TaskFilePicker onFinish={onFilesSelected} />
                            </TabPane>

                            <TabPane tabId={3}>
                                <TaskInstruction scenario={scenario} onFinish={onInstructionCompleted} />
                            </TabPane>

                            <TabPane tabId={4}>
                                <TaskResult scenario={scenario} files={files} instructions={instructions} />
                            </TabPane>
                        </TabContent>
                    </div>
                </div>
            </div>

            <ModalFooter>

            </ModalFooter>
        </Modal >
    </React.Fragment>

}

export default FileTaskLauncher;