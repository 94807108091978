import React, { useEffect, useState } from "react"
import { Card, CardBody, Col, Input, Label, Row } from "reactstrap";
import AppConfigIcon from "../../../../../assets/images/components/appConfig.png"
import { Link } from "react-router-dom";
import { isEmpty } from "lodash";
import Select from "react-select"
import { businessDomains } from "utils";
import { withTranslation } from "react-i18next";


const DeployBoard = ({ board, onChange, app, t }) => {

    const [appName, setAppName] = useState(app?.name);
    const [description, setDescription] = useState(app?.description);
    const [domains, setDomains] = useState([]);
    const [selectedDomains, setSelectedDomains] = useState([]);

    useEffect(() => {
        onChange({
            name: appName,
            description: description,
            tags: selectedDomains?.map(d => d.value)
        });

    }, [appName, description, selectedDomains])

    useEffect(() => {
        setDomains(businessDomains.map(d => ({
            label: d.name,
            value: d.name,
            description: d.description,
        })))
    }, []);

    useEffect(() => {
        if (domains?.length > 0 && app && app.tags) {
            setSelectedDomains(app.tags.map(t => domains.find(b => b.value === t)));
        }
    }, [domains, app])

    return <div >
        <Row >
            <Col md={6}>
                <Card>
                    <CardBody>
                        <div className="mb-3">
                            <Label for="basicpill-name-input1">
                                Nom:
                            </Label>
                            <Input
                                type="text"
                                className="form-control"
                                id="basicpill-name-input1"
                                placeholder="Nom de l'application"
                                value={appName}
                                onChange={e => setAppName(e.target.value)}
                            />
                        </div>
                        <div className="mb-3">
                            <Label for="basicpill-address-input1">
                                Description
                            </Label>
                            <textarea
                                id="basicpill-address-input1"
                                className="form-control"
                                rows="7"
                                placeholder="Quelques mots pour décrire cette application"
                                value={description}
                                onChange={e => setDescription(e.target.value)}
                            />
                        </div>

                        <div className="mb-3 card">
                            <Label className="fw-bold">{t("Tags")}:</Label>
                            <Select
                                options={domains}
                                value={selectedDomains}
                                isMulti={true}
                                className="select2-selection"
                                onChange={setSelectedDomains}

                            />
                        </div>

                    </CardBody>
                </Card>

            </Col>
            <Col md={5}>
                <Card className="m-0">
                    <CardBody >
                        <div className="vstack gap-4">
                            <div>
                                <img src={AppConfigIcon} alt="" height="100" className="mb-" />
                            </div>
                            <div>
                                <h5 className="fs-17 mb-2">
                                    <Link to="/job-details" className="text-dark">Bon à savoir </Link></h5>
                                <p className="text-muted fs-14 mb-3"> Une fois votre application publiée, vous pourrez l'utiliser dans toutes vos conversations, pour traiter vos documents, vos tâches et tout autre source connectée. </p>
                                <p className="text-muted fs-14 mb-1"> Bien décrire votre application permettra non seulement une meilleure pertinence dans les suggestions d'applications mais cela permettra également si vous souhaitiez la partager,  de mieux comprendre à quel moment utiliser cette application . </p>

                            </div>
                        </div>

                    </CardBody>
                </Card>
            </Col>
        </Row>


    </div>
}

export default withTranslation()(DeployBoard);
