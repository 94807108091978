import React from "react"
import { RotatingLines } from 'react-loader-spinner'

const TaskLoader = ({ height, color, width, visible }) => {
    const size = height || "55vh";
    return <div className="d-flex justify-content-center" style={{ height: size }}>
        <RotatingLines
            strokeColor={color || "white"}
            strokeWidth="5"
            animationDuration="0.75"
            width={width || "100"}
            visible={visible || true}
            className="text-muted"
        />
    </div >
}

export default TaskLoader;