import { combineReducers } from "redux";

// Front
import Layout from "./layout/reducer";

// Authentication
import Login from "./auth/login/reducer";
import Account from "./auth/register/reducer";
import ForgetPassword from "./auth/forgetpwd/reducer";
import Profile from "./auth/profile/reducer";
import Assistant from "./assistant/reducer";
import Todos from "./todos/reducer"
import Documents from "./documents/reducer"
import Omnichat from "./omnichat/reducer"
import Crm from "./crm/reducer"
import Studio from "./studio/reducer"
import Datasources from "./datasources/reducer"
import Users from "./users/reducer"
import Usages from "./usages/reducer"
import Deployments from "./deployments/reducer"
import Streams from "./streams/reducer"
import PromptStore from "./promptStore/reducer"

//ADD ONS
import Support from "./addons/support/reducer"


const rootReducer = combineReducers({
  Layout,
  Login,
  Account,
  ForgetPassword,
  Profile,
  Assistant,
  Todos,
  Documents,
  Omnichat,
  Crm,
  Studio,
  Datasources,
  Users,
  Usages,
  Deployments,
  Streams,
  Support,
  PromptStore
});

export default rootReducer;
