import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import {
    Row, Col, Form, Card, CardBody,
    Spinner
} from "reactstrap"

import JiraIcon from "assets/images/providers/Jira.png"
import { isEmpty } from "lodash"
import { DatasourceProviders, ResourceTypes } from "constants/general"
import { describeDatasource } from "service/datasources"


const JiraConnectivity = ({ onSetConnection }) => {

    const [url, setUrl] = useState("https://<domain>.atlassian.net/");
    const [accountType, setAccountType] = useState("service");
    const [login, setLogin] = useState(null);
    const [password, setPassword] = useState(null);
    const [tenantId, setTenantId] = useState(null);
    const [isTesting, setIsTesting] = useState(false);
    const [canTest, setCanTest] = useState(false);
    const [description, setDescription] = useState(null);
    const [isValid, setIsValid] = useState(false);

    const test = () => {

        const creds = {
            url: url,
            email: login,
            token: password,
            tenantId: tenantId,
        };

        const request = {
            id: DatasourceProviders.JIRA,
            type: ResourceTypes.SUPPORT,
            connection: {
                jira: creds
            }
        }

        setIsTesting(true);

        describeDatasource(request.id, request).then(resp => {
            console.log(description)
            setDescription(resp);
            setIsValid(true);
        }).finally(() => {
            setIsTesting(false);
        })
    }

    useEffect(() => {

        const state = !isEmpty(login) && !isEmpty(password) && !isEmpty(url) && !isEmpty(tenantId);

        const conn = {
            url: url,
            email: login,
            token: password,
            tenantId: tenantId,
        };

        onSetConnection({
            jira: conn,
            isValid: isValid

        });
        setCanTest(state);


    }, [login, password, url, isValid, tenantId])

    return (
        <React.Fragment >
            <div className="">
                <div>
                    <h5>Connect to JIRA</h5>
                    <p>
                        Provide connection details below to connect your support
                    </p>
                </div>

                <Row >
                    <Col lg={8}>
                        <Row>
                            <Col lg="8">
                                <div>
                                    <Form>
                                        <div className="form-floating mb-3">
                                            <input type="text" className="form-control" id="clientId" placeholder="ie: https://<Domain>.atlassian.net" value={url}
                                                onChange={e => setUrl(e.target.value)} />
                                            <label htmlFor="clientId">Base URL</label>
                                        </div>
                                    </Form>
                                </div>
                            </Col>

                        </Row>

                        <Row>
                            <Col lg="8">
                                <div>
                                    <Form>
                                        <div className="form-floating mb-3">
                                            <input type="text" className="form-control" id="tenantId" placeholder="ie: https://<Domain>.atlassian.net" value={tenantId}
                                                onChange={e => setTenantId(e.target.value)} />
                                            <label htmlFor="tenantId">Organization ID</label>
                                        </div>
                                    </Form>
                                </div>
                            </Col>

                        </Row>


                        <div>
                            <Row>
                                <Col lg="4">
                                    <div>
                                        <Row className="mb-2">
                                            <Form>
                                                <div className="form-floating mb-3">
                                                    <input type="text" className="form-control" id="floatingappIdInput" placeholder=""
                                                        value={login}
                                                        onChange={e => setLogin(e.target.value)}
                                                    />
                                                    <label htmlFor="floatingappIdInput">Email</label>
                                                </div>
                                            </Form>
                                        </Row>
                                    </div>
                                </Col>
                                <Col lg="4">
                                    <div>
                                        <Form>
                                            <div className="form-floating mb-3">
                                                <input type="password" className="form-control" id="floatingappKeyInput" placeholder=""
                                                    value={password}
                                                    onChange={e => setPassword(e.target.value)}

                                                />
                                                <label htmlFor="floatingappKeyInput">Api Token</label>
                                            </div>
                                        </Form>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <Row>
                            <Col md={9}>
                                <div className="float-end">
                                    <button onClick={test} disabled={!canTest} className="btn btn-info">
                                        <div className="hstack gap-2">
                                            Tester la connexion
                                            {isTesting && <Spinner size={"sm"} />}
                                        </div>
                                    </button>
                                    {!isTesting && isValid && <i className="mdi mdi-check-circle text-success font-size-18 ms-1" />}

                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col lg={4}>

                        <Card>
                            <CardBody>
                                <div className="favorite-icon">
                                    <Link to="#"><i className="uil uil-heart-alt fs-18"></i></Link>
                                </div>
                                <img src={JiraIcon} alt="" height="100" className="mb-3" />
                                <h5 className="fs-17 mb-2">
                                    <Link to="/job-details" className="text-dark">Description</Link></h5>
                                <p className="text-muted fs-14 mb-1"> Jira is a popular project management tool developed by Atlassian. It is primarily used for issue tracking, bug tracking, and agile project management. Jira allows teams to plan, track, and release software efficiently. It provides features such as creating and assigning tasks, tracking progress, managing workflows, and generating reports. Jira is widely used in software development teams but can also be used for various other project management purposes.

                                </p>

                                <div className="mt-3 hstack gap-2">
                                    <span className="badge rounded-1 badge-soft-info">tickets</span>
                                    <span className="badge rounded-1 badge-soft-info">teams</span>
                                    <span className="badge rounded-1 badge-soft-info">projects</span>
                                    <span className="badge rounded-1 badge-soft-info">...</span>
                                </div>

                            </CardBody>
                        </Card>
                        {/* <div className="d-flex justify-content-center" >
                            <div className="hstack gap-2">
                                <img src={SharePoint} width={150} height={150} className="mb-4" />
                                <div>
                                    <h5>Description</h5>
                                    <p>
                                        A versatile collaboration and document management platform developed by Microsoft. It offers a wide range of features and
                                        capabilities to enhance teamwork, streamline business processes, and manage content effectively within organizations
                                    </p>
                                </div>
                            </div>
                        </div> */}
                    </Col>
                </Row>
            </div >
        </React.Fragment >
    )
}

export default JiraConnectivity;
