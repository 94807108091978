import React, { useEffect, useState } from "react";
import logoDark from "../../../../../assets/images/logos/jask-8.png";
import logoLight from "../../../../../assets/images/logos/jask-6.png";

import { Card, CardBody, CardTitle } from "reactstrap";
import { layoutModeTypes } from "constants/layout";
import { useSelector, useDispatch } from "react-redux";
import { ResourceTypes } from "constants/general";
import { withTranslation } from "react-i18next";
import { getAssistantSuggestions } from "service/assistant";
import { map } from "lodash";
import { capitalize } from "lodash";

const LandingChat = ({ onExampleClicked, assistant, t }) => {

    const [suggestions, setSuggestions] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const { theme, account } = useSelector(state => ({
        theme: state.Layout.layoutModeType,
        account: state.Profile.account,

    }));

    useEffect(() => {
        getAssistantSuggestions(assistant?.id, 4).then(data => {
            setSuggestions(data);
        })
    }, [assistant])

    return <div className="landing-chat" >
        <div className=" chat-info">
            <div className="d-flex justify-content-center flex-column align-items-center mb-2 ">

                <span className="logo-lg" data-layout-mode="dark" >
                    {theme === layoutModeTypes.DARK ? <img src={logoDark} alt="" height="70" /> : <img src={logoLight} alt="" height="70" />}
                </span>
                <h5 className="text-muted mt-2">{t("Bonjour")} <b>{account?.firstName}</b>, {t("comment puis-je vous aider ?")}</h5>
            </div>

            {
                assistant?.app?.type === ResourceTypes.AGENT_ASSISTANT ?
                    (<div className="d-flex  align-items-center  justify-content-center">
                        <div className="bg-info bg-opacity-25 p-3 agent-info rounded-4 ">
                            <p className="text-muted">
                                <i className="mdi mdi-information text-info font-size-14" /> Je suis un assistant de type <b>agent</b>. Je suis là pour aider sur des tâches nécessitant beaucoup plus de reflexion, d'interrogation avant de formuler une réponse.
                            </p>
                            <p className="text-muted">
                                Un exemple de <b>usecase</b> serait d'analyser un document <b>complexe et dense</b> (rapport financier,...)  à partir d'une question que vous vous poseriez , comparer des performances, ... Je ferai de mon mieux pour répondre.  </p>
                        </div>

                    </div>) :
                    (<div><div className="d-flex mt-4 ps-2 pe-2 align-items-center  justify-content-center chat-suggestions">

                        {map(suggestions, item => {

                            return <Card className="  chat-info-headline p-2 me-4 bg-info bg-opacity-25 rounded-2 " onClick={() => onExampleClicked(item.content)}>
                                <CardTitle>
                                    <div className="hstack gap-1">
                                        <i className=" mdi mdi-lightbulb-on-outline text-info font-size-20" />
                                        <small>{capitalize(item.category)}</small>
                                    </div>
                                </CardTitle>

                                <p>{item.content}</p>
                            </Card>
                        })}


                        {/* <Card className="chat-info-headline  p-2 me-4 bg-info bg-opacity-25  rounded-2" onClick={() => onExampleClicked(t("Qu'est ce qu'un congé payé  ?"))}>
                            <CardTitle>
                                <div className="hstack gap-1">
                                    <i className=" mdi mdi-lightbulb-on-outline  text-info font-size-20" />
                                    <small>{t("Ressources Humaines")}</small>
                                </div>
                            </CardTitle>

                            <p>{t("Qu'est ce qu'un congé payé  ?")}</p>
                        </Card>

                        <Card className="chat-info-headline  p-2 me-4 bg-info bg-opacity-25 rounded-2" onClick={() => onExampleClicked(t("Que sais tu faire ?"))}>
                            <CardTitle>
                                <div className="hstack gap-1">
                                    <i className=" mdi mdi-lightbulb-on-outline  text-info font-size-20" />
                                    <small>Divers</small>
                                </div>
                            </CardTitle>

                            <p>{t("Que sais tu faire ?")}</p>
                        </Card> */}
                    </div></div>)
            }

        </div>
    </div>
}

export default withTranslation()(LandingChat);
