

import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { Badge, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import { map } from "lodash";
import { useSelector, useDispatch } from "react-redux";
import SimpleBar from "simplebar-react"
import { getTime } from "helpers/to-local-date";
import DeleteModal from "components/Common/DeleteModal";

const Conversation = ({ conversation, isActive, t, onConversationChanged, onRename, onDelete }) => {
    const [showTools, setShowTools] = useState(false);
    const [edit, setEdit] = useState(false);
    const [title, setTitle] = useState(conversation?.title);
    const [showDelete, setShowDelete] = useState(false);

    const toggle = (state) => {
        setShowTools(state);
    }

    const onToolbarClicked = (e) => {
        e.stopPropagation();
    }

    const onRenameClicked = (e, item) => {
        e.stopPropagation();
        setEdit(!edit)
    }

    const onDeleteClicked = (e, item) => {
        e.stopPropagation();
        setShowDelete(!showDelete);
    }

    const onsubmit = (e) => {
        const { key } = e;
        if (key === "Enter" && !e.shiftKey) {
            onRename(conversation.id, title)
            setEdit(false);
        }
    }

    const confirmDelete = () => {
        onDelete(conversation.id);
        setShowDelete(!showDelete);
    }

    useEffect(() => {
        return () => setShowTools(false);
    }, [])

    return <div
        onClick={() => onConversationChanged(conversation)}
        key={conversation.id}
        onMouseEnter={() => toggle(true)}
        onMouseLeave={() => toggle(false)}
        className={"conversation p-4  border border-secondary border-opacity-50 " + (isActive ? "active" : "")}
    >

        {showDelete && <DeleteModal
            show={showDelete}
            onCloseClick={() => setShowDelete(!showDelete)}
            text={t("Voulez-vous vraiment supprimer cette conversation ?")}
            onDeleteClick={confirmDelete}

        />}
        <div className="d-flex justify-content-between ">
            {!edit ? < h5 className="text-truncate font-size-14  fw-bold" >
                <div className="hstack gap-1">
                    <span>{title}</span>
                    {isActive && <Badge color="danger">{t("Actuelle")}</Badge>}
                </div>

            </h5> : <input
                type="text"
                value={title}
                onChange={e => setTitle(e.target.value)}
                className="form-control me-4"
                style={{ height: "30px" }}
                onKeyDown={onsubmit}
            />}
            {!showTools && <div className="font-size-11 ms-auto">
                {getTime(conversation.createdAt)}

            </div>}
            {showTools && <div className="font-size-11 ms-auto">
                <UncontrolledDropdown>
                    <DropdownToggle tag={"a"} onClick={(e) => onToolbarClicked(e)}>
                        <i className="mdi mdi-dots-horizontal text-info font-size-18"></i>
                    </DropdownToggle>
                    <DropdownMenu>
                        <DropdownItem onClick={e => onRenameClicked(e, conversation)}>{t("Renommer")}</DropdownItem>
                        <DropdownItem onClick={e => onDeleteClicked(e, conversation)}>{t("Supprimer")}</DropdownItem>
                    </DropdownMenu>
                </UncontrolledDropdown>

            </div>}

        </div>
        <div>
            <p className="text-truncate  mb-0">
                {conversation.lastMessage || t("Nouvelle conversation")}
            </p>
        </div>
    </div>
}

const OmniConversationList = ({ assistant, onConversationSelected, onRename, onDelete, currentConversation, conversations, t, onClose }) => {

    const dispatch = useDispatch();
    const [current, setCurrent] = useState(currentConversation);
    const [items, setItems] = useState(conversations);
    const { accessToken, channels } = useSelector(state => ({
        accessToken: state.Login.accessToken,
        channels: state.Streams.channels
    }));

    const onConversationChanged = (conversation) => {
        setCurrent(conversation);
        onConversationSelected(conversation);
    }

    const onConversationDelete = (id) => {
        onDelete(id);
        setItems(items.filter(i => i.id !== id));
    }

    return <div className="  pb-4" >
        <div className="d-flex justify-content-between bg-danger bg-opacity- text-white">
            <div className="p-2">
                <div className="hstack gap-2">
                    {assistant.logoUrl && <img src={assistant.logoUrl} width={60} height={60} />}
                    <div className={!assistant.logoUrl ? "ms-2" : ""}>
                        <span className="font-size-16 fw-bold">{assistant.name}</span>
                        <p>{assistant.description}</p>
                    </div>

                </div>

            </div>
            <div className="p-2">
                <button className="btn btn-close text-white" onClick={onClose} />
            </div>
        </div>
        <div className="d-flex justify-content-center p-2">
            <h5>{t("Liste des conversations")} <small>({items?.length})</small></h5>
        </div>
        <div>
            <SimpleBar className="assistants-conversations">
                <div className="">
                    {map(items, conversation => {
                        const isActive = conversation.id === current.id;
                        return <Conversation
                            key={conversation.id}
                            conversation={conversation}
                            isActive={isActive} t={t}
                            onConversationChanged={onConversationChanged}
                            onRename={onRename}
                            onDelete={onConversationDelete}

                        />
                    })}
                </div >


            </SimpleBar >
        </div >
    </div >
}

export default withTranslation()(OmniConversationList);