import { call, put, takeEvery } from "redux-saga/effects"
import { GET_CRM_OPPORTUNITIES, GET_CRM_CONTACTS, GET_CRM_USERS, GET_CRM_BOARD, GET_CRM_ACCOUNT, GET_CRM_OPPORTUNITIES_STAGES, GET_CRM_OPPORTUNITIES_BY_PRODUCT, GET_CRM_OPPORTUNITIES_HISTORICAL, GET_CRM_OPPTY_TREND } from "./actionTypes"
import { getCrmOpptySuccess, getCrmOpptyFailed, getCrmContactsSuccess, getCrmContactsFailed, getCrmUsersSuccess, getCrmUsersFailed, getCrmAccountSuccess, getCrmAccountFailed, getCrmAStagesSuccess, getCrmAStagesFailed, getCrmBoardSuccess, getCrmBoardFailed, getCrmProductShare, getCrmProductShareSuccess, getCrmProductShareFailed, getCrmHistoSuccess, getCrmHistoFailed, getCrmTrendSuccess, getCrmTrendFailed } from "./actions"
import { getCrmOpportunities, getCrmTrend, getCrmUsers, getCrmContacts, getCrmBoard, getCrmAccount, getCrmStages } from "helpers/backend_helper"

function* loadCrmOppty(action) {
  try {
    const response = yield call(getCrmOpportunities, action.payload)
    yield put(getCrmOpptySuccess(response))
  } catch (error) {
    yield put(getCrmOpptyFailed(error))
  }
}

function* loadCrmUsers(action) {
  try {
    const response = yield call(getCrmUsers, action.payload)
    yield put(getCrmUsersSuccess(response))
  } catch (error) {
    yield put(getCrmUsersFailed(error))
  }
}

function* loadContacts(action) {
  try {
    const response = yield call(getCrmContacts, action.payload)
    yield put(getCrmContactsSuccess(response))
  } catch (error) {
    yield put(getCrmContactsFailed(error))
  }
}

function* loadBoard(action) {
  try {
    const response = yield call(getCrmBoard, action.payload)
    yield put(getCrmBoardSuccess(response))
  } catch (error) {
    yield put(getCrmBoardFailed(error))
  }
}

function* loadAccount(action) {
  try {
    const response = yield call(getCrmAccount, action.payload)
    yield put(getCrmAccountSuccess(response))
  } catch (error) {
    yield put(getCrmAccountFailed(error))
  }
}

function* loadStages(action) {
  try {
    const response = yield call(getCrmStages, action.payload)
    yield put(getCrmAStagesSuccess(response))
  } catch (error) {
    yield put(getCrmAStagesFailed(error))
  }
}

function* loadProductBoard(action) {
  try {
    const response = yield call(getCrmBoard, action.payload)
    yield put(getCrmProductShareSuccess(response))
  } catch (error) {
    yield put(getCrmProductShareFailed(error))
  }
}

function* loadHistoBoard(action) {
  try {
    const response = yield call(getCrmBoard, action.payload)
    yield put(getCrmHistoSuccess(response))
  } catch (error) {
    yield put(getCrmHistoFailed(error))
  }
}

function* loadTrend(action) {
  try {
    const response = yield call(getCrmTrend, action.payload)
    yield put(getCrmTrendSuccess(response))
  } catch (error) {
    yield put(getCrmTrendFailed(error))
  }
}

function* crmSaga() {
  yield takeEvery(GET_CRM_OPPORTUNITIES, loadCrmOppty)
  yield takeEvery(GET_CRM_USERS, loadCrmUsers)
  yield takeEvery(GET_CRM_CONTACTS, loadContacts)
  yield takeEvery(GET_CRM_BOARD, loadBoard)
  yield takeEvery(GET_CRM_ACCOUNT, loadAccount)
  yield takeEvery(GET_CRM_OPPORTUNITIES_STAGES, loadStages)
  yield takeEvery(GET_CRM_OPPORTUNITIES_BY_PRODUCT, loadProductBoard)
  yield takeEvery(GET_CRM_OPPORTUNITIES_HISTORICAL, loadHistoBoard)
  yield takeEvery(GET_CRM_OPPTY_TREND, loadTrend)

}

export default crmSaga
